import React from 'react';
import PropTypes from 'prop-types';
import ShareIcon from '@mui/icons-material/Share';
import { useDispatch } from 'react-redux';
import ShareFileFormContainer, {
  VALIDATION_SCHEMA,
  InitialValues,
  validate
} from '../../../../forms/virtualDisc/containers/ShareFileFormContainer';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { shareFiles, shareFolder } from '../../../../actions/virtualDiscActions';

const useStyles = makeStyles(() => ({
  container: { height: ({ isMobile }) => (isMobile ? '100%' : '100%'), overflow: 'clip' }
}));

const ShareFileDialog = ({
  isDialogOpen,
  onClose,
  selectedFiles,
  isMobile,
  isSingleShare,
  hasSuperPermission,
  isFolder
}) => {
  const cl = useStyles({ isMobile });
  const missingPermissionsItemsArray = hasSuperPermission ? [] : selectedFiles.filter((item) => !item.isOwnByUser);
  const isForbidden = missingPermissionsItemsArray.length > 0;

  const initialValues = isSingleShare
    ? new InitialValues().assign({
        shareForUserRoles: selectedFiles[0].shareForUserRoles,
        shareForUserIds: selectedFiles[0].shareForUserIds,
        isForbidden
      })
    : new InitialValues().assign({ isForbidden });

  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const preparedData = {
      shareForUserRoles: values.isPrivate ? [] : values.shareForUserRoles,
      shareForUserIds: values.isPrivate ? [] : [...values.shareForUserIds]
    };
    if (isFolder) {
      preparedData.folderId = selectedFiles[0].id;
      await dispatch(shareFolder(preparedData));
    } else {
      preparedData.filesIds = selectedFiles.map((item) => item.id);
      await dispatch(shareFiles(preparedData));
    }

    onClose();
  };

  return (
    <Dialog open={isDialogOpen} maxWidth="xl" fullScreen={isMobile} classes={{ paper: cl.container }} fullWidth>
      <DialogTitle sx={{ p: isMobile ? 2 : 3 }}>
        <Box alignItems="center" sx={{ display: 'flex' }}>
          <ShareIcon sx={{ mr: 10 }} />
          <Typography variant="contentTite" sx={{ pt: 1 }}>
            Udostępnij {isFolder ? 'folder' : isSingleShare ? 'plik' : 'pliki'}
          </Typography>
        </Box>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        validate={validate}
        onSubmit={onSubmit}>
        {() => (
          <Form style={{ flex: 1, display: 'flex', minHeight: 0 }}>
            <DialogContent
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column'
              }}>
              <ShareFileFormContainer
                isFolder={isFolder}
                selectedItems={selectedFiles}
                onCloseDialog={onClose}
                isSingleShare={isSingleShare}
                missingPermissionsItemsArray={missingPermissionsItemsArray}
              />
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

ShareFileDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isSingleShare: PropTypes.bool,
  hasSuperPermission: PropTypes.bool.isRequired,
  isFolder: PropTypes.bool
};

export default ShareFileDialog;
