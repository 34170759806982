import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

const PresentationFileIcon = (props) => {
  const theme = useTheme();
  const presentationFileIconColor = theme.palette.components.fileIcons.presentationFile;

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H12C12.5523 4 13 4.44772 13 5V8C13 8.55228 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V19Z"
        fill={presentationFileIconColor}
      />
      <path
        d="M8.4002 17.9997C8.2978 17.9997 8.1954 17.9507 8.117 17.8537C7.961 17.6587 7.961 17.3417 8.117 17.1467L10.917 13.6467C11.0386 13.4937 11.225 13.4567 11.3786 13.5527L12.721 14.3917L15.317 11.1466C15.473 10.9516 15.7266 10.9516 15.8826 11.1466C16.0386 11.3416 16.0386 11.6586 15.8826 11.8536L13.0826 15.3537C12.9602 15.5057 12.7746 15.5417 12.621 15.4477L11.2786 14.6087L8.6826 17.8537C8.605 17.9507 8.5026 17.9997 8.4002 17.9997V17.9997Z"
        fill={presentationFileIconColor}
      />
      <path
        d="M15.6003 15.0001C15.3795 15.0001 15.2003 14.7761 15.2003 14.5V12H13.2003C12.9795 12 12.8003 11.776 12.8003 11.5C12.8003 11.224 12.9795 11 13.2003 11H15.6003C15.8211 11 16.0003 11.224 16.0003 11.5V14.5C16.0003 14.7761 15.8211 15.0001 15.6003 15.0001Z"
        fill={presentationFileIconColor}
      />
    </SvgIcon>
  );
};

export default PresentationFileIcon;
