'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../routePaths';
import AvatarDropzone from '../../common/avatar/AvatarDropzone';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import ChildCareIcon from '@mui/icons-material/ChildCare';

const ChildDetailsHeader = ({
  name,
  nameLink,
  group,
  photoUrl,
  photoChangeDisabled,
  onPhotoDrop,
  onPhotoRemove,
  sx
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      component="header"
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'center' : 'flex-start'}
      sx={{
        ...sx
      }}>
      <Box>
        <AvatarDropzone
          readonly={photoChangeDisabled}
          onPhotoDrop={onPhotoDrop}
          onPhotoRemove={onPhotoRemove}
          photoUrl={photoUrl}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          pl: isMobile ? 0 : 5,
          pt: isMobile ? 2 : 0
        }}>
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.color.primary,
            fontWeight: theme.typography.fontWeightBold
          }}>
          {name}
        </Typography>
        {group ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: theme.palette.color.primary,
              '&:hover': {
                color: theme.palette.color.primary,
                opacity: 0.9
              }
            }}>
            <ChildCareIcon
              sx={{
                fontSize: 30
              }}
            />
            <Typography
              sx={{
                pl: 1,
                cursor: 'pointer',
                alignItems: 'center'
              }}
              variant="h6"
              onClick={() => browserHistory.push(fromTemplate(routePaths.groupDetails, [group.id]))}>
              {group.name}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

ChildDetailsHeader.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  group: PropTypes.object,
  onPhotoDrop: PropTypes.func.isRequired,
  onPhotoRemove: PropTypes.func.isRequired,
  photoChangeDisabled: PropTypes.bool,
  photoUrl: PropTypes.string,
  sx: PropTypes.object
};

export default ChildDetailsHeader;
