import PropTypes from 'prop-types';
import React from 'react';
import ConfirmationDialog from '../../../forms/ConfirmationDialog';
import moment from 'moment';

const RemoveDayOffDialog = ({ dayOff, onConfirm, onCancel }) => {
  return (
    <ConfirmationDialog
      confirmationText={`Czy na pewno chcesz usunąć dzień wolny: ${moment(dayOff.date).format('YYYY.MM.DD')} - ${
        dayOff.name
      }?`}
      confirmationTitle="Usuń dzień wolny"
      onConfirm={onConfirm}
      confirmLabel="Usuń"
      onCancel={onCancel}
      isOpen={true}
    />
  );
};

RemoveDayOffDialog.propTypes = {
  dayOff: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default RemoveDayOffDialog;
