import { tryConvertToNumber } from './numberConverters';
import _ from 'lodash';

export function tryConvertSanitizeToTimeSpan(value) {
  return value.replace(/[^:.,\d\s]/g, '');
}

export function tryConvertToTimeSpan(value) {
  const sanitizedValue = tryConvertSanitizeToTimeSpan(value).replace(/[:.,]/g, ' ').split(' ');

  const hours = sanitizedValue.length > 0 ? tryConvertToNumber(sanitizedValue[0]) || 0 : 0;
  const minutes = sanitizedValue.length > 1 ? tryConvertToNumber(sanitizedValue[1]) || 0 : 0;

  return { hours, minutes };
}

export function tryConvertToString(value) {
  const { hours, minutes } = value;
  if (hours === undefined && minutes === undefined) return value || '';

  if (hours && minutes) return `${hours}:${minutes}`;
  if (!hours && minutes) return `0:${minutes}`;
  if (hours && !minutes) return `${hours}:00`;

  return '';
}

export function tryConvertMinutesToString(value) {
  if (!value) return '';
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return `${hours ? `${hours} godz. ` : ''}${minutes ? `${_.padStart(minutes % 60, 2, 0)} minut` : ''}`;
}
