import ApiBase, { ROOT_URL } from './ApiBase';

class LoginHistoryApi extends ApiBase {
  static getLoginHistory(params) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/logEntries/login`).query(params).type('application/json'));
  }

  static getLoginHistoryFile(params) {
    return super.toPromise(
      (agent) => {
        return agent
          .get(`${ROOT_URL}/logEntries/login/export`)
          .query(params)
          .type('application/json')
          .responseType('blob');
      },
      (res) => res.xhr
    );
  }
}

export default LoginHistoryApi;
