import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Divider, Box } from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PostHeader from '../../messageBoard/post/PostHeader';
import EmptyState from '../../common/EmptyState';

const EventDetailsDialog = (props) => {
  const { header, events, isDialogOpen, className, onClose, onShowEventFullDetails, onCreateNewEvent } = props;

  const handleClose = () => {
    onClose();
  };

  const handleShowEventFullDetails = (date) => {
    onShowEventFullDetails(date);
  };

  const handleCreateNewEvent = (date) => {
    onCreateNewEvent(date);
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleClose} maxWidth={events.length === 0 ? 'xs' : 'md'} fullWidth>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent className={className}>
        {events.length > 0 ? (
          <Box>
            {events.map((e) => (
              <Box key={e.id}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <EventOutlinedIcon sx={{ mr: 1 }} />
                  <PostHeader post={e} />
                </Box>
                {events.length > 1 && <Divider />}
              </Box>
            ))}
          </Box>
        ) : (
          <EmptyState message="Brak wydarzeń w tym dniu" contrast />
        )}
      </DialogContent>
      <DialogActions
        sx={{ display: events.length > 0 && 'flex', justifyContent: events.length > 0 ? 'flex-end' : 'center' }}>
        {events.length > 0 && (
          <Button variant="outlinedContrast" onClick={handleShowEventFullDetails} aria-label="Pokaż szczegóły">
            pokaż szczegóły
          </Button>
        )}
        <Button variant="contained" aria-label="dodaj wydarzenie" onClick={handleCreateNewEvent}>
          dodaj wydarzenie
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EventDetailsDialog.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  className: PropTypes.string,
  onShowEventFullDetails: PropTypes.func.isRequired,
  onCreateNewEvent: PropTypes.func.isRequired
};

export default EventDetailsDialog;
