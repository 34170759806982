import React from 'react';
import PropTypes from 'prop-types';
import IndividualClassesContainer from './IndividualClassesContainer';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';

const IndividualClassesPage = ({ location, params }) => {
  return (
    <LoadingRenderWrapper>
      <IndividualClassesContainer location={location} childId={params.id} />
    </LoadingRenderWrapper>
  );
};

IndividualClassesPage.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default IndividualClassesPage;
