import { FilterByRoleType } from '../../common/filters/filterByRole/FilterByRole';

export const prepareThread = (thread, recipients, groups, userId) => {
  const targetId = thread.participants.filter((p) => p.id !== userId)[0].id;
  const person = recipients.find((human) => human.id === targetId);
  let personType = FilterByRoleType.OTHERS;
  let belongToGroups = [];
  let children = [];

  // if (person && person.hasOwnProperty('documentNumber')) {
  //   personType = FilterByRoleType.LEGAL_GUARDIAN;
  // } else if (person && person.role === 'teacher') personType = FilterByRoleType.TEACHER;

  if (person && person.role === 'teacher') {
    personType = FilterByRoleType.TEACHER;
  } else if (person && person.hasOwnProperty('documentNumber')) {
    personType = FilterByRoleType.LEGAL_GUARDIAN;
  }

  if (person && person.children && person.children.length > 0) {
    belongToGroups = person.children.map((child) => groups.find((group) => group.id === child.groupId) || null);
    children = person.children;
  }

  // Z tego co rozumiem to ta funkcja niżej filtrowała na podstawie grupy, ale to filtrowanie jest już zrobione na backendzie
  // także zakomentowałem to w razie gdyby robiło to coś czego nie widzę

  // if (personType === FilterByRoleType.TEACHER) {
  //   belongToGroups = person.groups.map((item) => groups.find((group) => group.id === item.id) || null);
  // }

  return {
    ...thread,
    belongToGroups,
    personType,
    children
  };
};
