import React from 'react';
import PropTypes from 'prop-types';
import EventNote from '@mui/icons-material/FeedbackOutlined';
import StaffMessageBoardContainer from './StaffMessageBoardContainer';
import { Box } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';

const StaffMessageBoardPage = () => {
  return (
    <Box>
      <PageHeaderText title="Ogłoszenia - pracownicy" titleIcon={<EventNote />} />
      <ContentWrapper>
        <StaffMessageBoardContainer />
      </ContentWrapper>
    </Box>
  );
};

StaffMessageBoardPage.propTypes = {
  params: PropTypes.object.isRequired
};

export default StaffMessageBoardPage;
