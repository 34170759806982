import * as types from './actionTypes';
import api from '../api/ChildrenApi';
// import * as notificationActions from './notificationActions';
// import * as logger from '../utils/logger';

export function importValidateAsync(file) {
  return async (dispatch) => {
    try {
      const response = await api.validateCSVFile(file);
      dispatch({ type: types.IMPORT_CHILDREN_CSV_SUCCESS, result: response });
    } catch (error) {
      dispatch({ type: types.IMPORT_CHILDREN_CSV_FAILURE });
      throw error;
    }
  };
}

export function importChildrenAsync(file) {
  return async (dispatch) => {
    try {
      const response = await api.importCSVFile(file);
      dispatch({ type: types.IMPORT_CHILDREN_CSV_SUCCESS, result: response });
    } catch (error) {
      dispatch({ type: types.IMPORT_CHILDREN_CSV_FAILURE });
      throw error;
    }
  };
}
