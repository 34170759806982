/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function psychologicalGroupWorkProgramReducer(
  state = initialState.psychologicalGroupWorkProgram,
  action
) {
  switch (action.type) {
    case types.LOAD_GROUP_CLASSES: {
      const newState = Object.assign({}, state);
      newState.id = action.group.id;
      newState.basicGroupGoals = action.group.basicGroupGoals;
      newState.specificGroupGoals = action.group.specificGroupGoals;
      newState.groupMethods = action.group.groupMethods;
      newState.groupConclusions = action.group.groupConclusions;

      newState.childId = action.id;
      return newState;
    }
    case types.LOAD_GROUP_CLASSES_CALENDAR: {
      const newState = Object.assign({}, state);
      newState.posts = action.response;
      return newState;
    }
    case types.LOAD_GROUP_DIARY_CALENDAR: {
      const newState = Object.assign({}, state);
      newState.diary = action.response;
      return newState;
    }
    case types.ADD_GROUP_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = [...state.posts, { ...action.response }];
      return newState;
    }

    case types.EDIT_GROUP_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = newState.posts.filter((post) => post.id !== action.data.id);
      newState.posts = [...newState.posts, { ...action.data }];
      return newState;
    }
    case types.DELETE_GROUP_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = newState.posts.filter((post) => post.id !== action.postId);
      return newState;
    }
    //===================-=====================
    case types.ADD_GROUP_DIARY: {
      const newState = Object.assign({}, state);
      newState.diary = [...state.diary, { ...action.response }];
      return newState;
    }
    case types.EDIT_GROUP_DIARY: {
      const newState = Object.assign({}, state);
      const diaryIdx = newState.diary.findIndex((post) => post.id === action.data.id);
      newState.diary[diaryIdx] = { ...action.data };
      return newState;
    }
    case types.DELETE_GROUP_DIARY: {
      const newState = Object.assign({}, state);
      newState.diary = newState.diary.filter((post) => post.id !== action.postId);
      return newState;
    }

    //========================

    case types.ADD_GROUP_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data } = action;
      newState[data.type] = [...newState[data.type], { ...data, id: data.title }];
      return newState;
    }
    case types.EDIT_GROUP_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState[data.type].findIndex((el) => el.id === data.id);
      newState[data.type][elementIdx] = data;
      return newState;
    }
    case types.DELETE_GROUP_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data } = action;
      newState[data.type] = [...newState[data.type].filter((el) => el.id !== data.id)];
      return newState;
    }

    default:
      return state;
  }
}
