import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ReportTypes } from '../../../constants/reportTypes';
import SelectField from '../../common/SelectField';
import { Box, Checkbox, Typography, List, ListItem, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import CustomCheckbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';

class DuesCateringOverallReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'name',
      sortOptions: [
        { name: 'Po nazwisku', value: 'name' },
        { name: 'Po numerze', value: 'documentNumber' }
      ],
      dueTypes: [ChargingReportTypes.catering.value, ChargingReportTypes.staffCatering.value],
      showOverdue: true
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(dueType) {
    let newVal;
    if (this.state.dueTypes.includes(dueType)) newVal = [...this.state.dueTypes.filter((id) => id !== dueType)];
    else newVal = [...this.state.dueTypes, dueType];
    this.setState({ dueTypes: newVal });
    this.props.onChange({ target: { name: 'dueTypes', value: newVal } });
  }

  render() {
    return (
      <Box>
        <GroupAndMonthTemplate
          errors={this.props.errors}
          manyGroupsSelector
          year={this.props.year}
          month={this.props.month}
          groups={this.props.groups}
          onChange={this.props.onChange}
          legend={ReportTypes.duesCateringDetailsOverall.name}
        />
        <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.duesCateringDetailsOverall.name}`}>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Uwzględnij opłaty za
          </Typography>
          <List>
            {Object.values(ChargingReportTypes)
              .filter(
                (item) =>
                  item.value === ChargingReportTypes.catering.value ||
                  item.value === ChargingReportTypes.staffCatering.value
              )
              .map((due) => (
                <ListItem
                  sx={{ p: 0 }}
                  component="li"
                  button
                  key={due.value}
                  onClick={() => this.onSelect(due.value)}
                  aria-label={`Wybierz ${due.name}`}>
                  <ListItemIcon>
                    <Checkbox
                      checked={this.state.dueTypes.includes(due.value)}
                      tabIndex={-1}
                      inputProps={{ 'aria-labelledby': `${due}-checkbox` }}
                    />
                  </ListItemIcon>
                  <ListItemText id={`${due}-checkbox`} primary={due.name} />
                </ListItem>
              ))}
          </List>
        </FieldsetWrapper>
        <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.duesCateringDetailsOverall.name}`}>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Ustawienia raportu
          </Typography>
          <SelectField
            contrast
            floatingLabelText="Sortowanie"
            id="sortBy"
            value={this.state.sortBy}
            onChange={(event) => {
              this.setState({ sortBy: event.target.value });
              this.props.onChange({ target: { name: 'sortBy', value: event.target.value } });
            }}>
            {this.state.sortOptions.map((so) => {
              return (
                <MenuItem key={so.value} value={so.value}>
                  {so.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <Box>
            <CustomCheckbox
              sx={{ pt: 2 }}
              label="Uwzględniaj zaległości"
              defaultChecked={this.state.showOverdue}
              onCheck={(event, isChecked) => {
                this.setState({ showOverdue: isChecked });
                this.props.onChange({ target: { name: 'showOverdue', value: isChecked } });
              }}
            />
          </Box>
        </FieldsetWrapper>
      </Box>
    );
  }
}

DuesCateringOverallReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  showOverdue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DuesCateringOverallReport;
