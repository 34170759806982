import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import SelectField from '../../common/SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, Typography, MenuItem } from '@mui/material';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';

class DuesActivitiesOverallReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'name',
      sortOptions: [
        { name: 'Po nazwisku', value: 'name' },
        { name: 'Po numerze', value: 'documentNumber' }
      ],
      dueTypes: [ChargingReportTypes.activities.value]
    };
  }

  render() {
    return (
      <Box>
        <FieldsetWrapper legend="Wydruk kwitariuszy zajęć dodatkowych">
          <SelectField
            contrast
            sx={{ width: 'auto', overflow: 'hidden' }}
            floatingLabelText="Zajęcia dodatkowe"
            id="activityId"
            value={this.props.activityId}
            onChange={(event) => {
              this.props.onChange({ target: { name: 'activityId', value: event.target.value } });
            }}>
            {this.props.activities.map((activity) => {
              return (
                <MenuItem key={activity.id} value={activity.id}>
                  {activity.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <Box>
            <Typography
              variant="h6"
              sx={{ mt: 1, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              Wybierz miesiąc
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <YearAndMonthOptionsSlider
                contrast
                onSelected={(nextYear, nextMonth) => {
                  this.props.onChange({ target: { name: 'year', value: nextYear } });
                  this.props.onChange({ target: { name: 'month', value: nextMonth } });
                }}
                defaultMonth={this.props.month}
                defaultYear={this.props.year}
              />
            </Box>
          </Box>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Ustawienia raportu
          </Typography>
          <SelectField
            contrast
            floatingLabelText="Sortowanie"
            sx={{ width: 'auto', overflow: 'hidden' }}
            id="sortBy"
            value={this.state.sortBy}
            onChange={(event) => {
              this.setState({ sortBy: event.target.value });
              this.props.onChange({ target: { name: 'sortBy', value: event.target.value } });
            }}>
            {this.state.sortOptions.map((so) => {
              return (
                <MenuItem key={so.value} value={so.value}>
                  {so.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </FieldsetWrapper>
      </Box>
    );
  }
}

DuesActivitiesOverallReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  activities: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  activityId: PropTypes.string
};

export default DuesActivitiesOverallReport;
