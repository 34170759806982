import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

const CompressedFileIcon = (props) => {
  const theme = useTheme();
  const compressedFileIconColor = theme.palette.components.fileIcons.defaultFile;

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H12C12.5523 4 13 4.44772 13 5V8C13 8.55228 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V19Z"
        fill={compressedFileIconColor}
      />
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H12C12.5523 4 13 4.44772 13 5V8C13 8.55228 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V19Z"
        fill={compressedFileIconColor}
      />
      <path
        d="M11.9537 17.8004L11.1517 14.5834C11.1308 14.4992 11.0823 14.4245 11.0142 14.3712C10.946 14.3178 10.862 14.2888 10.7755 14.2888H10.3878V13.1221H11.5511V12.3443H10.3878V11.5666H11.5511V10.7888H10.3878V10.5943V10.3999H9.61223V11.5666H8.44891V12.3443H9.61223V13.1221H8.44891V13.8999H9.61223V14.2888H9.22446C9.13798 14.2888 9.05398 14.3178 8.98582 14.3712C8.91766 14.4245 8.86924 14.4992 8.84827 14.5834L8.04631 17.8004C7.98914 18.0297 7.98484 18.2691 8.03374 18.5004C8.08264 18.7316 8.18345 18.9487 8.3285 19.135C8.47356 19.3213 8.65906 19.472 8.8709 19.5757C9.08274 19.6794 9.31535 19.7332 9.55106 19.7332H10.4489C10.6847 19.7332 10.9173 19.6794 11.1291 19.5757C11.3409 19.472 11.5264 19.3213 11.6715 19.135C11.8166 18.9487 11.9174 18.7316 11.9663 18.5004C12.0152 18.2691 12.0109 18.0297 11.9537 17.8004ZM10 18.5666C9.84661 18.5666 9.69667 18.521 9.56913 18.4355C9.44159 18.35 9.34219 18.2286 9.28349 18.0864C9.22479 17.9443 9.20943 17.7879 9.23936 17.6371C9.26928 17.4862 9.34315 17.3476 9.45161 17.2388C9.56007 17.13 9.69826 17.056 9.8487 17.026C9.99914 16.9959 10.1551 17.0114 10.2968 17.0702C10.4385 17.1291 10.5596 17.2288 10.6448 17.3567C10.7301 17.4846 10.7755 17.635 10.7755 17.7888C10.7755 17.9951 10.6938 18.1929 10.5484 18.3388C10.4029 18.4846 10.2057 18.5666 10 18.5666Z"
        fill={compressedFileIconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69652 11.0667H10.4721V9.92217H11.6354V9.1444H10.4721V8.36662H11.6354V7.58884H10.4721V7.3944V7.19995H9.69652V8.36662H8.5332V9.1444H9.69652V9.92217H8.5332V10.7H9.69652V11.0667Z"
        fill={compressedFileIconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69652 7.8667H10.4721V6.72222H11.6354V5.94444H10.4721V5.16667H11.6354V4.38889H10.4721V4.19444V4H9.69652V5.16667H8.5332V5.94444H9.69652V6.72222H8.5332V7.5H9.69652V7.8667Z"
        fill={compressedFileIconColor}
      />
    </SvgIcon>
  );
};

export default CompressedFileIcon;
