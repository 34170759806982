import api from '../api/UnitApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
// import { browserHistory } from 'react-router';
// import { routePaths, fromTemplate } from '../routePaths';

export function loadEntrances() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ENTRANCES_START });
    return api
      .getEntrances()
      .then((entrances) => {
        dispatch({ type: types.LOAD_ENTRANCES_SUCCESS, entrances });
      })
      .catch(() => {
        dispatch({ type: types.LOAD_ENTRANCES_SUCCESS, entrances: [] });
        dispatch(notificationActions.showError('Wystąpił błąd'));
      });
  };
}

export function importEntrance(file) {
  return (dispatch) => {
    dispatch({ type: types.IMPORT_ENTRANCE_START });
    return api.importEntrance(file).then((res) => {
      dispatch({ type: types.IMPORT_ENTRANCE_SUCCESS, entrance: res });
    });
  };
}
