import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
});

const SortedTableCell = ({ cell, orderBy, order, onRequestSort }) => {
  const cl = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property, cell.type);
  };
  return (
    <TableCell {...cell.config} key={cell.id} sx={{ p: 0, px: 1.5, textAlign: 'left' }}>
      <TableSortLabel
        sx={{
          '&:hover': { color: (theme) => theme.palette.color.contrast },
          '&:focus .MuiTypography-root': { fontWeight: 700, color: (theme) => theme.palette.color.contrast }
        }}
        active={orderBy === cell.id}
        direction={orderBy === cell.id ? order : 'asc'}
        onClick={createSortHandler(cell.id)}>
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{cell.label}</Typography>
        {orderBy === cell.id ? (
          <Typography className={cl.visuallyHidden}>{order === 'desc' ? 'Malejąco' : 'Rosnąco'}</Typography>
        ) : null}
      </TableSortLabel>
      {cell.double && (
        <TableSortLabel
          sx={{
            '&:hover': { color: (theme) => theme.palette.color.contrast },
            '&:focus .MuiTypography-root': { fontWeight: 700, color: (theme) => theme.palette.color.contrast }
          }}
          active={orderBy === cell.id2}
          direction={orderBy === cell.id2 ? order : 'asc'}
          onClick={createSortHandler(cell.id2)}>
          <Typography>{cell.label2}</Typography>
          {orderBy === cell.id2 ? (
            <Typography className={cl.visuallyHidden}>{order === 'desc' ? 'Malejąco' : 'Rosnąco'}</Typography>
          ) : null}
        </TableSortLabel>
      )}
    </TableCell>
  );
};

SortedTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

export default SortedTableCell;
