import React from 'react';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import LegalGuardiansContainer from './containers/LegalGuardiansContainer';

const LegalGuardianPage = () => {
  return (
    <LoadingRenderWrapper>
      <LegalGuardiansContainer />
    </LoadingRenderWrapper>
  );
};

export default LegalGuardianPage;
