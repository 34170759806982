'use strict';

import BaseModel from '../../BaseModel';

export class ReportModel extends BaseModel {
  constructor() {
    super();
    this.type = '';
    this.name = '';
    this.formats = [];
    this.visibleTo = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.visibleTo = [...assigned.visibleTo];
    assigned.formats = [...assigned.formats];
  }
}
