import React from 'react';
import PropTypes from 'prop-types';
import { FormikTextField } from '../../../formik/FormikTextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { FieldArray } from 'formik';
import FormikSwitch from '../../../formik/FormikToggle';
import { Box, Typography, ListItem } from '@mui/material';

const ChildDetailsFormAttributes = (props) => {
  const { values } = props;
  return (
    <>
      <FieldArray
        name="attributes"
        render={() => (
          <Box>
            {values.attributes.map((attribute, index) => (
              <FieldsetWrapper
                key={attribute.attributeSetId || index}
                legend={
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      pl: 2,
                      color: (theme) => theme.palette.color.contrast
                    }}>
                    {attribute.attributeSetName}
                  </Typography>
                }
                invisible={false}>
                <Box sx={{ pb: 3 }}>
                  {attribute.attributeValues.map((att, ind) => (
                    <ListItem key={attribute.key || ind}>
                      {att.type === 'text' ? (
                        <FormikTextField
                          name={`attributes.${index}.attributeValues.${ind}.value` || ''}
                          label={att.name}
                          value={att.value || ''}
                          fullWidth
                        />
                      ) : (
                        <FormikSwitch
                          name={`attributes.${index}.attributeValues.${ind}.value`}
                          label={att.name}
                          value={att.value || false}
                        />
                      )}
                    </ListItem>
                  ))}
                </Box>
              </FieldsetWrapper>
            ))}
          </Box>
        )}
      />
    </>
  );
};

ChildDetailsFormAttributes.propTypes = {
  values: PropTypes.object.isRequired
};

export default ChildDetailsFormAttributes;