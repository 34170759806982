import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';
import initialState from './initial';

export default function psychologicalGroupDetailsUiReducer(state = initialState.psychologicalGroupDetailsUi, action) {
  switch (action.type) {
    //UPDATE
    case types.UPDATE_PSYCHOLOGICAL_GROUP: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_PSYCHOLOGICAL_GROUP_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.UPDATE_PSYCHOLOGICAL_GROUP_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.UPDATE_PSYCHOLOGICAL_GROUP_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.UPDATE_PSYCHOLOGICAL_GROUP_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }

    case types.ARCHIVE_PSYCHOLOGICAL_GROUP: {
      return Object.assign({}, state, { archival: UiModel.start() });
    }
    case types.ARCHIVE_PSYCHOLOGICAL_GROUP_CANCEL: {
      return Object.assign({}, state, { archival: UiModel.cancel() });
    }
    case types.ARCHIVE_PSYCHOLOGICAL_GROUP_SUBMIT: {
      return Object.assign({}, state, { archival: UiModel.submit() });
    }
    case types.ARCHIVE_PSYCHOLOGICAL_GROUP_SUCCESS: {
      return Object.assign({}, state, { archival: UiModel.success() });
    }
    case types.ARCHIVE_PSYCHOLOGICAL_GROUP_FAILURE: {
      return Object.assign({}, state, { archival: UiModel.failure(action.errors) });
    }

    case types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP: {
      return Object.assign({}, state, { enrolledChildren: UiModel.start() });
    }
    case types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_CANCEL: {
      return Object.assign({}, state, { enrolledChildren: UiModel.cancel() });
    }
    case types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUBMIT: {
      return Object.assign({}, state, { enrolledChildren: UiModel.submit() });
    }
    case types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUCCESS: {
      return Object.assign({}, state, { enrolledChildren: UiModel.success() });
    }
    case types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_FAILURE: {
      return Object.assign({}, state, { enrolledChildren: UiModel.failure(action.errors) });
    }
    case types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP: {
      return Object.assign({}, state, { associatedTeachers: UiModel.start() });
    }
    case types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_CANCEL: {
      return Object.assign({}, state, { associatedTeachers: UiModel.cancel() });
    }
    case types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUBMIT: {
      return Object.assign({}, state, { associatedTeachers: UiModel.submit() });
    }
    case types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUCCESS: {
      return Object.assign({}, state, { associatedTeachers: UiModel.success() });
    }
    case types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_FAILURE: {
      return Object.assign({}, state, { associatedTeachers: UiModel.failure(action.errors) });
    }

    default:
      return state;
  }
}
