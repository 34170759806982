import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PersonIcon from '@mui/icons-material/Person';
import MoreGroupsIcon from '../../../../assets/icons/MoreGroupsIcon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useShareFileContext } from '../../context/ShareFileContext';
import PrivateButton from '../PrivateButton/PrivateButton';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `panel-przelaczenia-${index}`
});

const useStyles = makeStyles({
  tabWrapper: {
    flexDirection: 'row'
  },
  text: {
    fontSize: 16
  },
  tab: {
    textTransform: 'none',
    flexDirection: 'row'
  },
  tabRightIcon: { fontSize: 32, marginRight: 20 }
});

const ShareFileHeaderOptions = ({
  selectedSharingOption,
  onPrivate,
  onChangeSharingOption,
  setShareToUsersTouched,
  shareToUsersTouched
}) => {
  const cl = useStyles();
  const { isMobile, isDisabled, isPrivate, isSingleShare, isFolder } = useShareFileContext();

  return (
    <Grid container direction="column">
      <PrivateButton
        isFolder={isFolder}
        isPrivate={isPrivate}
        onPrivate={onPrivate}
        isDisabled={isDisabled}
        isSingleShare={isSingleShare}
      />
      <Grid container>
        <Tabs
          orientation={isMobile ? 'vertical' : 'horizontal'}
          value={selectedSharingOption}
          onChange={(event, newValue) => {
            if (newValue !== selectedSharingOption) {
              if (!shareToUsersTouched) setShareToUsersTouched(true);
              onChangeSharingOption(newValue);
            }
          }}
          style={{ width: '100%', marginTop: '-10px' }}
          TabIndicatorProps={{
            sx: { background: (theme) => theme.palette.color.color25, maxWidth: '80%' }
          }}
          aria-label="Przełącz możliwe sterowanie"
          variant="fullWidth">
          <Tab
            sx={{
              '&.Mui-selected': {
                color: (theme) => theme.palette.color.color25
              },
              color: (theme) => theme.palette.text.primary
            }}
            icon={
              <MoreGroupsIcon
                sx={{
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.color.color25
                  },
                  fontSize: 40
                }}
              />
            }
            className={cl.tab}
            disabled={isDisabled}
            label={
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }} className={cl.text}>
                Udostępnianie dla poszczególnych ról
              </Typography>
            }
            classes={{ wrapper: cl.tabWrapper }}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              '&.Mui-selected': {
                color: (theme) => theme.palette.color.color25
              },
              color: (theme) => theme.palette.text.primary
            }}
            icon={
              shareToUsersTouched ? (
                <PersonIcon
                  sx={{
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.color.color25
                    },
                    fontSize: 32
                  }}
                />
              ) : (
                <PersonAddIcon className={cl.tabRightIcon} />
              )
            }
            className={cl.tab}
            disabled={isDisabled}
            label={
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }} className={cl.text}>
                {shareToUsersTouched ? 'Udostępnianie' : 'Udostępnij'} dla pojedynczych osób
              </Typography>
            }
            classes={{ wrapper: cl.tabWrapper }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
    </Grid>
  );
};

ShareFileHeaderOptions.propTypes = {
  onChangeSharingOption: PropTypes.func.isRequired,
  onPrivate: PropTypes.func,
  selectedSharingOption: PropTypes.number.isRequired,
  setShareToUsersTouched: PropTypes.func,
  shareToUsersTouched: PropTypes.bool
};

export default ShareFileHeaderOptions;
