import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ExpandMoreAll = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M20 20H4v2h16v-2ZM20 2H4v2h16V2ZM9.41 13.59 8 15l4 4 4-4-1.41-1.41L13 15.17V8.83l1.59 1.58L16 9l-4-4-4 4 1.41 1.41L11 8.83v6.34l-1.59-1.58Z" />
  </SvgIcon>
);

export default ExpandMoreAll;
