import PropTypes from 'prop-types';
import React from 'react';
import { tryConvertToNumber } from '../../../../utils/numberConverters';
import { ChargingDeductionModes } from '../../../../constants/chargingDeductionModes';
import { ChargingDeductionBases } from '../../../../constants/chargingDeductionBases';
import SelectField from '../../../common/SelectField';
import TextField from '../../../common/TextField';
import { Box, MenuItem } from '@mui/material';

const DeductionItem = ({ baseWithThresholds, deduction, onChange }) => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <SelectField
        contrast
        name="base"
        value={deduction.base}
        hintText="Nieobecność co najmniej..."
        fullWidth
        floatingLabelFixed={true}
        floatingLabelText=" "
        sx={{ mr: 1, minWidth: 240, maxWidth: 260 }}
        onChange={(e) => onChange({ target: { name: 'baseThreshold', value: e.target.value } })}>
        {baseWithThresholds.map((base) => (
          <MenuItem key={base.value} value={base.value}>
            {base.name}
          </MenuItem>
        ))}
      </SelectField>
      <TextField
        hintText="Ilośc dni"
        floatingLabelText="dniowa"
        floatingLabelFixed={false}
        value={deduction.threshold || ''}
        disabled={deduction.base === 'month'}
        name="thresholdInput"
        sx={{ mr: 1, minWidth: 70, maxWidth: 70 }}
        onChange={(e) => onChange({ target: { name: 'thresholdInput', value: e.target.value } })}
      />
      <SelectField
        contrast
        name="mode"
        value={deduction.mode}
        hintText="Modyfikuje kwotę o..."
        fullWidth
        floatingLabelFixed={true}
        floatingLabelText=" "
        sx={{ mr: 1, minWidth: 220, maxWidth: 220 }}
        onChange={(e) => onChange({ target: { name: 'mode', value: e.target.value } })}>
        {Object.values(ChargingDeductionModes).map((mode) => (
          <MenuItem key={mode.value} value={mode.value}>
            {deduction.base === ChargingDeductionBases.daysInRow.value ||
            deduction.base === ChargingDeductionBases.daysInRowFromStart.value
              ? mode.inRowName
              : mode.name}
          </MenuItem>
        ))}
      </SelectField>
      {deduction.mode === ChargingDeductionModes.reduceByPercent.value ? (
        <TextField
          hintText="Wartość... (%)"
          floatingLabelText="Wartość (%)"
          floatingLabelFixed={false}
          name="percentage"
          value={deduction.percentage || ''}
          sx={{ mr: 1, minWidth: 100, maxWidth: 100 }}
          onChange={onChange}
          onBlur={(e) => {
            onChange({ target: { name: 'percentage', value: tryConvertToNumber(e.target.value) } });
          }}
        />
      ) : (
        <TextField
          hintText="Wartość... (zł)"
          floatingLabelText="Wartość (zł)"
          floatingLabelFixed={false}
          name="amount"
          value={deduction.amount || ''}
          sx={{ mr: 1, minWidth: 100, maxWidth: 100 }}
          onChange={onChange}
          onBlur={(e) => {
            onChange({ target: { name: 'amount', value: tryConvertToNumber(e.target.value) } });
          }}
        />
      )}
      <TextField
        hintText="Do kwoty... (zł)"
        floatingLabelText="Do kwoty (zł)"
        floatingLabelFixed={false}
        value={deduction.maximumAmount || ''}
        name="maximumAmount"
        sx={{ minWidth: 100, maxWidth: 100 }}
        onChange={onChange}
        onBlur={(e) => onChange({ target: { name: 'maximumAmount', value: tryConvertToNumber(e.target.value) } })}
      />
    </Box>
  );
};

DeductionItem.propTypes = {
  baseWithThresholds: PropTypes.array.isRequired,
  deduction: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DeductionItem;
