import { check } from '../../validation/validateHelper';
import {
  HourlyChargingSchemeModel,
  DailyChargingSchemeModel,
  MonthlyChargingSchemeModel
} from './ChargingSchemeModels';
import { ChargingSchemeTypes } from '../../../constants/chargingSchemeTypes';
import { ChargingDeductionModes } from '../../../constants/chargingDeductionModes';
import { BaseModelValidator } from '../../BaseModelValidator';
import { isNumericGreaterThanOrEqual, isNumericBetween } from '../../../utils/numberValidators';
import iban from 'iban';
import moment from 'moment/moment';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';

const AccountCountryCode = 'PL';

const accountNumberValidator = (value, model) => {
  if (!value && model.onlinePaymentsEnabled) return 'Numer rachunku jest wymagany do obsługi wpłat elektronicznych';
  if (value && !iban.isValid(`${AccountCountryCode}${value}`)) return 'Numer rachunku jest nieprawidłowy';
  return null;
};

export class DailyChargingSchemeModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = DailyChargingSchemeModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      rate: (value) => !isNumericGreaterThanOrEqual(value, 0) && 'Wartość nie może być liczbą ujemną',
      absenceReportingDeadlineTime: (value, model) => {
        if (value.isEmpty() && !model.absenceReportingDeadlineDays === undefined) return 'Nie podano godziny';
        return null;
      },
      absenceReportingDeadlineDays: (value, model) => {
        if (!model.absenceReportingDeadlineTime.isEmpty() && value === undefined) return 'Nie podano dnia';
        return null;
      },
      accountNumber: accountNumberValidator,
      activityId: (value, model) => {
        if (check.isEmpty(value) && model.reportType === 'activities') return 'Wybierz zajęcia dodatkowe';
      },
      mealId: (value, model) => {
        if (check.isEmpty(value) && model.reportType === ChargingReportTypes.meals.value) return 'Wybierz posiłek';
      }
    };
  }
}

export class MonthlyChargingSchemeModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = MonthlyChargingSchemeModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      rate: (value) => !isNumericGreaterThanOrEqual(value, 0) && 'Wartość nie może być liczbą ujemną',
      deductions: (value) => {
        if (
          value.some(
            (x) => x.mode !== ChargingDeductionModes.reduceByPercent.value && !isNumericGreaterThanOrEqual(x.amount, 0)
          )
        )
          return 'Wartość rabatu musi być liczbą dodatnią';
        if (
          value.some(
            (x) => x.mode === ChargingDeductionModes.reduceByPercent.value && !isNumericBetween(x.percentage, 0, 100)
          )
        )
          return 'Procentowa wartość rabatu musi być liczbą z zakresu 0 - 100';
        // const deductionsSet = new Set();
        // if (value.some(deduction => deductionsSet.size === deductionsSet.add(`${deduction.base}-${deduction.threshold}`).size)) return 'Założenia rabatów nie mogą się powtarzać';
        return null;
      },
      absenceReportingDeadlineTime: (value, model) => {
        if (!!model.absenceReportingDeadlineDays && value.isEmpty()) return 'Nie podano godziny';
        return null;
      },
      absenceReportingDeadlineDays: (value, model) => {
        if (!model.absenceReportingDeadlineTime.isEmpty() && value === undefined) return 'Nie podano dnia';
        return null;
      },
      accountNumber: accountNumberValidator,
      activityId: (value, model) => {
        if (check.isEmpty(value) && model.reportType === 'activities') return 'Wybierz zajęcia dodatkowe';
      },
      mealId: (value, model) => {
        if (check.isEmpty(value) && model.reportType === ChargingReportTypes.meals.value) return 'Wybierz posiłek';
      }
    };
  }
}

export class HourlyChargingSchemeModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = HourlyChargingSchemeModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      rate: (value) => !isNumericGreaterThanOrEqual(value, 0) && 'Wartość nie może być liczbą ujemną',
      chargingPrecision: (value) => check.isEmpty(value) && 'Nie podano dokładności naliczania',
      nonChargeableFrom: (value, model) => {
        if (value.isEmpty() && !model.nonChargeableTo.isEmpty()) return 'Nie podano czasu rozpoczęcia darmowych godzin';
        if (value.isAfter(model.nonChargeableTo))
          return `Czas rozpoczęcia pracy musi być wcześniejszy niż ${model.nonChargeableTo.toString()}`;
        return null;
      },
      nonChargeableTo: (value, model) => {
        if (value.isEmpty() && !model.nonChargeableFrom.isEmpty())
          return 'Nie podano czasu zakończenia darmowych godzin';
        return null;
      },
      absenceReportingDeadlineTime: (value, model) => {
        if (value.isEmpty() && !model.absenceReportingDeadlineDays === undefined) return 'Nie podano godziny';
        return null;
      },
      absenceReportingDeadlineDays: (value, model) => {
        if (!model.absenceReportingDeadlineTime.isEmpty() && value === undefined) return 'Nie podano dnia';
        return null;
      },
      accountNumber: accountNumberValidator,
      nonChargeableMinutesAmount: (value, model) => {
        if (model.nonChargeableInMinutes && !isNumericGreaterThanOrEqual(value, 0))
          return 'Wartość musi być większa od 0';
        return null;
      }
    };
  }
}

export class ChargingSchemeModelValidator extends BaseModelValidator {
  static createEmpty(schemeTypeValue) {
    if (schemeTypeValue === ChargingSchemeTypes.hourly.value) return new HourlyChargingSchemeModelValidator();
    if (schemeTypeValue === ChargingSchemeTypes.daily.value) return new DailyChargingSchemeModelValidator();
    if (schemeTypeValue === ChargingSchemeTypes.monthly.value) return new MonthlyChargingSchemeModelValidator();
    throw new Error(`schema type ${schemeTypeValue} is not supported`);
  }
}

export class ChildChargingSchemeValidator extends BaseModelValidator {
  constructor() {
    super();
    this.constraints = {
      grantHoursAmount: (value) => {
        if (typeof value === 'number' && !isNumericGreaterThanOrEqual(value, 0))
          return 'Wartość nie może być liczbą ujemną';
        if (value > 24) return 'Niepoprawna wartość';
      },
      grantHourlyRate: (value) => {
        if (typeof value === 'number' && !isNumericGreaterThanOrEqual(value, 0))
          return 'Wartość nie może być liczbą ujemną';
      },
      reliefExpiryDate: (value) => {
        if (value === null) value = new Date();
        if (!moment(value).isValid()) {
          return 'Niepoprawna data';
        }
        if (!moment(value).isSameOrBefore(moment(new Date()).add(2, 'year')))
          return `Wybierz datę wcześniejszą niż ${moment(new Date()).add(2, 'year').format('DD/MM/YYYY')}`;
        if (moment(value).isBefore(moment(new Date()).subtract(1, 'day')))
          return `Data nie może być wcześniejsza niż ${moment(new Date()).format('DD/MM/YYYY')}`;
      }
    };
  }
}
