import React from 'react';
import { Zoom, Alert } from '@mui/material';
import { useFormikContext } from 'formik';
import { useShareFileContext } from '../../context/ShareFileContext';

const ShareFileMobileAlert = () => {
  const { isForbidden } = useShareFileContext();
  const { errors } = useFormikContext();
  const error = errors.atLeastOneItem;
  const isError = !!error || isForbidden;

  if (!isError) return null;
  return (
    <Zoom in={true}>
      <Alert style={{ width: '100%' }} severity="error">
        {isForbidden ? 'Nie posiadasz uprawnień do zarządzania udostępnianiem jednego z wybranych plików' : error}
      </Alert>
    </Zoom>
  );
};

export default ShareFileMobileAlert;
