import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Fade, Typography, Grid } from '@mui/material';
import { alpha } from '@mui/material';

export const usePrivateFileBackdropStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(to bottom right,${theme.palette.color.color7}, ${theme.palette.color.color21})`,
    opacity: 0.5,
    position: 'absolute',
    zIndex: theme.zIndex.mobileStepper,
    top: 0,
    left: 0,
    color: '#fff',
    padding: 60
  },
  icon: {
    fontSize: 80
  },
  text: {
    fontSize: 24,
    marginTop: 20
  }
}));

const PrivateFileBackdrop = ({ isOpen, text }) => {
  const cl = usePrivateFileBackdropStyles({ isMobile: false });
  return (
    <Fade in={isOpen} timeout={1000}>
      <Box className={cl.wrapper}>
        <Grid container alignItems="center" direction="column">
          <LockIcon className={cl.icon} />
          <Typography
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
            className={cl.text}
            align="center">
            {text}
          </Typography>
        </Grid>
      </Box>
    </Fade>
  );
};

PrivateFileBackdrop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default PrivateFileBackdrop;
