import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions/childrenActions';
import { bindActionCreators } from 'redux';
import OpeningBalanceForm from './OpeningBalanceForm';
import DialogForm from '../../forms/DialogForm';
import { OpeningBalance } from '../../../models/children/ChildModels';
import { OpeningBalanceModelValidator } from '../../../models/children/ChildModelsValidator';

class OpeningBalanceDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openingBalance: Object.assign({}, this.props.openingBalance),
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeOpeningBalance = this.handleChangeOpeningBalance.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openingBalance !== this.props.openingBalance) {
      this.handleChangeOpeningBalance(this.props.openingBalance);
    }
  }

  handleChangeOpeningBalance(value) {
    this.setState({
      openingBalance: Object.assign({}, value)
    });
  }

  handleSave() {
    this.props.actions.updateOpeningBalance(this.props.pupilId, this.state.openingBalance);
  }

  handleCancel() {
    this.setState({ openingBalance: Object.assign({}, this.props.openingBalance) });
    this.props.actions.updateOpeningBalanceCancel();
  }

  handleChange(event) {
    const field = event.target.name;
    const openingBalance = new OpeningBalance().assign(this.state.openingBalance);
    _.set(openingBalance, field, event.target.value);
    return this.setState({ openingBalance });
  }

  render() {
    return (
      <DialogForm
        maxWidth="sm"
        header="Bilans otwarcia"
        onSave={() => this.handleSave()}
        onCancel={() => this.handleCancel()}
        isDialogOpen={this.props.ui.isEditing}
        isProcessing={this.props.ui.isProcessing}
        onValidate={() => new OpeningBalanceModelValidator().validate(this.state.openingBalance)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="childDetailsUi.openingBalance"
        saveDisabled={this.props.openingBalance.isLocked}>
        <OpeningBalanceForm
          openingBalance={this.state.openingBalance}
          onChange={this.handleChange}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

OpeningBalanceDialog.propTypes = {
  pupilId: PropTypes.string.isRequired,
  openingBalance: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    pupilId: state.childDetails.id,
    openingBalance: state.childDetails.contract.openingBalance,
    ui: state.childDetailsUi.openingBalance
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpeningBalanceDialog);
