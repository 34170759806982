import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Strong = ({ sx, variant, ...rest }) => (
  <Typography
    component="span"
    variant={variant}
    sx={{ fontWeight: (t) => t.typography.fontWeightBold, display: 'inline', ...sx }}
    {...rest}
  />
);

Strong.propTypes = {
  sx: PropTypes.object,
  variant: PropTypes.string
};

export default Strong;
