import ApiBase, { ROOT_URL } from './ApiBase';

class DuesFkExportApi extends ApiBase {
  static handleArrayParameters(parameters) {
    const result = {};

    Object.keys(parameters).forEach((key) => {
      if (Array.isArray(parameters[key])) {
        result[`${key}[]`] = parameters[key];
      } else {
        result[key] = parameters[key];
      }
    });

    return result;
  }

  static getConfiguration() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/duesExport/fkIntegrationExportSettings`).type('application/json')
    );
  }

  static updateConfiguration(settings) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/duesExport`)
        .send({ ...settings, type: 'fkIntegrationExportSettings' })
        .type('application/json')
    );
  }

  static getExportsHistory() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/fkIntegration`).type('application/json'));
  }

  static runOldExport(parameters) {
    return super.toPromise(
      (agent) => {
        const params = DuesFkExportApi.handleArrayParameters(parameters);
        return agent
          .get(`${ROOT_URL}/reports/download`)
          .query({ reportType: 'detailedPaymentsSummaryPerChild' })
          .query({ reportFormat: 'xls' })
          .query({ dontShowNegativeValues: false })
          .query(params)
          .responseType('blob');
      },
      (r) => r.xhr
    );
  }

  static runExport({ month, type }) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/fkIntegration/export-by-email`).send({ month, type }).type('application/json')
    );
  }
  //
  // static runOldExport(data) {
  //   return super.toPromise(agent =>
  //     agent
  //       .post(`${ROOT_URL}/settlements/exports/export-by-email`)
  //       .send(data)
  //       .type('application/json'));
  // }
}

export default DuesFkExportApi;
