import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import { DiagnosticSchemaSetModel } from './DiagnosticSchemeSetModel';

export class DiagnosticSchemeSetModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = DiagnosticSchemaSetModel;

    this.constraints = {
      title: (value) => check.isEmpty(value) && 'Nie podano nazwy.'
    };
  }
}
