import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import LabelValue from '../LabelValue';
import PersonAvatar from '../../forms/PersonAvatar';
import { FormGroup, Typography, Box } from '@mui/material';
import SummaryCheckbox from './guardians/SummaryCheckbox';
import { useSelector } from 'react-redux';

const ChildrenDetailsSummary = ({ pupil }) => {
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex' }}>
        <PersonAvatar
          initials
          alt={pupil.firstName}
          url={pupil.photoUrl}
          firstName={pupil.firstName}
          lastName={pupil.lastName}
        />
        <Typography
          variant="h6"
          display="flex"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            justifyContent: 'flex-start',
            alignItems: 'center',
            ml: 1
          }}>
          {pupil.firstName} {pupil.secondName} {pupil.lastName}
        </Typography>
      </Box>
      <Typography variant="subtitle1" sx={{ pt: 3, fontWeight: (theme) => theme.typography.fontWeightBold }}>
        Dane personalne
      </Typography>
      <LabelValue label="Pesel" value={pupil.personalId} key="pesel" />
      {!nadarzyn && (
        <>
          <LabelValue
            label="Identyfikator dziecka Symfonia"
            value={pupil.childIntegrationId}
            key="Identyfikator dziecka Symfonia"
          />
          <LabelValue
            label="Dodatkowy identyfikator dziecka Symfonia"
            value={pupil.additionalChildIntegrationId}
            key="Dodatkowy identyfikator dziecka Symfonia"
          />
        </>
      )}
      <LabelValue label="Data urodzenia" value={moment(pupil.birthDate).format('DD.MM.YYYY')} key="Data urodzenia" />
      {/* <LabelValue
        label="Data imienin"
        value={pupil.nameDayDate ? moment(pupil.nameDayDate).format('DD.MM.YYYY') : null}
        key="Data imienin"
      /> */}
      <LabelValue
        label="Adres"
        value={`${pupil.address.address}, ${pupil.address.zipCode} ${pupil.address.city}`}
        key="Adres"
      />
      <Typography variant="subtitle1" sx={{ pt: 3, fontWeight: (theme) => theme.typography.fontWeightBold }}>
        Zadeklarowane godziny obecności
      </Typography>

      <Box key="godziny obecnosci">
        {pupil.declaredDropOffAt.isEmpty()
          ? 'dla dziecka nie zostały zadeklarowane godziny obecności'
          : `od ${pupil.declaredDropOffAt.toString()} do ${pupil.declaredPickUpAt.toString()}`}
      </Box>
      {!nadarzyn && (
        <>
          <Typography variant="subtitle1" sx={{ pt: 3, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Dodatkowe
          </Typography>
          {pupil.notes.map((note) => (
            <LabelValue key={note.key} label={note.key} value={note.value} />
          ))}
          <Typography variant="subtitle1" sx={{ pt: 3, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Zaświadczenia
          </Typography>
          <FormGroup>
            {pupil.opinions.map((opinion) => (
              <SummaryCheckbox
                disabled={true}
                label={opinion.key}
                variant="body2"
                key={opinion.key}
                value={opinion.value}
              />
            ))}
          </FormGroup>
          <Typography variant="subtitle1" sx={{ pt: 3, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Zgody
          </Typography>
          <FormGroup>
            {pupil.agreements.map((agreement) => (
              <SummaryCheckbox
                disabled={true}
                label={agreement.key}
                variant="body2"
                key={agreement.key}
                value={agreement.value}
              />
            ))}
          </FormGroup>
        </>
      )}
    </Box>
  );
};

ChildrenDetailsSummary.propTypes = {
  pupil: PropTypes.object.isRequired
};

export default ChildrenDetailsSummary;
