import PropTypes from 'prop-types';
import React from 'react';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Box, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const BoxStyle = {
  fontSize: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 95,
  m: 1.5,
  p: -0.5,
  borderRadius: 2,
  height: 100,
  color: (theme) => theme.palette.color.primary,
  border: (theme) => `1px solid ${theme.palette.color.primary} `,
  '&:active': { background: (theme) => theme.palette.text.primary }
};

const Keypad = ({ onNumberPress, onUndo, disabled, onClick }) => {
  return (
    <Box justifyContent="center" alignItems="center" direction="column">
      <Box sx={{ display: 'flex' }}>
        <Box sx={BoxStyle} onClick={() => onNumberPress(1)}>
          1
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(2)}>
          2
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(3)}>
          3
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={BoxStyle} onClick={() => onNumberPress(4)}>
          4
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(5)}>
          5
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(6)}>
          6
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={BoxStyle} onClick={() => onNumberPress(7)}>
          7
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(8)}>
          8
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(9)}>
          9
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box
          disabled={disabled}
          sx={BoxStyle}
          onClick={() => {
            onClick();
          }}>
          {disabled ? null : (
            <CheckIcon
              sx={{
                color: (theme) => theme.palette.color.primary,
                fontSize: 54
              }}
            />
          )}
        </Box>
        <Box sx={BoxStyle} onClick={() => onNumberPress(0)}>
          0
        </Box>
        <Box sx={BoxStyle} onClick={onUndo}>
          <BackspaceIcon sx={{ color: (theme) => theme.palette.color.primary, fontSize: 34 }} />
        </Box>
      </Box>
    </Box>
  );
};

Keypad.propTypes = {
  onNumberPress: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired
};

export default Keypad;
