/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function messageBoardUiReducer(state = initialState.configuration.importEntranceUi, action) {
  switch (action.type) {
    //create
    case types.IMPORT_ENTRANCE_START: {
      return UiModel.start();
    }
    case types.IMPORT_ENTRANCE_CANCEL: {
      return UiModel.cancel();
    }
    case types.IMPORT_ENTRANCE_SUBMIT: {
      return UiModel.submit();
    }
    case types.IMPORT_ENTRANCE_SUCCESS: {
      return UiModel.success();
    }
    case types.IMPORT_ENTRANCE_FAILURE: {
      return UiModel.failure(action.errors);
    }
    default:
      return state;
  }
}
