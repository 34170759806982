'use strict';

import PropTypes from 'prop-types';
import React from 'react';

import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import numberFormatter from '../../../utils/numberFormatter';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';

const PaymentRebate = ({ paymentRebate }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <MoneyOffIcon />
      </ListItemAvatar>
      <ListItemText
        primary={paymentRebate.description}
        secondary={`${numberFormatter.format(paymentRebate.amount)} zł`}
      />
    </ListItem>
  );
};
PaymentRebate.propTypes = {
  paymentRebate: PropTypes.object.isRequired
};

export default PaymentRebate;
