import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { ChildDetailsModel } from '../../../models/children/ChildModels';
import iban from 'iban';
import ChildDetailsFormPersonalData from './components/ChildDetailsFormPersonalData';
import ChildDetailsFormHoursOfPresence from './components/ChildDetailsFormHoursOfPresence';
import ChildDetailsFormAddress from './components/ChildDetailsFormAddress';
import ChildDetailsFormAdditionalInformation from './components/ChildDetailsFormAdditionalInformation';
import ChildDetailsFormOpinions from './components/ChildDetailsFormOpinions';
import ChildDetailsFormAgreements from './components/ChildDetailsFormAgreements';
import ChildDetailsFormAttributes from './components/ChildDetailsFormAttributes';
import SaveCancelButtons from '../../forms/buttons/SaveCancelButtons';
import {
  List,
  ListItem,
  Collapse,
  ListItemText,
  Paper,
  DialogActions,
  Typography,
  ListItemButton
} from '@mui/material';
import Pesel from '../../../utils/Pesel';
import { TimeModel } from '../../../models/TimeModel';
import { InitialValues } from '../../../models/children/ChildDetailsFormInitialValues';
import userRoles from '../../../constants/userRoles';
import FormikDisabler from '../../common/FormikDisabler';

const ChildDetailsFormRefactored = ({ pupil, symphony, isInitiallyExpanded = false, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);
  const [inProgress, setInProgress] = useState(false);
  const individualBankAccounts = useSelector((state) => state.configuration.unit.individualBankAccounts);
  const teacherReadonlyState = useSelector((state) => state.configuration.unit.editingChildDataByTeacherBlocked);
  const auth = useSelector((state) => state.auth);
  const formIsEditable = useMemo(() => {
    if (
      auth.userRole === userRoles.staffMemberPrincipal ||
      auth.userRole === userRoles.staffMemberAccountant ||
      auth.userRole === userRoles.staffMemberSecretary
    )
      return true;
    if (auth.userRole === userRoles.staffMemberTeacher) return !teacherReadonlyState;
    return false;
  }, [auth.userRole, teacherReadonlyState]);
  const AccountCountryCode = 'PL';

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    history.back();
  };

  const handleSubmit = async (values, actions) => {
    setInProgress(true);
    const childDetails = Object.assign(new ChildDetailsModel().assign(values), {
      declaredDropOffAt: new TimeModel().fromMoment(values.declaredDropOffAt),
      declaredPickUpAt: new TimeModel().fromMoment(values.declaredPickUpAt)
    });
    await onSubmit(childDetails, actions);
    setInProgress(false);
  };

  const initValues = Object.assign(new InitialValues().assign(pupil), {
    declaredDropOffAt: pupil.declaredDropOffAt.toDate(),
    declaredPickUpAt: pupil.declaredPickUpAt.toDate()
  });

  const VALIDATION_SCHEMA = Yup.object().shape({
    firstName: Yup.string().trim().required('Pole wymagane'),
    secondName: Yup.string().trim(),
    lastName: Yup.string().trim().required('Pole wymagane'),
    birthPlace: Yup.string().trim(),
    declaredDropOffAt: Yup.date().nullable(),
    declaredPickUpAt: Yup.date()
      .nullable()
      .min(Yup.ref('declaredDropOffAt'), 'Godzina odbioru musi być późniejsza niż godzina przyjęcia'),
    address: Yup.object().shape({
      address: Yup.string().trim().required('Pole wymagane'),
      zipCode: Yup.string()
        .matches('^[0-9]{2}-[0-9]{3}$', 'Podano nieprawidłowy kod pocztowy')
        .required('Pole wymagane'),
      city: Yup.string().trim().required('Pole wymagane')
    }),
    registeredAddress: Yup.object().shape(
      {
        address: Yup.string().trim(),
        zipCode: Yup.string().when(['zipCode'], {
          is: (code) => code && code !== undefined && code.length > 0,
          then: Yup.string().matches('^[0-9]{2}-[0-9]{3}$', 'Podano nieprawidłowy kod pocztowy')
        }),
        city: Yup.string().trim()
      },
      ['zipCode', 'zipCode']
    ),
    personalId: Yup.string().trim().required('Pole wymagane'),
    educationStartDate: Yup.date().nullable(),
    educationEndDate: Yup.date()
      .nullable()
      .min(
        Yup.ref('educationStartDate'),
        'Data zakończenia edukacji musi być datą późniejszą niż data rozpoczęcia edukacji'
      )
  });

  const validate = (values) => {
    const errors = {};
    const { stayBankAccount, cateringBankAccount, otherBankAccount, personalId, personalIdType } = values;
    if (stayBankAccount && !iban.isValid(`${AccountCountryCode}${stayBankAccount}`))
      errors.stayBankAccount = 'Podano nieprawidłowy numer konta';
    if (cateringBankAccount && !iban.isValid(`${AccountCountryCode}${cateringBankAccount}`))
      errors.cateringBankAccount = 'Podano nieprawidłowy numer konta';
    if (otherBankAccount && !iban.isValid(`${AccountCountryCode}${otherBankAccount}`))
      errors.otherBankAccount = 'Podano nieprawidłowy numer konta';
    if (personalIdType === 'pesel') {
      const pesel = new Pesel(personalId);
      if (!pesel.isValid()) errors.personalId = 'Podano nieprawidłowy numer ewidencyjny';
    }
    return errors;
  };
  return (
    <Formik initialValues={initValues} validationSchema={VALIDATION_SCHEMA} validate={validate} onSubmit={handleSubmit}>
      {({ values, isValid }) => {
        return (
          <Form sx={{ mb: 2 }}>
            <FormikDisabler disabled={!formIsEditable} />
            <Paper sx={{ my: 2, p: 1 }} elevation={1}>
              <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemButton sx={{ px: 2, py: 1 }} onClick={() => handleOpen()}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                        Formularz informacyjny
                      </Typography>
                    }
                  />
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={isOpen}>
                <List>
                  <ChildDetailsFormPersonalData individualBankAccounts={individualBankAccounts} symphony={symphony} />
                  <ChildDetailsFormHoursOfPresence />
                  <ChildDetailsFormAddress />
                  <ChildDetailsFormAdditionalInformation pupil={pupil} />
                  <ChildDetailsFormOpinions values={values} />
                  <ChildDetailsFormAgreements values={values} />
                  <ChildDetailsFormAttributes values={values} />
                  <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography component="span" sx={{ pl: 2 }} variant="subtitle1">
                      * - Pola wymagane
                    </Typography>
                    {formIsEditable && (
                      <SaveCancelButtons
                        saveLabel="Zapisz"
                        cancelLabel="Anuluj"
                        onCancel={onClose}
                        inProgress={inProgress}
                        type="submit"
                        saveDisabled={inProgress || !isValid}
                      />
                    )}
                  </DialogActions>
                </List>
              </Collapse>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

ChildDetailsFormRefactored.propTypes = {
  pupil: PropTypes.object.isRequired,
  symphony: PropTypes.bool.isRequired,
  isInitiallyExpanded: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

export default ChildDetailsFormRefactored;
