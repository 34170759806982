import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as unitActions from '../../../actions/unitActions';
import DescriptionIcon from '@mui/icons-material/Description';
import ElectronicDiaryIcon from '@mui/icons-material/LibraryBooks';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ApartmentIcon from '@mui/icons-material/Apartment';

import { Divider, Box, Typography, MenuItem, Button, Paper, IconButton, Grid } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import UnitAddressDialog from './dialogs/UnitAddressDialog';
import UnitWorkingHoursDialog from './dialogs/UnitWorkingHoursDialog';
import UnitPenaltiesDialog from './dialogs/UnitPenaltiesDialog';
import Strong from '../../common/Strong';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import { afterHoursChargingBase } from '../../../constants/afterHoursChargingBase';
import UnitAbsenceRemovalDeadlineDialog from './dialogs/UnitAbsenceRemovalDeadlineDialog';
import UnitListItem from './UnitListItem';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import PublisherApi from '../../../api/PublisherApi';
import { UnitListElement } from './UnitListElement';
import SelectField from '../../common/SelectField';
import ContentWrapper from '../../common/contentWrapper';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

class UnitPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { twoWayMessagingUpdateInProgress: false };
    this.state = { reportAbsenceInDaysOffUpdateInProgress: false };
    this.state = { postsModifyingEnabledInProgress: false };
    this.state = { individualBankAccountsInProgress: false };
    this.state = { childrenAgeInfoEnabledInProgress: false };
    this.state = { sendingChildPickedUpNotificationBlockedInProgress: false };
    this.state = { editingChildDataByTeacherBlockedInProgress: false };
    this.state = { addContractNumberToSettlementNumberInProgress: false };
    // this.state = { notificationSendingEnabledInProgress: false };
    this.state = { teacherAllGroupVisibilityInProgress: false };
    this.state = { teacherAllBoardPostsVisibilityInProgress: false };
    this.state = { legalGuardianPartOfBoardPostsVisibilityInProgress: false };

    this.state = { publishers: [] };
    this.state = { selectedPublisher: '' };
    this.state = { sendingOverduesNotificationInProgress: false };

    this.state = { editingChildDataByTeacherBlockedInProgress: false };
    this.state = { editingMealsCateringDuesInProgress: false };
    this.state = { editingAllowDebtCollection: false };
    this.state = { editingAllowAdaptation: false };

    this.isRemoveClicked = false;

    this.selectedCategory = null;
    this.categoryHeaders = [
      { name: 'Wszystkie', icon: <DescriptionIcon sx={{ fontSize: 60 }} /> },
      { name: 'Placówka', icon: <ApartmentIcon sx={{ fontSize: 60 }} /> },
      { name: 'Obecności', icon: <ElectronicDiaryIcon sx={{ fontSize: 60 }} /> },
      { name: 'Rozliczenia', icon: <AttachMoneyIcon sx={{ fontSize: 60 }} /> }
    ];

    PublisherApi.getPublishers().then((response) => this.setState({ publishers: response }));

    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleSaveAddress = this.handleSaveAddress.bind(this);
    this.handleCancelAddress = this.handleCancelAddress.bind(this);

    this.handleEditWorkingHours = this.handleEditWorkingHours.bind(this);
    this.handleSaveWorkingHours = this.handleSaveWorkingHours.bind(this);
    this.handleCancelWorkingHours = this.handleCancelWorkingHours.bind(this);

    this.handleEditPenalties = this.handleEditPenalties.bind(this);
    this.handleSavePenalties = this.handleSavePenalties.bind(this);
    this.handleCancelPenalties = this.handleCancelPenalties.bind(this);
    this.handlerEnableTwoWayMessaging = this.handlerEnableTwoWayMessaging.bind(this);
    this.handlerDisableTwoWayMessaging = this.handlerDisableTwoWayMessaging.bind(this);

    this.handlerEnableReportingAbsenceInDaysOff = this.handlerEnableReportingAbsenceInDaysOff.bind(this);
    this.handlerDisableReportingAbsenceInDaysOff = this.handlerDisableReportingAbsenceInDaysOff.bind(this);

    this.handlEditAbsenceRemovalDeadline = this.handlEditAbsenceRemovalDeadline.bind(this);
    this.handleSaveAbsenceRemovalDeadline = this.handleSaveAbsenceRemovalDeadline.bind(this);
    this.handleCancelAbsenceRemovalDeadline = this.handleCancelAbsenceRemovalDeadline.bind(this);
    this.handlerDisableNoHoursAttendance = this.handlerDisableNoHoursAttendance.bind(this);
    this.handlerEnableNoHoursAttendance = this.handlerEnableNoHoursAttendance.bind(this);

    this.handleEnablePostsModifying = this.handleEnablePostsModifying.bind(this);
    this.handleDisablePostsModifying = this.handleDisablePostsModifying.bind(this);

    this.handleEnableIndividualBankAccounts = this.handleEnableIndividualBankAccounts.bind(this);
    this.handleDisableIndividualBankAccounts = this.handleDisableIndividualBankAccounts.bind(this);

    this.handleEnableAddContractNumberToSettlementNumber =
      this.handleEnableAddContractNumberToSettlementNumber.bind(this);
    this.handleDisableAddContractNumberToSettlementNumber =
      this.handleDisableAddContractNumberToSettlementNumber.bind(this);

    this.handleEnableChildrenAgeInfo = this.handleEnableChildrenAgeInfo.bind(this);
    this.handleDisableChildrenAgeInfo = this.handleDisableChildrenAgeInfo.bind(this);

    this.handleEnableAllGroupsAccess = this.handleEnableAllGroupsAccess.bind(this);
    this.handleDisableAllGroupsAccess = this.handleDisableAllGroupsAccess.bind(this);
    this.handlePublisherChange = this.handlePublisherChange.bind(this);

    this.handleEnableAllBoardPostsVisibility = this.handleEnableAllBoardPostsVisibility.bind(this);
    this.handleDisableAllBoardPostsVisibility = this.handleDisableAllBoardPostsVisibility.bind(this);

    this.handleEnableLegalGuardianBoardPostsVisibility = this.handleEnableLegalGuardianBoardPostsVisibility.bind(this);
    this.handleDisableLegalGuardianBoardPostsVisibility =
      this.handleDisableLegalGuardianBoardPostsVisibility.bind(this);
    this.handleEnableSendingOverduesNotification = this.handleEnableSendingOverduesNotification.bind(this);
    this.handleDisableSendingOverduesNotification = this.handleDisableSendingOverduesNotification.bind(this);

    this.handleEnableSendingChildPickedUpNotificationBlocked =
      this.handleEnableSendingChildPickedUpNotificationBlocked.bind(this);
    this.handleDisableSendingChildPickedUpNotificationBlocked =
      this.handleDisableSendingChildPickedUpNotificationBlocked.bind(this);

    this.handleEnableEditingChildDataByTeacherBlocked = this.handleEnableEditingChildDataByTeacherBlocked.bind(this);
    this.handleDisableEditingChildDataByTeacherBlocked = this.handleDisableEditingChildDataByTeacherBlocked.bind(this);

    this.handleEnableMealsCateringDues = this.handleEnableMealsCateringDues.bind(this);
    this.handleDisableMealsCateringDues = this.handleDisableMealsCateringDues.bind(this);

    this.handleEnableAllowDebtCollection = this.handleEnableAllowDebtCollection.bind(this);
    this.handleDisableAllowDebtCollection = this.handleDisableAllowDebtCollection.bind(this);

    this.handleEnableAllowAdaptation = this.handleEnableAllowAdaptation.bind(this);
    this.handleDisableAllowAdaptation = this.handleDisableAllowAdaptation.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    if (!this.state.selectedPublisher && this.props.defaultPublishingHouseId && !this.isRemoveClicked) {
      PublisherApi.getCourseBookByPublisherId(this.props.defaultPublishingHouseId).then((response) => {
        this.isRemoveClicked = false;
        this.setState({ selectedPublisher: response.name });
      });
    }
  }

  handlerEnableTwoWayMessaging() {
    this.setState({ twoWayMessagingUpdateInProgress: true });
    this.props.actions
      .updateTwoWayMessagingSettingAsync(true)
      .finally(() => this.setState({ twoWayMessagingUpdateInProgress: false }));
  }

  handlerDisableTwoWayMessaging() {
    this.setState({ twoWayMessagingUpdateInProgress: true });
    this.props.actions
      .updateTwoWayMessagingSettingAsync(false)
      .finally(() => this.setState({ twoWayMessagingUpdateInProgress: false }));
  }

  handlerEnableReportingAbsenceInDaysOff() {
    this.setState({ reportAbsenceInDaysOffUpdateInProgress: true });
    this.props.actions
      .updateReportAbsenceInDaysOffAsync(true)
      .finally(() => this.setState({ reportAbsenceInDaysOffUpdateInProgress: false }));
  }

  handlerDisableReportingAbsenceInDaysOff() {
    this.setState({ reportAbsenceInDaysOffUpdateInProgress: true });
    this.props.actions
      .updateReportAbsenceInDaysOffAsync(false)
      .finally(() => this.setState({ reportAbsenceInDaysOffUpdateInProgress: false }));
  }

  handlerDisableNoHoursAttendance() {
    this.setState({ noHoursAttendancesUpdateInProgress: true });
    this.props.actions
      .updateNoHoursAttendanceAsync(false)
      .finally(() => this.setState({ noHoursAttendancesUpdateInProgress: false }));
  }

  handlerEnableNoHoursAttendance() {
    this.setState({ noHoursAttendancesUpdateInProgress: true });
    this.props.actions
      .updateNoHoursAttendanceAsync(true)
      .finally(() => this.setState({ noHoursAttendancesUpdateInProgress: false }));
  }

  handleEnablePostsModifying() {
    this.setState({ postsModifyingEnabledInProgress: true });
    this.props.actions
      .postsModifyingEnabledAsync(false)
      .finally(() => this.setState({ postsModifyingEnabledInProgress: false }));
  }

  handleDisablePostsModifying() {
    this.setState({ postsModifyingEnabledInProgress: true });
    this.props.actions
      .postsModifyingEnabledAsync(true)
      .finally(() => this.setState({ postsModifyingEnabledInProgress: false }));
  }

  handleEnableIndividualBankAccounts() {
    this.setState({ individualBankAccountsInProgress: true });
    this.props.actions
      .individualBankAccountsAsync(false)
      .finally(() => this.setState({ individualBankAccountsInProgress: false }));
  }

  handleDisableIndividualBankAccounts() {
    this.setState({ individualBankAccountsInProgress: true });
    this.props.actions
      .individualBankAccountsAsync(true)
      .finally(() => this.setState({ individualBankAccountsInProgress: false }));
  }

  handleEnableAddContractNumberToSettlementNumber() {
    this.setState({ addContractNumberToSettlementNumberInProgress: true });
    this.props.actions
      .addContractNumberToSettlementNumberAsync(false)
      .finally(() => this.setState({ addContractNumberToSettlementNumberInProgress: false }));
  }

  handleDisableAddContractNumberToSettlementNumber() {
    this.setState({ addContractNumberToSettlementNumberInProgress: true });
    this.props.actions
      .addContractNumberToSettlementNumberAsync(true)
      .finally(() => this.setState({ addContractNumberToSettlementNumberInProgress: false }));
  }

  handleEnableChildrenAgeInfo() {
    this.setState({ childrenAgeInfoEnabledInProgress: true });
    this.props.actions
      .childrenAgeInfoEnabledAsync(false)
      .finally(() => this.setState({ childrenAgeInfoEnabledInProgress: false }));
  }

  handleDisableChildrenAgeInfo() {
    this.setState({ childrenAgeInfoEnabledInProgress: true });
    this.props.actions
      .childrenAgeInfoEnabledAsync(true)
      .finally(() => this.setState({ childrenAgeInfoEnabledInProgress: false }));
  }

  handleEnableSendingChildPickedUpNotificationBlocked() {
    this.setState({ sendingChildPickedUpNotificationBlockedInProgress: true });
    this.props.actions
      .sendingChildPickedUpNotificationBlockedAsync(false)
      .finally(() => this.setState({ sendingChildPickedUpNotificationBlockedInProgress: false }));
  }

  handleDisableSendingChildPickedUpNotificationBlocked() {
    this.setState({ sendingChildPickedUpNotificationBlockedInProgress: true });
    this.props.actions
      .sendingChildPickedUpNotificationBlockedAsync(true)
      .finally(() => this.setState({ sendingChildPickedUpNotificationBlockedInProgress: false }));
  }

  handleEnableEditingChildDataByTeacherBlocked() {
    this.setState({ editingChildDataByTeacherBlockedInProgress: true });
    this.props.actions
      .editingChildDataByTeacherBlockedAsync(false)
      .finally(() => this.setState({ editingChildDataByTeacherBlockedInProgress: false }));
  }

  handleDisableEditingChildDataByTeacherBlocked() {
    this.setState({ editingChildDataByTeacherBlockedInProgress: true });
    this.props.actions
      .editingChildDataByTeacherBlockedAsync(true)
      .finally(() => this.setState({ editingChildDataByTeacherBlockedInProgress: false }));
  }

  handleEnableMealsCateringDues() {
    this.setState({ editingMealsCateringDuesInProgress: true });
    this.props.actions
      .mealsCateringDues(false)
      .finally(() => this.setState({ editingMealsCateringDuesInProgress: false }));
  }

  handleDisableMealsCateringDues() {
    this.setState({ editingMealsCateringDuesInProgress: true });
    this.props.actions
      .mealsCateringDues(true)
      .finally(() => this.setState({ editingMealsCateringDuesInProgress: false }));
  }

  handleEnableAllowDebtCollection() {
    this.setState({ editingAllowDebtCollection: true });
    this.props.actions.allowDebtCollection(false).finally(() => this.setState({ editingAllowDebtCollection: false }));
  }

  handleDisableAllowDebtCollection() {
    this.setState({ editingAllowDebtCollection: true });
    this.props.actions.allowDebtCollection(true).finally(() => this.setState({ editingAllowDebtCollection: false }));
  }

  handleEnableAllowAdaptation() {
    this.setState({ editingAllowAdaptation: true });
    this.props.actions.allowAdaptation(false).finally(() => this.setState({ editingAllowAdaptation: false }));
  }

  handleDisableAllowAdaptation() {
    this.setState({ editingAllowAdaptation: true });
    this.props.actions.allowAdaptation(true).finally(() => this.setState({ editingAllowAdaptation: false }));
  }

  handleEnableAllGroupsAccess() {
    this.setState({ teacherAllGroupVisibilityInProgress: true });
    this.props.actions
      .teacherGropuAccessAsync(true)
      .finally(() => this.setState({ teacherAllGroupVisibilityInProgress: false }));
  }

  handleDisableAllGroupsAccess() {
    this.setState({ teacherAllGroupVisibilityInProgress: true });
    this.props.actions
      .teacherGropuAccessAsync(false)
      .finally(() => this.setState({ teacherAllGroupVisibilityInProgress: false }));
  }

  handleEnableAllBoardPostsVisibility() {
    this.setState({ teacherAllBoardPostsVisibilityInProgress: true });
    this.props.actions
      .teacherPostsAccessAsync(true)
      .finally(() => this.setState({ teacherAllBoardPostsVisibilityInProgress: false }));
  }

  handleDisableAllBoardPostsVisibility() {
    this.setState({ teacherAllBoardPostsVisibilityInProgress: true });
    this.props.actions
      .teacherPostsAccessAsync(false)
      .finally(() => this.setState({ teacherAllBoardPostsVisibilityInProgress: false }));
  }

  handleEnableLegalGuardianBoardPostsVisibility() {
    this.setState({ legalGuardianPartOfBoardPostsVisibilityInProgress: true });
    this.props.actions
      .legalGuardianPostsAccessAsync(true)
      .finally(() => this.setState({ legalGuardianPartOfBoardPostsVisibilityInProgress: false }));
  }

  handleDisableLegalGuardianBoardPostsVisibility() {
    this.setState({ legalGuardianPartOfBoardPostsVisibilityInProgress: true });
    this.props.actions
      .legalGuardianPostsAccessAsync(false)
      .finally(() => this.setState({ legalGuardianPartOfBoardPostsVisibilityInProgress: false }));
  }

  handleEnableSendingOverduesNotification() {
    this.setState({ sendingOverduesNotificationInProgress: true });
    this.props.actions
      .sendingOverduesNotificationAsync(true)
      .finally(() => this.setState({ sendingOverduesNotificationInProgress: false }));
  }

  handleDisableSendingOverduesNotification() {
    this.setState({ sendingOverduesNotificationInProgress: true });
    this.props.actions
      .sendingOverduesNotificationAsync(false)
      .finally(() => this.setState({ sendingOverduesNotificationInProgress: false }));
  }

  handleEditAddress() {
    this.props.actions.updateUnitAddressStart();
  }

  handleSaveAddress(address) {
    this.props.actions.updateUnitAddressAsync(address);
  }

  handleCancelAddress() {
    this.props.actions.updateUnitAddressCancel();
  }

  handleEditWorkingHours() {
    this.props.actions.updateUnitWorkingHoursStart();
  }

  handleSaveWorkingHours(workingHours) {
    this.props.actions.updateUnitWorkingHoursAsync(workingHours);
  }

  handleCancelWorkingHours() {
    this.props.actions.updateUnitWorkingHoursCancel();
  }

  handleEditPenalties() {
    this.props.actions.updateUnitPenaltiesStart();
  }

  handleSavePenalties(penalties) {
    this.props.actions.updateUnitPenaltiesAsync(penalties);
  }

  handleCancelPenalties() {
    this.props.actions.updateUnitPenaltiesCancel();
  }

  handleSaveAbsenceRemovalDeadline(value) {
    this.props.actions.updateUnitAbsenceRemovalDeadlineAsync(value);
  }

  handleCancelAbsenceRemovalDeadline() {
    this.props.actions.updateUnitAbsenceRemovalDeadlineCancel();
  }

  handlePublisherChange(event) {
    this.setState({ selectedPublisher: event.target.value });
  }

  handleCategoryChange(name) {
    this.setState(() => ({
      selectedCategory: name
    }));
  }

  handlEditAbsenceRemovalDeadline() {
    this.props.actions.updateUnitAbsenceRemovalDeadlineStart();
  }

  renderAddressEditDialog() {
    if (this.props.addressUi.isEditing) {
      return (
        <UnitAddressDialog
          onSave={this.handleSaveAddress}
          onCancel={this.handleCancelAddress}
          isDialogOpen={this.props.addressUi.isEditing}
          isProcessing={this.props.addressUi.isProcessing}
          address={this.props.unit.address}
        />
      );
    }
    return null;
  }

  renderWorkingHoursEditDialog() {
    if (this.props.workingHoursUi.isEditing) {
      return (
        <UnitWorkingHoursDialog
          onSave={this.handleSaveWorkingHours}
          onCancel={this.handleCancelWorkingHours}
          isDialogOpen={this.props.workingHoursUi.isEditing}
          isProcessing={this.props.workingHoursUi.isProcessing}
          workingHours={this.props.unit.workingHours}
        />
      );
    }
    return null;
  }

  renderPenaltiesSettingsEditDialog() {
    if (this.props.penaltiesUi.isEditing) {
      return (
        <UnitPenaltiesDialog
          onSave={this.handleSavePenalties}
          onClear={this.handleSavePenalties}
          onCancel={this.handleCancelPenalties}
          isDialogOpen={this.props.penaltiesUi.isEditing}
          isProcessing={this.props.penaltiesUi.isProcessing}
          penalties={this.props.unit.penalties}
        />
      );
    }
    return null;
  }

  renderAbsenceRemovalDeadlineEditDialog() {
    if (this.props.absenceReportingDeadlineUi.isEditing) {
      return (
        <UnitAbsenceRemovalDeadlineDialog
          onSave={this.handleSaveAbsenceRemovalDeadline}
          onCancel={this.handleCancelAbsenceRemovalDeadline}
          isDialogOpen={this.props.absenceReportingDeadlineUi.isEditing}
          isProcessing={this.props.absenceReportingDeadlineUi.isProcessing}
          legalGuardiansAbsenceRemovalDeadlineHours={this.props.unit.legalGuardiansAbsenceRemovalDeadlineHours}
        />
      );
    }
    return null;
  }

  renderChips(categories) {
    return categories.map((category) => (
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <IconButton
          key={category.name}
          onClick={() => this.handleCategoryChange(category.name)}
          sx={{
            width: '100%',
            height: 133,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: (theme) => theme.palette.background.default,
            '&:hover': {
              background: (theme) => theme.palette.background.hover
            },
            borderRadius: '20px'
          }}>
          {category.icon}
          <Strong>{category.name}</Strong>
        </IconButton>
      </Grid>
    ));
  }

  renderAll() {
    return (
      <Box>
        {this.renderUnit()}
        {this.renderAttendance()}
        {this.renderSettlements()}
      </Box>
    );
  }

  renderUnit() {
    return (
      <Paper sx={{ p: { xs: 2.5, md: 6 }, my: 2 }}>
        <UnitListItem sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Dane placówki
          </Typography>
          <Typography sx={{ display: 'inline-block', wordBreak: 'break-word' }}>
            {this.props.unit.address.name}
            <br />
            {this.props.unit.address.address}
            <br />
            {this.props.unit.address.zipCode} {this.props.unit.address.city}
            <br />
            Numer telefonu: {this.props.unit.address.phoneNumber || 'nie podano'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 4 }}>
              <Typography>
                Województwo:
                <br />
                Gmina:
                <br />
                Numer NIP:
                <br />
                Numer identyfikacyjny placówki:
              </Typography>
            </Box>
            <Box>
              <Strong>{this.props.unit.address.voivodeship || 'nie podano'}</Strong>
              <br />
              <Strong>{this.props.unit.address.community || 'nie podano'}</Strong>
              <br />
              <Strong>{this.props.unit.address.nip || 'nie podano'}</Strong>
              <br />
              <Strong>{this.props.unit.address.facilityNumber}</Strong>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={this.handleEditAddress} variant="outlinedContrast">
              Edytuj dane
            </Button>
          </Box>
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <Typography variant="h6">Godziny pracy</Typography>
          <Typography>
            Placówka otwarta w godzinach:{' '}
            <strong>
              {this.props.unit.workingHours.from.isEmpty() || this.props.unit.workingHours.to.isEmpty()
                ? 'nie podano'
                : `${this.props.unit.workingHours.from.toString()} - ${this.props.unit.workingHours.to.toString()}`}
            </strong>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" aria-label="Edytuj godziny" onClick={this.handleEditWorkingHours}>
              Edytuj godziny
            </Button>
          </Box>
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <Typography variant="h6">Domyślne wydawnictwo</Typography>
          <Box>
            <SelectField
              contrast
              floatingLabelText="Domyślne wydawnictwo"
              floatingLabelFixed={false}
              sx={{ width: '100%' }}
              value={this.state.selectedPublisher}
              onChange={this.handlePublisherChange}>
              {this.state.publishers
                ? this.state.publishers.map((value) => (
                    <MenuItem key={value.id} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))
                : []}
            </SelectField>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                variant="contained"
                aria-label="Zmień"
                onClick={() => {
                  const publisher = this.state.publishers.filter((pub) => pub.name === this.state.selectedPublisher);
                  this.props.actions.updateDefaultPublisher(publisher[0].id);
                }}
                sx={{ m: 2, ml: 0 }}>
                Zmień
              </Button>
              <Button
                variant="contained"
                aria-label="Usuń"
                disabled={!this.props.defaultPublishingHouseId}
                onClick={() => {
                  this.isRemoveClicked = true;
                  this.setState({ selectedPublisher: '' });
                  this.props.actions.clearDefaultPublisher();
                }}
                sx={{ m: 2 }}>
                Usuń
              </Button>
            </Box>
          </Box>
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.childrenAgeInfoEnabledInProgress}
            label="Pokazuj informację o ukończeniu wieku 2.5 roku w rejestrze dzieci"
            desc="Pokazywanie informacji o ukończeniu wieku 2.5 roku w rejestrze dzieci jest"
            checked={this.props.unit.childrenAgeInfoEnabled}
            handleEnable={this.handleEnableChildrenAgeInfo}
            handleDisable={this.handleDisableChildrenAgeInfo}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            label="Komunikacja dwukierunkowa"
            desc="Możliwość wysyłania wiadomości bezpośrednich przez opiekunów prawnych jest"
            checked={this.props.unit.twoWayMessagingEnabled}
            handleDisable={this.handlerEnableTwoWayMessaging}
            handleEnable={this.handlerDisableTwoWayMessaging}
            disabled={this.state.twoWayMessagingUpdateInProgress}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.postsModifyingEnabledInProgress}
            label="Możliwość edytowania postów przez każdego pracownika"
            desc="Możliwość edytowania postów innych autorów przez każdego pracownika placówki jest"
            checked={this.props.unit.postsModifyingEnabled}
            handleEnable={this.handleEnablePostsModifying}
            handleDisable={this.handleDisablePostsModifying}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            label="Widoczność wszystkich ogłoszeń przez nauczycieli"
            desc="Dostępy do wszystkich ogłoszeń są"
            checked={this.props.unit.teacherAllBoardPostsVisibility}
            handleDisable={this.handleEnableAllBoardPostsVisibility}
            handleEnable={this.handleDisableAllBoardPostsVisibility}
            disabled={this.state.teacherAllBoardPostsVisibilityInProgress}
          />
          <Divider sx={{ my: 3 }} />
          <UnitListElement
            label="Widoczność wszystkich ogłoszeń przez rodziców"
            desc="Dostępy do wszystkich ogłoszeń są"
            checked={this.props.unit.legalGuardianPartOfBoardPostsVisibility}
            handleDisable={this.handleEnableLegalGuardianBoardPostsVisibility}
            handleEnable={this.handleDisableLegalGuardianBoardPostsVisibility}
            disabled={this.state.legalGuardianPartOfBoardPostsVisibilityInProgress}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            label="Pozwól nauczycielom na dostęp do wszystkich grup"
            desc="Dostępy do wszystkich grup są"
            checked={this.props.unit.teacherAllGroupVisibility}
            handleEnable={this.handleDisableAllGroupsAccess}
            handleDisable={this.handleEnableAllGroupsAccess}
            disabled={this.state.teacherAllGroupVisibilityInProgress}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.editingChildDataByTeacherBlockedInProgress}
            label="Blokowanie edycji danych dzieci przez nauczyciela "
            desc="Blokowanie edycji danych dzieci przez nauczyciela jest "
            checked={this.props.unit.editingChildDataByTeacherBlocked}
            handleEnable={this.handleEnableEditingChildDataByTeacherBlocked}
            handleDisable={this.handleDisableEditingChildDataByTeacherBlocked}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.editingMealsCateringDuesInProgress}
            label="Korzystaj z modułu posiłków  "
            desc="Korzystanie z modułu posiłków jest "
            checked={this.props.unit.mealsCateringDues}
            handleEnable={this.handleEnableMealsCateringDues}
            handleDisable={this.handleDisableMealsCateringDues}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.editingAllowDebtCollection}
            label="Zezwalaj na windykację dzieci w placówce  "
            desc="Przypisanie windykacji dzieci w placówce jest "
            checked={this.props.unit.allowDebtCollection}
            handleEnable={this.handleEnableAllowDebtCollection}
            handleDisable={this.handleDisableAllowDebtCollection}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.editingAllowAdaptation}
            label="Zezwalaj na adaptację dzieci w placówce  "
            desc="Przypisanie adaptacji dzieci w placówce jest "
            checked={this.props.unit.allowAdaptation}
            handleEnable={this.handleEnableAllowAdaptation}
            handleDisable={this.handleDisableAllowAdaptation}
          />
        </UnitListItem>
      </Paper>
    );
  }

  renderAttendance() {
    return (
      <Paper sx={{ p: { xs: 2.5, md: 6 }, my: 2 }}>
        <UnitListItem>
          <Typography variant="h6">Wycofywanie zgłoszonych nieobecności przez opiekunów</Typography>
          <Typography>
            Opiekunowie prawni mogą wycofywać zgłoszone nieobecności najpóźniej
            <strong> {this.props.unit.legalGuardiansAbsenceRemovalDeadlineHours} godzin</strong> przed ich rozpoczęciem.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" aria-label="Zmień" onClick={this.handlEditAbsenceRemovalDeadline}>
              Zmień
            </Button>
          </Box>
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.reportAbsenceInDaysOffUpdateInProgress}
            label="Możliwość zgłaszania nieobecności w dni wolne "
            desc="Możliwość zgłaszania nieobecności w dni wolne jest "
            checked={this.props.unit.reportAbsenceInDaysOffEnabled}
            handleDisable={this.handlerEnableReportingAbsenceInDaysOff}
            handleEnable={this.handlerDisableReportingAbsenceInDaysOff}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.noHoursAttendancesUpdateInProgress}
            label="Możliwość wprowadzania obecności według zadeklarowanych godzin obecności "
            desc="Możliwość wprowadzania obecności według zadeklarowanych godzin obecności w szczegółach dziecka jest "
            checked={this.props.unit.noHoursAttendancesEnabled}
            handleDisable={this.handlerEnableNoHoursAttendance}
            handleEnable={this.handlerDisableNoHoursAttendance}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.sendingChildPickedUpNotificationBlockedInProgress}
            label="Blokowanie wysyłania powiadomień o odbiorze dziecka "
            desc="Blokowanie wysyłania powiadomień do rodziców o odbiorze dziecka jest "
            checked={this.props.unit.sendingChildPickedUpNotificationBlocked}
            handleEnable={this.handleEnableSendingChildPickedUpNotificationBlocked}
            handleDisable={this.handleDisableSendingChildPickedUpNotificationBlocked}
          />
        </UnitListItem>
      </Paper>
    );
  }

  renderSettlements() {
    return (
      <Paper sx={{ p: { xs: 2.5, md: 6 }, my: 2 }}>
        <UnitListItem>
          <Typography variant="h6">Kary za nieodebranie dziecka na czas</Typography>
          {this.props.unit.penalties.afterHoursChargingRate == null ? (
            <Typography>Nie ustawiono</Typography>
          ) : (
            <Typography>
              Po przekroczeniu{' '}
              <strong>
                {this.props.unit.penalties.afterHoursChargingBase
                  ? afterHoursChargingBase[this.props.unit.penalties.afterHoursChargingBase].name
                  : '-'}{' '}
              </strong>
              zostanie naliczona kara w wysokości{' '}
              <strong>
                {this.props.unit.penalties.afterHoursChargingRate.toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN'
                })}{' '}
              </strong>
              za każde rozpoczęte <strong>{this.props.unit.penalties.afterHoursChargingInterval} minut</strong>.
              Naliczanie zostanie rozpoczęte z opóźnieniem{' '}
              <strong>{this.props.unit.penalties.afterHoursChargingOffsetInMinutes} minut</strong>.
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" aria-label="Edytuj" onClick={this.handleEditPenalties}>
              Edytuj
            </Button>
          </Box>
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.individualBankAccountsInProgress}
            label="Indywidualne numery kont bankowych dzieci "
            desc="Indywidualne numery kont bankowych dzieci są "
            checked={this.props.unit.individualBankAccounts}
            handleEnable={this.handleEnableIndividualBankAccounts}
            handleDisable={this.handleDisableIndividualBankAccounts}
          />
        </UnitListItem>
        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.addContractNumberToSettlementNumberInProgress}
            label="Dołącz numer umowy do numeru płatności "
            desc="Dołączanie numeru umowy do numeru płatności jest "
            checked={this.props.unit.addContractNumberToSettlementNumber}
            handleEnable={this.handleEnableAddContractNumberToSettlementNumber}
            handleDisable={this.handleDisableAddContractNumberToSettlementNumber}
          />
        </UnitListItem>

        <Divider sx={{ my: 3 }} />
        <UnitListItem>
          <UnitListElement
            disabled={this.state.sendingOverduesNotificationInProgress}
            label="Wysyłanie powiadomień do rodziców o zaległych opłatach "
            desc="Wysyłanie powiadomień do rodziców o zaległych opłatach jest "
            checked={this.props.unit.sendingOverduesNotification}
            handleDisable={this.handleEnableSendingOverduesNotification}
            handleEnable={this.handleDisableSendingOverduesNotification}
          />
        </UnitListItem>
      </Paper>
    );
  }

  renderContent(category) {
    switch (category) {
      case 'Wszystkie':
        return this.renderAll();
      case 'Placówka':
        return this.renderUnit();
      case 'Obecności':
        return this.renderAttendance();
      case 'Rozliczenia':
        return this.renderSettlements();
      default:
        return null;
    }
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <>
          <PageHeaderText title="Ustawienia placówki" titleIcon={<BusinessIcon />} />
          <ContentWrapper>
            {!this.state.selectedCategory ? (
              <Grid container spacing={3}>
                {this.renderChips(this.categoryHeaders)}
              </Grid>
            ) : (
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    this.setState({ selectedCategory: null });
                  }}>
                  Powrót do wyboru kategorii
                </Button>
                {this.renderContent(this.state.selectedCategory)}
              </Box>
            )}

            {this.renderAddressEditDialog()}
            {this.renderWorkingHoursEditDialog()}
            {this.renderPenaltiesSettingsEditDialog()}
            {this.renderAbsenceRemovalDeadlineEditDialog()}
          </ContentWrapper>
        </>
      </LoadingRenderWrapper>
    );
  }
}

UnitPage.propTypes = {
  unit: PropTypes.object.isRequired,
  workingHoursUi: PropTypes.object.isRequired,
  addressUi: PropTypes.object.isRequired,
  penaltiesUi: PropTypes.object.isRequired,
  absenceReportingDeadlineUi: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  defaultPublishingHouseId: PropTypes.string,
  isMobile: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    unit: state.configuration.unit,
    workingHoursUi: state.configuration.unitWorkingHoursUi,
    addressUi: state.configuration.unitAddressUi,
    penaltiesUi: state.configuration.unitPenaltiesUi,
    absenceReportingDeadlineUi: state.configuration.unitAbsenceRemovalDeadlineUi,
    defaultPublishingHouseId: state.configuration.unit.defaultPublishingHouseId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(unitActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitPage);
