import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List, ListItemIcon, ListItemText, ListItem, Grid, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import makeStyles from '@mui/styles/makeStyles';
import PhotoIcon from '@mui/icons-material/Photo';

const styles = (theme) => ({
  header: {
    paddingLeft: 8,
    marginTop: 10
  },
  successIcons: {
    color: theme.palette.color.success
  },
  itemIcon: {
    marginRight: 10
  },
  secondaryText: {
    color: 'rgb(83, 9, 4)',
    fontSize: 14
  }
});

const useStyles = makeStyles(styles);

const Description = ({ name, cl }) => (
  <Grid container alignItems="center">
    <PhotoIcon className={cl.itemIcon} />
    <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>{name}</Typography>
  </Grid>
);

Description.propTypes = {
  name: PropTypes.string.isRequired,
  cl: PropTypes.object.isRequired
};

const UploadedMultimediaDetailsContent = ({ newMultimedia, errorMultimedia, message }) => {
  const cl = useStyles();

  return (
    <Grid container direction="column">
      {message}
      {errorMultimedia && errorMultimedia.length !== 0 && (
        <>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }} className={cl.header}>
            Multimedia, które nie zostały dodane:
          </Typography>
          <List dense>
            {errorMultimedia.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <ErrorOutlineIcon color="error" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Description name={item.file.name} cl={cl} />}
                  secondary={
                    <Typography
                      sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                      className={cl.secondaryText}>
                      {item.errorMessage}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
      {errorMultimedia && errorMultimedia.length !== 0 && newMultimedia && newMultimedia.length !== 0 && <Divider />}
      {newMultimedia && newMultimedia.length !== 0 && (
        <>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }} className={cl.header}>
            Dodane multimedia:
          </Typography>
          <List dense>
            {newMultimedia.map((item, index) => (
              <ListItem key={index}>
                <ListItemText disableTypography primary={<Description name={item.name} cl={cl} />} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Grid>
  );
};

UploadedMultimediaDetailsContent.propTypes = {
  newMultimedia: PropTypes.array,
  errorMultimedia: PropTypes.array,
  message: PropTypes.object.isRequired
};

export default UploadedMultimediaDetailsContent;
