import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import GroupIcon from '@mui/icons-material/Group';
import ContentWrapper from '../common/contentWrapper';
import GroupDetailsCard from '../groupDetails/details/GroupDetailsCard';
import { GroupDetailsModelValidator } from '../../models/groups/GroupModelsValidator';
import GroupChildrenCard from '../groupDetails/children/GroupChildrenCard';
import GroupTeachersCard from '../groupDetails/teachers/GroupTeachersCard';
import React, { useEffect, useState } from 'react';
import staffMemberRoles from '../../constants/staffMemberRoles';
import {
  archiveGroupCancel,
  archiveGroupDetailsAsync,
  archiveGroupStart,
  associateTeacherAsync,
  associateTeacherCancel,
  associateTeacherStart,
  removeEnrolledChildrenAsync,
  removeTeacherAsync,
  updateEnrolledChildrenAsync,
  updateEnrolledChildrenCancel,
  updateEnrolledChildrenStart,
  updatePsychologicalGroupDetailsAsync
} from '../../actions/psychologicalGroupsActions';
import ArchiveGroupDialog from '../groupDetails/archiveGroupDialog';

const PsychologicalGroupDetailsPage = () => {
  const dispatch = useDispatch();
  const group = useSelector((state) => state.psychologicalGroupDetails);
  const groupUI = useSelector((state) => state.psychologicalGroupDetailsUi);
  const pupils = useSelector((state) => state.children);

  const teachers = useSelector((state) => state.staffMembers);

  const [groupData, setGroupData] = useState(group);

  useEffect(() => {
    setGroupData(group);
  }, [group]);

  const getTeachers = () => {
    return teachers.filter(
      (teacher) => teacher.role === staffMemberRoles.teacher.value || teacher.role === staffMemberRoles.principal.value
    );
  };

  const onUpdateGroupState = (event) => {
    setGroupData((prevState) => {
      const field = event.target.name;
      const updatedGroup = Object.assign({}, prevState);
      updatedGroup[field] = event.target.value;
      return updatedGroup;
    });
  };

  const handleGroupUpdate = () => {
    dispatch(updatePsychologicalGroupDetailsAsync(groupData));
  };

  const handleSaveEnrolledChildren = (newList) => {
    dispatch(
      updateEnrolledChildrenAsync(
        groupData.id,
        newList.map((child) => child.id)
      )
    );
  };

  const handleEditEnrolledChildren = () => {
    dispatch(updateEnrolledChildrenStart());
  };

  const handleCancelEnrolledChildren = () => {
    dispatch(updateEnrolledChildrenCancel());
    setGroupData(Object.assign({}, group));
  };

  const handleRemoveEnrolledChild = (childId) => {
    dispatch(removeEnrolledChildrenAsync(groupData.id, childId));
  };

  const handleEditAssociatedTeachers = () => {
    dispatch(associateTeacherStart());
  };
  const handleSaveAssociatedTeachers = (teacherId) => {
    dispatch(associateTeacherAsync(groupData.id, teacherId));
  };

  const handleCancelAssociatedTeachers = () => {
    dispatch(associateTeacherCancel());
    setGroupData(Object.assign({}, group));
  };
  const handleRemoveAssociatedTeacher = (teacherId) => {
    dispatch(removeTeacherAsync(groupData.id, teacherId));
  };

  const handleStartArchive = () => {
    dispatch(archiveGroupStart());
  };

  const handleCancelArchive = () => {
    dispatch(archiveGroupCancel());
  };

  const handleSaveArchiveGroup = (archiveChildren, archiveLegalGuardians, archiveBoardPosts) => {
    dispatch(archiveGroupDetailsAsync(group.id, archiveChildren, archiveLegalGuardians, archiveBoardPosts));
  };

  return (
    // <LoadingRenderWrapper onLoaded={this.handleLoaded}>
    <Box>
      <PageHeaderText title="Grupy" titleIcon={<GroupIcon />} />
      <ContentWrapper>
        <GroupDetailsCard
          group={groupData}
          onChange={onUpdateGroupState}
          onSave={handleGroupUpdate}
          onCancel={() => {}}
          onEdit={() => {}}
          onValidate={() => new GroupDetailsModelValidator().validate(groupData)}
          isEditing={groupUI.details.isEditing}
          isProcessing={groupUI.details.isProcessing}
        />
        <GroupChildrenCard
          isPsychological
          group={groupData}
          pupils={pupils}
          onChange={onUpdateGroupState}
          onSave={handleSaveEnrolledChildren}
          onCancel={handleCancelEnrolledChildren}
          onEdit={handleEditEnrolledChildren}
          onRemove={handleRemoveEnrolledChild}
          isEditing={groupUI.enrolledChildren.isEditing}
          isProcessing={groupUI.enrolledChildren.isProcessing}
        />
        <GroupTeachersCard
          group={groupData}
          teachers={getTeachers()}
          onChange={onUpdateGroupState}
          onSave={handleSaveAssociatedTeachers}
          onCancel={handleCancelAssociatedTeachers}
          onEdit={handleEditAssociatedTeachers}
          onRemove={handleRemoveAssociatedTeacher}
          isEditing={groupUI.associatedTeachers.isEditing}
          isProcessing={groupUI.associatedTeachers.isProcessing}
        />
        <Box
          justifyContent="flex-end"
          sx={{
            display: 'flex'
          }}>
          <Button variant="outlined" aria-label="Archiwizuj" onClick={handleStartArchive}>
            Archiwizuj
          </Button>
        </Box>
        {!groupUI.archival.isEditing ? null : (
          <ArchiveGroupDialog
            group={group}
            isDialogOpen={groupUI.archival.isEditing}
            isProcessing={groupUI.archival.isProcessing}
            onArchive={handleSaveArchiveGroup}
            onCancel={handleCancelArchive}
          />
        )}
      </ContentWrapper>
    </Box>
    // </LoadingRenderWrapper>
  );
};
export default PsychologicalGroupDetailsPage;
