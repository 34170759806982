import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../forms/DialogForm';
import { PinDeviceValidator } from '../../../models/configuration/guardiansPinService/PinDeviceValidator';
import PinDeviceForm from './PinDeviceForm';

class PinDeviceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: { ...this.props.device },
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const device = Object.assign({}, this.state.device);
    device[field] = event.target.value;
    return this.setState({ device });
  }

  render() {
    const { onSave, onCancel, title, canEditLogin, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header={title}
        maxWidth="sm"
        onSave={() => onSave(this.state.device)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new PinDeviceValidator().validate(this.state.device)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.guardiansPinService"
        requiredFieldText>
        <PinDeviceForm
          device={this.state.device}
          isLoginDisabled={!canEditLogin}
          onChange={this.handleChange}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

PinDeviceDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  canEditLogin: PropTypes.bool.isRequired,
  device: PropTypes.object
};

export default PinDeviceDialog;
