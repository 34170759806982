import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, MenuItem } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import moment from 'moment';
import DotsMenu from '../../common/menu/DotsMenu';

const ChildFileAttachmentsList = ({ attachments, onRemove, onDownloadFile, onShowData }) => {
  const renderMenu = (id, onClose) => {
    const menu = [
      <ActionConfirmationMenuItem
        key="1"
        onItemClick={onClose}
        actionLabel="Usuń"
        confirmationText="Czy na pewno chcesz usunąć załączony plik?"
        confirmationTitle="Usuń załączony plik"
        confirmLabel="Usuń"
        onConfirm={() => onRemove(id)}
        onCancel={() => {}}
      />
    ];
    if (!!onShowData) menu.push(<MenuItem onClick={() => onShowData(id)}>Podgląd</MenuItem>);
    return menu;
  };
  return (
    <List>
      {attachments.map((file) => {
        return (
          <ListItem
            button
            key={file.fileId}
            onClick={() => onDownloadFile(file.fileId)}
            component="li"
            aria-label={`Pobierz plik ${file.name}`}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                background: (theme) => theme.palette.background.hover
              }
            }}>
            <ListItemAvatar>
              <AttachmentIcon />
            </ListItemAvatar>
            <ListItemText
              primary={file.name}
              secondary={moment(file.uploadDate).fromNow()}
              secondaryTypographyProps={{
                color: (theme) => theme.palette.text.primary
              }}
            />
            <ListItemSecondaryAction>
              <DotsMenu>{(onClose) => renderMenu(file.fileId, onClose)}</DotsMenu>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

ChildFileAttachmentsList.propTypes = {
  attachments: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired
};

export default ChildFileAttachmentsList;
