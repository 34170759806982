import BaseModel from '../BaseModel';
import _ from 'lodash';

export class ValidationModel extends BaseModel {
  constructor(errors) {
    super();
    this.errors = errors || {};
    this.isValid = this.flatten(errors).every((e) => _.isEmpty(e));
  }

  flatten(data) {
    const result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        const l = cur.length;
        for (let i = 0; i < l; i++) {
          recurse(cur[i], prop + '[' + i + ']');
        }
        if (l === 0) {
          result[prop] = [];
        }
      } else {
        let isEmpty = true;
        /*eslint no-restricted-syntax:0 */
        /*eslint guard-for-in:0 */
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }

    recurse(data, '');
    return _.values(result);
  }
}
