import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { isSmall } from './sizeHelper';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

const CalendarDayContent = ({ day, containerWidth, primaryText, primaryIcon, secondaryIcon, menuIcon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1333));

  const getDayName = useMemo(() => {
    const date = new Date(day.date);
    return date.toLocaleDateString('pl', { weekday: 'short' }).toUpperCase().slice(0, -1);
  }, [day.date]);

  return isSmall(containerWidth) || isMobile ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        height: '100%',
        width: '100%',
        color: theme.palette.components.calendar.dayColor,
        '&:hover': {
          color: theme.palette.components.calendar.calendarDayColorHover
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: primaryIcon ? 'space-between' : 'start'
        }}>
        <Box
          component="span"
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', fontSize: 16 }}>
          {day.date.format('D')} {getDayName}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          fontSize: 16,
          textAlign: 'end',
          justifyContent: 'center',
          textOverflow: 'ellipsis',
          whiteSpace: 'wrap',
          fontWeight: 500,
          minWidth: '0',
          lineHeight: 'normal',
          alignItems: 'center'
        }}>
        {primaryText} {primaryIcon}
      </Box>
      <Box
        sx={{
          justifyContent: 'space-between',
          display: !secondaryIcon && !menuIcon ? 'none' : 'flex',
          '&:hover': {
            color: theme.palette.components.calendar.calendarDayColorHover
          }
        }}>
        {secondaryIcon}
        {menuIcon}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        height: '100%',
        width: '100%',
        color: theme.palette.components.calendar.dayColor,
        '&:hover': {
          color: theme.palette.components.calendar.calendarDayColorHover
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: primaryIcon ? 'space-between' : 'start'
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: primaryIcon ? 'space-between' : 'flex-start',
            fontSize: '16px'
          }}>
          {day.date.format('D')} {primaryIcon}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          minWidth: 0,
          flexWrap: 'nowrap',
          fontSize: 14,
          textAlign: 'end',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'wrap',
          fontWeight: 500,
          lineHeight: 'normal'
        }}>
        {primaryText}
      </Box>
      <Box
        sx={{
          justifyContent: 'space-between',
          display: !secondaryIcon && !menuIcon ? 'none' : 'flex',
          '&:hover': {
            color: theme.palette.components.calendar.calendarDayColorHover
          }
        }}>
        {secondaryIcon}
        {menuIcon}
      </Box>
    </Box>
  );
};

CalendarDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  primaryText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  primaryIcon: PropTypes.element,
  secondaryIcon: PropTypes.element,
  menuIcon: PropTypes.element
};

export default CalendarDayContent;
