import React from 'react';
import PropTypes from 'prop-types';
import PublishIcon from '@mui/icons-material/Publish';
import { Paper, Fade, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const UploadBackdrop = ({ isOpen, isMobile, text }) => {
  const theme = useTheme();
  return (
    <Fade in={isOpen}>
      <Paper
        sx={{
          minHeight: '100%',
          background: theme.palette.background.default,
          position: 'absolute',
          zIndex: theme.zIndex.snackbar,
          top: 0,
          left: 0,
          width: '100%',
          color: theme.palette.background.default
        }}>
        <Grid
          container
          alignItems="center"
          direction="column"
          sx={{
            background: (theme) => theme.palette.color.info,
            position: 'sticky',
            top: '50%',
            left: 0
          }}>
          <PublishIcon sx={{ fontSize: isMobile ? 'small' : 'medium' }} />
          <Typography
            variant={isMobile ? 'subtitle2' : 'h6'}
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
            align="center">
            {text}
          </Typography>
        </Grid>
      </Paper>
    </Fade>
  );
};

UploadBackdrop.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default UploadBackdrop;
