import PropTypes from 'prop-types';
import React from 'react';
import YearAndMonthOptionsSlider from './YearAndMonthOptionsSlider';
import Calendar from './Calendar';
import moment from 'moment';
import { Box } from '@mui/material';

class FullCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.onYearOrMonthChanged = this.onYearOrMonthChanged.bind(this);

    this.state = {
      month: this.props.defaultMonth,
      year: this.props.defaultYear
    };
  }

  onYearOrMonthChanged(year, month) {
    this.setState({ month, year }, () => this.props.onYearOrMonthSelected(year, month));
  }

  render() {
    return (
      <Box
        sx={{
          p: 3,
          pt: 0,
          display: 'flex',
          flexWrap: 'wrap',
          ...this.props.sx
        }}>
        <Box
          sx={{
            mt: 1,
            mb: 0.5,
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-end' }
          }}>
          <YearAndMonthOptionsSlider
            contrast
            onSelected={this.onYearOrMonthChanged}
            defaultMonth={this.state.month}
            defaultYear={this.state.year}
          />
        </Box>
        {this.props.header ? this.props.header : null}
        <Calendar
          weekOffset={this.props.weekOffset}
          date={moment(new Date(this.state.year, this.state.month - 1, 1))}
          markedDays={this.props.markedDays}
          onDaySelected={this.props.onDaySelected}
          onDayContentRender={this.props.onDayContentRender}
          onDayRender={this.props.onDayRender}
        />
      </Box>
    );
  }
}

FullCalendar.propTypes = {
  weekOffset: PropTypes.number,
  markedDays: PropTypes.array.isRequired,

  onDayRender: PropTypes.func,
  onDayContentRender: PropTypes.func,

  defaultYear: PropTypes.string,
  defaultMonth: PropTypes.string,

  onYearOrMonthSelected: PropTypes.func,
  onDaySelected: PropTypes.func,

  header: PropTypes.element
};

FullCalendar.defaultProps = {
  weekOffset: 0,
  onYearOrMonthSelected: () => {},
  onDaySelected: () => {},
  defaultYear: moment().format('YYYY'),
  defaultMonth: moment().format('MM')
};

export default FullCalendar;
