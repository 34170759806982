import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import TextField from '../../common/TextField';

const ChildFileAttachmentForm = ({ fileAttachment, onNameChange, errors }) => {
  return (
    <Box>
      <TextField
        hintText="Nazwa"
        floatingLabelText="Nazwa"
        floatingLabelFixed={false}
        fullWidth={true}
        name="name"
        value={fileAttachment.name}
        onChange={(event) => onNameChange(event.target.value)}
        errorText={errors.name}
        autoFocus={true}
      />
    </Box>
  );
};

ChildFileAttachmentForm.propTypes = {
  fileAttachment: PropTypes.object.isRequired,
  onNameChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ChildFileAttachmentForm;
