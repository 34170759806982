import { Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import EmptyState from '../EmptyState';
import ProgramElement from './ProgramElement';

const ProgramContainer = ({ title, data, onClick, onEdit, onDelete }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{title}</Typography>
        <Button variant="contained" onClick={onClick}>
          Dodaj
        </Button>
      </Box>
      <Box sx={{ mb: 4 }}>
        {!!data?.length > 0 ? (
          data.map((element, idx) => (
            <ProgramElement
              key={idx}
              data={element}
              onEdit={() => onEdit(element)}
              onDelete={() => onDelete(element)}
              onClose={onDelete}
            />
          ))
        ) : (
          <EmptyState message="Brak" contrast />
        )}
      </Box>
    </Box>
  );
};

export default ProgramContainer;
