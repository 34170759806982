import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../forms/PersonAvatar';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

const StaffMembersArchiveList = ({ staffMembers, roles }) => {
  return (
    <List>
      {staffMembers.map((member) => {
        return (
          <React.Fragment key={member.id}>
            <ListItem>
              <ListItemAvatar>
                <PersonAvatar
                  initials
                  alt={member.firstName}
                  url={member.photoUrl}
                  firstName={member.firstName}
                  lastName={member.lastName}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${member.lastName} ${member.firstName}`}
                secondaryTypographyProps={{
                  component: 'span',
                  sx: {
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: (theme) => theme.palette.color.contrast
                  }
                }}
                secondary={
                  <>
                    {roles[member.role].name}
                    <ArchivalInfo archivalInfo={member.archivalInfo} />
                  </>
                }
              />
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

StaffMembersArchiveList.propTypes = {
  staffMembers: PropTypes.array.isRequired,
  roles: PropTypes.object.isRequired
};

export default StaffMembersArchiveList;
