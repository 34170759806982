import React, { useState } from 'react';
import MessageSend from '../Components/MessageSend';
import MessageInformation from '../Components/MessageInformation';
import * as authActions from '../../../../actions/userAccountActions';
import { useDispatch } from 'react-redux';

const PasswordRecoveryContainer = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // eslint-disable-next-line react/prop-types
  const onSubmit = ({ email }) => {
    setIsLoading(true);
    dispatch(authActions.recoverPassword({ login: email })).then(() => {
      setIsLoading(false);
      setSubmitted(true);
    });
  };

  return (
    <>
      {submitted ? (
        <MessageSend submitted={submitted} />
      ) : (
        <MessageInformation isLoading={isLoading} onSubmit={onSubmit} />
      )}
    </>
  );
};

PasswordRecoveryContainer.propTypes = {};

export default PasswordRecoveryContainer;
