import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class WOPFUValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      date: (value) => check.isEmpty(value) && 'Nie podano daty',
      number: (value) => check.isEmpty(value) && 'Nie podano numeru oświadczenia',
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      failures: (value) => {
        if (check.isEmpty(value)) return { empty: 'Wymagane co najmniej 1 pole' };
        return value?.map((field, index) => check.isEmpty(field?.content) && { content: 'Pole nie może być puste' });
      },
      help: (value) => {
        if (check.isEmpty(value)) return { empty: 'Wymagane co najmniej 1 pole' };
        return value?.map((field, index) => check.isEmpty(field?.content) && { content: 'Pole nie może być puste' });
      },
      needs: (value) => {
        if (check.isEmpty(value)) return { empty: 'Wymagane co najmniej 1 pole' };
        return value?.map((field, index) => check.isEmpty(field?.content) && { content: 'Pole nie może być puste' });
      },
      programGrade: (value) => {
        if (check.isEmpty(value)) return { empty: 'Wymagane co najmniej 1 pole' };
        return value?.map((field, index) => check.isEmpty(field?.content) && { content: 'Pole nie może być puste' });
      },
      strongSides: (value) => {
        if (check.isEmpty(value)) return { empty: 'Wymagane co najmniej 1 pole' };
        return value?.map((field, index) => check.isEmpty(field?.content) && { content: 'Pole nie może być puste' });
      }
    };
  }
}
