import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../forms/PersonAvatar';
import DialogForm from '../forms/DialogForm';
import DaycareReadonlyDetails from '../daycare/DaycareReadonlyDetails';
import { Box } from '@mui/material';

const DaycareDetailsDialog = ({
  onCancel,
  title,
  isDialogOpen,
  entry,
  pupilFirstName,
  pupilLastName,
  pupilPhotoUrl
}) => (
  <DialogForm
    // sx={{ maxWidth: '400px' }}
    maxWidth="sm"
    header={title}
    onSave={() => {}}
    onCancel={onCancel}
    isDialogOpen={isDialogOpen}
    isProcessing={false}
    saveDisabled={true}
    onValidationDone={() => {}}
    statePathToUi="daycare">
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <PersonAvatar
        initials
        alt={pupilFirstName}
        url={pupilPhotoUrl}
        firstName={pupilFirstName}
        lastName={pupilLastName}
      />
      <Box
        sx={{
          textAlign: 'center',
          fontSize: 18,
          my: 2,
          mx: 0
        }}>{`${pupilFirstName} ${pupilLastName}`}</Box>
    </Box>
    <DaycareReadonlyDetails daycareEntry={entry} />
  </DialogForm>
);

DaycareDetailsDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  entry: PropTypes.object,
  date: PropTypes.string.isRequired,
  pupilId: PropTypes.string.isRequired,
  pupilFirstName: PropTypes.string.isRequired,
  pupilLastName: PropTypes.string.isRequired,
  pupilPhotoUrl: PropTypes.string
};

export default DaycareDetailsDialog;
