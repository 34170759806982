/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function childDetailsUiReducer(state = initialState.childDetailsUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_CHILD: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.CREATE_CHILD_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.CREATE_CHILD_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.CREATE_CHILD_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.CREATE_CHILD_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //update
    case types.UPDATE_CHILD: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_CHILD_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.UPDATE_CHILD_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.UPDATE_CHILD_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.UPDATE_CHILD_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    // legal
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD: {
      return Object.assign({}, state, { legalGuardians: UiModel.start() });
    }
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { legalGuardians: UiModel.cancel() });
    }
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { legalGuardians: UiModel.submit() });
    }
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { legalGuardians: UiModel.success() });
    }
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { legalGuardians: UiModel.failure(action.errors) });
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD: {
      return Object.assign({}, state, { legalGuardians: UiModel.start() });
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_CANCEL: {
      return Object.assign({}, state, { legalGuardians: UiModel.cancel() });
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUBMIT: {
      return Object.assign({}, state, { legalGuardians: UiModel.submit() });
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUCCESS: {
      return Object.assign({}, state, { legalGuardians: UiModel.success() });
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_FAILURE: {
      return Object.assign({}, state, { legalGuardians: UiModel.failure(action.errors) });
    }
    // guardian
    case types.ADD_GUARDIAN_TO_CHILD: {
      return Object.assign({}, state, { guardians: UiModel.start() });
    }
    case types.ADD_GUARDIAN_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { guardians: UiModel.cancel() });
    }
    case types.ADD_GUARDIAN_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { guardians: UiModel.submit() });
    }
    case types.ADD_GUARDIAN_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { guardians: UiModel.success() });
    }
    case types.ADD_GUARDIAN_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { guardians: UiModel.failure(action.errors) });
    }
    case types.UPDATE_GUARDIAN_IN_CHILD: {
      return Object.assign({}, state, { guardians: UiModel.start() });
    }
    case types.UPDATE_GUARDIAN_IN_CHILD_CANCEL: {
      return Object.assign({}, state, { guardians: UiModel.cancel() });
    }
    case types.UPDATE_GUARDIAN_IN_CHILD_SUBMIT: {
      return Object.assign({}, state, { guardians: UiModel.submit() });
    }
    case types.UPDATE_GUARDIAN_IN_CHILD_SUCCESS: {
      return Object.assign({}, state, { guardians: UiModel.success() });
    }
    case types.UPDATE_GUARDIAN_IN_CHILD_FAILURE: {
      return Object.assign({}, state, { guardians: UiModel.failure(action.errors) });
    }
    // opinion
    case types.ADD_OPINION_TO_CHILD: {
      return Object.assign({}, state, { opinions: UiModel.start() });
    }
    case types.ADD_OPINION_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { opinions: UiModel.cancel() });
    }
    case types.ADD_OPINION_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { opinions: UiModel.submit() });
    }
    case types.ADD_OPINION_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { opinions: UiModel.success() });
    }
    case types.ADD_OPINION_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { opinions: UiModel.failure(action.errors) });
    }
    // vacation
    case types.ADD_VACATION_PERIOD_TO_CHILD: {
      return Object.assign({}, state, { vacationPeriods: UiModel.start() });
    }
    case types.ADD_VACATION_PERIOD_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { vacationPeriods: UiModel.cancel() });
    }
    case types.ADD_VACATION_PERIOD_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { vacationPeriods: UiModel.submit() });
    }
    case types.ADD_VACATION_PERIOD_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { vacationPeriods: UiModel.success() });
    }
    case types.ADD_VACATION_PERIOD_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { vacationPeriods: UiModel.failure(action.errors) });
    }
    //survey
    case types.ADD_SURVEY_TO_CHILD: {
      return Object.assign({}, state, { surveys: UiModel.start() });
    }
    case types.ADD_SURVEY_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { surveys: UiModel.cancel() });
    }
    case types.ADD_SURVEY_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { surveys: UiModel.submit() });
    }
    case types.ADD_SURVEY_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { surveys: UiModel.success() });
    }
    case types.ADD_SURVEY_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { surveys: UiModel.failure(action.errors) });
    }
    // charging schemes
    case types.ASSIGN_CHARGING_SCHEME_TO_CHILD: {
      return Object.assign({}, state, { chargingSchemes: UiModel.start() });
    }
    case types.ASSIGN_CHARGING_SCHEME_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { chargingSchemes: UiModel.cancel() });
    }
    case types.ASSIGN_CHARGING_SCHEME_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { chargingSchemes: UiModel.submit() });
    }
    case types.ASSIGN_CHARGING_SCHEME_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { chargingSchemes: UiModel.success() });
    }
    case types.ASSIGN_CHARGING_SCHEME_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { chargingSchemes: UiModel.failure(action.errors) });
    }
    // attach file
    case types.ATTACH_FILE_TO_CHILD: {
      return Object.assign({}, state, { attachments: UiModel.start() });
    }
    case types.ATTACH_FILE_TO_CHILD_CANCEL: {
      return Object.assign({}, state, { attachments: UiModel.cancel() });
    }
    case types.ATTACH_FILE_TO_CHILD_SUBMIT: {
      return Object.assign({}, state, { attachments: UiModel.submit() });
    }
    case types.ATTACH_FILE_TO_CHILD_SUCCESS: {
      return Object.assign({}, state, { attachments: UiModel.success() });
    }
    case types.ATTACH_FILE_TO_CHILD_FAILURE: {
      return Object.assign({}, state, { attachments: UiModel.failure(action.errors) });
    }
    //opening balance
    case types.UPDATE_CHILD_OPENING_BALANCE: {
      return Object.assign({}, state, { openingBalance: UiModel.start() });
    }
    case types.UPDATE_CHILD_OPENING_BALANCE_CANCEL: {
      return Object.assign({}, state, { openingBalance: UiModel.cancel() });
    }
    case types.UPDATE_CHILD_OPENING_BALANCE_SUBMIT: {
      return Object.assign({}, state, { openingBalance: UiModel.submit() });
    }
    case types.UPDATE_CHILD_OPENING_BALANCE_SUCCESS: {
      return Object.assign({}, state, { openingBalance: UiModel.success() });
    }
    case types.UPDATE_CHILD_OPENING_BALANCE_FAILURE: {
      return Object.assign({}, state, { openingBalance: UiModel.failure(action.errors) });
    }
    // taken over by debt collection confirmation
    case types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION: {
      return Object.assign({}, state, { debtCollection: UiModel.start() });
    }
    case types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_CANCEL: {
      return Object.assign({}, state, { debtCollection: UiModel.cancel() });
    }
    case types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUBMIT: {
      return Object.assign({}, state, { debtCollection: UiModel.submit() });
    }
    case types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUCCESS: {
      return Object.assign({}, state, { debtCollection: UiModel.success() });
    }
    case types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_FAILURE: {
      return Object.assign({}, state, { debtCollection: UiModel.failure(action.errors) });
    }

    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN: {
      return Object.assign({}, state, { removeDebtCollection: UiModel.start() });
    }
    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_CANCEL: {
      return Object.assign({}, state, { removeDebtCollection: UiModel.cancel() });
    }
    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUBMIT: {
      return Object.assign({}, state, { removeDebtCollection: UiModel.submit() });
    }
    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUCCESS: {
      return Object.assign({}, state, { removeDebtCollection: UiModel.success() });
    }
    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_FAILURE: {
      return Object.assign({}, state, { removeDebtCollection: UiModel.failure(action.errors) });
    }

    // calendar
    case types.LOAD_CHILD_ATTENDANCES: {
      return Object.assign({}, state, { isCalendarLoading: true });
    }
    case types.LOAD_CHILD_ATTENDANCES_SUCCESS: {
      return Object.assign({}, state, { isCalendarLoading: false });
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.childDetailsUi);
    }
    //archiving child
    case types.ARCHIVE_CHILD: {
      return Object.assign({}, state, { archival: UiModel.start() });
    }
    case types.ARCHIVE_CHILD_CANCEL: {
      return Object.assign({}, state, { archival: UiModel.cancel() });
    }
    case types.ARCHIVE_CHILD_SUBMIT: {
      return Object.assign({}, state, { archival: UiModel.submit() });
    }
    case types.ARCHIVE_CHILD_SUCCESS: {
      return Object.assign({}, state, { archival: UiModel.success() });
    }
    //child details

    case types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET: {
      return Object.assign({}, state, { diagnoses: UiModel.start() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL: {
      return Object.assign({}, state, { diagnoses: UiModel.cancel() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT: {
      return Object.assign({}, state, { diagnoses: UiModel.submit() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS: {
      return Object.assign({}, state, { diagnoses: UiModel.success() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_FAILURE: {
      return Object.assign({}, state, { diagnoses: UiModel.failure(action.errors) });
    }
    //update
    case types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET: {
      return Object.assign({}, state, { diagnoses: UiModel.start() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL: {
      return Object.assign({}, state, { diagnoses: UiModel.cancel() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT: {
      return Object.assign({}, state, { diagnoses: UiModel.submit() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS: {
      return Object.assign({}, state, { diagnoses: UiModel.success() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_FAILURE: {
      return Object.assign({}, state, { diagnoses: UiModel.failure(action.errors) });
    }

    case types.LOAD_CHILD_DETAILS_SUMMARY_START: {
      return Object.assign({}, state, { detailsSummary: UiModel.submit() });
    }
    case types.LOAD_CHILD_DETAILS_SUMMARY_SUCCESS: {
      return Object.assign({}, state, { detailsSummary: UiModel.success() });
    }
    case types.LOAD_CHILD_DETAILS_SUMMARY_FAILURE: {
      return Object.assign({}, state, { detailsSummary: UiModel.failure(action.errors) });
    }
    //individualclasses

    case types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_START: {
      return Object.assign({}, state, { individualAttendanceUi: UiModel.start() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_CANCEL: {
      return Object.assign({}, state, { individualAttendanceUi: UiModel.cancel() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUBMIT: {
      return Object.assign({}, state, { individualAttendanceUi: UiModel.submit() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUCCESS:
    case types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUCCESS: {
      return Object.assign({}, state, { individualAttendanceUi: UiModel.success() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_FAILURE: {
      return Object.assign({}, state, { individualAttendanceUi: UiModel.failure(action.errors) });
    }

    //groupclasses

    case types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_START: {
      return Object.assign({}, state, { groupAttendanceUi: UiModel.start() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_CANCEL: {
      return Object.assign({}, state, { groupAttendanceUi: UiModel.cancel() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUBMIT: {
      return Object.assign({}, state, { groupAttendanceUi: UiModel.submit() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_SUCCESS:
    case types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUCCESS: {
      return Object.assign({}, state, { groupAttendanceUi: UiModel.success() });
    }
    case types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_FAILURE: {
      return Object.assign({}, state, { groupAttendanceUi: UiModel.failure(action.errors) });
    }

    default:
      return state;
  }
}
