import BaseModel from '../BaseModel';

export class ChildDetailsSummaryModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.baseDue = '';
    this.correctionTotal = '';
    this.totalDelayInterests = '';
    this.calculatedTotal = '';
    this.paymentsSummary = '';
    this.unpaidTotal = '';
    this.availableFund = '';
    this.month = '';
  }
}
