import { attributeObjectTypes } from '../../../constants/attributeObjectTypes';
import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, MenuItem } from '@mui/material';
import { ReportTypes } from '../../../constants/reportTypes';
import GroupPickerTemplate from './common/GroupPickerTemplate';

const ChildrenAttributeSetValuesReport = ({
  groupId,
  groups,
  attributeSetId,
  attributeSets,
  includePresentChildren,
  includeNotReportedChildren,
  onChange,
  errors
}) => {
  return (
    <Box>
      <GroupPickerTemplate
        errorText={errors.groupId}
        groupId={groupId}
        groups={groups}
        onChange={onChange}
        legend={ReportTypes.childrenAttributeSetValues.name}
        sx={{ pb: 2 }}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.childrenAttributeSetValues.name}`}>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden', mb: 2 }}
          floatingLabelText="Atrybuty"
          id="attributeSetId"
          value={attributeSetId}
          onChange={(event) => {
            onChange({ target: { name: 'attributeSetId', value: event.target.value } });
          }}>
          {attributeSets
            .filter((set) => set.objectType === attributeObjectTypes.child.value)
            .map((attributeSet) => {
              return (
                <MenuItem key={attributeSet.id} value={attributeSet.id}>
                  {attributeSet.name}
                </MenuItem>
              );
            })}
        </SelectField>
        <Checkbox
          label="Generuj dla dzieci obecnych w placówce"
          defaultChecked={includePresentChildren}
          onCheck={(event, isChecked) => onChange({ target: { name: 'includePresentChildren', value: isChecked } })}
        />
        <Checkbox
          label="Generuj dla dzieci niezgłoszonych"
          defaultChecked={includeNotReportedChildren}
          onCheck={(event, isChecked) => onChange({ target: { name: 'includeNotReportedChildren', value: isChecked } })}
        />
      </FieldsetWrapper>
    </Box>
  );
};

ChildrenAttributeSetValuesReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  attributeSets: PropTypes.array.isRequired,
  attributeSetId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  includePresentChildren: PropTypes.bool.isRequired,
  includeNotReportedChildren: PropTypes.bool.isRequired,
  errors: PropTypes.object
};

export default ChildrenAttributeSetValuesReport;
