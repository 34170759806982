import ApiBase, { ROOT_URL } from './ApiBase';

class LegalGuardiansApi extends ApiBase {
  static getLegalGuardians() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/legalGuardians`).type('application/json'));
  }

  static deleteLegalGuardian(id) {
    return super.toPromise((agent) => agent.delete(`${ROOT_URL}/legalGuardians/${id}`).type('application/json'));
  }
}

export default LegalGuardiansApi;
