import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';

const ValidationSummary = ({ messages, type }) => {
  if (!messages) {
    return null;
  }
  const messagesArray = Array.isArray(messages) ? messages : [messages];
  if (messagesArray.length === 0) {
    return null;
  }
  const getMessageColor = () => {
    if (type === 'error') {
      return (theme) => theme.palette.color.error;
    }
    if (type === 'warning') {
      return (theme) => theme.palette.color.warning;
    }
    if (type === 'info') {
      return (theme) => theme.palette.color.info;
    }
    return (theme) => theme.palette.color.primary;
  };
  return (
    <Typography
      className={classNames(type)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'left',
        color: `${getMessageColor()}`
      }}>
      {messagesArray.map((message, index) => (
        <Typography sx={{ mt: 1 }} key={index}>
          {message}
        </Typography>
      ))}
    </Typography>
  );
};

ValidationSummary.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired
};

export default ValidationSummary;

export const validationTypes = Object.freeze({
  error: 'validation-error',
  warning: 'validation-warning',
  info: 'validation-info'
});
