import * as types from './actionTypes';
import { Person, PersonGuardian } from '../models/attendance/AttendanceModels';
import * as notificationActions from './notificationActions';
import api from '../api/PsychologicalAttendanceApi';
import * as get from './getStateHelpers';

function getCurrentUserAsPerson(getState) {
  const { userFullName, userId, profilePhoto } = getState().auth;
  const firstName = userFullName.split(' ')[0];
  const lastName = userFullName.split(' ')[1];

  return new Person().assign({ firstName, lastName, id: userId, photoUrl: profilePhoto });
}

export function reportIndividualAttendance(childId) {
  return (dispatch, getState) => {
    return api
      .revertChildAbsence(childId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zgłoszono obecność dziecka ${get.childName(getState, childId)}`));
        dispatch({ type: types.REPORT_INDIVIDUAL_ATTENDANCE, childId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się zgłosić obecności dziecka ${get.childName(getState, childId)}}`)
        );
      });
  };
}

export function reportIndividualAbsence(childId, from, to, notes = 'empty') {
  return (dispatch, getState) => {
    return api
      .reportChildAbsence(childId, from, to, notes)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zgłoszono nieobecność dziecka ${get.childName(getState, childId)} ${get.displayDateRange(from, to)}`
          )
        );
        const absenceReportedBy = getCurrentUserAsPerson(getState);
        dispatch({ type: types.REPORT_INDIVIDUAL_ABSENCE, childId, from, to, notes, absenceReportedBy });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się zgłosić nieobecności dziecka ${get.childName(getState, childId)} }`
          )
        );
      });
  };
}
