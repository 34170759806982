import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, Fade, TableContainer, Pagination, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VirtualDiscTableBody from './Table/VirtualDiscTableBody';
import VirtualDiscTableHeader from './Table/VirtualDiscTableHeader';
import { useSelector } from 'react-redux';
import LoadingIndicatorWrapper from '../../../common/loading/LoadingIndicatorWrapper';

const useTableStyles = makeStyles((theme) => ({
  root: {
    minWidth: 650
  }
}));

const TableView = ({
  allItemsSelected,
  order,
  orderBy,
  onHeaderSelect,
  onRequestSort,
  onSelect,
  itemSelected,
  preparedFiles,
  onDelete,
  page,
  rowsPerPage,
  onChangePage,
  onDownload,
  areActionsDisabled,
  onShare,
  isManagement,
  hasSuperPermission,
  onCopyLink,
  cancelSelectedFiles,
  user,
  handleOpenMoveToFolderDialog
}) => {
  const cl = useTableStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.fetch);

  return (
    <LoadingIndicatorWrapper shouldUnmountChildrenWhenProcessing isProcessing={isProcessing}>
      <>
        <Fade in={true}>
          <Box>
            <TableContainer sx={{ p: 2 }}>
              <Table size="small" className={cl.root} aria-label="Lista płatności">
                <VirtualDiscTableHeader
                  allItemsSelected={allItemsSelected}
                  order={order}
                  orderBy={orderBy}
                  onSelect={onHeaderSelect}
                  onRequestSort={onRequestSort}
                  user={user}
                />
                <VirtualDiscTableBody
                  cancelSelectedFiles={cancelSelectedFiles}
                  data={preparedFiles}
                  onSelect={onSelect}
                  itemSelected={itemSelected}
                  onDelete={onDelete}
                  onCopyLink={onCopyLink}
                  onDownload={onDownload}
                  onShare={onShare}
                  orderBy={orderBy}
                  order={order}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  areActionsDisabled={areActionsDisabled}
                  handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog}
                  isManagement={isManagement}
                  hasSuperPermission={hasSuperPermission}
                  user={user}
                />
              </Table>
            </TableContainer>
            <Pagination
              count={Math.ceil(preparedFiles.length / rowsPerPage)}
              page={page + 1}
              onChange={onChangePage}
              siblingCount={isMobile ? 0 : 1}
              sx={{ mt: 2, mx: 'auto', display: 'flex', justifyContent: 'center', flexWrap: 'nowrap' }}
            />
          </Box>
        </Fade>
      </>
    </LoadingIndicatorWrapper>
  );
};

TableView.propTypes = {
  allItemsSelected: PropTypes.bool,
  areActionsDisabled: PropTypes.bool,
  itemSelected: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onCopyLink: PropTypes.func.isRequired,
  onHeaderSelect: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  preparedFiles: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isManagement: PropTypes.bool.isRequired,
  hasSuperPermission: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired
};

export default TableView;
