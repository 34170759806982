import moment from 'moment';
import StaffCalendarApi from '../api/StaffCalendarApi';
import * as types from './actionTypes';
import { showError, showSuccess } from './notificationActions';

export const loadStaffEventsAsync = (date) => {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_STAFF_EVENTS_BEGIN });
    try {
      const res = await StaffCalendarApi.getStaffEvents(moment(date).format('YYYY-MM'));
      dispatch({ type: types.LOAD_STAFF_EVENTS_SUCCESS, payload: res });
    } catch (err) {
      dispatch(showError(err.res));
      dispatch({ type: types.LOAD_STAFF_EVENTS_FAILURE });
      throw err;
    }
  };
};

export const loadFilteredStaffEventAsync = (date, filters) => {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_STAFF_EVENTS_BEGIN });
    try {
      const res = await StaffCalendarApi.getFileterdStaffEvents(moment(date).format('YYYY-MM'), filters);
      dispatch({ type: types.LOAD_STAFF_EVENTS_SUCCESS, payload: res });
    } catch (err) {
      dispatch(showError(err.res));
      dispatch({ type: types.LOAD_STAFF_EVENTS_FAILURE });
      throw err;
    }
  };
};

export const loadStaffEventsAtDayAsync = (date) => {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_STAFF_EVENT_DETAILS_BEGIN });
    try {
      const res = await StaffCalendarApi.getStaffEventsAtDay(date);
      dispatch({ type: types.LOAD_STAFF_EVENT_DETAILS_SUCCESS, payload: res });
    } catch (err) {
      dispatch(showError(err.res));
      dispatch({ type: types.LOAD_STAFF_EVENT_DETAILS_FAILURE });
      throw err;
    }
  };
};

export const createStaffEventAsync = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_STAFF_EVENT_BEGIN });
      await StaffCalendarApi.createStaffEvent(data);
      dispatch({ type: types.CREATE_STAFF_EVENT_SUCCESS, payload: data });
      dispatch(showSuccess('Wydarzenie zostało dodane.'));
    } catch (error) {
      dispatch({ type: types.CREATE_STAFF_EVENT_FAILURE, payload: error });
      throw Error();
    }
  };
};

export const updateStaffEventAsync = (data, post) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_STAFF_EVENT_BEGIN });
      await StaffCalendarApi.updateStaffEvent(data);
      dispatch({ type: types.UPDATE_STAFF_EVENT_SUCCESS, payload: { data, post } });
      dispatch(showSuccess('Wydarzenie zostało zmodyfikowane.'));
    } catch (error) {
      dispatch({ type: types.UPDATE_STAFF_EVENT_FAILURE, payload: error });
      throw Error();
    }
  };
};

export const deleteStaffEventAsync = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_STAFF_EVENT_BEGIN });
      await StaffCalendarApi.deleteStaffEvent(data);
      dispatch({ type: types.DELETE_STAFF_EVENT_SUCCESS, payload: data });
      dispatch(showSuccess('Wydarzenie zostało usunięte.'));
    } catch (error) {
      dispatch({ type: types.DELETE_STAFF_EVENT_FAILURE, payload: error });
      throw Error();
    }
  };
};
