import PropTypes from 'prop-types';
import React from 'react';
import GroupAvatar from './GroupAvatar';
import { Box, Typography } from '@mui/material';

const GroupDetailsSummary = ({ group }) => {
  const formatGroupType = () => {
    return group.isTemporary ? (
      <Box>Tymczasowa</Box>
    ) : (
      <Box>
        Rok {group.yearbook}/{group.yearbook + 1}
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
      <GroupAvatar group={group} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 100%',
          ml: 2
        }}>
        <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Grupa {group.name}
        </Typography>
        {formatGroupType()}
      </Box>
    </Box>
  );
};

GroupDetailsSummary.propTypes = {
  group: PropTypes.object.isRequired
};

export default GroupDetailsSummary;
