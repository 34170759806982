import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import RichTooltip from '../../richTooltip/richTooltip';
import PersonAvatar from '../../forms/PersonAvatar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FaceIcon from '@mui/icons-material/Face';

const KidsList = ({ childrenArray, placement = 'top' }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      onMouseEnter={() => setOpen(!open)}
      onMouseLeave={() => setOpen(!open)}
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'end',
        cursor: 'help'
      }}>
      <FaceIcon
        tabIndex={0}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        sx={{ fontSize: 28, mr: 1, color: (theme) => theme.palette.color.contrast }}
      />
      <Typography component="h3" fontWeight="medium" sx={{ color: (theme) => theme.palette.color.contrast }}>
        Dzieci
      </Typography>
      <RichTooltip
        content={
          <List id={open ? 'lista dzieci' : null}>
            {Array.isArray(childrenArray) &&
              childrenArray.length &&
              childrenArray.map((child) => {
                return (
                  <ListItem component="li" key={child.id}>
                    <ListItemAvatar>
                      <PersonAvatar
                        initials
                        alt={child.firstName}
                        url={child.photoUrl}
                        firstName={child.firstName}
                        lastName={child.lastName}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${child.firstName} ${child.lastName}`}
                      primaryTypographyProps={{
                        sx: {
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }
                      }}
                    />
                  </ListItem>
                );
              })}
          </List>
        }
        open={open}
        placement={placement}
        onClose={() => setOpen(false)}>
        <InfoOutlinedIcon sx={{ fontSize: 28, ml: 1, color: (theme) => theme.palette.color.contrast }} />
      </RichTooltip>
    </Box>
  );
};

KidsList.propTypes = {
  childrenArray: PropTypes.array.isRequired
};

export default KidsList;
