/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function psychologicalSchemesReducer(state = initialState.psychologicalSchemes, action) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_SCHEMES_LIST: {
      return action.data;
    }
    case types.DELETE_PSYCHOLOGICAL_SCHEMES: {
      const newState = Object.assign({}, state);
      newState[action.data.fieldName] = newState[action.data.fieldName].filter((el) => el.id !== action.data.id);

      return newState;
    }
    case types.ADD_PSYCHOLOGICAL_SCHEMES: {
      let newState = Object.assign({}, state);
      if (!!newState[action.data.fieldName]) newState[action.data.fieldName].push(action.data);
      else newState[action.data.fieldName] = [action.data];
      return newState;
    }
    case types.EDIT_PSYCHOLOGICAL_SCHEMES: {
      let newState = Object.assign({}, state);
      const dataIndex = newState[action.data.fieldName].findIndex((data) => data.id === action.data.id);
      newState[action.data.fieldName][dataIndex] = action.data;
      return newState;
    }

    default:
      return state;
  }
}
