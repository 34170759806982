const metadata = {
  indexRoute: {
    title: 'Pulpit',
    description: null,
    keyWords: null
  },
  unmatched: {
    title: null,
    description: null,
    keyWords: null
  },
  home: {
    title: null,
    description: null,
    keyWords: null
  },
  signIn: {
    title: 'Logowanie - 4Parents',
    description: null,
    keyWords: null,
    css: 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'
  },
  accountActivation: {
    title: null,
    description: null,
    keyWords: null
  },
  passwordRecovery: {
    title: null,
    description: null,
    keyWords: null
  },
  passwordExpired: {
    title: null,
    description: null,
    keyWords: null
  },
  passwordReset: {
    title: null,
    description: null,
    keyWords: null
  },
  userProfile: {
    title: null,
    description: null,
    keyWords: null
  },
  groups: {
    title: 'Grupy',
    description: null,
    keyWords: null
  },
  groupsArchive: {
    title: null,
    description: null,
    keyWords: null
  },
  groupRegister: {
    title: null,
    description: null,
    keyWords: null
  },
  groupDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  groupArchiveDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  activities: {
    title: 'Zajęcia dodatkowe',
    description: null,
    keyWords: null
  },
  activitiesArchive: {
    title: null,
    description: null,
    keyWords: null
  },
  activityRegister: {
    title: null,
    description: null,
    keyWords: null
  },
  activityDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  activityArchiveDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  children: {
    title: 'Rejestr dzieci',
    description: null,
    keyWords: null
  },
  student: {
    title: 'Rejestr uczniów',
    description: null,
    keyWords: null
  },
  childrenArchive: {
    title: null,
    description: null,
    keyWords: null
  },
  studentArchive: {
    title: null,
    description: null,
    keyWords: null
  },
  childRegister: {
    title: null,
    description: null,
    keyWords: null
  },
  studentRegister: {
    title: null,
    description: null,
    keyWords: null
  },
  childDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  studentDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  meals: {
    title: 'Posiłki',
    description: null,
    keyWords: null
  },
  mealRegister: {
    title: 'Rejestracja posiłu',
    description: null,
    keyWords: null
  },
  mealDetails: {
    title: 'Szczegóły posiłku',
    description: null,
    keyWords: null
  },
  mealsArchive: {
    title: 'Zarchiwizowane posiłki',
    description: null,
    keyWords: null
  },
  diets: {
    title: 'Diety',
    description: null,
    keyWords: null
  },
  dietDetails: {
    title: 'Szczegóły diety',
    description: null,
    keyWords: null
  },
  createDiet: {
    title: 'Nowa dieta',
    description: null,
    keyWords: null
  },
  childFinancialDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  staffFinancialDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  childSummary: {
    title: null,
    description: null,
    keyWords: null
  },
  childArchiveDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  legalGuardians: {
    title: 'Opiekunowie prawni',
    description: null,
    keyWords: null
  },
  staffMembers: {
    title: 'Konfiguracja - pracownicy',
    description: null,
    keyWords: null
  },
  staffMembersArchive: {
    title: null,
    description: null,
    keyWords: null
  },
  staffMemberRegister: {
    title: null,
    description: null,
    keyWords: null
  },
  staffMemberDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  staffMemberArchiveDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  messageBoard: {
    title: 'Tablica ogłoszeń',
    description: null,
    keyWords: null
  },
  messageBoardEvents: {
    title: null,
    description: null,
    keyWords: null
  },
  privateMessages: {
    title: 'Wszystkie wiadomości',
    description: null,
    keyWords: null
  },
  staffMemberPrivateMessages: {
    title: 'Wiadomości',
    description: null,
    keyWords: null
  },
  guardianPrivateMessages: {
    title: 'Wiadomości',
    description: null,
    keyWords: null
  },
  eventsCalendar: {
    title: 'Kalendarz wydarzeń',
    description: null,
    keyWords: null
  },
  attendance: {
    title: 'Dziennik obecności',
    description: null,
    keyWords: null
  },
  attendanceMonthlyHistory: {
    title: 'Dziennik obecności-Historia',
    description: null,
    keyWords: null
  },
  absentTomorrow: {
    title: 'Dziennik obecności-Nieobecne jutro',
    description: null,
    keyWords: null
  },
  attendanceDailyHistory: {
    title: null,
    description: null,
    keyWords: null
  },
  activitiesAttendanceSheetPage: {
    title: null,
    description: null,
    keyWords: null
  },
  returnFromOnlinePayments: {
    title: null,
    description: null,
    keyWords: null
  },
  guardianDues: {
    title: 'Opłaty',
    description: null,
    keyWords: null
  },
  guardianDueDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  guardianChildAttendance: {
    title: null,
    description: null,
    keyWords: null
  },
  guardianChildDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  configurationUnit: {
    title: 'Konfiguracja - placówka',
    description: null,
    keyWords: null
  },
  configurationSettlementsSettings: {
    title: 'Konfiguracja - rozliczeń',
    description: null,
    keyWords: null
  },
  configurationDaysOff: {
    title: 'Konfiguracja - dni wolne',
    description: null,
    keyWords: null
  },
  configurationChargingSchemes: {
    title: 'Konfiguracja - schematy płatności',
    description: null,
    keyWords: null
  },
  configurationReliefs: {
    title: 'Konfiguracja - ulgi',
    description: null,
    keyWords: null
  },
  configurationAttributesSets: {
    title: 'Konfiguracja - atrybuty',
    description: null,
    keyWords: null
  },
  configurationGuardiansPinService: {
    title: 'Konfiguracja - urządzenia do rejestracji obecności',
    description: null,
    keyWords: null
  },
  configurationDaycare: {
    title: 'Konfiguracja - aktywność dzieci',
    description: null,
    keyWords: null
  },
  settlements: {
    title: 'Rozliczenia - grupy',
    description: null,
    keyWords: null
  },
  settlementsGroup: {
    title: 'Rozliczenia - grupy',
    description: null,
    keyWords: null
  },
  settlementsExport: {
    title: null,
    description: null,
    keyWords: null
  },
  fkExport: {
    title: null,
    description: null,
    keyWords: null
  },
  symphonyExport: {
    title: 'Rozliczenia - symfonia',
    description: null,
    keyWords: null
  },
  settlementsImport: {
    title: 'Rozliczenia - import',
    description: null,
    keyWords: null
  },
  settlementsGroupDetails: {
    title: 'Rozliczenia - dzieci',
    description: null,
    keyWords: null
  },
  staffMembersSettlements: {
    title: 'Rozliczenia - pracownicy',
    description: null,
    keyWords: null
  },
  childDueDetails: {
    title: null,
    description: null,
    keyWords: null
  },
  reports: {
    title: 'Raporty',
    description: null,
    keyWords: null
  },
  configurationNumberingSchemes: {
    title: 'Konfiguracja - serie numeracji',
    description: null,
    keyWords: null
  },
  configurationReports: {
    title: 'Konfiguracja raporty',
    description: null,
    keyWords: null
  },
  pinSignIn: {
    title: null,
    description: null,
    keyWords: null
  },
  pinKeypad: {
    title: null,
    description: null,
    keyWords: null
  },
  pinEntries: {
    title: null,
    description: null,
    keyWords: null
  },
  gallery: {
    title: 'Galeria',
    description: null,
    keyWords: null
  },
  daycare: {
    title: 'Aktywność dzieci',
    description: null,
    keyWords: null
  },
  electronicDiaryTimeTable: {
    title: 'Dziennik elektroniczny - ramowy rozkład dnia',
    description: null,
    keyWords: null
  },
  electronicDiarySaveWork: {
    title: 'Dziennik elektroniczny - zapis pracy wychowawczo-dydaktycznej',
    description: null,
    keyWords: null
  },
  electronicDiaryTrip: {
    title: 'Dziennik elektroniczny - wycieczki',
    description: null,
    keyWords: null
  },
  electronicDiaryAdditionalActivities: {
    title: 'Dziennik elektroniczny - zajęcia dodatkowe',
    description: null,
    keyWords: null
  },
  electronicDiaryWorkWithParents: {
    title: 'Dziennik elektroniczny - roczny plan współpracy z rodzicami',
    description: null,
    keyWords: null
  },
  electronicDiaryWorkPlan: {
    title: 'Dziennik elektroniczny - plan Pracy',
    description: null,
    keyWords: null
  },
  configurationDiagnosisTemplate: {
    title: 'Konfiguracja - szablony diagnoz',
    description: null,
    keyWords: null
  },
  electronicDiaryMessageBoard: {
    title: null,
    description: null,
    keyWords: null
  },
  entrances: {
    title: 'Konfiguracja - wejścia',
    description: null,
    keyWords: null
  },
  pinPage: {
    title: null,
    description: null,
    keyWords: null
  },
  guardianApplications: {
    title: 'Wnioski',
    description: null,
    keyWords: null
  },
  applications: {
    title: 'Wnioski',
    description: null,
    keyWords: null
  },
  events: {
    title: 'Rejestr zdarzeń',
    description: null,
    keyWords: null
  },
  changeLog: {
    title: 'Co nowego?',
    description: null,
    keyWords: null
  },
  authorizedBySign: {
    title: 'Zatwierdzanie przez podpis',
    description: null,
    keyWords: null
  },
  loginHistory: {
    title: 'Historia logowań użytkowników'
  },
  currentUsers: {
    title: 'Zalogowani użytkownicy'
  },
  overdueList: {
    title: 'Zaległe opłaty'
  },
  overpaymentsList: {
    title: 'Nadpłaty'
  },
  childrenOnVacationPeriod: {
    title: 'Dzieci objęte okresem urlopowania'
  },
  expiringReliefs: {
    title: 'Wygasające ulgi'
  },
  takenOverByDebtCollection: {
    title: 'Przejęte przez windykację'
  },
  loginGov: {
    title: 'Trwa logowanie...'
  },
  statuteChanged: {
    title: 'Zmiana w regulaminie'
  },
  userManual: {
    title: 'Instrukcja obsługi'
  },
  staffCalendar: {
    title: 'Kalendarz wydarzeń - pracownicy'
  },
  staffMessageBoard: {
    title: 'Ogłoszenia - pracownicy'
  },
  scheduledTransfer: {
    title: 'Zaplanowane przeniesienia'
  },
  virtualDisc: {
    title: 'Dysk wirtualny'
  },
  authorizedToPickUpChild: {
    title: 'Osoby upoważnione do odbioru dziecka'
  },
  mobileDownload: {
    title: 'Pobierz aplikację mobilną'
  },
  childrenImport: {
    title: 'Import dzieci (csv)'
  },
  psychologicalGroups: {
    title: 'Grupy pomocy psychologiczno pedagogicznej',
    description: null,
    keyWords: null
  },
  psychologicalHelp: {
    title: 'Pomoc psychologiczno-pedagogiczna',
    description: null,
    keyWords: null
  },
  psychologicalSchemes: {
    title: 'Schematy pomocy psychologiczno-pedagogicznej',
    description: null,
    keyWords: null
  },
  psychologicalHelpChild: {
    title: 'Pomoc psychologiczno-pedagogiczna',
    description: null,
    keyWords: null
  },
  individualClasses: {
    title: 'Dziennik zajęć indywidualnych',
    description: null,
    keyWords: null
  },
  individualClassesDetails: {
    title: 'Dziennik zajęć indywidualnych',
    description: null,
    keyWords: null
  },
  groupClasses: {
    title: 'Dziennik zajęć grupowych',
    description: null,
    keyWords: null
  }
};

export default metadata;
