import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, MenuItem } from '@mui/material';
import SelectField from '../../common/SelectField';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ReportTypes } from '../../../constants/reportTypes';

const sortOptions = [
  { name: 'Po nazwisku', value: 'name' },
  { name: 'Po numerze', value: 'documentNumber' }
];

const DetailedPaymentsSummaryPerChildReport = ({ month, year, onChange, groups, errors }) => {
  const [sortBy, setSortBy] = useState('name');
  const [dueType, setDueType] = useState(ChargingReportTypes.stay.value);
  const [dontShowNegativeValues, setDontShowNegativeValues] = useState(false);
  const [totalPaymentsAmount, setTotalPaymentsAmount] = useState(false);

  const handleChange = (name, value) => {
    onChange({ target: { name, value } });
  };

  return (
    <Box>
      <GroupAndMonthTemplate
        errors={errors}
        manyGroupsSelector
        month={month}
        year={year}
        onChange={onChange}
        groups={groups}
        legend={ReportTypes.detailedPaymentsSummaryPerChild.name}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.detailedPaymentsSummaryPerChild.name}`}>
        <Typography variant="h6" sx={{ pt: 2 }}>
          Ustawienia raportu
        </Typography>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden' }}
          floatingLabelText="Rodzaj opłat"
          id="dueType"
          value={dueType}
          onChange={(event) => {
            setDueType(event.target.value);
            handleChange('dueType', event.target.value);
          }}
        >
          {Object.values(ChargingReportTypes).map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden', mt: 2 }}
          floatingLabelText="Sortowanie"
          id="sortBy"
          value={sortBy}
          onChange={(event) => {
            setSortBy(event.target.value);
            handleChange('sortBy', event.target.value);
          }}
        >
          {sortOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </SelectField>
        <Checkbox
          label="Pokazuj kwoty zmniejszeń, odpisów oraz ulg bez minusów"
          defaultChecked={dontShowNegativeValues}
          onCheck={(event, isChecked) => {
            setDontShowNegativeValues(isChecked);
            handleChange('dontShowNegativeValues', isChecked);
          }}
          sx={{ mt: 2 }}
        />
        <Checkbox
          label="Wyświetl ogólną sumę wpłat niezależnie od wybranego okresu"
          defaultChecked={totalPaymentsAmount}
          onCheck={(event, isChecked) => {
            setTotalPaymentsAmount(isChecked);
            handleChange('totalPaymentsAmount', isChecked);
          }}
          sx={{ mt: 2 }}
        />
      </FieldsetWrapper>
    </Box>
  );
};

DetailedPaymentsSummaryPerChildReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DetailedPaymentsSummaryPerChildReport;
