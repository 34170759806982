/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { LegalGuardianModel } from '../models/children/ChildModels';

export default function legalGuardiansReducer(state = initialState.legalGuardians, action) {
  switch (action.type) {
    case types.LOAD_LEGAL_GUARDIANS_SUCCESS: {
      return action.legalGuardians.map((legalGuardian) => new LegalGuardianModel().assign(legalGuardian));
    }
    case types.REMOVE_LEGAL_GUARDIAN_SUCCESS: {
      return [...state.filter((g) => g.id !== action.legalGuardianId)];
    }
    default:
      return state;
  }
}
