import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DiagnosticSchemaSetModel } from '../models/configuration/diagnosticSchemes/DiagnosticSchemeSetModel';

export default function diagnosticSchemesSetsReducer(state = initialState.configuration.diagnosticSchemesSets, action) {
  const { type, schema } = action;
  switch (type) {
    case types.LOAD_DIAGNOSTICSCHEMES_SETS_SUCCESS: {
      return schema.map((s) => new DiagnosticSchemaSetModel().assign(s));
    }
    case types.CREATE_DIAGNOSTICSCHEMES_SET_SUCCESS: {
      return [...state, new DiagnosticSchemaSetModel().assign(schema)];
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_SET_SUCCESS: {
      return [...state.map((g) => (g.id === schema.id ? new DiagnosticSchemaSetModel().assign(schema) : g))];
    }
    case types.REMOVE_DIAGNOSTICSCHEMES_SET_SUCCESS: {
      return state.filter((g) => g.id !== schema.id);
    }
    default:
      return state;
  }
}
