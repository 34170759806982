/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function attendanceUiReducer(state = initialState.attendanceUi, action) {
  switch (action.type) {
    //drop
    case types.REPORT_CHILD_DROPPED_OFF: {
      return Object.assign({}, state, { dropOff: UiModel.start() });
    }
    case types.REPORT_CHILD_DROPPED_OFF_CANCEL: {
      return Object.assign({}, state, { dropOff: UiModel.cancel() });
    }
    case types.REPORT_CHILD_DROPPED_OFF_SUBMIT: {
      return Object.assign({}, state, { dropOff: UiModel.submit() });
    }
    case types.REPORT_CHILD_DROPPED_OFF_SUCCESS: {
      return Object.assign({}, state, { dropOff: UiModel.success() });
    }
    case types.REPORT_CHILD_DROPPED_OFF_FAILURE: {
      return Object.assign({}, state, { dropOff: UiModel.failure(action.errors) });
    }
    //pick
    case types.REPORT_CHILD_PICKED_UP: {
      return Object.assign({}, state, { pickUp: UiModel.start() });
    }
    case types.REPORT_CHILD_PICKED_UP_CANCEL: {
      return Object.assign({}, state, { pickUp: UiModel.cancel() });
    }
    case types.REPORT_CHILD_PICKED_UP_SUBMIT: {
      return Object.assign({}, state, { pickUp: UiModel.submit() });
    }
    case types.REPORT_CHILD_PICKED_UP_SUCCESS: {
      return Object.assign({}, state, { pickUp: UiModel.success() });
    }
    case types.REPORT_CHILD_PICKED_UP_FAILURE: {
      return Object.assign({}, state, { pickUp: UiModel.failure(action.errors) });
    }
    // legal
    case types.REPORT_CHILD_ABSENCE: {
      return Object.assign({}, state, { absence: UiModel.start() });
    }
    case types.REPORT_CHILD_ABSENCE_CANCEL: {
      return Object.assign({}, state, { absence: UiModel.cancel() });
    }
    case types.REPORT_CHILD_ABSENCE_SUBMIT: {
      return Object.assign({}, state, { absence: UiModel.submit() });
    }
    case types.REPORT_CHILD_ABSENCE_SUCCESS: {
      return Object.assign({}, state, { absence: UiModel.success() });
    }
    case types.REPORT_CHILD_ABSENCE_FAILURE: {
      return Object.assign({}, state, { absence: UiModel.failure(action.errors) });
    }
    //
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE: {
      return Object.assign({}, state, { revertAttendance: UiModel.start() });
    }
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE_CANCEL: {
      return Object.assign({}, state, { revertAttendance: UiModel.cancel() });
    }
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT: {
      return Object.assign({}, state, { revertAttendance: UiModel.submit() });
    }
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS: {
      return Object.assign({}, state, { revertAttendance: UiModel.success() });
    }
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE_FAILURE: {
      return Object.assign({}, state, { revertAttendance: UiModel.failure(action.errors) });
    }
    //
    case types.REPORT_CHILD_ACTIVITY_ABSENCE_SUBMIT: {
      return Object.assign({}, state, { absence: UiModel.submit() });
    }
    case types.REPORT_CHILD_ACTIVITY_ABSENCE_SUCCESS: {
      return Object.assign({}, state, { absence: UiModel.success() });
    }
    case types.REPORT_CHILD_ACTIVITY_ABSENCE_FAILURE: {
      return Object.assign({}, state, { absence: UiModel.failure(action.errors) });
    }

    case types.REPORT_CHILD_ACTIVITY_ATTENDANCE_SUBMIT: {
      return Object.assign({}, state, { attendance: UiModel.submit() });
    }
    case types.REPORT_CHILD_ACTIVITY_ATTENDANCE_SUCCESS: {
      return Object.assign({}, state, { attendance: UiModel.success() });
    }
    case types.REPORT_CHILD_ACTIVITY_ATTENDANCE_FAILURE: {
      return Object.assign({}, state, { attendance: UiModel.failure(action.errors) });
    }

    //
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE: {
      return Object.assign({}, state, { reportAttendance: UiModel.start() });
    }
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE_CANCEL: {
      return Object.assign({}, state, { reportAttendance: UiModel.cancel() });
    }
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT: {
      return Object.assign({}, state, { reportAttendance: UiModel.submit() });
    }
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS: {
      return Object.assign({}, state, { reportAttendance: UiModel.success() });
    }
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE_FAILURE: {
      return Object.assign({}, state, { reportAttendance: UiModel.failure(action.errors) });
    }
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.attendanceUi);
    }
    default:
      return state;
  }
}
