import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import EmptyState from '../../common/EmptyState';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import { VacationPeriodModelValidator } from '../../../models/children/ChildModelsValidator';
import { Box } from '@mui/material';
import ChildVacationPeriodForm from './ChildVacationPeriodForm';
import ChildVacationPeriodsList from './ChildVacationPeriodsList';

const ChildVacationPeriodsCard = (props) => {
  const firstMonthOfCurrentYear = useMemo(() => {
    return new Date().getFullYear() + '-01';
  }, []);
  const [vacationPeriod, setVacationPeriod] = useState({
    vacationPeriodStart: firstMonthOfCurrentYear,
    vacationPeriodEnd: firstMonthOfCurrentYear
  });
  const [errors, setErrors] = useState({});

  const handleCreate = () => {
    props.onAdd();
  };

  const handleSave = () => {
    props.onSave(vacationPeriod);
  };

  const renderReadonlyForm = () => {
    return (
      <ChildVacationPeriodsList
        vacationPeriods={props.vacationPeriods}
        onRemove={(chosenVacationPeriod) => props.onRemove(chosenVacationPeriod)}
      />
    );
  };

  const renderEditForm = () => {
    return (
      <ChildVacationPeriodForm
        onStartMonthChange={(vacationPeriodStart) => setVacationPeriod({ ...vacationPeriod, vacationPeriodStart })}
        onEndMonthChange={(vacationPeriodEnd) => setVacationPeriod({ ...vacationPeriod, vacationPeriodEnd })}
        startMonth={vacationPeriod.vacationPeriodStart}
        endMonth={vacationPeriod.vacationPeriodEnd}
        errors={errors}
        readonly={false}
      />
    );
  };

  return (
    <Box>
      <CardDialogEditForm
        title="Okres urlopowania"
        isInitiallyExpanded={props.isInitiallyExpanded}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        onAction={() => handleCreate()}
        onCancel={() => props.onCancel()}
        onValidate={() => new VacationPeriodModelValidator().validate(vacationPeriod)}
        onValidationDone={(err) => setErrors(err)}
        onSave={handleSave}
        actionLabel="Dodaj okres urlopowania"
        statePathToUi="childDetailsUi.vacationPeriods"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Brak okresów urlopowania" contrast />}
        editForm={renderEditForm()}
        isEmpty={props.vacationPeriods.length < 1}
      />
    </Box>
  );
};

ChildVacationPeriodsCard.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default ChildVacationPeriodsCard;
