import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ReliefType } from '../../../constants/reliefType';
import { getConstantByValue } from '../../../utils/getConstantByValue';
import numberFormatter from '../../../utils/numberFormatter';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';
import _ from 'lodash';

import { List, ListItem, ListItemText, Button, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { connect } from 'react-redux';

const ChargingSchemesList = ({ allChargingSchemes, allReliefs, assignedChargingSchemes, onEdit, onRemove }) => {
  const getReliefDescription = (reliefId) => {
    const relief = _.find(allReliefs, (r) => r.id === reliefId);
    return `Ulga ${relief?.name} ${numberFormatter.format(relief?.rate)} ${
      relief?.type === ReliefType.fixedAmount.value ? 'zł' : '%'
    }`;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <List>
      {_.orderBy(assignedChargingSchemes, ['reliefId']).map((chargingScheme) => {
        const reportType = getConstantByValue(
          ChargingReportTypes,
          _.find(allChargingSchemes, (s) => s.id === chargingScheme.schemeId)?.reportType
        )?.name;

        const relief = chargingScheme.reliefId ? getReliefDescription(chargingScheme.reliefId) : 'Brak ulg';
        return (
          <ListItem sx={{ pl: 0, my: 2 }} key={chargingScheme.schemeId}>
            <ListItemText
              primary={_.find(allChargingSchemes, (s) => s.id === chargingScheme.schemeId)?.name}
              primaryTypographyProps={{
                fontWeight: (theme) => theme.typography.fontWeightBold,
                display: 'flex',
                flexWrap: 'wrap'
              }}
              secondary={`${reportType} | ${relief}`}
              secondaryTypographyProps={{
                color: (theme) => theme.palette.color.contrast,
                display: 'flex',
                flexWrap: 'wrap'
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: isMobile ? 'column' : 'row',
                mr: -5
              }}>
              <ActionConfirmationButton
                actionLabel="usuń"
                variant="text"
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  color: (theme) => theme.palette.color.color25,
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}
                confirmationText="Czy na pewno chcesz odpiąć schemat płatności?"
                confirmationTitle="Usuń schemat płatności"
                isSecondary={true}
                confirmLabel="usuń"
                onConfirm={() => onRemove(chargingScheme.schemeId)}
                onCancel={() => {}}
              />
              <Button
                variant="text"
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  color: (theme) => theme.palette.color.color7,
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}
                onClick={() => onEdit(chargingScheme)}>
                edytuj
              </Button>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

ChargingSchemesList.propTypes = {
  allChargingSchemes: PropTypes.array.isRequired,
  allReliefs: PropTypes.array.isRequired,
  assignedChargingSchemes: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ChargingSchemesList;
