import React from 'react';
import PropTypes from 'prop-types';
import DialogForm from '../forms/DialogForm';

const ArchiveStudentDialog = ({ pupil, onArchive, onCancel, isDialogOpen, isProcessing }) => {
  return (
    <DialogForm
      maxWidth="sm"
      header={`Archiwizowanie ucznia - ${pupil.firstName} ${pupil.lastName}`}
      onSave={() => onArchive()}
      onCancel={onCancel}
      saveLabel="Archiwizuj"
      isDialogOpen={isDialogOpen}
      isProcessing={isProcessing}
      noValidate
    />
  );
};

ArchiveStudentDialog.propTypes = {
  onArchive: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  pupil: PropTypes.object.isRequired
};

export default ArchiveStudentDialog;
