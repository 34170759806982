/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ActivityArchiveModel } from '../models/activities/ActivityArchiveModels';

export default function activitiesArchiveReducer(state = initialState.archives.activities, action) {
  switch (action.type) {
    case types.LOAD_ACTIVITIES_ARCHIVE_SUCCESS: {
      return action.activities.map((activity) => new ActivityArchiveModel().assign(activity));
    }
    default:
      return state;
  }
}
