import MealsApi from '../api/MealsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';

export function loadMealsCategoriesAsync() {
  return async (dispatch) => {
    try {
      const mealsCategories = await MealsApi.getMealCategories();
      dispatch({ type: types.LOAD_MEALS_CATEGORIES_SUCCESS, mealsCategories });
    } catch (e) {
      dispatch({ type: types.LOAD_MEALS_CATEGORIES_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać kategorii posiłków'));
    }
  };
}

export function createMealCategoryAsync(mealCategory) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.createMealCategory(mealCategory);
      dispatch(loadMealsCategoriesAsync());
      dispatch(notificationActions.showSuccess(`Utworzono kategorię posiłków ${mealCategory.name}`));
    } catch (e) {
      dispatch({ type: types.CREATE_MEAL_CATEGORY_FAILURE });
      dispatch(notificationActions.showError('Nie udało się utworzyć kategorii posiłków'));
      throw e;
    }
  };
}

export function addMealToCategory(categoryId, meal) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.addMealToCategory(categoryId, meal);
      dispatch(loadMealsCategoriesAsync());
      dispatch(notificationActions.showSuccess(`Dodano posiłek ${meal.name} do kategorii`));
    } catch (e) {
      dispatch({ type: types.ADD_MEAL_TO_CATEGORY_FAILURE });
      dispatch(notificationActions.showError('Nie udało się dodać posiłku do kategorii'));
      throw e;
    }
  };
}

export function removeMealFromCategory(categoryId, mealId) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.removeMealFromCategory(categoryId, mealId);
      dispatch(loadMealsCategoriesAsync());
      dispatch(notificationActions.showSuccess('Usunięto posiłek z kategorii'));
    } catch (e) {
      dispatch({ type: types.REMOVE_MEAL_FROM_CATEGORY_FAILURE });
      dispatch(notificationActions.showError('Nie udało się usunąć posiłku z kategorii'));
      throw e;
    }
  };
}

export function deleteMealCategoryAsync(categoryId) {
  return async (dispatch) => {
    try {
      await MealsApi.deleteMealCategory(categoryId);
      dispatch(loadMealsCategoriesAsync());
      dispatch(notificationActions.showSuccess('Usunięto kategorię'));
    } catch (e) {
      if (e.status === 412) {
        dispatch({ type: types.DELETE_MEAL_CATEGORY_FAILURE });
        dispatch(
          notificationActions.showError(`Nie udało się usunąć kategorii, ${e.response.body.generalError.toLowerCase()}`)
        );
        throw new Error(`Nie udało się usunąć kategorii, ${e.response.body.generalError.toLowerCase()}`);
      } else {
        dispatch({ type: types.DELETE_MEAL_CATEGORY_FAILURE });
        dispatch(notificationActions.showError('Nie udało się usunąć kategorii'));
        throw new Error(`Nie udało się usunąć kategorii`);
      }
    }
  };
}
