import React from 'react';
import { Box, Button, List, ListItem } from '@mui/material';
import PropTypes from 'prop-types';
import Dialog from '../common/Dialog';
import ListItemText from '@mui/material/ListItemText';
import config from '../../config/config';
import insuranceLogo from '../../img/logo_b_1280.svg';
import insuranceLogoWhiteUrl from '../../img/logo_b_1280_white.svg';
import { useThemeMode } from '../../hooks/useThemeMode';
import { themeTypes } from '../../styles/appTheme';

const InsuranceInfoDialog = ({ open, onClose, insurance }) => {
  const themeMode = useThemeMode();
  const handleNavigateToInsurancePage = () => {
    window.open(config.insuranceUrl + insurance.insuranceCode, '_blank');
  };

  const actions = [
    <Button variant="contained" aria-label="Anuluj" key={1} onClick={onClose}>
      Anuluj
    </Button>,
    <Button variant="contained" aria-label="Kup ubezpieczenie" key={2} onClick={handleNavigateToInsurancePage}>
      Kup ubezpieczenie
    </Button>
  ];
  return (
    /*eslint no-return-assign: 0 */
    /*eslint react/jsx-no-bind: 0 */
    <Box>
      <Dialog title="Informacje o ubezpieczycielu" actions={actions} modal={false} open={open} onRequestClose={onClose}>
        <img
          style={{ width: '100%' }}
          src={themeMode !== themeTypes.dark.value ? insuranceLogo : insuranceLogoWhiteUrl}
          alt="Logo ubezpieczyciela"
        />
        <List>
          <ListItem>
            <ListItemText primary="Imię i nazwisko ubezpieczyciela" secondary={insurance.insurerName} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Telefon ubezpieczyciela" secondary={insurance.insurerPhone} />
          </ListItem>
        </List>
      </Dialog>
    </Box>
  );
};

InsuranceInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  insurance: PropTypes.object.isRequired
};

export default InsuranceInfoDialog;
