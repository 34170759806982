import PropTypes from 'prop-types';
import React from 'react';
import { Button, useMediaQuery, useTheme, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const SaveCancelButtons = ({
  inProgress,
  onSave,
  onCancel,
  saveLabel,
  cancelLabel,
  saveDisabled,
  type = null,
  children = null,
  hideSubmit,
  hideCancel
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column-reverse' : 'row'}
      justifyContent={isMobile ? 'center' : 'flex-end'}>
      {!hideCancel && (
        <Button
          sx={{ ml: 1, mb: { xs: 2, sm: 0 }, color: (theme) => theme.palette.color.contrast }}
          onClick={onCancel}
          disabled={inProgress}
          variant="outlinedContrast">
          {cancelLabel}
        </Button>
      )}
      {children}
      {!hideSubmit && (
        <LoadingButton
          sx={{ ml: 1 }}
          variant="contained"
          onClick={onSave}
          type={type}
          loading={inProgress}
          disabled={saveDisabled}>
          {saveLabel}
        </LoadingButton>
      )}
    </Box>
  );
};

SaveCancelButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  inProgress: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  hideSubmit: PropTypes.bool
};

SaveCancelButtons.defaultProps = {
  saveLabel: 'Zapisz',
  cancelLabel: 'Anuluj'
};

export default SaveCancelButtons;
