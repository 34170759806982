import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reliefsActions from '../../../actions/reliefsActions';
import ReliefDialog from './dialogs/ReliefDialog';
import { ReliefModel } from '../../../models/configuration/reliefs/ReliefModels';
import ReliefsList from './ReliefsList';
import { Box, Button, Paper } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import EmptyState from '../../common/EmptyState';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import { useState } from 'react';

const ReliefsPage = (props) => {
  const [currentRelief, setCurrentRelief] = useState();

  const handleCreate = () => {
    setCurrentRelief(new ReliefModel());
    props.actions.createReliefDetailsStart();
  };

  const handleSave = (relief) => {
    props.actions.createReliefDetailsAsync(relief);
  };

  const handleCancel = () => {
    props.actions.createReliefDetailsCancel();
    setCurrentRelief(null);
  };

  const handleArchive = (reliefId) => {
    props.actions.archiveReliefDetailsAsync(reliefId);
  };

  const renderReliefDialog = () => {
    if (props.ui.isEditing) {
      return (
        <ReliefDialog
          onSave={handleSave}
          onCancel={handleCancel}
          isDialogOpen={props.ui.isEditing}
          isProcessing={props.ui.isProcessing}
          relief={currentRelief}
        />
      );
    }
    return null;
  };
  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Ulgi" titleIcon={<MoneyOffIcon />} />
        <ContentWrapper>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" aria-label="Dodaj ulgę" onClick={handleCreate} sx={{ mb: 2 }}>
              Dodaj ulgę
            </Button>
          </Box>
          <Paper sx={{ p: 2 }}>
            {props.reliefs.length === 0 ? (
              <EmptyState contrast message="Nie zdefiniowano jeszcze żadnych ulg" />
            ) : (
              <ReliefsList reliefs={props.reliefs} onRemove={handleArchive} />
            )}
          </Paper>
          {renderReliefDialog()}
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

ReliefsPage.propTypes = {
  reliefs: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reliefs: state.configuration.reliefs,
    ui: state.configuration.reliefsUi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(reliefsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReliefsPage);
