import staffMemberRoles from '../../../constants/reducedStaffMemberRoles';
import { mapStaffRoleToUserRole } from '../../../utils/mapStaffRoleToUserRole';

export const reducedPrepareStaffMemberList = (role, array) => {
  switch (role) {
    case staffMemberRoles.teacher.value:
      return {
        title: 'Nauczyciele',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    default:
      return undefined;
  }
};
