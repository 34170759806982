import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Badge from '../../navigation/menu/Bagde';

const UnreadThreadsBadgeWrapper = (props) => {
  const count = useSelector((state) => state.privateThreads.unreadThreadsIds.length);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={
          ({ mb: 1 },
          (props.style,
          {
            display: 'flex',
            justicyContent: 'space-between',
            fontWeight: (theme) => theme.typography.fontWeightLarge,
            fontSize: '16px'
          }))
        }>
        {props.text}
      </Typography>
      {count > 0 ? <Badge sx={{ ml: 1, mt: -1 }} badgeValue={count} /> : null}
    </Box>
  );
};

export default UnreadThreadsBadgeWrapper;
