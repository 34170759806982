import React from 'react';
import { routePathActions } from '../../../routePathActions';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import DrawerButton from '../DrawerButton/DrawerButton';
import MessageHeader from '../MessageView/MessageHeader';
import MessagesList from '../MessageView/MessagesList';
import SendMessageCard from '../MessageView/SendMessageCard';
import ThreadViewDrawer from '../ThreadView/ThreadViewDrawer';
import CreateThreadButton from '../ThreadView/CreateThreadButton';
import FilterBarV2 from '../../common/FilterBarV2';
import ThreadsList from '../ThreadView/ThreadsList';
import EmptyState from '../../common/EmptyState';
import MailIcon from '@mui/icons-material/Mail';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import * as privateMessagesActions from '../../../actions/privateMessagesActions';
import * as virtualDiscActions from '../../../actions/virtualDiscActions';
import { connect } from 'react-redux';
import withMediaQuery from '../../../hoc/withMediaQuery';
import { prepareThread } from './prepareThread';
import FilterByRole, { FilterByRoleType } from '../../common/filters/filterByRole/FilterByRole';
import { Box, Paper, Typography } from '@mui/material';
import SendMessageDialog from '../components/SendMessageDialog';
import userRoles from '../../../constants/userRoles';

const forbidenRoles = ['digitalDiary', 'teacherSupport', 'canteenWorker'];

class SenderMessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleThreadSelection = this.handleThreadSelection.bind(this);
    this.handleMessageUpdate = this.handleMessageUpdate.bind(this);
    this.handleMessageSelect = this.handleMessageSelect.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
    this.handleMessageDelete = this.handleMessageDelete.bind(this);
    this.handleMessageEdit = this.handleMessageEdit.bind(this);
    this.handleLoadMoreMessages = this.handleLoadMoreMessages.bind(this);
    this.handleLoadMoreThreads = this.handleLoadMoreThreads.bind(this);
    this.handleMessagesLoaded = this.handleMessagesLoaded.bind(this);
    this.getRecipients = this.getRecipients.bind(this);
    this.getTypingParticipant = this.getTypingParticipant.bind(this);
    this.handleThreadsFiltered = this.handleThreadsFiltered.bind(this);
    this.handleFilterThreads = this.handleFilterThreads.bind(this);
    this.handleChangeDrawer = this.handleChangeDrawer.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.threadsGlobalFilter = this.threadsGlobalFilter.bind(this);
    this.handleFilterBy = this.handleFilterBy.bind(this);
    this.handleResetGroups = this.handleResetGroups.bind(this);
    this.handleGifSelected = this.handleGifSelected.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleMultiMessageSubmit = this.handleMultiMessageSubmit.bind(this);
    this.handleMultiMessageSend = this.handleMultiMessageSend.bind(this);

    this.state = {
      thread: null,
      threads: [],
      filteredThreads: [],
      nextMessageText: undefined,
      editingMessageId: undefined,
      isDrawerOpen: false,
      selectedGroup: '',
      gif: '',
      file: null,
      filterBy: FilterByRoleType.ALL,
      searchValue: '',
      isMultiMessageOpen: false,
      multiMessageThreads: [],
      multiMessageRecipients: [],
      multiMessageIsSended: false
    };
  }

  componentDidMount() {
    this.handleThreadSelection(this.props.threads[0]);
    this.handleThreadsFiltered(this.props.threads);
    this.handleMessagesLoaded(this.props.messages, this.props.threads[0]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.messages !== prevProps.messages) {
      this.handleMessagesLoaded(this.props.messages, this.state.thread);
    }
    if (this.props.threads !== prevProps.threads) {
      this.handleThreadsFiltered(this.props.threads);
    }
  }

  handleThreadSelection(thread) {
    this.setState({ searchValue: '', nextMessageText: '' });
    if (thread) {
      this.props.virtualDistActions.fetchFiles();
      this.setState({ thread }, () => {
        if (!thread.threadId) {
          this.props.actions.clearMessages();
        } else {
          this.props.actions.loadMessagesAsync(thread.threadId, 20, 0, 'my');
        }
      });
    }
  }

  getRecipients() {
    const legalsAllowed = this.props.isAllowed(routePathActions.privateMessages.sendToLegalGuardians);
    const staffAllowed = this.props.isAllowed(routePathActions.privateMessages.sendToStaffMembers);

    let recipients = [];
    if (legalsAllowed && staffAllowed) {
      recipients = [...this.props.legalGuardians, ...this.props.staffMembers.filter((s) => s.id !== this.props.userId)];
    } else if (legalsAllowed) {
      recipients = [...this.props.legalGuardians];
    } else if (staffAllowed) {
      recipients = [...this.props.staffMembers.filter((s) => s.id !== this.props.userId)];
    }
    return recipients.filter((recipient) => !forbidenRoles.includes(recipient.role));
  }

  getTypingParticipant() {
    if (!this.state.thread || !this.props.threads) return null;
    const thread = this.props.threads.find((t) => t.threadId === this.state.thread.threadId);
    return thread ? thread.typingParticipant : null;
  }

  handleMessagesLoaded(messages) {
    const lastUnread = messages.find((x) => !x.readByMe);
    if (!lastUnread) return;
    this.props.actions.markMessageRead(lastUnread.threadId, lastUnread.sentAt);
  }

  handleMessageUpdate(nextMessageText) {
    if (!this.state.nextMessageText && nextMessageText && this.state.thread.threadId) {
      this.props.actions.notifyTyping(this.state.thread.threadId);
    }

    if (this.state.nextMessageText && !nextMessageText && this.state.thread.threadId) {
      this.props.actions.notifyNotTyping(this.state.thread.threadId);
    }
    this.setState({ nextMessageText });
  }

  handleMessageSelect(message) {
    this.setState({ nextMessageText: message.content.text, editingMessageId: message.id });
  }

  handleEditCancel() {
    this.setState({ nextMessageText: '', editingMessageId: undefined });
  }

  handleMessageSubmit() {
    if (this.state.thread.threadId) this.props.actions.notifyNotTyping(this.state.thread.threadId);
    let text =
      (!!this.state.nextMessageText ? this.state.nextMessageText : '') + ' ' + (this.state.gif && this.state.gif);
    if (!!this.state.file) {
      text = ` ${this.state.file.fileUrl}?name=${encodeURIComponent(
        this.state.file.name
      )}&mimeType=${encodeURIComponent(this.state.file.mimeType)} `;
    }
    const message = {
      id: this.state.editingMessageId,
      content: {
        text: text
      }
    };
    if (this.state.editingMessageId) {
      this.props.actions.updateMessageAsync(message, this.state.thread, this.props.userId);
      this.setState({ nextMessageText: '', editingMessageId: undefined });
    } else {
      const { threadId } = this.props.actions.sendMessageAsync(message, this.state.thread, this.props.userId);
      this.setState({
        nextMessageText: '',
        editingMessageId: undefined,
        thread: Object.assign({}, this.state.thread, { threadId })
      });
    }
    this.setState(() => ({
      gif: '',
      file: null
    }));
  }

  handleMultiMessageSend(value) {
    let text = (!!value ? value : '') + ' ' + (this.state.gif && this.state.gif);
    if (!!this.state.file) {
      text = ` ${this.state.file.fileUrl}?name=${encodeURIComponent(
        this.state.file.name
      )}&mimeType=${encodeURIComponent(this.state.file.mimeType)} `;
    }
    const message = {
      id: undefined,
      content: {
        text: text
      }
    };
    this.props.actions.sendMultiMessageAsync(message, this.state.multiMessageThreads, this.props.userId);

    this.setState({
      gif: '',
      file: null,
      isMultiMessageOpen: false,
      multiMessageIsSended: true
    });
    this.props.actions.loadMessagesAsync(this.state.thread.threadId, 20, 0, 'my');
  }

  handleMultiMessageSubmit(threads, recipients) {
    this.setState(() => ({
      isMultiMessageOpen: true,
      multiMessageThreads: threads,
      multiMessageRecipients: recipients,
      multiMessageIsSended: false
    }));
  }

  handleMessageDelete(message) {
    this.props.actions.deleteMessageAsync(message, this.state.thread);
  }

  handleMessageEdit(message, content = '', gif = '') {
    const newMessage = Object.assign({}, message);
    newMessage.content = { text: content };
    if (gif) newMessage.content.text += ' ' + gif;
    this.props.actions.updateMessageAsync(newMessage, this.state.thread, message.senderId);
  }

  handleLoadMoreMessages() {
    if (!this.state.thread.threadId) return;

    this.props.actions.loadNextMessagesAsync(this.state.thread.threadId, 10, this.props.messages.length, 'my');
  }

  handleLoadMoreThreads() {
    this.props.actions.loadNextThreadsAsync(10, this.props.threads.length);
  }

  threadsGlobalFilter(threads, selectedGroup, filterBy) {
    if (filterBy === 'GroupChats') return threads.filter((thread) => thread.participants.length > 2);
    if (filterBy === FilterByRoleType.TEACHER) {
      let threadsWithTeachers = threads.filter((item) =>
        item.participants.some((p) => p.id !== this.props.userId && p.role === userRoles.staffMemberTeacher)
      );
      if (selectedGroup) {
        threadsWithTeachers = threadsWithTeachers.filter((thread) =>
          thread.participants.some(
            (p) =>
              p.id !== this.props.userId &&
              p.role === userRoles.staffMemberTeacher &&
              this.props.staffMembers.find((sm) => sm.id === p.id).groups.some((group) => group.id === selectedGroup)
          )
        );
      }
      return threadsWithTeachers;
    }
    if (filterBy === FilterByRoleType.LEGAL_GUARDIAN) {
      let threadsWithGuardians = threads.filter((item) =>
        item.participants.some((p) => p.id !== this.props.userId && p.role === userRoles.legalGuardian)
      );
      if (selectedGroup) {
        threadsWithGuardians = threadsWithGuardians.filter((thread) =>
          thread.participants.some(
            (p) =>
              p.id !== this.props.userId &&
              p.role === userRoles.legalGuardian &&
              this.props.legalGuardians
                .find((sm) => sm.id === p.id)
                .children.some((child) => child.groupId === selectedGroup)
          )
        );
      }
      return threadsWithGuardians;
    }
    if (filterBy !== FilterByRoleType.ALL) {
      return threads.filter((item) => item.personType === filterBy);
    }
    return threads;
  }

  handleThreadsFiltered(filteredThreads) {
    this.setState((prev) => ({
      threads: filteredThreads,
      filteredThreads: this.threadsGlobalFilter(filteredThreads, prev.selectedGroup, prev.filterBy)
    }));
  }

  handleFilterThreads(thread, keyword) {
    return thread.participants.some(
      (p) =>
        p.id !== this.props.userId &&
        (p.fullName.toLowerCase().includes(keyword.toLowerCase()) ||
          p.fullName.split(' ').reverse().join(' ').toLowerCase().includes(keyword.toLowerCase()))
    );
  }

  handleChangeDrawer() {
    this.setState((prevState) => {
      return { isDrawerOpen: !prevState.isDrawerOpen };
    });
  }

  handleSelectGroup(value) {
    this.setState((prev) => ({
      selectedGroup: value,
      filteredThreads: this.threadsGlobalFilter(prev.threads, value, prev.filterBy)
    }));
    this.handleThreadsFiltered(this.props.threads);
  }

  handleResetFilters() {
    this.setState((prev) => ({
      selectedGroup: '',
      filterBy: FilterByRoleType.ALL,
      filteredThreads: this.threadsGlobalFilter(prev.threads, '', FilterByRoleType.ALL)
    }));
  }

  handleFilterBy(e) {
    const newValue = e.target.value;
    const selectedGroup =
      newValue !== FilterByRoleType.LEGAL_GUARDIAN && newValue !== FilterByRoleType.TEACHER
        ? ''
        : this.state.selectedGroup;
    this.setState((prev) => ({
      filterBy: newValue,
      selectedGroup,
      filteredThreads: this.threadsGlobalFilter(prev.threads, selectedGroup, newValue)
    }));
  }

  handleResetGroups() {
    this.setState((prev) => ({
      selectedGroup: '',
      filteredThreads: this.threadsGlobalFilter(prev.threads, '', prev.filterBy)
    }));
  }

  handleGifSelected(gif) {
    this.setState(() => ({
      gif
    }));
  }

  handleFileSelected(file) {
    this.setState(() => ({
      file
    }));
  }

  render() {
    const { userId, isRequiredSize } = this.props;
    const { isDrawerOpen } = this.state;
    const typingParticipant = this.getTypingParticipant();
    return (
      <LoadingRenderWrapper>
        <>
          {isRequiredSize && <DrawerButton closeDrawer={this.handleChangeDrawer} />}
          <Paper
            sx={{
              background: (theme) => theme.palette.components.messenger.background,
              height: '100%',
              mr: 0,
              '@media(min-width: 1200px)': {
                mr: '280px'
              }
            }}>
            {this.state.isMultiMessageOpen && (
              <SendMessageDialog
                onSubmit={this.handleMultiMessageSend}
                open={this.state.isMultiMessageOpen}
                onCancel={() => this.setState({ isMultiMessageOpen: false })}
                legalGuardians={this.props.legalGuardians}
                staffMembers={this.props.staffMembers}
                multiMessageThreads={this.state.multiMessageThreads}
                isGifSelected={this.state.gif}
                onGifSelected={(gifUrl) => {
                  this.handleGifSelected(gifUrl);
                }}
                participants={this.state.multiMessageRecipients.map(
                  (pId) =>
                    this.props.staffMembers.find((sm) => sm.id === pId) ||
                    this.props.legalGuardians.find((lg) => lg.id === pId)
                )}
                recipients={this.getRecipients()}
                onFileSelected={this.handleFileSelected}
                isFileSelected={this.state.file}
                user={this.props.user}
                files={this.props.virtualDiscFolder}
              />
            )}
            {this.state.thread && (
              <Box>
                <MessageHeader
                  thread={this.state.thread}
                  selectUser={(x) => x.participants.filter((p) => p.id !== userId)}
                  display="left"
                />
                <MessagesList
                  messages={this.props.messages}
                  canLoadMore={this.props.canLoadMoreMessages}
                  onLoadMore={this.handleLoadMoreMessages}
                  isLoading={this.props.isLoadingMessages}
                  isInitial={this.props.isInitialLoadingMessages}
                  onSelect={this.handleMessageSelect}
                  userId={this.props.userId}
                  onDelete={this.handleMessageDelete}
                  onUpdate={this.handleMessageEdit}
                  thread={this.state.thread}
                  files={this.props.virtualDisc}
                />
                {typingParticipant && (
                  <Typography sx={{ ml: 1, color: (theme) => theme.palette.components.messenger.messengerText }}>
                    {typingParticipant.fullName} pisze...
                  </Typography>
                )}
                <SendMessageCard
                  maxHeight="50vh"
                  participants={this.state.thread.participants}
                  text={this.state.nextMessageText}
                  onChange={this.handleMessageUpdate}
                  onSubmit={this.handleMessageSubmit}
                  isEditing={!!this.state.editingMessageId}
                  onCancelEdit={this.handleEditCancel}
                  isGifSelected={this.state.gif}
                  onGifSelected={(gifUrl) => {
                    this.handleGifSelected(gifUrl);
                  }}
                  onFileSelected={this.handleFileSelected}
                  isFileSelected={this.state.file}
                  user={this.props.user}
                  files={this.props.virtualDiscFolder}
                />
              </Box>
            )}
            {(!!this.props.threads.length || !!this.state.thread) && (
              <ThreadViewDrawer
                isDrawerOpen={isDrawerOpen}
                isRequiredSize={isRequiredSize}
                closeDrawer={this.handleChangeDrawer}
                newMessageButton={
                  <CreateThreadButton
                    isCheckbox
                    threads={this.props.threads}
                    recipients={this.getRecipients()}
                    onCreate={this.handleThreadSelection}
                    userId={this.props.userId}
                    user={this.props.user}
                    groups={this.props.groups}
                    legalGuardians={this.props.legalGuardians}
                    staffMembers={this.props.staffMembers}
                    sx={{ height: 'auto' }}
                    onMultiMessage={this.handleMultiMessageSubmit}
                    multiMessageIsSended={this.state.multiMessageIsSended}
                  />
                }>
                <FilterBarV2
                  value={this.state.searchValue}
                  onKeywordChange={(value) => this.setState({ searchValue: value })}
                  contrast
                  itemsToFilter={this.props.threads}
                  onFilter={this.handleThreadsFiltered}
                  filter={this.handleFilterThreads}
                  endAdornment={
                    <FilterByRole
                      messanger
                      groups={this.props.groups}
                      selectedGroup={this.state.selectedGroup}
                      onSelectGroup={this.handleSelectGroup}
                      resetFilters={this.handleResetFilters}
                      filterBy={this.state.filterBy}
                      onFilterBy={this.handleFilterBy}
                      onResetGroups={this.handleResetGroups}
                    />
                  }
                />
                <ThreadsList
                  authUser={this.props.user}
                  showLastMessage
                  threads={this.state.filteredThreads}
                  selectUser={(x) => x.participants.filter((p) => p.id !== userId)[0]}
                  onSelect={this.handleThreadSelection}
                  onLoadMore={this.handleLoadMoreThreads}
                  isLoading={this.props.isLoadingThreads}
                  canLoadMore={this.props.canLoadMoreThreads}
                />
                {this.state.filteredThreads.length === 0 && <EmptyState message="Brak wyników wyszukiwania" contrast />}
              </ThreadViewDrawer>
            )}
          </Paper>
          {!this.props.threads.length && !this.state.thread && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <EmptyState message="Nie wysłano jeszcze żadnej wiadomości" icon={<MailIcon />} />

              <CreateThreadButton
                isCheckbox
                threads={this.props.threads}
                recipients={this.getRecipients()}
                onCreate={this.handleThreadSelection}
                userId={this.props.userId}
                user={this.props.user}
                groups={this.props.groups}
                legalGuardians={this.props.legalGuardians}
                staffMembers={this.props.staffMembers}
                sx={{ height: 'auto' }}
                onMultiMessage={this.handleMultiMessageSubmit}
                multiMessageIsSended={this.state.multiMessageIsSended}
              />
            </Box>
          )}
        </>
      </LoadingRenderWrapper>
    );
  }
}

SenderMessageContainer.propTypes = {
  threads: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  canLoadMoreMessages: PropTypes.bool.isRequired,
  canLoadMoreThreads: PropTypes.bool.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  isInitialLoadingMessages: PropTypes.bool.isRequired,
  isLoadingThreads: PropTypes.bool.isRequired,
  staffMembers: PropTypes.array.isRequired,
  legalGuardians: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  isAllowed: PropTypes.func.isRequired,
  isRequiredSize: PropTypes.bool,
  groups: PropTypes.array.isRequired,
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return {
    messages: state.privateMessages.messages,
    canLoadMoreMessages: state.privateMessages.canLoadMore,
    canLoadMoreThreads: state.privateThreads.canLoadMore,
    isLoadingMessages: state.privateMessages.isLoading,
    isInitialLoadingMessages: state.privateMessages.isInitial,
    isLoadingThreads: state.privateThreads.isLoading,
    staffMembers: state.staffMembers,
    legalGuardians: state.legalGuardians,
    userId: state.auth.userId,
    user: state.auth,
    virtualDisc: state.virtualDisc,
    virtualDiscFolder: state.virtualDiscFolder.disc,
    isAllowed: state.auth.isAllowed.bind(state.auth),
    groups: state.groups,
    threads: state.privateThreads.threads.map((thread) =>
      prepareThread(thread, [...state.legalGuardians, ...state.staffMembers], state.groups, state.auth.userId)
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(privateMessagesActions, dispatch),
    virtualDistActions: bindActionCreators(virtualDiscActions, dispatch)
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withMediaQuery('lg'))(SenderMessageContainer);
