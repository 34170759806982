import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@mui/material';

const UnitAttendanceSummaryItem = ({ name, value }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          display: 'flex',
          justifyContent: 'center'
        }}>
        {name}
      </Typography>
      <br />
      <Typography
        variant="h4"
        sx={{
          fontWeight: (theme) => theme.typography.fontWeightBlack,
          color: (theme) => theme.palette.color.color19,
          display: 'flex',
          justifyContent: 'center'
        }}>
        {value}
      </Typography>
    </Box>
  );
};

UnitAttendanceSummaryItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default UnitAttendanceSummaryItem;
