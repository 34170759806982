/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function profileHistoryReducer(state = initialState.profileHistory, action) {
  switch (action.type) {
    case types.LOAD_PROFILE_HISTORY_SUCCESS: {
      return {
        profileHistoryList: [...action.profileHistory.data],
        rowNumber: parseInt(action.profileHistory.rowNumber, 10)
      };
    }
    default:
      return state;
  }
}
