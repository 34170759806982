import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ReportTypes } from '../../../constants/reportTypes';
import SelectField from '../../common/SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, Typography, MenuItem } from '@mui/material';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';

class DuesOtherOverallReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'name',
      sortOptions: [
        { name: 'Po nazwisku', value: 'name' },
        { name: 'Po numerze', value: 'documentNumber' }
      ],
      dueTypes: [ChargingReportTypes.other.value]
    };
  }

  render() {
    return (
      <Box>
        <GroupAndMonthTemplate
          errors={this.props.errors}
          manyGroupsSelector
          year={this.props.year}
          month={this.props.month}
          groups={this.props.groups}
          onChange={this.props.onChange}
          legend={ReportTypes.duesOtherDetailsOverall.name}
        />
        <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.duesOtherDetailsOverall.name}`}>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Ustawienia raportu
          </Typography>
          <SelectField
            contrast
            sx={{ width: 'auto', overflow: 'hidden' }}
            floatingLabelText="Sortowanie"
            id="sortBy"
            value={this.state.sortBy}
            onChange={(event) => {
              this.setState({ sortBy: event.target.value });
              this.props.onChange({ target: { name: 'sortBy', value: event.target.value } });
            }}>
            {this.state.sortOptions.map((so) => {
              return (
                <MenuItem key={so.value} value={so.value}>
                  {so.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </FieldsetWrapper>
      </Box>
    );
  }
}

DuesOtherOverallReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DuesOtherOverallReport;
