import PropTypes from 'prop-types';
import React from 'react';
import { useTheme, Avatar } from '@mui/material';
import PersonsAvatar from '@mui/icons-material/AccountCircleOutlined';
import { create } from '../../utils/AvatarContentGenerator';

const PersonAvatar = (props) => {
  const theme = useTheme();
  const {
    url,
    firstName,
    lastName,
    fullName,
    className,
    alt = 'Awatar użytkownika',
    clear,
    initials,
    style,
    color,
    sx
  } = props;
  const effectiveLastName = lastName || (fullName ? fullName.split(' ')[1] : '') || '';
  const effectiveFirstName = firstName || (fullName ? fullName.split(' ')[0] : '') || '';

  const renderPhotoAvatar = () => (
    <Avatar
      sx={{
        minWidth: '25px',
        minHeight: '25px',
        ...sx,
        ...style,
        background: (theme) => theme.palette.color.wcag || ''
      }}
      alt={alt}
      className={[className].join(' ')}
      src={url}
    />
  );

  const renderDefaultAvatar = () => (
    <Avatar
      alt={alt}
      sx={{
        minWidth: '25px',
        minHeight: '25px',
        textDecoration: 'none',
        ...style,
        ...sx,
        color: (theme) => theme.palette.color.primary,
        background: (theme) => theme.palette.background.transparent,
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightBold
      }}>
      <PersonsAvatar />
    </Avatar>
  );

  const renderInitialsAvatar = () => {
    return (
      <Avatar
        sx={{
          minWidth: '25px',
          minHeight: '25px',
          textDecoration: 'none',
          ...style,
          ...sx,
          background: (theme) =>
            theme.palette.color.wcag ||
            create().generateColorFromName(effectiveLastName + ' ' + effectiveFirstName) ||
            theme.palette.color.color4
        }}
        alt={alt}
        className={[className].join(' ')}>
        {create().generateInitialsFromFullName(effectiveLastName, effectiveFirstName)}
      </Avatar>
    );
  };

  const renderClearAvatar = () => (
    <Avatar
      sx={{
        minWidth: '25px',
        minHeight: '25px',
        textDecoration: 'none',
        color: 'transparent',
        ...style,
        ...sx,
        background: (theme) =>
          theme.palette.color.wcag ||
          (!color ? create().generateColorFromName(clear) : color) ||
          theme.palette.color.color4
      }}
      alt={alt}
      className={[className].join(' ')}
    />
  );

  if (url) {
    return renderPhotoAvatar();
  }
  if (clear) {
    return renderClearAvatar();
  }
  if (initials) {
    return renderInitialsAvatar();
  }
  return renderDefaultAvatar();

  // return url ? renderPhotoAvatar() : renderDefaultAvatar();
};

PersonAvatar.propTypes = {
  color: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  sx: PropTypes.object,
  clear: PropTypes.string,
  initials: PropTypes.bool
};

export default PersonAvatar;
