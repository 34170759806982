const ignoreObjectAssignmentMismatch = true;

class BaseModel {
  onAssignment() {}

  verify(obj) {
    const local = Object.keys(this);
    const filteredLocal = local.filter((x) => obj.hasOwnProperty(x));
    const injected = Object.keys(obj);
    const filteredInjected = local.filter((x) => this.hasOwnProperty(x));
    return filteredLocal.length === local.length && injected.length === filteredInjected.length;
  }

  verifyMissingKeysWithDetails(obj) {
    const local = Object.keys(this);
    const missingKeys = local.filter((x) => !obj.hasOwnProperty(x));
    missingKeys.forEach((missingKey) => {
      /* eslint no-console: 'off' */
      console.warn(`${this.constructor.name} - injected object doesn't have key: ${missingKey}`);
    });
  }

  verifyExcessiveKeysWithDetails(obj) {
    const injected = Object.keys(obj);
    const excessiveKeys = injected.filter((x) => !this.hasOwnProperty(x));
    excessiveKeys.forEach((excessiveKey) => {
      /* eslint no-console: 'off' */
      console.warn(`${this.constructor.name} - injected object has excessive key: ${excessiveKey}`);
    });
  }

  mapToLocalProperties(obj) {
    const injected = Object.keys(obj);
    const filtered = injected.filter((x) => this.hasOwnProperty(x));
    return filtered.reduce((agg, value) => {
      /* eslint no-param-reassign: 'off' */
      agg[value] = obj[value];
      return agg;
    }, {});
  }

  assign(obj) {
    if (!obj) {
      return this;
    }
    if (!this.verify(obj) && ignoreObjectAssignmentMismatch === false) {
      this.verifyMissingKeysWithDetails(obj);
      this.verifyExcessiveKeysWithDetails(obj);
    }

    const mapped = this.mapToLocalProperties(obj);
    const assigned = Object.assign(this, mapped);
    this.onAssignment(assigned);
    return assigned;
  }
}

export default BaseModel;
