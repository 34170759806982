import PropTypes from 'prop-types';
import React from 'react';
import { Box, MenuItem } from '@mui/material';
import * as AlbumTypes from '../../../constants/groupsAndActivitiesTypes';
import SelectField from '../../common/SelectField';
import RichMultiSelectField from '../../formik/RichMultiSelectField/RichMultiSelectField';
import SingleMemberChip from '../../gallery/Components/Albums/SingleMemberChip';
import { options } from '../../../constants/recipientsOptionsToChoose';
import { connect } from 'react-redux';

class RecipientsOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroups: this.props.selectedGroups,
      selectedActivities: this.props.selectedActivities
    };
    this.handleSelectActivities = this.handleSelectActivities.bind(this);
    this.handleSelectGroups = this.handleSelectGroups.bind(this);
    this.getGroups = this.getGroups.bind(this);
  }

  handleSelectActivities(selected) {
    this.props.onSelectionChanged([], selected);
  }

  handleSelectGroups(groups) {
    this.props.onSelectionChanged(groups, []);
  }

  getGroups() {
    if (!this.props.unit.teacherAllGroupVisibility) {
      const user = this.props.staffMembers.find((obj) => obj.id === this.props.auth.userId);
      if (user) {
        const groups =
          user.role === 'teacher'
            ? this.props.groups.filter((group) => user.groups.find((userGroup) => userGroup.id === group.id))
            : this.props.groups;
        return groups;
      }
      return [];
    }
    return this.props.groups;
  }

  renderDialog() {
    if (this.props.selectedPublishOptionValue === options.limited.value) {
      return (
        <RichMultiSelectField
          error={this.props.limitedToGroupsError}
          errorText={this.props.limitedToGroupsHelperText}
          options={this.getGroups()}
          name="limitedToGroups"
          label="Wybierz grupy*"
          type={AlbumTypes.GROUP}
          selectElement={<SingleMemberChip />}
          chipElement={<SingleMemberChip />}
          isSelectAll={true}
          value={this.props.selectedGroups}
          onChange={this.handleSelectGroups}
          onDelete={this.handleSelectGroups}
        />
      );
    }
    if (this.props.selectedPublishOptionValue === options.limitedToActivities.value) {
      return (
        <RichMultiSelectField
          error={this.props.limitedToActivitiesError}
          errorText={this.props.limitedToActivitiesHelperText}
          options={this.props.activities}
          name="limitedToActivities"
          label="Wybierz zajęcia dodatkowe*"
          type={AlbumTypes.ACTIVITY}
          selectElement={<SingleMemberChip />}
          chipElement={<SingleMemberChip />}
          isSelectAll={true}
          value={this.props.selectedActivities}
          onChange={this.handleSelectActivities}
          onDelete={this.handleSelectActivities}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <Box
        sx={{
          ...this.props.sx
        }}>
        <SelectField
          contrast
          id="available-to"
          sx={{ minWidth: 200 }}
          name="selectedPublishOptionValue"
          floatingLabelText="Widoczne dla"
          value={this.props.selectedPublishOptionValue}
          onChange={this.props.onSelectedPublishOptionValueChange}>
          <MenuItem value={options.all.value}>{options.all.name}</MenuItem>
          <MenuItem value={options.limited.value} disabled={!this.props.groups.length}>
            {options.limited.name}
          </MenuItem>
          <MenuItem value={options.limitedToActivities.value} disabled={!this.props.activities.length}>
            {options.limitedToActivities.name}
          </MenuItem>
        </SelectField>
        {this.renderDialog()}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    unit: state.configuration.unit,
    auth: state.auth,
    staffMembers: state.staffMembers
  };
}

RecipientsOptions.propTypes = {
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  selectedActivities: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(RecipientsOptions);
