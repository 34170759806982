import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';

import PersonAvatar from '../forms/PersonAvatar';
import { routePaths, fromTemplate } from '../../routePaths';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import EmptyState from '../common/EmptyState';

const GroupArchiveChildrenList = ({ pupils, isPupilsArchived }) => {
  return (
    <>
      {pupils.length > 0 ? (
        <List>
          {pupils.map((pupil) => {
            return (
              <ListItem
                key={pupil.id}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}>
                <PersonAvatar
                  sx={{ mr: 2 }}
                  initials
                  alt={pupil.firstName}
                  url={pupil.photoUrl}
                  firstName={pupil.firstName}
                  lastName={pupil.lastName}
                />
                <ListItemText
                  disableTypography
                  primary={
                    <Link
                      to={fromTemplate(
                        isPupilsArchived(pupil) ? routePaths.childArchiveDetails : routePaths.childDetails,
                        pupil.id
                      )}
                      style={{
                        textDecoration: 'none'
                      }}>
                      <Typography
                        sx={{
                          fontWeight: (theme) => theme.typography.fontWeightBold,
                          color: (theme) => theme.palette.text.primary
                        }}>
                        {pupil.lastName} {pupil.firstName}
                      </Typography>
                    </Link>
                  }
                  secondary={<Typography>Data urodzenia {moment(pupil.birthDate).format('YYYY-MM-DD')}</Typography>}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <EmptyState message="Brak dzieci przypisanych do grupy" contrast />
      )}
    </>
  );
};

GroupArchiveChildrenList.propTypes = {
  pupils: PropTypes.array.isRequired,
  isPupilsArchived: PropTypes.func.isRequired
};

export default GroupArchiveChildrenList;
