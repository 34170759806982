import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordeonElement = ({ title, content }) => {
  return (
    <Paper sx={{ p: 1, my: 2 }}>
      <Accordion sx={{ boxShadow: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default AccordeonElement;
