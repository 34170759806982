/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DueListModel } from '../models/settlements/SettlementModels';

export default function guardianDuesReducer(state = initialState.guardianDues, action) {
  switch (action.type) {
    case types.LOAD_DUES_SUCCESS: {
      return action.dues.map((due) => new DueListModel().assign(due));
    }
    default:
      return state;
  }
}
