import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import moment from 'moment';
import EmptyState from '../common/EmptyState';

const ChildArchiveFileAttachmentsList = ({ attachments, onDownloadFile }) => {
  return (
    <>
      {attachments.length > 0 ? (
        <List>
          {attachments.map((file) => {
            return (
              <ListItem
                key={file.fileId}
                onClick={() => onDownloadFile(file.fileId)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}>
                <AttachmentIcon sx={{ mr: 1 }} />
                <ListItemText
                  primary={file.name}
                  secondary={moment(file.uploadDate).fromNow()}
                  secondaryTypographyProps={{
                    color: (theme) => theme.palette.text.primary
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <EmptyState message="Brak załączników" contrast />
      )}
    </>
  );
};

ChildArchiveFileAttachmentsList.propTypes = {
  attachments: PropTypes.array.isRequired,
  onDownloadFile: PropTypes.func.isRequired
};

export default ChildArchiveFileAttachmentsList;
