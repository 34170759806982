import React from 'react';
import FormikTimePicker from '../../../formik/FormikTimePicker';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Grid, Typography, ListItem } from '@mui/material';

const ChildDetailsFormHoursOfPresence = () => {
  return (
    <FieldsetWrapper
      legend={
        <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
          Zadeklarowane godziny obecności
        </Typography>
      }
      invisible={false}
      labelStyle={{ color: (theme) => theme.palette.color.contrast }}>
      <Grid container columnSpacing={1} sx={{ pb: 4 }}>
        <Grid item md={6}>
          <ListItem>
            <FormikTimePicker name="declaredDropOffAt" label="Od" fullWidth />
          </ListItem>
        </Grid>
        <Grid item md={6}>
          <ListItem>
            <FormikTimePicker name="declaredPickUpAt" label="Do" fullWidth />
          </ListItem>
        </Grid>
      </Grid>
    </FieldsetWrapper>
  );
};

export default ChildDetailsFormHoursOfPresence;
