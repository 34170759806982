import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as duesExportSettingsActions from '../../../actions/duesExportSettingsActions';
import ArchiveIcon from '@mui/icons-material/Archive';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import DuesExportSettingsDialog from './DuesExportSettingsDialog';
import DuesExportDialog from './DuesExportDialog';
import Paper from '@mui/material/Paper';
import ExportTable from '../exportTable/exportTable';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Button, Typography } from '@mui/material';
import Strong from '../../common/Strong';
import ContentWrapper from '../../common/contentWrapper';

class DuesExportPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      settingsDialogVisible: false,
      exportDialogVisible: false,
      exportInProgress: false,
      type: 'symfoniaExport'
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRunExport = this.handleRunExport.bind(this);
    this.handleOpenExportDialog = this.handleOpenExportDialog.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadSymphonyConfiguration();
    this.props.actions.loadExportsHistory(this.state.type);
  }

  handleEdit() {
    this.setState({ settingsDialogVisible: true });
  }

  handleSave(settings) {
    this.props.actions.updateConfiguration(settings);
    this.setState({ settingsDialogVisible: false });
  }

  handleOpenExportDialog() {
    this.setState({ exportDialogVisible: true });
  }

  handleRunExport(date) {
    this.setState({ exportInProgress: true });
    this.props.actions
      .runExport(this.state.type, date, '')
      .then(() => this.props.actions.loadExportsHistory(this.state.type))
      .finally(() => this.setState({ exportInProgress: false, exportDialogVisible: false }));
  }

  renderDialog() {
    if (this.state.settingsDialogVisible) {
      return (
        <DuesExportSettingsDialog
          onSave={this.handleSave}
          onCancel={() => {
            this.setState({ settingsDialogVisible: false });
          }}
          duesExportSettings={this.props.configuration}
          isDialogOpen={true}
          isProcessing={this.props.ui.isProcessing}
        />
      );
    }
    return null;
  }

  render() {
    const { configuration, duesExports } = this.props;
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Eksport Symfonia" titleIcon={<ArchiveIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Button onClick={this.handleEdit} sx={{ mb: 2 }} variant="outlined" aria-label="Zmień ustawienia">
                Zmień ustawienia
              </Button>
            </Box>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Ustawienia eksportu</Typography>
              <Box sx={{ my: 2 }}>
                Adres email: <Strong>{configuration.email || 'nie podano'}</Strong>
              </Box>
              <Box sx={{ my: 2 }}>
                Dodatkowy adres email: <Strong>{configuration.additionalEmail || 'nie podano'}</Strong>
              </Box>
              <Button
                aria-label="Uruchom eksport"
                variant="outlinedContrast"
                onClick={this.handleOpenExportDialog}
                disabled={this.state.exportInProgress || !configuration.email}>
                Uruchom eksport
              </Button>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Historia
              </Typography>
              <ExportTable duesExports={duesExports} />
            </Paper>
            {this.renderDialog()}
            <DuesExportDialog
              type={this.state.type}
              open={this.state.exportDialogVisible}
              onSubmit={this.handleRunExport}
              inProgress={this.state.exportInProgress}
              onClose={() => {
                this.setState({ exportDialogVisible: false });
              }}
            />
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

DuesExportPage.propTypes = {
  configuration: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  duesExports: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration.duesExportSettings,
    ui: state.configuration.duesExportSettingsUi,
    duesExports: state.duesExports
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(duesExportSettingsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DuesExportPage);
