import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../routePaths';
import EventsCalendar from '../eventsCalendar/calendar/EventsCalendar';
import { Box, Button, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  loadStaffEventsAsync,
  createStaffEventAsync,
  loadFilteredStaffEventAsync
} from '../../actions/staffCalendarActions';
import EventDialog from './Dialogs/EventDialog';
import LoadingIndicatorWrapper from '../common/loading/LoadingIndicatorWrapper';
import { BoardPostModel } from '../../models/messageBoard/MessageBoardModels';
import { TimeModel } from '../../models/TimeModel';
import EventDetailsDialog from './Dialogs/EventDetailsDialog';
import { SearchBar } from '../common/SearchBar';

const INITIAL_VALUES = {
  title: '',
  content: '',
  eventDate: moment(),
  eventStartTime: null,
  eventEndTime: null,
  files: []
};

const useStyles = makeStyles({
  root: {},
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  buttonLeft: {
    mr: 1
  },
  paper: {
    p: 2
  }
});

const StaffCalendarContainer = (props) => {
  const { month, year } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [editDialog, setEditDialog] = useState(false);
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [activeFilters, setActiveFilters] = useState(false);
  const [selectedDayEvents, setSelectedDayEvents] = useState({
    date: moment(),
    events: []
  });
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const getEvents = useCallback(async () => {
    await dispatch(loadStaffEventsAsync(moment(new Date(year, month - 1, 1))));
  }, [dispatch, month, year]);

  const createEvent = async (event) => {
    await dispatch(createStaffEventAsync(event));
    setEditDialog(false);
  };

  const getFilterdEvents = async (filters) => {
    try {
      await dispatch(loadFilteredStaffEventAsync(moment(new Date(year, month - 1, 1)), filters));
      setActiveFilters(true);
    } catch (error) {
      setActiveFilters(false);
      setSearchValue('');
      getEvents();
    }
  };

  const daysOff = useSelector((state) => state.configuration.daysOff);
  const posts = useSelector((state) => state.staffCalendar.events);
  const loading = useSelector((state) => state.staffCalendar.loading);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    setSelectedDayEvents((prevState) => ({
      ...prevState,
      events: posts.filter((post) => post.eventDate === prevState.date.format('YYYY-MM-DD'))
    }));
  }, [posts]);

  const handleCalendarDateChange = async (date) => {
    await dispatch(loadStaffEventsAsync(date));
  };

  const handleDetails = (day) => {
    const date = day.date.format('YYYY-MM-DD');
    setSelectedDayEvents({
      date: day.date,
      events: posts.filter((post) => post.eventDate === date)
    });
    setDetailsDialog(true);
  };

  const handleEventDialog = (date = moment()) => {
    setEditDialog(true);
    setInitialValues((prevState) => ({
      ...prevState,
      eventDate: date
    }));
  };

  const handleOpenFullDetails = () => {
    const { date } = selectedDayEvents;
    browserHistory.push(
      fromTemplate(routePaths.staffCalendarDetails, [date.format('YYYY'), date.format('MM'), date.format('DD')])
    );
  };

  const handleCloseDetails = () => {
    setDetailsDialog(false);
  };

  const handleCreateEventFromDetails = () => {
    handleEventDialog(selectedDayEvents.date);
  };

  const handleEventDialogClose = () => {
    setEditDialog(false);
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = (values) => {
    const post = Object.assign(new BoardPostModel().assign(values), {
      eventStartTime: new TimeModel().fromMoment(values.eventStartTime),
      eventEndTime: new TimeModel().fromMoment(values.eventEndTime),
      files: values.files.map((file) => file.id),
      internal: true
    });
    createEvent(post);
  };

  const handleSearch = () => {
    getFilterdEvents({
      textFilter: searchValue
    });
  };

  const handleClearFilters = () => {
    setActiveFilters(false);
    setSearchValue('');
    getEvents();
  };

  return (
    <LoadingIndicatorWrapper isProcessing={loading}>
      <>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'flex-end', pb: 2 }}>
            <SearchBar
              label="Szukaj"
              SearchOnClick={handleSearch}
              value={searchValue}
              onChange={handleSearchValueChange}
              handleTextSearch={handleSearch}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              {activeFilters && (
                <Button
                  variant="contained"
                  aria-label="Wyczyść filtry"
                  onClick={handleClearFilters}
                  className={classes.buttonLeft}>
                  Wyczyść filtry
                </Button>
              )}
              <Button variant="contained" aria-label="Nowe wydarzenie" onClick={() => handleEventDialog()}>
                Nowe wydarzenie
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Paper className={classes.paper}>
          <EventsCalendar
            eventDays={posts}
            daysOff={daysOff}
            onDateChange={handleCalendarDateChange}
            onDaySelected={(date) => handleDetails(date)}
            date={() => moment(new Date(year, month - 1, 1))}
            year={year}
            month={month}
          />
        </Paper>
        {editDialog && (
          <EventDialog
            open={editDialog}
            onClose={handleEventDialogClose}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            title="Dodaj wydarzenie"
            inProgress={loading}
          />
        )}
        {detailsDialog && (
          <EventDetailsDialog
            events={selectedDayEvents.events}
            onCreateNewEvent={handleCreateEventFromDetails}
            isDialogOpen={detailsDialog}
            onShowEventFullDetails={handleOpenFullDetails}
            onClose={handleCloseDetails}
            header="Wydarzenia"
          />
        )}
      </>
    </LoadingIndicatorWrapper>
  );
};

StaffCalendarContainer.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired
};

export default StaffCalendarContainer;
