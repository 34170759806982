import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as childrenActions from '../../../actions/childrenActions';
import * as reportsActions from '../../../actions/reportsActions';
import * as settlementsActions from '../../../actions/settlementsActions';
import * as staffMemberActions from '../../../actions/staffMembersActions';
import ChildDetailsHeader from '../../childDetails/header/ChildDetailsHeader';
import FinancialDetailsCard from '../../childDetails/financialDetails/FinancialDetailsCard';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import { Box } from '@mui/material';

class StaffFinancialDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handlerLoadPersonalBalance = this.handlerLoadPersonalBalance.bind(this);
    this.handlerPayoutFromBalance = this.handlerPayoutFromBalance.bind(this);
    this.handlerDeposit = this.handlerDeposit.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
    this.handleGeneratePayoutConfirmation = this.handleGeneratePayoutConfirmation.bind(this);
    this.handleChangeStaffMemberState = this.handleChangeStaffMemberState.bind(this);
  }

  componentDidMount() {
    this.handlerLoadPersonalBalance();
  }

  componentDidUpdate(prevProps) {
    if (this.props.staffMember !== prevProps.staffMember) {
      this.handleChangeStaffMemberState(this.props.staffMember);
    }
  }

  handleChangeStaffMemberState(value) {
    this.setState({ staffMember: Object.assign({}, value) });
  }

  handleLoaded() {
    this.setState({ staffMember: Object.assign({}, this.props.staffMember) });
  }

  handleGeneratePayoutConfirmation(operationDate) {
    return this.props.reportsActions.generateBalanceFundsPayoutConfirmationAsync(
      this.props.staffMemberId,
      operationDate
    );
  }

  handlerLoadPersonalBalance() {
    return this.props.actions.loadChildPersonalBalanceAsync(this.props.staffMemberId);
  }

  handlerPayoutFromBalance(amount, paymentMethod) {
    return this.props.staffMemberActions.cashOutMoneyFromPersonalBalanceAsync(
      this.props.staffMemberId,
      amount,
      paymentMethod
    );
  }

  handlerDeposit(paymentDetails, staffMember) {
    return this.props.settlementsActions.registerStaffDepositAsync(paymentDetails, staffMember);
  }

  render() {
    const { staffMember } = this.props;
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <ChildDetailsHeader
            name={staffMember.firstName + ' ' + staffMember.lastName}
            photoUrl={staffMember.photoUrl}
            photoChangeDisabled={true}
            onPhotoDrop={() => {}}
            onPhotoRemove={() => {}}
          />
          <FinancialDetailsCard
            isStaffMember={true}
            child={staffMember}
            personalBalance={this.props.availableFunds}
            onPayoutFromBalance={this.handlerPayoutFromBalance}
            onDeposit={this.handlerDeposit}
            onLoadBalance={this.handlerLoadPersonalBalance}
            onGeneratePayoutConfirmation={this.handleGeneratePayoutConfirmation}
          />
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

StaffFinancialDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  staffMember: PropTypes.object.isRequired,
  staffMemberId: PropTypes.string.isRequired,
  staffMemberPersonalBalance: PropTypes.object,
  staffMemberActions: PropTypes.object.isRequired,
  reportsActions: PropTypes.object.isRequired,
  settlementsActions: PropTypes.object.isRequired,
  availableFunds: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const staffMemberId = ownProps.params.id;
  return {
    staffMemberId,
    staffMember: state.staffMemberDetails,
    availableFunds: state.childPersonalBalance
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(childrenActions, dispatch),
    reportsActions: bindActionCreators(reportsActions, dispatch),
    settlementsActions: bindActionCreators(settlementsActions, dispatch),
    staffMemberActions: bindActionCreators(staffMemberActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffFinancialDetailsPage);
