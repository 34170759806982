import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReadIndicatorIcon from '../icons/ReadIndicatorIcon';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import moment from 'moment';
import ConfirmationIconButton from '../../forms/buttons/ConfirmationIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { convertMessage, isDiskLink } from '../../common/messageFunctions';
import IconButton from '@mui/material/IconButton';
import SendMessageCard from './SendMessageCard';

const small = { height: 10, width: 10, marginLeft: 4 };

const formatTime = (time) => {
  const m = moment(time);
  if (moment().diff(m, 'days') === 0) return m.format('HH:mm');
  return m.format('LLL');
};

const checkIfDeleted = (content) => {
  return content === 'Wiadomość została usunięta';
};

const Message = forwardRef(
  (
    {
      message,
      canSelect,
      onSelect,
      type,
      canDelete,
      onDelete,
      onUpdate,
      currentUserId,
      handleKeyup,
      index,
      onCancelDeleteMessage = null,
      isContrastColor,
      avatar,
      author,
      thread,
      filesWithPermission
    },
    ref
  ) => {
    const [dateVisibility, setDateVisible] = useState(false);
    const [deleteVisibility, setDeleteVisibility] = useState(false);
    const [editMessage, setEditMessage] = useState();
    const [editingMessageContent, setEditingMessageContent] = useState('');
    const [gif, setGif] = useState();
    useEffect(() => {
      ref.current[0].parentElement.parentElement.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const useStyles = makeStyles((theme) => ({
      message: {
        transition: 'background-color 200ms ease',
        borderRadius: 12,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        background: type === 'incoming' ? theme.palette.color.color3 : theme.palette.background.hover,
        color: type === 'incoming' ? theme.palette.background.color7 : theme.palette.color.contrast
      },
      outgoing: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      incoming: {
        display: 'flex',
        justifyContent: 'flex-start'
      },
      selectable: {
        cursor: 'pointer'
      },
      focusMessage: {
        height: '100%',
        '&:focus': {
          outline: 'none'
        },
        '&:focus-visible': {
          outline: `,01px solid ${theme.palette.color.color1}`
        }
      },
      messageTime: {
        fontSize: 10,
        pl: '2px',
        mt: '2px',
        color: type === 'incoming' ? theme.palette.background.color7 : theme.palette.color.contrast
      },
      deleteButton: {
        display: 'none'
      },
      messageBox: {
        justifyContent: type === 'outgoing' ? 'end' : 'start'
      }
    }));
    const cl = useStyles();
    const getClass = (type) => {
      switch (type) {
        case 'incoming':
          return cl.incoming;
        case 'outgoing':
          return cl.outgoing;
        default:
          return '';
      }
    };
    const handleOpenEditng = () => {
      setEditingMessageContent(message.content.text);
      setEditMessage(true);
    };
    const handleSubmitEditing = (content, gif) => {
      if (!!content || !!gif) setEditMessage(false);
      onUpdate(message, content, gif);
    };
    const handleDelete = () => {
      const deletedMessage = Object.assign({}, message);
      if (!deletedMessage.threadId) {
        deletedMessage.threadId = thread.threadId;
      }
      onDelete(deletedMessage);
    };

    return (
      <Box
        className={` ${getClass(type)}${canSelect ? cl.selectable : ''}`}
        onClick={onSelect}
        sx={{ cursor: 'pointer', width: '100%', mx: 1 }}>
        <Box
          onClick={() => {
            setDateVisible(!dateVisibility);
          }}
          onMouseEnter={() => {
            setDeleteVisibility(true);
          }}
          onMouseLeave={() => {
            setDeleteVisibility(false);
          }}
          className={cl.focusMessage}
          ref={(el) => {
            // eslint-disable-next-line no-param-reassign
            ref.current[index] = el;
          }}
          sx={{ width: { xs: '85%', sm: '80%' }, my: 0.5 }}
          tabIndex={-1}
          onKeyUp={(e) => handleKeyup(e, index)}>
          {author && (
            <Typography
              sx={{
                textAlign: type === 'outgoing' ? 'right' : 'left',
                mr: type === 'outgoing' && 4.5,
                ml: type === 'incoming' && 4.5,
                opacity: 0.5,
                color: (theme) => theme.palette.components.messenger.messengerText
              }}>
              {author}
            </Typography>
          )}
          <Box
            className={cl.messageBox}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
            {!editMessage && deleteVisibility && canDelete && onDelete ? (
              <Box>
                <ConfirmationIconButton
                  confirmationText="Czy na pewno chcesz usunąć wiadomość?"
                  confirmationTitle="Usuwanie wiadomości"
                  confirmLabel="Tak"
                  cancelLabel="Nie"
                  actionLabel="Usuń"
                  onConfirm={handleDelete}
                  onCancel={() => onCancelDeleteMessage()}
                  buttonStyle={{ padding: 0 }}>
                  <Tooltip title="Usuń">
                    <DeleteIcon
                      sx={{
                        fontSize: 14,
                        color: (theme) => (!isContrastColor ? theme.palette.grey[400] : theme.palette.color.color22)
                      }}
                    />
                  </Tooltip>
                </ConfirmationIconButton>
                {!isDiskLink(message.content?.text) && (
                  <Tooltip title="Edytuj">
                    <IconButton onClick={handleOpenEditng}>
                      <BorderColorIcon
                        sx={{
                          fontSize: 14,
                          color: (theme) => (!isContrastColor ? theme.palette.grey[400] : theme.palette.color.color22)
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ) : null}
            <Box
              sx={{
                order: type === 'outgoing' ? 1 : 0,
                p: !!avatar ? 0.5 : 0,
                height: '100%',
                alignSelf: 'end'
              }}>
              {!!avatar && avatar}
            </Box>
            {!editMessage && (
              <Box
                className={` ${cl.message} ${getClass(type)}`}
                sx={{
                  px: 2,
                  pt: 1,
                  pb: 0.5,
                  display: 'flex',
                  flexDirection: 'column',
                  mr: type === 'outgoing' && !avatar && 4.5,
                  ml: type === 'incoming' && !avatar && 4.5,
                  opacity: checkIfDeleted(message.content.text) ? 0.6 : 1
                }}>
                {typeof message.content?.text === 'string'
                  ? convertMessage(message.content?.text, filesWithPermission)
                  : convertMessage(message.content?.text.content.text, filesWithPermission)}

                {dateVisibility && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      p: 1
                    }}>
                    <Box className={`${cl.messageTime}`}>
                      {message.updatedAt ? formatTime(message.updatedAt) : formatTime(message.sentAt)}
                    </Box>
                    {message.updatedAt && (
                      <Typography
                        sx={{
                          fontSize: 10,
                          ml: 1,
                          display: 'flex',
                          alignItems: 'center',
                          color: (theme) =>
                            type === 'incoming' ? theme.palette.background.color7 : theme.palette.color.contrast
                        }}>
                        (edytowane)
                        <BorderColorIcon
                          sx={{
                            ...small,
                            ml: 0.5
                          }}
                        />
                      </Typography>
                    )}
                    {message.senderId === currentUserId && (
                      <ReadIndicatorIcon
                        isContrastColor={isContrastColor}
                        isRead={message.readByParticipants}
                        isLarge={false}
                      />
                    )}
                  </Box>
                )}
              </Box>
            )}
            {editMessage && (
              <SendMessageCard
                maxHeight="50vh"
                text={editingMessageContent}
                onChange={(e) => setEditingMessageContent(e)}
                onSubmit={handleSubmitEditing}
                isEditing={editMessage}
                onCancelEdit={() => {
                  setEditMessage(false);
                }}
                isGifSelected={gif}
                onGifSelected={(gifUrl) => {
                  setGif(gifUrl);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

Message.propTypes = {
  message: PropTypes.object.isRequired,
  canSelect: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  currentUserId: PropTypes.string.isRequired,
  forwardedRef: PropTypes.any,
  handleKeyup: PropTypes.any,
  index: PropTypes.number,
  onCancelDeleteMessage: PropTypes.func,
  isContrastColor: PropTypes.bool
};

export default Message;
