import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../../forms/PersonAvatar';
import AuthorizationDetails from './AuthorizationDetails';
import { downloadAuthorizationDocMenuItem } from './DownloadAuthorizationDocMenuItem';
import { extendAuthorizationDateMenuItem } from './ExtendAuthorizationDateMenuItem';
import moment from 'moment';
import DotsMenu from '../../menu/DotsMenu';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  MenuItem,
  useTheme,
  useMediaQuery,
  Box,
  Typography
} from '@mui/material';
import { signAuthorizationMenuItem } from './SignAuthorizationMenuItem';

const shouldDisplayMenu = (isAuthorized, authorizationExpirationDate) =>
  isAuthorized && (!authorizationExpirationDate || moment().isBefore(authorizationExpirationDate));
const isApplicationExpired = (isAuthorized, authorizationExpirationDate) =>
  isAuthorized && authorizationExpirationDate && moment().isAfter(authorizationExpirationDate);
const downloadSignConfirmation = (url) => {
  window.open(url, '_blank');
};

const ReadonlyGuardiansList = ({ guardians, onExtendAuthorizationDate, onDownloadAuthorizationDoc, onSign, unit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isElectronicSignEnabled = () => unit.features && unit.features.signApplications;

  const renderMenu = (guardian) => {
    if (
      !isApplicationExpired(true, guardian.authorizationExpirationDate) &&
      guardian.signedFileInfo &&
      guardian.signedFileInfo.fileId
    ) {
      return (
        <DotsMenu>
          {() => [
            <MenuItem key={1} onClick={() => downloadSignConfirmation(guardian.signedFileInfo.signedDocLink)}>
              Pobierz potwierdzenie podpisu (.XML)
            </MenuItem>
          ]}
        </DotsMenu>
      );
    }
    if (shouldDisplayMenu(guardian.isAuthorized, guardian.authorizationExpirationDate)) {
      return null;
    }
    if (!guardian.isAuthorized && isElectronicSignEnabled() && !guardian.signedFileInfo.fileId) {
      return (
        <DotsMenu>
          {() => [
            signAuthorizationMenuItem(guardian.isAuthorized, () => onSign(guardian), 1),
            downloadAuthorizationDocMenuItem(guardian.isAuthorized, () => onDownloadAuthorizationDoc(guardian), 2),
            extendAuthorizationDateMenuItem(
              guardian.isAuthorized,
              guardian.authorizationExpirationDate,
              () => onExtendAuthorizationDate(guardian),
              3
            )
          ]}
        </DotsMenu>
      );
    }
    if (isApplicationExpired(guardian.isAuthorized, guardian.authorizationExpirationDate)) {
      return (
        <DotsMenu>
          {() => [
            extendAuthorizationDateMenuItem(
              guardian.isAuthorized,
              guardian.authorizationExpirationDate,
              () => onExtendAuthorizationDate(guardian),
              3
            )
          ]}
        </DotsMenu>
      );
    }

    return (
      <DotsMenu>
        {() => [downloadAuthorizationDocMenuItem(guardian.isAuthorized, () => onDownloadAuthorizationDoc(guardian), 2)]}
      </DotsMenu>
    );
  };

  const Item = ({ guardian, lastItem }) => (
    <>
      <ListItem
        sx={{
          py: 1,
          '&:hover': {
            background: (theme) => theme.palette.background.hover
          }
        }}>
        {!isMobile && (
          <ListItemIcon>
            <PersonAvatar
              initials
              alt={guardian.firstName}
              firstName={guardian.firstName}
              lastName={guardian.lastName}
            />
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          primary={
            <Typography
              sx={{
                mr: 1,
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}>
              {guardian.firstName} {guardian.lastName}
              {guardian.relationship && (
                <Typography component="span" sx={{ mx: 1 }}>
                  ({guardian.relationship})
                </Typography>
              )}
            </Typography>
          }
          secondary={
            <Box sx={{ pt: 1 }}>
              <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                Numer dokumentu
                <Typography
                  component="span"
                  sx={{
                    mx: 1,
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}>
                  {guardian.documentNumber}
                </Typography>
              </Typography>
              {!isMobile && (
                <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                  Numer telefonu
                  <Typography
                    component="span"
                    sx={{
                      mx: 1,
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}>
                    {guardian.phoneNumber || 'brak'}
                  </Typography>
                </Typography>
              )}
              {!isMobile && guardian.pin && (
                <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                  Pin
                  <Typography
                    component="span"
                    sx={{
                      mx: 1,
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}>
                    {guardian.pin}
                  </Typography>
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap' }}>
                <AuthorizationDetails
                  authorizedBy={guardian.authorizedBy}
                  isAuthorizedBySign={guardian.isAuthorizedBySign}
                  isAuthorizedByAll={guardian.isAuthorizedByAll}
                  isAuthorized={guardian.isAuthorized}
                  authorizationExpirationDate={guardian.authorizationExpirationDate}
                />
              </Box>
            </Box>
          }
        />
        <ListItemSecondaryAction>{renderMenu(guardian)}</ListItemSecondaryAction>
      </ListItem>
    </>
  );

  Item.propTypes = {
    guardian: PropTypes.object.isRequired,
    lastItem: PropTypes.bool
  };

  return (
    <List>
      {guardians.map((guardian, index) => (
        // eslint-disable-next-line react/prop-types
        <Item key={guardian.id} lastItem={index === guardians.length - 1} guardian={guardian} />
      ))}
    </List>
  );
};

ReadonlyGuardiansList.propTypes = {
  guardians: PropTypes.array.isRequired,
  onExtendAuthorizationDate: PropTypes.func.isRequired,
  onDownloadAuthorizationDoc: PropTypes.func.isRequired,
  onSign: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired
};

export default ReadonlyGuardiansList;
