import PropTypes from 'prop-types';
import React from 'react';
import ColorPicker from '../../common/ColorPicker';
import { avatarColorPaletteArray } from '../../../constants/avatarColorPalette';
import { create } from '../../../utils/AvatarContentGenerator';
import { FormControl, FormControlLabel, RadioGroup, Radio, Typography, Stack, Box } from '@mui/material';
import TextField from '../../common/TextField';
import FormLabel from '@mui/material/FormLabel';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const GroupDetailsForm = ({ group, onChange, yearbooks, onYearbookChange, errors }) => {
  const getDefaultYearbookValue = () => {
    return group.isTemporary ? yearbooks[2].value : group.yearbook;
  };

  return (
    <Stack>
      <FieldsetWrapper legend="Pola dodania nowej grupy">
        <TextField
          hintText="Nazwa grupy"
          floatingLabelText="Nazwa grupy*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="name"
          value={group.name}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.name}
        />
        <Box sx={{ display: 'flex', align: 'start', mt: 4 }}>
          <Box sx={{ display: 'flex', align: 'start', flexDirection: 'column', justifyContent: 'start' }}>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.color1 }}>
              Kolor grupy
            </Typography>
            <ColorPicker
              name="color"
              palette={avatarColorPaletteArray}
              defaultColor={group.color || create().generateColorFromName(group.name)}
              onChange={(color) => onChange({ target: { name: 'color', value: color } })}
            />
          </Box>

          <FormControl
            component="fieldset"
            sx={{ display: 'flex', align: 'start', flexDirection: 'column', justifyContent: 'start' }}>
            <FormLabel component="legend">
              <Typography variant="caption" sx={{ pl: 0, color: (theme) => theme.palette.text.primary }}>
                Rok szkolny
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="wybierz rok szkolny"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                '& .MuiSvgIcon-root': {
                  fontSize: 40,
                  color: (theme) => theme.palette.color.color25
                }
              }}
              name="selectYearBooks"
              onClick={(e) => onYearbookChange(e.target.value)}
              value={group.isTemporary ? getDefaultYearbookValue() : group.yearbook}>
              {yearbooks.map((yearbook) => (
                <FormControlLabel
                  key={yearbook.name}
                  value={yearbook.value}
                  sx={{ mr: 5 }}
                  control={<Radio />}
                  label={yearbook.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </FieldsetWrapper>
    </Stack>
  );
};

GroupDetailsForm.propTypes = {
  group: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  yearbooks: PropTypes.array.isRequired,
  onYearbookChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default GroupDetailsForm;
