/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { StaffMemberModel } from '../models/staffMembers/StaffMemberModels';

export default function staffMembersReducer(state = initialState.staffMembers, action) {
  switch (action.type) {
    case types.LOAD_STAFF_MEMBERS_SUCCESS: {
      return action.members.map((m) => new StaffMemberModel().assign(m));
    }
    case types.CREATE_STAFF_MEMBER_SUCCESS: {
      return [...state, new StaffMemberModel().assign(action.member)];
    }
    case types.UPDATE_STAFF_MEMBER_SUCCESS: {
      return [...state.filter((m) => m.id !== action.member.id), new StaffMemberModel().assign(action.member)];
    }
    case types.ARCHIVE_STAFF_MEMBER_SUCCESS: {
      return [...state.filter((m) => m.id !== action.memberId)];
    }
    default:
      return state;
  }
}
