import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import api from '../api/DietsApi';

export function loadDietsAsync() {
  return async (dispatch) => {
    try {
      const data = await api.loadDiets();
      dispatch({ type: types.LOAD_DIETS_SUCCESS, data });
    } catch (e) {
      dispatch({ type: types.LOAD_DIETS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać danych'));
    }
  };
}

export function loadDietDetailsAsync(dietId) {
  return async (dispatch) => {
    try {
      const data = await api.loadDietDetails(dietId);
      dispatch({ type: types.LOAD_DIET_DETAILS_SUCCESS, data });
    } catch (e) {
      dispatch({ type: types.LOAD_DIET_DETAILS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać danych'));
    }
  };
}

export function createDietAsync(diet) {
  return async (dispatch) => {
    try {
      const data = await api.createDiet(diet);
      dispatch(notificationActions.showSuccess(`Utworzono dietę ${diet.name}`));
      dispatch(loadDietsAsync());
    } catch (e) {
      dispatch({ type: types.CREATE_DIET_FAILURE });
      dispatch(notificationActions.showError('Nie udało się utworzyć diety'));
      throw e;
    }
  };
}

export function updateDietAsync(diet) {
  return async (dispatch) => {
    try {
      const data = await api.updateDiet(diet);
      dispatch(loadDietDetailsAsync(diet.id));
      dispatch(notificationActions.showSuccess(`Zaktualizowano dietę ${diet.name}`));
    } catch (e) {
      dispatch({ type: types.UPDATE_DIET_FAILURE });
      dispatch(notificationActions.showError('Nie udało się zaktualizować diety'));
      throw e;
    }
  };
}

export function deleteDietAsync(dietId) {
  return async (dispatch) => {
    try {
      await api.deleteDiet(dietId);
      dispatch(loadDietsAsync());
      dispatch(notificationActions.showSuccess('Usunięto dietę'));
    } catch (e) {
      if (e.status === 412) {
        dispatch(
          notificationActions.showError(`Nie udało się usunąć diety, ${e.response.body.generalError.toLowerCase()}`)
        );
      } else {
        dispatch({ type: types.UPDATE_DIET_FAILURE });
        dispatch(notificationActions.showError('Nie udało się usunąć diety'));
      }
      throw e;
    }
  };
}

export function enrollChildrenToDietAsync(body) {
  return async (dispatch) => {
    try {
      const data = await api.enrollChildren(body);
      dispatch(loadDietDetailsAsync(body.menuId));
      dispatch({ type: types.ENROLL_CHILDREN_TO_DIET_SUCCESS });
      dispatch(notificationActions.showSuccess('Dzieci zostały przypisane do diety'));
    } catch (e) {
      if (e.status === 412) {
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać dziecka do diety,  ${e.response.body.generalError.toLowerCase()}`
          )
        );
      } else {
        dispatch({ type: types.ENROLL_CHILDREN_TO_DIET_FAILURE });
        dispatch(notificationActions.showError('Nie udało się przypisać dziecka do diety'));
      }
      throw e;
    }
  };
}

export function unrollChildrenToDietAsync(body) {
  return async (dispatch) => {
    try {
      const data = await api.unrollChildren(body);
      dispatch(loadDietDetailsAsync(body.menuId));
      dispatch({ type: types.UNROLL_CHILDREN_TO_DIET_SUCCESS });
      dispatch(notificationActions.showSuccess('Dzieci zostały odpisane od diety'));
    } catch (e) {
      dispatch({ type: types.UNROLL_CHILDREN_TO_DIET_FAILURE });
      dispatch(notificationActions.showError('Nie udało się odpisać dziecka od diety'));
      throw e;
    }
  };
}

export function changeChildEnrollment(body) {
  return async (dispatch) => {
    try {
      const data = await api.changeChildEnrollment(body);
      dispatch(loadDietDetailsAsync(body.menuId));
      dispatch({ type: types.CHANGE_CHILD_ENROLLMENT_TO_DIET_SUCCESS });
      dispatch(notificationActions.showSuccess('Dzieci zostały przypisane do diety'));
    } catch (e) {
      if (e.status === 412) {
        console.log({ e });
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać dziecka do diety,  ${e.response.body.generalError.toLowerCase()}`
          )
        );
      } else {
        console.log(e);
        dispatch({ type: types.CHANGE_CHILD_ENROLLMENT_TO_DIET_FAILURE });
        dispatch(notificationActions.showError('Nie udało się przypisać dziecka do diety'));
      }
      throw e;
    }
  };
}
