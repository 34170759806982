import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import ReliefForm from '../forms/ReliefForm';
import { ReliefModelValidator } from '../../../../models/configuration/reliefs/ReliefModelsValidators';

class ReliefDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relief: this.props.relief,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const relief = Object.assign({}, this.state.relief);
    relief[field] = event.target.value;
    return this.setState({ relief });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        maxWidth="xs"
        header="Nowa ulga"
        onSave={() => onSave(this.state.relief)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new ReliefModelValidator().validate(this.state.relief)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.reliefsUi"
        requiredFieldText>
        <ReliefForm relief={this.state.relief} onChange={this.handleChange} errors={this.state.errors} />
      </DialogForm>
    );
  }
}

ReliefDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  relief: PropTypes.object.isRequired
};

export default ReliefDialog;
