import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import MenuRoute from './MenuRoute';

import HomeIcon from '@mui/icons-material/HomeOutlined';
import ChildrenIcon from '@mui/icons-material/FormatListNumberedOutlined';
import AssignmentIndIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import LegalGuardiansIcon from '@mui/icons-material/GroupOutlined';
import ReportsIcon from '@mui/icons-material/InsertChartOutlined';
import ManualIcon from '@mui/icons-material/DescriptionOutlined';
import PrivateMessagesIcon from '@mui/icons-material/EmailOutlined';
import DaycareIcon from '@mui/icons-material/ChildCare';
import ChangeLogIcon from '@mui/icons-material/VerifiedOutlined';
import ElectronicDiaryIcon from '@mui/icons-material/AssignmentOutlined';
import TimeTable from '@mui/icons-material/DateRange';
import SaveDataIcon from '@mui/icons-material/Save';
import TravelIcon from '@mui/icons-material/CardTravel';
import ActivitiesIcon from '@mui/icons-material/GroupAddOutlined';
import WorkWithParentsIcon from '@mui/icons-material/GroupWork';
import WorkSchedule from '@mui/icons-material/Schedule';
import AttendanceIcon from '@mui/icons-material/HistoryOutlined';
import ImportantEventInGroupIcon from '@mui/icons-material/AssignmentLate';
import PhotoLibraryIcon from '@mui/icons-material/CollectionsOutlined';
import StorageIcon from '@mui/icons-material/SaveOutlined';

import { eventTypes } from '../../../constants/eventTypes';
import moment from 'moment';
import config from '../../../config/config';
import { routePaths } from '../../../routePaths';
import UnreadThreadsBadgeWrapper from '../../common/privateMessages/UnreadThreadsBadgeWrapper';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import insuranceLogoUrl from '../../../img/logo_b_1280.svg';
import insuranceLogoWhiteUrl from '../../../img/logo_b_1280_white.svg';
import InsuranceInfoDialog from '../../insuranceInfo/InsuranceInfoDialog';

import { connect, useSelector } from 'react-redux';
import { useThemeMode } from '../../../hooks/useThemeMode';
import { themeTypes } from '../../../styles/appTheme';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import GroupIcon from '@mui/icons-material/GroupsOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';

const TeacherMenu = ({
  firstGroupId,
  currentRoute,
  onRouteSelect,
  isDaycareEnabled,
  insurance,
  choosenGroup,
  teacherAllGroupVisibility
}) => {
  const themeMode = useThemeMode();
  const [insuranceDialog, setInsuranceDialog] = useState(false);
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const unreadPostsCount = useSelector((state) => state.staffMessageBoard.unreadedPosts).length;
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Obecności"
        icon={
          <ElectronicDiaryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Dziennik obecności"
            icon={
              <ElectronicDiaryIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
              />
            }
            routePath={routePaths.attendance}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik obecności - zajęcia dodatkowe"
            icon={
              <ElectronicDiaryIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
              />
            }
            routePath={routePaths.activitiesAttendanceSheetPage}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Historia"
            icon={
              <AttendanceIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
              />
            }
            routePath={routePaths.attendanceMonthlyHistory}
            routeParams={[!choosenGroup.isTemporary ? choosenGroup.id : firstGroupId, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Nieobecne jutro"
            icon={
              <EventBusyIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
              />
            }
            routePath={routePaths.absentTomorrow}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      {isDaycareEnabled && (
        <MenuRoute
          text="Aktywność dzieci"
          icon={
            <DaycareIcon
              fontSize="medium"
              sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
            />
          }
          routePath={routePaths.daycare}
          key={getNextKey()}
          {...rest}
        />
      )}
      <MenuRoute
        text="Wiadomości"
        icon={
          <PrivateMessagesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        routePath={routePaths.staffMemberPrivateMessages}
        key={getNextKey()}
        badge={<UnreadThreadsBadgeWrapper />}
        {...rest}
      />
      <MenuRoute
        text="Ogłoszenia"
        icon={
          <MessageBoardIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color4 }}
          />
        }
        routePath={routePaths.messageBoard}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Kalendarz wydarzeń"
        icon={
          <EventsCalendarIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.eventsCalendar}
        routeParams={[year, month]}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Galeria"
        icon={
          <PhotoLibraryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.gallery}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color21 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Rejestr dzieci"
        icon={
          <ChildrenIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.children}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Dziennik specjalistyczny"
        icon={
          <AssignmentSharpIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Grupy"
            icon={
              <GroupIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color8 }}
              />
            }
            routePath={routePaths.psychologicalGroups}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Pomoc psychologiczno-pedagogiczna"
            icon={
              <AssignmentTurnedInIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
              />
            }
            routePath={routePaths.psychologicalHelp}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik zajęć indywidualnych"
            icon={
              <PermContactCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.individualClasses}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik zajęć grupowych"
            icon={
              <GroupsSharpIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color9 }}
              />
            }
            routePath={routePaths.groupClasses}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        primaryText="Dziennik elektroniczny"
        icon={
          <ElectronicDiaryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Ramowy rozkład dnia"
            icon={
              <TimeTable
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryTimeTable}
            routeParams={[eventTypes.daySchedule.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zapis pracy wychowawczo-dydaktycznej"
            icon={
              <SaveDataIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiarySaveWork}
            routeParams={[eventTypes.saveWork.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Wycieczki"
            icon={
              <TravelIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryTrip}
            routeParams={[eventTypes.trip.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zajęcia dodatkowe"
            icon={
              <ActivitiesIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryAdditionalActivities}
            routeParams={[eventTypes.additionalActivities.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Roczny plan współpracy z rodzicami"
            icon={
              <WorkWithParentsIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryWorkWithParents}
            routeParams={[eventTypes.workWithParents.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Plan pracy"
            icon={
              <WorkSchedule
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryWorkPlan}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik obecności"
            icon={
              <AttendanceIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.attendanceMonthlyHistory}
            routeParams={[!choosenGroup.isTemporary ? choosenGroup.id : firstGroupId, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Ważne wydarzenia z życia grupy"
            icon={
              <ImportantEventInGroupIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.messageBoard}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        primaryText="Wewnętrzne"
        icon={
          <AssignmentIndIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color9 }}
          />
        }
        badgeValue={unreadPostsCount}
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Ogłoszenia"
            icon={
              <MessageBoardIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color9 }}
              />
            }
            routePath={routePaths.staffMessageBoard}
            badgeValue={unreadPostsCount}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Kalendarz"
            icon={
              <EventsCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color9 }}
              />
            }
            routePath={routePaths.staffCalendar}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Osoby upoważnione do odbioru dziecka"
            icon={
              <LegalGuardiansIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color9 }}
              />
            }
            routePath={routePaths.authorizedToPickUpChild}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Zajęcia dodatkowe"
        icon={
          <ActivitiesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color8 }}
          />
        }
        routePath={routePaths.activities}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Raporty"
        icon={
          <ReportsIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.reports}
        key={getNextKey()}
        {...rest}
      />
      <Divider />
      <MenuRoute
        text="Co nowego?"
        icon={
          <ChangeLogIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.changeLog}
        key={getNextKey()}
        {...rest}
      />
      {insurance && insurance.isActive && (
        <ListItem key={getNextKey()} component="li">
          <ListItemButton onClick={() => setInsuranceDialog(true)}>
            <img
              src={themeMode !== themeTypes.dark.value ? insuranceLogoUrl : insuranceLogoWhiteUrl}
              width="70%"
              alt="Ubezpieczenia"
            />
          </ListItemButton>
        </ListItem>
      )}
      <ListItem
        button
        key={getNextKey()}
        onClick={() => window.open(config.staffManualUrl, '_blank')}
        component="li"
        aria-label="Otwórz instrukcję obsługi">
        <ListItemIcon>
          <ManualIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{ '& .MuiListItemText-primary': { fontWeight: (theme) => theme.typography.fontWeightBold } }}
          primary="Instrukcja obsługi"
        />
      </ListItem>
      {insuranceDialog && (
        <InsuranceInfoDialog insurance={insurance} onClose={() => setInsuranceDialog(false)} open={insuranceDialog} />
      )}
    </List>
  );
};

function mapStateToProps(state) {
  return {
    choosenGroup: state.digitalDiary.choosenGroup,
    teacherAllGroupVisibility: state.configuration.unit.teacherAllGroupVisibility
  };
}

TeacherMenu.propTypes = {
  firstGroupId: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  isDaycareEnabled: PropTypes.bool,
  insurance: PropTypes.object,
  choosenGroup: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(TeacherMenu);
