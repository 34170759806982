import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../../actions/authActions';
import SignInForm from './SignInForm';
import WelcomeScreen from '../../common/welcomeScreen/WelcomeScreen';
import { UserModelValidator } from '../../../models/children/ChildModelsValidator';

class SignInPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      signInData: { userName: '', password: '' },
      errorMessage: null,
      errors: { userName: '', password: '' }
    };

    this.onUpdateSignInState = this.onUpdateSignInState.bind(this);
    this.onSignIn = this.onSignIn.bind(this);
    this.onSignInWithToken = this.onSignInWithToken.bind(this);
    this.handleChangeErrorState = this.handleChangeErrorState.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastAuthErrorMessage !== this.props.lastAuthErrorMessage) {
      this.handleChangeErrorState(this.props.lastAuthErrorMessage);
    }
  }

  handleChangeErrorState(value) {
    this.setState({ errorMessage: value });
  }

  onUpdateSignInState(event) {
    const field = event.target.name;
    const { signInData } = this.state;
    signInData[field] = event.target.value;
    return this.setState({ signInData });
  }

  onSignIn(event) {
    this.setState({
      errors: new UserModelValidator().validate(this.state.signInData).errors
    });
    event.preventDefault();
    new UserModelValidator().validate(this.state.signInData).isValid &&
      this.props.actions.signInAsync(this.state.signInData);
  }

  onSignInWithToken(data) {
    this.props.actions.signInWithToken(data);
  }

  render() {
    return (
      <WelcomeScreen>
        <SignInForm
          errors={this.state.errors}
          query={this.props.location.query}
          signInData={this.state.signInData}
          onChange={this.onUpdateSignInState}
          onSignIn={this.onSignIn}
          features={this.props.unit.features}
          onSignInWithToken={this.onSignInWithToken}
          errorMessage={this.state.errorMessage}
          isProcessing={this.props.isProcessing}
        />
      </WelcomeScreen>
    );
  }
}

SignInPage.propTypes = {
  actions: PropTypes.object.isRequired,
  lastAuthErrorMessage: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired,
  unit: PropTypes.object.isRequired,
  location: PropTypes.object
};

function mapStateToProps(state) {
  return {
    lastAuthErrorMessage: state.auth.lastAuthErrorMessage,
    isProcessing: state.auth.isProcessing,
    unit: state.configuration.unit
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
