import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import UserRoleIcon from '../../../userRoles/UserRoleIcon';
import PrivateFile from '../PrivateFile/PrivateFile';

const useStyles = makeStyles((theme) => ({
  chip: {
    maxWidth: '100%',
    border: ({ color }) => `2px solid ${theme.palette.color.secondary}`
  },
  icon: {
    color: ({ color }) => color || theme.palette.color.color4
  },
  label: {
    whiteSpace: ({ wrapToNextLine }) => (wrapToNextLine ? 'pre-wrap' : 'nowrap')
  }
}));

const SingleUserRoleChip = ({
  customClass,
  wrapToNextLine = false,
  name,
  onDelete,
  role,
  isSpecialOption,
  ...rest
}) => {
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.contrastColor);
  const color = isContrastColor ? theme.palette.color.color4 : theme.palette.components.role[role];
  const cl = useStyles({ color, wrapToNextLine });

  if (isSpecialOption) return <PrivateFile text="Wyłącz udostępnianie plików" />;
  return (
    <Chip
      icon={<UserRoleIcon role={role} className={cl.icon} />}
      label={<Typography variant="chipContent">{name}</Typography>}
      className={`${cl.chip} ${customClass}`}
      variant="outlined"
      onDelete={onDelete}
      classes={{ label: cl.label }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      {...rest}
    />
  );
};

SingleUserRoleChip.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  color: PropTypes.string,
  onDelete: PropTypes.func,
  wrapToNextLine: PropTypes.bool,
  customClass: PropTypes.string,
  isSpecialOption: PropTypes.bool
};

export default SingleUserRoleChip;
