import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, ListItem, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import Strong from '../../../common/Strong';

const AdvancedInterestListItem = ({ data, editable, onDelete, onEdit }) => {
  return (
    <ListItem>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'start', md: 'center' },
          p: 0,
          width: '100%'
        }}>
        <Typography component="span">Stopa odsetek: {data.rate}</Typography>
        <Box>
          <Typography variant="body1" component="span">
            Data rozpoczęcia obowiązywania:{' '}
          </Typography>
          <Strong variant="body1" component="span">
            {data.startDate}
          </Strong>
        </Box>
      </Box>
      {!!editable && (
        <Box sx={{ display: 'flex' }}>
          <IconButton onClick={() => onEdit(data)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => onDelete(data)}>
            <Delete sx={{ color: (theme) => theme.palette.color.error }} />
          </IconButton>
        </Box>
      )}
    </ListItem>
  );
};

AdvancedInterestListItem.propTypes = {
  data: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default AdvancedInterestListItem;
