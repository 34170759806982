import { Typography } from '@mui/material';
import { handleColorSelector } from '../common/settlementStatusColor';
import { CalculationStatus } from '../../constants/calculationStatus';

const SettlementStatus = ({ calculationStatus }) => {
  return (
    <Typography
      sx={{
        fontSize: 12,
        background: handleColorSelector(CalculationStatus[calculationStatus].value),
        textAlign: 'center',
        borderRadius: '5px',
        px: '5px',
        display: 'inline',
        padding: '0.4em 0.6em 0.2em',
        fontWeight: (theme) => theme.typography.fontWeightMedium,
        letterSpacing: '0.6px',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        textTransform: 'uppercase',
        color: (theme) => theme.palette.color.primary
      }}>
      {CalculationStatus[calculationStatus].name}
    </Typography>
  );
};
export default SettlementStatus;
