import PropTypes from 'prop-types';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import DatePicker from '../../common/DatePicker';
import moment from 'moment';

const ListOfFeesPaidReport = ({ startDate, selectOther = false, onChange }) => {
  return (
    <FieldsetWrapper legend="Pola dla generowania raportu zestawienie opłat wnoszonych">
      <DatePicker
        hintText="Na dzień"
        floatingLabelText="Na dzień"
        floatingLabelFixed={false}
        name="startDate"
        value={startDate ? new Date(startDate) : undefined}
        onChange={(e, value) => onChange({ target: { name: 'startDate', value: moment(value).format('YYYY-MM-DD') } })}
      />
      <Checkbox
        label="Dołącz w raporcie płatności typu 'Inne'"
        defaultChecked={selectOther}
        onCheck={(event, isChecked) => onChange({ target: { name: 'selectOther', value: isChecked } })}
      />
    </FieldsetWrapper>
  );
};

ListOfFeesPaidReport.propTypes = {
  startDate: PropTypes.string.isRequired,
  selectOther: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ListOfFeesPaidReport;
