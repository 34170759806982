import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

const FlatButton = ({
  label,
  onClick,
  primary,
  secondary,
  className,
  disabled,
  labelStyle,
  icon,
  children,
  onKeyDown,
  variant,
  style,
  sx,
  ...rest
}) => {
  return (
    <Button
      disabled={disabled}
      className={className}
      variant={variant || 'contained'}
      onClick={onClick}
      onKeyDown={onKeyDown}
      // eslint-disable-next-line no-nested-ternary
      color={primary ? 'primary' : secondary ? 'error' : 'secondary'}
      startIcon={icon}
      sx={{ ...style, ...sx }}
      aria-label={`${label || children}`}
      {...rest}>
      {label ? (
        <Typography component="span" sx={{ ...labelStyle }}>
          {label}
        </Typography>
      ) : (
        children
      )}
    </Button>
  );
};

FlatButton.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.any,
  icon: PropTypes.any,
  variant: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onKeyDown: PropTypes.func,
  style: PropTypes.object
};

export default FlatButton;
