import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from '../../common/DatePicker';
import DialogForm from '../../forms/DialogForm';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import moment from 'moment';
import TextField from '../../common/TextField';
import { Box, Typography } from '@mui/material';

class ApproveDuesDialog extends React.Component {
  constructor(props) {
    super(props);
    const defaultMaturityDate = 14;
    this.state = {
      approveDetails: {
        maturityDate: moment().add(defaultMaturityDate, 'days').format('YYYY-MM-DD'),
        maturityDateDays: defaultMaturityDate,
        dueCalculationsIds: props.dueCalculationsIds
      },
      errors: {},
      isSaveDisabled: false
    };

    this.handleMaturityDateChange = this.handleMaturityDateChange.bind(this);
    this.handleMaturityDateDaysChange = this.handleMaturityDateDaysChange.bind(this);
  }

  handleMaturityDateChange(value) {
    const { approveDetails } = this.state;
    approveDetails.maturityDate = value.format('YYYY-MM-DD');
    approveDetails.maturityDateDays = value.diff(moment().startOf('day'), 'days');
    this.setState({
      ...this.state,
      errors: {
        maturityDate: approveDetails.maturityDateDays < 0 ? 'data nie może wskazywać przeszłości' : undefined
      },
      isSaveDisabled: approveDetails.maturityDateDays < 0
    });
    this.setState({ approveDetails });
  }

  handleMaturityDateDaysChange(value) {
    const { approveDetails } = this.state;
    approveDetails.maturityDate = moment().add(value, 'days').format('YYYY-MM-DD');
    approveDetails.maturityDateDays = value;
    this.setState({
      ...this.state,
      errors: {
        maturityDateDays: approveDetails.maturityDateDays < 0 ? 'termin zapłaty nie może być ujemny' : undefined
      },
      isSaveDisabled: approveDetails.maturityDateDays < 0
    });
    this.setState({ approveDetails });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    const { approveDetails } = this.state;
    return (
      <DialogForm
        maxWidth="sm"
        header="Zatwierdzanie naliczonych opłat"
        onSave={() => onSave(this.state.approveDetails)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="approveDuesUi"
        saveLabel="Zatwierdź"
        saveDisabled={this.state.isSaveDisabled}>
        {!this.props.isEmployee && <Typography>Zatwierdzone należności pojawią się na kontach opiekunów.</Typography>}

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            hintText="Termin płatności (ilość dni)"
            floatingLabelText="Termin płatności (ilość dni)"
            floatingLabelFixed={false}
            name="maturityDateDays"
            value={approveDetails.maturityDateDays || '0'}
            onChange={(e) => this.handleMaturityDateDaysChange(e.target.value)}
            onBlur={(e) => this.handleMaturityDateDaysChange(tryConvertToNumber(e.target.value))}
            errorText={this.state.errors.maturityDateDays}
            sx={{ mt: 0, mr: 3, mb: 3, ml: 0, width: '250px', maxWidth: '100%' }}
          />
          <DatePicker
            hintText="Termin płatności"
            floatingLabelText="Termin płatności"
            floatingLabelFixed={false}
            name="maturityDate"
            value={new Date(approveDetails.maturityDate)}
            errorText={this.state.errors.maturityDate}
            minDate={new Date('2000/01/01')}
            onChange={(empty, date) => this.handleMaturityDateChange(moment(date))}
            sx={{ minWidth: 100, m: 0 }}
          />
        </Box>
      </DialogForm>
    );
  }
}

ApproveDuesDialog.propTypes = {
  isEmployee: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  dueCalculationsIds: PropTypes.array.isRequired
};

export default ApproveDuesDialog;
