import React from 'react';
import _ from 'lodash';
import uuid from 'uuid/v4';

function hasChildren(node) {
  return node.props.children && node.props.children.length > 0;
}

/* eslint no-console:0 */
function extendWithEnterLeaveHooks(node, onEnter, onLeave) {
  return React.cloneElement(node, {
    onEnter: (nextState, replace) => {
      console.log(`Entering url: ${nextState.location.pathname}`);
      onEnter(nextState.location, nextState.params, _.findLast(nextState.routes, (x) => x.path).path, replace);
    },
    onLeave: () => {
      console.log(`Leaving url: ${node.props.path}`);
      onLeave(node.props.path);
    },
    key: `${node.props.path}-${uuid()}`
  });
}

function iterateThroughChildren(node, onEnter, onLeave) {
  if (hasChildren(node)) {
    const wrappedChildren = node.props.children.map((c) => iterateThroughChildren(c, onEnter, onLeave));
    const wrappedNode = React.cloneElement(node, {}, wrappedChildren);
    return extendWithEnterLeaveHooks(wrappedNode, onEnter, onLeave);
  }
  return extendWithEnterLeaveHooks(node, onEnter, onLeave);
}

export const wrapRoutesActions = (routes, onEnter, onLeave) => {
  return iterateThroughChildren(routes, onEnter, onLeave);
};
