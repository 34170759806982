import ApiBase, { ROOT_URL } from './ApiBase';
import moment from 'moment';
import _ from 'lodash';

class DaycareApi extends ApiBase {
  static getConfiguration() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/daycare`).type('application/json'));
  }

  static updateConfiguration(settings) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/configuration/daycare`).send(settings).type('application/json')
    );
  }

  static getDaycareEntries(groupId, date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/daycare/groups/${groupId}/${moment(date).format('YYYY-MM-DD')}`).type('application/json')
    );
  }

  static getChildDaycareEntries(childId, date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/daycare/children/${childId}/${moment(date).format('YYYY-MM')}`).type('application/json')
    );
  }

  static createDaycareEntry(entry) {
    return super.toPromise(
      (agent) =>
        agent
          .post(`${ROOT_URL}/daycare`)
          .send(_.omit(entry, ['id']))
          .type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateDaycareEntry(entry) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/daycare/${entry.id}`).send(entry).type('application/json')
    );
  }
}

export default DaycareApi;
