import React from 'react';
import PropTypes from 'prop-types';
import GalleryMoveFilesForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../../forms/gallery/GalleryMoveFilesForm';
import FormDialog from '../../../../formik/formDialog/FormDialog';
import DialogContent from './DialogContent';
import { Divider } from '@mui/material';

const MoveDialog = ({ isDialogOpen, onClose, onSubmit, albums, selectedItems, inProgress }) => {
  return (
    <FormDialog
      title="Przenieś multimedia do innego albumu"
      open={isDialogOpen}
      onClose={onClose}
      initialValues={new InitialValues()}
      validationSchema={VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={onSubmit}
      requiredFieldText
      maxWidth="sm"
      inProgress={inProgress}>
      <DialogContent selectedItems={selectedItems} />
      <Divider sx={{ mt: 4 }} />
      <GalleryMoveFilesForm albums={albums} />
    </FormDialog>
  );
};

MoveDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  albums: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  inProgress: PropTypes.bool.isRequired
};

export default MoveDialog;
