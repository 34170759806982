import PropTypes from 'prop-types';
import React from 'react';
import { Button, Fade, Grid, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import uuid from 'uuid/v4';

const useStyles = makeStyles((theme) => ({
  container: { padding: 15 },
  button: {
    background: ({ isPrivate, isDisabled }) =>
      // eslint-disable-next-line no-nested-ternary
      isDisabled && !isPrivate
        ? theme.palette.color.disabled
        : isPrivate
        ? `linear-gradient(to right,${theme.palette.color.color7}, ${theme.palette.color.color21})`
        : `linear-gradient(to right,${theme.palette.color.color6}, ${theme.palette.color.color25} )`,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  },
  animatedItem: {
    animation: `$Width 1000ms ${theme.transitions.easing.easeInOut}`
  },
  text: {
    textTransform: 'none',
    fontSize: 18,
    color: theme.palette.color.primary,
    whiteSpace: 'nowrap'
  },
  '@keyframes Width': {
    '0%': {
      maxWidth: '0%',
      opacity: 0
    },
    '100%': {
      maxWidth: '100%',
      opacity: 1
    }
  },
  '@keyframes Gradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 75%'
    }
  }
}));

const PrivateButton = ({ isPrivate, onPrivate, isDisabled, isSingleShare, isFolder }) => {
  const cl = useStyles({ isPrivate, isDisabled });

  return (
    <Grid container className={cl.container}>
      <Button
        key={uuid()}
        className={` ${cl.button} ${cl.animatedItem}`}
        variant="contained"
        startIcon={
          <>
            <Fade in={true} timeout={1000}>
              <Box>
                {isPrivate ? (
                  <LockOpenIcon sx={{ fontSize: 34, mr: 5, mt: 1 }} />
                ) : (
                  <LockIcon sx={{ fontSize: 34, mr: 5, mt: 1 }} />
                )}
              </Box>
            </Fade>
          </>
        }
        onClick={onPrivate}
        disabled={isDisabled && !isPrivate}>
        <Fade in={true} timeout={1000}>
          <Box>
            <Typography>
              {isPrivate
                ? `Przywróć możliwość udostępniania ${isFolder ? 'folderu' : isSingleShare ? 'pliku' : 'plików'}`
                : `Wyłącz udostępnianie ${
                    isFolder ? 'wybranego folderu' : isSingleShare ? 'wybranego pliku' : ' wybranych plików'
                  }`}
            </Typography>
          </Box>
        </Fade>
      </Button>
    </Grid>
  );
};

PrivateButton.propTypes = {
  isDisabled: PropTypes.bool,
  isPrivate: PropTypes.bool,
  onPrivate: PropTypes.func,
  isSingleShare: PropTypes.bool,
  isFolder: PropTypes.bool
};

function moviePropsAreEqual(prev, next) {
  return prev.isPrivate === next.isPrivate;
}

export default React.memo(PrivateButton, moviePropsAreEqual);
