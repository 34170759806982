import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function mealDetailsUiReducer(state = initialState.mealDetailsUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_MEAL_DETAILS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.CREATE_MEAL_DETAILS_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.CREATE_MEAL_DETAILS_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.CREATE_MEAL_DETAILS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.CREATE_MEAL_DETAILS_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //update
    case types.UPDATE_MEAL_DETAILS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_MEAL_DETAILS_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.UPDATE_MEAL_DETAILS_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.UPDATE_MEAL_DETAILS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.UPDATE_MEAL_DETAILS_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    default:
      return state;
  }
}
