import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Albums from './Albums/Albums';
import Multimedia from './Multimedia/Multimedia';
import AlbumManagment from './Albums/AlbumManagement';
import { useDispatch, useSelector } from 'react-redux';
import * as galleryActions from '../../../actions/galleryActions';
import normalUserRoleNames from '../../../constants/userRoleNames';
import reducedUserRoleNames from '../../../constants/reducedUserRoleNames';
import { browserHistory } from 'react-router';
import { routePaths } from '../../../routePaths';
import * as notificationsActions from '../../../actions/notificationActions';
import * as SortTypes from '../../../constants/sortTypes';
import { useIsMount } from '../../../utils/useIsMount';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

const GalleryContainer = ({ location }) => {
  const [album, setAlbum] = useState(null);
  const groups = useSelector((state) => state.groups);
  const activities = useSelector((state) => state.activities);
  const multimedia = useSelector((state) => state.gallery.multimedia);
  const albums = useSelector((state) => state.gallery.albums);
  const auth = useSelector((state) => state.auth);
  const [sortType, setSortType] = useState(SortTypes.NEWEST);
  const dispatch = useDispatch();
  const firstRender = useIsMount();
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const userRoleNames = nadarzyn ? reducedUserRoleNames : normalUserRoleNames;

  const enableEditRoles = [userRoleNames.staffMemberPrincipal.value, userRoleNames.staffMemberTeacher.value];

  const isEditAvailable = enableEditRoles.includes(auth.userRole);

  const findAndSetAlbum = (id) => {
    const newAlbum = albums.find((item) => item.id === id);
    setAlbum(newAlbum);
    return newAlbum;
  };

  useEffect(() => {
    setAlbum(null);

    if (firstRender) {
      if (location.query.id && albums.length) {
        const isAlbum = findAndSetAlbum(location.query.id);
        if (!isAlbum) {
          dispatch(notificationsActions.showError('Nie masz dostępu do podanego katalogu'));
          browserHistory.push({
            pathname: routePaths.gallery,
            query: {}
          });
        }
        if (!multimedia.length && isAlbum) dispatch(galleryActions.fetchMultimedia(location.query.id));
      }
    }
    if (album && location.query.id) {
      findAndSetAlbum(album.id);
    }
  }, [album, albums, dispatch, findAndSetAlbum, firstRender, location.query.id, multimedia.length]);

  const handleSelectAlbum = (id) => {
    findAndSetAlbum(id);
    dispatch(galleryActions.fetchMultimedia(id));
  };
  return (
    <>
      <PageHeaderText title="Galeria zdjęć" titleIcon={<PhotoCameraIcon />} />
      <ContentWrapper>
        {album ? (
          <AlbumManagment
            album={album}
            groups={groups}
            activities={activities}
            isEditAvailable={isEditAvailable}
            onBack={() => setAlbum(null)}
          />
        ) : (
          <Albums
            groups={groups}
            activities={activities}
            isEditA
            albums={albums}
            isEditAvailable={isEditAvailable}
            sortType={sortType}
            setSortType={setSortType}
            selectAlbum={handleSelectAlbum}
          />
        )}
        {album && multimedia && (
          <Multimedia multimedia={multimedia} albums={albums} album={album} isEditAvailable={isEditAvailable} />
        )}
      </ContentWrapper>
    </>
  );
};

GalleryContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default GalleryContainer;
