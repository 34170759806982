import api from '../api/SettlementsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as reportsActions from './reportsActions';
import { CalculationStatus } from '../constants/calculationStatus';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadSettlementsAsync(year, month) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_SETTLEMENTS });
    return api
      .getSettlements(year, month)
      .then((settlements) => dispatch({ type: types.LOAD_SETTLEMENTS_SUCCESS, settlements }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy opłat za miesiąc ${month}.${year}`));
        return logger.error(error);
      });
  };
}

export function loadStaffSettlementsAsync(year, month) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_SETTLEMENTS });
    return api
      .getStaffSettlements(year, month)
      .then((settlements) => dispatch({ type: types.LOAD_SETTLEMENTS_SUCCESS, settlements }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy opłat za miesiąc ${month}.${year}`));
        return logger.error(error);
      });
  };
}

export function getChildUnpaidDues(childId) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHILD_DUES });
    return api
      .getChildUnpaidDues(childId)
      .then((childDues) => dispatch({ type: types.LOAD_CHILD_DUES_SUCCESS, childDues }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy opłat dla wybranego dziecka'));
        return logger.error(error);
      });
  };
}

export function getChildBalanceSummary(childId) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHILD_BALANCE_SUMMARY });
    return api
      .getChildBalanceSummary(childId)
      .then((childBalanceSummary) => dispatch({ type: types.LOAD_CHILD_BALANCE_SUMMARY_SUCCESS, childBalanceSummary }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać podsumowania salda dla wybranego dziecka'));
        return logger.error(error);
      });
  };
}

export function getStaffUnpaidDues(staffId) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_STAFF_DUES });
    return api
      .getStaffUnpaidDues(staffId)
      .then((staffDues) => dispatch({ type: types.LOAD_STAFF_DUES_SUCCESS, staffDues }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy opłat dla wybranego dziecka'));
        return logger.error(error);
      });
  };
}

export function calculateSettlementsStart() {
  return { type: types.CALCULATE_SETTLEMENTS };
}

export function calculateSettlementsCancel() {
  return { type: types.CALCULATE_SETTLEMENTS_CANCEL };
}

export function calculateSettlementsAsync(year, month, params) {
  return (dispatch, getState) => {
    dispatch({ type: types.CALCULATE_SETTLEMENTS_SUBMIT });
    return api
      .calculateSettlements(year, month, params)
      .then((settlements) => {
        dispatch(
          notificationActions.showSuccess(
            `Wygenerowano opłaty ${get.displaySettlements(getState, params)} za miesiąc ${month}.${year}`
          )
        );
        dispatch({ type: types.CALCULATE_SETTLEMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CALCULATE_SETTLEMENTS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się wygenerować opłat ${get.displaySettlements(getState, params)} za miesiąc ${month}.${year}`
          )
        );
        return logger.error(error);
      });
  };
}

export function calculateCorrectiveDuesStart() {
  return { type: types.CALCULATE_CORRECTIVE_DUES };
}

export function calculateCorrectiveDuesCancel() {
  return { type: types.CALCULATE_CORRECTIVE_DUES_CANCEL };
}

export function calculateCorrectiveDuesAsync(year, month, dueIds, options) {
  return (dispatch) => {
    dispatch({ type: types.CALCULATE_CORRECTIVE_DUES_SUBMIT });
    return api
      .calculateCorrectiveDues(year, month, dueIds, options)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess('Wygenerowano korekty'));
        dispatch({ type: types.CALCULATE_CORRECTIVE_DUES_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CALCULATE_CORRECTIVE_DUES_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się wygenerować korekt'));
        return logger.error(error);
      });
  };
}

export function calculateStaffSettlementsAsync(year, month, params) {
  return (dispatch) => {
    dispatch({ type: types.CALCULATE_SETTLEMENTS_SUBMIT });
    return api
      .calculateStaffSettlements(year, month, params)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess(`Wygenerowano opłaty za miesiąc ${month}.${year}`));
        dispatch({ type: types.CALCULATE_SETTLEMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CALCULATE_SETTLEMENTS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się wygenerować opłat za miesiąc ${month}.${year}`));
        return logger.error(error);
      });
  };
}

export function print(duesIds) {
  return (dispatch) => {
    dispatch(reportsActions.generateDuesListReportAsync({ ids: duesIds.join(',') }));
  };
}

export function updateSettlementStart() {
  return { type: types.UPDATE_SETTLEMENT };
}

export function updateSettlementCancel() {
  return { type: types.UPDATE_SETTLEMENT_CANCEL };
}

export function updateSettlementAsync(nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_SETTLEMENT_SUBMIT });
    return nextSettlement.schemeResultItems
      .reduce(
        (prom, schemeResult) =>
          prom.then(() =>
            api.updateAdjustment(
              settlement.id,
              schemeResult.schemeSnaphot.id,
              schemeResult.adjustmentReason,
              schemeResult.adjustmentAmount
            )
          ),
        Promise.resolve()
      )
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano opłatę dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        dispatch({ type: types.UPDATE_SETTLEMENT_SUCCESS, settlement });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_SETTLEMENT_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować opłaty dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function approveDuesStart() {
  return { type: types.APPROVE_DUE };
}

export function approveDuesCancel() {
  return { type: types.APPROVE_DUE_CANCEL };
}

export function approveDuesAsync(year, month, approveDetails, refreshStaffDues) {
  return (dispatch) => {
    dispatch({ type: types.APPROVE_DUE_SUBMIT });
    return api
      .approveDues(year, month, approveDetails, refreshStaffDues)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess('Zatwierdzono opłaty'));
        dispatch({ type: types.APPROVE_DUE_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.APPROVE_DUE_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zatwierdzić opłat'));
        return logger.error(error);
      });
  };
}

export function approveStaffDuesAsync(year, month, approveDetails) {
  return approveDuesAsync(year, month, approveDetails, true);
}

export function cancelSettlementsAsync(year, month, settlementIds) {
  return (dispatch) => {
    dispatch({ type: types.CANCEL_SETTLEMENT_SUBMIT });
    return api
      .cancelSettlements(year, month, settlementIds)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess('Anulowano opłaty'));
        dispatch({ type: types.CANCEL_SETTLEMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch(notificationActions.showError(error.response.body.generalError));
          return dispatch({ type: types.CANCEL_SETTLEMENT_FAILURE, errors: error.response.body });
        }
        dispatch(notificationActions.showError('Nie udało się anulować opłat'));
        return logger.error(error);
      });
  };
}

export function deleteSettlementsAsync(year, month, settlementIds, refreshStaffDues) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_SETTLEMENTS_SUBMIT });
    return api
      .deleteSettlements(year, month, settlementIds, refreshStaffDues)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess('Usunięto opłaty'));
        dispatch({ type: types.DELETE_SETTLEMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.DELETE_SETTLEMENTS_FAILURE, errors: error.response.body });
        dispatch({ type: types.DELETE_SETTLEMENTS_FAILURE });
        dispatch(notificationActions.showError('Nie udało się usunąć opłat'));
        return logger.error(error);
      });
  };
}

export function deleteStaffSettlementsAsync(year, month, settlementIds) {
  return deleteSettlementsAsync(year, month, settlementIds, true);
}

export function cancelSettlementAsync(year, month, nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  settlement.calculationStatus = CalculationStatus.canceled.value;
  return (dispatch) => {
    dispatch({ type: types.CANCEL_SETTLEMENT_SUBMIT });
    return api
      .cancelSettlement(settlement.id)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Anulowano opłatę (${settlement.number}) dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        dispatch({ type: types.CANCEL_SETTLEMENT_SUCCESS, settlement });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch(notificationActions.showError(error.response.body.generalError));
          return dispatch({ type: types.CANCEL_SETTLEMENT_FAILURE, errors: error.response.body });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się anulować opłaty (${settlement.number}) dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function cancelSettlementDelayInterestsAsync(nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  return (dispatch) => {
    dispatch({ type: types.CANCEL_SETTLEMENT_DELAY_INTERESTS_SUBMIT });
    return api
      .cancelDelayInterests(settlement.id)
      .then(() => {
        settlement.totalDelayInterests = null;
        settlement.calculatedTotalWithDelayInterests = null;
        settlement.delayInterestCalculationDate = null;
        dispatch(notificationActions.showSuccess(`Anulowano odsetki dla opłaty ${settlement.number}`));
        dispatch({ type: types.CANCEL_SETTLEMENT_DELAY_INTERESTS_SUCCESS, settlement });
        return true;
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CANCEL_SETTLEMENT_DELAY_INTERESTS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się anulować odsetek dla opłaty ${settlement.number}`));
        logger.error(error);
        return false;
      });
  };
}

export function saveCustomInterestsAsync(nextSettlement, value) {
  const settlement = Object.assign({}, nextSettlement);
  return async (dispatch) => {
    try {
      dispatch({ type: types.EDIT_SETTLEMENT_DELAY_INTERESTS_SUBMIT });

      const response = await api.saveCustomDelayInterests(settlement.id, parseFloat(value));
      settlement.totalDelayInterests = response.totalDelayInterests;
      settlement.calculatedTotalWithDelayInterests = response.calculatedTotalWithDelayInterests;
      settlement.delayInterestCalculationDate = response.delayInterestCalculationDate;
      dispatch(notificationActions.showSuccess(`Zmieniono odsetki dla opłaty ${settlement.number}`));
      dispatch({ type: types.EDIT_SETTLEMENT_DELAY_INTERESTS_SUCCESS, settlement });
      return response;
    } catch (error) {
      dispatch(notificationActions.showError(`Nie udało się zmienić odsetek dla opłaty ${settlement.number}`));
      logger.error(error);
      return false;
    }
  };
}

export function reserveBalanceFunds(nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  return (dispatch) => {
    dispatch({ type: types.RESERVE_BALANCE_FUNDS_SUBMIT });
    return api
      .reserveBalanceFunds(settlement.id)
      .then((data) => {
        settlement.reservedBalanceFunds = data.reservedBalanceFunds;
        settlement.calculatedTotal = data.newCalculatedTotal;
        settlement.personAvailableFunds = data.newAvailableFunds;
        settlement.hasFundsAvailable = data.newAvailableFunds > 0;
        dispatch(notificationActions.showSuccess('Zaktualizowano opłatę'));
        dispatch({ type: types.RESERVE_BALANCE_FUNDS_SUCCESS, settlement });
        dispatch({
          type: types.AVAILABLE_FUNDS_UPDATED,
          personId: settlement.person.id,
          newAvailableFunds: data.newAvailableFunds,
          hasFundsAvailable: settlement.hasFundsAvailable
        });
        return data;
      })
      .catch((error) => {
        if (error.status === 412) return dispatch(notificationActions.showError(error.response.body.generalError));
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        logger.error(error);
        return null;
      });
  };
}

export function removeReservedFunds(nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  return (dispatch) => {
    dispatch({ type: types.REMOVE_RESERVED_BALANCE_FUNDS_SUBMIT });
    return api
      .removeReservedFunds(settlement.id)
      .then((data) => {
        settlement.reservedBalanceFunds = null;
        settlement.calculatedTotal = data.newCalculatedTotal;
        settlement.personAvailableFunds = data.newAvailableFunds;
        settlement.hasFundsAvailable = data.newAvailableFunds > 0;
        dispatch(notificationActions.showSuccess('Zaktualizowano opłatę'));
        dispatch({ type: types.REMOVE_RESERVED_BALANCE_FUNDS_SUCCESS, settlement });
        dispatch({
          type: types.AVAILABLE_FUNDS_UPDATED,
          personId: settlement.person.id,
          newAvailableFunds: data.newAvailableFunds,
          hasFundsAvailable: settlement.hasFundsAvailable
        });
        return data;
      })
      .catch((error) => {
        if (error.status === 412) return dispatch(notificationActions.showError(error.response.body.generalError));
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        logger.error(error);
        return null;
      });
  };
}

export function registerPaymentStart() {
  return { type: types.REGISTER_PAYMENT };
}

export function registerPaymentCancel() {
  return { type: types.REGISTER_PAYMENT_CANCEL };
}

export function registerPaymentAsync(paymentDetails, person, year, month) {
  return (dispatch) => {
    dispatch({ type: types.REGISTER_PAYMENTS_SUBMIT });
    return api
      .registerStaffPayments(person.id, paymentDetails, year, month)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess(`Rozliczano opłaty dla '${person.firstName} ${person.lastName}'`));
        dispatch({ type: types.REGISTER_PAYMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REGISTER_PAYMENTS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(`Nie udało się rozliczyć opłat dla '${person.firstName} ${person.lastName}'`)
        );
        return logger.error(error);
      });
  };
}

export function registerPaymentsStart() {
  return { type: types.REGISTER_PAYMENTS };
}

export function registerPaymentsCancel() {
  return { type: types.REGISTER_PAYMENTS_CANCEL };
}

export function registerPaymentsAsync(paymentDetails, person, year, month) {
  return (dispatch) => {
    dispatch({ type: types.REGISTER_PAYMENTS_SUBMIT });
    return api
      .registerPayments(person.id, paymentDetails, year, month)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess(`Rozliczano opłaty dla '${person.firstName} ${person.lastName}'`));
        dispatch({ type: types.REGISTER_PAYMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REGISTER_PAYMENTS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(`Nie udało się rozliczyć opłat dla '${person.firstName} ${person.lastName}'`)
        );
        return logger.error(error);
      });
  };
}

export function registerStaffPaymentsAsync(paymentDetails, person, year, month) {
  return (dispatch) => {
    dispatch({ type: types.REGISTER_PAYMENTS_SUBMIT });
    return api
      .registerStaffPayments(person.id, paymentDetails, year, month)
      .then((settlements) => {
        dispatch(notificationActions.showSuccess(`Rozliczano opłaty dla '${person.firstName} ${person.lastName}'`));
        dispatch({ type: types.REGISTER_PAYMENTS_SUCCESS, settlements });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REGISTER_PAYMENTS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(`Nie udało się rozliczyć opłat dla '${person.firstName} ${person.lastName}'`)
        );
        return logger.error(error);
      });
  };
}

export function registerDepositAsync(paymentDetails, person) {
  return (dispatch) => {
    dispatch({ type: types.REGISTER_PAYMENTS_SUBMIT });
    return api
      .registerDeposit(person.id, paymentDetails)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Rozliczano wpłatę dla '${person.firstName} ${person.lastName}'`));
        dispatch({ type: types.REGISTER_PAYMENTS_SUCCESS, settlements: [] });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REGISTER_PAYMENTS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(`Nie udało się rozliczyć wpłaty dla '${person.firstName} ${person.lastName}'`)
        );
        return logger.error(error);
      });
  };
}

export function registerStaffDepositAsync(paymentDetails, person) {
  return (dispatch) => {
    dispatch({ type: types.REGISTER_PAYMENTS_SUBMIT });
    return api
      .registerStaffDeposit(person.id, paymentDetails)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Rozliczano wpłatę dla '${person.firstName} ${person.lastName}'`));
        dispatch({ type: types.REGISTER_PAYMENTS_SUCCESS, settlements: [] });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REGISTER_PAYMENTS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(`Nie udało się rozliczyć wpłaty dla '${person.firstName} ${person.lastName}'`)
        );
        return logger.error(error);
      });
  };
}

export function undoPaymentAsync(nextSettlement, paymentId) {
  return (dispatch) => {
    dispatch({ type: types.UNDO_PAYMENT_SUBMIT });
    return api
      .undoPayment(nextSettlement.id, paymentId)
      .then((settlement) => {
        dispatch(
          notificationActions.showSuccess(
            `Wycofano wpłatę z rozliczenia (${nextSettlement.number}) dla '${nextSettlement.person.firstName} ${nextSettlement.person.lastName}'`
          )
        );
        dispatch({ type: types.UNDO_PAYMENT_SUCCESS, settlement });
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.UNDO_PAYMENT_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się wycofać wpłaty z rozliczenia (${nextSettlement.number}) dla '${nextSettlement.person.firstName} ${nextSettlement.person.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function cancelOnlinePaymentAsync(nextSettlement, onlinePaymentId) {
  return (dispatch) => {
    dispatch({ type: types.CANCEL_ONLINE_PAYMENT_SUBMIT });
    return api
      .cancelOnlinePayment(nextSettlement.id, onlinePaymentId)
      .then((settlement) => {
        dispatch(notificationActions.showSuccess('Anulowano płatność elektoniczną'));
        dispatch({ type: types.CANCEL_ONLINE_PAYMENT_SUCCESS, settlement });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CANCEL_ONLINE_PAYMENT_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się anulować płatności elektronicznej,'));
        return logger.error(error);
      });
  };
}

export function loadOverdueAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_OVERDUE_PAYMENTS });
    return api
      .getOverdue()
      .then((overdue) => dispatch({ type: types.LOAD_OVERDUE_PAYMENTS_SUCCESS, overdue }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy zaległych opłat'));
        return logger.error(error);
      });
  };
}

export function loadOverpaymentsAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_OVERPAYMENTS });
    return api
      .getOverpayments()
      .then((overpayments) => dispatch({ type: types.LOAD_OVERPAYMENTS_SUCCESS, overpayments }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy nadpłat'));
        return logger.error(error);
      });
  };
}

export function registerRebateStart() {
  return { type: types.REGISTER_REBATE };
}

export function registerRebateCancel() {
  return { type: types.REGISTER_REBATE_CANCEL };
}

export function registerRebateAsync(rebateDetails, nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  settlement.paymentRebate = {
    description: rebateDetails.description,
    amount: rebateDetails.amount
  };
  return (dispatch) => {
    dispatch({ type: types.REGISTER_REBATE_SUBMIT });
    return api
      .registerRebate(settlement.id, rebateDetails)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zarejestrowano zwrot dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        dispatch({ type: types.REGISTER_REBATE_SUCCESS, settlement });
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.REGISTER_REBATE_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zarejestrować zwrotu dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function sendNotifications(notificationList) {
  return async (dispatch) => {
    try {
      await api.sendNotify(notificationList);
      dispatch(notificationActions.showSuccess(`Powiadomienia zostały wysłane`));
    } catch (error) {
      dispatch(notificationActions.showError(`Powiadomienia nie zostały wysłane`));
      return logger.error(error);
    }
  };
}

export function processRefunds(refundList) {
  return async (dispatch) => {
    try {
      await api.refundOverpayments(refundList);
      dispatch({ type: types.PROCESS_REFUNDS_SUCCESS, refundList });
      dispatch(notificationActions.showSuccess(`Zwroty zostały przetworzone`));
    } catch (error) {
      dispatch(notificationActions.showError(`Zwroty nie zostały przetworzone`));
      return logger.error(error);
    }
  };
}

export function removeRebateAsync(nextSettlement) {
  const settlement = Object.assign({}, nextSettlement);
  settlement.paymentRebate = null;
  return (dispatch) => {
    dispatch({ type: types.REMOVE_REBATE_SUBMIT });
    return api
      .removeRebate(settlement.id)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Usunięto zwrot dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        dispatch({ type: types.REMOVE_REBATE_SUCCESS, settlement });
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.REMOVE_REBATE_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć zwrotu dla '${settlement.person.firstName} ${settlement.person.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function saveSettlementsFilters(filters) {
  return (dispatch) => {
    dispatch({ type: types.SAVE_SETTLEMENTS_FILTERS, filters });
    return Promise.resolve(filters);
  };
}

export function resetSettlementsFilters() {
  return (dispatch) => {
    dispatch({ type: types.RESET_SETTLEMENTS_FILTERS });
    return Promise.resolve();
  };
}

export function validateMt940File(file, bankName, childNumberScheme) {
  return async (dispatch) => {
    try {
      const response = await api.validateMt940File(file, bankName, childNumberScheme);
      dispatch({ type: types.IMPORT_MT940_FILE_SUCCESS, result: response });
      return response;
    } catch (error) {
      dispatch({ type: types.IMPORT_MT940_FILE_FAILURE });
      throw error;
    }
  };
}

export function importMt940File(payload) {
  return async (dispatch) => {
    try {
      const response = await api.importMt940File(payload);
      dispatch({ type: types.IMPORT_MT940_FILE_SUCCESS, result: response });
      return response;
    } catch (error) {
      dispatch({ type: types.IMPORT_MT940_FILE_SUCCESS });
      throw error;
    }
  };
}

export function loadAllSettlements() {
  return async (dispatch) => {
    try {
      const response = await dispatch(api.loadAllSettlements);
      return response;
    } catch (error) {
      dispatch(notificationActions.showError('Nie udało się załadować płatności'));
      throw error;
    }
  };
}

export function calculateDelayInterests(year, month, payload) {
  return async (dispatch) => {
    dispatch({ type: types.CALCULATE_INTERESTS_SUBMIT });
    try {
      const response = await api.calculateDelayInterests(payload);
      dispatch({ type: types.CALCULATE_INTERESTS_SUCCESS });
      await dispatch(loadSettlementsAsync(year, month));
      dispatch(notificationActions.showSuccess('Pomyślnie obliczono odsetki dla podanych płatności '));
      return response;
    } catch (error) {
      dispatch({ type: types.CALCULATE_INTERESTS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się obliczyć odsetek dla podanych płatności'));
      throw error;
    }
  };
}
