import { useState } from 'react';

export const useUpload = () => {
  const [files, setFiles] = useState([]);

  const handleFileAdded = (file) => {
    setFiles((prevState) => [...prevState, file]);
  };

  const handleFileDeleted = (file) => {
    setFiles((prevState) => prevState.filter((f) => f.id !== file.id));
  };

  return [files, handleFileAdded, handleFileDeleted, setFiles];
};
