import PropTypes from 'prop-types';
import React from 'react';
import { tryConvertToInteger } from '../../../../utils/numberConverters';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import TextField from '../../../common/TextField';

const UnitAbsenceRemovalDeadlineForm = ({ legalGuardiansAbsenceRemovalDeadlineHours, onChange, errors }) => {
  return (
    <FieldsetWrapper legend="Zmiana czasu na wycofanie nieobecności" style={{ minWidth: 320 }}>
      <TextField
        floatingLabelText="Czas na wycofanie nieobecności (godziny)*"
        name="legalGuardiansAbsenceRemovalDeadlineHours"
        value={legalGuardiansAbsenceRemovalDeadlineHours}
        errorText={errors.legalGuardiansAbsenceRemovalDeadlineHours}
        fullWidth={true}
        onChange={(e) => {
          onChange({
            target: { name: 'legalGuardiansAbsenceRemovalDeadlineHours', value: tryConvertToInteger(e.target.value) }
          });
        }}
      />
    </FieldsetWrapper>
  );
};

UnitAbsenceRemovalDeadlineForm.propTypes = {
  legalGuardiansAbsenceRemovalDeadlineHours: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default UnitAbsenceRemovalDeadlineForm;
