'use strict';

import PropTypes from 'prop-types';

import React from 'react';
import GuardianPaidDuesList from './GuardianPaidDuesList';

const GuardianDuesToPay = ({ duesToPay, selectedDuesIds, onSelect, onChoose, onlinePaymentEnabled }) => {
  return (
    <GuardianPaidDuesList
      dues={duesToPay}
      selectedDuesIds={selectedDuesIds}
      onSelect={onSelect}
      onChoose={onChoose}
      onlinePaymentEnabled={onlinePaymentEnabled}
      isTableUnpaid
    />
  );
};

GuardianDuesToPay.propTypes = {
  duesToPay: PropTypes.array.isRequired,
  selectedDuesIds: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChoose: PropTypes.func.isRequired,
  onlinePaymentEnabled: PropTypes.bool
};

export default GuardianDuesToPay;
