import React from 'react';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import StudentContainer from './constainers/StudentContainer';

const StudentPage = () => {
  return (
    <LoadingRenderWrapper>
      <StudentContainer />
    </LoadingRenderWrapper>
  );
};

export default StudentPage;
