import PropTypes from 'prop-types';
import React from 'react';
import Divider from '@mui/material/Divider';
import { browserHistory } from 'react-router';
import GroupAvatar from '../common/groups/GroupAvatar';
import { routePaths, fromTemplate } from '../../routePaths';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import EmptyState from '../common/EmptyState';

const GroupsArchiveList = ({ groups }) => {
  const formatGroupType = (group) => {
    return group.isTemporary ? (
      <Typography
        sx={{ color: (theme) => theme.palette.text.primary, fontWeight: (theme) => theme.typography.fontWeightBold }}>
        Tymczasowa
      </Typography>
    ) : (
      <Typography
        sx={{ color: (theme) => theme.palette.text.primary, fontWeight: (theme) => theme.typography.fontWeightBold }}>
        Rok {group.yearbook}/{group.yearbook + 1}
      </Typography>
    );
  };

  const secondaryTextRender = (group) => {
    return (
      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {formatGroupType(group)}
        <ArchivalInfo archivalInfo={group.archivalInfo} />
      </Box>
    );
  };

  if (groups.length > 0)
    return (
      <List sx={{ py: 0, overflowX: 'scroll' }}>
        {groups.map((group) => {
          return (
            <ListItem
              key={group.id}
              onClick={() => {
                browserHistory.push(fromTemplate(routePaths.groupArchiveDetails, [group.id]));
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}>
              <ListItemAvatar>
                <GroupAvatar group={group} />
              </ListItemAvatar>
              <ListItemText disableTypography primary={group.name} secondary={secondaryTextRender(group)} />
              <Divider />
            </ListItem>
          );
        })}
      </List>
    );
  return <EmptyState message="Brak wyników wyszukiwania" contrast />;
};

GroupsArchiveList.propTypes = {
  groups: PropTypes.array.isRequired
};

export default GroupsArchiveList;
