import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../../constants/chargingReportTypes';
import { FormControlLabel, Radio, RadioGroup, ListSubheader } from '@mui/material';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const ReportTypesOptions = ({ onSelected, defaultSelected, isDisabled, schemeType }) => {
  return (
    <FieldsetWrapper
      legend={
        <ListSubheader component="span" sx={{ pl: 0, fontSize: 12, color: (theme) => theme.palette.color.contrast }}>
          Za jaką usługę raportowane są opłaty:
        </ListSubheader>
      }
      invisible={false}
      sx={{ width: '100%' }}>
      <RadioGroup
        row
        aria-label="Sposób raportowania opłat"
        name="reportType"
        value={defaultSelected || Object.values(ChargingReportTypes)[0].value}
        onClick={(e) => {
          return isDisabled ? null : onSelected(e.target.value);
        }}>
        {Object.values(ChargingReportTypes).map((item) => {
          return (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio color="primary" />}
              label={item.name}
              labelPlacement="end"
              disabled={isDisabled || (item.value === 'meals' && schemeType === 'monthly')}
            />
          );
        })}
      </RadioGroup>
    </FieldsetWrapper>
  );
};

ReportTypesOptions.propTypes = {
  onSelected: PropTypes.func.isRequired,
  defaultSelected: PropTypes.string,
  isDisabled: PropTypes.bool,
  schemeType: PropTypes.string
};

export default ReportTypesOptions;
