import ApiBase, { ROOT_URL } from './ApiBase';
import moment from 'moment';
import prepareQueryParams from '../utils/prepareQueryParams';

class ChildrenApi extends ApiBase {
  static getChildren() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children`).type('application/json'));
  }

  static getChildrenArchive() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/archive`).type('application/json'));
  }

  static getLegalGuardiansChildren() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/myChildren`).type('application/json'));
  }

  static createChild(child) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children`).send(child).type('application/json'),
      super.filterLocationHeader
    );
  }

  static createStudent(student) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children/createLinkedChildAndGuardian`).send(student).type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateChild(childId, child) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/children/${childId}`).send(child).type('application/json')
    );
  }

  static updateStudent(studentId, student) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/children/updateLinkedChildAndGuardian/${studentId}`).send(student).type('application/json')
    );
  }

  static getChild(childId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/${childId}`).type('application/json'));
  }

  static getChildPersonalBalance(childId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/${childId}/balance`).type('application/json'));
  }

  static cashOutMoneyFromPersonalBalance(childId, amount, method, walletType) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/children/${childId}/cashBalance`)
        .send({ amount, method, walletType })
        .type('application/json')
    );
  }

  static getLegalGuardiansChild(childId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/myChildren/${childId}`).type('application/json'));
  }

  static getChildArchive(childId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/archive/${childId}`).type('application/json'));
  }

  static archiveChild(childId, deleteLegalGuardians) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/children/${childId}/archive`).send({ deleteLegalGuardians }).type('application/json')
    );
  }

  //legalGuardians

  static getChildLegalGuardians(childId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/children/${childId}/legalGuardians`).type('application/json')
    );
  }

  static addChildLegalGuardian(childId, legalGuardian) {
    return super.toPromise(
      (agent) =>
        agent.post(`${ROOT_URL}/children/${childId}/legalGuardians`).send(legalGuardian).type('application/json'),
      (r) => {
        const id = super.filterLocationHeader(r);
        const { pin } = r.body;
        return { id, pin };
      }
    );
  }

  static updateChildLegalGuardian(childId, guardianId, legalGuardian) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/legalGuardians/${guardianId}`)
        .send(legalGuardian)
        .type('application/json')
    );
  }

  static assignLegalGuardianToChild(childId, guardianId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/children/${childId}/legalGuardians/${guardianId}/assign`).type('application/json')
    );
  }

  static deleteChildLegalGuardian(childId, guardianId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/children/${childId}/legalGuardians/${guardianId}`).type('application/json')
    );
  }

  //guardians

  static getChildGuardians(childId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/${childId}/guardians`).type('application/json'));
  }

  static addChildGuardian(childId, guardian) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children/${childId}/guardians`).send(guardian).type('application/json'),
      (r) => {
        const id = super.filterLocationHeader(r);
        const { pin } = r.body;
        return { id, pin };
      }
    );
  }

  static updateChildGuardian(childId, guardianId, guardian) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/children/${childId}/guardians/${guardianId}`).send(guardian).type('application/json')
    );
  }

  static deleteChildGuardian(childId, guardianId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/children/${childId}/guardians/${guardianId}`).type('application/json')
    );
  }

  static updateChildGuardianAuthorization(childId, guardianId, isAuthorized) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/guardians/${guardianId}/authorization`)
        .send({ isAuthorized })
        .type('application/json')
    );
  }

  static extendChildGuardianAuthorizationExpiry(childId, guardianId, authorizationExpirationDate) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/guardians/${guardianId}/authorizationExpiryDate`)
        .send({ authorizationExpirationDate })
        .type('application/json')
    );
  }

  static updateProfilePhoto(childId, file) {
    const data = new FormData();
    data.append('file', file, file.name);

    return super.toPromise((agent) => agent.patch(`${ROOT_URL}/children/${childId}/photo`).send(data));
  }

  static removeProfilePhoto(childId) {
    return super.toPromise((agent) => agent.delete(`${ROOT_URL}/children/${childId}/photo`).type('application/json'));
  }

  static updateChargingScheme(childId, scheme) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/contract/schemes/${scheme.schemeId}`)
        .send({
          reliefId: scheme.reliefId,
          reliefExpiryDate: scheme.reliefExpiryDate ? moment(scheme.reliefExpiryDate).format('YYYY-MM-DD') : null,
          singleUse: scheme.singleUse,
          grantHoursAmount: scheme.grantHoursAmount,
          grantHourlyRate: scheme.grantHourlyRate,
          calculationStartDate: scheme.calculationStartDate
            ? moment(scheme.calculationStartDate).format('YYYY-MM-DD')
            : null
        })
        .type('application/json')
    );
  }

  static removeChargingScheme(childId, schemeId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/children/${childId}/contract/schemes/${schemeId}`).type('application/json')
    );
  }

  ///attachments

  static addFileAttachment(childId, fileAttachment) {
    const data = new FormData();
    data.append('file', fileAttachment.file, fileAttachment.file.name);
    data.append('name', fileAttachment.name);

    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children/${childId}/files`).send(data),
      super.filterLocationHeader
    );
  }

  static addSurvey(childId, fileAttachment) {
    const data = new FormData();
    data.append('file', fileAttachment.file, fileAttachment.file.name);
    data.append('name', fileAttachment.name);
    data.append('fileType', fileAttachment.fileInfo.fileType);
    data.append('title', fileAttachment.fileInfo.title);

    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children/${childId}/files`).send(data),
      super.filterLocationHeader
    );
  }

  static addDiagnose(childId, diagnose) {
    const newDiagnose = {
      title: diagnose.title,
      content: diagnose.content,
      date: diagnose.date,
      createdBy: diagnose.createdBy
    };
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/children/${childId}/diagnoses`).send(newDiagnose).type('application/json')
    );
  }

  static deleteDiagnose(childId, diagnose) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/children/${childId}/diagnoses/${diagnose.diagnoseId}`).type('application/json')
    );
  }

  static addOpinion(childId, fileAttachment) {
    const data = new FormData();
    data.append('file', fileAttachment.file, fileAttachment.file.name);
    data.append('name', fileAttachment.name);
    data.append('fileType', fileAttachment.fileInfo.fileType);
    data.append('title', fileAttachment.fileInfo.title);
    data.append('date', moment(fileAttachment.fileInfo.date).format('YYYY-MM-DD'));
    data.append('releasedBy', fileAttachment.fileInfo.releasedBy);
    data.append('opinionType', fileAttachment.opinionType);
    data.append('opinionTypeOtherValue', fileAttachment.opinionTypeOtherValue);
    data.append('reason', fileAttachment.reason);
    data.append('opinionNumber', fileAttachment.opinionNumber);
    data.append('needHelp', fileAttachment.needHelp);
    data.append('helpDateStart', moment(fileAttachment.helpDateStart).format('YYYY-MM-DD'));
    data.append('helpDateEnd', moment(fileAttachment.helpDateEnd).format('YYYY-MM-DD'));

    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children/${childId}/files`).send(data),
      super.filterLocationHeader
    );
  }

  static addVacationPeriod(childId, vacationPeriodStart, vacationPeriodEnd) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/children/${childId}/addVacationPeriod`)
        .send({ vacationPeriodStart, vacationPeriodEnd })
        .type('application/json')
    );
  }

  static deleteVacationPeriod(childId, vacationPeriodId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/children/${childId}/removeVacationPeriod`)
        .send({ vacationPeriodId })
        .type('application/json')
    );
  }

  static deleteFileAttachment(childId, fileAttachmentId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/children/${childId}/files/${fileAttachmentId}`).type('application/json')
    );
  }

  static importData(fileAttachment, childId) {
    const data = new FormData();
    data.append('file', fileAttachment.file, fileAttachment.file.name);
    data.append('name', fileAttachment.name);
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/children/${childId}/files`).send(data),
      super.filterLocationHeader
    );
  }

  static importChildren(file) {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('name', file.name);
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/children/vulcan_import`).send(data));
  }

  static importStudent(file) {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('name', file.name);
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/children/nadarzynImport`).send(data));
  }

  static exportChildrenList(query) {
    return ApiBase.toPromise(
      (agent) => {
        return agent.get(`${ROOT_URL}/children/vulcan_export/${query}`).type('application/json').responseType('blob');
      },
      (res) => res.xhr
    );
  }

  static exportStudents(groups) {
    return ApiBase.toPromise(
      (agent) => {
        return agent
          .get(`${ROOT_URL}/children/nadarzynExport/${groups.join(';')}`)
          .type('application/json')
          .responseType('blob');
      },
      (res) => res.xhr
    );
  }

  static updateOpeningBalance(childId, openingBalance) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/contract/openingBalance`)
        .send({
          totalDays: openingBalance.totalDays || null,
          totalTime: openingBalance.totalTime,
          totalChargeableTime: openingBalance.totalChargeableTime,
          stayDueCorrection: openingBalance.stayDueCorrection || null,
          cateringDueCorrection: openingBalance.cateringDueCorrection || null,
          otherDueCorrection: openingBalance.otherDueCorrection || null
        })
        .type('application/json')
    );
  }

  //attributes
  static updateAttributes(childId, attributeSet) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/attributes/${attributeSet.attributeSetId}`)
        .send(attributeSet.attributeValues)
        .type('application/json')
    );
  }

  //attributes
  static fetchSignUrl(childId, guardianId, data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/children/${childId}/guardian/${guardianId}/getUrl`).send(data).type('application/json')
    );
  }

  static completeAuthorizationBySign(childId, guardianId, fileLink) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/children/${childId}/guardian/${guardianId}/authorizationBySign`)
        .send({ fileLink })
        .type('application/json')
    );
  }

  static getChildDetailsSummary(childId, params) {
    const readyParamas = prepareQueryParams(params);
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/children/${childId}/detailedPaymentsSummary`).query(readyParamas).type('application/json')
    );
  }

  static validateCSVFile(file) {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('name', file.name);
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/children/validate_csv_file`).send(data));
  }

  static importCSVFile(file) {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('name', file.name);
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/children/children_import_csv`).send(data));
  }

  static walletTransfer(transferDetails, childId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/children/${childId}/transferToAnotherWallet`)
        .send(transferDetails)
        .type('application/json')
    );
  }

  static getJournalExport(month, groupId) {
    return super.toPromise(
      (agent) => agent.get(`${ROOT_URL}/children/kindergartenJournalExport/${month}/${groupId}`).responseType('blob'),
      (res) => ({ response: res.xhr.response, headers: res.headers })
    );
  }

  static exportChildrenSIO() {
    return super.toPromise(
      (agent) => agent.get(`${ROOT_URL}/children/export_sio`).responseType('blob'),
      (res) => res.xhr
    );
  }

  static loadChildrenTakenOverByDebtCollection() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/children/getChildrenWithDebtCollection`).type('application/json')
    );
  }

  static addTakenOverByDebtCollectionChildren(childrenIds) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/children/addTakenOverByDebtCollectionChildren`).send(childrenIds).type('application/json')
    );
  }

  static removeTakenOverByDebtCollectionChildren(childrenIds) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/children/removeTakenOverByDebtCollectionChildren`)
        .send({ childrenIds })
        .type('application/json')
    );
  }

  static getChildrenOnVacationPeriod() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/children/onVacationPeriod`).type('application/json'));
  }
}

export default ChildrenApi;
