import PropTypes from 'prop-types';
import React from 'react';
import AttendanceListItem from './PreviousAttendanceListItem';
import PreviousAttendanceEditDialogs from './PreviousAttendanceEditDialogs';
import { Box, List, Paper, Typography } from '@mui/material';
import { connect } from 'react-redux';

class PreviousAttendanceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEntry: null
    };
    this.renderListItem = this.renderListItem.bind(this);
  }

  renderListItem(entry) {
    return (
      <AttendanceListItem
        key={entry.childId}
        journalEntry={entry}
        journalDate={this.props.journalDate}
        onReportAttendance={() => {
          this.setState({ currentEntry: entry }, this.props.onReportAttendanceStart);
        }}
        onReportAbsence={() => {
          this.setState({ currentEntry: entry }, this.props.onRevertAttendanceStart);
        }}
      />
    );
  }

  render() {
    if (!this.props.journalEntries.length || !this.props.choosenGroup.id) {
      return null;
    }
    return (
      <Box>
        <Typography variant="h6">{this.props.title}</Typography>
        <Paper sx={{ px: 2, py: 1 }}>
          <List>{this.props.journalEntries.map((entry) => this.renderListItem(entry))}</List>
        </Paper>

        <PreviousAttendanceEditDialogs
          reportAttendanceUi={this.props.ui.reportAttendance}
          onReportAttendanceCancel={this.props.onReportAttendanceCancel}
          onReportAttendance={this.props.onReportAttendance}
          revertAttendanceUi={this.props.ui.revertAttendance}
          onRevertAttendanceCancel={this.props.onRevertAttendanceCancel}
          onRevertAttendance={this.props.onRevertAttendance}
          childId={this.state.currentEntry ? this.state.currentEntry.childId : ''}
          absenceFrom={this.state.currentEntry ? this.state.currentEntry.absenceFrom : null}
          absenceTo={this.state.currentEntry ? this.state.currentEntry.absenceTo : null}
          isAbsenceFromBlocked={true}
          isAbsenceToBlocked={true}
          droppedOffAt={this.state.currentEntry ? this.state.currentEntry.droppedOffAt : null}
          pickedUpAt={this.state.currentEntry ? this.state.currentEntry.pickedUpAt : null}
          pickedUpBy={this.state.currentEntry ? this.state.currentEntry.pickedUpBy : null}
          childFirstName={this.state.currentEntry ? this.state.currentEntry.firstName : ''}
          childLastName={this.state.currentEntry ? this.state.currentEntry.lastName : ''}
          childPhotoUrl={this.state.currentEntry ? this.state.currentEntry.photoUrl : ''}
          childAllGuardians={this.state.currentEntry ? this.state.currentEntry.allGuardians : []}
          childContract={this.state.currentEntry ? this.state.currentEntry.contract : null}
          journalDate={this.props.journalDate}
        />
      </Box>
    );
  }
}

PreviousAttendanceList.propTypes = {
  title: PropTypes.string.isRequired,
  journalDate: PropTypes.object.isRequired,
  journalEntries: PropTypes.array.isRequired,

  onReportAttendanceStart: PropTypes.func.isRequired,
  onReportAttendanceCancel: PropTypes.func.isRequired,
  onReportAttendance: PropTypes.func.isRequired,

  onRevertAttendanceStart: PropTypes.func.isRequired,
  onRevertAttendanceCancel: PropTypes.func.isRequired,
  onRevertAttendance: PropTypes.func.isRequired,

  ui: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    choosenGroup: state.digitalDiary.choosenGroup
  };
}

export default connect(mapStateToProps)(PreviousAttendanceList);
