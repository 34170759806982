import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChildAttendanceCalendar from '../../common/children/attendance/calendar/ChildAttendanceCalendar';
import { useDispatch, useSelector } from 'react-redux';
import PsychologicalAttendanceDialog from '../../attendanceSheet/psychological/psychologicalAttendanceDialog';
import PsychologicalAbsenceDialog from '../../attendanceSheet/psychological/psychologicalAbsenceDialog';
import {
  loadChildIndividualClassesAttendanceAsync,
  reportAttendanceStartAsync,
  revertAbsence,
  revertIndividualAbsence
} from '../../../actions/psychologicalAttendanceActions';
import moment from 'moment';
import AttendanceDetailsDialog from '../attendance/dialogs/AttendanceDetailsDialog';

const ChildIndividualPsychologicalAttendance = ({ childDetails, isInitiallyExpanded }) => {
  const dispatch = useDispatch();
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const attendances = useSelector((state) => state.childPsychologicalIndividualAttendances);
  const Ui = useSelector((state) => state.childDetailsUi.individualAttendanceUi);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [mode, setMode] = useState(null);
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [selectedDetailsDay, setSelectedDetailsDay] = useState();

  useEffect(() => {
    dispatch(loadChildIndividualClassesAttendanceAsync(childDetails.id, moment().format('YYYY-MM')));
  }, [childDetails.id, dispatch]);

  const onDateChange = (e) => {
    dispatch(loadChildIndividualClassesAttendanceAsync(childDetails.id, e.format('YYYY-MM')));
  };

  const renderDialog = () => {
    switch (mode) {
      case 'attendance':
        return (
          <PsychologicalAttendanceDialog
            childDetails={childDetails}
            Ui={Ui}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
          />
        );
      case 'absence':
        return (
          <PsychologicalAbsenceDialog
            childDetails={childDetails}
            Ui={Ui}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
          />
        );
      case 'revertAbsence':
        return (
          <PsychologicalAbsenceDialog
            childDetails={childDetails}
            Ui={Ui}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
            absenceFrom={selectedDate}
            absenceTo={selectedDate}
            absenceNotes=""
            isAbsenceFromBlocked={!!(currentDay && currentDay.data.droppedOffAt)}
            isAbsenceToBlocked={!!(currentDay && currentDay.data.droppedOffAt)}
          />
        );
      case 'revertAttendance':
        return (
          <PsychologicalAbsenceDialog
            childDetails={childDetails}
            Ui={Ui}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
            absenceFrom={selectedDate}
            absenceTo={selectedDate}
            absenceNotes=""
            isAbsenceFromBlocked={!!(currentDay && currentDay.data.droppedOffAt)}
            isAbsenceToBlocked={!!(currentDay && currentDay.data.droppedOffAt)}
          />
        );
      default:
        break;
    }
  };

  const handleDetailsOpen = (e) => {
    console.log(e);
    setSelectedDetailsDay(e);
    setDetailsOpen(true);
  };

  return (
    <>
      <Accordion sx={{ p: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Obecności na zajęciach indywidualnych
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChildAttendanceCalendar
            noDescription
            allDays={attendances}
            markedDays={!!attendances.entries ? attendances.entries : []}
            attendanceSummary={!!attendances.summary ? attendances.summary : {}}
            daysOff={daysOff}
            onDateChange={(e) => onDateChange(e)}
            onDaySelected={() => {}}
            onReportAttendance={(day) => {
              setSelectedDate(day?.date);
              setCurrentDay(day?.date);

              setMode('attendance');
              dispatch(reportAttendanceStartAsync());
            }}
            onReportAbsence={(day) => {
              setSelectedDate(day?.date);
              setCurrentDay(day?.date);
              setMode('absence');
              dispatch(reportAttendanceStartAsync());
            }}
            onRevertAbsence={(day) => {
              console.log(day);
              dispatch(revertIndividualAbsence(childDetails.id, day, day, childDetails.assistanceId));
            }}
            onDetailsOpen={(day) => handleDetailsOpen(day)}
            isLoading={Ui.isCalendarLoading}
          />

          {Ui.isEditing && renderDialog()}
        </AccordionDetails>
      </Accordion>
      <AttendanceDetailsDialog
        isPsychological
        isDialogOpen={isDetailsOpen}
        childDetails={childDetails}
        childAttendance={attendances?.entries?.find((day) => day?.date === selectedDetailsDay?.data?.date)}
        onRequestClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default ChildIndividualPsychologicalAttendance;
