import React from 'react';
import { TableContainer, TableHead, TableRow, TableBody, Table } from '@mui/material';
import PropTypes from 'prop-types';
import AuthorizedToPickUpChildTableRow from './AuthorizedToPickUpChildTableRow';
import SortedTableCell from '../../table/SortedTableCell';
import * as definedSortTypes from '../../../constants/definedSortTypes';

const AuthorizedToPickUpChildTable = (props) => {
  const { onSort, filteredRows, types, order, orderBy } = props;

  const cells = [
    {
      id: 'childLastName',
      label: 'Dziecko',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'guardianLastName',
      label: 'Osoba odbierająca',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'guardianAuthorizationExpirationDate',
      label: 'Data wygaśnięcia',
      type: definedSortTypes.DATE_SORT
    }
  ];

  return (
    <TableContainer>
      <Table
        sx={{
          width: '95%',
          my: 0,
          mx: 'auto'
        }}>
        <TableHead>
          <TableRow>
            {cells.map((cell) => (
              <SortedTableCell
                key={cell.id}
                order={order}
                orderBy={orderBy.property}
                onRequestSort={onSort}
                cell={cell}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.length > 0
            ? filteredRows.map((tableRow) => (
                <AuthorizedToPickUpChildTableRow
                  key={tableRow.guardianId + tableRow.childId}
                  tableRow={tableRow}
                  types={types}
                />
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AuthorizedToPickUpChildTable.propTypes = {
  types: PropTypes.object,
  filteredRows: PropTypes.array,
  onSort: PropTypes.func,
  tableRows: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.object
};

export default AuthorizedToPickUpChildTable;
