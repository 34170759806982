import { BaseModelValidator } from '../../models/BaseModelValidator';
import { MealModel } from '../../models/meals/MealModels';
import { check } from '../../models/validation/validateHelper';

export class MealDetailsModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = MealModel;

    this.constraints = {
      name: (value) => {
        if (check.isEmpty(value)) {
          return 'Posiłek musi mieć nazwę.';
        } else if (value.length > 75) {
          return 'Nazwa posiłku nie może przekraczać 75 znaków.';
        }
      }
    };
  }
}
