import PropTypes from 'prop-types';
import React from 'react';
import { ChargingCalculationMethod } from '../../../../constants/chargingCalculationMethods';
import { ChargingSchemeTypes } from '../../../../constants/chargingSchemeTypes';
import { getConstantByValue } from '../../../../utils/getConstantByValue';
import { DisplayTimeDescription } from './SchemeDescription';
import { Box, Typography } from '@mui/material';

const DailySchemeDescription = ({ scheme }) => {
  return (
    <Box
      sx={{
        color: (theme) => theme.palette.color.contrast
      }}>
      <Typography sx={{ fontSize: 14 }}>
        Stawka:
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
          {scheme.rate} {ChargingSchemeTypes[scheme.schemeType].unit}
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 14 }}>
        Opłata naliczana:
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
          {getConstantByValue(ChargingCalculationMethod, scheme.calculationMethod).name}
        </Typography>
      </Typography>
      <DisplayTimeDescription scheme={scheme} />
    </Box>
  );
};

DailySchemeDescription.propTypes = {
  scheme: PropTypes.object.isRequired
};

export default DailySchemeDescription;
