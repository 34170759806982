import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Divider,
  ListItem,
  List,
  Alert,
  Fade
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import FileSpecificIcon from '../../common/FileSpecificIcon/FileSpecificIcon';
import { shareFiles } from '../../../actions/virtualDiscActions';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';

const FilePermissionDialog = ({ onClose, open, participants, file, usersWithPermission, onSend, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const withoutPermission = useMemo(() => {
    const usersWithPermissionIds = usersWithPermission.map((u) => u.id);
    return participants.filter((p) => !usersWithPermissionIds.includes(p.id));
  }, [participants, usersWithPermission, file]);

  const handleChangePermissions = async () => {
    setIsLoading(true);
    const preparedData = {
      filesIds: [file.id],
      shareForUserRoles: file.shareForUserRoles,
      shareForUserIds: [...file.shareForUserIds, ...withoutPermission.map((u) => u.id)]
    };

    await dispatch(shareFiles(preparedData));
    setIsLoading(false);
    handleSend();
  };
  const handleSend = () => {
    onSend();
    onClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Czy udostępnić plik w konwersacji?</DialogTitle>
      <Divider />
      <DialogContent>
        {!!file && !file.isOwnByUser && user.userRole !== 'staffMemberPrincipal' && (
          <Fade in={true} timeout={1000}>
            <Alert sx={{ width: '100%', mb: 1 }} severity="error">
              {' '}
              Nie posiadasz uprawnień do zarządzania udostępnianiem tego pliku.
            </Alert>
          </Fade>
        )}
        {!withoutPermission.length ? (
          <Typography sx={{ mb: 2 }}>Wszyscy użytkownicy mają uprawnienia do tego pliku.</Typography>
        ) : (
          <Box sx={{ mb: 2 }}>
            <Typography>Nie wszyscy członkowie konwersacji mają uprawnienia do tego pliku:</Typography>
            <List sx={{ maxHeight: 200, overflowY: 'scroll' }}>
              {withoutPermission.map((user) => (
                <ListItem key={user.id}>
                  {user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <Chip
          sx={{ height: 'auto', py: 2 }}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', textOverflow: 'ellipsis', width: '100%' }}>
              <FileSpecificIcon type={file.type === 'folder' ? 'folder' : file.mimeType} sx={{ fontSize: 32 }} />

              <Typography>{!!file && file.name}</Typography>
            </Box>
          }
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}>
        {!!withoutPermission.length ? (
          <>
            <Button variant="contained" onClick={handleSend}>
              Wyślij bez zmiany uprawnień
            </Button>
            <Box>
              <LoadingButton
                variant="contained"
                aria-label="Wyślij i udostępnij wszystkim"
                key="2"
                onClick={handleChangePermissions}
                loading={isLoading}
                disabled={!!file && !file.isOwnByUser && user.userRole !== 'staffMemberPrincipal'}>
                Wyślij i udostępnij wszystkim
              </LoadingButton>
            </Box>
          </>
        ) : (
          <Button variant="contained" onClick={handleSend}>
            Wyślij
          </Button>
        )}
        <Button onClick={onClose} variant="outlinedContrast">
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FilePermissionDialog;
