import React from 'react';
import * as Yup from 'yup';
import BaseModel from '../../models/BaseModel';
import { FormikTextField } from '../../components/formik/FormikTextField';
import FieldsetWrapper from '../../components/forms/FieldsetWrapper';
import { EMAIL_REGEXP } from '../../utils/validationRegExps';

const RecoveryPasswordForm = () => {
  return (
    <FieldsetWrapper legend="Pola email">
      <FormikTextField label="Adres email*" name="email" fullWidth />
    </FieldsetWrapper>
  );
};

RecoveryPasswordForm.propTypes = {};

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.email = '';
  }
}

export const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().matches(EMAIL_REGEXP, 'Niepoprawna składnia emailu').required('Email jest wymagany')
});

export const validate = () => {
  const errors = {};
  return errors;
};

export default RecoveryPasswordForm;
