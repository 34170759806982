import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  Typography,
  Grid,
  Pagination,
  useMediaQuery,
  MenuItem
} from '@mui/material';
import moment from 'moment';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import DatePicker from '../../common/DatePicker';
import eventTranslations from '../../../constants/eventTranslations';
import SelectField from '../../common/SelectField';

const LegalGuardianHistoryDialog = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const countOfPagination = Math.ceil(props.profileHistory.rowNumber / rowsPerPage);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const loadEvents = (newRow, newPage) => {
    const params = {
      fetchCount: newRow,
      page: newPage
    };
    if (!!dateFrom) params.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    if (!!dateTo) params.dateTo = moment(dateTo).format('YYYY-MM-DD');
    props.onFilter(props.userId, params);
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    loadEvents(rowsPerPage, newValue - 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    loadEvents(e.target.value, 0);
  };

  const handleFilter = () => {
    setPage(1);
    loadEvents(rowsPerPage, 0);
  };

  return (
    <Dialog
      key="legalGuardianHistory-dialog-dialog"
      id="legalGuardianHistory-dialog"
      onClose={() => props.onClose()}
      maxWidth="md"
      fullWidth={true}
      aria-labelledby="legalGuardianHistory-title"
      open={props.dialogState}>
      <DialogTitle id="legalGuardianHistory-title">Historia zmian profilu</DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            mb: 2
          }}>
          <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            <ClearButtonWrapper contrast onClear={() => setDateFrom(null)}>
              <DatePicker
                onChange={(val) => setDateFrom(val)}
                value={dateFrom}
                floatingLabelText="Zmiany od"
                name="timeFrom"
                maxDate={dateTo || new Date()}
              />
            </ClearButtonWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
            <ClearButtonWrapper contrast onClear={() => setDateTo(null)}>
              <DatePicker
                onChange={(val) => setDateTo(val)}
                value={dateTo}
                floatingLabelText="Zmiany do"
                name="timeTo"
                minDate={dateFrom || null}
                maxDate={new Date()}
              />
            </ClearButtonWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end', md: 'flex-end' } }}>
            <SelectField
              contrast
              sx={{
                width: 88
              }}
              id="rowsPerPage"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              floatingLabelText="Wierszy na stronę">
              {numberArrayOfOptions.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectField>
            <Button variant="contained" aria-label="Filtruj" sx={{ ml: 2 }} onClick={handleFilter}>
              Filtruj
            </Button>
          </Grid>
        </Grid>
        {props.loading ? (
          <Box sx={{ m: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <TableContainer>
              <Table aria-label="Historia zmian profilu">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Zmiana</TableCell>
                    <TableCell align="center">Wykonawca</TableCell>
                    <TableCell align="right">Data zmiany</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.profileHistory.profileHistoryList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">{eventTranslations[row.event]}</TableCell>
                      <TableCell align="center">
                        {!row.userData.login ? <Typography component="span">Brak</Typography> : null}
                        {row.userData.fullName && (
                          <Typography component="span">
                            {row.userData.fullName}
                            <br />
                          </Typography>
                        )}
                        {row.userData.login && (
                          <Typography component="span">
                            {row.userData.login} <br />
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">{moment(row.created_at).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))}
                  {props.profileHistory.profileHistoryList.length === 0 && (
                    <TableRow>
                      <TableCell>Brak zmian w profilu</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[1, 2, 5, 10, 25]}
              labelRowsPerPage="Wierszy na stronę:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} z ${count !== -1 ? count : `więcej niż ${to}`}`
              }
              component="div"
              count={props.profileHistory.rowNumber}
              rowsPerPage={rows}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleRowsChange}
            />
             */}
            {countOfPagination > 1 && (
              <Pagination
                siblingCount={isMobile ? 0 : 1}
                count={countOfPagination}
                page={page}
                onChange={handleChangePage}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 4
                }}
              />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose} aria-label="Zamknij">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LegalGuardianHistoryDialog.propTypes = {
  dialogState: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  profileHistory: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired
};

export default LegalGuardianHistoryDialog;
