/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { AttributeSetModel } from '../models/configuration/attributes/AttributeSetModel';
import _ from 'lodash';

export default function attributesSetsReducer(state = initialState.configuration.attributesSets, action) {
  switch (action.type) {
    case types.LOAD_ATTRIBUTES_SETS_SUCCESS: {
      return _.sortBy(
        action.sets.map((set) => new AttributeSetModel().assign(set)),
        (as) => as.name
      );
    }
    case types.CREATE_ATTRIBUTES_SET_SUCCESS: {
      const set = new AttributeSetModel().assign(action.set);
      return [...state, set];
    }
    case types.UPDATE_ATTRIBUTES_SET_SUCCESS: {
      const set = new AttributeSetModel().assign(action.set);
      return [...state.map((g) => (g.id === set.id ? set : g))];
    }
    case types.REMOVE_ATTRIBUTES_SET_SUCCESS: {
      return [...state.filter((g) => g.id !== action.set.id)];
    }
    default:
      return state;
  }
}
