import { useRef } from 'react';
import jsPDF from 'jspdf';
import PosterTemplate from './PosterTemplate';
import { Box } from '@mui/system';
import { Button } from '@mui/material';

function DownloadPoster({ qr, format }) {
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: format,
      unit: 'px'
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        doc.deletePage(2);
        await doc.save('document');
      }
    });
  };

  return (
    <Box>
      <Button variant="contained" fullWidth={false} sx={{ mt: 2, textTransform: 'none' }} onClick={handleGeneratePdf}>
        {`pobierz kod QR w formacie ${format.toUpperCase()} (.pdf)`}
      </Button>
      <Box
        sx={{
          display: 'none'
        }}>
        <Box sx={{ width: format === 'a4' ? '450px' : '633px' }} ref={reportTemplateRef}>
          <PosterTemplate qr={qr} />
        </Box>
      </Box>
    </Box>
  );
}

export default DownloadPoster;
