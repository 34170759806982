import '../../../node_modules/react-virtualized/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import paymentsApi from '../../api/PaymentsApi';
import { ChargingReportTypes } from '../../constants/chargingReportTypes';
import numberFormatter from '../../utils/numberFormatter';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import { CalculationStatus } from '../../constants/calculationStatus';
import { PaymentStatus } from '../../constants/paymentStatus';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, Button, Checkbox as Checkbox2, Table as Table2 } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import makeStyles from '@mui/styles/makeStyles';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Strong from '../common/Strong';

const useCellHeaderStyles = makeStyles({
  root: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: 15
  }
});

const useCellBodyStyles = makeStyles({
  root: {
    fontSize: 16
  }
});

const isPaymentLinkAvailable = (due) =>
  due != null &&
  due.latestPayment != null &&
  (due.latestPayment.status === PaymentStatus.defined.value ||
    due.latestPayment.status === PaymentStatus.pending.value) &&
  due.latestPayment.onlinePaymentLink != null;

const GuardianPaidDuesList = ({
  dues,
  onChoose,
  onPrint,
  isTableUnpaid,
  onlinePaymentEnabled,
  selectedDuesIds,
  onSelect
}) => {
  const showPaymentLinkColumn = Array.isArray(dues) && dues.some((due) => isPaymentLinkAvailable(due));
  const showDownloadPdfColumn =
    Array.isArray(dues) && dues.some((due) => due.calculationStatus === CalculationStatus.paid.value);
  const cellBodyStyles = useCellBodyStyles();
  const cellHeaderStyles = useCellHeaderStyles();

  const itemSelected = (rowIndex) => selectedDuesIds.some((id) => id === dues[rowIndex].id);

  return (
    <Box sx={{ overflowX: 'auto' }}>
      <TableContainer sx={{ mt: 1, boxShadow: 'none' }} component={Paper}>
        <Table2 size="small" aria-label="Tabela opłat dzieci">
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: cellHeaderStyles.root }} align="left">
                Dziecko
              </TableCell>
              {showPaymentLinkColumn && (
                <TableCell classes={{ root: cellHeaderStyles.root }} align="center">
                  Płatność
                </TableCell>
              )}
              <TableCell classes={{ root: cellHeaderStyles.root }} align="left">
                Opłata
              </TableCell>
              <TableCell classes={{ root: cellHeaderStyles.root }} align="left">
                Data
              </TableCell>
              {isTableUnpaid && (
                <TableCell classes={{ root: cellHeaderStyles.root }} align="left">
                  Termin płatności
                </TableCell>
              )}
              <TableCell classes={{ root: cellHeaderStyles.root }} align="left">
                Kwota
              </TableCell>
              {showDownloadPdfColumn && (
                <TableCell classes={{ root: cellHeaderStyles.root }} align="center">
                  Pobierz
                </TableCell>
              )}
              {isTableUnpaid && (
                <TableCell classes={{ root: cellHeaderStyles.root }} align="right">
                  Do zapłaty
                </TableCell>
              )}
              {isTableUnpaid && onlinePaymentEnabled && (
                <TableCell classes={{ root: cellHeaderStyles.root }} align="center">
                  Opłać
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {dues.map((row, rowIndex) => {
              return (
                <TableRow
                  sx={{
                    '&:hover': {
                      background: (theme) => theme.palette.background.hover
                    }
                  }}
                  key={`${row.id}-${rowIndex}`}
                  onClick={() => onChoose(dues[rowIndex])}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) onChoose(dues[rowIndex]);
                  }}
                  tabIndex={0}>
                  <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                    {`${row.person.firstName} ${row.person.lastName}`}
                  </TableCell>
                  {showPaymentLinkColumn && (
                    <TableCell classes={{ root: cellBodyStyles.root }} align="center">
                      {isPaymentLinkAvailable(dues[rowIndex]) && (
                        <Button
                          variant="contained"
                          aria-label="Ponów"
                          onClick={(event) => {
                            event.stopPropagation();
                            paymentsApi.postAsFormToGateway(dues[rowIndex].latestPayment.onlinePaymentLink);
                          }}
                          startIcon={<OpenInBrowserIcon />}>
                          Ponów
                        </Button>
                      )}
                    </TableCell>
                  )}
                  <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                    {ChargingReportTypes[row.dueType].name}
                  </TableCell>
                  <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                    {moment(row.approvalDate).format('YYYY-MM-DD')}
                  </TableCell>
                  {isTableUnpaid && (
                    <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                      {moment(row.maturityDate).format('YYYY-MM-DD')}
                    </TableCell>
                  )}
                  <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                    <Strong>
                      {dues[rowIndex].calculatedTotal !== undefined && dues[rowIndex].calculatedTotal !== null
                        ? numberFormatter.format(dues[rowIndex].calculatedTotal)
                        : '-'}
                    </Strong>
                  </TableCell>
                  {dues[rowIndex].calculationStatus === CalculationStatus.paid.value && !onlinePaymentEnabled && (
                    <TableCell sx={{ width: 30 }} align="center">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onPrint(dues[rowIndex]);
                        }}
                        aria-label="Pobierz pdf"
                        size="large">
                        <PictureAsPdfIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  )}
                  {isTableUnpaid && (
                    <TableCell classes={{ root: cellBodyStyles.root }} align="right">
                      <Strong>
                        {dues[rowIndex].getAmountToPaid() !== undefined && dues[rowIndex].getAmountToPaid() !== null
                          ? numberFormatter.format(dues[rowIndex].getAmountToPaid())
                          : '-'}
                      </Strong>
                    </TableCell>
                  )}
                  {isTableUnpaid && onlinePaymentEnabled && (
                    <TableCell align="center">
                      <Box onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox2
                          color="primary"
                          inputProps={{ 'aria-label': `wybierz kolumne ${rowIndex}` }}
                          checked={itemSelected(rowIndex)}
                          onChange={() => onSelect({ id: dues[rowIndex].id })}
                          disabled={
                            dues[rowIndex].calculationStatus !== CalculationStatus.approved.value ||
                            !dues[rowIndex].onlinePaymentSupported
                          }
                        />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table2>
      </TableContainer>
    </Box>
  );
};

GuardianPaidDuesList.propTypes = {
  dues: PropTypes.array.isRequired,
  onChoose: PropTypes.func.isRequired,
  onPrint: PropTypes.func,
  isTableUnpaid: PropTypes.bool,
  onlinePaymentEnabled: PropTypes.bool,
  selectedDuesIds: PropTypes.array,
  onSelect: PropTypes.func
};

export default GuardianPaidDuesList;
