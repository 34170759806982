import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DigitalDiaryEventsModel } from '../models/digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export default function digitalDiaryReducer(state = initialState.digitalDiary.events, action) {
  switch (action.type) {
    case types.CREATE_DIGITAL_DIARY_EVENT_SUCCESS: {
      let newSortedArray = [];
      if (action.sendedData.eventData.cloneDays) {
        newSortedArray = _.sortBy(
          [
            ...state.events,
            action.eventData,
            ...action.sendedData.eventData.cloneDays.map((date) => {
              return {
                ...action.sendedData.eventData,
                id: uuidv4(),
                eventDate: date
              };
            })
          ],
          (item) => item.eventDate
        );
      } else {
        newSortedArray = _.sortBy([...state.events, action.eventData]);
      }

      return new DigitalDiaryEventsModel([...newSortedArray]);
    }
    case types.LOAD_DIGITAL_DIARY_EVENT_START: {
      return state;
    }
    case types.LOAD_DIGITAL_DIARY_EVENT_SUCCESS: {
      return new DigitalDiaryEventsModel(action.data);
    }
    default:
      return state;
  }
}
