import React from 'react';
import PersonAvatar from '../../forms/PersonAvatar';
import PropTypes from 'prop-types';
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

const BoardPostCardDialogItem = (props) => {
  return (
    <ListItem
      sx={{
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}
      key={props.element.id}>
      <ListItemAvatar>
        <PersonAvatar
          initials
          alt={props.element.firstName}
          url={props.element.photoUrl}
          firstName={props.element.firstName}
          lastName={props.element.lastName}
        />
      </ListItemAvatar>
      <ListItemText
        primary={props.element.lastName + ' ' + props.element.firstName}
        primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
        secondary={
          props.element.seen ? (
            <Typography component="p" sx={{ display: 'flex', alignItems: 'center' }}>
              Wyświetlono
              <Check sx={{ color: (theme) => theme.palette.color.color24, ml: 1 }} />
            </Typography>
          ) : (
            <Typography component="p" sx={{ display: 'flex', alignItems: 'center' }}>
              Nie wyświetlono
              <Close sx={{ color: (theme) => theme.palette.color.error, ml: 1 }} />
            </Typography>
          )
        }
        secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
      />
    </ListItem>
  );
};

BoardPostCardDialogItem.propTypes = {
  element: PropTypes.object
};

export default BoardPostCardDialogItem;
