import initialState from './initial';
import {
  LOAD_STAFF_EVENTS_SUCCESS,
  LOAD_STAFF_EVENTS_BEGIN,
  LOAD_STAFF_EVENTS_FAILURE,
  CREATE_STAFF_EVENT_SUCCESS,
  CREATE_STAFF_EVENT_BEGIN,
  CREATE_STAFF_EVENT_FAILURE,
  LOAD_STAFF_EVENT_DETAILS_BEGIN,
  LOAD_STAFF_EVENT_DETAILS_FAILURE,
  LOAD_STAFF_EVENT_DETAILS_SUCCESS,
  UPDATE_STAFF_EVENT_BEGIN,
  UPDATE_STAFF_EVENT_SUCCESS,
  UPDATE_STAFF_EVENT_FAILURE,
  DELETE_STAFF_EVENT_BEGIN,
  DELETE_STAFF_EVENT_FAILURE,
  DELETE_STAFF_EVENT_SUCCESS
} from '../actions/actionTypes';
import { BoardPostModel, MessageBoardModel } from '../models/messageBoard/MessageBoardModels';

export default (state = initialState.staffCalendar, action) => {
  switch (action.type) {
    case LOAD_STAFF_EVENTS_BEGIN:
      return {
        ...state,
        events: [],
        loading: true
      };
    case LOAD_STAFF_EVENTS_FAILURE:
      return {
        ...state,
        events: [],
        loading: false
      };
    case LOAD_STAFF_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.map((event) => new BoardPostModel().assign(event)),
        loading: false
      };
    case CREATE_STAFF_EVENT_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CREATE_STAFF_EVENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case CREATE_STAFF_EVENT_SUCCESS:
      return {
        ...state,
        events: [
          ...state.events.map((event) => new BoardPostModel().assign(event)),
          new BoardPostModel().assign(action.payload)
        ],
        loading: false
      };
    case UPDATE_STAFF_EVENT_BEGIN:
      return {
        ...state,
        loading: true
      };
    case UPDATE_STAFF_EVENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case UPDATE_STAFF_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map((event) =>
          action.payload.post.id === event.id ? new BoardPostModel().assign(action.payload.post) : event
        ),
        eventDetails: new MessageBoardModel(
          state.eventDetails.posts.map((event) =>
            action.payload.post.id === event.id ? new BoardPostModel().assign(action.payload.post) : event
          ),
          state.eventDetails.totalCount
        ),
        loading: false
      };
    case DELETE_STAFF_EVENT_BEGIN:
      return {
        ...state,
        loading: true
      };
    case DELETE_STAFF_EVENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case DELETE_STAFF_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter((event) => action.payload.id !== event.id),
        eventDetails: new MessageBoardModel(
          state.eventDetails.posts.filter((event) => action.payload.id !== event.id),
          state.eventDetails.totalCount
        ),
        loading: false
      };
    case LOAD_STAFF_EVENT_DETAILS_BEGIN:
      return {
        ...state,
        eventDetails: new MessageBoardModel([], 0),
        loading: true
      };
    case LOAD_STAFF_EVENT_DETAILS_FAILURE:
      return {
        ...state,
        eventDetails: new MessageBoardModel([], 0),
        loading: false
      };
    case LOAD_STAFF_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: new MessageBoardModel([...action.payload], action.payload.length),
        loading: false
      };
    default:
      return state;
  }
};
