import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ChildFileAttachmentsList from './ChildFileAttachmentsList';
import ChildFileAttachmentsForm from './ChildFileAttachmentForm';
import EmptyState from '../../common/EmptyState';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import { FileModel } from '../../../models/children/ChildModels';
import { FileModelValidator } from '../../../models/children/ChildModelsValidator';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { ROOT_URL } from '../../../api/ApiBase';
import FilePickerWithRef from '../../common/FilePickerWithRef/FilePickerWithRef';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

const ChildFileAttachmentsCard = (props) => {
  const filePickerRef = React.createRef();

  const [newFileAttachment, setNewFileAttachment] = useState(new FileModel());
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleNameChange = (name) => {
    setNewFileAttachment(Object.assign({}, newFileAttachment, { name }));
  };

  const handleFilePicked = (file) => {
    setNewFileAttachment(Object.assign({}, new FileModel(), { file }));
    dispatch(props.onCreate());
  };

  const handleDownloadFile = (fileId) => {
    const path = `${ROOT_URL}/children/${props.pupilId}/files/${fileId}`;
    window.open(path, '_blank');
  };

  const renderEditForm = () => {
    return (
      <ChildFileAttachmentsForm
        fileAttachment={newFileAttachment}
        onNameChange={handleNameChange}
        onFileDrop={handleFilePicked}
        errors={errors}
      />
    );
  };

  const renderReadonlyForm = () => {
    return (
      <ChildFileAttachmentsList
        attachments={props.attachments}
        onRemove={props.onRemove}
        onDownloadFile={handleDownloadFile}
      />
    );
  };

  const renderActionTitle = (filename) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AttachmentIcon sx={{ mr: 1 }} />
        {filename}
      </Box>
    );
  };

  return (
    <Box>
      <CardDialogEditForm
        title="Załączniki"
        isInitiallyExpanded={props.isInitiallyExpanded}
        onSave={() => props.onAdd(newFileAttachment)}
        onCancel={() => dispatch(props.onCancel())}
        onValidate={() => new FileModelValidator().validate(newFileAttachment)}
        onValidationDone={(err) => setErrors(err)}
        onAction={() => filePickerRef.current.open()}
        actionLabel="DODAJ"
        actionTitle={newFileAttachment.file ? renderActionTitle(newFileAttachment.file.name) : ''}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        statePathToUi="childDetailsUi.attachments"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Brak załączników" contrast />}
        editForm={renderEditForm()}
        isEmpty={props.attachments.length < 1}
      />
      <Box sx={{ display: 'none' }}>
        <FilePickerWithRef ref={filePickerRef} onDrop={handleFilePicked} />
      </Box>
    </Box>
  );
};

ChildFileAttachmentsCard.propTypes = {
  pupilId: PropTypes.string.isRequired,
  attachments: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired
};

export default ChildFileAttachmentsCard;
