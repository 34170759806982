import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function psychologicalChoosenGroupReducer(state = initialState.psychologicalChoosenGroup, action) {
  switch (action.type) {
    case types.CHANGE_PSYCHOLOGICAL_GROUP: {
      return Object.assign({}, action.group);
    }

    default:
      return state;
  }
}
