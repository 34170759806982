import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../../common/Checkbox';
import Toggle from '../../common/Toggle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as exportChildrenActions from '../../../actions/exportChildrenActions';
import { List, ListItem, ListItemText, ListItemIcon, Box, Button } from '@mui/material';
import Dialog from '../../common/Dialog';

// TODO: w przypadku gdy jest jedna grupa w przedszkolu (może być więcej grup ale bez dzieci), lista przy eksporcie jest "pusta".

class ExportChildrenDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      // eslint-disable-next-line no-confusing-arrow
      groups: this.props.groups.map((group) => {
        return { name: group.name, id: group.id, checked: false };
      }),
      educationDates: false,
      allGroups: false
    };
    this.handleExportChildren = this.handleExportChildren.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCheckboxToggle = this.handleCheckboxToggle.bind(this);
    this.handleAllGroupsToggle = this.handleAllGroupsToggle.bind(this);
    this.handleGroupCheck = this.handleGroupCheck.bind(this);
  }

  handleExportChildren() {
    if (this.props.nadarzyn) {
      this.props.actions.exportStudentAsync(
        this.state.allGroups
          ? this.state.groups.map((group) => group.id)
          : this.state.groups.filter((group) => group.checked).map((group) => group.id)
      );
    } else {
      this.props.actions.exportChildrenAsync({
        dates: this.state.educationDates,
        groups: this.state.allGroups
          ? this.state.groups.map((group) => group.id)
          : this.state.groups.filter((group) => group.checked).map((group) => group.id)
      });
    }

    this.setState({
      educationDates: false,
      allGroups: false,
      groups: this.props.groups.map((group) => {
        return { name: group.name, id: group.id, checked: false };
      })
    });
  }

  handleClose() {
    this.setState({
      educationDates: false,
      allGroups: false,
      groups: this.props.groups.map((group) => {
        return { name: group.name, id: group.id, checked: false };
      })
    });
    this.props.actions.exportChildrenCancel();
  }

  handleCheckboxToggle() {
    this.setState((state) => {
      return {
        educationDates: !state.educationDates
      };
    });
  }

  handleAllGroupsToggle(e, val) {
    this.setState({
      allGroups: val
    });
  }

  handleGroupCheck(id) {
    this.setState((prevState) => ({
      // eslint-disable-next-line no-confusing-arrow
      groups: prevState.groups.map((group) => (group.id === id ? { ...group, checked: !group.checked } : group))
    }));
  }

  render() {
    const actions = [
      <Button
        variant="outlinedContrast"
        aria-label="Anuluj"
        key={1}
        onClick={() => this.props.actions.exportChildrenCancel()}>
        Anuluj
      </Button>,
      <Button variant="contained" aria-label="Pobierz plik(.xml)" key={2} onClick={this.handleExportChildren}>
        Pobierz plik(.xml)
      </Button>
    ];

    const styles = {
      block: {
        maxWidth: 250
      },
      checkbox: {
        marginBottom: 16
      }
    };
    return (
      /*eslint no-return-assign: 0 */
      /*eslint react/jsx-no-bind: 0 */
      <Box>
        <Dialog
          title={`Eksportowanie ${this.props.nadarzyn ? 'uczniów' : 'dzieci'}`}
          actions={actions}
          modal={false}
          open={this.props.ui.isEditing}
          onRequestClose={this.handleClose}>
          {!this.props.nadarzyn && (
            <Checkbox
              label="Data rozpoczęcia i zakończenia edukacji"
              style={styles.checkbox}
              defaultChecked={this.state.educationDates}
              onCheck={this.handleCheckboxToggle}
              sx={{ m: 0, mr: 2 }}
            />
          )}
          <Toggle
            onToggle={this.handleAllGroupsToggle}
            label="Eksportuj wszystkie grupy"
            labelPosition="right"
            defaultToggled={this.state.allGroups}
          />
          {!this.state.allGroups ? (
            <List
              sx={{
                mt: 1,
                overflow: 'auto',
                maxHeight: '300px'
              }}>
              {this.state.groups.map((group) => {
                return (
                  <ListItem
                    key={group.id}
                    sx={{
                      '&:hover': {
                        background: (theme) => theme.palette.background.hover
                      }
                    }}>
                    <ListItemIcon>
                      <Checkbox defaultChecked={group.checked} onCheck={() => this.handleGroupCheck(group.id)} />
                    </ListItemIcon>
                    <ListItemText primary={group.name} />
                  </ListItem>
                );
              })}
            </List>
          ) : null}
        </Dialog>
      </Box>
    );
  }
}

ExportChildrenDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    ui: state.configuration.exportChildrenUi,
    groups: state.groups,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(exportChildrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportChildrenDialog);
