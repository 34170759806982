import BaseModel from '../BaseModel';
// import moment from 'moment';
// import {TimeModel} from '../TimeModel';

export class MessageBoardFilterModel extends BaseModel {
  constructor() {
    super();
    this.dateFrom = '';
    this.dateTo = '';
    this.authors = [];
    this.activities = [];
    this.groups = [];
    this.filtered = false;
    this.pinned = false;
    this.textFilter = '';
  }

  onAssignment(assigned) {
    this.authors = assigned.authors.map((a) => a);
    this.activities = assigned.activities.map((a) => a);
    this.groups = assigned.groups.map((a) => a);
  }
}
