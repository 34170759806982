import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import LoadingIndicatorWrapper from "../common/loading/LoadingIndicatorWrapper";
import {
  createStaffMessageBoardPosts,
  deleteStaffMessageBoardPosts,
  loadFileteredStaffMessageBoardPosts,
  loadMoreFileteredStaffMessageBoardPosts,
  loadMoreStaffMessageBoardPosts,
  loadStaffMessageBoardPosts,
  updateStaffMessageBoardPosts,
  readAllStaffMessageBoard
} from "../../actions/staffMessageBoardActions";
import BoardPostsList from "../messageBoard/BoardPostsList";
import PostDialog from "./Dialogs/PostDialog";
import { AuthorModel, BoardPostModel } from "../../models/messageBoard/MessageBoardModels";
import { Button, Grid } from "@mui/material";
import { SearchBar } from "../common/SearchBar";
import _ from "lodash";

const useStyles = makeStyles({
  root: {},
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  buttonLeft: {
    marginRight: "8px"
  },
  paper: {
    padding: "16px"
  }
});

const INITIAL_VALUES = {
  title: "",
  content: "",
  files: []
};

const INIT_POST_LIMIT = 4;
const offset = 0;

const StaffMessageBoardContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.staffMessageBoard.loading);
  const loadingMore = useSelector((state) => state.staffMessageBoard.loadingMore);
  const { posts, pageCount, hasNextPage } = useSelector((state) => state.staffMessageBoard.posts);
  const auth = useSelector((state) => state.auth);

  const [searchValue, setSearchValue] = useState("");
  const [postDialog, setPostDialog] = useState(false);
  const [activeFilters, setActiveFilters] = useState(false);
  const [initialValues, setInitialValues] = useState(new BoardPostModel().assign(INITIAL_VALUES));
  const [editing, setEditing] = useState(false);
  const [limit, setLimit] = useState(INIT_POST_LIMIT);

  useEffect(() => {
    dispatch(
      loadStaffMessageBoardPosts({
        limit,
        offset
      })
    );
  }, []);

  const handlePostDialog = () => {
    setEditing(false);
    setInitialValues(new BoardPostModel().assign(INITIAL_VALUES));
    setPostDialog(true);
  };

  const handleClose = () => {
    setPostDialog(false);
  };

  const handleEditOpen = (post) => {
    setInitialValues(new BoardPostModel().assign(post));
    setEditing(true);
    setPostDialog(true);
  };

  const handleSubmit = async (values) => {
    try {
      if (editing) {
        const editedValues = _.cloneDeep(values);
        editedValues.lastEditedBy = new AuthorModel().assign({ id: auth.userId, fullName: auth.userFullName });
        editedValues.lastEditedAt = new Date();
        await dispatch(
          updateStaffMessageBoardPosts(
            Object.assign(new BoardPostModel().assign(editedValues), {
              files: editedValues.files.map((file) => file.id),
              internal: true
            }),
            new BoardPostModel().assign(editedValues)
          )
        );
      } else {
        await dispatch(
          createStaffMessageBoardPosts(
            Object.assign(new BoardPostModel().assign(values), {
              files: values.files.map((file) => file.id),
              internal: true
            }),
            new BoardPostModel().assign({
              ...values,
              author: { id: auth.userId, fullName: auth.userFullName }
            })
          )
        );
      }

      setPostDialog(false);
    } catch (err) {
      setPostDialog(true);
    }
  };

  const handleDelete = (post) => {
    dispatch(deleteStaffMessageBoardPosts(post));
  };

  const handleReadAllPost = () => {
    dispatch(readAllStaffMessageBoard());
  };

  const handleLoadMore = () => {
    setLimit((prevState) => {
      if (activeFilters) {
        dispatch(
          loadMoreFileteredStaffMessageBoardPosts({
            textFilter: searchValue,
            limit: prevState + INIT_POST_LIMIT,
            offset
          })
        );
      } else {
        dispatch(
          loadMoreStaffMessageBoardPosts({
            limit: prevState + INIT_POST_LIMIT,
            offset
          })
        );
      }
      return prevState + INIT_POST_LIMIT;
    });
  };

  const handleClearFilters = () => {
    setActiveFilters(false);
    setSearchValue("");
    setLimit(() => {
      dispatch(
        loadStaffMessageBoardPosts({
          limit: INIT_POST_LIMIT,
          offset
        })
      );
      return INIT_POST_LIMIT;
    });
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    setActiveFilters(true);
    dispatch(
      loadFileteredStaffMessageBoardPosts({
        textFilter: searchValue
      })
    );
  };

  return (
    <>
      <LoadingIndicatorWrapper isProcessing={loading}>
        <>
          <Grid container sx={{ pb: 4 }}>
            <Grid item xs={12} lg={5} sx={{ display: "flex", alignItems: "flex-end" }}>
              <SearchBar
                label="Szukaj"
                handleTextSearch={handleSearch}
                SearchOnClick={handleSearch}
                value={searchValue}
                onChange={handleSearchValueChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={7}
              sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap", pt: { xs: 2, lg: 0 } }}>
              {activeFilters && (
                <Button
                  sx={{ py: 1, lineHeight: 1 }}
                  variant="outlined"
                  aria-label="Wyczyść filtry"
                  onClick={handleClearFilters}
                  className={classes.buttonLeft}>
                  Wyczyść filtry
                </Button>
              )}
              <Button
                sx={{ py: 1, lineHeight: 1 }}
                variant="contained"
                aria-label="Nowy wpis"
                onClick={() => handlePostDialog()}>
                Nowy wpis
              </Button>
              <Button
                sx={{ py: 1, lineHeight: 1 }}
                variant="contained"
                aria-label="Oznacz wszystkie jako przeczytane"
                onClick={() => handleReadAllPost()}>
                Oznacz wszystkie jako przeczytane
              </Button>
            </Grid>
          </Grid>

          <BoardPostsList
            posts={posts}
            pageCount={pageCount}
            hasNextPage={hasNextPage}
            customPermissions
            onEdit={handleEditOpen}
            onDelete={handleDelete}
            onLoadMoreClick={handleLoadMore}
            loadingMore={loadingMore}
            dontDisplayHistory={false}
            isStaffMemberBoard={true}
          />
        </>
      </LoadingIndicatorWrapper>
      {postDialog && (
        <PostDialog
          inProgress={loading}
          initialValues={initialValues}
          onClose={handleClose}
          onSubmit={handleSubmit}
          open={postDialog}
          title={editing ? "Edytuj wpis" : "Dodaj wpis"}
        />
      )}
    </>
  );
};

StaffMessageBoardContainer.propTypes = {};

export default StaffMessageBoardContainer;
