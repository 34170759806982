import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';
import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';

const GroupTeachersList = ({ teachers, onRemoveTeacher }) => {
  const sortedTeachers = useMemo(() => {
    return teachers.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }, [teachers]);

  return (
    <List>
      {sortedTeachers.map((teacher) => {
        return (
          <ListItem key={teacher.id}>
            <ListItemAvatar>
              <PersonAvatar
                initials
                alt={teacher.firstName}
                url={teacher.photoUrl}
                firstName={teacher.firstName}
                lastName={teacher.lastName}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                  {teacher.lastName + ' ' + teacher.firstName}
                </Typography>
              }
              secondary={
                <Typography sx={{ opacity: 0.7 }}>
                  {teacher.associatedAt ? `W grupie od ${moment(teacher.associatedAt).format('YYYY-MM-DD')}` : ''}
                </Typography>
              }
              disabled={true}
            />
            <ActionConfirmationButton
              actionLabel="odłącz"
              confirmationText="Czy na pewno chcesz odłączyć nauczyciela od grupy?"
              confirmationTitle="Odłącz nauczyciela"
              confirmLabel="odłącz"
              variant="clearText"
              onConfirm={() => onRemoveTeacher(teacher.id)}
              onCancel={() => {}}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

GroupTeachersList.propTypes = {
  teachers: PropTypes.array.isRequired,
  onRemoveTeacher: PropTypes.func.isRequired
};

export default GroupTeachersList;
