import PropTypes from 'prop-types';
import React from 'react';
import ValidationWrapper from './ValidationWrapper';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button } from '@mui/material';
import SaveCancelButtons from './buttons/SaveCancelButtons';
import withMediaQuery from '../../hoc/withMediaQuery';
import PublisherDialogForm from './PublisherDialogForm';
import Small from '../common/Small';
import RODO from '../../constants/RODO';

class DialogForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.changeVisibilityOfCoursebookDialog = this.changeVisibilityOfCoursebookDialog.bind(this);
    this.changeVisibilityOfConfirmationDialog = this.changeVisibilityOfConfirmationDialog.bind(this);
    this.hideConfirmationDialog = this.hideConfirmationDialog.bind(this);
    this.changeVisibilityOfRODODialog = this.changeVisibilityOfRODODialog.bind(this);
    this.state = {
      showAllValidationErrors: false,
      showPublisherDialog: false,
      showConfirmationDialog: false,
      showRODODialog: false
    };
  }

  handleSave() {
    if (this.props.hasConfirmationDialog && !this.state.showConfirmationDialog) {
      this.changeVisibilityOfConfirmationDialog();
    } else {
      if (this.props.onValidate && !this.props.onValidate().isValid) {
        this.setState({ showAllValidationErrors: true });
      } else {
        this.props.onSave();
        this.hideConfirmationDialog();
      }
    }
  }

  changeVisibilityOfCoursebookDialog() {
    this.setState((prevState) => ({ ...prevState, showPublisherDialog: !prevState.showPublisherDialog }));
  }

  changeVisibilityOfConfirmationDialog() {
    this.setState((prevState) => ({ ...prevState, showConfirmationDialog: !prevState.showConfirmationDialog }));
  }

  hideConfirmationDialog() {
    this.setState((prevState) => ({ ...prevState, showConfirmationDialog: false }));
  }

  changeVisibilityOfRODODialog() {
    this.setState((prevState) => ({ ...prevState, showRODODialog: !prevState.showRODODialog }));
  }

  renderDialogButtons(isConfirmationDialog = false) {
    const { onCancel, saveLabel, saveDisabled, cancelLabel, extraActionContent, isMobile, hideSubmit } = this.props;
    return (
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, flexWrap: 'wrap', mt: 2 }}>
        {extraActionContent}
        <SaveCancelButtons
          hideSubmit={hideSubmit}
          isMobile={isMobile}
          saveLabel={saveLabel}
          onSave={this.handleSave}
          cancelLabel={cancelLabel}
          onCancel={isConfirmationDialog ? this.hideConfirmationDialog : onCancel}
          inProgress={this.props.isProcessing}
          saveDisabled={
            !!saveDisabled || (this.props.errors && Object.values(this.props.errors).filter((e) => e !== '').length > 0)
          }
        />
      </Box>
    );
  }

  renderConfirmationDialog() {
    const { hasConfirmationDialog, confirmationTitle, confirmationText, attachRODO } = this.props;
    return hasConfirmationDialog && this.state.showConfirmationDialog ? (
      <Dialog open={this.state.showConfirmationDialog} onClose={() => this.setState({ showConfirmationDialog: false })}>
        <DialogTitle>{confirmationTitle}</DialogTitle>
        <DialogContent>{confirmationText}</DialogContent>
        {attachRODO && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              sx={{ ml: 1, mb: { xs: 2, sm: 0 }, color: (theme) => theme.palette.color.contrast, width: '35%' }}
              variant="outlinedContrast"
              onClick={this.changeVisibilityOfRODODialog}>
              Szczegóły RODO
            </Button>
          </Box>
        )}
        <DialogActions>{this.renderDialogButtons(true)}</DialogActions>
      </Dialog>
    ) : null;
  }

  renderRODODialog() {
    return this.props.attachRODO && this.state.showRODODialog ? (
      <Dialog open={this.state.showRODODialog} onClose={() => this.setState({ showRODODialog: false })}>
        <DialogTitle>Szczegóły RODO</DialogTitle>
        <DialogContent>{RODO}</DialogContent>
        <DialogActions>
          <Button
            sx={{ ml: 1, mb: { xs: 2, sm: 0 }, color: (theme) => theme.palette.color.contrast }}
            onClick={this.changeVisibilityOfRODODialog}
            variant="outlinedContrast">
            Wróć
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }

  renderCoursebookDialog() {
    return this.state.showPublisherDialog ? (
      <PublisherDialogForm
        coursebookSetMethods={this.props.coursebookSetMethods}
        open={this.state.showPublisherDialog}
        onCancel={() => this.changeVisibilityOfCoursebookDialog()}
      />
    ) : null;
  }

  render() {
    const {
      coursebook,
      defaultPublishingHouseId,
      header,
      children,
      isDialogOpen,
      onValidate,
      onValidationDone,
      statePathToUi,
      onCancel,
      className,
      noValidate = false,
      icon,
      maxWidth,
      buttonsHidden,
      scrollHidden
    } = this.props;

    return (
      <>
        <Dialog
          className={className}
          open={isDialogOpen}
          onClose={onCancel}
          fullWidth
          maxWidth={maxWidth || 'lg'}
          PaperProps={{
            sx: { borderRadius: 2 }
          }}>
          <DialogTitle sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { sm: 'space-between' },
                alignItems: 'center',
                flexDirection: { xs: 'column', sm: 'row' }
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  width: '100%',
                  justifyContent: { xs: 'center', sm: 'flex-start' }
                }}>
                {icon}
                {header}
              </Box>
              {coursebook && defaultPublishingHouseId && (
                <Button
                  variant="contained"
                  onClick={() => this.changeVisibilityOfCoursebookDialog()}
                  sx={{ width: { sm: 400 } }}>
                  Wybierz temat z podręcznika
                </Button>
              )}
            </Box>
          </DialogTitle>
          <DialogContent sx={{ px: 3, overflowY: scrollHidden ? 'hidden' : 'auto' }}>
            <Box>
              {!noValidate ? (
                <ValidationWrapper
                  validate={onValidate}
                  onValidationDone={onValidationDone}
                  statePathToUi={statePathToUi}
                  showAllErrors={this.state.showAllValidationErrors}>
                  {children}
                </ValidationWrapper>
              ) : (
                <>{children}</>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              mx: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: buttonsHidden ? '20px' : '100px'
            }}>
            {this.props.requiredFieldText && <Small sx={{ whiteSpace: 'nowrap' }}>* - Pola wymagane</Small>}
            {!buttonsHidden && (
              <Box
                sx={{
                  width: '100%'
                }}>
                {this.renderDialogButtons()}
              </Box>
            )}
          </DialogActions>
        </Dialog>
        {this.renderCoursebookDialog()}
        {this.renderConfirmationDialog()}
        {this.renderRODODialog()}
      </>
    );
  }
}

DialogForm.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  onValidationDone: PropTypes.func,
  statePathToUi: PropTypes.string,
  children: PropTypes.node.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  coursebookSetMethods: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  saveDisabled: PropTypes.bool,
  saveLabel: PropTypes.string,
  className: PropTypes.string,
  cancelLabel: PropTypes.string,
  extraActionContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  requiredFieldText: PropTypes.bool,
  isMobile: PropTypes.bool,
  noValidate: PropTypes.bool,
  coursebook: PropTypes.bool,
  defaultPublishingHouseId: PropTypes.string,
  icon: PropTypes.object,
  maxWidth: PropTypes.string,
  buttonsHidden: PropTypes.bool,
  scrollHidden: PropTypes.bool,
  hasConfirmationDialog: PropTypes.bool,
  confirmationTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  attachRODO: PropTypes.bool
};

DialogForm.defaultProps = {
  buttonsHidden: false,
  onValidate: null,
  onValidationDone: null,
  coursebook: false,
  coursebookSetMethods: () => {},
  hasConfirmationDialog: false,
  confirmationTitle: '',
  confirmationText: '',
  attachRODO: false
};

export default withMediaQuery()(DialogForm);
