import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as attendanceActions from '../../actions/attendanceActions';
import * as attendanceHistoryActions from '../../actions/attendanceHistoryActions';
import * as digitalDiaryActions from '../../actions/digitalDiaryActions';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { routePaths, fromTemplate } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import AttendanceHistoryIcon from '@mui/icons-material/History';
import GroupSwitch from '../common/groups/GroupSwitch';
import withMediaQuery from '../../hoc/withMediaQuery';
import AttendanceGroupHistoryCalendar from './history/AttendanceGroupHistoryCalendar';
import { Box } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';

class AttendanceJournalsHistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleYearOrMonthChanged = this.handleYearOrMonthChanged.bind(this);
    this.handleDaySelected = this.handleDaySelected.bind(this);
  }

  handleYearOrMonthChanged(year, month) {
    browserHistory.push(fromTemplate(routePaths.attendanceMonthlyHistory, [this.props.defaultGroupId, year, month]));
  }

  handleGroupChange(group) {
    this.props.digitalDiaryEvents.changeChoosenGroup(group);
    browserHistory.push(
      fromTemplate(routePaths.attendanceMonthlyHistory, [group.id, this.props.defaultYear, this.props.defaultMonth])
    );
  }

  handleDaySelected(date) {
    const d = moment(date);
    browserHistory.push(
      fromTemplate(routePaths.attendanceDailyHistory, [
        this.props.defaultGroupId,
        d.format('YYYY'),
        d.format('MM'),
        d.format('DD')
      ])
    );
  }

  render() {
    const { defaultYear, defaultMonth } = this.props;
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Historia" titleIcon={<AttendanceHistoryIcon />} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GroupSwitch onGroupChange={this.handleGroupChange} showArchivedGroups={false} />
          </Box>
          <ContentWrapper>
            <AttendanceGroupHistoryCalendar
              onSelected={this.handleYearOrMonthChanged}
              defaultMonth={defaultMonth}
              defaultYear={defaultYear}
              date={moment(new Date(defaultYear, defaultMonth - 1, 1))}
              daysOff={this.props.daysOff}
              markedDays={this.props.journalDays.map((d) => {
                return { date: d, data: null };
              })}
              onDateChange={this.handleDaySelected}
            />
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

AttendanceJournalsHistoryPage.propTypes = {
  journalDays: PropTypes.array.isRequired,
  daysOff: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  defaultYear: PropTypes.string.isRequired,
  defaultMonth: PropTypes.string.isRequired,
  defaultGroupId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  choosenGroup: PropTypes.object.isRequired,
  historyActions: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  digitalDiaryEvents: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    journalDays: state.attendanceHistory.days,
    daysOff: state.configuration.daysOff,
    groups: state.groups,
    defaultYear: ownProps.params.year,
    defaultMonth: ownProps.params.month,
    defaultGroupId: ownProps.params.groupId,
    auth: state.auth,
    choosenGroup: state.digitalDiary.choosenGroup,
    staffMembers: state.staffMembers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(attendanceActions, dispatch),
    historyActions: bindActionCreators(attendanceHistoryActions, dispatch),
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch)
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withMediaQuery())(AttendanceJournalsHistoryPage);
