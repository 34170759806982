import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportAttendance,
  reportAttendanceCancelAsync,
  reportGroupAttendance,
  reportGroupAttendanceCancelAsync
} from '../../../actions/psychologicalAttendanceActions';
import ReportAttendanceDialog from '../reportDialogs/ReportAttendanceDialog';

const PsychologicalAttendanceDialog = ({ childDetails, attendanceDetails, journalDate, isGroup, Ui, groupId }) => {
  const workingHours = useSelector((state) => state.configuration.unit.workingHours);
  const dispatch = useDispatch();

  const onSave = (e) => {
    dispatch(
      isGroup
        ? reportGroupAttendance({
            childId: childDetails.id,
            date: e.droppedOffAt,
            groupId: groupId,
            droppedOfAt: e.droppedOffAt,
            pickedUpAt: e.pickedUpAt
          })
        : reportAttendance({
            childId: childDetails.id,
            date: e.droppedOffAt,
            droppedOfAt: e.droppedOffAt,
            pickedUpAt: e.pickedUpAt
          })
    );
  };

  const onCancel = () => {
    dispatch(isGroup ? reportGroupAttendanceCancelAsync() : reportAttendanceCancelAsync());
  };

  return (
    <ReportAttendanceDialog
      pickedUpBy={attendanceDetails?.pickedUpBy ? attendanceDetails?.pickedUpBy.id : null}
      pickedUpAt={
        attendanceDetails.pickedUpAt
          ? moment(attendanceDetails.pickedUpAt)
          : workingHours.to.hourLocal !== null
          ? moment(journalDate).set('hour', workingHours.to.hourLocal).set('minute', workingHours.to.minuteLocal)
          : moment(journalDate).set('hour', 20)
      }
      droppedOffAt={
        attendanceDetails.droppedOffAt
          ? moment(attendanceDetails.droppedOffAt)
          : workingHours.from.hourLocal !== null
          ? moment(journalDate).set('hour', workingHours.from.hourLocal).set('minute', workingHours.from.minuteLocal)
          : moment(journalDate).set('hour', 10)
      }
      noGuardians
      guardians={[]}
      pupilFirstName={childDetails.firstName}
      pupilLastName={childDetails.lastName}
      pupilPhotoUrl={childDetails.pupilPhotoUrl}
      pupilContract={childDetails.pupilContract}
      absenceFrom={journalDate}
      absenceTo={journalDate}
      absenceNotes=""
      isAbsenceFromBlocked={childDetails.isAbsenceFromBlocked}
      isAbsenceToBlocked={childDetails.isAbsenceToBlocked}
      isProcessing={Ui.isProcessing}
      isDialogOpen={Ui.isEditing}
      onCancel={onCancel}
      onSave={onSave}
      isFirstAttendanceAttempt={!attendanceDetails?.droppedOffAt || !attendanceDetails?.pickedUpAt}
      statePathToUi="childDetailsUi.individualAttendanceUi"
    />
  );
};

export default PsychologicalAttendanceDialog;
