import React, { useState } from 'react';
import FormDialog from '../../../formik/formDialog/FormDialog';
import { FormikTextField } from '../../../formik/FormikTextField';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import ColorPicker from '../../../common/ColorPicker';
import { avatarColorPaletteArray } from '../../../../constants/avatarColorPalette';
import { ErrorMessage } from 'formik';

export default function CreateOrEditFolderDialog({
  onClose,
  open,
  onSubmit,
  initialValues = { name: '', color: avatarColorPaletteArray[0].color }
}) {
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.action);
  const [color, setColor] = useState(initialValues.color);
  const [nameError, setNameError] = useState('');
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    onSubmit({ ...values, color });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name.trim()) {
      errors.name = 'Podaj nazwę folderu';
      setNameError(errors.name);
    } else if (values.name.length > 100) {
      errors.name = 'Nazwa folderu nie może przekraczać 100 znaków';
      setNameError(errors.name);
    } else {
      setNameError('');
    }

    return errors;
  };
  const handleColorChange = (value) => {
    setColor(value);
  };

  return (
    <FormDialog
      onClose={handleClose}
      open={open}
      maxWidth={'md'}
      onSubmit={handleSubmit}
      title={`${initialValues.name ? 'Edytuj' : 'Utwórz'} folder`}
      requiredFieldText
      initialValues={initialValues}
      inProgress={isProcessing}
      validate={validate}>
      <FormikTextField name="name" label="Nazwa*" fullWidth />
      <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'start' }}>
        <Typography variant="caption">Kolor folderu*</Typography>

        <ColorPicker
          name="color"
          defaultColor={color}
          palette={avatarColorPaletteArray}
          onChange={(color) => handleColorChange(color)}
        />
      </Box>
    </FormDialog>
  );
}
