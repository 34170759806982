import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as attendanceActions from '../../actions/attendanceActions';
import * as pinActions from '../../actions/pinActions';
import PersonAvatar from '../forms/PersonAvatar';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import LinkedChildren from './LinkedChildren';
import _ from 'lodash';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import backgroundAdorment from '../../assets/svg/hero-bg-dots.svg';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 32
  },
  confirmButton: {
    background: ({ isButtonDisable }) => (isButtonDisable ? theme.palette.grey[500] : theme.palette.color.color8)
  }
}));

const AttendanceDashboardPage = () => {
  const [selectedEntriesIds, setSelectedEntriesIds] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const authenticatedPinUser = useSelector((state) => state.pinEntries);
  const dispatch = useDispatch();

  const handleApprove = () => {
    let message = '';
    let severity = 'success';
    const currentTime = moment();
    const todo = selectedEntriesIds.map((selectedId) => {
      return async () => {
        const child = _.find(authenticatedPinUser.linkedChildren, (e) => e.id === selectedId);
        if (child && child.wasDroppedOff) {
          try {
            const result = await dispatch(
              attendanceActions.reportChildPickUpAsync(child.id, child.pinOwnerId, currentTime)
            );
            message = result;
            severity = 'success';
          } catch (e) {
            message = `Nie udało się zgłosić odbioru dziecka`;
            severity = 'error';
          }
        } else if (child) {
          try {
            const result = await dispatch(attendanceActions.reportChildDropOffAsync(child.id, currentTime));
            message = result;
            severity = 'success';
          } catch (e) {
            message = `Nie udało się zgłosić przyjęcia dziecka`;
            severity = 'error';
          }
        }

        return Promise.resolve();
      };
    });
    setSubmitting(true);
    todo
      .reduce((previousPromise, next) => previousPromise.then(() => next()), Promise.resolve())
      .then(() => dispatch(pinActions.cancelAttendanceRegistration(message, severity)));
  };

  const handleChildrenChecked = (id, selected) => {
    if (!selected) {
      setSelectedEntriesIds([...selectedEntriesIds, id]);
    } else {
      setSelectedEntriesIds(selectedEntriesIds.filter((i) => i !== id));
    }
  };
  const guardian = authenticatedPinUser;
  const children = _.orderBy(
    guardian.linkedChildren.filter((child) => !child.hasPlannedAbsence),
    ['firstName']
  );

  const isButtonDisable = submitting || !selectedEntriesIds || selectedEntriesIds.length === 0;

  const cl = useStyles({ isButtonDisable });

  return (
    <LoadingRenderWrapper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{
          p: 5,
          minHeight: '100vh',
          background: (theme) =>
            ` url(${backgroundAdorment}), transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`
        }}>
        <Typography component="h1" sx={{ mb: 4, fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Panel rejestracji wejścia/wyjścia dziecka
        </Typography>
        <Grid container alignItems="center">
          <PersonAvatar
            initials
            alt={guardian.firstName}
            url={guardian.photoUrl}
            firstName={guardian.firstName}
            lastName={guardian.lastName}
            size={42}
          />
          <Typography component="h2" sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium, ml: 1 }}>
            {guardian.firstName} {guardian.lastName}
          </Typography>
        </Grid>

        <Typography
          component="h2"
          variant="h6"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium, mt: 4 }}
          gutterBottom>
          Wybierz dzieci
        </Typography>
        <LinkedChildren entries={children} onCheck={handleChildrenChecked} selectedEntries={selectedEntriesIds} />

        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <Button
                variant="outlined"
                aria-label="Anuluj"
                onClick={() => {
                  dispatch(pinActions.cancelAttendanceRegistration());
                }}>
                Anuluj
              </Button>

              <Button
                sx={{ width: 200 }}
                variant="contained"
                aria-label="Potwierdź"
                onClick={handleApprove}
                fullWidth
                disabled={isButtonDisable}>
                Potwierdź
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </LoadingRenderWrapper>
  );
};

export default AttendanceDashboardPage;
