import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../../common/TextField';
import SelectField from '../../../common/SelectField';
import { MenuItem, Box } from '@mui/material';
import { attributeObjectTypes } from '../../../../constants/attributeObjectTypes';
import AttributesList from './AttributesList';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const AttributeSetForm = ({ attributeSet, onChange, errors, onCreateAttribute }) => {
  return (
    <Box sx={{ p: 0 }}>
      <FieldsetWrapper legend="Dane obiektu do którego zostaną przypisane atrybuty">
        <TextField
          hintText="Nazwa"
          floatingLabelText="Nazwa*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="name"
          value={attributeSet.name}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.name}
        />
        <SelectField
          contrast
          fullWidth
          sx={{ mb: 1 }}
          floatingLabelText="Powiązany z obiektem*"
          floatingLabelFixed={false}
          id="objectType"
          value={attributeSet.objectType}
          onChange={(e) => {
            onChange({ target: { name: 'objectType', value: e.target.value } });
          }}
          errorText={errors.objectType}>
          {Object.values(attributeObjectTypes).map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </SelectField>
      </FieldsetWrapper>

      <AttributesList
        attributes={attributeSet.attributes}
        onRemove={(id) =>
          onChange({ target: { name: 'attributes', value: attributeSet.attributes.filter((a) => a.id !== id) } })
        }
        onChange={(att) =>
          onChange({
            target: { name: 'attributes', value: attributeSet.attributes.map((a) => (a.id !== att.id ? a : att)) }
          })
        }
        onCreate={onCreateAttribute}
      />
    </Box>
  );
};

AttributeSetForm.propTypes = {
  attributeSet: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onCreateAttribute: PropTypes.func.isRequired
};

export default AttributeSetForm;
