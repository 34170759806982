import React from 'react';
import PropTypes from 'prop-types';
import * as Types from '../../../constants/groupsAndActivitiesTypes';
import SpecialButton from '../../groupsAndActivities/SpecialButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from '@mui/material';

const FromToTransfer = ({ type, from, to, archiveChild }) => {
  const upType = type.toUpperCase();
  return (
    <Grid container alignItems="center" wrap="nowrap" sx={{ p: 1 }}>
      <SpecialButton
        disabled
        data={from}
        type={!!from ? upType : Types.NONE}
        defaultLabel={upType === Types.GROUP ? 'Brak grupy' : 'Brak zajęć dodatkowych'}
        multiple={upType === Types.ACTIVITY}
        fullWidth
        tiny
        sx={{ px: 1 }}
      />
      <ArrowForwardIosIcon sx={{ fontSize: 50, p: '0 10px' }} />
      <SpecialButton
        disabled
        data={to}
        type={!!to ? upType : Types.NONE}
        defaultLabel={upType === Types.GROUP ? 'Brak grupy' : 'Brak zajęć dodatkowych'}
        multiple={upType === Types.ACTIVITY}
        fullWidth
        tiny
        archiveChild={archiveChild}
        sx={{ px: 1 }}
      />
    </Grid>
  );
};

FromToTransfer.propTypes = {
  from: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  to: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.string.isRequired,
  archiveChild: PropTypes.bool
};

export default FromToTransfer;
