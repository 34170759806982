import React, { useState } from 'react';
import UserPasswordTemplate, {
  validate as validateTemplate,
  InitialValues as InitialValuesTemplate,
  VALIDATION_SCHEMA as VALIDATION_SCHEMA_TEMPLATE
} from '../templates/UserPasswordTemplate';
import * as Yup from 'yup';
import IconButtonPipe from '../../components/common/IconButtonPipe';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormikTextField } from '../../components/formik/FormikTextField';

const ChangePasswordForm = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  return (
    <>
      <FormikTextField
        label="Aktualne hasło*"
        type={showCurrentPassword ? 'text' : 'password'}
        name="currentPassword"
        fullWidth
        endAdornment={
          <IconButtonPipe
            onClick={(e) => {
              e.preventDefault();
              setShowCurrentPassword(!showCurrentPassword);
            }}
            tooltip={showCurrentPassword ? 'Ukryj hasło' : 'Wyświetl hasło'}
            size="small">
            {showCurrentPassword ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
          </IconButtonPipe>
        }
      />
      <UserPasswordTemplate passDiff={true} />
    </>
  );
};

ChangePasswordForm.propTypes = {};

export class InitialValues extends InitialValuesTemplate {
  constructor() {
    super();
    this.currentPassword = '';
  }
}

export const VALIDATION_SCHEMA = Yup.object({
  ...VALIDATION_SCHEMA_TEMPLATE,
  password: VALIDATION_SCHEMA_TEMPLATE.password.notOneOf(
    [Yup.ref('currentPassword'), null],
    'Hasło nie spełnia wymaganych kryteriów'
  ),
  currentPassword: Yup.string().required('Aktualne hasło jest wymagane')
});

export const validate = ({ password, currentPassword }) => {
  const errors = validateTemplate({ password });
  if (password === currentPassword) errors.passDiff = true;
  return errors;
};

export default ChangePasswordForm;
