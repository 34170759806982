import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import { DayOffModel } from './DaysOffModels';
import moment from 'moment';

export class DayOffModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = DayOffModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      date: (value) => {
        if (check.isEmpty(value)) return 'Nie podano daty';
        if (!moment(value).isValid()) return 'Niepoprawna data';
        if (!moment(value).isSameOrBefore(moment(new Date()).add(2, 'year')))
          return `Wybierz datę wcześniejszą niż ${moment(new Date()).add(2, 'year').format('DD/MM/YYYY')}`;
      }
    };
  }
}
