import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ActivityAvatar from './ActivityAvatar';

const useStyles = makeStyles(() => ({
  list: {
    padding: '8px 24px'
  }
}));

const ActivitySwitch = (props) => {
  const { childActivities, onSelectActivity, contrast } = props;
  const cl = useStyles();
  const activities = useSelector((state) => state.activities);

  const [showActivityDialog, setShowActivityDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(
    childActivities.length > 0 ? activities.find((activity) => activity.id === childActivities[0].id) : null
  );

  const activitiesToDisplay =
    childActivities.length > 0
      ? childActivities.map((childActivity) => activities.find((activity) => childActivity.id === activity.id))
      : null;

  const handleOpenCloseDialog = () => {
    setShowActivityDialog((prev) => !prev);
  };

  const handleChangeActivity = (activity) => {
    setSelectedActivity(activity);
    onSelectActivity(activity);
    setShowActivityDialog((prev) => !prev);
  };

  useEffect(() => {
    onSelectActivity(selectedActivity);
  }, [onSelectActivity, selectedActivity]);

  return (
    <>
      {selectedActivity ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap' }}>
            <ListItemAvatar>
              <ActivityAvatar activity={selectedActivity} />
            </ListItemAvatar>
            <ListItemText
              primary={selectedActivity.name}
              primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
            />
            <Button
              variant={contrast ? 'outlinedContrast' : 'outlined'}
              aria-label="Wybierz aktywność"
              sx={{ mx: 2 }}
              onClick={() => handleOpenCloseDialog()}>
              <SwapHorizIcon /> Wybierz aktywność
            </Button>
          </Box>
          <Dialog open={showActivityDialog} maxWidth="sm" fullWidth={true} onClose={() => handleOpenCloseDialog()}>
            <DialogTitle>Wybierz aktywność</DialogTitle>
            <DialogContent>
              <List>
                {activitiesToDisplay.map((activity) => (
                  <ListItem sx={{ p: 0, m: 0 }} key={activity.id}>
                    <ListItemButton onClick={() => handleChangeActivity(activity)} sx={{ px: 0 }}>
                      <ListItemAvatar>
                        <ActivityAvatar activity={activity} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={activity.name}
                        primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" aria-label="Anuluj" onClick={() => handleOpenCloseDialog()}>
                Anuluj
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

ActivitySwitch.propTypes = {
  childActivities: PropTypes.array.isRequired,
  onSelectActivity: PropTypes.func
};

export default ActivitySwitch;
