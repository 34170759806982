import PropTypes from 'prop-types';
import React from 'react';
import { RadioGroup, Radio, FormControlLabel, FormControl, FormLabel, Box, Typography, Button } from '@mui/material';
import { PaymentMethods } from '../../../constants/paymentMethods';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import TextField from '../../common/TextField';

const defaultState = {
  amountToCash: '',
  paymentMethod: PaymentMethods.cash.value,
  canCashFromBalance: false,
  walletType: 'general'
};

class PayoutSection extends React.Component {
  constructor() {
    super();
    this.state = { ...defaultState };
    this.handleAmountToPayoutUpdate = this.handleAmountToPayoutUpdate.bind(this);
    this.handlePayoutFromBalance = this.handlePayoutFromBalance.bind(this);
  }

  handleAmountToPayoutUpdate(e) {
    const amountToCash = e.target.value;
    this.setState({
      amountToCash,
      errorText: !(amountToCash > 0 && amountToCash <= this.props.availableFunds) && 'Wprowadz poprawną wartość',
      canCashFromBalance:
        Number.isFinite(parseFloat(amountToCash)) && amountToCash > 0 && amountToCash <= this.props.availableFunds
    });
  }

  handlePayoutFromBalance() {
    this.props.onPayoutFromBalance(this.state.amountToCash, this.state.paymentMethod, this.props.walletType);
    this.setState(defaultState);
  }

  render() {
    if (!this.props.availableFunds) return null;

    return (
      <Box>
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Wypłata nadpłaconych środków (nie więcej niż {this.props.availableFunds} zł)
        </Typography>
        <Box>
          <Box
            sx={{
              p: 0
            }}>
            <TextField
              sx={{ width: '200px', maxWidth: '100%' }}
              hintText="Kwota nadpłaty do wypłaty"
              floatingLabelText="Kwota nadpłaty do wypłaty"
              floatingLabelFixed={false}
              name="amountToCash"
              errorText={this.state.errorText}
              value={this.state.amountToCash}
              disabled={this.props.inProgress}
              onChange={this.handleAmountToPayoutUpdate}
              onBlur={(e) => {
                this.handleAmountToPayoutUpdate({
                  target: { name: 'amountToCash', value: tryConvertToNumber(e.target.value) }
                });
              }}
            />
          </Box>
          <Box
            sx={{
              p: 0
            }}>
            <FormControl
              component="fieldset"
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                '& div': {
                  width: 'auto',
                  mr: 3,
                  '& div div': {
                    mr: 1
                  },
                  '& label': {
                    width: 'auto'
                  }
                }
              }}>
              <FormLabel
                component="legend"
                sx={{
                  '&.Mui-focused': {
                    color: (theme) => theme.palette.color.contrast
                  },
                  color: (theme) => theme.palette.color.contrast
                }}>
                Sposób płatności
              </FormLabel>
              <RadioGroup
                sx={{ display: 'flex', flexDirection: 'row' }}
                aria-label="sposób płatności"
                name="paymentMethod"
                value={this.state.paymentMethod}
                onChange={(e) => this.setState({ paymentMethod: e.target.value })}>
                <FormControlLabel
                  key={PaymentMethods.cash.value}
                  label="Wypłata w kasie"
                  value={PaymentMethods.cash.value}
                  disabled={this.props.inProgress}
                  control={<Radio />}
                />
                <FormControlLabel
                  key={PaymentMethods.transfer.value}
                  label={PaymentMethods.transfer.name}
                  value={PaymentMethods.transfer.value}
                  disabled={this.props.inProgress}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ height: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button
              variant="contained"
              aria-label="Wypłać"
              onClick={this.handlePayoutFromBalance}
              disabled={!this.state.canCashFromBalance || this.props.inProgress}>
              Wypłać
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

PayoutSection.propTypes = {
  personalBalance: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onPayoutFromBalance: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
  availableFunds: PropTypes.number.isRequired
};

export default PayoutSection;
