import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../actions/attendanceActions';
import * as daysOffActions from '../../actions/daysOffActions';
import ChildrenDetailsSummary from '../common/children/ChildrenDetailsSummary';
import ChildArchiveGuardianList from './ChildArchiveGuardiansList';
import ChildArchiveLegalGuardiansList from './ChildArchiveLegalGuardiansList';
import ChildAttendanceCalendar from '../common/children/attendance/calendar/ChildAttendanceCalendar';
import ChildArchiveFileAttachmentsList from './ChildArchiveFileAttachmentsList';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import { Box, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { ROOT_URL } from '../../api/ApiBase';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';

class ChildArchiveDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.loadAttendanceData = this.loadAttendanceData.bind(this);
    this.handleDownloadFile = this.handleDownloadFile.bind(this);
  }

  loadAttendanceData(date) {
    this.props.attendanceActions.loadChildAttendancesAsync(this.props.pupilId, date);
    this.props.daysOffActions.loadDaysOffAsync(moment(date).format('YYYY'));
  }

  handleDownloadFile(fileId) {
    const path = `${ROOT_URL}/children/archive/${this.props.pupilId}/files/${fileId}`;
    window.open(path, '_blank');
  }

  render() {
    const { pupil } = this.props;

    return (
      <LoadingRenderWrapper>
        <Box>
          <ArchivalInfo
            archivalInfo={pupil.archivalInfo}
            contrast={true}
            sx={{ py: 1, display: 'flex', justifyContent: 'flex-end' }}
          />
          <Paper>
            <ChildrenDetailsSummary pupil={pupil} />
          </Paper>
          <Paper sx={{ my: 2, p: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Obecności
            </Typography>
            <ChildAttendanceCalendar
              allDays={this.props.attendances}
              markedDays={this.props.attendances.entries}
              attendanceSummary={this.props.attendances.summary}
              daysOff={this.props.daysOff}
              onDateChange={(date) => this.loadAttendanceData(date)}
              onDaySelected={() => {}}
              onReportAttendance={() => {}}
              onReportAbsence={() => {}}
              onDetailsOpen={() => {}}
              defaultYear={
                pupil.archivalInfo ? moment(pupil.archivalInfo.archivedOn, 'DD.MM.YYYY').format('YYYY') : undefined
              }
              defaultMonth={
                pupil.archivalInfo ? moment(pupil.archivalInfo.archivedOn, 'DD.MM.YYYY').format('MM') : undefined
              }
              onRevertAbsence={() => {}}
              isReadOnly={true}
              isLoading={this.props.isCalendarLoading}
            />
          </Paper>
          <Paper sx={{ my: 2, p: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Opiekunowie prawni
            </Typography>
            <ChildArchiveLegalGuardiansList guardians={pupil.legalGuardians} />
          </Paper>
          {!this.props.nadarzyn && (
            <Paper sx={{ my: 2, p: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Osoby upoważnione do odbioru dziecka
              </Typography>
              <ChildArchiveGuardianList guardians={pupil.guardians} />
            </Paper>
          )}
          <Paper sx={{ my: 2, p: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Załączniki
            </Typography>
            <ChildArchiveFileAttachmentsList attachments={pupil.attachments} onDownloadFile={this.handleDownloadFile} />
          </Paper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ChildArchiveDetailsPage.propTypes = {
  pupil: PropTypes.object.isRequired,
  pupilId: PropTypes.string.isRequired,
  attendances: PropTypes.object.isRequired,
  daysOff: PropTypes.array.isRequired,
  attendanceActions: PropTypes.object.isRequired,
  daysOffActions: PropTypes.object.isRequired,
  isCalendarLoading: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  const pupilId = ownProps.params.id; //from group/:id path !parameter is always a string!
  return {
    pupilId,
    pupil: state.archives.childDetails,
    attendances: state.childAttendances,
    daysOff: state.configuration.daysOff,
    isCalendarLoading: state.childDetailsUi.isCalendarLoading,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    attendanceActions: bindActionCreators(attendanceActions, dispatch),
    daysOffActions: bindActionCreators(daysOffActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildArchiveDetailsPage);
