import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Tooltip } from '@mui/material';
import SendMessageCard from '../MessageView/SendMessageCard';
import React, { useState } from 'react';
import PersonAvatar from '../../forms/PersonAvatar';
import { AvatarGroup } from '@mui/lab';

const SendMessageDialog = ({
  open,
  isGifSelected,
  onGifSelected,
  onFileSelected,
  isFileSelected,
  participants,
  user,
  onSubmit,
  onCancel,
  files
}) => {
  const [value, setValue] = useState('');

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle sx={{ px: { xs: 1, sm: 3 } }}>Wyślij wiadomość</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', px: { xs: 1, sm: 3 } }}>
        <AvatarGroup max={10} sx={{ mb: 1, alignItems: 'start' }}>
          {participants.map((participant) => (
            <PersonAvatar
              key={participant.id}
              initials
              url={participant.photoUrl}
              firstName={participant.firstName}
              lastName={participant.lastName}
            />
          ))}
        </AvatarGroup>
        <SendMessageCard
          text={value}
          onChange={(e) => {
            setValue(e);
          }}
          onSubmit={() => onSubmit(value)}
          isGifSelected={isGifSelected}
          onGifSelected={onGifSelected}
          onFileSelected={onFileSelected}
          isFileSelected={isFileSelected}
          participants={participants}
          user={user}
          files={files}
          maxHeight={'40vh'}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlinedContrast" aria-label="Anuluj" onClick={onCancel}>
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SendMessageDialog;
