import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import ShareSearchBar from '../ShareSearchBar';
import EmptyState from '../../../../../components/common/EmptyState';
import SharePersonListComponent from '../SharePersonListComponent/SharePersonListComponent';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import { useSelector } from 'react-redux';
import ReducedSharePersonListComponent from '../SharePersonListComponent/ReducedSharePersonListComponent';

const AvailableUsersContent = ({
  areFiltersInUse,
  onSingleAction,
  onMultiAction,
  availableList,
  searchText,
  ...rest
}) => {
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const [forceOpenCollapse, setForceOpenCollapse] = useState(false);
  const isAvailableListEmpty = !availableList.some(
    (item) => (item.users && item.users.length !== 0) || (item.teachers && item.teachers.length !== 0)
  );
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        height: '100%',
        overflowY: 'auto'
      }}>
      <ShareSearchBar
        searchText={searchText}
        onExpandAction={() => {
          setForceOpenCollapse((prev) => !prev);
        }}
        isAvailableListEmpty={isAvailableListEmpty}
        forceOpenCollapse={forceOpenCollapse}
        {...rest}
      />
      {isAvailableListEmpty && areFiltersInUse && (
        <EmptyState message="Brak wyników wyszukiwania dla podanych filtrów" contrast />
      )}
      <Box sx={{ p: 2 }}>
        {isAvailableListEmpty && !areFiltersInUse && (
          <EmptyState icon={<SentimentSatisfiedIcon />} message="Wybrano wszystkie osoby z listy" />
        )}
        {nadarzyn
          ? availableList.map((item, index) => (
              <ReducedSharePersonListComponent
                onSingleAction={onSingleAction}
                onMultiAction={onMultiAction}
                {...item}
                key={index}
                forceOpenCollapse={searchText.length !== 0 || forceOpenCollapse}
              />
            ))
          : availableList.map((item, index) => (
              <SharePersonListComponent
                onSingleAction={onSingleAction}
                onMultiAction={onMultiAction}
                {...item}
                key={index}
                forceOpenCollapse={searchText.length !== 0 || forceOpenCollapse}
              />
            ))}
      </Box>
    </Grid>
  );
};

AvailableUsersContent.propTypes = {
  onMultiAction: PropTypes.func.isRequired,
  onSingleAction: PropTypes.func.isRequired,
  areFiltersInUse: PropTypes.bool,
  availableList: PropTypes.array.isRequired,
  filterBy: PropTypes.string.isRequired,
  groups: PropTypes.array,
  isMobile: PropTypes.bool,
  onClearSearch: PropTypes.func.isRequired,
  onFilterBy: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onResetGroups: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedGroup: PropTypes.string
};

export default AvailableUsersContent;
