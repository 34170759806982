import PropTypes from 'prop-types';
import React from 'react';
import { ChargingSchemeTypes } from '../../../../constants/chargingSchemeTypes';
import HourlySchemeDescription from './HourlySchemeDescription';
import DailySchemeDescription from './DailySchemeDescription';
import MonthlySchemeDescription from './MonthlySchemeDescription';
import moment from 'moment';
import { chargingAbsenceReportingDeadlineDays as DeadlineDays } from '../../../../constants/chargingAbsenceReportingDeadlineDays';
import { Typography } from '@mui/material';

export const DisplayTimeDescription = ({ scheme }) => {
  let localHours = null;
  let localData = null;
  const isDataVisible =
    !!scheme.absenceReportingDeadlineTime &&
    (scheme.absenceReportingDeadlineTime.hourLocal || scheme.absenceReportingDeadlineTime.minuteLocal);
  if (isDataVisible) {
    localHours = moment({
      hour: scheme.absenceReportingDeadlineTime.hourLocal || 0,
      minute: scheme.absenceReportingDeadlineTime.minuteLocal || 0
    }).format('HH:mm');
  }
  if (scheme.absenceReportingDeadlineDays !== null) {
    localData = Object.values(DeadlineDays).find((x) => x.value === scheme.absenceReportingDeadlineDays);
    localData = localData.name;
  }

  return (
    <>
      {isDataVisible && (
        <Typography sx={{ color: (theme) => theme.palette.color.contrast, fontSize: 14 }}>
          Nieobecności można zgłosić do:
          <Typography
            component="span"
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
            {localHours}
            {scheme.absenceReportingDeadlineDays !== null && <> ({localData})</>}
          </Typography>
        </Typography>
      )}
    </>
  );
};

DisplayTimeDescription.propTypes = {
  scheme: PropTypes.object.isRequired
};

const SchemeDescription = ({ scheme }) => {
  if (scheme.schemeType === ChargingSchemeTypes.hourly.value) {
    return <HourlySchemeDescription scheme={scheme} />;
  }
  if (scheme.schemeType === ChargingSchemeTypes.daily.value) {
    return <DailySchemeDescription scheme={scheme} />;
  }
  if (scheme.schemeType === ChargingSchemeTypes.monthly.value) {
    return <MonthlySchemeDescription scheme={scheme} />;
  }
  return null;
};

SchemeDescription.propTypes = {
  scheme: PropTypes.object.isRequired
};

export default SchemeDescription;
