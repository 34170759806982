import React, { useState } from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ManualLink from '../../../ManualLink';
import PinSection from '../Components/PinSection';
import ChangeProfilePhotoForm from '../Components/Form/ChangeProfilePhotoForm';
import { useDispatch, useSelector } from 'react-redux';
import * as userAccountActions from '../../../../actions/userAccountActions';
import Paper from '@mui/material/Paper';
import ChangePasswordFormWrap from '../Components/Form/ChangePasswordFormWrap';
import Grid from '@mui/material/Grid';
import userRoles from '../../../../constants/userRoles';
import PageHeaderText from '../../../common/pageHeader/PageHeaderText';

const ChangePasswordContainer = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const pin = useSelector((state) => state.auth.pin);
  const { profilePhoto } = useSelector((state) => state.auth);
  const isParent = useSelector((state) => state.auth.userRole);
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);

  const onSubmit = ({ password, currentPassword }, { resetForm }) => {
    dispatch(userAccountActions.changePassword({ currentPassword, newPassword: password })).then((e) => {
      if (e) setError(e);
      else {
        setError(null);
        resetForm();
      }
    });
  };

  const onUpdateProfilePhoto = (file) => {
    dispatch(userAccountActions.updateProfilePhoto(file));
  };

  const onRemoveProfilePhoto = () => {
    dispatch(userAccountActions.removeProfilePhoto());
  };

  return (
    <>
      <Grid container justifyContent="space-between" component="header">
        <PageHeaderText title="Profil użytkownika" titleIcon={<AccountBoxIcon />} />
        {!nadarzyn && isParent === userRoles.legalGuardian && <ManualLink index="7" />}
      </Grid>
      <Paper sx={{ p: 2, my: 2 }}>
        <PinSection pin={pin} />
        <ChangeProfilePhotoForm
          onPhotoDrop={onUpdateProfilePhoto}
          photoUrl={profilePhoto}
          onPhotoRemove={onRemoveProfilePhoto}
        />
        <ChangePasswordFormWrap onSubmit={onSubmit} error={error} />
      </Paper>
    </>
  );
};

export default ChangePasswordContainer;
