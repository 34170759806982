import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import ColorPicker from '../../common/ColorPicker';
import { avatarColorPaletteArray } from '../../../constants/avatarColorPalette';
import { create } from '../../../utils/AvatarContentGenerator';
import CustomTextField from '../../common/TextField';

export const MealDetailsForm = ({ meal, onChange, errors }) => {
  return (
    <Box>
      <FieldsetWrapper legend="Pola dodania nowego posiłku">
        <CustomTextField
          variant="standard"
          hintText="Nazwa posiłku"
          floatingLabelText="Nazwa posiłku*"
          floatingLabelFixed={false}
          onChange={onChange}
          autoFocus={true}
          name="name"
          value={meal.name}
          fullWidth={true}
          errorText={errors.name}
        />
        <Box sx={{ display: 'flex', align: 'center', mt: 4 }}>
          <Box sx={{ display: 'flex', align: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="caption">Kolor posiłku</Typography>
            <ColorPicker
              palette={avatarColorPaletteArray}
              defaultColor={meal.color || create().generateColorFromName(meal.name)}
              onChange={(color) => onChange({ target: { name: 'color', value: color } })}
            />
          </Box>
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

MealDetailsForm.propTypes = {
  meal: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object
};
