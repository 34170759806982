import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../forms/PersonAvatar';
import { Box, Divider, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import Strong from '../../common/Strong';

const GuardiansWithoutPinList = ({ guardians }) => {
  if (!guardians.length) return null;
  return (
    <Box>
      <Divider />
      <List>
        {guardians.map((childWithGuardians) =>
          childWithGuardians.guardians.map((guardian) => {
            const childName = `${childWithGuardians.childLastName} ${childWithGuardians.childFirstName}`;
            const guardianName = `${guardian.lastName} ${guardian.firstName}`;
            return (
              <React.Fragment key={guardian.id}>
                <ListItem>
                  <ListItemAvatar>
                    <PersonAvatar
                      initials
                      alt={guardian.firstName}
                      url={guardian.photoUrl}
                      firstName={guardian.firstName}
                      lastName={guardian.lastName}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={guardianName}
                    secondary={
                      <Box>
                        Numer dokumentu opiekuna: <Strong>{guardian.documentNumber}</Strong>, dziecko:{' '}
                        <Strong>{childName}</Strong>
                      </Box>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })
        )}
      </List>
    </Box>
  );
};

GuardiansWithoutPinList.propTypes = {
  guardians: PropTypes.array.isRequired
};

export default GuardiansWithoutPinList;
