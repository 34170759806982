import PropTypes from 'prop-types';
import React from 'react';
import { Input } from '@mui/material';
import dimensions from 'react-dimensions';
import SearchIcon from '@mui/icons-material/Search';
import withStyles from '@mui/styles/withStyles';
import { InputFieldStyles } from '../../constants/ComponentStyles/inputFieldStyles';
import StackRow from './StackRow';
import InputAdornment from '@mui/material/InputAdornment';

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.runFiltering = this.runFiltering.bind(this);
    this.state = {
      keyword: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemsToFilter !== prevProps.itemsToFilter) {
      this.runFiltering(this.props.itemsToFilter, this.state.keyword);
    }
  }

  handleInputChange(event) {
    this.setState(
      {
        keyword: event.target.value.toLowerCase()
      },
      () => this.runFiltering(this.props.itemsToFilter, this.state.keyword)
    );
  }

  runFiltering(items, keyword) {
    const { filter, onFilter, onKeywordChange } = this.props;
    if (onFilter) onFilter(keyword !== null ? items.filter((item) => filter(item, keyword)) : items);
    if (onKeywordChange) onKeywordChange(keyword);
  }

  render() {
    const { initialKeyword, style, endAdornment, sx } = this.props;
    return (
      <StackRow sx={{ ...sx }}>
        <Input
          placeholder="Wyszukaj na liście"
          inputProps={{ 'aria-label': 'Wyszukaj na liście – lista zostanie automatycznie zaktualizowana.' }}
          aria-label="Wyszukaj na liście – lista zostanie automatycznie zaktualizowana."
          id="standard-basic"
          sx={{
            color: (theme) => (this.props.contrast ? theme.palette.text.primary : theme.palette.color.primary),
            ':before': {
              borderBottomColor: (theme) =>
                this.props.contrast ? theme.palette.text.primary : theme.palette.color.primary
            },
            ':after': {
              borderBottomColor: (theme) =>
                this.props.contrast ? theme.palette.text.primary : theme.palette.color.primary
            },
            ...style
          }}
          defaultValue={initialKeyword || ''}
          onChange={this.handleInputChange}
          fullWidth={true}
          endAdornment={endAdornment}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: (theme) => (this.props.contrast ? theme.palette.text.primary : theme.palette.color.primary)
                }}
              />
            </InputAdornment>
          }
        />
      </StackRow>
    );
  }
}

FilterBar.propTypes = {
  itemsToFilter: PropTypes.array.isRequired,
  filter: PropTypes.func,
  onFilter: PropTypes.func,
  onKeywordChange: PropTypes.func,
  initialKeyword: PropTypes.string,
  containerWidth: PropTypes.number,
  classes: PropTypes.any,
  style: PropTypes.object,
  sx: PropTypes.object,
  contrast: PropTypes.bool,
  endAdornment: PropTypes.node
};

export default dimensions({ containerStyle: { width: 'auto' } })(withStyles(InputFieldStyles)(FilterBar));
