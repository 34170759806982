import ApiBase, { ROOT_URL } from './ApiBase';

class ApplicationsApi extends ApiBase {
  static getLegalGuardianApplicationsForChild(childId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/applications/my/${childId}`).type('application/json'));
  }

  static createApplication(application) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/applications`).send(application).type('application/json')
    );
  }

  static updateApplication(id, application) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/applications/${id}`).send(application).type('application/json')
    );
  }

  static archiveApplication(applicationId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/applications/${applicationId}/archive`).type('application/json')
    );
  }

  static getAllApplications() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/applications/`).type('application/json'));
  }

  static approveApplication(applicationId) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/applications/${applicationId}/approve`).type('application/json')
    );
  }

  static rejectApplication(applicationId) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/applications/${applicationId}/reject`).type('application/json')
    );
  }
}

export default ApplicationsApi;
