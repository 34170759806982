import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Pagination,
  Box,
  useMediaQuery,
  List,
  CircularProgress,
  Typography
} from '@mui/material';
import { getComparator, stableSort } from '../../../utils/tableSorting';
import { makeStyles } from '@mui/styles';
import BoardPostCardDialogItem from './BoardPostCardDialogItem';
import BoardPostCardDialogFilter from './BoardPostCardFilter';
import { useTheme } from '@emotion/react';

const useTableStyles = makeStyles((theme) => ({
  root: {
    minWidth: 650
  },
  pagination: {
    background: theme.palette.color.primary
  }
}));

const StaffBoardPostCardDialog = ({ seenBy, setDialogState, dialogState, staffMembers, isLoading }) => {
  const [userFilter, setUserFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const theme = useTheme();
  const filters = { all: '', seen: 'seen', unseen: 'unseen' };
  const cl = useTableStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const handleSetFilter = (value) => {
    setUserFilter(value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRows = (value) => {
    setRowsPerPage(value);
    setPage(1);
  };

  const noUsersFound = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    return <Typography>Brak danych do wyświetlenia</Typography>;
  };

  const postUnseenBy = useMemo(() => {
    if (seenBy) {
      return JSON.parse(JSON.stringify(seenBy.unread));
    }
    return [];
  }, [seenBy]);

  const showUsers = useMemo(() => {
    const seenOrUnseenList = staffMembers.map((element) => ({
      firstName: element.firstName,
      lastName: element.lastName,
      seen: !postUnseenBy.includes(element.id),
      id: element.id,
      photoUrl: element.photoUrl
    }));
    if (userFilter === filters.seen) return seenOrUnseenList.filter((item) => item.seen);
    if (userFilter === filters.unseen) {
      return seenOrUnseenList.filter((item) => !item.seen);
    }
    return seenOrUnseenList;
  });

  return (
    <Dialog
      classes={{ paper: cl.dialogPaper }}
      key="view-dialog"
      id="view-dialog"
      maxWidth="md"
      fullWidth
      onClose={() => setDialogState(false)}
      aria-labelledby="view-dialog-title"
      open={dialogState}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ m: 2, color: theme.palette.grey[600] }}>
        <DialogTitle>Historia wyświetlania</DialogTitle>
        <DialogContentText
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <BoardPostCardDialogFilter
            setFilter={handleSetFilter}
            filter={userFilter}
            rows={rowsPerPage}
            setRows={handleChangeRows}
            setPage={handleChangePage}
          />
        </DialogContentText>
      </Box>
      <DialogContent>
        <List style={isLoading ? { display: 'flex', justifyContent: 'center' } : {}}>
          {dialogState && showUsers.length > 0 && !isLoading
            ? stableSort(showUsers, getComparator('asc', { property: 'lastName', type: 'STRING_SORT' }))
                .slice(page * rowsPerPage, page * rowsPerPage + parseInt(rowsPerPage, 10))
                .map((element) => <BoardPostCardDialogItem key={element.id} element={element} />)
            : noUsersFound()}
        </List>
      </DialogContent>
      {dialogState ? (
        <Pagination
          siblingCount={isMobile ? 0 : 1}
          sx={{ alignSelf: 'center', p: 1 }}
          classes={{ ul: cl.ul }}
          count={Math.ceil(showUsers.length / rowsPerPage)}
          page={page + 1}
          onChange={handleChangePage}
        />
      ) : null}
      {dialogState ? (
        <DialogActions>
          <Button variant="outlinedContrast" onClick={() => setDialogState(false)}>
            Zamknij
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

StaffBoardPostCardDialog.propTypes = {
  seenBy: PropTypes.object,
  setDialogState: PropTypes.func,
  dialogState: PropTypes.bool,
  staffMembers: PropTypes.array,
  isLoading: PropTypes.bool
};

export default StaffBoardPostCardDialog;
