import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import config from '../../../config/config';
import { TableContainer } from '@mui/material';

const ExportTable = ({ duesExports }) => {
  return (
    <TableContainer>
      <Table aria-label="Tabela exportu">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Liczba rozliczeń</TableCell>
            <TableCell>Pobierz</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {duesExports.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{moment(item.exportedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.duesCount}</TableCell>
              <TableCell>
                <a href={`${config.apiUrl}/settlements/exports/${item.id}/download`}>Pobierz(.xlsx)</a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExportTable.propTypes = {
  duesExports: PropTypes.array
};

export default ExportTable;
