import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import { WorkPlanValidationGoalModel } from '../../../../models/digitalDiary/workPlan/WorkPlanValidationModels';
import TextField from '../../../common/TextField';
import { Box } from '@mui/material';

class WorkPlanEducationalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workPlanSetGoal: this.props.workPlanSetGoal,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const newWorkPlanSetGoal = { ...this.state.workPlanSetGoal };
    newWorkPlanSetGoal.goalName = event.target.value;
    this.setState({ workPlanSetGoal: newWorkPlanSetGoal });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing, isEditing } = this.props;
    return (
      <Box>
        <DialogForm
          header={
            isEditing ? 'Edycja zamierzeń wychowawczo-dydaktycznych' : 'Dodaj zamierzenie wychowawczo-dydaktyczne'
          }
          onSave={() => onSave(this.state.workPlanSetGoal)}
          onCancel={onCancel}
          isDialogOpen={isDialogOpen}
          isProcessing={isProcessing}
          onValidate={() => new WorkPlanValidationGoalModel().validate(this.state.workPlanSetGoal)}
          onValidationDone={(errors) => this.setState({ errors })}
          requiredFieldText
          statePathToUi="configuration.attributesSetsUi">
          <TextField
            hintText="Nazwa"
            floatingLabelText="Nazwa*"
            floatingLabelFixed={false}
            autoFocus={true}
            name="name"
            value={this.state.workPlanSetGoal.goalName}
            onChange={this.handleChange}
            fullWidth={true}
            errorText={this.state.errors.goalName}
          />
        </DialogForm>
      </Box>
    );
  }
}

WorkPlanEducationalDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  workPlanSetGoal: PropTypes.object
};

export default WorkPlanEducationalDialog;
