/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { GroupModel } from '../models/groups/GroupModels';

export default function groupsReducer(state = initialState.groups, action) {
  switch (action.type) {
    case types.LOAD_GROUPS_SUCCESS: {
      return action.groups.map((group) => new GroupModel().assign(group));
    }
    case types.CREATE_GROUP_DETAILS_SUCCESS: {
      const group = new GroupModel().assign(action.groupDetails);
      return [...state, group];
    }
    case types.UPDATE_GROUP_DETAILS_SUCCESS: {
      const group = new GroupModel().assign(action.groupDetails);
      return [...state.filter((g) => g.id !== group.id), group];
    }
    case types.ARCHIVE_GROUP_SUCCESS: {
      return [...state.filter((g) => g.id !== action.groupId)];
    }
    default:
      return state;
  }
}
