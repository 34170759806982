import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../../calendar/Grid/CalendarDayContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment';
import { Box, MenuItem } from '@mui/material';
import IconMenu from '../../../menu/IconMenu';
import { useTheme } from '@emotion/react';

const AttendantDayContent = ({
  day,
  containerWidth,
  droppedOffAt,
  pickedUpAt,
  pickedUpBy,
  onReportAttendance,
  onReportAbsence,
  onDetailsOpen,
  isReadOnly,
  isActivityCalendar = false
}) => {
  const theme = useTheme();
  return (
    <CalendarDayContent
      day={day}
      containerWidth={containerWidth}
      primaryText={
        <Box>
          {pickedUpBy ? <Box sx={{ fontSize: 12 }}>{`${pickedUpBy.firstName} ${pickedUpBy.lastName}`}</Box> : null}
          {droppedOffAt && pickedUpAt
            ? moment(droppedOffAt).format('HH:mm') + '-' + moment(pickedUpAt).format('HH:mm')
            : null}
          {}
        </Box>
      }
      primaryIcon={
        <IconMenu
          sx={{ ml: 1 }}
          icon={AccountCircleIcon}
          color={theme.palette.color.color12}
          isReadOnly={isReadOnly}
          dense>
          {(onClose) => [
            !isActivityCalendar ? (
              <MenuItem
                key="1"
                onClick={() => {
                  onClose();
                  onReportAttendance(day);
                }}>
                Zmień godziny obecności
              </MenuItem>
            ) : null,
            <MenuItem
              key="2"
              onClick={() => {
                onClose();
                onReportAbsence(day);
              }}>
              Wycofaj obecność
            </MenuItem>,
            !isActivityCalendar ? (
              <MenuItem
                key="3"
                onClick={() => {
                  onClose();
                  onDetailsOpen(day);
                }}>
                Więcej...
              </MenuItem>
            ) : null
          ]}
        </IconMenu>
      }
    />
  );
};

AttendantDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  droppedOffAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pickedUpAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pickedUpBy: PropTypes.object,
  onReportAttendance: PropTypes.func.isRequired,
  onReportAbsence: PropTypes.func.isRequired,
  onDetailsOpen: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isActivityCalendar: PropTypes.bool
};

export default AttendantDayContent;
