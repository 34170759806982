import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import CardEditForm from '../forms/CardEditForm';
import { MealDetailsForm } from './forms/MealDetailsForm';
import { MealDetailsModelValidator } from './MealDetailsModelValidator';

export const MealDetailsCard = ({ meal, title, handleSave, onCancel, isLoading, onValidate }) => {
  const [errors, setErrors] = useState({});
  const [mealDetails, setMealDetails] = useState(meal);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setMealDetails((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  }, []);

  return (
    <CardEditForm
      onSave={() => handleSave(mealDetails)}
      onCancel={onCancel}
      onValidate={() => new MealDetailsModelValidator().validate(mealDetails)}
      onValidationDone={(errors) => setErrors(errors)}
      isProcessing={isLoading}
      isExpanded={true}
      onExpand={() => {}}
      onCollapse={() => {}}
      title={title}
      statePathToUi="mealDetailsUi"
      editForm={<MealDetailsForm meal={mealDetails} onChange={handleChange} errors={errors} />}
      groupColor={mealDetails.color}
    />
  );
};

MealDetailsCard.propTypes = {
  meal: PropTypes.object,
  title: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
