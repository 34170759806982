import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import { AttributeDefinitionModelValidator } from '../../../../models/configuration/attributes/AttributeDefinitionModelValidator';
import { AttributeDefinitionModel } from '../../../../models/configuration/attributes/AttributeDefinitionModel';
import AttributeForm from './AttributeForm';

class CreateAttributeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: new AttributeDefinitionModel(),
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const attribute = Object.assign({}, this.state.attribute);
    attribute[field] = event.target.value;
    return this.setState({ attribute });
  }

  handleStateClear() {
    const attribute = new AttributeDefinitionModel();
    this.setState({ attribute });
  }

  handleCancel() {
    this.props.onCancel();
    this.handleStateClear();
  }

  handleSave() {
    this.props.onSave(Object.assign({}, this.state.attribute));
    this.handleStateClear();
  }

  render() {
    const { isDialogOpen } = this.props;
    return (
      <DialogForm
        maxWidth="sm"
        header="Dodaj zestaw atrybutów"
        onSave={() => this.handleSave()}
        onCancel={() => this.handleCancel()}
        isDialogOpen={isDialogOpen}
        isProcessing={false}
        onValidate={() => new AttributeDefinitionModelValidator().validate(this.state.attribute)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi=""
        requiredFieldText>
        <AttributeForm attribute={this.state.attribute} onChange={this.handleChange} errors={this.state.errors} />
      </DialogForm>
    );
  }
}

CreateAttributeDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired
};

export default CreateAttributeDialog;
