import React from 'react';
import PropTypes from 'prop-types';
import { fromTemplate, routePaths } from '../../routePaths';
import ChildDetailsHeader from './header/ChildDetailsHeader';
import { connect } from 'react-redux';
import SummaryAttendanceCard from './summary/SummaryCard';

const ChildDetailsSummary = ({ pupil, pupilId }) => {
  return (
    <>
      <ChildDetailsHeader
        name={pupil.firstName + ' ' + pupil.lastName}
        nameLink={fromTemplate(routePaths.childDetails, [pupil.id])}
        group={pupil.enrolledToGroup}
        photoUrl={pupil.photoUrl}
        photoChangeDisabled={true}
        onPhotoDrop={() => {}}
        onPhotoRemove={() => {}}
      />
      <SummaryAttendanceCard childId={pupilId} groupId={pupil.enrolledToGroup && pupil.enrolledToGroup.id} />
    </>
  );
};

ChildDetailsSummary.propTypes = {
  pupilId: PropTypes.string.isRequired,
  pupil: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const pupilId = ownProps.params.id;
  return {
    pupilId,
    pupil: state.childDetails
  };
}

export default connect(mapStateToProps)(ChildDetailsSummary);
