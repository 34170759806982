import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as childrenActions from '../../actions/childrenActions';
import { ChildDetailsModel } from '../../models/children/ChildModels';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ChildDetailsFormRefactored from './details/ChildDetailsFormRefactored';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import { Box } from '@mui/material';

const ChildRegisterPage = () => {
  const pupil = new ChildDetailsModel();
  const dispatch = useDispatch();
  const symphonyIntegration = useSelector(
    (state) => state.configuration.settlementsSettings.symphonyIntegrationEnabled
  );

  const handleChildChangeSubmit = async (childDetails, actions) => {
    try {
      await dispatch(childrenActions.createChildAsync(childDetails));
    } catch (e) {
      Object.entries(e).forEach((element) => {
        if (element[0] === 'declaredPickUpAt') actions.setFieldError('shownDeclaredPickUpAt', element[1]);
        if (element[0] === 'declaredDropOffAt') actions.setFieldError('shownDeclaredDropOffAt', element[1]);
        else actions.setFieldError(element[0], element[1]);
      });
    }
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Dodaj dziecko" titleIcon={<FormatListNumberedIcon />} />
        <ContentWrapper>
          <ChildDetailsFormRefactored
            pupil={pupil}
            onSubmit={handleChildChangeSubmit}
            symphony={symphonyIntegration}
            isInitiallyExpanded={true}
          />
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default ChildRegisterPage;
