import BaseModel from '../BaseModel';

export class ScheduledTransferModel extends BaseModel {
  constructor(id = null) {
    super();
    this.id = id;
    this.childId = '';
    this.firstName = '';
    this.lastName = '';
    this.date = null;
    this.type = null;
    this.from = null;
    this.to = null;
    this.archiveChild = false;
    this.archiveLegalGuardians = false;
    this.avatarUrl = null;
    this.status = 'pending';
  }
}
