import React from 'react';
import PropTypes from 'prop-types';
import PasswordExpiredFormWrap from '../Components/Forms/PasswordExpiredFormWrap';
import * as authActions from '../../../../actions/userAccountActions';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

const PasswordExpiredContainer = ({ location }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react/prop-types
  const onSubmit = ({ password }) =>
    dispatch(authActions.resetPassword({ token: location.query.token, newPassword: password }));

  return (
    <>
      <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
        Witaj! Ze względów bezpieczeństwa informacji Twoje hasło musi zostać zmienione
      </Typography>

      <PasswordExpiredFormWrap onSubmit={onSubmit} />
    </>
  );
};

PasswordExpiredContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default PasswordExpiredContainer;
