import PropTypes from 'prop-types';
import React from 'react';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';

const PinSection = ({ pin }) => {
  if (!pin) return null;
  return (
    <Box>
      <Typography variant="h6">Twój pin</Typography>
      <Box sx={{ my: 1 }}>{pin}</Box>
      <Divider />
    </Box>
  );
};

PinSection.propTypes = {
  pin: PropTypes.string
};

export default PinSection;
