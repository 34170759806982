import duesApi from '../api/GuardianDuesApi';
import paymentsApi from '../api/PaymentsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as reportsActions from './reportsActions';
import * as logger from '../utils/logger';

export function loadDues(year) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_DUES });
    return duesApi
      .getDues(year)
      .then((dues) => dispatch({ type: types.LOAD_DUES_SUCCESS, dues }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy opłat'));
        return logger.error(error);
      });
  };
}

export function loadDueDetailsAsync(id) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_DUE_DETAILS });
    return duesApi
      .getDue(id)
      .then((dueDetails) => dispatch({ type: types.LOAD_DUE_DETAILS_SUCCESS, dueDetails }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać szczegółów opłaty '${id}'`));
        return logger.error(error);
      });
  };
}

export function pay(dueIds) {
  return (dispatch) => {
    dispatch({ type: types.PAY_FOR });
    return paymentsApi
      .createOnlinePayment(dueIds)
      .then((paymentServiceUrl) => {
        dispatch({ type: types.PAY_FOR_SUCCESS });
        paymentsApi.postAsFormToGateway(paymentServiceUrl);
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się rozpocząć procesu płatności'));
        return logger.error(error);
      });
  };
}

export function print(dueId, saveAs) {
  return (dispatch) => {
    dispatch(reportsActions.generatePaidDueReportAsync(dueId, saveAs));
  };
}
