import Box from '@mui/material/Box';
import PersonAvatar from '../../forms/PersonAvatar';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Badge, Tooltip } from '@mui/material';
import React from 'react';

const DeleteAvatar = ({ title, avatarStyles, url, firstName, lastName, onRemove }) => {
  return (
    <Tooltip arrow title={title}>
      <Badge
        sx={{ cursor: 'pointer', p: 0 }}
        onClick={onRemove}
        badgeContent={
          <ClearIcon
            sx={{
              p: 0,
              color: (theme) => theme.palette.color.primary,
              fontSize: 14,
              fontWeight: (theme) => theme.typography.fontWeightBlack
            }}
          />
        }>
        <PersonAvatar sx={avatarStyles} url={url} lastName={lastName} firstName={firstName} initials />
      </Badge>
    </Tooltip>
  );
};
export default DeleteAvatar;
