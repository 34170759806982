import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../common/calendar/Grid/CalendarDayContent';
import DayOffDayContent from './DayOffDayContent';
import momentBusiness from 'moment-business-days';
import moment from 'moment';
import { Grid, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';
import Calendar from '../../common/calendar/Calendar';
import CalendarDay from '../../common/calendar/Grid/CalendarDay';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import { Box } from '@mui/system';

const AttendanceGroupHistoryCalendar = ({ date, daysOff, onDateChange, defaultMonth, defaultYear, onSelected }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const renderDayContent = (day, containerWidth, data) => {
    if (day.isWeekend || !day.isCurrentMonth) {
      return <CalendarDayContent day={day} containerWidth={containerWidth} />;
    }
    if (data.isDayOff) {
      return <DayOffDayContent day={day} containerWidth={containerWidth} dayOffName={data.dayOffName} />;
    }
    return <CalendarDayContent day={day} containerWidth={containerWidth} />;
  };

  const renderWorkingDaysSummary = () => {
    const daysOffCount = daysOff
      ? daysOff
          .filter((d) => moment(d.date).isSame(date, 'month'))
          .filter((d) => momentBusiness(d.date).isBusinessDay()).length
      : 0;
    const workingDays = momentBusiness(date).monthBusinessDays();

    const workingDaysCount = workingDays.length - daysOffCount;

    return (
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: 'center'
        }}>
        <Grid item>
          <Box sx={{ ml: 4 }}>
            <YearAndMonthOptionsSlider
              contrast
              onSelected={onSelected}
              defaultMonth={defaultMonth}
              defaultYear={defaultYear}
            />
          </Box>
        </Grid>
        <Grid item sx={{ display: 'flex', px: 1 }}>
          <Typography>Dni pracujących:</Typography>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mx: 1 }}>
            {workingDaysCount}
          </Typography>
          <Typography>Dni wolnych:</Typography>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ml: 1 }}>{daysOffCount}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper sx={{ p: 2 }}>
      {renderWorkingDaysSummary()}
      <Calendar
        weekOffset={0}
        date={date}
        markedDays={daysOff.map((d) => {
          return { date: d.date, data: { isDayOff: true, dayOffName: d.name } };
        })}
        onDayRender={(day, contentRender, onDaySelected, containerWidth, isDisabled) => (
          <CalendarDay
            isDisabled={day.data.isDayOff || isDisabled}
            day={day}
            onDaySelected={onDaySelected}
            contentRender={contentRender}
            containerWidth={containerWidth}
          />
        )}
        onDayContentRender={(day, containerWidth) => renderDayContent(day, containerWidth, day.data)}
        onDaySelected={(day) => onDateChange(day.date)}
      />
    </Paper>
  );
};

AttendanceGroupHistoryCalendar.propTypes = {
  date: PropTypes.object.isRequired,
  daysOff: PropTypes.array.isRequired,
  markedDays: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  defaultMonth: PropTypes.string.isRequired,
  defaultYear: PropTypes.string.isRequired
};

export default AttendanceGroupHistoryCalendar;
