import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as childrenActions from '../../../actions/childrenActions';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import ActionButton from '../../forms/buttons/ActionButton';
import ChildChargingSchemeForm from '../../common/chargingSchemes/ChargingSchemeForm';
import ChildChargingSchemesList from '../../common/chargingSchemes/ChargingSchemesList';
import { ChildChargingScheme } from '../../../models/children/ChildModels';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import EmptyState from '../../common/EmptyState';
import OpeningBalanceDialog from '../openingBalance/OpeningBalanceDialog';
import DebtCollectionConfirmationDialog from '../debtCollection/DebtCollectionConfirmationDialog';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../routePaths';
import { ChildChargingSchemeValidator } from '../../../models/configuration/chargingSchemes/ChargingSchemeModelsValidators';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import RemoveTakenOverByDebtCollectionChildrenConfirmationDialog from '../debtCollection/RemoveTakenOverByDebtCollectionChildrenConfirmationDialog';

const ChildChargingSchemesCard = (props) => {
  const [activeScheme, setActiveScheme] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [errors, setErrors] = useState({});
  const allowDebtCollection = useSelector((state) => state.configuration.unit.allowDebtCollection);

  const getAvailableChargingSchemes = () => {
    const hasStaySchemeAssigned = props.childChargingSchemes.some((cs) =>
      props.allChargingSchemes.some(
        (scheme) => scheme.id === cs.schemeId && scheme.reportType === ChargingReportTypes.stay.value
      )
    );

    return props.allChargingSchemes
      .filter((scheme) => !props.childChargingSchemes.some((cs) => scheme.id === cs.schemeId))
      .filter((scheme) => scheme.reportType !== ChargingReportTypes.stay.value || !hasStaySchemeAssigned);
  };

  const handleDisplayOpeningBalance = () => {
    props.childrenActions.updateOpeningBalanceStart();
  };

  const handleDisplayDebtCollectionConfirmation = () => {
    props.childrenActions.debtCollectionConfirmationStart();
  };

  const handleDisplayRemoveDebtCollectionConfirmation = () => {
    props.childrenActions.removeTakenOverByDebtCollectionChildrenStart();
  };

  const handleAssignScheme = () => {
    const availableChargingSchemes = getAvailableChargingSchemes();
    const defaultScheme = new ChildChargingScheme();
    if (availableChargingSchemes.length > 0) {
      defaultScheme.schemeId = _.first(availableChargingSchemes).id;
    }
    setActiveScheme(defaultScheme);
    setIsNew(true);
    props.onCreate();
  };

  const handleEditScheme = (scheme) => {
    setActiveScheme(new ChildChargingScheme().assign(scheme));
    setIsNew(false);
    props.onEdit();
  };

  const handleSchemeChange = (schemeId) => {
    const { id, absenceReportingDeadlineTime, absenceReportingDeadlineDays } = props.allChargingSchemes.find(
      (x) => x.id === schemeId
    );
    const { reliefId, reliefExpiryDate, singleUse, grantHoursAmount, grantHourlyRate } = activeScheme;
    setActiveScheme(
      new ChildChargingScheme().assign({
        schemeId: id,
        absenceReportingDeadlineTime,
        absenceReportingDeadlineDays,
        reliefId,
        reliefExpiryDate,
        singleUse,
        grantHoursAmount,
        grantHourlyRate
      })
    );
  };

  const handleChange = (event) => {
    setActiveScheme({ ...activeScheme, [event.target.name]: event.target.value });
  };

  const renderDetailsSummary = () => {
    return (
      <ActionButton
        key="0"
        actionLabel="Ewidencja opłat"
        onAction={() => browserHistory.push(fromTemplate(routePaths.childSummary, [props.pupil.id]))}
        inProgress={props.isProcessing}
      />
    );
  };

  const renderOpenOpeningBalanceDialogButton = () => {
    return (
      <ActionButton
        key="1"
        onAction={handleDisplayOpeningBalance}
        actionLabel="Bilans otwarcia"
        inProgress={props.isProcessing}
      />
    );
  };

  const renderOpenFinancialDetailsButton = () => {
    return (
      <ActionButton
        key="2"
        actionLabel="Rozliczenie nadpłat"
        onAction={() => browserHistory.push(fromTemplate(routePaths.childFinancialDetails, [props.pupil.id]))}
        inProgress={props.isProcessing}
      />
    );
  };

  const renderDebtCollectionConfirmationDialogButton = () => {
    if (!allowDebtCollection) return null;
    const isTakenOverByDebtCollection = props.pupil.takenOverByDebtCollection;
    return (
      <ActionButton
        key="3"
        actionLabel={isTakenOverByDebtCollection ? 'Anulowanie statusu windykacji' : 'Potwierdzenie windykacji'}
        onAction={
          isTakenOverByDebtCollection
            ? handleDisplayRemoveDebtCollectionConfirmation
            : handleDisplayDebtCollectionConfirmation
        }
      />
    );
  };

  const renderOpeningBalanceDialog = () => {
    return <OpeningBalanceDialog />;
  };

  const renderDebtCollectionConfirmationDialog = () => {
    return <DebtCollectionConfirmationDialog />;
  };

  const renderRemoveTakenOverByDebtCollectionConfirmationDialog = () => {
    return <RemoveTakenOverByDebtCollectionChildrenConfirmationDialog />;
  };

  const renderEditForm = () => {
    return (
      <ChildChargingSchemeForm
        isEmployee={false}
        scheme={activeScheme}
        isNew={isNew}
        onSchemeChange={handleSchemeChange}
        onChange={handleChange}
        errors={errors}
        chargingSchemes={props.allChargingSchemes}
        reliefs={props.allReliefs}
        availableChargingSchemes={getAvailableChargingSchemes()}
      />
    );
  };

  const renderReadonlyForm = () => {
    return (
      <ChildChargingSchemesList
        allChargingSchemes={props.allChargingSchemes}
        allReliefs={props.allReliefs}
        assignedChargingSchemes={props.childChargingSchemes}
        onEdit={handleEditScheme}
        onRemove={props.onRemove}
      />
    );
  };

  return (
    <Box>
      <CardDialogEditForm
        maxWidth="md"
        // ref="cardEditForm"
        title="Opłaty"
        isInitiallyExpanded={props.isInitiallyExpanded}
        onSave={() => props.onSave(activeScheme)}
        onCancel={() => props.onCancel(activeScheme)}
        onAction={handleAssignScheme}
        onValidate={() => new ChildChargingSchemeValidator().validate(activeScheme)}
        onValidationDone={(err) => setErrors(err)}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        statePathToUi="childDetailsUi.chargingSchemes"
        actionLabel="Przypisz schemat płatności"
        actionTitle="Schemat płatności"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Brak przypisanych opłat" contrast />}
        editForm={renderEditForm()}
        isEmpty={props.childChargingSchemes.length === 0}
        customActions={[
          renderDetailsSummary(),
          renderOpenOpeningBalanceDialogButton(),
          renderOpenFinancialDetailsButton(),
          renderDebtCollectionConfirmationDialogButton()
        ]}
        saveDisabled={getAvailableChargingSchemes().length === 0 && isNew}
      />
      {renderOpeningBalanceDialog()}
      {renderDebtCollectionConfirmationDialog()}
      {renderRemoveTakenOverByDebtCollectionConfirmationDialog()}
    </Box>
  );
};

ChildChargingSchemesCard.propTypes = {
  allReliefs: PropTypes.array.isRequired,
  allChargingSchemes: PropTypes.array.isRequired,
  childChargingSchemes: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  childrenActions: PropTypes.object.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
  pupil: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return Object.assign({}, ownProps);
}

function mapDispatchToProps(dispatch) {
  return {
    childrenActions: bindActionCreators(childrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildChargingSchemesCard);
