import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import * as settlementsActions from '../../actions/settlementsActions';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import RecalculateIcon from '@mui/icons-material/Refresh';
import ApproveMultipleIcon from '@mui/icons-material/DoneAll';
import GetPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettlementsList from './SettlementsList';
import SettlementsStatistics from './SettlementsStatistics';
import CalculateStaffSettlementsDialog from './CalculateStaffSettlementsDialog';
import SettlementsFilters from './filters/StaffSettlementsFilters';
import SettlementDetailsDialog from './details/SettlementDetailsDialog';
import RegisterStaffMemberPaymentDialog from './payments/RegisterStaffMemberPaymentDialog';
import RegisterRebateDialog from './payments/RegisterRebateDialog';
import ApproveDuesDialog from './details/ApproveDuesDialog';
import FilterBar from '../common/FilterBar';
import YearAndMonthOptionsSlider from '../common/calendar/YearAndMonthOptionsSlider';
import _ from 'lodash';
import { routePaths, fromTemplate } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import numberFormatter from '../../utils/numberFormatter';
import { Alert, Box, Button, Grid, Typography, Paper, Stack } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';

class StaffSettlementsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentSettlement: null,
      selectedStatus: null,
      filterKeyword: '',
      filteredItems: props.settlements,
      selectedItemsIds: [],
      duesToApprove: [],
      isAlert: true
    };
    this.onDateChanged = this.onDateChanged.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleFilterKeywordChange = this.handleFilterKeywordChange.bind(this);
    this.applyFilters = this.applyFilters.bind(this);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.handleCalculateStart = this.handleCalculateStart.bind(this);
    this.handleCalculateCancel = this.handleCalculateCancel.bind(this);
    this.handleCalculate = this.handleCalculate.bind(this);

    this.handlePrint = this.handlePrint.bind(this);

    this.handleApproveStart = this.handleApproveStart.bind(this);
    this.handleApproveCancel = this.handleApproveCancel.bind(this);
    this.handleApprove = this.handleApprove.bind(this);

    this.handleDueCancel = this.handleDueCancel.bind(this);

    this.handleRegisterPaymentsStart = this.handleRegisterPaymentsStart.bind(this);
    this.handleRegisterPaymentsCancel = this.handleRegisterPaymentsCancel.bind(this);
    this.handleRegisterPayments = this.handleRegisterPayments.bind(this);

    this.handleRegisterRebateStart = this.handleRegisterRebateStart.bind(this);
    this.handleRegisterRebateCancel = this.handleRegisterRebateCancel.bind(this);
    this.handleRegisterRebate = this.handleRegisterRebate.bind(this);

    this.handleUndoPayment = this.handleUndoPayment.bind(this);
    this.handleRemoveRebate = this.handleRemoveRebate.bind(this);

    this.handleDuesDelete = this.handleDuesDelete.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleCancelDelayInterest = this.handleCancelDelayInterest.bind(this);

    this.handleReserveBalanceFunds = this.handleReserveBalanceFunds.bind(this);
    this.handleRemoveReservedFunds = this.handleRemoveReservedFunds.bind(this);
    this.handleCancelOnlinePayment = this.handleCancelOnlinePayment.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settlements !== this.props.settlements) {
      this.applyFilters(this.props.settlements);
    }
  }

  onDateChanged(year, month) {
    browserHistory.push(fromTemplate(routePaths.staffMembersSettlements, [year, month]));
  }

  getSettlements() {
    return this.props.settlements;
  }

  handleStatusChange(value) {
    this.setState({ selectedStatus: value }, () => this.applyFilters());
  }

  handleCancelDelayInterest(settlement) {
    return this.props.actions.cancelSettlementDelayInterestsAsync(settlement);
  }

  handleFilterKeywordChange(keyword) {
    this.setState({ filterKeyword: keyword }, () => this.applyFilters());
  }

  applyFilters(settlements) {
    const filteredItems = _.orderBy(
      settlements || this.props.settlements,
      ['calculationStatus', 'person.lastName', 'person.firstName', 'dueType'],
      ['desc']
    )
      .filter((s) => !this.state.selectedStatus || this.state.selectedStatus === s.calculationStatus)
      .filter(
        (s) =>
          !this.state.filterKeyword ||
          s.person.firstName.toLowerCase().includes(this.state.filterKeyword) ||
          s.person.lastName.toLowerCase().includes(this.state.filterKeyword)
      );

    this.setState({ selectedItemsIds: [], filteredItems });
  }

  handleOpen(settlement) {
    this.setState({ selectedItemsIds: [], currentSettlement: settlement });
    this.props.actions.updateSettlementStart();
  }

  handleSave(settlement) {
    this.props.actions.updateSettlementAsync(settlement);
  }

  handleCancel() {
    this.props.actions.updateSettlementCancel();
    this.setState({ currentSettlement: null });
  }

  handleCalculateStart() {
    this.props.actions.calculateSettlementsStart();
  }

  handleCalculateCancel() {
    this.props.actions.calculateSettlementsCancel();
  }

  handleCalculate(params) {
    this.props.actions.calculateStaffSettlementsAsync(this.props.year, this.props.month, params);
  }

  handlePrint(duesIds) {
    this.props.actions.print(duesIds);
    this.setState({ selectedItemsIds: [] });
  }

  handleApproveStart(duesIds) {
    this.setState({ duesToApprove: duesIds });
    this.props.actions.approveDuesStart();
  }

  handleApproveCancel() {
    this.props.actions.approveDuesCancel();
  }

  handleApprove(approveDetails) {
    this.props.actions.approveStaffDuesAsync(this.props.year, this.props.month, approveDetails);
  }

  handleDueCancel(settlement) {
    this.props.actions.cancelSettlementAsync(this.props.year, this.props.month, settlement);
  }

  handleDuesDelete(settlementIds) {
    this.props.actions.deleteStaffSettlementsAsync(this.props.year, this.props.month, settlementIds);
  }

  handleRegisterPaymentsStart(settlement) {
    this.setState({ selectedItemsIds: [], currentSettlement: settlement });
    this.props.actions.registerPaymentsStart();
  }

  handleRegisterPaymentsCancel() {
    this.props.actions.registerPaymentsCancel();
    this.setState({ currentSettlement: null });
  }

  handleRegisterPayments(paymentDetails) {
    this.props.actions.registerStaffPaymentsAsync(
      paymentDetails,
      this.state.currentSettlement.person,
      this.props.year,
      this.props.month
    );
  }

  handleRegisterRebateStart(settlement) {
    this.setState({ selectedItemsIds: [], currentSettlement: settlement });
    this.props.actions.registerRebateStart();
  }

  handleRegisterRebateCancel() {
    this.props.actions.registerRebateCancel();
    this.setState({ currentSettlement: null });
  }

  handleRegisterRebate(rebateDetails) {
    this.props.actions.registerRebateAsync(rebateDetails, this.state.currentSettlement);
  }

  handleUndoPayment(settlement, paymentId) {
    this.props.actions.undoPaymentAsync(settlement, paymentId || null);
    this.handleCancel();
  }

  handleRemoveRebate(settlement) {
    this.props.actions.removeRebateAsync(settlement);
  }

  canBulkDelete() {
    if (this.state.selectedItemsIds.length === 0) return false;
    return _.every(
      this.state.selectedItemsIds.map((id) => this.props.settlements.find((s) => s.id === id)?.canBeDeleted())
    );
  }

  handleSelect(selection) {
    this.setState((prevState) => {
      let selectedItemsIds = [...prevState.selectedItemsIds];
      if (selection === 'all') {
        selectedItemsIds = prevState.filteredItems.map((i) => i.id);
      } else if (selection === 'none') {
        selectedItemsIds = [];
      } else if (selection && selection.id) {
        if (selectedItemsIds.some((i) => i === selection.id)) {
          _.remove(selectedItemsIds, (i) => i === selection.id);
        } else {
          selectedItemsIds.push(selection.id);
        }
      }

      return { ...prevState, selectedItemsIds };
    });
  }

  handleReserveBalanceFunds(settlement) {
    return this.props.actions.reserveBalanceFunds(settlement);
  }

  handleRemoveReservedFunds(settlement) {
    return this.props.actions.removeReservedFunds(settlement);
  }

  handleCancelOnlinePayment(settlement, onlinePaymentId) {
    this.props.actions.cancelOnlinePaymentAsync(settlement, onlinePaymentId).finally(() => this.handleCancel());
  }

  renderFilters() {
    return (
      <SettlementsFilters selectedStatus={this.state.selectedStatus} handleStatusChange={this.handleStatusChange} />
    );
  }

  renderList() {
    if (this.getSettlements().length > 0) {
      return (
        <SettlementsList
          isChildrenSettlementsList={false}
          settlements={this.state.filteredItems}
          selectedSettlementsIds={this.state.selectedItemsIds}
          onChoose={this.handleOpen}
          onSelect={this.handleSelect}
          onCancel={this.handleDueCancel}
          onApprove={this.handleApproveStart}
          onDelete={this.handleDuesDelete}
          onRegisterPayment={this.handleRegisterPaymentsStart}
          onRegisterRebate={this.handleRegisterRebateStart}
          onRemoveRebate={this.handleRemoveRebate}
          onPrint={this.handlePrint}
          personLabel="Pracownik"
          onCalculateCorrectiveDues={null}
        />
      );
    }
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <EmptyState message="Nie wygenerowano jeszcze opłat za wybrany okres" contrast />
        <Button variant="contained" aria-label="Wygeneruj rozliczenia" onClick={this.handleCalculateStart}>
          Wygeneruj rozliczenia
        </Button>
      </Box>
    );
  }

  renderBulkActions() {
    if (this.state.selectedItemsIds.length === 0) {
      return this.renderActionsToolbar();
    }
    return (
      <Grid container columnSpacing={4}>
        <Grid item container xs={12} xl={4}>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: { xs: 'center', xl: 'start' }, alignItems: 'center' }}>
            <Avatar
              size={20}
              sx={{ display: 'flex', alignSelf: 'center', mr: 2, color: (theme) => theme.palette.color.contrast }}
              key="avatar">
              {this.state.selectedItemsIds.length}
            </Avatar>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: { xs: 'center', xl: 'start' }, alignItems: 'center' }}>
            <Typography sx={{ mr: 1, display: 'flex', color: (theme) => theme.palette.color.primary, fontSize: 16 }}>
              opłaty:
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary,
                fontSize: 16
              }}>
              {numberFormatter.format(
                this.props.settlements
                  .filter((s) => this.state.selectedItemsIds.some((id) => id === s.id))
                  .map((s) => s.getTotalAmount())
                  .reduce((a, b) => a + b, 0)
              )}
              zł
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} xl={8}>
          <Grid
            item
            xs={6}
            xl={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', xl: 'start' }, alignItems: 'center' }}>
            <Button
              aria-label="Zatwierdź"
              key="approve-button"
              sx={{ fontSize: 16, width: 150, color: (theme) => theme.palette.color.primary }}
              disabled={this.state.selectedItemsIds.length === 0}
              onClick={() => this.handleApproveStart(this.state.selectedItemsIds)}
              startIcon={<ApproveMultipleIcon />}>
              Zatwierdź
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            xl={3}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', xl: 'start' },
              alignItems: 'center',
              color: (theme) => theme.palette.color.primary
            }}>
            <ActionConfirmationButton
              actionLabel="Usuń"
              sx={{ fontSize: 16, color: (theme) => theme.palette.color.primary }}
              confirmationText="Czy na pewno chcesz usunąć wybrane opłaty? Tej operacji nie można cofnąć."
              confirmationTitle="Usuwanie opłat"
              isDisabled={!this.canBulkDelete()}
              onConfirm={() => this.handleDuesDelete(this.state.selectedItemsIds)}
              actionIcon={<DeleteIcon />}
              key="delete-button"
            />
          </Grid>
          <Grid
            item
            xs={6}
            xl={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', xl: 'start' }, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Button
                sx={{ width: 175, color: (theme) => theme.palette.color.primary, whiteSpace: 'nowrap' }}
                aria-label="Pobierz wydruki(.pdf)"
                key="print-button"
                disabled={this.state.selectedItemsIds.length === 0}
                onClick={() => this.handlePrint(this.state.selectedItemsIds)}
                startIcon={<GetPdfIcon />}>
                Pobierz wydruki(.pdf)
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            xl={3}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', xl: 'start' },
              alignItems: 'center',
              color: (theme) => theme.palette.color.primary
            }}>
            {this.renderActionsToolbar()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderActionsToolbar() {
    return (
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, alignItems: 'center' }}>
        <Button
          sx={{ width: 150, color: (theme) => theme.palette.color.primary, whiteSpace: 'nowrap' }}
          disabled={this.getSettlements().length === 0}
          onClick={this.handleCalculateStart}
          aria-label="Przelicz opłaty"
          variant="contained">
          <RecalculateIcon />
          Przelicz opłaty
        </Button>
      </Box>
    );
  }

  renderDetailsDialog() {
    if (!this.props.detailsUi.isEditing) {
      return null;
    }
    return (
      <SettlementDetailsDialog
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        isDialogOpen={this.props.detailsUi.isEditing}
        isProcessing={this.props.detailsUi.isProcessing}
        settlement={this.state.currentSettlement}
        undoPayment={this.handleUndoPayment}
        cancelDelayInterest={this.handleCancelDelayInterest}
        cancelOnlinePayment={this.handleCancelOnlinePayment}
        reserveBalanceFunds={this.handleReserveBalanceFunds}
        removeReservedFunds={this.handleRemoveReservedFunds}
      />
    );
  }

  renderCalculateSettlementsDialog() {
    if (!this.props.calculationUi.isEditing) {
      return null;
    }
    return (
      <CalculateStaffSettlementsDialog
        onSave={this.handleCalculate}
        onCancel={this.handleCalculateCancel}
        isDialogOpen={this.props.calculationUi.isEditing}
        isProcessing={this.props.calculationUi.isProcessing}
      />
    );
  }

  renderRegisterPaymentDialog() {
    if (!this.props.registerPaymentsUi.isEditing) {
      return null;
    }
    return (
      <RegisterStaffMemberPaymentDialog
        onSave={this.handleRegisterPayments}
        onCancel={this.handleRegisterPaymentsCancel}
        isDialogOpen={this.props.registerPaymentsUi.isEditing}
        isProcessing={this.props.registerPaymentsUi.isProcessing}
        settlement={this.state.currentSettlement}
      />
    );
  }

  renderRegisterRebateDialog() {
    if (!this.props.registerRebateUi.isEditing) {
      return null;
    }
    return (
      <RegisterRebateDialog
        onSave={this.handleRegisterRebate}
        onCancel={this.handleRegisterRebateCancel}
        isDialogOpen={this.props.registerRebateUi.isEditing}
        isProcessing={this.props.registerRebateUi.isProcessing}
        settlement={this.state.currentSettlement}
      />
    );
  }

  renderApproveDuesDialog() {
    if (!this.props.approveDuesUi.isEditing) {
      return null;
    }
    return (
      <ApproveDuesDialog
        isEmployee={true}
        onSave={this.handleApprove}
        onCancel={this.handleApproveCancel}
        isDialogOpen={this.props.approveDuesUi.isEditing}
        isProcessing={this.props.approveDuesUi.isProcessing}
        dueCalculationsIds={this.state.duesToApprove}
      />
    );
  }

  renderAvailableFundsInfo() {
    if (!this.state.filteredItems.some((due) => due.canBePaidWithBalanceFunds())) {
      return null;
    }
    return (
      <>
        {this.state.isAlert ? (
          <Alert
            sx={{ my: 2 }}
            onClose={() => {
              this.setState({ isAlert: false });
            }}
            severity="info">
            Podświetlone opłaty można rozliczyć za pomocą dostępnych środków
          </Alert>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Rozliczenia pracowników" titleIcon={<PermContactCalendarOutlinedIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}>
              <YearAndMonthOptionsSlider
                onSelected={this.onDateChanged}
                defaultMonth={this.props.month}
                defaultYear={this.props.year}
              />
            </Box>
            <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }} spacing={2}>
              <SettlementsStatistics
                settlements={this.getSettlements()}
                sx={{ justifyContent: { xs: 'center', sm: 'start' } }}
              />
              {this.renderFilters()}
            </Stack>

            {this.renderBulkActions()}
            <FilterBar
              itemsToFilter={this.props.settlements}
              onKeywordChange={this.handleFilterKeywordChange}
              sx={{ my: 3 }}
            />

            <Paper sx={{ mt: 2 }} zdepth={1}>
              {this.renderList()}
            </Paper>
            {this.renderCalculateSettlementsDialog()}
            {this.renderDetailsDialog()}
            {this.renderRegisterPaymentDialog()}
            {this.renderRegisterRebateDialog()}
            {this.renderApproveDuesDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

StaffSettlementsPage.propTypes = {
  settlements: PropTypes.array.isRequired,
  detailsUi: PropTypes.object.isRequired,
  calculationUi: PropTypes.object.isRequired,
  registerPaymentsUi: PropTypes.object.isRequired,
  registerRebateUi: PropTypes.object.isRequired,
  approveDuesUi: PropTypes.object.isRequired,
  listUi: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  currentSettlement: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    settlements: state.settlements,
    detailsUi: state.settlementsUi,
    calculationUi: state.settlementsCalculationUi,
    registerPaymentsUi: state.registerPaymentsUi,
    registerRebateUi: state.registerRebateUi,
    approveDuesUi: state.approveDuesUi,
    listUi: state.settlementsListUi,
    year: ownProps.params.year,
    month: ownProps.params.month,
    isLoading: state.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffSettlementsPage);
