import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import PayoutSection from './PayoutSection';
import BalanceOperationsTable from './BalanceOperationsTable';
import { MenuItem, Box, Typography, Paper, Select } from '@mui/material';
import DepositSection from './DepositSection';
import { useDispatch } from 'react-redux';
import * as notificationsActions from '../../../actions/notificationActions';

const walletConfig = [
  {
    id: 0,
    name: 'general',
    label: 'Portfel ogólny'
  },
  {
    id: 1,
    name: 'stay',
    label: 'Pobyt'
  },
  {
    id: 2,
    name: 'catering',
    label: 'Wyżywienie'
  },
  {
    id: 3,
    name: 'other',
    label: 'Inne'
  },
  {
    id: 4,
    name: 'activities',
    label: 'Zajęcia dodatkowe'
  },
  {
    id: 5,
    name: 'meals',
    label: 'Posiłki'
  }
];

const FinancialDetailsCard = ({ isStaffMember = false, ...props }) => {
  const [actionInProgress, setActionInProgress] = useState(false);
  const [walletType, setWalletType] = useState(walletConfig[0].name);
  const [operationsToDisplay, setOperationsToDisplay] = useState(props.personalBalance);

  const dispatch = useDispatch();

  const handleWalletChange = (type) => {
    setWalletType(type);
  };

  const handlePayoutFromBalance = async (amountToCash, paymentMethod) => {
    setActionInProgress(true);
    try {
      await props.onPayoutFromBalance(amountToCash, paymentMethod, walletType);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await props.onLoadBalance();
    } catch (error) {
      dispatch(notificationsActions.showError('Nie udało się zarejestrować transakcji'));
    }
    setActionInProgress(false);
  };

  const handleDeposit = async (paymentDetails) => {
    setActionInProgress(true);
    try {
      await props.onDeposit(paymentDetails, props.child);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await props.onLoadBalance();
    } catch (error) {
      dispatch(notificationsActions.showError('Nie udało się zarejestrować transakcji'));
    }
    setActionInProgress(false);
  };

  const handleTransferToWallet = async (from, target, amount) => {
    setActionInProgress(true);
    try {
      props.onWalletTransfer(from, target, amount);
      await new Promise((resolve) => setTimeout(resolve, 1500));
      await props.onLoadBalance();
    } catch (error) {
      dispatch(notificationsActions.showError('Nie udało się zarejestrować transakcji'));
    }
    setActionInProgress(false);
  };

  const handleGeneratePayoutConfirmation = async (operationDate) => {
    setActionInProgress(true);
    try {
      await props.onGeneratePayoutConfirmation(operationDate);
    } catch (error) {
      dispatch(notificationsActions.showError('Nie udało się zarejestrować transakcji'));
    }
    setActionInProgress(false);
  };

  useEffect(() => {
    if (props.personalBalance) {
      const temp = JSON.parse(JSON.stringify(props.personalBalance));
      temp.operations = temp.operations.map((operation) =>
        !operation.walletType ? { ...operation, walletType: 'general' } : operation
      );
      setOperationsToDisplay(temp);
    }
  }, [props.personalBalance]);

  const renderWalletSwitch = useCallback(
    (wType) => {
      switch (wType) {
        case 'general':
          return props.personalBalance.availableFunds;
        case 'stay':
          return props.personalBalance.availableFundsStay;
        case 'catering':
          return props.personalBalance.availableFundsCatering;
        case 'other':
          return props.personalBalance.availableFundsOther;
        case 'activities':
          return props.personalBalance.availableFundsActivities;
        case 'meals':
          return props.personalBalance.availableFundsMeals;
        default:
          return props.personalBalance.availableFunds;
      }
    },
    [
      props.personalBalance.availableFunds,
      props.personalBalance.availableFundsMeals,
      props.personalBalance.availableFundsActivities,
      props.personalBalance.availableFundsCatering,
      props.personalBalance.availableFundsOther,
      props.personalBalance.availableFundsStay
    ]
  );

  return (
    <Box>
      <Paper sx={{ my: 2, p: 3 }}>
        <Typography variant="h6" sx={{ pb: 2 }}>
          Stan środków i płatności historyczne
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography>Stan środków:</Typography>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ml: 1 }}>
            {isStaffMember
              ? (renderWalletSwitch('general') || 0).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN'
                })
              : (renderWalletSwitch(walletType) || 0).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN'
                })}
          </Typography>
        </Box>
        {isStaffMember ? null : (
          <Box>
            Aktualny portfel na który zostaną przelane pozostałe środki
            <Select
              sx={{
                color: (theme) => theme.palette.color.color2,
                borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`,
                '&:hover': {
                  borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`
                },
                '& .MuiSvgIcon-root': {
                  color: (theme) => theme.palette.color.color2
                },
                m: 2
              }}
              variant="standard"
              value={walletType}
              onChange={(event) => handleWalletChange(event.target.value)}>
              {walletConfig.map((wallet) => (
                <MenuItem key={wallet.id} value={wallet.name}>
                  {wallet.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <DepositSection
          isStaffMember={isStaffMember}
          walletType={walletType}
          walletConfig={walletConfig.filter((wallet) => wallet.name !== walletType)}
          personalBalance={props.personalBalance}
          inProgress={actionInProgress}
          onWalletTransfer={handleTransferToWallet}
          onDeposit={handleDeposit}
          availableMoney={isStaffMember ? renderWalletSwitch('general') || 0 : renderWalletSwitch(walletType) || 0}
        />
        {renderWalletSwitch(walletType) && renderWalletSwitch(walletType) > 0 ? (
          <>
            <PayoutSection
              walletType={walletType}
              personalBalance={props.personalBalance}
              inProgress={actionInProgress}
              onPayoutFromBalance={handlePayoutFromBalance}
              availableFunds={renderWalletSwitch(walletType) || 0}
            />
          </>
        ) : null}
      </Paper>
      <Paper sx={{ my: 2, p: 3 }}>
        <BalanceOperationsTable
          walletConfig={walletConfig}
          walletType={walletType}
          personalBalance={operationsToDisplay}
          inProgress={actionInProgress}
          onGeneratePayoutConfirmation={handleGeneratePayoutConfirmation}
        />
      </Paper>
    </Box>
  );
};

FinancialDetailsCard.propTypes = {
  personalBalance: PropTypes.object.isRequired,
  onPayoutFromBalance: PropTypes.func.isRequired,
  onDeposit: PropTypes.func.isRequired,
  onLoadBalance: PropTypes.func.isRequired,
  onGeneratePayoutConfirmation: PropTypes.func.isRequired,
  theme: PropTypes.object,
  child: PropTypes.object.isRequired,
  onWalletTransfer: PropTypes.func,
  isStaffMember: PropTypes.bool
};

export default FinancialDetailsCard;
