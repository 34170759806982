import BaseModel from '../BaseModel';

export class Person extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = '';
  }
}

export class PersonGuardian extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = '';
  }
}

export class ChildDaycareEntryModel extends BaseModel {
  constructor() {
    super();
    this.child = new Person();
    this.id = '';
    this.date = null;
    this.sleepTimeInMinutes = undefined;
    this.meals = [];
    this.mealsNote = undefined;
    this.mealsScale = [];
    this.mood = [];
    this.nappies = undefined;
    this.poos = undefined;
    this.note = undefined;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.child = new Person().assign(assigned.child);
  }
}

export class DailyGroupDaycareSheetModel extends BaseModel {
  constructor() {
    super();
    this.groupId = null;
    this.date = null;
    this.entries = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.entries = assigned.entries.map((e) => new ChildDaycareEntryModel().assign(e));
  }
}

export class ChildDaycareModel extends BaseModel {
  constructor() {
    super();
    this.childId = '';
    this.entries = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.entries = assigned.entries.map((entry) => new ChildDaycareEntryModel().assign(entry));
  }
}
