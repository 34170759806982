import ApiBase, { ROOT_URL } from './ApiBase';
import moment from 'moment';

class AttendanceApi extends ApiBase {
  static getAllTodayAttendances() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/attendances/today`).type('application/json'));
  }

  static getAllAttendances(date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/attendances/${moment(date).format('YYYY-MM-DD')}`).type('application/json')
    );
  }

  static getTodayAttendance(groupId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/attendances/group/${groupId}/today`).type('application/json')
    );
  }

  static getAttendance(groupId, date) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/attendances/group/${groupId}/${moment(date).format('YYYY-MM-DD')}`)
        .type('application/json')
    );
  }

  static getChildAttendances(childId, date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/attendances/child/${childId}/${moment(date).format('YYYY-MM')}`).type('application/json')
    );
  }

  static getAttendanceDaysInMonth(groupId, date) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/attendances/group/${groupId}/days/${moment(date).format('YYYY-MM')}`)
        .type('application/json')
    );
  }

  static getAttendanceMonths(groupId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/attendances/group/${groupId}/months`).type('application/json')
    );
  }

  static reportChildDroppedOff(childId, droppedOffAt, isAdaptation) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/reportDroppedOff`)
        .send({ droppedOffAt: moment(droppedOffAt).format(), isAdaptation })
        .type('application/json')
    );
  }

  static reportChildPickedUp(childId, guardianId, pickedUpAt) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/reportPickedUp`)
        .send({ guardianId, pickedUpAt: moment(pickedUpAt).format() })
        .type('application/json')
    );
  }

  static setDailyAttendanceEntry(childId, droppedOffAt, guardianId, pickedUpAt) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/setDailyAttendanceEntry`)
        .send({
          droppedOffAt: moment(droppedOffAt).format(),
          pickedUpAt: moment(pickedUpAt).format(),
          guardianId
        })
        .type('application/json')
    );
  }

  static reportChildAbsence(childId, absenceFrom, absenceTo, absenceNotes) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/reportAbsence`)
        .send({
          absenceFrom: moment(absenceFrom).format('YYYY-MM-DD'),
          absenceTo: moment(absenceTo).format('YYYY-MM-DD'),
          absenceNotes
        })
        .type('application/json')
    );
  }

  static revertChildDroppedOff(childId, date) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/undoDroppedOff`)
        .send({ date: moment(date).format('YYYY-MM-DD') })
        .type('application/json')
    );
  }

  static revertChildPickedUp(childId, date) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/undoPickedUp`)
        .send({ date: moment(date).format('YYYY-MM-DD') })
        .type('application/json')
    );
  }

  static revertChildAbsence(childId, absencesFrom, absencesTo) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/attendances/child/${childId}/removeAbsences`)
        .send({
          absencesFrom: moment(absencesFrom).format('YYYY-MM-DD'),
          absencesTo: moment(absencesTo).format('YYYY-MM-DD')
        })
        .type('application/json')
    );
  }

  static revertPreviousChildAttendanceAsync(childId, droppedOffAt, pickedUpAt, absenceFrom, absenceTo, absenceNotes) {
    if (pickedUpAt) {
      return this.revertChildPickedUp(childId, pickedUpAt)
        .then(() => this.revertChildDroppedOff(childId, droppedOffAt))
        .then(() => this.reportChildAbsence(childId, absenceFrom, absenceTo, absenceNotes));
    }
    if (droppedOffAt) {
      return this.revertChildDroppedOff(childId, droppedOffAt).then(() =>
        this.reportChildAbsence(childId, absenceFrom, absenceTo, absenceNotes)
      );
    }
    return this.reportChildAbsence(childId, absenceFrom, absenceTo, absenceNotes);
  }

  static reportPreviousChildAttendanceAsync(childId, droppedOffAt, pickedUpAt, guardianId, absenceFrom, absenceTo) {
    if (absenceFrom && absenceTo) {
      const effectiveAbsenceDate = droppedOffAt;
      return this.revertChildAbsence(childId, effectiveAbsenceDate, effectiveAbsenceDate).then(() =>
        this.setDailyAttendanceEntry(childId, droppedOffAt, guardianId, pickedUpAt)
      );
    }

    return this.setDailyAttendanceEntry(childId, droppedOffAt, guardianId, pickedUpAt);
  }

  static getTodayAttendanceSummary() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/attendances/todaySummary`).type('application/json'));
  }

  static getTodayActivityAttendance(activityId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/activities/${activityId}/today`).type('application/json'));
  }

  static setDailyActivityAttendanceEntry(childId, activityId, droppedOffAt, pickedUpAt) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/activities/child/${childId}/setDailyAttendanceEntry`)
        .send({
          droppedOffAt: moment(droppedOffAt).format(),
          pickedUpAt: moment(pickedUpAt).format(),
          activityId
        })
        .type('application/json')
    );
  }

  static reportChildActivityAbsence(childId, activityId, absenceFrom, absenceTo, absenceNotes) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/activities/child/${childId}/reportAbsence`)
        .send({
          absenceFrom: moment(absenceFrom).format('YYYY-MM-DD'),
          absenceTo: moment(absenceTo).format('YYYY-MM-DD'),
          absenceNotes,
          activityId
        })
        .type('application/json')
    );
  }

  static getMonthlyActivityAttendance(activityId, childId, month) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/activities/child/${childId}/${activityId}/${month}`).type('application/json')
    );
  }

  static getAbsentTomorrow() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/attendances/tomorrowAbsences`).type('application/json'));
  }
}

export default AttendanceApi;
