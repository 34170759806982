import authenticationApi from '../api/AuthenticationApi';
import localStorageProvider from '../utils/localStorageProvider';
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_FAILURE_INVALID_CREDENTIALS,
  SIGN_IN_LOGIN_GOV_FAILURE_INVALID_CREDENTIALS,
  SIGN_IN_PASSWORD_CHANGE_REQUIRED,
  SIGNED_OUT,
  SIGN_IN_GOV_SUCCESS,
  SIGN_IN_FAILURE_LOGIN_GOV
} from './actionTypes';
import * as initActions from './initActions';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths } from '../routePaths';
import { authFailureReasons } from '../constants/authFailureReasons';
import * as logger from '../utils/logger';

export function signIn() {
  return { type: SIGN_IN };
}

export function signInSuccess(token) {
  return { type: SIGN_IN_SUCCESS, token };
}

export function signInGovSuccess(userId) {
  return { type: SIGN_IN_GOV_SUCCESS, userId };
}

export function signInAsync(signInData) {
  return (dispatch) => {
    dispatch(signIn());
    return authenticationApi
      .signIn(signInData)
      .then((authenticationResult) => {
        const { token } = authenticationResult;
        localStorageProvider.setItem('authToken', token);
        dispatch(signInSuccess(authenticationResult.token));
        if (!authenticationResult.acceptanceRequired) {
          const expectedRedirect = localStorage.getItem('expectedRedirect');
          if (expectedRedirect) {
            localStorage.removeItem('expectedRedirect');
            browserHistory.push(expectedRedirect);
          } else {
            browserHistory.push(routePaths.home);
          }
        } else {
          browserHistory.push(routePaths.statuteChanged);
        }
      })
      .then(() => {
        dispatch(initActions.preloadData());
      })
      .catch((error) => {
        if (error.status === 401 && error.response.body.failureReason === authFailureReasons.invalidCredentials) {
          return dispatch({ type: SIGN_IN_FAILURE_INVALID_CREDENTIALS });
        }
        if (error.status === 401 && error.response.body.failureReason === authFailureReasons.expiredPassword) {
          const passwordChangeToken = error.response.body.token;
          browserHistory.push({
            pathname: routePaths.passwordExpired,
            query: { token: passwordChangeToken }
          });
          return dispatch({
            type: SIGN_IN_PASSWORD_CHANGE_REQUIRED,
            token: passwordChangeToken
          });
        }
        dispatch({ type: SIGN_IN_FAILURE });
        dispatch(notificationActions.showError('Logowanie nie powiodło się'));
        return logger.error(error);
      });
  };
}

export function getToken() {
  return authenticationApi
    .getToken()
    .then((token) => token)
    .catch((error) => {
      return logger.error(error);
    });
}

export function signInWithToken(signInData) {
  return (dispatch) => {
    return authenticationApi
      .signInWithToken(signInData)
      .then((authenticationResult) => {
        const { token } = authenticationResult;
        localStorageProvider.setItem('authToken', token);
        dispatch(signInSuccess(authenticationResult.token));
        dispatch(signInGovSuccess(authenticationResult.trustedProfileUserId));
        if (!authenticationResult.acceptanceRequired) {
          browserHistory.push(routePaths.home);
        } else {
          browserHistory.push(routePaths.statuteChanged);
        }
      })
      .then(() => {
        dispatch(initActions.preloadData());
      })
      .catch((error) => {
        if (error.status === 401 && error.response.body.failureReason === authFailureReasons.invalidCredentials) {
          browserHistory.replace(routePaths.signIn);
          return dispatch({ type: SIGN_IN_LOGIN_GOV_FAILURE_INVALID_CREDENTIALS });
        }
        if (error.status === 401 && error.response.body.failureReason === authFailureReasons.loginRestricted) {
          browserHistory.replace(routePaths.signIn);
          return dispatch({ type: SIGN_IN_FAILURE_LOGIN_GOV });
        }
        dispatch(notificationActions.showError('Logowanie nie powiodło się'));
        browserHistory.replace(routePaths.signIn);
        return logger.error(error);
      });
  };
}

export function signInPinDeviceAsync(signInData) {
  return (dispatch) => {
    dispatch(signIn());
    return authenticationApi
      .signInPinDevice(signInData)
      .then((authenticationResult) => {
        const { token } = authenticationResult;
        localStorageProvider.setItem('authToken', token);
        dispatch(signInSuccess(authenticationResult.token));
        browserHistory.push(routePaths.pinKeypad);
      })
      .then(() => {
        dispatch(initActions.preloadData());
      })
      .catch((error) => {
        if (error.status === 401 && error.response.body.failureReason === authFailureReasons.invalidCredentials) {
          return dispatch({ type: SIGN_IN_FAILURE_INVALID_CREDENTIALS });
        }
        dispatch({ type: SIGN_IN_FAILURE });
        dispatch(notificationActions.showError('Logowanie nie powiodło się'));
        return logger.error(error);
      });
  };
}

export function signedOut() {
  return { type: SIGNED_OUT };
}

export function logoutGov(trustedProfileUserId) {
  return (dispatch) => {
    return authenticationApi
      .logout(trustedProfileUserId)
      .then(() => {
        localStorageProvider.removeItem('authToken');
        dispatch(signedOut());
        dispatch(initActions.cleanUp());
        window.location.href = `${window.location.origin}`;
      })
      .catch((error) => {
        return logger.error(error);
      });
  };
}

export function signOut(trustedProfileUserId) {
  if (trustedProfileUserId) {
    return logoutGov(trustedProfileUserId);
  } else {
    return (dispatch) => {
      localStorageProvider.removeItem('authToken');
      dispatch(signedOut());
      dispatch(initActions.cleanUp());
      window.location.href = `${window.location.origin}`;
    };
  }
}
