import PropTypes from 'prop-types';
import React from 'react';
import { ReportTypes } from '../../../constants/reportTypes';
import CustomCheckbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, Typography } from '@mui/material';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';

class DuesPaymentsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStaff: false,
      showOverdue: true,
      showPaymentDates: false
    };
  }

  render() {
    const { year, month, groups, showOverdue, showPaymentDates, showStaff, onChange, errors } = this.props;
    return (
      <Box>
        <GroupAndMonthTemplate
          errors={errors}
          manyGroupsSelector
          year={year}
          month={month}
          groups={groups}
          onChange={onChange}
          legend={ReportTypes.stayDuesPayments.name}
        />
        <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.stayDuesPayments.name}`}>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Ustawienia raportu
          </Typography>
          <Box sx={{ pb: 1 }}>
            <CustomCheckbox
              label="Uwzględniaj wyżywienie pracowników"
              defaultChecked={showStaff}
              onCheck={(event, isChecked) => {
                this.setState({ showStaff: isChecked });
                onChange({ target: { name: 'showStaff', value: isChecked } });
              }}
            />
          </Box>
          <Box sx={{ pb: 1 }}>
            <CustomCheckbox
              label="Uwzględniaj zaległości"
              defaultChecked={showOverdue}
              onCheck={(event, isChecked) => {
                this.setState({ showOverdue: isChecked });
                onChange({ target: { name: 'showOverdue', value: isChecked } });
              }}
            />
          </Box>
          <Box sx={{ pb: 1 }}>
            <CustomCheckbox
              label="Uwzględniaj daty wpłat względem wybranego miesiące (niezależnie od dat wygenerowania rozliczeń)"
              defaultChecked={showPaymentDates}
              onCheck={(event, isChecked) => {
                this.setState({ showPaymentDates: isChecked });
                onChange({ target: { name: 'showPaymentDates', value: isChecked } });
              }}
            />
          </Box>
        </FieldsetWrapper>
      </Box>
    );
  }
}

DuesPaymentsReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  showOverdue: PropTypes.bool.isRequired,
  showPaymentDates: PropTypes.bool.isRequired,
  showStaff: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  errors: PropTypes.object
};

export default DuesPaymentsReport;
