import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DietDetailsModel } from '../models/meals/DietModels';

export default function dietDetailsReducer(state = initialState.dietDetails, action) {
  switch (action.type) {
    case types.LOAD_DIET_DETAILS_SUCCESS: {
      return new DietDetailsModel().assign(action.data);
    }
    default:
      return state;
  }
}
