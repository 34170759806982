/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function daysOffUiReducer(state = initialState.configuration.daysOffUi, action) {
  switch (action.type) {
    //create
    case types.ADD_DAY_OFF: {
      return UiModel.start();
    }
    case types.ADD_DAY_OFF_CANCEL: {
      return UiModel.cancel();
    }
    case types.ADD_DAY_OFF_SUBMIT: {
      return UiModel.submit();
    }
    case types.ADD_DAY_OFF_SUCCESS: {
      return UiModel.success();
    }
    case types.ADD_DAY_OFF_FAILURE: {
      return UiModel.failure(action.errors);
    }
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.configuration.daysOffUi);
    }
    default:
      return state;
  }
}
