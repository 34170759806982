import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import MenuItem from '@mui/material/MenuItem';
import SelectField from '../../common/SelectField';
import { ReportTypes } from '../../../constants/reportTypes';
import { Box, Typography, FormControlLabel, Switch } from '@mui/material';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

class DuesStayOverallReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'name',
      sortOptions: [
        { name: 'Po nazwisku', value: 'name' },
        { name: 'Po numerze', value: 'documentNumber' }
      ],
      dueTypes: [ChargingReportTypes.stay.value],
      summaryRelief: this.props.summaryRelief
    };
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  handleSwitchChange() {
    this.setState({ summaryRelief: !this.props.summaryRelief });
    this.props.onChange({ target: { name: 'summaryRelief', value: !this.props.summaryRelief } });
  }

  render() {
    return (
      <Box>
        <GroupAndMonthTemplate
          errors={this.props.errors}
          manyGroupsSelector
          year={this.props.year}
          month={this.props.month}
          groups={this.props.groups}
          onChange={this.props.onChange}
          legend={ReportTypes.duesStayDetailsOverall.name}
        />
        <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.duesStayDetailsOverall.name}`}>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Ustawienia raportu
          </Typography>
          <SelectField
            contrast
            floatingLabelText="Sortowanie"
            sx={{ width: 'auto', overflow: 'hidden' }}
            id="sortBy"
            value={this.state.sortBy}
            onChange={(event) => {
              this.setState({ sortBy: event.target.value });
              this.props.onChange({ target: { name: 'sortBy', value: event.target.value } });
            }}>
            {this.state.sortOptions.map((so) => {
              return (
                <MenuItem key={so.value} value={so.value}>
                  {so.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <FormControlLabel
            sx={{ m: 0, mt: 2 }}
            control={
              <Switch checked={this.state.summaryRelief} onChange={this.handleSwitchChange} name="summaryRelief" />
            }
            label="Uwzględnij ulgi w podsumowaniu"
            labelPlacement="end"
          />
        </FieldsetWrapper>
      </Box>
    );
  }
}

DuesStayOverallReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  summaryRelief: PropTypes.bool.isRequired
};

export default DuesStayOverallReport;
