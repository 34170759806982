import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';
import api from '../api/DigitalDiaryApi';

export function addNewEventAsync(event) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_DIGITAL_DIARY_EVENT_SUBMIT });
    return api
      .addNewEvent(event)
      .then((newEvent) => {
        dispatch({
          type: types.CREATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS,
          eventData: newEvent
        });
        dispatch(notificationActions.showSuccess(`pomyślnie dodano ${event.eventType}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się dodać wydarzenia'));
        return logger.error(error);
      });
  };
}

export function loadEventsByRoleAtDayAsync(set) {
  return (dispatch) => {
    return api
      .getSomeDataByDay(set)
      .then((events) => {
        dispatch(notificationActions.showSuccess('Załadowano dane pomyślnie'));
        dispatch({ type: types.LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY_SUCCESS, events });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać danych'));
        return logger.error(error);
      });
  };
}

export function createEventStart() {
  return { type: types.CREATE_MESSAGE_BOARD_DIGITALDIARY };
}

export function createEventCancel() {
  return { type: types.CREATE_MESSAGE_BOARD_DIGITALDIARY_CANCEL };
}

export function updateEventStart() {
  return { type: types.UPDATE_MESSAGE_BOARD_DIGITALDIARY };
}

export function updateEventCancel() {
  return { type: types.UPDATE_MESSAGE_BOARD_DIGITALDIARY_CANCEL };
}

export function updateEventAsync({ eventData, eventType, eventDate, groupId }) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUBMIT });
    api
      .updateEvent({ eventData, eventType })
      .then(() => {
        api.getSomeDataByDay({ eventType, eventDate, groupId }).then((events) => {
          dispatch(notificationActions.showSuccess('Załadowano dane pomyślnie'));
          dispatch({ type: types.LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY_SUCCESS, events });
          dispatch({ type: types.UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS });
        });
      })
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano wydarzenie: '${eventData.title}'`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować danych'));
        dispatch({ type: types.UPDATE_MESSAGE_BOARD_DIGITALDIARY_FAILURE });
        return logger.error(error);
      });
  };
}

export function deleteEventAsync(eventId) {
  return (dispatch, getState) => {
    dispatch({ type: types.DELETE_MESSAGE_BOARD_POST });
    api
      .deleteEvent(eventId)
      .then(() => {
        dispatch({ type: types.DELETE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS, eventId });
      })
      .then(() => {
        dispatch(notificationActions.showSuccess(`Usunięto wpis '${get.postTitle(getState, eventId)}'`));
      });
  };
}
