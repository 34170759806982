import {
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import DotsMenu from '../../common/menu/DotsMenu';
import PersonAvatar from '../../forms/PersonAvatar';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { callMenuItem } from '../../forms/CallMenuItem';
import { routePathActions } from '../../../routePathActions';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import userRoles from '../../../constants/userRoles';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../routePaths';
import { resetUserPassword } from '../../../actions/userAccountActions';
import { useDispatch } from 'react-redux';
import IconButtonPipe from '../../common/IconButtonPipe';
import * as notificationsActions from '../../../actions/notificationActions';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const dotsMenu = makeStyles({
  root: {
    left: 0
  }
});

const SingleLegalGuardianItem = ({
  legal,
  isStaffMember,
  isPinEnabled,
  isAllowed,
  onRemove,
  onRestartActivation,
  openProfileHistoryDialog
}) => {
  const dispatch = useDispatch();
  const classes = dotsMenu();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState("Kliknij przycisk 'Generuj' aby wygenerować nowe hasło");
  const { clipboard } = navigator;

  const openPasswordDialog = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleChangePassword = async (legalGuardianId) => {
    setPassword(await dispatch(resetUserPassword(legalGuardianId)));
  };

  const handleDisplay = () => {
    return (
      <DialogContent>
        <DialogContentText
          sx={{
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.color.contrast
          }}>
          {password === "Kliknij przycisk 'Generuj' aby wygenerować nowe hasło"
            ? 'Generowanie nowego hasła'
            : 'Wygenerowane hasło:'}
        </DialogContentText>
        <DialogContentText sx={{ color: theme.palette.color.contrast }}>
          {password}
          {password !== "Kliknij przycisk 'Generuj' aby wygenerować nowe hasło" ? (
            <IconButtonPipe
              tooltip="Skopiuj link albumu do schowka"
              onClick={async () => {
                try {
                  await clipboard.writeText(password);
                  dispatch(notificationsActions.showSuccess('Skopiowano hasło do schowka'));
                } catch (error) {
                  dispatch(notificationsActions.showError('Nie udało się skopiować hasła do schowka'));
                }
              }}>
              <FileCopyIcon />
            </IconButtonPipe>
          ) : null}
        </DialogContentText>
      </DialogContent>
    );
  };

  return (
    <Box
      sx={{
        '&:hover': {
          background: theme.palette.background.hover
        }
      }}>
      <ListItem sx={{ py: 1 }}>
        <ListItemIcon>
          <PersonAvatar
            initials
            alt={legal.firstName}
            url={legal.photoUrl}
            firstName={legal.firstName}
            lastName={legal.lastName}
            sx={{ ml: 2, mr: 2 }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{ pr: 6 }}
          disableTypography
          primary={
            <Box sx={{ pb: 1 }}>
              <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
                {legal.lastName} {legal.firstName}
              </Typography>
              <Typography component="span" sx={{ display: 'block', wordBreak: 'break-all' }}>
                {legal.email}
              </Typography>
            </Box>
          }
          secondary={
            <>
              {isPinEnabled && legal.pin && (
                <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                  Pin
                  <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightBold, mx: 1 }}>
                    {legal.pin}
                  </Typography>
                </Typography>
              )}
              <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                Numer telefonu
                <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightBold, mx: 1 }}>
                  {legal.phoneNumber || 'brak'}
                </Typography>
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography component="span">
                  {`Konto ${legal.userAccountActivated ? 'aktywne' : 'nieaktywne'} `}
                </Typography>
                {legal.userAccountActivated ? (
                  <CheckIcon
                    sx={{
                      color: theme.palette.color.success,
                      ml: 1
                    }}
                  />
                ) : (
                  <CloseIcon
                    sx={{
                      color: theme.palette.color.error,
                      ml: 1
                    }}
                  />
                )}
              </Box>
            </>
          }
        />
        <ListItemSecondaryAction>
          <DotsMenu sx={{ className: isMobile ? classes.root : null }}>
            {(onClose) => [
              callMenuItem(legal.phoneNumber, 1),
              !legal.userAccountActivated && isAllowed(routePathActions.legalGuardians.restartActivation) && (
                <ActionConfirmationMenuItem
                  key={2}
                  actionLabel="Wyślij ponownie wiadomość aktywacyjną"
                  confirmationText={`Czy na pewno chcesz ponownie wysłać wiadomość aktywacyjną na adres email opiekuna: ${legal.email}?`}
                  confirmationTitle="Wyślij ponownie wiadomość aktywacyjną"
                  confirmLabel="Wyślij"
                  onConfirm={() => {
                    onRestartActivation(legal.email);
                  }}
                  onItemClick={() => onClose()}
                  onCancel={() => {}}
                />
              ),
              isAllowed(routePathActions.legalGuardians.deleteLegalGuardianAccount) && (
                <ActionConfirmationMenuItem
                  key={3}
                  actionLabel="Usuń konto"
                  confirmationText="Czy na pewno chcesz usunąć konto opiekuna? Opiekun zostanie automatycznie odpięty od dzieci oraz utraci możliwość korzystania z systemu."
                  confirmationTitle="Usuń konto opiekuna"
                  confirmLabel="Usuń"
                  onConfirm={() => onRemove(legal.id)}
                  onItemClick={() => onClose()}
                  onCancel={() => {}}
                />
              ),
              isStaffMember(userRoles.staffMemberPrincipal) && (
                <MenuItem
                  key={4}
                  aria-haspopup="true"
                  aria-label="Pokaż historię zmian profilu"
                  onClick={() => openProfileHistoryDialog(legal.id)}>
                  Historia zmian
                </MenuItem>
              ),
              isStaffMember(userRoles.staffMemberPrincipal) && (
                <MenuItem
                  key={5}
                  aria-haspopup="true"
                  aria-label="Wygeneruj nowe hasło"
                  onClick={() => {
                    onClose();
                    openPasswordDialog();
                  }}>
                  {legal.userAccountActivated ? 'Zresetuj hasło' : 'Generuj hasło'}
                </MenuItem>
              ),
              <Dialog
                sx={{ zIndex: theme.zIndex.modal }}
                key="password-dialog"
                id="password-dialog"
                maxWidth="xs"
                onClose={handleCancel}
                aria-labelledby="password-dialog-title"
                open={isOpen}>
                <DialogTitle id="password-dialog-title">Resetowanie hasła</DialogTitle>
                {handleDisplay()}
                {password === "Kliknij przycisk 'Generuj' aby wygenerować nowe hasło" ? (
                  <DialogActions>
                    <Button aria-label="anuluj" variant="outlinedContrast" onClick={handleCancel}>
                      anuluj
                    </Button>
                    <Button aria-label="generuj" variant="contained" onClick={() => handleChangePassword(legal.id)}>
                      generuj
                    </Button>
                  </DialogActions>
                ) : (
                  <DialogActions>
                    <Button onClick={handleCancel}>OK</Button>
                  </DialogActions>
                )}
              </Dialog>
            ]}
          </DotsMenu>
        </ListItemSecondaryAction>
      </ListItem>
      {legal.children.length > 0 && (
        <Box sx={{ pl: 11, pb: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography sx={{ mr: 1 }}>Dzieci: </Typography>
          {legal.children.map((c) => (
            <Chip
              variant="outlined"
              key={`Chip${c.id}`}
              sx={{
                m: '4px',
                p: '4px',
                borderColor: theme.palette.color.contrast,
                color: theme.palette.color.contrast,
                height: 'auto',
                fontSize: 14,
                display: isMobile ? 'flex' : null,
                justifyContent: isMobile ? 'flex-start' : null
              }}
              label={`${c.firstName} ${c.lastName}${c.groupName ? ' (' + c.groupName + ')' : ''}`}
              onClick={() => browserHistory.push(fromTemplate(routePaths.childDetails, [c.id]))}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

SingleLegalGuardianItem.propTypes = {
  isAllowed: PropTypes.func,
  isPinEnabled: PropTypes.bool,
  isStaffMember: PropTypes.func,
  lastItem: PropTypes.bool,
  legal: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onRestartActivation: PropTypes.func,
  openProfileHistoryDialog: PropTypes.func,
  onPasswordChange: PropTypes.func
};

export default React.memo(SingleLegalGuardianItem);
