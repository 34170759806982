import { Box, Button, ListItemAvatar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ActivityAvatar from '../../../common/activities/ActivityAvatar';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useSelector } from 'react-redux';
import ActivityModal from '../../../common/activities/ActivityModal';
import PropTypes from 'prop-types';

const ChargingSchemeActivitySwitch = ({ onChange, activityId, error }) => {
  const activities = useSelector((state) => state.activities);

  const [showActivityDialog, setShowActivityDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(activities.find((activity) => activity.id === activityId));

  useEffect(() => {
    if (activityId && selectedActivity) {
      const foundActivity = activities.find((activity) => activity.id === activityId);
      if (foundActivity) {
        setSelectedActivity(foundActivity);
        onChange({ target: { value: selectedActivity.id, name: 'activityId' } });
      }
    }
  }, [activities, activityId, onChange, selectedActivity]);

  const handleOpenCloseDialog = () => {
    setShowActivityDialog((prev) => !prev);
  };

  const handleChangeActivity = (activity) => {
    setSelectedActivity(activity);
    setShowActivityDialog((prev) => !prev);
    onChange({ target: { value: activity.id, name: 'activityId' } });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', m: '8px 0' }}>
        {!!selectedActivity ? (
          <>
            <ListItemAvatar>
              <ActivityAvatar activity={selectedActivity} />
            </ListItemAvatar>
            <Typography>{selectedActivity.name}</Typography>
          </>
        ) : (
          <Typography sx={{ color: (theme) => (error ? theme.palette.color.error : theme.palette.color.contrast) }}>
            Wybierz aktywność
          </Typography>
        )}

        <Button
          variant="contained"
          aria-label="Wybierz aktywność"
          sx={{ mx: 1 }}
          onClick={() => handleOpenCloseDialog()}>
          <SwapHorizIcon /> Wybierz aktywność
        </Button>
      </Box>
      <ActivityModal
        onSelectActivity={handleChangeActivity}
        isDialogOpen={showActivityDialog}
        changeDialogState={handleOpenCloseDialog}
      />
    </>
  );
};

ChargingSchemeActivitySwitch.propTypes = {
  onChange: PropTypes.func,
  activityId: PropTypes.string
};

export default ChargingSchemeActivitySwitch;
