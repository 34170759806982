import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const DoneListIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M2 6.5a.5.5 0 0 1 .5-.5h16.154a.5.5 0 0 1 .5.5v1.348a.5.5 0 0 1-.5.5H2.5a.5.5 0 0 1-.5-.5V6.5ZM10.948 10.697H2.5a.5.5 0 0 0-.5.5v1.348c0 .277.222.5.498.5h7.037c.471 0 2.826-2.348 1.413-2.348ZM12.066 15.394h-9.57a.497.497 0 0 0-.496.5v1.348c0 .276.222.5.498.5h7.98c.529 0 3.178-2.348 1.588-2.348ZM20.446 9.916a.92.92 0 0 1 1.312 1.288l-4.894 6.122a.922.922 0 0 1-1.325.024l-3.246-3.246a.92.92 0 1 1 1.3-1.3l2.569 2.567 4.26-5.428.024-.027Z" />
  </SvgIcon>
);

export default DoneListIcon;
