import moment from 'moment';
import { BaseModelValidator } from '../BaseModelValidator';

export class ReportAttendanceValidator extends BaseModelValidator {
  constructor() {
    super();
    this.constraints = {
      generalError: (value, model) => {
        if (model.droppedOffAt && model.pickedUpAt && model.droppedOffAt.hours() > model.pickedUpAt.hours())
          return 'Godzina odebrania dziecka nie może być wcześniejsza od godziny jego przybycia';
        if (
          model.droppedOffAt &&
          model.pickedUpAt &&
          model.droppedOffAt.hours() === model.pickedUpAt.hours() &&
          model.droppedOffAt.minutes() > model.pickedUpAt.minutes()
        )
          return 'Godzina odebrania dziecka nie może być wcześniejsza od godziny jego przybycia';
        return null;
      }
    };
  }
}

export class ReportAbsenceValidator extends BaseModelValidator {
  constructor() {
    super();
    this.constraints = {
      generalError: (value, model) => {
        if (moment(model.absenceFrom).isAfter(moment(model.absenceTo)))
          return 'Data rozpoczęcia nieobecności nie może być datą późniejszą od daty zakończenia nieobecności';
        if (moment(model.absenceTo).isSameOrAfter(moment().add(3, 'M'))) {
          return 'Nie można zgłosić nieobecności dłużej niż 3 miesiące do przodu';
        }
        return null;
      }
    };
  }
}
