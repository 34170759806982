import './css/style.css';
import image1 from './assets/header.jpg';
// import image2 from './assets/qr.jpg';
import image3 from './assets/footer.jpg';

const PosterTemplate = ({ qr }) => {
  return (
    <>
      <div className="container">
        <header>
          <img src={image1} alt="header" />
        </header>
        <main>
          <div className="qrImage">{qr}</div>
        </main>
        <footer>
          <img src={image3} alt="footer" />
        </footer>
      </div>
    </>
  );
};

export default PosterTemplate;
