import userAccountApi from '../api/UserAccountApi';
import * as notificationActions from './notificationActions';
import * as types from './actionTypes';
import localStorageProvider from '../utils/localStorageProvider';
import { browserHistory } from 'react-router';
import { routePaths } from '../routePaths';
import { authFailureReasons } from '../constants/authFailureReasons';
import * as logger from '../utils/logger';

export function resetPassword(resetPasswordData) {
  return (dispatch) => {
    dispatch({ type: types.RESET_PASSWORD_SUBMIT });
    return userAccountApi
      .resetPassword(resetPasswordData)
      .then(() => {
        browserHistory.push(routePaths.home);
        dispatch({ type: types.RESET_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        if (error.status === 401 && error.response.body.failureReason === authFailureReasons.tokenExpired) {
          dispatch({ type: types.RESET_PASSWORD_FAILURE });
          return dispatch(notificationActions.showError('Link aktywacyjny utracił ważność'));
        }
        if (error.status === 412) {
          dispatch({ type: types.RESET_PASSWORD_FAILURE });
          return dispatch(notificationActions.showError(error.response.body.generalError));
        }
        dispatch({ type: types.RESET_PASSWORD_FAILURE });
        dispatch(notificationActions.showError('Nie udało się zresetować hasła'));
        return logger.error(error);
      });
  };
}

export function recoverPassword(passwordRecoveryData) {
  return (dispatch) => {
    return userAccountApi.recoverPassword(passwordRecoveryData).catch((error) => {
      dispatch(notificationActions.showError('Nie udało się odzyskać hasła'));
      return logger.error(error);
    });
  };
}

export function changePassword(changePasswordData) {
  return (dispatch) => {
    dispatch({ type: types.CHANGE_PASSWORD });
    return userAccountApi
      .changePassword(changePasswordData)
      .then((result) => {
        const { token } = result;
        localStorageProvider.setItem('authToken', token);
        dispatch(notificationActions.showSuccess('Hasło zmienione'));
        dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, token });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zmienić hasła'));
        logger.error(error);
        if (JSON.parse(error.response.text).password === 'Aktualne hasło nie jest prawidłowe.') {
          return 'Podane aktualne hasło jest nieprawidłowe.';
        }
        return null;
      });
  };
}

export function updateProfilePhoto(file) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_USER_PROFILE_PHOTO });
    if (file.size > 750 * 1024) {
      dispatch(notificationActions.showError('Rozmiar wybranego pliku przekracza 750KB'));
      return Promise.resolve();
    }

    return userAccountApi
      .updateProfilePhoto(file)
      .then((result) => {
        dispatch(notificationActions.showSuccess('Zaktualizowano zdjęcie'));
        dispatch({ type: types.UPDATE_USER_PROFILE_PHOTO_SUCCESS, photoUrl: result.photoUrl });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować zdjęcia'));
        return logger.error(error);
      });
  };
}

export function removeProfilePhoto() {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_USER_PROFILE_PHOTO });

    return userAccountApi
      .removeProfilePhoto()
      .then(() => {
        dispatch(notificationActions.showSuccess('Usunięto zdjęcie'));
        dispatch({ type: types.REMOVE_USER_PROFILE_PHOTO_SUCCESS });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się usunąć zdjęcia'));
        return logger.error(error);
      });
  };
}

export function restartUserAccountActivation(userLogin) {
  return (dispatch) => {
    dispatch({ type: types.RESTART_ACCOUNT_ACTIVATION });
    return userAccountApi
      .restartActivationProcess(userLogin)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(`Wysłano ponownie wiadomość aktywacyjną na adres email: '${userLogin}'`)
        );
        dispatch({ type: types.RESTART_ACCOUNT_ACTIVATION_SUCCESS });
      })
      .catch((error) => {
        let detail = null;
        if (error.status === 412) {
          detail = error.response.body.generalError;
        }
        dispatch(notificationActions.showError(`Nie udało się wysłać wiadomości aktywacyjnej. ${detail || ''}`));
        dispatch({ type: types.RESTART_ACCOUNT_ACTIVATION_FAILURE, errors: error.response.body });
        return logger.error(error);
      });
  };
}

export function resetUserPassword(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.RESET_ACCOUNT_PASSWORD });
      const password = await userAccountApi.resetPasswordProcess(userId);
      dispatch(notificationActions.showSuccess(`Pomyślnie wygenerowano nowe hasło dla konta`));
      dispatch({ type: types.RESET_ACCOUNT_PASSWORD_SUCCESS });
      return password.password;
    } catch (error) {
      let detail = null;
      if (error.status === 412) {
        detail = error.response.body.generalError;
      }
      dispatch(notificationActions.showError(`Nie udało się wygenerować nowego hasła.${detail || ''}`));
      dispatch({ type: types.RESET_ACCOUNT_PASSWORD_FAILURE, errors: error.response.body });
      return logger.error(error);
    }
  };
}

export function loadUserState() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_USER_STATE });
    return userAccountApi
      .getUserState()
      .then((userState) => {
        dispatch({ type: types.LOAD_USER_STATE_SUCCESS, userState });
        if (userState.acceptanceRequired) {
          browserHistory.push(routePaths.statuteChanged);
        }
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się wczytać danych użytkownika'));
        return logger.error(error);
      });
  };
}
