export function initOneSignal(appId, userId) {
  const OneSignal = window.OneSignal || [];
  OneSignal.push(() => {
    OneSignal.init({
      appId,
      autoResubscribe: true,
      persistNotification: false,
      welcomeNotification: {
        disable: true
      },
      notifyButton: {
        enable: true,
        size: 'medium',
        theme: 'inverse',
        prenotify: 'hello',
        text: {
          'tip.state.unsubscribed': 'Włącz powiadomienia',
          'tip.state.subscribed': 'Powiadomienia aktywne',
          'tip.state.blocked': 'Powiadomienia zablokowane',
          'message.prenotify': 'Kliknij aby włączyć powiadomienia',
          'message.action.subscribed': 'Dziękujemy!',
          'message.action.resubscribed': 'Zostałeś zapisany na powiadomienia',
          'message.action.unsubscribed': 'Nie otrzymasz więcej powiadomień',
          'dialog.main.title': 'Zarządzaj powiadomieniami',
          'dialog.main.button.subscribe': 'ZAPISZ SIĘ',
          'dialog.main.button.unsubscribe': 'WYPISZ SIĘ',
          'dialog.blocked.title': 'Odblokuj powiadomienia',
          'dialog.blocked.message': 'Postępuj zgodnie z instrukcją aby odblokować powiadomienia:'
        }
      }
    });
    OneSignal.setExternalUserId(userId);
    OneSignal.showNativePrompt();
  });
}
