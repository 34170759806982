/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ActivityModel } from '../models/activities/ActivityModels';

export default function activitiesReducer(state = initialState.activities, action) {
  switch (action.type) {
    case types.LOAD_ACTIVITIES_SUCCESS: {
      return action.activities.map((activity) => new ActivityModel().assign(activity));
    }
    case types.CREATE_ACTIVITY_DETAILS_SUCCESS: {
      const activity = new ActivityModel().assign(action.activityDetails);
      return [...state, activity];
    }
    case types.UPDATE_ACTIVITY_DETAILS_SUCCESS: {
      const activity = new ActivityModel().assign(action.activityDetails);
      return [...state.filter((g) => g.id !== activity.id), activity];
    }
    case types.ARCHIVE_ACTIVITY_DETAILS_SUCCESS: {
      return [...state.filter((g) => g.id !== action.activityId)];
    }
    default:
      return state;
  }
}
