import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EmptyState from '../../common/EmptyState';
import { useSelector } from 'react-redux';
import BoardPost from './BoardPost';
import Pagination from '@mui/material/Pagination';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const BoardPostsList = ({ posts, eventType, onSave, onDelete, onEdit, onClearFilters }) => {
  const useStyles = makeStyles((theme) => ({
    ul: {
      '& .MuiPaginationItem-root': {
        color: theme.palette.color.primary
      }
    }
  }));
  const classes = useStyles();
  const [isProcessing] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [filteredPosts, setFilteredPosts] = useState(posts);

  const state = useSelector((selector) => selector);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleSave = (post) => {
    onSave(post);
  };

  const handleDelete = (post) => {
    onDelete(post);
  };

  useEffect(() => {
    if (posts.length > 0) {
      let temp = posts;
      temp = temp.slice(10 * (page - 1), 10 * page);
      setFilteredPosts(temp);
    }
  }, [posts, page]);

  return (
    <>
      {filteredPosts.length > 0 ? (
        <>
          {filteredPosts.map((post) => (
            <BoardPost
              key={post.id}
              post={post}
              onEdit={onEdit}
              onSave={handleSave}
              onDelete={handleDelete}
              isProcessing={isProcessing}
              onClearFilters={onClearFilters}
              state={state}
              eventType={eventType}
            />
          ))}
          <Pagination
            siblingCount={isMobile ? 0 : 1}
            count={Math.ceil(posts.length / 10)}
            page={page}
            onChange={handleChangePage}
            sx={{ my: 0, mx: 'auto', display: 'flex', justifyContent: 'center' }}
            classes={{ ul: classes.ul }}
          />
        </>
      ) : (
        <EmptyState isPaper contrast message="Brak wpisów" />
      )}
    </>
  );
};

BoardPostsList.propTypes = {
  posts: PropTypes.array,
  eventType: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onClearFilters: PropTypes.func
};

export default BoardPostsList;
