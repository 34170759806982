import { check } from '../validation/validateHelper';
import { BaseModelValidator } from '../BaseModelValidator';
import { BoardPostModel, CoursebookBoardPostModel } from './MessageBoardModels';
import moment from 'moment';
import { options } from '../../constants/recipientsOptionsToChoose';

export class BoardPostModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = BoardPostModel;

    this.constraints = {
      title: (value) => {
        if (check.isEmpty(value)) return 'Nie podano tytułu';
        if (value.length > 100) return 'Tytuł jest zbyt długi';
        return null;
      },
      eventDate: (value) => {
        if (check.isEmpty(value)) return 'Nie podano daty zdarzenia';
        if (!moment(value).isValid()) return 'Niepoprawna data';
        if (!moment(value).isSameOrBefore(moment(new Date()).add(2, 'year')))
          return `Wybierz datę wcześniejszą niż ${moment(new Date()).add(2, 'year').format('DD/MM/YYYY')}`;
        return null;
      },
      eventStartTime: (value, model) => {
        if (value.isAfter(model.eventEndTime))
          return `Zadeklarowana godzina musi być wcześniejsza niż ${model.eventEndTime.toString()}`;
        if (!value.isEmpty() && !moment(value, 'hh:mm').isValid()) return 'Niepoprawny czas';
        return null;
      },
      eventEndTime: (value) => {
        if (!value.isEmpty() && !moment(value, 'hh:mm').isValid()) return 'Niepoprawny czas';
        return null;
      },
      content: (value) => {
        const cleanText = value.replace(/<\/?[^>]+(>|$)/g, '');
        if (cleanText.trim().length === 0) return 'Nie podano treści ogłoszenia.';
        if (cleanText.length > 7000) return 'Treść jest za długa.';
        return null;
      },
      limitedToGroups: (values, allValues) => {
        if (allValues.selectedPublishOptionValue === options.limited.value && values.length === 0)
          return 'Nie wybrano grup';
      },
      limitedToActivities: (values, allValues) => {
        if (allValues.selectedPublishOptionValue === options.limitedToActivities.value && values.length === 0)
          return 'Nie wybrano zajęć dodatkowych';
      }
    };
  }
}

export class BoardPostWithoutDateModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = BoardPostModel;

    this.constraints = {
      title: (value) => {
        if (check.isEmpty(value)) return 'Nie podano tytułu.';
        if (value.length > 100) return 'Tytuł jest zbyt długi';
        return null;
      },
      content: (value) => {
        const cleanText = value.replace(/<\/?[^>]+(>|$)/g, '');
        if (cleanText.trim().length === 0) return 'Nie podano treści ogłoszenia.';
        return null;
      },
      limitedToGroups: (values, allValues) => {
        if (allValues.selectedPublishOptionValue === options.limited.value && values.length === 0)
          return 'Nie wybrano grup.';
      },
      limitedToActivities: (values, allValues) => {
        if (allValues.selectedPublishOptionValue === options.limitedToActivities.value && values.length === 0)
          return 'Nie wybrano zajęć dodatkowych';
      }
    };
  }
}

export class CoursebookBoardPostModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = CoursebookBoardPostModel;

    this.constraints = {
      title: (value) => {
        if (check.isEmpty(value)) return 'Nie wybrano tematu';
        return null;
      },
      note: (value) => {
        const cleanText = value.replace(/<\/?[^>]+(>|$)/g, '');
        if (cleanText.trim().length === 0) return 'Nie podano treści';
        return null;
      },
      thematicCicle: (value) => {
        if (check.isEmpty(value)) return 'Nie wybrano kręgu tematycznego';
        return null;
      },
      coursebook: (value) => {
        if (check.isEmpty(value)) return 'Nie wybrano podręcznika';
        return null;
      },
      programPoints: (value) => {
        if (value.length === 0) return 'Nie wybrano punktów dydaktycznych';
        return null;
      }
    };
  }
}
