import React from 'react';
import PropTypes from 'prop-types';
import PersonAvatar from '../../../../components/forms/PersonAvatar';
import { ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, useTheme } from '@mui/material';
import IconButtonPipe from '../../../../components/common/IconButtonPipe';
import ShareIcon from '@mui/icons-material/Share';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const SingleUser = ({ firstName, lastName, photoUrl, id, onSingleAction, isSelected = false, isDisabled }) => {
  const theme = useTheme();
  return (
    <ListItem dense style={{ display: 'flex' }}>
      <ListItemAvatar>
        <PersonAvatar initials alt={firstName} url={photoUrl} firstName={firstName} lastName={lastName} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            {firstName} {lastName}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <IconButtonPipe
          onClick={(e) => {
            e.preventDefault();
            onSingleAction(id, isSelected);
          }}
          tooltip={isSelected ? 'Usuń z listy' : `Udostępnij plik dla ${firstName} ${lastName}`}
          // eslint-disable-next-line no-nested-ternary
          style={{
            color: isDisabled
              ? theme.palette.color.disabled
              : isSelected
              ? theme.palette.color.error
              : theme.palette.color.secondary
          }}>
          {isSelected ? (
            <DeleteForeverOutlinedIcon sx={{ fontSize: 32 }} />
          ) : (
            <ShareIcon sx={{ color: theme.palette.color.secondary }} />
          )}
        </IconButtonPipe>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

SingleUser.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
  id: PropTypes.string.isRequired,
  onSingleAction: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default SingleUser;
