import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, MenuItem, Typography } from '@mui/material';
import DatePicker from '../../../common/DatePicker';
import SelectField from '../../../common/SelectField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import ManyGroupsSelector from './ManyGroupsPickerTemplate';

const SingleDateAndGroupPickerTemplate = ({
  groupId,
  activityId,
  activities,
  date,
  groups,
  onChange,
  errors,
  legend,
  manyGroupsSelector
}) => {
  return (
    <Box>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        {!manyGroupsSelector ? (
          <SelectField
            contrast
            errorText={errors.groupId}
            sx={{ width: 'auto', overflow: 'hidden' }}
            floatingLabelText={groupId ? 'Grupa' : 'Zajęcia dodatkowe'}
            id={groupId ? 'groupId' : 'activityId'}
            value={groupId ? groupId : activityId}
            onChange={(event) => {
              onChange({ target: { name: groupId ? 'groupId' : 'activityId', value: event.target.value } });
            }}>
            {groupId
              ? groups.map((group) => {
                  return (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  );
                })
              : null}
            {activityId
              ? activities.map((activity) => {
                  return (
                    <MenuItem key={activity.id} value={activity.id}>
                      {activity.name}
                    </MenuItem>
                  );
                })
              : null}
          </SelectField>
        ) : (
          <ManyGroupsSelector groups={groups} onChange={onChange} errorText={errors.groupId} />
        )}
        <Typography variant="h6" sx={{ pt: 2, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          Na dzień
        </Typography>
        <Box sx={{ display: { sm: 'flex' }, justifyContent: { xs: 'center', sm: 'space-between' } }}>
          <Box
            sx={{
              pb: { xs: 1, sm: 0 },
              mr: { sm: 2 },
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}>
            <DatePicker
              name="date"
              value={date ? new Date(date) : undefined}
              onChange={(e, value) => onChange({ target: { name: 'date', value: moment(value).format('YYYY-MM-DD') } })}
              errorText={errors.date}
            />
          </Box>
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

SingleDateAndGroupPickerTemplate.propTypes = {
  groupId: PropTypes.string,
  date: PropTypes.string,
  groups: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  legend: PropTypes.string.isRequired,
  activities: PropTypes.array,
  activityId: PropTypes.string,
  manyGroupsSelector: PropTypes.bool
};

export default SingleDateAndGroupPickerTemplate;
