import React, { useMemo, useState } from 'react';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import IconButtonPipe from '../../../common/IconButtonPipe';
import DatePicker from '../../../common/DatePicker';
import ClearButtonWrapper from '../../../common/ClearButtonWrapper';
import { Clear } from '@mui/icons-material';
import {
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  TextField,
  Paper,
  Badge,
  Chip,
  FormLabel,
  Popover
} from '@mui/material';
import PropTypes from 'prop-types';
import mime from 'mime';
import FileSpecificIcon from '../../../common/FileSpecificIcon/FileSpecificIcon';
import { FILE_CATEGORY } from '../../../../constants/fileCategory';
import makeStyles from '@mui/styles/makeStyles';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RichMultiSelectField from '../../../formik/RichMultiSelectField/RichMultiSelectField';
import SingleUserRoleChip from '../SingleUserRole/SingleUserRoleChip';
import SingleUserRole from '../SingleUserRole/SingleUserRole';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { userRolesOptions as normalUserRolesOptions } from '../../../../forms/virtualDisc/helpers/userRolesOptions';
import { reducedUserRolesOptions } from '../../../../forms/virtualDisc/helpers/reducedUserRolesOptions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: 350
  }
});

const filesAvailableCategories = [
  { label: 'Pliki PDF', type: FILE_CATEGORY.pdf[0], value: FILE_CATEGORY.pdf },
  { label: 'Arkusze kalkulacyjne', type: FILE_CATEGORY.spreadsheet[0], value: FILE_CATEGORY.spreadsheet },
  { label: 'Dokumenty ', type: FILE_CATEGORY.text[0], value: FILE_CATEGORY.text },
  { label: 'Audio', type: FILE_CATEGORY.audio[0], value: FILE_CATEGORY.audio },
  { label: 'Filmy', type: FILE_CATEGORY.video[0], value: FILE_CATEGORY.video },
  { label: 'Archiwa', type: FILE_CATEGORY.compressed[0], value: FILE_CATEGORY.compressed },
  { label: 'Zdjęcia', type: FILE_CATEGORY.image[0], value: FILE_CATEGORY.image },
  { label: 'Prezentacje', type: FILE_CATEGORY.presentation[0], value: FILE_CATEGORY.presentation },
  { label: 'Foldery', type: 'folder', value: ['folder'] },
  {
    label: 'Inne',
    others: true,
    value: Object.values(FILE_CATEGORY).flat()
  }
];

const Filter = ({
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  filesExtensions,
  onFileExtensionsChange,
  onResetFilters,
  selectedUserRoles,
  onSelectedUserRolesChange,
  isOwnerFile,
  onOwnerFileChange,
  isManagement
}) => {
  const cl = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const userRolesOptions = nadarzyn ? reducedUserRolesOptions : normalUserRolesOptions;
  const isDateInvalid = useMemo(() => {
    if (dateFrom !== null && dateTo !== null) {
      return dateFrom > dateTo;
    }
    return false;
  }, [dateFrom, dateTo]);

  return (
    <>
      <IconButtonPipe
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        tooltip="Filtruj"
        aria-haspopup="true"
        aria-describedby={id}>
        {filesExtensions.length > 0 || selectedUserRoles.length > 0 || dateFrom || dateTo || isOwnerFile ? (
          <Badge badgeContent="!">
            <TuneRoundedIcon />
          </Badge>
        ) : (
          <TuneRoundedIcon />
        )}
      </IconButtonPipe>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{ paper: cl.wrapper }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <>
          <Paper sx={{ p: 2, pl: 3 }}>
            <Box sx={{ display: 'flex', alignItems: ' center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: ' center' }}>
                <Typography variant="h6">Filtry</Typography>
                <IconButtonPipe onClick={onResetFilters} tooltip="Zresetuj filtry">
                  <RotateLeftIcon />
                </IconButtonPipe>
              </Box>
              <Box>
                <IconButtonPipe onClick={() => setAnchorEl(null)} tooltip="Zamknij okno filtrów">
                  <Clear />
                </IconButtonPipe>
              </Box>
            </Box>
            {isManagement && (
              <RichMultiSelectField
                options={[
                  ...userRolesOptions,
                  {
                    id: 'specialOptionsOnList',
                    name: 'Bezpośrednich osób',
                    role: 'shareToMoreThenOneUser'
                  }
                ]}
                value={selectedUserRoles}
                onChange={onSelectedUserRolesChange}
                onDelete={(values) => {
                  onSelectedUserRolesChange(values);
                }}
                name="selectedRoles"
                label="Udostępnione dla"
                chipElement={<SingleUserRoleChip />}
                selectElement={<SingleUserRole />}
                SelectDisplayStylesProps={{ gridTemplateColumns: undefined }}
              />
            )}
            <Autocomplete
              value={filesExtensions}
              onChange={(event, newValue) => {
                onFileExtensionsChange(newValue);
              }}
              multiple
              id="tags-filled"
              disableCloseOnSelect
              options={filesAvailableCategories}
              getOptionLabel={(option) => option.label}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    icon={
                      <FileSpecificIcon
                        type={option.type === 'folder' ? 'folder' : mime.getType(option.type)}
                        style={{ p: 3 }}
                      />
                    }
                    variant="outlined"
                    label={
                      <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="chipContent">
                        {option.label}
                      </Typography>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderOption={(props, option) => {
                return (
                  <Box component="li" {...props}>
                    <FileSpecificIcon type={option.type === 'folder' ? 'folder' : mime.getType(option.type)} />
                    <Typography sx={{ ml: 2 }}>{option.label}</Typography>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Typ plików"
                  sx={{ mt: 2 }}
                  placeholder="Szukaj"
                  InputLabelProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                />
              )}
            />
            <Box sx={{ pt: 2 }}>
              <FormLabel component="legend" sx={{ mb: 1 }}>
                <Typography> Data dodania plików</Typography>
              </FormLabel>
              <ClearButtonWrapper onClear={() => setDateFrom(null)} disabled={!dateFrom} contrast>
                <DatePicker
                  onChange={(val) => setDateFrom(val)}
                  value={dateFrom}
                  floatingLabelText="Od"
                  name="timeFrom"
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper onClear={() => setDateTo(null)} disabled={!dateTo} contrast>
                <DatePicker
                  onChange={(val) => setDateTo(val)}
                  value={dateTo}
                  floatingLabelText="Do"
                  name="timeTo"
                  displayError={isDateInvalid}
                  errorContent="Data początkowa nie może być późniejsza od daty końcowej"
                />
              </ClearButtonWrapper>
            </Box>
            {isManagement && (
              <FormControlLabel
                control={<Checkbox checked={isOwnerFile} onChange={onOwnerFileChange} name="checked" />}
                label={
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography>Pokaż tylko moje pliki</Typography>
                    <FolderSharedOutlinedIcon fontSize="large" sx={{ ml: 1 }} />
                  </Box>
                }
              />
            )}
          </Paper>
        </>
      </Popover>
    </>
  );
};

Filter.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  filesExtensions: PropTypes.array,
  filesType: PropTypes.string,
  onFileExtensionsChange: PropTypes.func,
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  selectedUserRoles: PropTypes.array,
  onSelectedUserRolesChange: PropTypes.func.isRequired,
  isOwnerFile: PropTypes.bool.isRequired,
  onOwnerFileChange: PropTypes.func.isRequired,
  isManagement: PropTypes.bool.isRequired
};

export default Filter;
