import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ArchivalInfo = ({ archivalInfo, contrast, amount, ...other }) => {
  if (!archivalInfo) return null;
  const { archivedOn, archivedBy } = archivalInfo;
  return (
    <Typography
      component="span"
      sx={{
        mr: 1,
        color: contrast ? (theme) => theme.palette.color.primary : (theme) => theme.palette.color.contrast
      }}>
      Zarchiwizowano
      <span style={{ marginLeft: '8px', marginRight: '8px' }}>
        <strong>{archivedOn}</strong>
      </span>
      przez
      <span style={{ marginLeft: '8px', marginRighr: '8px' }}>
        <strong>{archivedBy.userFullName}</strong>
      </span>
    </Typography>
  );
};

ArchivalInfo.propTypes = {
  archivalInfo: PropTypes.object,
  contrast: PropTypes.bool,
  amount: PropTypes.number
};

export default ArchivalInfo;
