import PropTypes from 'prop-types';
import React from 'react';
import Divider from '@mui/material/Divider';
import numberFormatter from '../../../utils/numberFormatter';
import { ChargingSchemeTypes } from '../../../constants/chargingSchemeTypes';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ChargingDeductionModes } from '../../../constants/chargingDeductionModes';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import _ from 'lodash';
import { Box, Grid, Typography } from '@mui/material';

const SettlementCalculationDetails = ({ calculation }) => {
  const getTimeFromMinutes = (minutes) => `${Math.floor(minutes / 60)}:${_.padStart(minutes % 60, 2, 0)}`;

  const getTotalChargeableTime = () => {
    if (calculation.schemeSnaphot.schemeType === ChargingSchemeTypes.hourly.value) {
      return getTimeFromMinutes(calculation.attendanceSummarySnapshot.totalChargeableMinutesPresent);
    }

    const { daysPresentChargeable } = calculation.attendanceSummarySnapshot;
    const { daysPresent } = calculation.attendanceSummarySnapshot;
    return `${daysPresentChargeable != null ? daysPresentChargeable : daysPresent} dni`;
  };

  const getTotalTime = () => {
    if (calculation.schemeSnaphot.schemeType === ChargingSchemeTypes.hourly.value) {
      return getTimeFromMinutes(calculation.attendanceSummarySnapshot.totalMinutesPresent);
    }
    return `${calculation.attendanceSummarySnapshot.daysPresent} dni`;
  };

  const getCorrection = (correctionSelector) => {
    return calculation.corrections ? _.round(correctionSelector(calculation.corrections) || 0, 2) : 0;
  };

  const renderDataRow = (description, amount, subDescription) => {
    return (
      <Grid container>
        <Grid item xs={6} sm={9}>
          <Box
            sx={{
              m: 1,
              p: 1,
              pr: 0,
              mr: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignItems: 'center',
              textTransform: 'uppercase'
            }}>
            {description}

            <Typography>{subDescription}:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', pr: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}>
            {numberFormatter.format(amount)} zł
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderCorrectionDataRow = (correctionSelector, correctionTypeDescription) => {
    const correction = getCorrection(correctionSelector);
    return correction
      ? renderDataRow(correction < 0 ? 'Odpis' : 'Niedopłata', correction, correctionTypeDescription)
      : null;
  };

  const renderHeader = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Box
            sx={{
              m: 1,
              p: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              background: (theme) => theme.palette.background.color5
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>Okres</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <EventOutlinedIcon />
              <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}>
                {calculation.calculatedForMonth}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            sx={{
              m: 1,
              p: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              background: (theme) => theme.palette.background.color5
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>Czas pobytu</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TimerOutlinedIcon />
              {calculation.attendanceSummarySnapshot.totalMinutesPresent === 0 &&
              calculation.schemeSnaphot.reportType !== ChargingReportTypes.staffCatering.value
                ? 'brak'
                : getTotalChargeableTime()}
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Całkowity czas</Typography>
              <Typography>{getTotalTime()}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            sx={{
              m: 1,
              p: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              background: (theme) => theme.palette.background.color5
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>Stawka</Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightRegular,
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {numberFormatter.format(calculation.schemeSnaphot.rate)} zł
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            sx={{
              m: 1,
              p: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              background: (theme) => theme.palette.background.color5
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>Opłata</Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightRegular,
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {numberFormatter.format(calculation.baseDue)} zł
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const getDeductionSubDescription = (appliedDeduction) => {
    switch (appliedDeduction.mode) {
      case ChargingDeductionModes.setTo.value:
        return `kwota ustalona na ${numberFormatter.format(appliedDeduction.amount)}`;
      case ChargingDeductionModes.reduceByAmount.value:
        if (appliedDeduction.base === 'days_in_row') {
          return `kwota obniżona o ${numberFormatter.format(calculation.baseDue - calculation.baseAfterDeduction)}`;
        }
        return `kwota obniżona o ${numberFormatter.format(appliedDeduction.amount)}`;
      case ChargingDeductionModes.reduceByPercent.value:
        return `kwota obniżona o ${appliedDeduction.percentage} %`;
      default:
        return null;
    }
  };

  const getDeductionAmount = () => {
    switch (calculation.appliedDeduction.mode) {
      case ChargingDeductionModes.setTo.value:
        return calculation.appliedDeduction.amount;
      case ChargingDeductionModes.reduceByAmount.value:
        if (calculation.appliedDeduction.base === 'days_in_row') {
          return calculation.baseAfterDeduction;
        }
        return calculation.baseDue - calculation.appliedDeduction.amount;
      case ChargingDeductionModes.reduceByPercent.value:
        return _.round(calculation.baseDue * (1 - calculation.appliedDeduction.percentage / 100), 2);
      default:
        return null;
    }
  };

  const getAmountAfterRelief = () => {
    const base = calculation.appliedDeduction ? getDeductionAmount(calculation) : calculation.baseDue;

    return -(base - calculation.baseAfterRelief);
  };

  const getSchemeDetails = () => {
    return calculation.correctionScheme ? `, na podstawie schematu ${calculation.correctionScheme.schemeName}` : '';
  };

  return (
    <Box>
      <Typography variant="h6">{calculation.schemeSnaphot.name}</Typography>
      {renderHeader()}
      {calculation.appliedDeduction
        ? renderDataRow(
            'Zastosowano rabat za nieobecność',
            getDeductionAmount(),
            getDeductionSubDescription(calculation.appliedDeduction)
          )
        : null}
      {calculation.reliefSnapshot ? renderDataRow(calculation.reliefSnapshot.name, getAmountAfterRelief()) : null}
      {renderCorrectionDataRow((x) => x.openingBalance, '(poprzedni miesiąc - bilans otwarcia)')}
      {renderCorrectionDataRow(
        (x) => x.deductionFromPreviousMonth,
        calculation.previousMonthAppliedDeduction
          ? ` (rabat za poprzedni miesiąc - ${getDeductionSubDescription(calculation.previousMonthAppliedDeduction)})`
          : ''
      )}
      {renderCorrectionDataRow(
        (x) => x.inAdvanceDifferenceFromPreviousMonth,
        `(poprzedni miesiąc${getSchemeDetails()})`
      )}
      {renderCorrectionDataRow(
        (x) => x.inAdvanceDifferenceFromCurrentMonth,
        `(zaplanowane nieobecności${getSchemeDetails()})`
      )}
      {renderCorrectionDataRow((x) => x.inAdvanceRemainingOverpayment, '(saldo)')}
      {renderCorrectionDataRow((x) => x.penaltyFromPreviousMonth, '(kara za spóźnienie)')}
      {calculation.penaltyAmount ? renderDataRow('Kara za spóźnienie', calculation.penaltyAmount) : null}
      {calculation.grantAmount
        ? renderDataRow(
            `Dotacja (Pakiet ${calculation.grantSnapshot.hoursAmount} godz. ${calculation.grantSnapshot.chargeableDays} dni)`,
            calculation.grantAmount
          )
        : null}
    </Box>
  );
};

SettlementCalculationDetails.propTypes = {
  calculation: PropTypes.object.isRequired
};

export default SettlementCalculationDetails;
