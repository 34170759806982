import React from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@mui/icons-material/Description';
import ElectronicDiaryIcon from '@mui/icons-material/LibraryBooks';
import ChildrenIcon from '@mui/icons-material/Face';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  iconMargin: {
    margin: '15px',
    transform: 'scale(2)'
  }
}));

const ReportsCategoriesIcons = ({ category }) => {
  const cl = useStyles();

  if (category === 'all') {
    return <DescriptionIcon className={cl.iconMargin} />;
  }
  if (category === 'digitalDiary') {
    return <ElectronicDiaryIcon className={cl.iconMargin} />;
  }
  if (category === 'childrenWithAttendance') {
    return <ChildrenIcon className={cl.iconMargin} />;
  }
  if (category === 'groups') {
    return <GroupIcon className={cl.iconMargin} />;
  }
  if (category === 'payments') {
    return <AttachMoneyIcon className={cl.iconMargin} />;
  }
  return <PictureAsPdfIcon className={cl.iconMargin} />;
};

ReportsCategoriesIcons.propTypes = {
  category: PropTypes.string.isRequired
};

export default ReportsCategoriesIcons;
