import _ from 'lodash';
import validatejs from 'validate.js';

export const check = validatejs.validate;

export function validate(model, constraints, rootmodel) {
  const result = Object.keys(constraints).map((key) => {
    const validator = constraints[key];

    const message = check.isFunction(validator)
      ? validator(_.get(model, key), rootmodel || model)
      : validate(model[key], constraints[key], rootmodel || model);
    return message && { [key]: message };
  });
  return result.length ? Object.assign({}, ...result) : null;
}
