import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PsychologicalHelpContainer from './PsychologicalHelpContainer';

const PsychologicalHelpPage = ({ location }) => {
  return (
    <LoadingRenderWrapper>
      <PsychologicalHelpContainer location={location} />
    </LoadingRenderWrapper>
  );
};

PsychologicalHelpPage.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default PsychologicalHelpPage;
