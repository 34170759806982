import PropTypes from 'prop-types';
import React from 'react';
import GroupChildrenList from './GroupChildrenList';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import EmptyState from '../../common/EmptyState';
import GroupChildrenEditListv2 from './GroupChildrenEditListv2';
import { connect } from 'react-redux';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import { EnrolledToGroupModel } from '../../../models/children/ChildModels';

class GroupChildrenCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: null
    };
    this.getEnrolledChildrenWithDetails = this.getEnrolledChildrenWithDetails.bind(this);
    this.getNotEnrolledChildren = this.getNotEnrolledChildren.bind(this);
  }

  renderEditForm() {
    return (
      <GroupChildrenEditListv2
        allPupils={
          this.props.isPsychological ? this.getNotEnrolledPsychologicalChildren() : this.getNotEnrolledChildren()
        }
        enrolledPupilsIds={this.props.group.enrolledChildren.map((e) => e.id)}
        onChange={this.props.onChange}
      />
    );
  }

  /*eslint 'react/sort-comp':0 */
  getEnrolledChildrenWithDetails() {
    if (this.props.isPsychological) {
      return this.props.group.enrolledChildren
        .map((child) => ({
          ...this.props.pupils.find((c) => c.id === child.id),
          enrolledToGroup: new EnrolledToGroupModel().assign({ enrolledAt: child.enrolledAt })
        }))
        .filter((child) => !!child && !!child.id)
        .sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1));
    }
    return this.props.group.enrolledChildren
      .map((child) => this.props.pupils.find((c) => c.id === child.id))
      .filter((child) => !!child);
  }

  getNotEnrolledChildren() {
    return this.props.pupils.filter((child) => !child.enrolledToGroup || !child.enrolledToGroup.id);
  }

  getEnrolledPsychologicalChildrenWithDetails() {
    return this.props.group.enrolledChildren
      .map((child) => this.props.pupils.find((c) => c.id === child.id))
      .filter((child) => !!child);
  }

  getNotEnrolledPsychologicalChildren() {
    return this.props.pupils.filter((child) => !child.enrolledToPsychologicalPedagogicalGroup?.length > 0);
  }

  renderReadonlyForm() {
    return <GroupChildrenList pupils={this.getEnrolledChildrenWithDetails()} onRemoveChild={this.props.onRemove} />;
  }

  render() {
    return (
      <CardDialogEditForm
        maxWidth="md"
        title={this.props.nadarzyn ? 'Uczniowie' : 'Dzieci'}
        subtitle={`${this.props.group.enrolledChildren ? this.props.group.enrolledChildren.length : 0}`}
        isInitiallyExpanded={true}
        onSave={() => this.props.onSave(this.props.group.selectedChildren)}
        onCancel={this.props.onCancel}
        onAction={this.props.onEdit}
        onValidationDone={(errors) => this.setState({ errors })}
        isDialogOpen={this.props.isEditing}
        isProcessing={this.props.isProcessing}
        statePathToUi="groupDetailsUi.enrolledChildren"
        readonlyForm={this.renderReadonlyForm()}
        editForm={this.renderEditForm()}
        isEmpty={this.props.group.enrolledChildren.length < 1}
        emptyForm={<EmptyState contrast message={this.props.nadarzyn ? 'Brak uczniów' : 'Brak dzieci'} />}
        saveDisabled={
          this.getNotEnrolledChildren() === 0 ||
          !this.props.group.selectedChildren ||
          this.props.group.selectedChildren.length === 0
        }
        actionTitle={`Przypisz ${this.props.nadarzyn ? 'uczniów' : 'dzieci'} do grupy`}
        actionTitleIcon={<ChildCareIcon sx={{ fontSize: 32, mx: 2 }} />}
        actionLabel={`Przypisz ${this.props.nadarzyn ? 'uczniów' : 'dzieci'}`}
      />
    );
  }
}

GroupChildrenCard.propTypes = {
  group: PropTypes.object.isRequired,
  pupils: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  nadarzyn: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
};

export default connect(mapStateToProps)(GroupChildrenCard);
