import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import ReportsList from '../ReportsList';
import { ReportModel } from '../../../../models/configuration/reports/ReportModel';
import _ from 'lodash';

class ReportsConfigurationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: this.props.reports
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(visibilityChanged) {
    const reports = this.state.reports.map((report) => new ReportModel().assign(report));
    const report = _.find(reports, (r) => r.type === visibilityChanged.reportType);
    if (!visibilityChanged.visible) {
      _.pull(report.visibleTo, visibilityChanged.role);
    } else {
      report.visibleTo.push(visibilityChanged.role);
    }

    return this.setState({ reports });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header="Zmiana widoczności raportów"
        onSave={() => onSave(this.state.reports)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        statePathToUi="configuration.reportsConfigurationUi"
        onValidationDone={() => {}}>
        <ReportsList reports={this.state.reports} onChange={this.handleChange} />
      </DialogForm>
    );
  }
}

ReportsConfigurationDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired
};

export default ReportsConfigurationDialog;
