import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as numberingSchemesActions from '../../../actions/numberingSchemesActions';
import { NumberingSchemeModel } from '../../../models/configuration/numberingSchemes/NumberingSchemeModels';
import { Box, Button, Paper } from '@mui/material';
import NumberingSchemeDialog from './dialogs/NumberingSchemeDialog';
import NumberingSchemesList from './NumberingSchemesList';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import ContentWrapper from '../../common/contentWrapper';

class NumberingSchemesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentNumberingScheme: null
    };
    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleCreate() {
    this.setState({ currentNumberingScheme: new NumberingSchemeModel() });
    this.props.actions.createNumberingSchemeDetailsStart();
  }

  handleEdit(scheme) {
    this.setState({ currentNumberingScheme: scheme });
    this.props.actions.updateNumberingSchemeStart();
  }

  handleSave(scheme) {
    if (scheme.id) {
      this.props.actions.updateNumberingSchemeAsync(scheme);
    } else {
      this.props.actions.createNumberingSchemeDetailsAsync(scheme);
    }
  }

  handleCancel() {
    if (this.state.currentNumberingScheme) {
      this.props.actions.updateNumberingSchemeCancel();
    } else {
      this.props.actions.createNumberingSchemeDetailsCancel();
    }
    this.setState({ currentNumberingScheme: null });
  }

  handleDelete(schemeId) {
    this.props.actions.deleteNumberingSchemeDetailsAsync(schemeId);
  }

  renderNumberingSchemeDialog() {
    if (this.props.ui.isEditing) {
      return (
        <NumberingSchemeDialog
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          isDialogOpen={this.props.ui.isEditing}
          isProcessing={this.props.ui.isProcessing}
          scheme={this.state.currentNumberingScheme}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Serie numeracji" titleIcon={<FormatListNumberedIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" aria-label="Dodaj numerację" onClick={this.handleCreate} sx={{ width: 200 }}>
                Dodaj numerację
              </Button>
            </Box>

            <Paper sx={{ mt: 2, p: 3 }}>
              <NumberingSchemesList
                schemes={this.props.numberingSchemes}
                onSelected={this.handleEdit}
                onRemove={this.handleDelete}
              />
            </Paper>
            {this.renderNumberingSchemeDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

NumberingSchemesPage.propTypes = {
  numberingSchemes: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    numberingSchemes: state.configuration.numberingSchemes,
    ui: state.configuration.numberingSchemesUi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(numberingSchemesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberingSchemesPage);
