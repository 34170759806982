import BaseModel from '../BaseModel';

export class TestApiModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = '';
  }
}
