import BaseModel from '../../BaseModel';
import { TimeModel } from '../../TimeModel';
import { afterHoursChargingBase } from '../../../constants/afterHoursChargingBase';
import { featuresFromPrefixValidator } from '../../../utils/featuresFromPrefixValidator';

export class UnitAddress extends BaseModel {
  constructor() {
    super();
    this.name = '';
    this.city = '';
    this.address = '';
    this.zipCode = '';
    this.nip = '';
    this.phoneNumber = '';
    this.facilityNumber = '';
    this.voivodeship = '';
    this.community = '';
  }
}

export class WorkingHours extends BaseModel {
  constructor() {
    super();
    this.from = new TimeModel();
    this.to = new TimeModel();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.from = new TimeModel().assign(assigned.from);
    assigned.to = new TimeModel().assign(assigned.to);
  }
}

export class PenaltiesSettings extends BaseModel {
  constructor(
    afterHoursChargingRate,
    afterHoursChargingInterval,
    afterHoursChargingOffsetInMinutes,
    afterHoursChargingBaseValue
  ) {
    super();
    this.afterHoursChargingRate = afterHoursChargingRate;
    this.afterHoursChargingBase = afterHoursChargingBaseValue;
    this.afterHoursChargingInterval = afterHoursChargingInterval;
    this.afterHoursChargingOffsetInMinutes = afterHoursChargingOffsetInMinutes;

    if (
      afterHoursChargingRate &&
      afterHoursChargingInterval &&
      !afterHoursChargingBaseValue &&
      !afterHoursChargingOffsetInMinutes
    ) {
      this.afterHoursChargingBase = afterHoursChargingBase.kindergartenWorkingHours.value;
      this.afterHoursChargingOffsetInMinutes = 0;
    }
  }
}

export class GuardiansQRServiceSettings extends BaseModel {
  constructor() {
    super();
    this.dynamic = false;
    this.enabled = false;
  }
}

export class UnitModel extends BaseModel {
  constructor() {
    super();
    this.onlinePaymentsEnabled = false;
    this.onlinePaymentsIncludeCommission = true;
    this.daycareEnabled = false;
    this.address = new UnitAddress();
    this.workingHours = new WorkingHours();
    this.penalties = new PenaltiesSettings();
    this.twoWayMessagingEnabled = false;
    this.reportAbsenceInDaysOffEnabled = false;
    this.legalGuardiansAbsenceRemovalDeadlineHours = null;
    this.noHoursAttendancesEnabled = false;
    this.defaultPublishingHouseId = '';
    this.features = {
      ...featuresFromPrefixValidator()
    };
    this.insurance = {};
    this.oneSignalWebAppId = null;
    this.postsModifyingEnabled = true;
    this.individualBankAccounts = true;
    this.childrenAgeInfoEnabled = true;
    this.sendingChildPickedUpNotificationBlocked = false;
    this.editingChildDataByTeacherBlocked = false;
    this.addContractNumberToSettlementNumber = false;
    this.teacherAllGroupVisibility = false;
    this.teacherAllBoardPostsVisibility = false;
    this.legalGuardianPartOfBoardPostsVisibility = false;
    this.guardiansQRServiceSettings = new GuardiansQRServiceSettings();
    this.sendingOverduesNotification = false;
    // this.notificationSendingEnabled = true;
    this.mealsCateringDues = false;
    this.allowDebtCollection = false;
    this.allowAdaptation = false;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.address = new UnitAddress().assign(assigned.address);
    assigned.workingHours = new WorkingHours().assign(assigned.workingHours);
    assigned.guardiansQRServiceSettings = new GuardiansQRServiceSettings().assign(assigned.guardiansQRServiceSettings);
    assigned.features = {
      ...assigned.features,
      ...featuresFromPrefixValidator()
    };
  }
}
