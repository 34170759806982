import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import ChildToHelpRow from "./ChildToHelpRow";
import _ from "lodash";
import EmptyState from "../common/EmptyState";

const PsychologicalTable = ({ childrenToHelp, rowsPerPage, handleChangePage, page, openEditDialog }) => {
  return (
    <Paper sx={{ my: 2, p: 2 }}>
      {childrenToHelp.length ? (
        <TableContainer component={Box}>
          <Table aria-label="psychological-table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 200 }}>
                  Imie i nazwisko
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 230 }}>
                  Data rozpoczęcia
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 230 }}>
                  Data zakończenia
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 200 }}>
                  Przyczyna
                </TableCell>
                {/* <TableCell align="left" sx={{ fontSize: 18, minWidth: 200 }}>
                          Grupa
                        </TableCell> */}
                <TableCell align="left">
                  <Typography
                    sx={{
                      border: 0,
                      clip: "rect(0 0 0 0)",
                      height: "1px",
                      ml: "-1px",
                      overflow: "hidden",
                      p: 0,
                      top: 0,
                      width: "1px"
                    }}>
                    Menu
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 && childrenToHelp
                  ? childrenToHelp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : childrenToHelp
              ).map((child, index) => (
                <ChildToHelpRow child={child} key={child.id} openEditDialog={openEditDialog} />
              ))}
            </TableBody>
          </Table>
          <Pagination
            siblingCount={1}
            count={_.ceil(childrenToHelp.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 1,
              mt: 2
            }}
          />
        </TableContainer>
      ) : (
        <EmptyState message="Brak dzieci" contrast />
      )}
    </Paper>
  );
};

export default PsychologicalTable;
