// components/OverpaymentsListBody.jsx
import TableBody from '@mui/material/TableBody';
import { getComparator, stableSort } from '../../../utils/tableSorting';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import numberFormatter from '../../../utils/numberFormatter';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import DotsMenu from '../../common/menu/DotsMenu';
import { useDispatch } from 'react-redux';
import { generateReportAsync } from '../../../actions/reportsActions';

const OverpaymentsListBody = ({
  overpayments,
  page,
  rowsPerPage,
  order,
  orderBy,
  notificationList,
  setNotificationList
}) => {
  const dispatch = useDispatch();
  const isChecked = (personId, walletType) => {
    return notificationList.some((item) => item.personId === personId && item.walletType === walletType);
  };

  const handleCheckboxChange = (personId, walletType) => {
    const updatedList = isChecked(personId, walletType)
      ? notificationList.filter((item) => !(item.personId === personId && item.walletType === walletType))
      : [...notificationList, { personId, walletType }];

    setNotificationList(updatedList);
  };

  const handleReportGenerate = (childId, amount) => {
    dispatch(generateReportAsync('refundOfFees', 'pdf', { childId, amount }));
  };

  return (
    <TableBody>
      {stableSort(overpayments, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return (
            <TableRow
              key={`${row.personId}-${row.walletType}`}
              sx={{
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}>
              <TableCell align="left">
                <Checkbox
                  checked={isChecked(row.personId, row.walletType)}
                  onChange={() => handleCheckboxChange(row.personId, row.walletType)}
                />
              </TableCell>
              <TableCell align="left">
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
                  {row.firstName}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
                  {row.lastName}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{row.number}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{numberFormatter.format(row.amount)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{ChargingReportTypes[row.walletType]?.name || 'Portfel bazowy'}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{moment(row.date).format('YYYY-MM-DD')}</Typography>
              </TableCell>

              <TableCell sx={{ width: 30 }} align="right">
                <DotsMenu disabled={false} sx={{ m: 0, p: 0 }}>
                  {() => [
                    <MenuItem key="1" onClick={() => handleReportGenerate(row.personId, row.amount)} disabled={false}>
                      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                        Pobierz wydruk zwrotu
                      </Typography>
                    </MenuItem>
                  ]}
                </DotsMenu>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

OverpaymentsListBody.propTypes = {
  overpayments: PropTypes.array.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  notificationList: PropTypes.array,
  setNotificationList: PropTypes.func
};

export default OverpaymentsListBody;
