import * as types from '../actions/actionTypes';

export default function updateRollbarUserTracking(prevState, nextState, action) {
  if (!window.Rollbar) return;

  // run this either when app starts or when login status changed.
  if (prevState.auth.userId !== nextState.auth.userId || action.type === types.PRELOAD_DATA) {
    if (nextState.auth.userId) {
      window.Rollbar.configure({
        payload: {
          person: {
            id: nextState.auth.userId,
            username: nextState.auth.userName,
            email: nextState.auth.userName
          }
        }
      });
    } else {
      window.Rollbar.configure({
        payload: { person: null }
      });
    }
  }
}
