import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgramContainer from '../common/psychologicalWorkProgram/ProgramContainer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IndividualProgramDialogs from './IndividualProgramDialogs';
import {
  deleteProgramElement,
  setGeneralDialogClosed,
  setGeneralDialogOpen
} from '../../actions/psychologicalIndividualClassesActions';

const IndividualProgram = () => {
  const dispatch = useDispatch();

  const basicGoals = useSelector((state) => state.psychologicalIndividualWorkProgram.basicGoals);
  const specificGoals = useSelector((state) => state.psychologicalIndividualWorkProgram.specificGoals);
  const methods = useSelector((state) => state.psychologicalIndividualWorkProgram.methods);
  const conclusions = useSelector((state) => state.psychologicalIndividualWorkProgram.conclusions);
  const Ui = useSelector((state) => state.psychologicalIndividualProgramUi.individualProgram);
  const [currentData, setCurrentData] = useState(null);
  const assistanceId = useSelector((state) => state.psychologicalIndividualWorkProgram.id);

  const [dialogType, setDialogType] = useState('');

  const handleSave = () => {
    setCurrentData(null);
  };

  const handleDelete = (data) => {
    dispatch(deleteProgramElement(data, assistanceId));
  };

  const handleGeneralDialogOpen = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('basicGoals');
    dispatch(setGeneralDialogOpen());
  };

  const handleSpecificDialogOpen = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('specificGoals');
    dispatch(setGeneralDialogOpen());
  };

  const handleGeneralMethods = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('methods');
    dispatch(setGeneralDialogOpen());
  };

  const handleGeneralConclusions = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('conclusions');
    dispatch(setGeneralDialogOpen());
  };

  const handleCancel = () => {
    setDialogType('');
    setCurrentData(null);
    dispatch(setGeneralDialogClosed());
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Indywidualny program pracy z uczniem</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProgramContainer
              title="Cele ogólne"
              data={basicGoals}
              onClick={() => handleGeneralDialogOpen(null)}
              onEdit={handleGeneralDialogOpen}
              onDelete={handleDelete}
            />
            <ProgramContainer
              title="Cele szczegółowe"
              data={specificGoals}
              onClick={() => {
                handleSpecificDialogOpen(null);
              }}
              onEdit={handleSpecificDialogOpen}
              onDelete={handleDelete}
            />
            <ProgramContainer
              title="Metody"
              data={methods}
              onClick={() => {
                handleGeneralMethods(null);
              }}
              onEdit={handleGeneralMethods}
              onDelete={handleDelete}
            />
            <ProgramContainer
              title="Wnioski"
              data={conclusions}
              onClick={() => {
                handleGeneralConclusions(null);
              }}
              onEdit={handleGeneralConclusions}
              onDelete={handleDelete}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      {Ui.isEditing && (
        <IndividualProgramDialogs
          dialogType={dialogType}
          handleCancel={handleCancel}
          selectedElement={currentData}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

export default IndividualProgram;
