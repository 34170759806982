import { Box } from '@mui/material';
import Checkbox from '../../common/Checkbox';
import PropTypes from 'prop-types';
import DatePickerAndGroupTemplate from './common/DateAndGroupPickerTemplate';
import { ReportTypes } from '../../../constants/reportTypes';

const ChildrenAttendanceInHoursDuesReport = ({
  groupId,
  startDate,
  endDate,
  groups,
  advancedHourlyCalculation,
  onChange,
  errors
}) => {
  return (
    <Box>
      <DatePickerAndGroupTemplate
        legend={`Pola dla generowania raportu ${ReportTypes.childrenAttendanceInHoursDuesXls.name}`}
        groupId={groupId}
        startDate={startDate}
        endDate={endDate}
        groups={groups}
        onChange={onChange}
        errors={errors}
      />
      <Checkbox
        label="Rzeczywiste godziny do zapłaty w przypadku rozliczeń godzinowych"
        defaultChecked={advancedHourlyCalculation}
        onCheck={(event, isChecked) => onChange({ target: { name: 'advancedHourlyCalculation', value: isChecked } })}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

ChildrenAttendanceInHoursDuesReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  advancedHourlyCalculation: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ChildrenAttendanceInHoursDuesReport;
