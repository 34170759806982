import PropTypes from 'prop-types';
import React from 'react';
import PaidIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/AccessTime';
import FailedIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { PaymentMethods } from '../../../constants/paymentMethods';
import { PaymentStatus } from '../../../constants/paymentStatus';
import { PersonTypes } from '../../../constants/personTypes';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';
import numberFormatter from '../../../utils/numberFormatter';
import paymentsApi from '../../../api/PaymentsApi';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';

const RegisteredPaymentsList = ({ payments, undoPayment, cancelOnlinePayment, personType }) => {
  const getIcon = (payment) => {
    if (payment.method === PaymentMethods.online.value) {
      switch (payment.status) {
        case PaymentStatus.defined.value:
        case PaymentStatus.pending.value:
          return (
            <PendingIcon
              sx={{
                color: (theme) => theme.palette.color.color21
              }}
            />
          );
        case PaymentStatus.canceled.value:
        case PaymentStatus.failed.value:
          return (
            <FailedIcon
              sx={{
                color: (theme) => theme.palette.color.color14
              }}
            />
          );
        default:
          return (
            <PaidIcon
              sx={{
                color: (theme) => theme.palette.color.color12
              }}
            />
          );
      }
    }

    return (
      <PaidIcon
        sx={{
          color: (theme) => theme.palette.color.color12
        }}
      />
    );
  };

  const renderUndoAction = (payment) => {
    return undoPayment && payment.method !== PaymentMethods.online.value ? (
      <ActionConfirmationButton
        actionLabel="Wycofaj"
        confirmationText={
          personType === PersonTypes.child
            ? 'Kwota wycofywanej płatności zasili środki bilansu dziecka. Środki można wykorzystać na rozliczenie pozostałych lub przyszłych opłat, jak również zarejestrować ich wypłatę rodzicowi.'
            : 'Czy na pewno chcesz wycofać opłatę?'
        }
        confirmationTitle="Wycofanie wpłaty"
        isSecondary={true}
        confirmLabel="Wycofaj"
        onConfirm={() => undoPayment(payment.id)}
        onCancel={() => {}}
        sx={{ float: 'right' }}
      />
    ) : null;
  };

  const renderCancelOnlinePaymentAction = (payment) => {
    return cancelOnlinePayment &&
      payment.method === PaymentMethods.online.value &&
      payment.status === PaymentStatus.defined.value ? (
      <ActionConfirmationButton
        actionLabel="Anuluj"
        confirmationText="Płatność nie została jeszcze zakończona, wobec czego może zostać bezpiecznie anulowana. Czy chcesz anulować płatność elektroniczną?"
        confirmationTitle="Anulowanie płatności elektronicznej"
        isSecondary={true}
        confirmLabel="Tak"
        cancelLabel="Nie"
        onConfirm={() => cancelOnlinePayment(payment.onlinePaymentId)}
        onCancel={() => {}}
        sx={{ float: 'right' }}
      />
    ) : null;
  };

  const renderRetryPaymentButton = (payment) => {
    return payment.method === PaymentMethods.online.value &&
      payment.onlinePaymentLink &&
      (payment.status === PaymentStatus.defined.value || payment.status === PaymentStatus.pending.value) ? (
      <Button
        variant="contained"
        aria-label="Ponów"
        onClick={(event) => {
          event.stopPropagation();
          paymentsApi.postAsFormToGateway(payment.onlinePaymentLink);
        }}>
        <OpenInBrowserIcon
          sx={{
            color: (theme) => theme.palette.color.color1
          }}
        />
        Ponów
      </Button>
    ) : null;
  };

  const getSecondaryText = (isOnlinePayment, payment) => {
    const date = moment(payment.date).format(isOnlinePayment ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');
    const orderId = payment.onlinePaymentOrderId ? `Identyfikator: ${payment.onlinePaymentOrderId}` : '';
    const isInterestPayment = payment.isInterestPayment ? 'Opłata odsetkowa' : 'Opłata bazowa';
    return `${date} ${orderId} - ${isInterestPayment}`;
  };

  const getRightIconButton = (payment) => {
    if (!cancelOnlinePayment && !undoPayment) {
      return renderRetryPaymentButton(payment);
    }

    return payment.method !== PaymentMethods.online.value
      ? renderUndoAction(payment)
      : renderCancelOnlinePaymentAction(payment);
  };

  return (
    <List component="nav" aria-label="lista trwających transakcji">
      {payments.map((payment, index) => {
        const onlinePayment = payment.method === PaymentMethods.online.value;
        const status = onlinePayment ? PaymentStatus[payment.status].name : '';
        return (
          <ListItem key={index}>
            <ListItemIcon>{getIcon(payment)}</ListItemIcon>
            <ListItemText
              primary={
                `${PaymentMethods[payment.method].name} ${status}` +
                (payment.amount != null ? ` ${numberFormatter.format(payment.amount)} zł` : '')
              }
              secondary={getSecondaryText(onlinePayment, payment)}
              secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
            />
            {getRightIconButton(payment)}
          </ListItem>
        );
      })}
    </List>
  );
};
RegisteredPaymentsList.propTypes = {
  payments: PropTypes.array.isRequired,
  undoPayment: PropTypes.func,
  cancelOnlinePayment: PropTypes.func,
  personType: PropTypes.string
};

export default RegisteredPaymentsList;
