import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import RecipientDialog from './RecipientsDialog';
import { PrivateThreadModel } from '../../../models/privateMessages/PrivateMessagesModels';
import { Box, Button } from '@mui/material';
import { prepareThread } from '../containers/prepareThread';

const CreateThreadButton = (props) => {
  const {
    onCreate,
    recipients,
    userId,
    groups,
    threads,
    user,
    isCheckbox,
    onlyOneRecipient,
    onMultiMessage,
    multiMessageIsSended
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRecipents, setSelectedRecipients] = useState([]);

  useEffect(() => {
    setIsDialogOpen(false);
  }, [multiMessageIsSended]);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
    setSelectedRecipients([]);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedRecipients([]);
  };
  const handleRecipientSelect = (recipient) => {
    if (!!onlyOneRecipient) setSelectedRecipients([recipient.id]);
    else {
      setSelectedRecipients((prev) => {
        if (prev.find((id) => id === recipient.id)) {
          return prev.filter((id) => id !== recipient.id);
        } else {
          return [...prev, recipient.id];
        }
      });
    }
  };
  const handleMultiMessage = () => {
    const multiMessageThreads = [];
    selectedRecipents.forEach((recipient) => {
      const thread = threads.find(
        (thread) =>
          thread.participants
            .filter((p) => p.id !== userId)
            .map((p) => p.id)
            .join('') === recipient
      );
      if (!!thread) {
        multiMessageThreads.push(thread);
      } else {
        const thread = new PrivateThreadModel().assign({
          participants: [
            {
              id: userId,
              fullName: user.userFullName,
              role: user.role,
              photoUrl: user.profilePhoto
            },
            {
              id: recipient,
              fullName: `${recipients.find((u) => u.id === recipient).firstName} ${
                recipients.find((u) => u.id === recipient).lastName
              }`,
              role: recipients.find((u) => u.id === recipient).role || undefined,
              photoUrl: recipients.find((u) => u.id === recipient).photoUrl,
              children: recipients.find((u) => u.id === recipient).children || []
            }
          ]
        });
        multiMessageThreads.push(prepareThread(thread, recipients, groups, userId));
      }
    });
    onMultiMessage(multiMessageThreads, selectedRecipents);
  };
  const handleCreateThread = () => {
    let threadExist = false;
    if (threads) {
      threads.forEach((thread) => {
        const sortedRecipents = selectedRecipents.sort().join(' ');
        const sortedParticipants = thread.participants
          .filter((p) => p.id !== userId)
          .map((p) => p.id)
          .sort()
          .join(' ');
        if (sortedRecipents === sortedParticipants) {
          threadExist = true;
          onCreate(thread);
          handleDialogClose();
        }
      });
    }
    if (threadExist === false) {
      const participants = selectedRecipents.map((r) => ({
        id: r,
        fullName: `${recipients.find((u) => u.id === r).firstName} ${recipients.find((u) => u.id === r).lastName}`,
        role: recipients.find((u) => u.id === r).role || undefined,
        photoUrl: recipients.find((u) => u.id === r).photoUrl,
        children: recipients.find((u) => u.id === r).children || []
      }));
      const thread = new PrivateThreadModel().assign({
        participants: [
          {
            id: userId,
            fullName: user.userFullName,
            role: user.role,
            photoUrl: user.profilePhoto
          },
          ...participants
        ]
      });
      onCreate(prepareThread(thread, recipients, groups, userId));
      handleDialogClose();
      setSelectedRecipients([]);
    }
  };

  const handleSelectFew = (recipients) => {
    setSelectedRecipients(recipients);
  };

  return (
    <Box>
      <Button variant="contained" aria-label="Nowa wiadomość" onClick={handleDialogOpen} sx={{ ml: 7 }}>
        Nowa wiadomość
      </Button>
      <RecipientDialog
        user={user}
        isCheckbox={isCheckbox}
        recipients={recipients.map((item) => {
          if (item.children && item.children.length > 0) {
            return {
              ...item,
              belongToGroups: item.children.map((child) => groups.find((group) => group.id === child.groupId) || null)
            };
          }
          return { ...item, belongToGroups: [] };
        })}
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onSelect={handleRecipientSelect}
        onSelectFew={handleSelectFew}
        selectedRecipents={selectedRecipents}
        onSave={handleCreateThread}
        groups={groups || []}
        onMultiMessage={handleMultiMessage}
      />
    </Box>
  );
};

CreateThreadButton.propTypes = {
  onCreate: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  user: PropTypes.object,
  groups: PropTypes.array,
  legalGuardians: PropTypes.array,
  staffMembers: PropTypes.array,
  threads: PropTypes.array,
  isCheckbox: PropTypes.bool,
  onlyOneRecipient: PropTypes.bool,
  dialogOpen: PropTypes.bool
};

export default CreateThreadButton;
