import PropTypes from 'prop-types';
import React from 'react';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import YearAndMonthOptionsSlider from '../../../common/calendar/YearAndMonthOptionsSlider';
import { Box, Typography } from '@mui/material';

const YearAndMonthPickerTemplate = ({ year, month, onChange, legend, sx }) => {
  return (
    <Box sx={{ ...sx }}>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        <Typography variant="h6" sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          Wybierz miesiąc
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          <YearAndMonthOptionsSlider
            contrast
            onSelected={(nextYear, nextMonth) => {
              onChange({ target: { name: 'year', value: nextYear } });
              onChange({ target: { name: 'month', value: nextMonth } });
            }}
            defaultMonth={month}
            defaultYear={year}
          />
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

YearAndMonthPickerTemplate.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  legend: PropTypes.string,
  sx: PropTypes.object
};

export default YearAndMonthPickerTemplate;
