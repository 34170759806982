import ApiBase, { ROOT_URL } from './ApiBase';

class GuardianDuesApi extends ApiBase {
  static getDues(year) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/settlements/dues/guardian/${year}`).type('application/json')
    );
  }

  static getDue(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/settlements/dues/${id}`).type('application/json'));
  }
}

export default GuardianDuesApi;
