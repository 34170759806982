import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import ImportIcon from '@mui/icons-material/CloudUpload';
import { ROOT_URL } from '../../api/ApiBase';
import FilePickerWithRef from '../common/FilePickerWithRef/FilePickerWithRef';
import * as childrenActions from '../../actions/importChildrenCSVActions';
import { useDispatch } from 'react-redux';
import ImportErrorsComponent from './components/ImportErrorsComponent';
import ImportInformationComponent from './components/ImportInformationComponent';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';

const useStyles = makeStyles((theme) => ({
  InformationText: {
    width: '100%',
    height: '400px',
    fontSize: '2rem',
    alignSelf: 'center',
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  CheckIconStyles: {
    color: 'green',
    transform: 'scale(2)',
    margin: '20px'
  },
  InfoIconStyles: {
    color: theme.palette.color.color4,
    transform: 'scale(2)',
    margin: '20px'
  }
}));

const ImportPage = () => {
  const fileRef = React.createRef();
  const dispatch = useDispatch();
  const reader = new FileReader();
  const classes = useStyles();

  const fileStateOptions = {
    none: 'none',
    inProgress: 'inProgress',
    loaded: 'loaded',
    withRowErrors: 'withRowErrors',
    withFileErrors: 'withFileErrors',
    success: 'success',
    importing: 'importing',
    imported: 'imported'
  };

  const [errors, setErrors] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [fileState, setFileState] = useState(fileStateOptions.none);
  const [file, setFile] = useState();

  const handleDownloadTemplate = () => {
    const path = `${ROOT_URL}/users/child_import_template_csv`;
    window.open(path, '_blank');
  };

  useEffect(() => {
    if (fileState === fileStateOptions.loaded) {
      if (errors.length > 0) {
        let isFileError = false;
        errors.forEach((error) => {
          if (error.row === -1) isFileError = true;
        });
        if (isFileError === true) {
          setFileState(fileStateOptions.withFileErrors);
        } else {
          setFileState(fileStateOptions.withRowErrors);
        }
      }
      if (errors.length === 0) {
        setFileState(fileStateOptions.success);
      }
    }
  }, [fileState]);

  const handleDisplayFileStatus = () => {
    reader.onload = function (e) {
      const text = e.target.result;
      setHeaders(text.slice(0, text.indexOf('\n')).split(';').join(',').split(','));
      setRows(text.split('\n'));
      setFileState(fileStateOptions.loaded);
    };
    reader.readAsText(file);
  };

  const handleValidate = async () => {
    setErrors([]);
    setFileState(fileStateOptions.inProgress);
    try {
      await dispatch(childrenActions.importValidateAsync(file));
      setFileState(fileStateOptions.loaded);
    } catch (error) {
      setErrors(error.response.body);
      handleDisplayFileStatus(file);
    }
  };

  const handleImport = async () => {
    setFileState(fileStateOptions.importing);
    try {
      await dispatch(childrenActions.importChildrenAsync(file));
      setFileState(fileStateOptions.imported);
    } catch (error) {
      setErrors(error.response.body);
      handleDisplayFileStatus(file);
    }
  };

  const handleAddFile = (f) => {
    setFile(f);
  };

  useEffect(() => {
    if (file) {
      handleValidate();
    }
  }, [file]);

  return (
    <>
      <PageHeaderText title="Import dzieci" titleIcon={<ImportIcon />} />
      <ContentWrapper>
        <Paper sx={{ p: 3 }}>
          {fileState === fileStateOptions.none ? <ImportInformationComponent /> : null}
          {fileState === fileStateOptions.inProgress ? (
            <Box className={classes.InformationText}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Trwa wczytywanie pliku, proszę czekać...
              </Typography>
              <CircularProgress />
            </Box>
          ) : null}
          {fileState === fileStateOptions.withRowErrors || fileState === fileStateOptions.withFileErrors ? (
            <ImportErrorsComponent
              headers={headers}
              rows={rows}
              errors={errors}
              errorType={fileState === fileStateOptions.withRowErrors ? 'rows' : 'file'}
            />
          ) : null}
          {fileState === fileStateOptions.success ? (
            <Box className={classes.InformationText}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Dane są poprawne oraz gotowe do zaimportowania
              </Typography>
              <InfoOutlinedIcon className={classes.InfoIconStyles} />
            </Box>
          ) : null}
          {fileState === fileStateOptions.importing ? (
            <Box className={classes.InformationText}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Trwa importowanie pliku, proszę czekać...
              </Typography>
              <CircularProgress />
            </Box>
          ) : null}
          {fileState === fileStateOptions.imported ? (
            <Box className={classes.InformationText}>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Pomyślnie zaimportowano dane
              </Typography>
              <CheckCircleOutlineOutlinedIcon className={classes.CheckIconStyles} />
            </Box>
          ) : null}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', pt: 3, pb: 3 }}>
            <Button variant="contained" aria-label="Pobierz wzór pliku" onClick={() => handleDownloadTemplate()}>
              Pobierz wzór pliku
            </Button>
            <Button
              variant="contained"
              aria-label="Zatwierdź"
              onClick={() => handleImport(file)}
              disabled={fileState !== fileStateOptions.success}>
              Zatwierdź
            </Button>
            <Button variant="contained" aria-label="Wgraj plik CSV" onClick={() => fileRef.current.open()}>
              Wgraj plik CSV
            </Button>
          </Box>
        </Paper>
        <FilePickerWithRef ref={fileRef} onDrop={(f) => handleAddFile(f)} labelId="import pliku" />
      </ContentWrapper>
    </>
  );
};

export default ImportPage;
