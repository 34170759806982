import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../../actions/attendanceActions';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupsAttendanceSummary from './GroupsAttendanceSummary';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import UnitAttendanceSummary from './UnitAttendanceSummary';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Typography } from '@mui/material';

class DigitalDiaryTeacherDashboardPage extends React.Component {
  componentDidMount() {
    this.props.actions.loadTodayAttendanceSummaryAsync();
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title={`Witaj ${this.props.loggedUserName}`} titleIcon={<HomeOutlinedIcon />} />

          <Typography
            sx={{
              color: (theme) => theme.palette.color.primary,
              fontWeight: (theme) => theme.typography.fontWeightBold,
              opacity: 0.9,
              display: 'flex',
              alignItems: 'flex-end',
              my: 2
            }}
            component="h3"
            variant="h6">
            <ChildCareIcon fontSize="large" sx={{ mr: 2 }} /> Dzisiaj w placówce
          </Typography>

          <UnitAttendanceSummary attendanceSummary={this.props.attendanceSummary} />
          {this.props.attendanceSummary.groups.length > 0 ? (
            <GroupsAttendanceSummary attendanceSummary={this.props.attendanceSummary.groups} />
          ) : (
            <Typography
              sx={{
                color: (theme) => theme.palette.color.primary,
                pt: 3,
                pb: 5,
                pl: 4,
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}
              variant="h6">
              Nie założono jeszcze żadnych grup
            </Typography>
          )}
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

DigitalDiaryTeacherDashboardPage.propTypes = {
  actions: PropTypes.object.isRequired,
  loggedUserName: PropTypes.string.isRequired,
  attendanceSummary: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    loggedUserName: state.auth.userFullName,
    attendanceSummary: state.staffDashboard.attendanceSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(attendanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalDiaryTeacherDashboardPage);
