'use strict';

import ApiBase, { ROOT_URL } from './ApiBase';

class AuthenticationApi extends ApiBase {
  static signIn(signInData) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/authenticate`).send(signInData).type('application/json')
    );
  }

  static logout(userId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/trustedProfile/logout`).send({ UserId: userId }).type('application/json')
    );
  }

  static getToken() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/users/trustedProfile/login`).type('application/json'));
  }

  static signInWithToken(data) {  
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/trustedProfile/signin-saml`).send(data).type('application/json')
    );
  }

  static signInPinDevice(signInData) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/pinDevice/authenticate`).send(signInData).type('application/json')
    );
  }
}

export default AuthenticationApi;
