import PropTypes from 'prop-types';
import { useState } from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import DialogForm from '../../forms/DialogForm';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import TextField from '../../common/TextField';
import { Typography } from '@mui/material';

const RegisterRebateDialog = ({ onSave, onCancel, isDialogOpen, isProcessing, settlement }) => {
  const [rebateDetails, setRebateDetails] = useState({
    description: '',
    amount: 0
  });
  const [errors, setErrors] = useState({});

  const handleParamsChange = (event) => {
    const field = event.target.name;
    setRebateDetails({
      ...rebateDetails,
      [field]: event.target.value
    });
  };

  return (
    <DialogForm
      header={`Rejestracja zwrotu dla opłaty numer ${settlement.number}`}
      onSave={() => onSave(rebateDetails)}
      onCancel={onCancel}
      isDialogOpen={isDialogOpen}
      isProcessing={isProcessing}
      onValidationDone={(newErrors) => setErrors(newErrors)}
      statePathToUi="registerRebateUi">
      <Typography>
        Rejestracja zwrotu za
        <Typography component="span" sx={{ mx: 0.5, fontWeight: (theme) => theme.typography.fontWeightBold }}>
          {ChargingReportTypes[settlement.dueType].name.toLowerCase()}
        </Typography>
        w okresie
        <Typography component="span" sx={{ ml: 0.5, fontWeight: (theme) => theme.typography.fontWeightBold }}>
          {settlement.month}
        </Typography>
        .
      </Typography>
      <Typography>
        Dziecko
        <Typography component="span" sx={{ ml: 0.5, fontWeight: (theme) => theme.typography.fontWeightBold }}>
          {settlement.person.firstName} {settlement.person.lastName}
        </Typography>
      </Typography>
      <TextField
        hintText="Kwota zwrotu"
        floatingLabelText="Kwota zwrotu"
        floatingLabelFixed={false}
        name="amount"
        value={rebateDetails.amount}
        onChange={handleParamsChange}
        onBlur={(e) => {
          handleParamsChange({ target: { name: 'amount', value: tryConvertToNumber(e.target.value) } });
        }}
        errorText={errors.amount || errors.rebate}
      />
      <TextField
        hintText="Opis"
        floatingLabelText="Opis"
        floatingLabelFixed={false}
        name="description"
        value={rebateDetails.description}
        onChange={handleParamsChange}
        errorText={errors.description}
        fullWidth={true}
      />
    </DialogForm>
  );
};

RegisterRebateDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  settlement: PropTypes.object.isRequired
};

export default RegisterRebateDialog;
