import { useField, useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyle = makeStyles((theme) => ({
  underline: {
    '&::before': {
      borderBottom: '1px solid' + theme.palette.color.contrast
    },
    '&:hover::before': {
      borderBottom: '2px solid ' + theme.palette.color.color22
    }
  }
}));

const labelStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  fontSize: '1px'
};

export const FormikTextField = ({
  name,
  label = false,
  fullWidth,
  disabled,
  type,
  endAdornment,
  style,
  autocomplete = null,
  onChange,
  errorText,
  zip,
  multiline,
  maxRows,
  ...rest
}) => {
  const { setFieldValue, status } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (evt) => {
    let { value } = evt.target;

    if (zip) {
      value = value.replace(/\D/g, '');
      if (value.length === 3 && value.slice(-1) === '-') {
        value = value.slice(0, -1);
      } else if (value.length >= 3 && value.slice(-1) !== '-') {
        value = [value.slice(0, 2), value.slice(2)].join('-');
      }
      if (value.length > 6) {
        value = value.slice(0, 6);
      }
    }
    if (onChange) {
      onChange(value, name);
    } else {
      setFieldValue(name, value);
    }
  };

  const textFieldConfig = {
    ...field,
    ...rest,
    onChange: handleChange,
    sx: { m: 1, ...style },
    autoComplete: autocomplete,
    type,
    label,
    fullWidth,
    multiline,
    maxRows,
    InputProps: { classes: { underline: classes.underline }, endAdornment },
    InputLabelProps: { sx: isMobile ? labelStyle : null },
    FormHelperTextProps: { role: 'status' },
    disabled: status?.disabled || disabled,
    helperText: meta.touched && (errorText || meta.error),
    error: meta.touched && (!!errorText || !!meta.error)
  };

  return (
    <TextField
      variant="standard"
      {...textFieldConfig}
      sx={{
        my: 1,
        '& .MuiInputBase-input': {
          overflow: !multiline && 'hidden',
          textOverflow: !multiline && 'ellipsis'
        },
        '& label': {
          fontSize: 14,
          '&.Mui-focused': {
            fontSize: 14
          }
        }
      }}
    />
  );
};

FormikTextField.propTypes = {
  name: PropTypes.string,
  errorText: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  style: PropTypes.object,
  autocomplete: PropTypes.any,
  onChange: PropTypes.func,
  zip: PropTypes.bool,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number
};
