export default (params) => {
  const result = {};

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      result[`${key}[]`] = params[key];
    } else {
      result[key] = params[key];
    }
  });
  return result;
};
