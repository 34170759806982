import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import ChildGuardianForm from '../../common/children/guardians/ChildGuardianForm';
import ChildGuardiansList from '../../common/children/guardians/ChildGuardiansList';
import { GuardianModel } from '../../../models/children/ChildModels';
import { GuardianModelValidator } from '../../../models/children/ChildModelsValidator';
import EmptyState from '../../common/EmptyState';
import isSubdomain from '../../../utils/isSubdomain';
import { GDANSK_SUBDOMAINS } from '../../../constants/gdanskSubdomains';

const ChildGuardiansCard = (props) => {
  const [activeGuardian, setActiveGuardian] = useState(new GuardianModel());
  const [errors, setErrors] = useState({});
  const handleCreate = () => {
    setActiveGuardian(new GuardianModel(true));
    props.onCreate();
  };
  const isGdanskSubdomain = useMemo(() => isSubdomain(GDANSK_SUBDOMAINS), []);

  const handleEdit = (guardian) => {
    setActiveGuardian(new GuardianModel().assign(guardian));
    props.onEdit();
  };

  const handleGuardianChange = (event) => {
    setActiveGuardian({ ...activeGuardian, [event.target.name]: event.target.value });
  };

  const renderEditForm = () => {
    return <ChildGuardianForm guardian={activeGuardian} onChange={handleGuardianChange} errors={errors} />;
  };

  const renderReadonlyForm = () => {
    return (
      <ChildGuardiansList
        guardians={props.guardians}
        isPinEnabled={props.isPinEnabled}
        onEdit={handleEdit}
        onRemove={props.onRemove}
        onAuthorize={props.onAuthorize}
        onUnAuthorize={props.onUnAuthorize}
      />
    );
  };

  return (
    <CardDialogEditForm
      title="Osoby upoważnione do odbioru dziecka"
      isInitiallyExpanded={props.isInitiallyExpanded}
      onSave={() => props.onSave(activeGuardian)}
      onCancel={() => props.onCancel(activeGuardian)}
      onValidate={() => new GuardianModelValidator().validate(activeGuardian)}
      onValidationDone={(err) => setErrors(err)}
      onAction={handleCreate}
      actionLabel="DODAJ"
      actionTitle="Osoba odbierająca"
      isDialogOpen={props.isEditing}
      isProcessing={props.isProcessing}
      statePathToUi="childDetailsUi.guardians"
      readonlyForm={renderReadonlyForm()}
      emptyForm={<EmptyState message="Brak osób upoważnionych do odbioru" contrast />}
      editForm={renderEditForm()}
      isEmpty={props.guardians.length < 1}
      requiredFieldText
      hasConfirmationDialog={isGdanskSubdomain}
      confirmationTitle="Dodawanie osoby upoważnionej"
      confirmationText="Jednocześnie informujemy, iż uzyskaliśmy od osób upoważnionych do odbioru dziecka ze żłobka zgodę na przetwarzanie ich danych osobowych w celu realizacji działań objętych treścią upoważnienia, jak również że bierzemy na siebie odpowiedzialność za przestrzeganie przez te osoby Regulaminu porządkowego żłobka oraz innych procedur bezpieczeństwa obowiązujących w żłobku."
      attachRODO={isGdanskSubdomain}
    />
  );
};

ChildGuardiansCard.propTypes = {
  guardians: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAuthorize: PropTypes.func.isRequired,
  onUnAuthorize: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isPinEnabled: PropTypes.bool.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default ChildGuardiansCard;
