import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import EmojiCounter from '../daycare/EmojiCounter';
import { Box, Typography } from '@mui/material';

const EmojiIncrementer = ({ name, labelText, emoji, value = 0, onChange }) => {
  const increment = (value) => {
    if (value >= 0) {
      return value + 1;
    }
    return value;
  };
  const decrement = (value) => {
    if (value > 0) {
      return value - 1;
    }
    return value;
  };

  return (
    <Box
      sx={{
        m: ' 14px 0 0 0'
      }}>
      <Typography>{labelText}</Typography>
      <Box sx={{ ml: -1.4, mr: 2 }}>
        <IconButton
          type="button"
          aria-label="Usuń"
          style={{ minWidth: 40 }}
          disabled={!value}
          onClick={() => onChange({ target: { name, value: decrement(value) } })}>
          <RemoveIcon />
        </IconButton>

        <EmojiCounter count={value} emoji={emoji} />

        <IconButton
          aria-label="Dodaj"
          type="button"
          style={{ minWidth: 40 }}
          onClick={() => onChange({ target: { name, value: increment(value) } })}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

EmojiIncrementer.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default EmojiIncrementer;
