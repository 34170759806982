import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '../common/Dialog';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

class ConfirmationDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleConfirm() {
    this.props.onConfirm();
  }

  handleCancel() {
    this.props.onCancel();
  }

  renderActionButtons() {
    return [
      <Button
        variant="outlinedContrast"
        aria-label={this.props.cancelLabel}
        key="1"
        disabled={this.props.inProgress}
        onClick={this.handleCancel}>
        {this.props.cancelLabel}
      </Button>,
      <LoadingButton
        variant="contained"
        aria-label={this.props.confirmLabel}
        key="2"
        onClick={this.handleConfirm}
        loading={this.props.inProgress}
        disabled={this.props.isInvalid}>
        {this.props.confirmLabel}
      </LoadingButton>
    ];
  }

  render() {
    return (
      <Dialog
        title={this.props.confirmationTitle}
        actions={this.renderActionButtons()}
        open={this.props.isOpen}
        {...this.props}>
        {this.props.confirmationText}
        {this.props.children}
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.string,
  confirmationTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  inProgress: PropTypes.bool,
  isInvalid: PropTypes.bool
};

ConfirmationDialog.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
  isConfirmPrimary: false,
  isConfirmSecondary: false,
  isConfirmDisabled: false,
  inProgress: false
};

export default ConfirmationDialog;
