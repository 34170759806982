import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DialogForm from '../../../forms/DialogForm';
import { AttributeSetModelValidator } from '../../../../models/configuration/attributes/AttributeSetModelValidator';
import AttributeSetForm from './AttributeSetForm';
import AttributeDialog from './AttributeDialog';
import { Box } from '@mui/material';

const CreateAttributeSetDialog = ({ onSave, onCancel, attributesSet, isDialogOpen, isProcessing }) => {
  const [attributeSet, setAttributeSet] = useState(attributesSet);
  const [errors, setErrors] = useState({});
  const [isAttributeDialogOpen, setIsAttributeDialogOpen] = useState(false);

  const handleChange = (event) => {
    const field = event.target.name;
    const attributeSets = Object.assign({}, attributeSet);

    attributeSets[field] = event.target.value;
    setAttributeSet(attributeSets);
  };

  const handleAttributeCreatDialogOpen = () => {
    setIsAttributeDialogOpen(true);
  };

  const handleAttributeCreated = (att) => {
    const event = { target: { name: 'attributes', value: [...attributeSet.attributes, att] } };
    setIsAttributeDialogOpen(false);
    handleChange(event);
  };

  const renderCreateAttributeDialog = () => {
    if (!isAttributeDialogOpen) return null;
    return (
      <AttributeDialog
        isDialogOpen={isAttributeDialogOpen}
        onCancel={() => setIsAttributeDialogOpen(false)}
        onSave={(att) => handleAttributeCreated(att)}
      />
    );
  };

  const handleValidatedone = (errors) => {
    setErrors(errors);
  };

  return (
    <Box>
      <DialogForm
        maxWidth="sm"
        header="Dodaj zestaw atrybutów"
        onSave={() => onSave(attributeSet)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new AttributeSetModelValidator().validate(attributeSet)}
        onValidationDone={handleValidatedone}
        statePathToUi="configuration.attributesSetsUi"
        requiredFieldText>
        <AttributeSetForm
          attributeSet={attributeSet}
          onChange={handleChange}
          errors={errors}
          onCreateAttribute={handleAttributeCreatDialogOpen}
        />
      </DialogForm>
      {renderCreateAttributeDialog()}
    </Box>
  );
};

CreateAttributeSetDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  attributesSet: PropTypes.object
};

export default CreateAttributeSetDialog;
