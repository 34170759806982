import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { Clear } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import * as Types from '../../../../constants/groupsAndActivitiesTypes';
import SingleSelectWithButton from '../../../formik/SingleSelectWithButton/SingleSelectWithButton';
import Badge from '@mui/material/Badge';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Fade from '@mui/material/Fade';
import ClearIcon from '@mui/icons-material/Clear';
import IconButtonPipe from '../../IconButtonPipe';
import UserRoleIcon from '../../../userRoles/UserRoleIcon';
import userRoles from '../../../../constants/userRoles';
import { useSelector } from 'react-redux';

export const FilterByRoleType = {
  ALL: 'all',
  LEGAL_GUARDIAN: 'legalGuardian',
  TEACHER: 'teacher',
  OTHERS: 'others'
};

const FilterByRole = ({
  groups,
  selectedGroup,
  onSelectGroup,
  resetFilters,
  filterBy,
  onFilterBy,
  onResetGroups,
  iconProps,
  messanger,
  ...rest
}) => {
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButtonPipe
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          e.preventDefault();
        }}
        tooltip="Filtruj"
        aria-haspopup="true"
        aria-describedby={id}
        {...rest}>
        {selectedGroup || (filterBy && filterBy !== FilterByRoleType.ALL) ? (
          <Badge badgeContent="!">
            <TuneRoundedIcon />
          </Badge>
        ) : (
          <TuneRoundedIcon />
        )}
      </IconButtonPipe>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <>
          <Box sx={{ pl: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Filtrowanie</Typography>

            <IconButtonPipe onClick={resetFilters} tooltip="Zresetuj filtry">
              <RotateLeftIcon />
            </IconButtonPipe>

            <Box>
              <IconButtonPipe onClick={() => setAnchorEl(null)} tooltip="Zamknij okno filtrów">
                <Clear />
              </IconButtonPipe>
            </Box>
          </Box>
          {filterBy && (
            <>
              <Box sx={{ px: 2 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <Typography sx={{ color: theme.palette.color.contrast }}>Pokaż:</Typography>
                  </FormLabel>
                  <RadioGroup aria-label="gender" name="gender1" value={filterBy} sx={{ p: 2 }} onChange={onFilterBy}>
                    <FormControlLabel value="all" control={<Radio />} label={<Typography>Wszystkich</Typography>} />
                    <FormControlLabel
                      value={FilterByRoleType.LEGAL_GUARDIAN}
                      control={<Radio />}
                      label={
                        <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          {nadarzyn ? 'Uczniów' : 'Opiekunów prawnych'}
                          <UserRoleIcon role={userRoles.legalGuardian} sx={{ ml: 1, fontSize: 30 }} />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={FilterByRoleType.TEACHER}
                      control={<Radio />}
                      label={
                        <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          Nauczycieli
                          <UserRoleIcon
                            role={userRoles.staffMemberTeacher}
                            sx={{ ml: 1, fontSize: 34, color: theme.palette.components.role.staffMemberTeacher }}
                          />
                        </Typography>
                      }
                    />
                    {!nadarzyn && (
                      <FormControlLabel
                        value={FilterByRoleType.OTHERS}
                        control={<Radio />}
                        label={
                          <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            Pozostały personel
                            <SupervisedUserCircleIcon sx={{ ml: 2, fontSize: 30, color: theme.palette.color.color7 }} />
                          </Typography>
                        }
                      />
                    )}
                    {messanger && (
                      <FormControlLabel
                        value={'GroupChats'}
                        control={<Radio />}
                        label={
                          <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            Czaty grupowe
                            <Diversity3Icon sx={{ ml: 2, fontSize: 30 }} />
                          </Typography>
                        }
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          )}
          {!nadarzyn &&
            (!filterBy || filterBy === FilterByRoleType.LEGAL_GUARDIAN || filterBy === FilterByRoleType.TEACHER) && (
              <Fade in={true}>
                <Box sx={{ p: 2, pt: 1 }}>
                  {selectedGroup && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        Wybrana grupa
                        <IconButtonPipe onClick={onResetGroups} tooltip="Zresetuj filtr grup">
                          <ClearIcon />
                        </IconButtonPipe>
                      </Typography>
                    </Box>
                  )}
                  <SingleSelectWithButton
                    type={Types.GROUP}
                    options={groups}
                    value={selectedGroup}
                    onChange={onSelectGroup}
                    fullWidth
                    defaultLabel="Wybierz grupę"
                  />
                </Box>
              </Fade>
            )}
        </>
      </Popover>
    </>
  );
};

FilterByRole.propTypes = {
  filterBy: PropTypes.string,
  groups: PropTypes.array.isRequired,
  onFilterBy: PropTypes.func,
  onSelectGroup: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string.isRequired,
  onResetGroups: PropTypes.func,
  iconProps: PropTypes.object
};

export default FilterByRole;
