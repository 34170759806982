import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../forms/DialogForm';
import TimePicker from '../../common/TimePicker';
import PersonAvatar from '../../forms/PersonAvatar';
import _ from 'lodash';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import ExceededWorkingHoursWarningWrapper from './ExceededWorkingHoursWarningWrapper';
import { TimeModel } from '../../../models/TimeModel';
import { ReportAttendanceValidator } from '../../../models/attendance/AttendanceModelsValidator';
import { List, ListItem, ListItemText, ListItemAvatar, Box, Typography } from '@mui/material';
import moment from 'moment';

class ReportAttendanceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(this.props.droppedOffAt),
      droppedOffAt: moment(this.props.droppedOffAt, 'HH:mm'),
      pickedUpAt: moment(this.props.pickedUpAt, 'HH:mm'),
      pickedUpBy: this.props.pickedUpBy || this.props.guardians.length ? this.props.guardians[0].id : null,
      errors: {}
    };
    this.handleDropOffTimeChange = this.handleDropOffTimeChange.bind(this);
    this.handlePickUpTimeChange = this.handlePickUpTimeChange.bind(this);
    this.handleGuardianChange = this.handleGuardianChange.bind(this);
    this.processData = this.processData.bind(this);
  }

  handleDropOffTimeChange(droppedOffAt) {
    this.setState({ droppedOffAt });
  }

  handlePickUpTimeChange(pickedUpAt) {
    this.setState({ pickedUpAt });
  }

  handleGuardianChange(pickedUpBy) {
    this.setState({ pickedUpBy });
  }

  processData() {
    const droppedOffAt = moment(this.state.date);
    droppedOffAt.hour(moment(this.state.droppedOffAt).hour());
    droppedOffAt.minute(moment(this.state.droppedOffAt).minute());

    const pickedUpAt = moment(this.state.date);
    pickedUpAt.hour(moment(this.state.pickedUpAt).hour());
    pickedUpAt.minute(moment(this.state.pickedUpAt).minute());

    return {
      droppedOffAt: droppedOffAt,
      pickedUpAt: pickedUpAt,
      pickedUpBy: this.state.pickedUpBy,
      errors: this.state.errors
    };
  }

  renderGuardiansList(guardians) {
    return (
      <List>
        {guardians.map((guardian) => {
          return (
            <ListItem
              key={guardian.id}
              button
              selected={this.state.pickedUpBy === guardian.id}
              onClick={() => this.handleGuardianChange(guardian.id)}
              component="li"
              aria-label="Zgłoś obecność">
              <ListItemAvatar>
                <PersonAvatar
                  initials
                  alt={guardian.firstName}
                  url={guardian.photoUrl}
                  firstName={guardian.firstName}
                  lastName={guardian.lastName}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{ px: 2 }}
                primary={`${guardian.firstName} ${guardian.lastName} ${
                  guardian.relationship && `(${guardian.relationship})`
                }`}
                primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                secondary={`Numer dokumentu: ${guardian.documentNumber}`}
                secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  renderEmptyGuardiansListError() {
    return (
      <Box
        sx={{
          mt: 2,
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          display: 'flex',
          alignItems: 'center'
        }}>
        <PriorityHighOutlinedIcon sx={{ color: (theme) => theme.palette.color.error, mr: 1 }} />
        <Box sx={{ mt: 0.5 }}>Brak osób przypisanych do dziecka</Box>
      </Box>
    );
  }

  render() {
    const {
      pupilFirstName,
      pupilLastName,
      pupilPhotoUrl,
      guardians,
      onSave,
      noGuardians,
      onCancel,
      isDialogOpen,
      isProcessing,
      statePathToUi,
      isFirstAttendanceAttempt
    } = this.props;
    return (
      <DialogForm
        sx={{
          m: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDIrection: 'column'
        }}
        maxWidth="xs"
        header={isFirstAttendanceAttempt ? 'Zgłoś obecność' : 'Zmiana obecności'}
        onSave={() => onSave(this.processData())}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new ReportAttendanceValidator().validate(this.state)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi={statePathToUi}
        saveDisabled={_.isEmpty(guardians) && !noGuardians}>
        <ExceededWorkingHoursWarningWrapper
          droppedOffAt={new TimeModel().parse(this.state.droppedOffAt)}
          pickedUpAt={new TimeModel().parse(this.state.pickedUpAt)}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
          <PersonAvatar
            initials
            alt={pupilFirstName}
            url={pupilPhotoUrl}
            firstName={pupilFirstName}
            lastName={pupilLastName}
          />
          <Typography
            sx={{
              ml: 2,
              wordBreak: 'break-word',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>{`${pupilFirstName} ${pupilLastName}`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>
          <TimePicker
            sx={{
              '&:input': {
                textAlign: 'center',
                fontSize: 32
              }
            }}
            floatingLabelText="od"
            value={this.state.droppedOffAt || null}
            onChange={(e) => this.handleDropOffTimeChange(e)}
            name="dropped"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <TimePicker
            sx={{
              '&:input': {
                textAlign: 'center',
                fontSize: '32px'
              }
            }}
            floatingLabelText="do"
            value={this.state.pickedUpAt || null}
            onChange={(e) => this.handlePickUpTimeChange(e)}
            name="picked"
          />
        </Box>
        {!noGuardians && (
          <>
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}>
              Osoba odbierająca
            </Typography>
            {_.isEmpty(guardians) ? this.renderEmptyGuardiansListError() : this.renderGuardiansList(guardians)}
          </>
        )}
      </DialogForm>
    );
  }
}

ReportAttendanceDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  droppedOffAt: PropTypes.object.isRequired,
  pickedUpAt: PropTypes.object.isRequired,
  pickedUpBy: PropTypes.string,
  guardians: PropTypes.array.isRequired,
  pupilFirstName: PropTypes.string.isRequired,
  pupilLastName: PropTypes.string.isRequired,
  pupilPhotoUrl: PropTypes.string,
  statePathToUi: PropTypes.string.isRequired,
  isFirstAttendanceAttempt: PropTypes.bool
};

export default ReportAttendanceDialog;
