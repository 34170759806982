/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DigitalDiaryEventsModel } from '../models/digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
// import {MessageBoardModel, BoardPostModel} from '../models/messageBoard/MessageBoardModels';

export default function messageBoardDigitalDiaryReducer(state = initialState.messageBoardDigitalDiary, action) {
  switch (action.type) {
    case types.LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY_SUCCESS: {
      return new DigitalDiaryEventsModel([...action.events], action.events.length);
    }
    case types.CREATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS: {
      return new DigitalDiaryEventsModel([action.eventData, ...state.events], state.totalCount + 1);
    }
    case types.UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS: {
      // const updatedEvents = state.events.map(event => {
      //   return action.event.id === event.id ? new DigitalDiaryEventModel().assign(action.event) : event;
      // });
      // return new DigitalDiaryEventsModel(updatedEvents, state.pageCount);
      return state;
    }
    case types.DELETE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS: {
      return new DigitalDiaryEventsModel([...state.events.filter((p) => p.id !== action.eventId)], state.pageCount - 1);
    }
    default:
      return state;
  }
}
