import ApiBase, { ROOT_URL } from './ApiBase';

class ReportsSettingsApi extends ApiBase {
  static getReportsSettings() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/reports`).type('application/json'));
  }

  static updateReportsSettings(settings) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/reports`).send(settings).type('application/json')
    );
  }
}

export default ReportsSettingsApi;
