import React from 'react';
import { Box, Typography } from '@mui/material';
import Badge from './Bagde';

const BoardBadgeWrapper = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={
          ({ mb: 1 },
          (props.style,
          {
            display: 'flex',
            justicyContent: 'space-between',
            fontWeight: (theme) => theme.typography.fontWeightLarge,
            fontSize: '16px'
          }))
        }>
        {props.text}
      </Typography>
      {props.displayCount > 0 && <Badge sx={{ ml: 1, mt: -1 }} badgeValue={props.displayCount} />}
    </Box>
  );
};

export default BoardBadgeWrapper;
