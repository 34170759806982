import React from 'react';
import PropTypes from 'prop-types';
import userRoles from '../../constants/userRoles';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PrincipalIcon from '../../assets/icons/PrincipalIcon';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { useTheme } from '@mui/material';

const UserRoleIcon = ({ role, ...rest }) => {
  const theme = useTheme();
  switch (role) {
    case userRoles.staffMemberPrincipal:
      return <PrincipalIcon style={{ color: theme.palette.components.role.staffMemberPrincipal }} {...rest} />;
    case userRoles.legalGuardian:
      return (
        <FamilyRestroomOutlinedIcon
          sx={{ color: `${theme.palette.components.role.legalGuardian} !important` }}
          {...rest}
        />
      );
    case userRoles.staffMemberAccountant:
      return (
        <CalculateOutlinedIcon
          sx={{ color: `${theme.palette.components.role.staffMemberAccountant} !important` }}
          {...rest}
        />
      );
    case userRoles.staffMemberCanteenWorker:
      return (
        <SoupKitchenIcon
          sx={{ color: `${theme.palette.components.role.staffMemberCanteenWorker} !important` }}
          {...rest}
        />
      );
    case userRoles.staffMemberSecretary:
      return <FaxOutlinedIcon sx={{ color: theme.palette.components.role.staffMemberSecretary }} {...rest} />;
    case userRoles.staffMemberTeacher:
      return (
        <GroupOutlinedIcon sx={{ color: `${theme.palette.components.role.staffMemberTeacher} !important` }} {...rest} />
      );
    case userRoles.staffMemberDigitalDiary:
      return (
        <GroupAddOutlinedIcon
          sx={{ color: `${theme.palette.components.role.staffMemberDigitalDiary} !important` }}
          {...rest}
        />
      );
    default:
      return <PermIdentityIcon sx={{ color: `${theme.palette.color.color21} !important` }} {...rest} />;
  }
};

UserRoleIcon.propTypes = {
  role: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default UserRoleIcon;
