import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  Box,
  Typography,
  Pagination,
  MenuItem,
  Chip,
  Grid,
  useMediaQuery
} from '@mui/material';
import eventTranslations from '../../../constants/eventTranslations';
import DatePicker from '../../common/DatePicker';
import moment from 'moment';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import { bindActionCreators } from 'redux';
import * as eventsActions from '../../../actions/eventsActions';
import eventsApi from '../../../api/EventsApi';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import SelectField from '../../common/SelectField';

const eventOptions = Object.keys(eventTranslations).map((key) => {
  return {
    value: key,
    title: eventTranslations[key]
  };
});

const EventsPage = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [eventTypes, setEventTypes] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const loadEvents = (newRow, newPage) => {
    const params = {
      fetchCount: newRow,
      page: newPage
    };
    if (!!dateFrom) {
      params.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    }
    if (!!dateTo) {
      params.dateTo = moment(dateTo).format('YYYY-MM-DD');
    }
    if (!!eventTypes.length) {
      params.types = eventTypes.map((e) => e.value).join(',');
    }
    props.actions.loadEventsAsync(params);
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    loadEvents(rowsPerPage, newValue - 1);
  };

  const handleRowsChange = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    loadEvents(e.target.value, 0);
  };

  const handleFilter = () => {
    setPage(1);
    loadEvents(rowsPerPage, 0);
  };

  const storeBlobAs = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadFile = () => {
    const params = {};
    if (!!dateFrom) {
      params.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    }
    if (!!dateTo) {
      params.dateTo = moment(dateTo).format('YYYY-MM-DD');
    }
    if (!!eventTypes.length) {
      params.types = eventTypes.map((e) => e.value).join(',');
    }
    eventsApi.getEventsFile(params).then((res) => {
      storeBlobAs(res.response, 'Rejestr zdarzeń.xml');
    });
  };

  return (
    <Box>
      <PageHeaderText title="Rejestr Zdarzeń" titleIcon={<EventOutlinedIcon />} />
      <ContentWrapper>
        <FieldsetWrapper legend="Pola wyboru typu oraz daty zdarzenia">
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} xl={5} sx={{ pr: 3, pt: 2 }}>
              <Autocomplete
                sx={{ width: '100%', minWidth: '300px', my: 1 }}
                multiple
                id="tags-standard"
                value={eventTypes}
                onChange={(event, newValue) => setEventTypes(newValue)}
                options={eventOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderTags={(value) =>
                  value.map((option) => (
                    <Chip
                      key={option.value}
                      variant="outlined"
                      label={option.title}
                      sx={{ color: (theme) => theme.palette.color.primary, m: '2px' }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Typ zdarzeń"
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottom: (theme) => `1px solid ${theme.palette.color.primary} !important`
                      },
                      '& .MuiInput-underline:after': {
                        borderBottom: (theme) => `1px solid ${theme.palette.color.primary} !important`
                      },
                      '& .MuiInput-underline:hover': {
                        borderBottom: (theme) => `1px solid ${theme.palette.color.hidden} !important`
                      },
                      svg: { color: (theme) => theme.palette.color.primary },
                      label: {
                        color: (theme) => theme.palette.color.primary,
                        '&.Mui-focused': {
                          color: (theme) => theme.palette.color.primary
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              sx={{
                pt: 3,
                pb: 1,
                display: 'flex',
                justifyContent: { xs: 'flex-end', md: 'flex-start', xl: 'flex-end' },
                flexWrap: 'no-wrap'
              }}>
              <ClearButtonWrapper sx={{ width: 180 }} onClear={() => setDateFrom(null)}>
                <DatePicker
                  contrast
                  onChange={(val) => setDateFrom(val)}
                  value={dateFrom}
                  floatingLabelText="Zdarzenia od"
                  name="timeFrom"
                  maxDate={dateTo || null}
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper sx={{ width: 180 }} onClear={() => setDateTo(null)}>
                <DatePicker
                  contrast
                  onChange={(val) => setDateTo(val)}
                  value={dateTo}
                  floatingLabelText="Zdarzenia do"
                  name="timeTo"
                  minDate={dateFrom || null}
                />
              </ClearButtonWrapper>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              sx={{
                pt: '18px',
                display: 'flex',
                justifyContent: { xs: 'flex-end', md: 'flex-end' }
              }}>
              <SelectField
                sx={{
                  width: 88
                }}
                id="rowsPerPage"
                value={rowsPerPage}
                onChange={handleRowsChange}
                floatingLabelText="Wierszy na stronę">
                {numberArrayOfOptions.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </SelectField>
              <Button sx={{ ml: 2, mt: 2 }} variant="outlined" aria-label="Filtruj" onClick={handleFilter}>
                Filtruj
              </Button>
            </Grid>
          </Grid>
        </FieldsetWrapper>
        <Box>
          <Paper sx={{ p: 5 }}>
            <TableContainer>
              <Table aria-label="Tabela zdarzeń">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="tableHeader">Typ zdarzenia</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="tableHeader">Użytkownik</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="tableHeader">Data zdarzenia</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.events.eventsList.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        height: 64,
                        '&:hover': {
                          background: (theme) => theme.palette.background.hover
                        }
                      }}>
                      <TableCell align="left" sx={{ py: '4px' }}>
                        <Typography variant="tableBodyCell">{eventTranslations[row.event]}</Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ py: '4px' }}>
                        {!row.userData.login ? <Typography variant="tableBodyCell">Brak</Typography> : null}
                        {row.userData.fullName && (
                          <Typography component="span" variant="tableBodyCell">
                            {row.userData.fullName}
                          </Typography>
                        )}
                        {row.userData.login && (
                          <Typography component="span" sx={{ display: 'block', fontSize: 16 }}>
                            {row.userData.login}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ py: '4px' }}>
                        <Typography variant="tableBodyCell">
                          {moment(row.created_at).format('YYYY-MM-DD HH:mm')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {props.events.eventsList.length === 0 && (
                    <TableRow>
                      <TableCell sx={{ py: '4px' }}>
                        <Typography variant="tableBodyCell">Brak wyników</Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              siblingCount={isMobile ? 0 : 1}
              count={Math.ceil(props.events.rowNumber / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 4
              }}
            />
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" aria-label="Pobierz rejestr zdarzeń (.XML)" onClick={handleDownloadFile}>
              Pobierz rejestr zdarzeń (.XML)
            </Button>
          </Box>
        </Box>
      </ContentWrapper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.events
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(eventsActions, dispatch)
  };
};

EventsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
