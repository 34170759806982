/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function currentUsersReducer(state = initialState.currentUsers, action) {
  switch (action.type) {
    case types.LOAD_CURRENT_USERS_SUCCESS: {
      return { currentUsersList: [...action.currentUsers], rowNumber: action.currentUsers.length };
    }
    default:
      return state;
  }
}
