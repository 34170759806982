import { Box, Button, List, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdvancedInterestDialog from '../dialogs/AdvancedInterestDialog';
import AdvancedInterestListItem from './AdvancedInterestListItem';

/* const data = [
   { startDate: '20-10-2022', rate: 30 },
   { startDate: '10-10-2022', rate: 30 },
   { startDate: '25-9-2022', rate: 20 }
]
*/

const AdvancedInterests = ({ editable = true, data, onChange }) => {
  const theme = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedInterest, setSelectedIntrest] = useState(null);

  const handleOpenDialog = () => {
    setSelectedIntrest(null);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleDelete = ({ startDate }) => {
    onChange(data.filter((i) => i.startDate !== startDate));
  };

  const handleStartEdit = ({ rate, startDate }) => {
    setSelectedIntrest({ rate, startDate });
    setShowDialog(true);
  };

  const handleSubmit = ({ rate, startDate }, formikHelpers) => {
    if (selectedInterest) {
      onChange(data.map((i) => (i.startDate === selectedInterest.startDate ? { rate, startDate } : i)));
      setSelectedIntrest(null);
    } else if (data) {
      if (data.find((i) => i.startDate === startDate)) {
        formikHelpers.setFieldError('startDate', 'Daty reguł muszą być unikalne');
        return;
      }
      onChange([
        ...data,
        {
          rate: parseFloat(rate),
          startDate
        }
      ]);
    } else {
      onChange([
        {
          rate: parseFloat(rate),
          startDate
        }
      ]);
    }
    handleCloseDialog();
  };

  return (
    <Box sx={{ my: 2, mx: 0 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
        Zaawansowane naliczanie odsetek
      </Typography>
      <Box sx={{ p: 2, borderRadius: '20px', background: (theme) => theme.palette.background.hover }}>
        {data && data.length ? (
          <List>
            {data.map((i) => (
              <AdvancedInterestListItem
                key={i.startDate}
                data={i}
                editable={editable}
                onDelete={handleDelete}
                onEdit={handleStartEdit}
              />
            ))}
          </List>
        ) : (
          <Typography>Brak zdefiniowanych reguł naliczania odsetek</Typography>
        )}
        {!!editable && (
          <Button variant="contained" aria-label="Dodaj nową regułę" onClick={handleOpenDialog} sx={{ my: 1, mx: 0 }}>
            Dodaj nową regułę
          </Button>
        )}
      </Box>
      <AdvancedInterestDialog
        open={showDialog}
        initialData={selectedInterest}
        edit={!!selectedInterest}
        onSubmit={handleSubmit}
        onClose={handleCloseDialog}
      />
    </Box>
  );
};

AdvancedInterests.propTypes = {
  editable: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func
};

export default AdvancedInterests;
