import React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import { Grid, Typography } from '@mui/material';

import Fade from '@mui/material/Fade';

const MessageSend = ({ submitted }) => {
  return (
    <Fade in={submitted}>
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <EmailIcon sx={{ fontSize: 40, color: (theme) => theme.palette.color.color7 }} />
        <Typography sx={{ p: 3, fontWeight: (theme) => theme.typography.fontWeightMedium, textAlign: 'center' }}>
          Sprawdź skrzynkę pocztową i użyj linku do zresetowania swojego hasła.
        </Typography>
      </Grid>
    </Fade>
  );
};

MessageSend.propTypes = {
  submitted: PropTypes.bool.isRequired
};

export default MessageSend;
