import BaseModel from '../BaseModel';
import { compareNames } from '../../utils/personNamesComparator';

export class EnrolledChildModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.lastName = '';
    this.firstName = '';
    this.enrolledAt = '';
    this.photoUrl = '';
  }
}

export class AssociatedTeacherModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.lastName = '';
    this.firstName = '';
    this.associatedAt = '';
    this.photoUrl = '';
  }
}

export class PsychologicalGroupModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.yearbook = null;
    this.isTemporary = true;
    this.color = '';
    this.enrolledChildren = [];
    this.associatedTeachers = [];
    this.todayAttendance = [];
    this.basicGroupGoals = [];
    this.groupConclusions = [];
    this.groupMethods = [];
    this.specificGroupGoals = [];
  }

  onAssignment(assigned) {
    assigned.enrolledChildren = assigned.enrolledChildren
      .map((child) => new EnrolledChildModel().assign(child))
      .sort(compareNames);

    assigned.associatedTeachers = assigned.associatedTeachers
      .map((teacher) => new AssociatedTeacherModel().assign(teacher))
      .sort(compareNames);
  }
}
