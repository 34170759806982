import ApiBase, { ROOT_URL } from './ApiBase';

class ChargingSchemesApi extends ApiBase {
  static getChargingSchemes() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/chargingSchemes`).type('application/json'));
  }

  static getChargingScheme(schemeId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/chargingSchemes/${schemeId}`).type('application/json')
    );
  }

  static createChargingScheme(scheme) {
    return super.toPromise(
      (agent) =>
        agent
          .post(`${ROOT_URL}/configuration/chargingSchemes`)
          .send(scheme)
          .set('Location', '')
          .type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateChargingScheme(schemeId, scheme) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/chargingSchemes/${schemeId}`).send(scheme).type('application/json')
    );
  }

  static archiveChargingScheme(schemeId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/configuration/chargingSchemes/${schemeId}`).type('application/json')
    );
  }

  static getAssignedChildren(schemeId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/chargingSchemes/${schemeId}/children`).type('application/json')
    );
  }

  static assignChildren(schemeId, childrenIds, singleUse, reliefId, reliefExpirationDate) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/configuration/chargingSchemes/${schemeId}/linkToChildren`)
        .send({ childrenIds, singleUse, reliefId, reliefExpirationDate })
        .type('application/json')
    );
  }

  static unassignChildren(schemeId, childrenIds) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/configuration/chargingSchemes/${schemeId}/unlinkFromChildren`)
        .send({ childrenIds })
        .type('application/json')
    );
  }

  static getCateringChargingSchemes() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/chargingSchemes/catering`).type('application/json')
    );
  }
}

export default ChargingSchemesApi;
