const eventTranslations = {
  'system.deployedInKindergarten': 'Utworzono przedszkole',
  // configuration
  'system.dayOffDefined': 'Zdefiniowano dzień wolny',
  'system.dayOffRemoved': 'Usunięto dzień wolny',
  'system.reliefDefined': 'Zdefiniowano ulgę',
  'system.reliefRemoved': 'Usunięto ulgę',
  'system.chargingSchemeDefined': 'Zdefiniowano schemat płatności',
  'system.chargingSchemeUpdated': 'Zmieniono schemat płatności',
  'system.chargingSchemeRemoved': 'Usunięto schemat płatności',
  'system.chargingSchemeAccountNumberUpdated': 'Zmieniono numer konta schematu płatności',
  'system.unitAddressUpdated': 'Zmieniono adres placówki',
  'system.attendancesTypeUpdated': 'Zmieniono możliwość wprowadzania obecności według zadeklarowanych godzin obecności',
  'system.unitWorkingHoursUpdated': 'Zmieniono godziny otwarcia placówki',
  'system.twoWayMessagingEnabled': 'Włączono komunikację dwukierunkową',
  'system.twoWayMessagingDisabled': 'Wyłączono komunikację dwukierunkową',
  'system.sendingOverdueNotificationUpdated':
    'Zaktualizowano ustawienia wysyłania powiadomień do rodziców o zaległych opłatach',
  'system.reportAbsenceInDaysOffEnabled': 'Włączono możliwość zgłaszania nieobecności w dni wolne',
  'system.reportAbsenceInDaysOffDisabled': 'Wyłączono możliwość zgłaszania nieobecności w dni wolne',
  'system.penaltiesSettingsUpdated': 'Zmieniono ustawienia odsetek',
  'system.settlementsSettingsUpdated': 'Zmieniono ustawienia płatności',
  'system.numberingSchemeUsed': 'Użyto serię numeracji',
  'system.numberingSchemeDefined': 'Zdefiniowano serię numeracji',
  'system.numberingSchemeUpdated': 'Zmieniono serię numeracji',
  'system.boardPostsCommentsUpdated': 'Zaktualizowano możliwość komentowania ogłoszeń',
  'system.numberingSchemeRemoved': 'Usunięto serię numeracji',
  'system.reportSettingsUpdated': 'Zmieniono ustawienia raportów',
  'system.guardiansPinServiceUpdated': 'Zmieniono ustawienia logowania pinem',
  'system.guardiansPinServiceDeviceAdded': 'Dodano urządzenie do logowania pinem',
  'system.guardiansPinServiceDeviceRemoved': 'Usunięto urządzenie do logowania pinem',
  'system.daycareSettingsUpdated': 'Zmieniono ustawienia aktywności dzieci',
  'system.guardiansQRServiceUpdated': 'Zaktualizowanie możliwości rejestrowania obecności za pomocą pinu',
  'system.legalGuardiansAbsenceRemovalDeadlineUpdated':
    'Zmieniono ustawienia wycofywania zgłoszonych nieobecności przez opiekunów',
  'system.duesExportSettingsUpdated': 'Zmieniono ustawienia eksportu rozliczeń (Sputnik)',
  'system.fkIntegrationExportSettingsUpdated': 'Zmieniono ustawienia eksportu rozliczeń (Enova)',
  'system.symphonyIntegrationExportSettingsUpdated': 'Zmieniono ustawienia eksportu rozliczeń (Symfonia)',
  'system.postsModifyingUpdated': 'Zmieniono możliwość modyfikacji postów przez wszystkich pracowników',
  'system.individualBankAccountsUpdated': 'Zmieniono ustawienia indywidualnych kont bankowych dzieci',
  'system.addContractNumberToSettlementNumberUpdated':
    'Zmieniono ustawienia dołączania numeru umowy do numeru płatności',
  'system.childrenAgeInfoUpdated':
    'Zaktualizowano widoczności informacji o ukończeniu wieku 2.5 roku w rejestrze dzieci',
  'system.childrenAgeInfoEnabled': 'Zaktualizowano ustawienia dotyczące wyświetlania wieku dziecka',
  'system.sendingChildPickedUpNotificationBlocked':
    'Zaktualizowano ustawienia dotyczące blokowania wysyłania powiadomień do rodziców o odbiorze dziecka',
  'system.teacherAllBoardPostsVisibilitySettingsUpdated':
    'Zaktualizowano Widoczność wszystkich ogłoszeń przez nauczycieli',
  'system.legalGuardianPartOfBoardPostsVisibilitySettingsUpdated':
    'Zaktualizowano Widoczność wszystkich ogłoszeń przez rodziców',
  'system.editingChildDataByTeacherBlockedUpdated':
    'Zaktualizowano ustawienia blokowania edycji danych dzieci przez nauczyciela',
  'system.sendingChildPickedUpNotificationBlockedUpdated':
    'Zmodywikowano ustawienia blokowania wysyłania powiadomień o odbiorze dziecka',
  'system.teacherAllGroupVisibilitySettingsUpdated': 'Zaktualizowano widoczność wszystkich grup dla nauczycieli',
  'system.editingChildDataByTeacherBlocked': 'Zaktualizowano ustawienia uprawnień nauczyciela do edycji danych dziecka',
  'system.defaultPublishingHouseIdUpdated': 'Zaktualizowano domyślne wydawnictwo',
  'system.mealsCateringDuesUpdated': 'Zaktualizowano ustawienia dotyczące korzystania z modułu posiłków',
  // 'system.notificationSendingEnabled': 'Włączono możliwość edycji postów przez wszystkich pracowników',
  // 'system.notificationSendingDisabled': 'Wyłączono możliwość edycji postów przez wszystkich pracowników',
  //
  'system.settlementCreated': 'Utworzono rozliczenie',
  // digitalDiary
  'digitalDiaryEvent.created': 'Utworzono wydarzenie w dzienniku elektronicznym',
  'digitalDiaryEvent.edited': 'Zmieniono wydarzenie w dzienniku elektronicznym',
  'digitalDiaryEvent.archived': 'Zarchiwizowano wydarzenie w dzienniku elektronicznym',
  //
  // applications
  'applications.created': 'Utworzono wniosek',
  'applications.edited': 'Zmieniono wniosek',
  'applications.archived': 'Zarchiwizowano wniosek',
  'applications.approved': 'Zatwierdzono wniosek',
  'applications.rejected': 'Odrzucono wniosek',
  //
  // attributes
  // sets
  'attributeSet.defined': 'Zdefiniowano zestaw atrybutów',
  'attributeSet.updated': 'Zmieniono zestaw atrybutów',
  'attributeSet.archived': 'Zarchiwizowano zestaw atrybutów',
  'attributeSet.attributeDefinitionAdded': 'Dodano atrybu do zestawu',
  'attributeSet.attributeDefinitionUpdated': 'Zmieniono atrybut w zestawie',
  'attributeSet.attributeDefinitionRemoved': 'Usunięto atrybut z zestawu',
  //
  // holder
  'attributeHolder.attributeValueDefined': 'Zdefiniowano wartość atrybutu dziecka',
  'attributeHolder.attributeValueChanged': 'Zmieniono wartość atrybutu dziecka',
  'attributeHolder.attributeValueReset': 'Zresetowano wartość atrybutu dziecka',
  //
  //
  // settlements
  'settlement.createdForMonth': 'Utworzono rozliczenie miesięczne',
  'settlement.dueLinked': 'Przypięto rozliczenie',
  'settlement.dueUnlinked': 'Odpięto rozliczenie',
  'settlement.duesExported': 'Wyeksportowano rozliczenie (Sputnik)',
  'settlement.fkIntegrationExported': 'Wyeksportowano rozliczenie (Enova)',
  // due
  'settlement.draftDueCalculated': 'Przeliczono opłatę roboczą',
  'settlement.draftDueAdjusted': 'Dodano poprawkę do opłaty roboczej',
  'settlement.draftDueUnadjusted': 'Usunięto poprawkę do opłaty roboczej',
  'settlement.dueApproved': 'Zatwierdzono opłatę',
  'settlement.dueCanceled': 'Anulowano opłatę',
  'settlement.dueArchived': 'Zarchiwizowano opłatę',
  'settlement.dueOnlinePaymentInfoUpdated': 'Zmieniono status płatności online dla opłaty',
  'settlement.duePaymentRebateEntered': 'Wprowadzono rabat do płatności',
  'settlement.duePaymentRebateRemoved': 'Usunięto rabat z płatności',
  'settlement.duePaymentFailed': 'Płatność niepowiodła się',
  'settlement.dueDelayInterestCalculated': 'Naliczono odsetki dla płatności',
  'settlement.dueDelayInterestCanceled': 'Anulowano odsetki dla płatności',
  'settlement.correctiveDueDefined': 'Dodano korektę do płatności',
  'settlement.dueUnmarkedAsPaid': 'Usunięto oznaczenie płatności jako opłaconej',
  'settlement.duePaymentRegistered': 'Zarejestrowano płatność',
  'settlement.duePaymentUndone': 'Anulowano płatność',
  'settlement.duePaid': 'Należność została opłacona',
  'settlement.dueOverpaymentCreditedToBalance': 'Nadpłata została doliczona do salda',
  'settlement.availableFundsReserved': 'Zarezerwowano środki',
  'settlement.availableFundsReservationUndone': 'Anulowano rezerwację środków',
  //
  //
  // online payments
  'onlinePayments.paymentDefined': 'Utworzono płatność online',
  'onlinePayments.paymentStarted': 'Rozpoczęto płatność online',
  'onlinePayments.paymentFailed': 'Płatnośc online zakończona niepowodzeniem',
  'onlinePayments.paymentSucceeded': 'Płatność online powiodła się',
  'onlinePayments.paymentCanceled': 'Płatność online została anulowana',
  //
  // group
  groupDefined: 'Utworzono grupę',
  groupRenamed: 'Zmieniono nazwę grupy',
  groupArchived: 'Zarchiwizowano grupę',
  childEnrolled: 'Dziecko zostało przypisane do grupy',
  childDisenrolled: 'Dziecko zostało wypisane z grupy',
  teacherAssociated: 'Nauczyciel został przypisany do grupy',
  teacherDissociated: 'Nauczyciel został wypisant z grupy',
  //
  // activity
  'activity.defined': 'Zajęcie dodatkowe zostało utworzone',
  'activity.renamed': 'Zmieniono nazwę zajęcia dodatkowego',
  'activity.archived': 'Zarchiwizowano zajęcie dodatkowe',
  'activity.childEnrolled': 'Dziecko zostało przypisane do zajęcia dodatkowego',
  'activity.childDisenrolled': 'Dziecko zostało wypisane z zajęcia dodatkowego',
  //
  // staffMember
  // contract
  'staffMember.contract.schemeLinked': 'Załączono schemat płatności do pracownika',
  'staffMember.contract.schemeUnlinked': 'Odłączono schemat płatnoścu od pracownika',
  //
  staffMemberCreated: 'Utworzono pracownika',
  staffMemberProfileUpdated: 'Zedytowano profil pracownika',
  staffMemberArchived: 'Zarchiwizowano pracownika',
  staffMemberProfilePhotoAssigned: 'Dodano zdjęcie do profilu pracownika',
  staffMemberProfilePhotoRemoved: 'Usunięto zdjęcie z profilu pracownika',
  staffMemberRoleUpdated: 'Zmieniono rolę pracownika',
  //
  // child
  // contract
  'child.contract.schemeLinked': 'Załączono schemat płatności do dziecka',
  'child.contract.schemeUnlinked': 'Odłączono schemat płatności od dziecka',
  // openingBalance
  'child.contract.openingBalanceSet': 'Ustawiono bilans otwarcia',
  'child.contract.openingBalanceLocked': 'Zablokowano bilans otwarcia',
  'child.contract.openingBalanceUnlocked': 'Odblokowano bilans otwarcia',
  //
  //
  // diagnose
  'child.DiagnoseAdded': 'Dodano diagnozę dziecka',
  'child.DiagnoseRemoved': 'Usunięto diagnozę dziecka',
  //
  // attachment
  'child.attachmentCreated': 'Dodano załącznik dziecka',
  'child.attachmentRemoved': 'Usunięto załącznik dziecka',
  //
  'child.pinPrefixAssigned': 'Przypisano prefiks pinu do dziecka',
  'child.pinPrefixUnassigned': 'Odpięto prefiks pinu do dziecka',
  childRegistered: 'Utworzono dziecko',
  childDataUpdated: 'Zmieniono dane dziecka',
  childArchived: 'Zarchiwizowano dziecko',
  childLegalGuardianAssigned: 'Przypisano opiekuna prawnego do dziecko',
  childLegalGuardianRemoved: 'Usunięto opiekuna prawnego przypisanego do dziecka',
  childGuardianAssigned: 'Przypisano osobę upoważnioną do odbioru dziecka',
  childGuardianUpdated: 'Zmieniono dane osoby upoważnionej do odbioru dziecka',
  childGuardianAuthorizationUpdated: 'Zmieniono status zgłoszenia osoby upoważnionej do odbioru dziecka',
  childGuardianAuthorizationBySignUpdated:
    'Zmieniono status zgłoszenia osoby upoważnionej do odbioru dziecka profilem zaufanym',
  childGuardianRemoved: 'Usunięto osobę upoważnioną do odbioru dziecka',
  childGuardianPinSet: 'Ustawiono pin osobie upoważnionej do odbioru dziecka',
  childProfilePhotoAssigned: 'Dodano zdjęcie osobie upoważnionej do odbioru dziecka',
  childProfilePhotoRemoved: 'Usunięto zdjęcie osobie upoważnionej do odbioru dziecka',

  //
  // duesLog
  'duesLog.created': 'Utworzono rejestr płatności',
  'duesLog.entry.created': 'Dodano wpis do rejestru płatności',
  'duesLog.entry.deleted': 'Usunięto wpis z rejestru płatności',
  // funds
  'funds.credited': 'Uznano środki',
  'funds.creditedFromPayment': 'Uznano środki z płatności',
  'funds.creditedFromDueOverpayment': 'Uznano środki z nadpłaty',
  'funds.revertedCreditFromDueOverpayment': 'Zwrócono środki z nadpłaty',
  'funds.paymentCovered': 'Płatność została pokryta przez środki',
  'funds.amountCashed': 'Środki zostały wypłacone',
  'funds.initialFundsSet': 'Zdefiniowano środki początkowe',
  'funds.dueTotalReduced': 'Zredukowano należność',
  'funds.dueTotalReductionUndone': 'Cofnięto zredukowanie należności',
  'funds.transferredToAnotherWallet': 'Środki przeniesione do innego portfela',
  //
  //
  // attandanceJournal
  'attendanceJournal.created': 'Utworzono dziennik obecności',
  'attendanceJournal.entry.created': 'Utworzono wpis w dzienniku obecności',
  'attendanceJournal.entry.childDroppedOff': 'Dodano wpis o wejściu dziecka',
  'attendanceJournal.entry.childPickedUp': 'Dodano wpis o wyjściu dziecka',
  'attendanceJournal.entry.attendanceEntrySet': 'Zmieniono godziny obecności dziecka',
  'attendanceJournal.entry.childDropOffUndone': 'Wycofano wpis o wejściu dziecka',
  'attendanceJournal.entry.childPickUpUndone': 'Wycofano wyjście dziecka',
  'attendanceJournal.entry.absenceNoticeEntered': 'Dodano wpis o nieobecności dziecka',
  'attendanceJournal.entry.absenceNoticeRemoved': 'Usunięto wpis o nieobecności dziecka',
  //
  //Activity attendance
  'activityAttendanceJournal.created': 'Utworzono dziennik obecności zajęć dodatkowych',
  'activityAttendanceJournal.entry.created': 'Utworzono wpis w dzienniku obecności zajęć dodatkowych',
  'activityAttendanceJournal.entry.childDroppedOff': 'Dodano wpis o wejściu dziecka w dzienniku zajęć dodatkowych',
  'activityAttendanceJournal.entry.childPickedUp': 'Dodano wpis o wyjściu dziecka w dzienniku zajęć dodatkowych',
  'activityAttendanceJournal.entry.attendanceEntrySet': 'Zmieniono godziny obecności dziecka na zajęciach dodatkowych',
  'activityAttendanceJournal.entry.childDropOffUndone': 'Wycofano wpis o wejściu dziecka na zajęciach dodatkowych',
  'activityAttendanceJournal.entry.childPickUpUndone': 'Wycofano wyjście dziecka na zajęciach dodatkowych',
  'activityAttendanceJournal.entry.absenceNoticeEntered': 'Dodano wpis o nieobecności dziecka na zajęciach dodatkowych',
  'activityAttendanceJournal.entry.absenceNoticeRemoved':
    'Usunięto wpis o nieobecności dziecka na zajęciach dodatkowych',

  // daycareDetails
  'daycareDetails.entry.created': 'Utworzono wpis o aktywności dziecka',
  'daycareDetails.entry.updated': 'Zmieniono wpis o aktywności dziecka',
  //
  // legalGuardian
  legalGuardianRegistered: 'Dodano opiekuna prawnego',
  legalGuardianPersonalDataUpdated: 'Zmieniono dane opiekuna prawnego',
  legalGuardianProfilePhotoAssigned: 'Dodano zdjęcie opiekuna prawnego',
  legalGuardianProfilePhotoRemoved: 'Usunięto zdjęcie opiekuna prawnego',
  legalGuardianRemoved: 'Usunięto opiekuna prawnego',
  legalGuardianPinSet: 'Zmieniono pin opiekuna prawnego',
  //
  // user
  loginDataDefined: 'Zdefiniowane dane logowania',
  loginDataDeleted: 'Usunięto dane logowania',
  userLoginChanged: 'Zmieniono login uzytkownika',
  userPasswordChanged: 'Zmieniono hasło użytkownika',
  userPreferencesChanged: 'Zmieniono ustawienia użytkownika',
  passwordRecoveryRequested: 'Zażądano odzyskania hasła',
  activationProcessRestarted: 'Odnowiono proces aktywacji użytkownika',
  passwordExpired: 'Hasło wygasło',
  personalDetailsUpdated: 'Zmieniono dane osobiste użytkownika',
  roleUpdated: 'Zmieniono rolę użytkownika',
  confirmAcceptance: 'Zaakceptowano regulamin',
  changedAcceptance: 'Zmieniono regulamin',
  //
  // boardPost
  boardPostPublished: 'Opublikowano ogłoszenie',
  internalBoardPostPublished: 'Opublikowano ogłoszenie wewnętrzne',
  boardPostEdited: 'Zaktualizowano ogłoszenie',
  boardPostArchived: 'Zarchiwizowano ogłoszenie',
  //
  // privateMessage
  'privateMessage.sent': 'Wysłano wiadomość',
  'privateMessage.updated': 'Zaktualizowano wiadomość',
  'privateMessage.read': 'Przeczytano wiadomość',
  //
  // gallery
  createAlbum: 'Stworzono album',
  updateAlbum: 'Zaaktulizowano album',
  deleteAlbum: 'Usunięto album',
  addMultimedia: 'Dodano multimedia',
  updateMultimedia: 'Zaaktualizowano multimedia',
  deleteMultimedia: 'Usunięto multimedia',

  //
  // Scheduled transfers
  plannedEnrollmentCreated: 'Zaplanowano planowane przeniesienie',
  plannedEnrollmentRemoved: 'Zaarchiwizowano planowane przeniesienie',
  plannedEnrollmentUpdated: 'Zmodyfikowano planowane przeniesienie',
  plannedEnrollmentExecuted: 'Wykonano planowane przeniesienie',
  plannedEnrollmentRejected: 'Odrzucono planowane przeniesienie',
  plannedEnrollmentDisenrolled: 'Wypisano lub zapisano zapis',

  //
  // Virtual Disc
  virtualDiscDeletedFiles: 'Usunięto pliki z dysku wirtualnego',
  virtualDiscAddFile: 'Dodano plik do dysku wirtualnego',
  virtualDiscShareFile: 'Zmieniono uprawnienia pliku w dysku wirtualnym',

  //psychologicalPedagogicalAssistance
  psychologicalPedagogicalAssistanceDefined: 'Dodano dziecko do pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceArchived: 'Zarchiwizowano psychologiczno-pedagogiczną pomoc dziecka',
  psychologicalPedagogicalAssistanceLetterOfNotificationAdded:
    'Dodano pismo zawiadamiające w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceLetterOfNotificationEdited:
    'Edytowano pismo zawiadamiające w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceLetterOfNotificationArchived:
    'Zarchiwizowano pismo zawiadamiające w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceIPETAdded: 'Dodano wpis IPET w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceIPETEdited: 'Edytowano wpis IPET w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceIPETArchived: 'Zarchiwizowano wpis IPET w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceWOPFUAdded: 'Dodano wpis WPOFU w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceWOPFUEdited: 'Edytowano wpis WPOFU w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceWOPFUArchived: 'Zarchiwizowano wpis WPOFU w pomocy psychologiczno-pedagogicznej',
  psychologicalPedagogicalAssistanceTemplateDefined: 'Dodano schemat psychologiczno-pedagogiczny',
  psychologicalPedagogicalGroupDefined: 'Utworzono grupę psychologiczno-pedagogiczną',
  psychologicalPedagogicalGroupChildEnrolled: 'Przypisano dziecko do grupy psychologiczno-pedagogicznej',
  psychologicalPedagogicalGroupChildDisenrolled: 'Usunięto dziecko z grupy psychologiczno-pedagogicznej',
  psychologicalPedagogicalGroupTeacherAssociated: 'Przypisano nauczyciela do grupy psychologiczno-pedagogicznej',
  psychologicalPedagogicalGroupTeacherDissociated: 'Usunięto nauczyciela z grupy psychologiczno-pedagogicznej',
  //
  // Meals
  //
  mealCreated: 'Dodano posiłek',
  mealUpdated: 'Zaktualizowano posiłek',
  mealDeleted: 'Usunięto posiłek',
  mealArchived: 'Zarchiwizowano posiłek',

  mealMenuCreated: 'Dodano dietę',
  mealMenuUpdated: 'Zaktualizowano dietę',
  mealMenuDeleted: 'Usunięto diete',

  childrenEnrolledToMenu: 'Przypisano dzieci do diety',
  'child.mealMenus.childEnrolled': 'Przypisano dziecko do diety',
  childrenDisenrolledFromMenu: 'Odpięto dzieci od diety',
  'child.mealMenus.childDisenrolled': 'Odpięto dziecko od diety',
  'child.meals.mealsEdited': 'Zaktualizowano posiłki dziecka',
  mealCategoryCreated: 'Dodano kategorię posiłku',
  mealCategoryUpdated: 'Zaktualizowano kategorię posiłku',
  mealCategoryDeleted: 'Usunięto kategorię posiłku',
  mealAddedToCategory: 'Przypisano posiłek do kategorii',
  mealRemovedFromCategory: 'Usunięto posiłek z kategorii',

  //
  // Posts Coments
  commentAdded: 'Dodano komentarz',
  commentEdited: 'Zaktualizowano komentarz',
  commentDeleted: 'Usunięto komentarz'
};

export default eventTranslations;
