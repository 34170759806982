import makeStyles from '@mui/styles/makeStyles';

export const useToolkitStyles = makeStyles((theme) => ({
  wrapper: {
    border: `2px solid ${theme.palette.color.color4}`,
    position: 'sticky',
    top: 63,
    zIndex: theme.zIndex.appBar,
    background: theme.palette.background.default,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)'
  },
  actionButtons: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    justifyContent: 'center',
    width: ({ isMobile }) => (isMobile ? '100%' : 'auto')
  },
  tabs: {
    minHeight: 'auto'
  },
  scroller: {
    display: 'flex'
  },
  tab: {
    minWidth: 'auto',
    padding: '6px 12px',
    minHeight: 'auto'
  },
  selectedTab: {
    '&.Mui-selected': {
      borderRadius: 5
    }
  },
  tabIcons: {
    '&.Mui-selected': {
      color: theme.palette.color.contrast
    },
    color: theme.palette.color.contrast
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: ({ isMobile }) => (isMobile ? '100%' : 'auto')
  },
  mobileBorders: {
    borderTop: ({ isMobile }) => (isMobile ? `2px solid ${theme.palette.color.color4} ` : undefined),
    borderBottom: ({ isMobile }) => (isMobile ? `2px solid ${theme.palette.color.color4} ` : undefined),
    flexWrap: 'wrap'
  }
}));
