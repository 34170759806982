import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Checkbox, Typography } from '@mui/material';
import SortedTableCell from '../../../../table/SortedTableCell';
import PropTypes from 'prop-types';
import React from 'react';
import * as definedSortType from '../../../../../constants/definedSortTypes';
import userRoles from '../../../../../constants/userRoles';

const sortedCells = [
  {
    id: 'name',
    label: 'Nazwa',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'uploadedBy',
    label: 'Właściciel',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'uploadedAt',
    label: 'Data dodania',
    type: definedSortType.DATE_SORT
  },
  {
    id: 'size',
    label: 'Rozmiar',
    type: definedSortType.NUMBER_SORT
  }
];

const VirtualDiscTableHeader = ({ allItemsSelected, onSelect, orderBy, onRequestSort, order, user }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: 30 }} align="center" aria-label="wybierz wszystkie">
          <Checkbox
            inputProps={{ 'aria-label': 'Zaznacz wszystkie' }}
            checked={allItemsSelected}
            onChange={() => (allItemsSelected ? onSelect('none') : onSelect('all'))}
          />
        </TableCell>
        {sortedCells.map((cell) => (
          <SortedTableCell
            key={cell.id}
            order={order}
            orderBy={orderBy.property}
            onRequestSort={onRequestSort}
            cell={cell}
          />
        ))}
        {user !== userRoles.legalGuardian ? (
          <TableCell align="left">
            <Typography>Informacje dodatkowe</Typography>
          </TableCell>
        ) : (
          false
        )}

        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
};

VirtualDiscTableHeader.propTypes = {
  allItemsSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  orderBy: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired
};

export default VirtualDiscTableHeader;
