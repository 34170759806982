import api from '../api/AttendanceApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import _ from 'lodash';
import moment from 'moment';
import { Person, PersonGuardian } from '../models/attendance/AttendanceModels';
import exceededAbsenceReportDeadline from '../utils/exceededAbsenceReportDeadline';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

function getCurrentUserAsPerson(getState) {
  const { userFullName, userId, profilePhoto } = getState().auth;
  const firstName = userFullName.split(' ')[0];
  const lastName = userFullName.split(' ')[1];

  return new Person().assign({ firstName, lastName, id: userId, photoUrl: profilePhoto });
}

export function loadAttendanceDaysInMonthAsync(groupId, date) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ATTENDANCE_DAYS_IN_MONTH });
    const momentDate = moment(date, 'YYYY-MM-DD');
    const days = _.rangeRight(1, momentDate.daysInMonth() + 1)
      .map((day) => momentDate.clone().set('date', day))
      .filter((day) => day.isoWeekday() !== 6 && day.isoWeekday() !== 7)
      .map((day) => day.format('YYYY-MM-DD'));

    return dispatch({ type: types.LOAD_ATTENDANCE_DAYS_IN_MONTH_SUCCESS, days });

    // todo: pomyslec czy to zostawiamy
    /*return api
     .getAttendanceDaysInMonth(groupId, date)
     .then(days => dispatch({type: types.LOAD_ATTENDANCE_DAYS_IN_MONTH_SUCCESS, days}))
     .catch(error => {
     dispatch(notificationActions.showError(error));
     return logger.error(error);
     });*/
  };
}

export function loadAttendanceMonthsAsync(groupId) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ATTENDANCE_MONTHS });

    const years = [moment().subtract(1, 'years').format('YYYY'), moment().format('YYYY')];

    const months = [];
    _.range(1, 13).forEach((month) => months.push(`${years[0]}-${_.padStart(month, 2, '0')}`));
    _.range(1, moment().month() + 2).forEach((m) => months.push(`${years[1]}-${_.padStart(m, 2, '0')}`));

    return dispatch({ type: types.LOAD_ATTENDANCE_MONTHS_SUCCESS, groupId, months });
    /*return api
     .getAttendanceMonths(groupId)
     .then(months => dispatch({type: types.LOAD_ATTENDANCE_MONTHS_SUCCESS, groupId, months}))
     .catch(error => {
     dispatch(notificationActions.showError(error));
     return logger.error(error);
     });*/
  };
}

export function loadPreviousAttendanceAsync(groupId, date) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_PREVIOUS_ATTENDANCE });
    return api
      .getAttendance(groupId, date)
      .then((attendanceJournal) => dispatch({ type: types.LOAD_PREVIOUS_ATTENDANCE_SUCCESS, attendanceJournal }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać listy obecności z dnia ${get.displayDate(date)} dla grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadAllPreviousAttendancesAsync(date) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ALL_PREVIOUS_ATTENDANCE });
    return api
      .getAllAttendances(date)
      .then((attendanceJournals) => dispatch({ type: types.LOAD_ALL_PREVIOUS_ATTENDANCE_SUCCESS, attendanceJournals }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy obecności z dnia ${get.displayDate(date)}`));
        return logger.error(error);
      });
  };
}

export function revertPreviousChildAttendanceStart() {
  return { type: types.REVERT_PREVIOUS_CHILD_ATTENDANCE };
}

export function revertPreviousChildAttendanceCancel() {
  return { type: types.REVERT_PREVIOUS_CHILD_ATTENDANCE_CANCEL };
}

export function revertPreviousChildAttendanceAsync(
  childId,
  droppedOfAt,
  pickedUpAt,
  absenceFrom,
  absenceTo,
  absenceNotes
) {
  return (dispatch, getState) => {
    dispatch({ type: types.REVERT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT });
    return api
      .revertPreviousChildAttendanceAsync(childId, droppedOfAt, pickedUpAt, absenceFrom, absenceTo, absenceNotes)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zgłoszono nieobecność ${get.displayDateRange(absenceFrom, absenceTo)} dla dziecka '${get.childName(
              getState,
              childId
            )}'`
          )
        );
        const { contract } = getState().childDetails;
        const absenceDeadlineViolated = exceededAbsenceReportDeadline(
          contract ? contract.schemes : [],
          absenceFrom,
          moment().format(),
          false
        );
        const absenceReportedBy = getCurrentUserAsPerson(getState);
        dispatch({
          type: types.REVERT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS,
          childId,
          absenceFrom,
          absenceTo,
          absenceNotes,
          absenceDeadlineViolated,
          absenceReportedBy
        });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REVERT_PREVIOUS_CHILD_ATTENDANCE_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zgłosić nieobecność ${get.displayDateRange(
              absenceFrom,
              absenceTo
            )} dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function reportPreviousChildAttendanceStart() {
  return { type: types.REPORT_PREVIOUS_CHILD_ATTENDANCE };
}

export function reportPreviousChildAttendanceCancel() {
  return { type: types.REPORT_PREVIOUS_CHILD_ATTENDANCE_CANCEL };
}

export function reportPreviousChildAttendanceAsync(
  childId,
  droppedOfAt,
  pickedUpAt,
  guardianId,
  absenceFrom,
  absenceTo
) {
  return (dispatch, getState) => {
    dispatch({ type: types.REPORT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT });
    return api
      .reportPreviousChildAttendanceAsync(childId, droppedOfAt, pickedUpAt, guardianId, absenceFrom, absenceTo)
      .then(() => {
        const updateChild = getState().children.find((c) => c.id === childId);
        const { id, firstName, lastName, relationship, photoUrl } = [
          ...updateChild.legalGuardians,
          ...updateChild.guardians
        ].find((g) => g.id === guardianId);
        const pickedUpBy = new PersonGuardian().assign({ id, firstName, lastName, relationship, photoUrl });
        const receivedBy = getCurrentUserAsPerson(getState);
        const releasedBy = getCurrentUserAsPerson(getState);
        dispatch(
          notificationActions.showSuccess(
            `Zgłoszono obecność ${get.displayTimeRange(droppedOfAt, pickedUpAt)} (${get.displayDate(
              droppedOfAt
            )}) dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        dispatch({
          type: types.REPORT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS,
          childId,
          droppedOfAt,
          pickedUpAt,
          pickedUpBy,
          receivedBy,
          releasedBy
        });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.REPORT_PREVIOUS_CHILD_ATTENDANCE_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zgłosić obecności ${get.displayTimeRange(droppedOfAt, pickedUpAt)} (${get.displayDate(
              droppedOfAt
            )}) dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}
