/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function settlementsCalculationUiReducer(state = initialState.settlementsCalculationUi, action) {
  switch (action.type) {
    case types.CALCULATE_SETTLEMENTS: {
      return UiModel.start();
    }
    case types.CALCULATE_SETTLEMENTS_CANCEL: {
      return UiModel.cancel();
    }
    case types.CALCULATE_SETTLEMENTS_SUBMIT: {
      return UiModel.submit();
    }
    case types.CALCULATE_SETTLEMENTS_SUCCESS: {
      return UiModel.success();
    }
    case types.CALCULATE_SETTLEMENTS_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.settlementsCalculationUi);
    }
    default:
      return state;
  }
}
