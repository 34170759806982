import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../routePaths';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActionArea,
  Typography,
  Collapse,
  Paper,
  CardContent
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const GroupEnrollmentHistoryCard = ({ enrolledToGroupHistory, isInitiallyExpanded }) => {
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const history = _.sortBy(enrolledToGroupHistory, (x) => x.enrolledAt).reverse();

  if (enrolledToGroupHistory.length === 0) {
    return null;
  }

  return (
    <Paper elevation={1} sx={{ p: 1, mb: 2 }}>
      <CardActionArea onClick={handleOpen}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1, alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: '4px' }}>
            Historia przypisania do grup
          </Typography>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </CardContent>
      </CardActionArea>
      <Collapse in={isOpen}>
        {history.length && (
          <Table aria-label="Zaległe opłaty">
            <TableHead>
              <TableRow>
                <TableCell>Grupa</TableCell>
                <TableCell>Przypisano</TableCell>
                <TableCell>Wypisano</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((enrolled, index) => (
                <TableRow
                  key={index}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      background: (theme) => theme.palette.background.hover
                    }
                  }}>
                  <TableCell>
                    <Typography
                      onClick={() => browserHistory.push(fromTemplate(routePaths.groupDetails, [enrolled.id]))}>
                      {enrolled.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{enrolled.enrolledAt && moment(enrolled.enrolledAt).format('L')}</TableCell>
                  <TableCell>{enrolled.disenrolledAt && moment(enrolled.disenrolledAt).format('L')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Typography component="caption" sx={{ pl: 2 }}>
              Historia przypisania do grup
            </Typography>
          </Table>
        )}
      </Collapse>
    </Paper>
  );
};

GroupEnrollmentHistoryCard.propTypes = {
  enrolledToGroupHistory: PropTypes.array.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default GroupEnrollmentHistoryCard;
