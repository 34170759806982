import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, MenuItem, Typography } from '@mui/material';
import SingleDatePickerTemplate from './common/SingleDatePickerTemplate';

const StatementOfExpectedInterestAmountForDayReport = ({ legend, groupId, date, groups, onChange, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FieldsetWrapper legend={legend}>
        <SelectField
          errorText={errors.groupId}
          contrast
          sx={{ width: 'auto', overflow: 'hidden', pb: 2 }}
          floatingLabelText="Grupa"
          id="groupId"
          value={groupId}
          onChange={(event) => {
            onChange({ target: { name: 'groupId', value: event.target.value } });
          }}>
          {groups.map((group) => {
            return (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <Typography variant="h6" sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          Wybierz datę
        </Typography>
        <SingleDatePickerTemplate
          date={date}
          errors={errors}
          name="Wybierz datę"
          legend={legend}
          onChange={(event) => {
            onChange({ target: { name: 'date', value: event.target.value } });
          }}
        />
      </FieldsetWrapper>
    </Box>
  );
};

StatementOfExpectedInterestAmountForDayReport.propTypes = {
  legend: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default StatementOfExpectedInterestAmountForDayReport;
