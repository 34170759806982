import React, { useState } from 'react';
import { Input, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import numberFormatter from '../../../../utils/numberFormatter';
import { CalculationStatus } from '../../../../constants/calculationStatus';
import moment from 'moment';
import { ChargingReportTypes } from '../../../../constants/chargingReportTypes';
import PropTypes from 'prop-types';
import Checkbox from '../../../common/Checkbox';
import SettlementStatus from '../../SettlementStatus';

const useCellBodyStyles = makeStyles({
  root: {
    fontSize: 16,
    py: 0,
    px: 2,
    align: 'left'
  }
});

const PartlyCorrectPaymentsTableRow = ({
  row,
  onChangeAmount,
  onCheckboxChange,
  onValidateErrors,
  legalGuardianData,
  date
}) => {
  const cellBodyStyles = useCellBodyStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);

  const handleInputChange = (event) => {
    const temp = event.target.value;
    if (temp >= 0) {
      setPaymentAmount(temp);
    }
    onChangeAmount(row.id, parseFloat(temp) || 0, date, row.person);
    if (numberFormatter.format(temp) > numberFormatter.format(row.getAmountToPaid())) {
      onValidateErrors(legalGuardianData.id, true);
    } else {
      onValidateErrors(legalGuardianData.id, false);
    }
  };

  const handleCheck = () => {
    setPaymentAmount(0);
    setIsChecked((prev) => !prev);
    onCheckboxChange(row.id, paymentAmount);
    if (!isChecked) {
      onChangeAmount(row.id, 0, date, row.person);
    }
    if (numberFormatter.format(event.target.value) > numberFormatter.format(row.getAmountToPaid())) {
      onValidateErrors(legalGuardianData.id, true);
    } else {
      onValidateErrors(legalGuardianData.id, false);
    }
    if (isChecked) {
      onValidateErrors(legalGuardianData.id, false);
    }
  };

  return (
    <TableRow
      tabIndex={0}
      sx={{
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}>
      <TableCell classes={{ root: cellBodyStyles.root }}>
        <Checkbox defaultChecked={isChecked} onCheck={() => handleCheck()} />
      </TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }}>{row.number}</TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }}>{`${row.name}`}</TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }}>{ChargingReportTypes[row.dueType].name}</TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }}>
        <strong>
          {row.getTotalAmount() !== undefined && row.getTotalAmount() !== null
            ? numberFormatter.format(row.getTotalAmount())
            : '-'}
        </strong>
      </TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }}>
        <strong>
          {row.getAmountToPaid() !== undefined && row.getAmountToPaid() !== null
            ? numberFormatter.format(row.getAmountToPaid())
            : '-'}
        </strong>
      </TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }}>
        <SettlementStatus calculationStatus={row.calculationStatus} />
      </TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }} style={{ maxWidth: 110 }}>
        {moment(row.calculationDate).format('YYYY-MM-DD')}
      </TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }} style={{ maxWidth: 110 }}>
        <strong>{numberFormatter.format(row.paidAmount)}</strong>
      </TableCell>
      <TableCell classes={{ root: cellBodyStyles.root }} style={{ maxWidth: 110 }}>
        <Input
          onChange={handleInputChange}
          type="number"
          value={isChecked ? paymentAmount : ''}
          disabled={!isChecked}
        />
      </TableCell>
    </TableRow>
  );
};

PartlyCorrectPaymentsTableRow.propTypes = {
  row: PropTypes.object,
  onCheckboxChange: PropTypes.func,
  onChangeAmount: PropTypes.func,
  onValidateErrors: PropTypes.func,
  legalGuardianData: PropTypes.object,
  date: PropTypes.string
};

export default PartlyCorrectPaymentsTableRow;
