import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButtonPipe from '../../../common/IconButtonPipe';
import FileSpecificIcon from '../../../common/FileSpecificIcon/FileSpecificIcon';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Box, CircularProgress, Grid, ListItem, Typography } from '@mui/material';
import FolderMenu from '../FolderMenu/FolderMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderContent } from '../../../../actions/virtualDiscActions';
import userRoles from '../../../../constants/userRoles';
import { themeTypes } from '../../../../styles/appTheme';

const Folder = ({
  item,
  handleOnDrop,
  handleOnDrag,
  draggedItem,
  handleOpenMoveToFolderDialog,
  onOpenFolder,
  hideMenu = false,
  disableDragging = false,
  onClick,
  isSelected,
  isCheckbox
}) => {
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.action);
  const userRole = useSelector((state) => state.auth.userRole);
  const selectedTheme = useSelector((state) => state.theme);
  const isManagement = userRole !== userRoles.legalGuardian && userRole !== userRoles.staffMemberCanteenWorker;
  const [isHighlighted, setIsHighlighted] = useState(false);

  const { id, name, type } = item;
  const dispatch = useDispatch();
  const onDragStart = (e) => {
    if (!isManagement) return;
    handleOnDrag(e, item);
  };
  const handleDragOver = (e) => {
    if (draggedItem.id !== item.id) setIsHighlighted(true);
    if (!isManagement) return;
    e.preventDefault();
  };
  const handleDragLeave = (e) => {
    setIsHighlighted(false);
    if (!isManagement) return;
    e.preventDefault();
  };
  const handleOpenFolder = () => {
    !!onOpenFolder && onOpenFolder();
    dispatch(getFolderContent(id));
  };
  const OnDrop = () => {
    if (draggedItem.id !== id && isManagement) {
      const preparedData = {
        items: [{ id: draggedItem.id, type: draggedItem.type === 'folder' ? 'folder' : 'file' }],
        newParentId: item.id
      };
      handleOnDrop(preparedData);
    }
    setIsHighlighted(false);
    return;
  };
  const keyDownFunction = (e) => {
    if (e.keyCode === 32) onClick();
  };

  return (
    <ListItem
      sx={{
        p: 0.25,
        background: (theme) =>
          isHighlighted || isSelected
            ? selectedTheme !== themeTypes.contrast.value
              ? item.color
              : theme.palette.components.fileIcons.folder
            : theme.palette.background.color3,
        overflow: 'hidden',
        borderRadius: '5px',
        '&:hover': {
          background: (theme) =>
            isHighlighted || isSelected
              ? selectedTheme !== themeTypes.contrast.value
                ? item.color
                : theme.palette.components.fileIcons.folder
              : theme.palette.background.hover
        }
      }}>
      <Box
        aria-label={isCheckbox ? `Zaznaczalny folder ${item.name}` : undefined}
        role={isCheckbox ? 'checkbox' : undefined}
        aria-checked={isCheckbox ? isSelected : undefined}
        tabIndex={isCheckbox && 0}
        onDragStart={onDragStart}
        draggable={isManagement && !disableDragging}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDoubleClick={handleOpenFolder}
        onClick={onClick}
        onKeyDown={keyDownFunction}
        onDrop={OnDrop}
        sx={{
          minHeight: 184,
          height: '100%',
          px: 1.75,
          py: 0.75,
          cursor: isManagement && !disableDragging ? 'grab' : 'pointer',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '4px',
          overflow: 'hidden',
          background: (theme) => theme.palette.background.color3,
          '&:hover': {
            background: (theme) => theme.palette.background.hover
          }
        }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButtonPipe
            tooltip={`Otwórz folder: ${name}`}
            onClick={(e) => {
              handleOpenFolder();
              e.stopPropagation();
            }}>
            <FolderOpenIcon sx={{ color: (theme) => theme.palette.color.color22 }} />
          </IconButtonPipe>
          {isManagement && !hideMenu && (
            <FolderMenu item={item} handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog} />
          )}
        </Grid>

        {isProcessing && draggedItem?.id === item.id ? (
          <CircularProgress />
        ) : (
          <FileSpecificIcon type={type} item={item} style={{ fontSize: 60, marginBottom: 10 }} />
        )}
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            mx: 'auto',
            marginTop: 'auto',
            textAlign: 'center',
            display: '-webkit-box',
            webkitLineClamp: '2',
            webkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all'
          }}>
          {name}
        </Typography>
      </Box>
    </ListItem>
  );
};

Folder.propTypes = {
  handleOnDrop: PropTypes.func,
  handleOnDrag: PropTypes.func,
  onOpenFolder: PropTypes.func,
  onClick: PropTypes.func,
  draggedItem: PropTypes.object,
  item: PropTypes.object.isRequired,
  hideMenu: PropTypes.bool,
  disableDragging: PropTypes.bool,
  isSelected: PropTypes.bool
};
export default React.memo(Folder);
