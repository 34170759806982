import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Box, Grid, Typography } from '@mui/material';

const ChildAttendanceSummary = ({ summary, hidePaidDays, excusedAbsences }) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', my: 2 }}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <TodayOutlinedIcon />
          <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
            Ilość dni:
          </Typography>
          <Typography sx={{ m: 0.25 }}>{summary.totalDays}</Typography>
        </Grid>
        {!hidePaidDays && (
          <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <TodayOutlinedIcon />
            <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
              Ilość dni płatnych:
            </Typography>
            <Typography sx={{ m: 0.25 }}>
              {summary.totalPaidDays != null ? summary.totalPaidDays : summary.totalDays}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <TimerOutlinedIcon />
          <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
            Czas pobytu:
          </Typography>
          <Typography sx={{ m: 0.25 }}>
            {summary.totalHours}:{_.padStart(summary.totalMinutes, 2, '0')}
          </Typography>
        </Grid>
        {!!excusedAbsences && (
          <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <ArticleOutlinedIcon />
            <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
              Usprawiedliwione nieobecności:
            </Typography>
            <Typography sx={{ m: 0.25 }}>{excusedAbsences}</Typography>
          </Grid>
        )}
        {summary.schemeHasFreeHours ? (
          <Grid item xs={12} sm={6} xl={3} sx={{ display: 'flex' }}>
            <TimerOutlinedIcon />
            <Typography
              variant="subtitle1"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'right'
              }}>
              Czas pobytu płatny:
            </Typography>
            <Typography sx={{ m: 0.25 }}>
              {summary.totalPaidHours}:{_.padStart(summary.totalPaidMinutes, 2, '0')}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

ChildAttendanceSummary.propTypes = {
  summary: PropTypes.object.isRequired,
  hidePaidDays: PropTypes.bool
};

export default ChildAttendanceSummary;
