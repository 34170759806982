import React, { useState } from 'react';
import IconButtonPipe from '../../../common/IconButtonPipe';
import Popover from '@mui/material/Popover';
import { Clear } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as SortTypes from '../../../../constants/sortTypes';

const Sorts = ({ sortType, handleSort, title, icon, contrast, isAlbums = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box onClick={({ currentTarget }) => setAnchorEl(currentTarget)} sx={{ cursor: 'pointer', p: 1 }}>
        {icon || (
          <Button variant={contrast ? 'outlinedContrast' : 'outlined'} aria-label="sortuj">
            sortuj
          </Button>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <>
          <Box sx={{ px: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>Sortowanie</Typography>
            <IconButtonPipe onClick={() => setAnchorEl(null)}>
              <Clear />
            </IconButtonPipe>
          </Box>
          <Box sx={{ px: 2 }}>
            <FormLabel component="legend">
              <Typography sx={{ color: (theme) => theme.palette.color.contrast }}>{title} od:</Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={sortType}
                sx={{ p: 1 }}
                onChange={(e) => handleSort(e.target.value)}>
                <FormControlLabel value={SortTypes.NEWEST} control={<Radio />} label="Najnowszego" />
                <FormControlLabel value={SortTypes.LATEST} control={<Radio />} label="Najstarszego" />
                {isAlbums && (
                  <>
                    <FormControlLabel
                      value={SortTypes.LATEST_MODIFIED}
                      control={<Radio />}
                      label="Ostatnio zaaktualizowanego"
                    />
                    <FormControlLabel value={SortTypes.A_Z} control={<Radio />} label="Alfabetycznie A-Z" />
                    <FormControlLabel value={SortTypes.Z_A} control={<Radio />} label="Alfabetycznie Z-A" />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      </Popover>
    </>
  );
};

Sorts.propTypes = {
  cl: PropTypes.object,
  sortType: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isAlbums: PropTypes.bool,
  icon: PropTypes.string
};

export default Sorts;
