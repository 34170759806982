import userRoles from '../../../constants/userRoles';
import uuid from 'uuid/v4';

export const userRolesOptions = [
  {
    id: uuid(),
    name: 'Opiekunowie prawni',
    role: userRoles.legalGuardian,
    tooltip: 'Udostępniony dla opiekunów prawnych'
  },
  {
    id: uuid(),
    name: 'Księgowi',
    role: userRoles.staffMemberAccountant,
    tooltip: 'Udostępniony dla księgowych'
  },
  {
    id: uuid(),
    name: 'Sekretariat',
    role: userRoles.staffMemberSecretary,
    tooltip: 'Udostępniony dla sekretariatu'
  },
  {
    id: uuid(),
    name: 'Nauczyciele',
    role: userRoles.staffMemberTeacher,
    tooltip: 'Udostępniony dla nauczycieli'
  },
  {
    id: uuid(),
    name: 'Nauczyciele - Zajęcia dodatkowe',
    role: userRoles.staffMemberDigitalDiary,
    tooltip: 'Udostępniony dla nauczycieli prowadzących zajęcia dodatkowe'
  },
  {
    id: uuid(),
    name: 'Pracownicy stołówki',
    role: userRoles.staffMemberCanteenWorker,
    tooltip: 'Udostępniony dla pracowników stołówki'
  }
];
