import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import { DiagnosticSchemeSetModelValidator } from '../../../../models/configuration/diagnosticSchemes/DiagnosticSchemeSetModelValidator';
import { DiagnosticSchemaSetModel } from '../../../../models/configuration/diagnosticSchemes/DiagnosticSchemeSetModel';
import DiagnosticSchemeSetForm from './DiagnosticSchemeSetForm';
import { Box } from '@mui/material';

class CreateDiagnosticSchemeSetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosticSchemeSet: new DiagnosticSchemaSetModel().assign(this.props.diagnosticSchemeSet),
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSuperTextEditor = this.handleSuperTextEditor.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const diagnosticSchemeSet = { ...this.state.diagnosticSchemeSet };
    diagnosticSchemeSet[field] = event.target.value;
    return this.setState({ diagnosticSchemeSet });
  }

  handleSuperTextEditor(value) {
    const superText = value;
    const diagnosticSchemeSet = { ...this.state.diagnosticSchemeSet };
    diagnosticSchemeSet.content = superText;
    return this.setState({ diagnosticSchemeSet });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing, isEditing } = this.props;
    return (
      <Box>
        <DialogForm
          header={isEditing ? 'Edycja szablonu diagnoz' : 'Dodaj szablon diagnoz'}
          onSave={() => {
            onSave(this.state.diagnosticSchemeSet);
          }}
          onCancel={onCancel}
          isDialogOpen={isDialogOpen}
          isProcessing={isProcessing}
          onValidate={() => new DiagnosticSchemeSetModelValidator().validate(this.state.diagnosticSchemeSet)}
          onValidationDone={(errors) => this.setState({ errors })}
          requiredFieldText
          statePathToUi="configuration.attributesSetsUi" //Nie wiem gdzie to znalezc, zostawiam
        >
          <DiagnosticSchemeSetForm
            diagnosticSchemeSet={this.state.diagnosticSchemeSet}
            onChange={this.handleChange}
            handleSuperTextEditor={this.handleSuperTextEditor}
            errors={this.state.errors}
          />
        </DialogForm>
      </Box>
    );
  }
}

CreateDiagnosticSchemeSetDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  diagnosticSchemeSet: PropTypes.object,
  isEditing: PropTypes.bool
};

export default CreateDiagnosticSchemeSetDialog;
