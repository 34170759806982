/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { MessageBoardFilterModel } from '../models/messageBoardFilter/MessageBoardFilterModels';

export default function messageBoardUiReducer(state = initialState.messageBoardFilter, action) {
  switch (action.type) {
    case types.FILTER_MESSAGE_BOARD_SUCCESS: {
      return new MessageBoardFilterModel().assign(action.filters);
    }
    default:
      return state;
  }
}
