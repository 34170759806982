import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import ChildAttendanceCalendar from '../../common/children/attendance/calendar/ChildAttendanceCalendar';
import { useDispatch, useSelector } from 'react-redux';
import PsychologicalAttendanceDialog from '../../attendanceSheet/psychological/psychologicalAttendanceDialog';
import PsychologicalAbsenceDialog from '../../attendanceSheet/psychological/psychologicalAbsenceDialog';
import {
  loadChildGroupClassesAttendanceAsync,
  reportGroupAttendanceStartAsync,
  revertGroupAbsence
} from '../../../actions/psychologicalAttendanceActions';
import moment from 'moment';
import { changePsychologicalGroup } from '../../../actions/psychologicalChoosenGroupActions';
import { Box } from '@mui/system';
import AttendanceDetailsDialog from '../attendance/dialogs/AttendanceDetailsDialog';
import PsycholologicalGroupSwitch from '../../common/psychologicalGroups/PsycholologicalGroupSwitch';

const ChildGroupPsychologicalAttendance = ({ childDetails, isInitiallyExpanded }) => {
  const dispatch = useDispatch();
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const attendances = useSelector((state) => state.childPsychologicalGroupAttendances);
  const psychologicalGroups = useSelector((state) => state.psychologicalGroups);
  const Ui = useSelector((state) => state.childDetailsUi.groupAttendanceUi);
  const [selectedDate, setSelectedDate] = useState(null);
  const childGroups = useSelector((state) => state.childDetails.enrolledToPsychologicalPedagogicalGroup);
  const [selectedGroup, setSelectedGroup] = useState(childGroups[0]?.id);
  const [mode, setMode] = useState(null);
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [selectedDetailsDay, setSelectedDetailsDay] = useState();

  useEffect(() => {
    if (!selectedGroup) {
      setSelectedGroup(childGroups[0].id);
    }
  }, [childGroups, selectedGroup]);

  useEffect(() => {
    dispatch(loadChildGroupClassesAttendanceAsync(selectedGroup, childDetails.id, moment().format('YYYY-MM')));
  }, [attendances.length, childDetails.id, childGroups, dispatch, selectedGroup]);

  const handleGroupChange = (value) => {
    dispatch(loadChildGroupClassesAttendanceAsync(value, childDetails.id, moment().format('YYYY-MM')));
    setSelectedGroup(value);
    dispatch(changePsychologicalGroup(psychologicalGroups.find((group) => group.id === value)));
  };

  const onDateChange = (e) => {
    dispatch(loadChildGroupClassesAttendanceAsync(selectedGroup, childDetails.id, e.format('YYYY-MM')));
  };

  const handleDetailsOpen = (e) => {
    setSelectedDetailsDay(e);
    setDetailsOpen(true);
  };

  const renderDialog = () => {
    switch (mode) {
      case 'attendance':
        return (
          <PsychologicalAttendanceDialog
            isGroup
            groupId={selectedGroup}
            Ui={Ui}
            childDetails={childDetails}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
          />
        );
      case 'absence':
        return (
          <PsychologicalAbsenceDialog
            isGroup
            groupId={selectedGroup}
            Ui={Ui}
            childDetails={childDetails}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
          />
        );
      case 'revertAbsence':
        return (
          <PsychologicalAbsenceDialog
            isGroup
            groupId={selectedGroup}
            Ui={Ui}
            childDetails={childDetails}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
            absenceFrom={selectedDate}
            absenceTo={selectedDate}
            absenceNotes=""
            isAbsenceFromBlocked={false}
            isAbsenceToBlocked={false}
          />
        );
      case 'revertAttendance':
        return (
          <PsychologicalAbsenceDialog
            groupId={selectedGroup}
            isGroup
            Ui={Ui}
            childDetails={childDetails}
            journalDate={selectedDate}
            attendanceDetails={attendances.entries.find(
              (day) => new Date(day.date).toDateString() === new Date(selectedDate).toDateString()
            )}
            absenceFrom={selectedDate}
            absenceTo={selectedDate}
            absenceNotes=""
            isAbsenceFromBlocked={false}
            isAbsenceToBlocked={false}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Accordion sx={{ p: 1, mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Obecności na zajęciach grupowych
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <PsycholologicalGroupSwitch groups={childGroups} handleChange={handleGroupChange} value={selectedGroup} />
          </Box>
          <ChildAttendanceCalendar
            noDescription
            allDays={attendances}
            markedDays={!!attendances.entries ? attendances.entries : []}
            attendanceSummary={!!attendances.summary ? attendances.summary : {}}
            daysOff={daysOff}
            onDateChange={(e) => onDateChange(e)}
            onDaySelected={() => {}}
            onReportAttendance={(day) => {
              setSelectedDate(day?.date);
              setMode('attendance');
              dispatch(reportGroupAttendanceStartAsync());
            }}
            onReportAbsence={(day) => {
              setSelectedDate(day?.date);
              setMode('absence');
              dispatch(reportGroupAttendanceStartAsync());
            }}
            onRevertAbsence={(day) => {
              dispatch(revertGroupAbsence(childDetails.id, day, day, selectedGroup));
            }}
            onDetailsOpen={(day) => handleDetailsOpen(day)}
            isLoading={Ui.isCalendarLoading}
          />
          {Ui.isEditing && renderDialog()}
        </AccordionDetails>
      </Accordion>
      <AttendanceDetailsDialog
        isPsychological
        isDialogOpen={isDetailsOpen}
        childDetails={childDetails}
        childAttendance={attendances?.entries?.find((day) => day?.date === selectedDetailsDay?.data?.date)}
        onRequestClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default ChildGroupPsychologicalAttendance;
