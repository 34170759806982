import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { PaymentMethods } from '../../../constants/paymentMethods';
import SelectField from '../../common/SelectField';
import Checkbox from '../../common/Checkbox';
import { ReportTypes } from '../../../constants/reportTypes';
import { Box, Typography, MenuItem } from '@mui/material';
import DatePickerAndGroupTemplate from './common/DateAndGroupPickerTemplate';
import { connect } from 'react-redux';
import userRoles from '../../../constants/userRoles';

class PaymentsSummaryPerChildReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'name',
      sortOptions: [
        { name: 'Po nazwisku', value: 'name' },
        { name: 'Po numerze', value: 'documentNumber' }
      ],
      dueTypes: [ChargingReportTypes.stay.value, ChargingReportTypes.catering.value, ChargingReportTypes.other.value],
      paymentMethods: [
        PaymentMethods.cash.value,
        PaymentMethods.online.value,
        PaymentMethods.transfer.value,
        PaymentMethods.fromBalance.value
      ],
      paymentsInSelectedRange: false
    };
  }

  renderDueTypeCheckbox(name, dueType) {
    return (
      <Checkbox
        label={name}
        key={name}
        sx={{ width: 'auto', mr: 2 }}
        defaultChecked={this.state.dueTypes.includes(dueType)}
        onCheck={(e, v) => {
          let newVal;
          if (!v) newVal = [...this.state.dueTypes.filter((dt) => dt !== dueType)];
          else newVal = [...this.state.dueTypes, ...[dueType]];
          this.setState({ dueTypes: newVal });
          this.props.onChange({ target: { name: 'dueTypes', value: newVal } });
        }}
      />
    );
  }

  renderPaymentMethodCheckbox(name, method) {
    return (
      <Checkbox
        label={name}
        key={name}
        sx={{ width: 'auto', mr: 2, whiteSpace: 'nowrap' }}
        defaultChecked={this.state.paymentMethods.includes(method)}
        onCheck={(e, v) => {
          let newVal;
          if (!v) newVal = [...this.state.paymentMethods.filter((pm) => pm !== method)];
          else newVal = [...this.state.paymentMethods, ...[method]];
          this.setState({ paymentMethods: newVal });
          this.props.onChange({ target: { name: 'paymentMethods', value: newVal } });
        }}
      />
    );
  }

  render() {
    const { startDate, endDate, errors, onChange, groups, groupId } = this.props;
    return (
      <Box>
        <DatePickerAndGroupTemplate
          manyGroupsSelector
          groups={groups}
          groupId={groupId}
          startDate={startDate}
          endDate={endDate}
          errors={errors}
          onChange={onChange}
          legend={ReportTypes.paymentsSummaryPerChild.name}
        />
        <Typography variant="h6" sx={{ pt: 2 }}>
          Typ opłat
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {this.renderDueTypeCheckbox(ChargingReportTypes.stay.name, ChargingReportTypes.stay.value)}
          {this.renderDueTypeCheckbox(ChargingReportTypes.catering.name, ChargingReportTypes.catering.value)}
          {this.renderDueTypeCheckbox(ChargingReportTypes.other.name, ChargingReportTypes.other.value)}
          {this.renderDueTypeCheckbox(ChargingReportTypes.activities.name, ChargingReportTypes.activities.value)}
        </Box>
        <Typography variant="h6" sx={{ pt: 2 }}>
          Forma płatności
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {this.renderPaymentMethodCheckbox(PaymentMethods.cash.shortName, PaymentMethods.cash.value)}
          {this.renderPaymentMethodCheckbox(PaymentMethods.transfer.shortName, PaymentMethods.transfer.value)}
          {this.renderPaymentMethodCheckbox(PaymentMethods.online.shortName, PaymentMethods.online.value)}
          {this.renderPaymentMethodCheckbox(PaymentMethods.fromBalance.shortName, PaymentMethods.fromBalance.value)}
        </Box>

        <Typography variant="h6" sx={{ pt: 2 }}>
          Ustawienia raportu
        </Typography>
        <SelectField
          fullWidth
          contrast
          floatingLabelText="Sortowanie"
          id="sortBy"
          value={this.state.sortBy}
          onChange={(event) => {
            this.setState({ sortBy: event.target.value });
            onChange({ target: { name: 'sortBy', value: event.target.value } });
          }}>
          {this.state.sortOptions.map((so) => {
            return (
              <MenuItem key={so.value} value={so.value}>
                {so.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <Checkbox
          label="Wyświetl sumę wpłat względem wybranego zakresu dat"
          defaultChecked={this.state.paymentsInSelectedRange}
          onCheck={(event, isChecked) => {
            this.setState({ paymentsInSelectedRange: isChecked });
            onChange({ target: { name: 'paymentsInSelectedRange', value: isChecked } });
          }}
          sx={{ mt: 2 }}
        />
      </Box>
    );
  }
}

PaymentsSummaryPerChildReport.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(PaymentsSummaryPerChildReport);
