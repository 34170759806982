import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import DatePicker from '../../DatePicker';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '../../TextField';
import { Box, Stack } from '@mui/material';

const ChildGuardianForm = ({ guardian, onChange, errors }) => {
  return (
    <Stack>
      <TextField
        hintText="Imię"
        floatingLabelText="Imię*"
        floatingLabelFixed={false}
        autocomplete="given-name"
        name="firstName"
        value={guardian.firstName}
        onChange={onChange}
        errorText={errors.firstName}
        autoFocus={true}
      />
      <TextField
        hintText="Nazwisko"
        floatingLabelText="Nazwisko*"
        floatingLabelFixed={false}
        autocomplete="family-name"
        name="lastName"
        value={guardian.lastName}
        onChange={onChange}
        errorText={errors.lastName}
        fullWidth={true}
      />
      <TextField
        hintText="Stopień pokrewieństwa"
        floatingLabelText="Stopień pokrewieństwa"
        floatingLabelFixed={false}
        name="relationship"
        value={guardian.relationship}
        onChange={onChange}
        errorText={errors.relationship}
        fullWidth={true}
      />
      <TextField
        hintText="Numer dowodu osobistego lub paszportu"
        floatingLabelText="Numer dokumentu tożsamości*"
        floatingLabelFixed={false}
        name="documentNumber"
        value={guardian.documentNumber}
        onChange={onChange}
        errorText={errors.documentNumber}
        fullWidth={true}
      />
      <Box>
        <DatePicker
          sx={{ width: 230 }}
          hintText="Data ważności upoważnienia (włącznie)"
          floatingLabelText="Data wygaśnięcia uprawnień"
          floatingLabelFixed={true}
          name="authorizationExpirationDate"
          value={guardian.authorizationExpirationDate}
          errorText={errors.authorizationExpirationDate}
          minDate={moment().subtract(10, 'days').toDate()}
          maxDate={moment().add(1, 'year').toDate()}
          onChange={(empty, date) => {
            onChange({ target: { name: 'authorizationExpirationDate', value: date } });
          }}
        />
        <IconButton
          onClick={() => onChange({ target: { name: 'authorizationExpirationDate', value: null } })}
          disabled={!guardian.authorizationExpirationDate}
          size="large">
          <ClearIcon sx={{ color: (theme) => theme.palette.grey[500] }} />
        </IconButton>
      </Box>
      <TextField
        hintText="Numer telefonu kontaktowego"
        floatingLabelText="Numer telefonu kontaktowego"
        type="number"
        floatingLabelFixed={false}
        autocomplete="tel"
        name="phoneNumber"
        value={guardian.phoneNumber}
        onChange={onChange}
        errorText={errors.phoneNumber}
      />
    </Stack>
  );
};

ChildGuardianForm.propTypes = {
  guardian: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ChildGuardianForm;
