import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

const DefaultFileIcon = (props) => {
  const theme = useTheme();
  const defaultIconColor = theme.palette.components.fileIcons.defaultFile;

  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H12C12.5523 4 13 4.44772 13 5V8C13 8.55228 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V19Z"
        fill={defaultIconColor}
      />
      <rect x="8" y="12" width="8" height="2" fill={defaultIconColor} />
      <rect x="8" y="16" width="8" height="2" fill={defaultIconColor} />
    </SvgIcon>
  );
};

export default DefaultFileIcon;
