import PropTypes from 'prop-types';
import React from 'react';
import StackRow from './StackRow';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

const PageHeader = ({ children }) => {
  const childrenArray = Array.isArray(children) ? children : [children];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack sx={{ flexWrap: 'wrap' }}>
      <StackRow sx={{ width: isMobile ? '100%' : null, textAlign: isMobile ? 'center' : null }}>
        {childrenArray[0]}
      </StackRow>
      <Stack
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'flex-end'}
        flexGrow={1}
        alignItems="center"
        sx={{ width: isMobile ? '100%' : null, pb: isMobile ? 3 : 0 }}>
        {childrenArray.filter((child, index) => index > 0)}
      </Stack>
    </Stack>
  );
};

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired
};

export default PageHeader;
