import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ChildrenList from '../components/List/ChildrenList';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import { routePaths } from '../../../routePaths';
import { routePathActions } from '../../../routePathActions';
import { caseInsensitiveComparator } from '../../../utils/polishSort';
//v4
import Header from '../components/Header/Header';
import { Box, Pagination, Paper, Button, useMediaQuery } from '@mui/material';
import EmptyState from '../../common/EmptyState';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import userRoles from '../../../constants/userRoles';

const ChildrenContainer = () => {
  const [filteredPupils, setFilteredPupils] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const unit = useSelector((state) => state.configuration.unit);
  const staffMembers = useSelector((state) => state.staffMembers);
  const auth = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.auth.userId);
  const pupils = useSelector((state) => state.children);
  const isAllowed = useSelector((state) => state.auth.isAllowed.bind(auth));

  const groupChildrenByLastName = (children) => {
    return _.chain(children)
      .sort((a, b) => caseInsensitiveComparator(a.lastName, b.lastName))
      .value();
  };

  const availableChilds = useMemo(() => {
    const getTeacherGroupsIds = () => {
      const gropus = staffMembers.filter((e) => e.id === userId)[0].groups;
      return gropus.map((e) => e.id);
    };

    return !unit.teacherAllGroupVisibility && auth.userRole === userRoles.staffMemberTeacher
      ? pupils
          .filter((e) => e.enrolledToGroup !== null)
          .filter((e) => getTeacherGroupsIds().includes(e.enrolledToGroup.id))
      : pupils;
  }, [auth.userRole, pupils, staffMembers, unit.teacherAllGroupVisibility, userId]);

  useEffect(() => {
    setFilteredPupils(groupChildrenByLastName(availableChilds));
  }, [availableChilds, pupils]);

  const redirectToCreatePage = () => {
    browserHistory.push(routePaths.childRegister);
  };

  const redirectToChildrenArchivePage = () => {
    browserHistory.push(routePaths.childrenArchive);
  };

  const handleFilterChange = (filteredList) => {
    setPage(0);
    setFilteredPupils(groupChildrenByLastName(filteredList));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);

    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const countOfPagination = Math.ceil(filteredPupils.length / rowsPerPage);

  return (
    <Box>
      <PageHeaderText title="Rejestr dzieci" titleIcon={<FormatListNumberedIcon />} />
      <ContentWrapper>
        <Box>
          <Header
            isAllowed={isAllowed(routePathActions.children.registerChild)}
            redirectToCreatePage={redirectToCreatePage}
            itemsToFilter={availableChilds}
            onFilter={handleFilterChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
          />
          <Paper sx={{ p: 2 }}>
            {filteredPupils.length > 0 && (
              <Box>
                <ChildrenList pupils={filteredPupils.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Pagination
                    siblingCount={isMobile ? 0 : 1}
                    sx={{
                      p: 3
                    }}
                    count={countOfPagination}
                    page={page + 1}
                    onChange={handleChangePage}
                  />
                </Box>
              </Box>
            )}
            {filteredPupils.length === 0 && (
              <EmptyState
                contrast
                message={availableChilds.length === 0 ? 'Nie zapisano żadnych dzieci' : 'Brak wyników wyszukiwania'}
              />
            )}
          </Paper>
          {!(auth.userRole === userRoles.staffMemberTeacher) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="outlined" aria-label="Pokaż archiwalne" onClick={redirectToChildrenArchivePage}>
                Pokaż archiwalne
              </Button>
            </Box>
          )}
        </Box>
      </ContentWrapper>
    </Box>
  );
};

export default ChildrenContainer;
