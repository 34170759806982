'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import Dialog from '../../common/Dialog';
import SelectField from '../../common/SelectField';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button, Typography } from '@mui/material';

class DuesExportDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      month: moment().startOf('month'),
      type: null,
      dueType: null
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({ month: date });
  }

  handleTypeChange(event) {
    this.setState({ type: event.target.value });
  }

  handlePaymentTypeChange(event) {
    this.setState({ dueType: event.target.value });
  }

  render() {
    const actions = [
      <Button
        variant="contained"
        aria-label="Wyślij"
        key={1}
        onClick={() =>
          this.props.onSubmit({
            month: this.state.month.format('YYYY-MM'),
            type: this.state.type,
            dueType: this.state.dueType,
            groupIds: this.props.groups
          })
        }>
        Wyślij
      </Button>,
      <Button variant="contained" aria-label="Anuluj" key={2} onClick={this.props.onClose}>
        Anuluj
      </Button>
    ];

    return (
      <Dialog
        title="Eksport danych"
        modal={false}
        open={this.props.open}
        actions={actions}
        onRequestClose={this.props.onClose}
        overlayClassName="dialog-overlay">
        <Typography>Wybierz okres rozliczeń</Typography>
        <YearAndMonthOptionsSlider
          contrast
          onSelected={(year, month) => {
            const date = moment(`${year}-${month}`, 'YYYY-MM');
            this.handleDateChange(date.startOf('month'));
          }}
          defaultMonth={this.state.month.format('MM')}
          defaultYear={this.state.month.format('YYYY')}
        />
        <SelectField
          floatingLabelText="Wybierz rodzaj rozliczeń"
          id="fkSettlementsType"
          value={this.state.type}
          sx={{ minWidth: 200 }}
          onChange={this.handleTypeChange}>
          <MenuItem key={1} value="payments">
            Wpłaty
          </MenuItem>
          <MenuItem key={2} value="monthlyDues">
            Należności
          </MenuItem>
        </SelectField>

        {this.state.type === 'payments' ? (
          <Box>
            <SelectField
              floatingLabelText="Wybierz typ wpłaty"
              id="fkPaidType"
              value={this.state.dueType}
              sx={{ minWidth: 200, margimtnTop: 1 }}
              onChange={this.handlePaymentTypeChange}>
              <MenuItem key={1} value="stay">
                Pobyt
              </MenuItem>
              <MenuItem key={2} value="catering">
                Wyżywienie
              </MenuItem>
              <MenuItem key={3} value="other">
                Dodatkowe
              </MenuItem>
            </SelectField>
          </Box>
        ) : null}
      </Dialog>
    );
  }
}

DuesExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired
};

export default DuesExportDialog;
