import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  ListItem,
  List,
  Alert,
  Button,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReportIcon from '@mui/icons-material/Report';
import IconButtonPipe from '../../common/IconButtonPipe';
import Dialog from '../../common/Dialog';
import RichTooltip from '../../richTooltip/richTooltip';

const ImportErrorsComponent = ({ headers, rows, errors, errorType }) => {
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [displayedErrors, setDisplayedErrors] = useState([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);

  const handleOpenDialog = React.useCallback((err) => {
    setDisplayedErrors(err);
    setIsDialogOpen((prev) => !prev);
  }, []);

  const handleCloseDialog = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleCloseTooltip = React.useCallback(() => {
    setIsTooltipOpen((prev) => !prev);
  }, []);

  const actions = React.useMemo(
    () => [
      <Button variant="contained" aria-label="Zamknij" key={1} onClick={() => handleCloseDialog()}>
        Zamknij
      </Button>
    ],
    []
  );

  return (
    <>
      {errorType === 'rows' ? (
        <TableContainer sx={{ maxHeight: '420px' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: theme.palette.color.primary }}>
                <TableCell />
                <TableCell>L.p.</TableCell>
                {headers.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {errors.map((error, index) => (
                <TableRow>
                  {index === 0 ? (
                    <RichTooltip
                      content={
                        <Alert severity="info" onClose={handleCloseTooltip}>
                          Kliknij w wykrzyknik aby wyświetlić błędy
                        </Alert>
                      }
                      open={isTooltipOpen}
                      placement="bottom"
                      ArrowStyles={{ color: theme.palette.color.primary }}>
                      <TableCell>
                        <IconButtonPipe
                          onClick={() => handleOpenDialog(error.errors)}
                          tooltip="Wyświetl błędy"
                          sx={{ ml: 1 }}>
                          <ReportIcon sx={{ color: theme.palette.color.color10 }} />
                        </IconButtonPipe>
                      </TableCell>
                    </RichTooltip>
                  ) : (
                    <TableCell>
                      <IconButtonPipe
                        onClick={() => handleOpenDialog(error.errors)}
                        tooltip="Wyświetl błędy"
                        sx={{ ml: 1 }}>
                        <ReportIcon sx={{ color: theme.palette.color.color10 }} />
                      </IconButtonPipe>
                    </TableCell>
                  )}

                  <TableCell>{error.row + 1}</TableCell>
                  {rows[error.row + 1]
                    .split(';')
                    .join(',')
                    .split(',')
                    .map((cell) => (
                      <TableCell>{cell}</TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            W pliku pojawiły się następujące błędy:
          </Typography>
          {errors.map((error) => (
            <Alert severity="error" sx={{ m: 1 }}>
              {error.errors.message}
            </Alert>
          ))}
        </>
      )}
      <Dialog
        title="Błędy:"
        modal={false}
        open={isDialogOpen}
        actions={actions}
        onRequestClose={() => handleCloseDialog}>
        <List>
          {displayedErrors.map((error) => (
            <ListItem>{error.message}</ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};

ImportErrorsComponent.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  errors: PropTypes.array,
  errorType: PropTypes.string
};

export default ImportErrorsComponent;
