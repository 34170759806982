import PropTypes from 'prop-types';
import React from 'react';
import RichTextEditor from '../../forms/richTextEditor/RichTextEditor';
import DialogForm from '../../forms/DialogForm';
import { BoardPostModelValidator } from '../../../models/messageBoard/MessageBoardModelsValidator';
import DatePicker from '../../common/DatePicker';
import TimePicker from '../../common/TimePicker';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import { TimeModel } from '../../../models/TimeModel';
import localStorageProvider from '../../../utils/localStorageProvider';
import moment from 'moment';
import { FormControl, FormHelperText, Box, Chip, Autocomplete, TextField, Button } from '@mui/material';
import withMediaQuery from '../../../hoc/withMediaQuery';
import CustomTextField from '../../common/TextField';
import { ProgramPoints as allProgramPoints } from '../../../utils/ProgramPointsList';
import { uniqueId } from 'lodash';

class SaveWorkDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      post: { ...this.props.post, title: '', learningMaterials: '', programPoints: [] },
      errors: {},
      showProgramPoints: false,
      refresh: uniqueId()
    };
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSelectCoursebook = this.handleSelectCoursebook.bind(this);
    this.handleLearningMaterialChange = this.handleLearningMaterialChange.bind(this);
    this.handleProgramPointsChange = this.handleProgramPointsChange.bind(this);
    this.token = localStorageProvider.getItem('authToken');
    this.options = allProgramPoints
      .map((pp) => pp.values)
      .flat()
      .map((programPoint) => {
        const group = programPoint.split('.')[0];
        const programPointValue = programPoint.split(' - ')[0];
        return {
          group,
          programPointValue,
          programPoint
        };
      });
  }

  handleTitleChange(e) {
    this.setState({ post: Object.assign(this.state.post, { title: e.target.value }) });
  }

  handleContentChange(htmlContent) {
    this.setState({ post: Object.assign(this.state.post, { content: htmlContent }) });
  }

  handleEventDateChange(e, date) {
    this.setState({
      post: Object.assign(this.state.post, {
        eventDate: date ? moment(date).format('YYYY-MM-DD') : null
      })
    });
    if (!date) {
      this.handleEventStartTimeChange(undefined);
      this.handleEventEndTimeChange(undefined);
    }
  }

  handleEventStartTimeChange(time) {
    this.setState({
      post: Object.assign(this.state.post, { eventStartTime: time ? new TimeModel().parse(time) : new TimeModel() })
    });
    if (!time) {
      this.handleEventEndTimeChange(undefined);
    }
  }

  handleEventEndTimeChange(time) {
    this.setState({
      post: Object.assign(this.state.post, { eventEndTime: time ? new TimeModel().parse(time) : new TimeModel() })
    });
  }

  handleSelectCoursebook(title, learningMaterials, programPoints, note) {
    this.setState((prevState) => {
      return {
        ...prevState,
        refresh: uniqueId(),
        post: Object.assign(prevState.post, {
          title,
          learningMaterials,
          programPoints,
          content: note
        })
      };
    });
  }

  handleProgramPointsChange(event, value) {
    this.setState((prevState) => ({
      ...prevState,
      post: Object.assign(prevState.post, {
        programPoints: value
      })
    }));
  }

  handleLearningMaterialChange(e) {
    this.setState({ post: Object.assign(this.state.post, { learningMaterials: e.target.value }) });
  }

  changeVisibilityOfProgramPoints() {
    this.setState((prevState) => ({
      ...prevState,
      showProgramPoints: !prevState.showProgramPoints
    }));
  }

  render() {
    const { isMobile } = this.props;
    return (
      <DialogForm
        onSave={() =>
          this.props.onSave({
            ...this.state.post,
            programPoints: this.state.post.programPoints.map((v) => v.programPointValue).join(', ')
          })
        }
        onCancel={() => this.props.onCancel(this.state.post)}
        onValidate={() => new BoardPostModelValidator().validate(this.state.post)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="messageBoardUi"
        isDialogOpen={this.props.isEditing}
        isProcessing={this.props.isProcessing}
        header="Utwórz nowy wpis"
        coursebook
        defaultPublishingHouseId={this.props.defaultPublishingHouseId}
        coursebookSetMethods={this.handleSelectCoursebook}
        saveDisabled={this.props.isGroupArchived}
        requiredFieldText>
        <CustomTextField
          floatingLabelText="Temat zajęć*"
          autoFocus={true}
          name="title"
          value={this.state.post.title}
          onChange={this.handleTitleChange}
          fullWidth={true}
          errorText={this.state.errors.title}
        />
        <FormControl
          error={!!this.state.errors && !!this.state.errors.content}
          sx={{
            width: '100%',
            borderBottom:
              !!this.state.errors && !!this.state.errors.content
                ? (theme) => `1px solid ${theme.palette.grey[300]}`
                : null
          }}>
          <RichTextEditor
            sx={{ border: (theme) => `1px solid ${theme.palette.grey[300]}` }}
            onChange={this.handleContentChange}
            key={this.state.refresh}
            initialText={this.state.post.content}
          />
          <FormHelperText>{this.state.errors.content}</FormHelperText>
        </FormControl>
        <CustomTextField
          floatingLabelText="Materiały dydaktyczne*"
          sx={{ width: '100%' }}
          value={this.state.post.learningMaterials}
          onChange={this.handleLearningMaterialChange}
        />
        <Autocomplete
          id="program-points-autocomplete"
          sx={{
            '& .MuiInput-underline:before': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.contrast} !important`
            },
            '& .MuiInput-underline:after': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.contrast} !important`
            },
            '& .MuiInput-underline:hover': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.contrast} !important`
            },
            svg: { color: (theme) => theme.palette.color.contrast },
            label: {
              color: (theme) => theme.palette.color.contrast,
              '&.Mui-focused': {
                color: (theme) => theme.palette.color.contrast
              }
            }
          }}
          multiple
          disableCloseOnSelect
          value={this.state.post.programPoints}
          options={this.options.filter(
            (option) =>
              !this.state.post.programPoints.map((v) => v.programPointValue).some((v) => v === option.programPointValue)
          )}
          getOptionLabel={(option) => option.programPoint}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option.programPointValue} {...getTagProps({ index })} />
            ))
          }
          groupBy={(option) => `${option.group} - ${allProgramPoints.filter((pp) => pp.key === option.group)[0].title}`}
          renderInput={(params) => <TextField {...params} label="Podstawa programowa*" variant="standard" />}
          onChange={this.handleProgramPointsChange}
        />
        <Box>
          <Button
            variant="clearText"
            sx={{ py: 1, px: 1, mx: 0 }}
            onClick={() => this.changeVisibilityOfProgramPoints()}>
            {`${this.state.showProgramPoints ? 'Ukryj' : 'Pokaż'} opis wybranych punktów podstawy programowej`}
          </Button>
        </Box>
        {this.state.showProgramPoints &&
          this.state.post.programPoints.map((pp) => <p key={uniqueId()}>{pp.programPoint}</p>)}
        {this.props.canSetDate && (
          <Box
            sx={{
              px: 0,
              pt: 1,
              pb: 0,
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'start',
              justifyContent: 'space-between'
            }}>
            <ClearButtonWrapper
              onClear={() => this.handleEventDateChange(undefined)}
              disabled={!this.props.canClearDate}
              contrast>
              <DatePicker
                floatingLabelText="Data wydarzenia"
                textFieldStyle={{ width: 128 }}
                onChange={(e, date) => this.handleEventDateChange(e, date)}
                value={this.state.post.eventDate ? new Date(this.state.post.eventDate) : undefined}
                name="eventDate"
                errorText={this.state.errors.eventDate}
                sx={{ m: 0 }}
                id="data-wydarzenia-picker"
              />
            </ClearButtonWrapper>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
              <ClearButtonWrapper onClear={() => this.handleEventStartTimeChange(undefined)} contrast>
                <TimePicker
                  floatingLabelText="Rozpoczęcie"
                  disabled={!this.state.post.eventDate}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => this.handleEventStartTimeChange(time)}
                  value={
                    !this.state.post.eventStartTime.isEmpty() ? this.state.post.eventStartTime.toDate() : undefined
                  }
                  name="eventStartTime"
                  errorText={this.state.errors.eventStartTime}
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper onClear={() => this.handleEventEndTimeChange(undefined)} contrast>
                <TimePicker
                  floatingLabelText="Zakończenie"
                  disabled={this.state.post.eventStartTime.isEmpty()}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => this.handleEventEndTimeChange(time)}
                  value={!this.state.post.eventEndTime.isEmpty() ? this.state.post.eventEndTime.toDate() : undefined}
                  name="eventEndTime"
                  errorText={this.state.errors.eventEndTime}
                />
              </ClearButtonWrapper>
            </Box>
          </Box>
        )}
      </DialogForm>
    );
  }
}

SaveWorkDialog.propTypes = {
  post: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  canClearDate: PropTypes.bool,
  canSetDate: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isGroupArchived: PropTypes.bool,
  defaultPublishingHouseId: PropTypes.string
};

SaveWorkDialog.defaultProps = {
  canClearDate: true,
  canSetDate: true
};

export default withMediaQuery()(SaveWorkDialog);
