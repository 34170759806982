import React from 'react';
import { Helmet } from 'react-helmet';

export default (WrappedComponent, { title, description, keyWords, css }) => {
  return (props) => (
    <>
      <Helmet>
        {css && <link rel="stylesheet" href={css} />}
        {title && <title>{title} - 4Parents</title>}
        {description && <meta name="description">{description}</meta>}
        {keyWords && keyWords.length && <meta name="keywords">{keyWords.join(', ')}</meta>}
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};
