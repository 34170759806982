import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import { useSelector } from 'react-redux';

const MealAvatar = ({ meal }) => {
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <Avatar
      sx={{
        background: isContrastColor === 'contrast' ? theme.palette.color.wcag : meal.color,
        borderRadius: '50%',
        width: 42,
        height: 42
      }}
      variant="rounded">
      <DinnerDiningOutlinedIcon size="large" sx={{ color: theme.palette.color.primary }} />
    </Avatar>
  );
};

MealAvatar.propTypes = {
  meal: PropTypes.object
};

export default MealAvatar;
