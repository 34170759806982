import PropTypes from 'prop-types';
import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  useMediaQuery,
  ListItemSecondaryAction
} from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';
import { useTheme } from '@emotion/react';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import DotsMenu from '../../common/menu/DotsMenu';
import callMenuItem from '../../forms/CallMenuItem';

const ChildLegalGuardiansList = ({ guardians, isPinEnabled, onEdit, onRemove }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <List>
      {guardians.map((guardian) => {
        return (
          <ListItem
            button
            key={guardian.id}
            onClick={() => onEdit(guardian)}
            component="li"
            aria-label={`Edytuj opiekuna prawnego ${guardian.firstName} ${guardian.lastName}`}>
            <ListItemAvatar>
              <PersonAvatar
                initials
                alt={guardian.firstName}
                url={guardian.photoUrl}
                firstName={guardian.firstName}
                lastName={guardian.lastName}
              />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={
                <Box>
                  <Typography
                    sx={{
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}>
                    {guardian.firstName} {guardian.lastName}
                    {guardian.relationship && (
                      <Typography component="span" sx={{ mx: 1 }}>
                        ({guardian.relationship})
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}>
                    {guardian.email}
                  </Typography>
                </Box>
              }
              secondary={
                <Box sx={{ pt: 1, display: 'flex', flexWrap: 'wrap', flexDirection: isMobile ? 'column' : 'row' }}>
                  <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                    Numer dokumentu
                    <Typography
                      component="span"
                      sx={{
                        mx: 1,
                        fontWeight: (theme) => theme.typography.fontWeightBold
                      }}>
                      {guardian.documentNumber}
                    </Typography>
                  </Typography>
                  <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                    Numer telefonu
                    <Typography
                      component="span"
                      sx={{
                        mx: 1,
                        fontWeight: (theme) => theme.typography.fontWeightBold
                      }}>
                      {guardian.phoneNumber || 'brak'}
                    </Typography>
                  </Typography>
                  {isPinEnabled && guardian.pin && (
                    <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                      Pin
                      <Typography
                        component="span"
                        sx={{
                          mx: 1,
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.pin}
                      </Typography>
                    </Typography>
                  )}
                </Box>
              }
            />
            <ListItemSecondaryAction>
              <DotsMenu>
                {(onClose) => {
                  if (guardian.phoneNumber) {
                    return [
                      callMenuItem(guardian.phoneNumber, 1),
                      <ActionConfirmationMenuItem
                        key="2"
                        onItemClick={onClose}
                        actionLabel="Odepnij"
                        confirmationText="Czy na pewno chcesz odpiąć opiekuna prawnego?"
                        confirmationTitle="Odepnij opiekuna prawnego"
                        confirmLabel="Odepnij"
                        onConfirm={() => onRemove(guardian.id)}
                        onCancel={() => {}}
                      />
                    ];
                  }
                  return [
                    <ActionConfirmationMenuItem
                      key="2"
                      onItemClick={onClose}
                      actionLabel="Odepnij"
                      confirmationText="Czy na pewno chcesz odpiąć opiekuna prawnego?"
                      confirmationTitle="Odepnij opiekuna prawnego"
                      confirmLabel="Odepnij"
                      onConfirm={() => onRemove(guardian.id)}
                      onCancel={() => {}}
                    />
                  ];
                }}
              </DotsMenu>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

ChildLegalGuardiansList.propTypes = {
  guardians: PropTypes.array.isRequired,
  isPinEnabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ChildLegalGuardiansList;
