import React from 'react';
import PropTypes from 'prop-types';
import {
  DefaultFileIcon,
  PdfFileIcon,
  SpreadsheetFileIcon,
  TextFileIcon,
  AudioFileIcon,
  CompressedFileIcon,
  ImageFileIcon,
  PresentationFileIcon,
  SystemFileIcon,
  VideoFileIcon,
  ProgrammingFileIcon
} from '../../../assets/icons/files';
import mime from 'mime';
import { FILE_CATEGORY } from '../../../constants/fileCategory';
import DiscFolderIcon from '../../../assets/icons/folder/DiscFolderIcon';

const FileSpecificIcon = ({ type, className, item, ...rest }) => {
  const mimeTypes = (array) => array.map((item) => mime.getType(item));

  //PDF FILES
  if (type === 'folder') {
    return <DiscFolderIcon item={item} {...rest} />;
  }
  if (!type) return <DefaultFileIcon {...rest} />;

  if (mimeTypes(FILE_CATEGORY.pdf).includes(type)) {
    return <PdfFileIcon className={`${className}`} {...rest} />;
  }
  //Spreadsheet FILES
  if (mimeTypes(FILE_CATEGORY.spreadsheet).includes(type)) {
    return <SpreadsheetFileIcon className={`${className}`} {...rest} />;
  }
  //TEXT FILES
  if (mimeTypes(FILE_CATEGORY.text).includes(type)) {
    return <TextFileIcon className={`${className}`} {...rest} />;
  }
  //AUDIO FILES
  if (mimeTypes(FILE_CATEGORY.audio).includes(type)) {
    return <AudioFileIcon className={`${className}`} {...rest} />;
  }
  //COMPRESSED FILES
  if (mimeTypes(FILE_CATEGORY.compressed).includes(type)) {
    return <CompressedFileIcon className={` ${className}`} {...rest} />;
  }
  //IMAGE FILES
  if (mimeTypes(FILE_CATEGORY.image).includes(type)) {
    return <ImageFileIcon className={` ${className}`} {...rest} />;
  }
  //PRESENTATION FILES
  if (mimeTypes(FILE_CATEGORY.presentation).includes(type)) {
    return <PresentationFileIcon className={` ${className}`} {...rest} />;
  }
  //SYSTEM FILES
  if (mimeTypes(FILE_CATEGORY.system).includes(type)) {
    return <SystemFileIcon className={` ${className}`} {...rest} />;
  }
  //VIDEO FILES
  if (mimeTypes(FILE_CATEGORY.video).includes(type)) {
    return <VideoFileIcon className={` ${className}`} {...rest} />;
  }
  //PROGRAMMING FILES
  if (mimeTypes(FILE_CATEGORY.programming).includes(type)) {
    return <ProgrammingFileIcon className={` ${className}`} {...rest} />;
  }

  return <DefaultFileIcon {...rest} />;
};

FileSpecificIcon.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string
};

export default FileSpecificIcon;
