import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../common/TextField';

const DuesExportSettingsForm = ({ duesExportSettings, onChange, errors }) => {
  return (
    <Box>
      <Box>
        <TextField
          fullWidth
          hintText="Adres email"
          floatingLabelText="Email"
          floatingLabelFixed={false}
          name="email"
          value={duesExportSettings.email}
          autocomplete="email"
          onChange={onChange}
          errorText={errors.email}
          type="email"
        />
        <TextField
          fullWidth
          hintText="Dodatkowy adres email"
          floatingLabelText="Dodatkowy adres email"
          floatingLabelFixed={false}
          name="additionalEmail"
          value={duesExportSettings.additionalEmail}
          autocomplete="email"
          onChange={onChange}
          errorText={errors.additionalEmail}
          type="email"
        />
      </Box>
    </Box>
  );
};

DuesExportSettingsForm.propTypes = {
  duesExportSettings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DuesExportSettingsForm;
