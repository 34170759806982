import PropTypes from 'prop-types';
import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import moment from 'moment';
import SelectField from '../../common/SelectField';
import { Box, Checkbox, Typography, List, ListItem, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DatePicker from '../../common/DatePicker';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { ReportTypes } from '../../../constants/reportTypes';
import GroupPickerTemplate from './common/GroupPickerTemplate';

const chargingTypes = [ChargingReportTypes.stay, ChargingReportTypes.catering];

const ReliefsSummaryReport = ({
  groupId,
  groups,
  schemeReportType,
  reliefIds,
  reliefs,
  reliefsDate,
  onChange,
  errors
}) => {
  const onSelect = (reliefId) => {
    let nextIds;
    if (reliefIds.includes(reliefId)) nextIds = [...reliefIds.filter((id) => id !== reliefId)];
    else nextIds = [...reliefIds, reliefId];
    onChange({ target: { name: 'reliefIds', value: nextIds } });
  };

  return (
    <Box>
      <GroupPickerTemplate
        errorText={errors.groupId}
        sx={{ pb: 2 }}
        groupId={groupId}
        groups={groups}
        onChange={onChange}
        legend={ReportTypes.reliefsSummary.name}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.reliefsSummary.name}`}>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden', pb: 2 }}
          floatingLabelText="Rodzaj opłat"
          id="schemeReportType"
          value={schemeReportType}
          onChange={(event) => {
            onChange({ target: { name: 'schemeReportType', value: event.target.value } });
          }}>
          {chargingTypes.map((type) => {
            return (
              <MenuItem key={type.value} value={type.value}>
                {type.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <Box sx={{ display: 'flex' }}>
          <DatePicker
            floatingLabelText="Ulgi ważne w dniu"
            name="date"
            value={reliefsDate ? new Date(reliefsDate) : new Date()}
            onChange={(e, value) =>
              onChange({ target: { name: 'reliefsDate', value: moment(value).format('YYYY-MM-DD') } })
            }
          />
        </Box>
        <Box>
          <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.reliefsSummary.name}`}>
            <Typography variant="h6" sx={{ pt: 2 }}>
              Wybierz ulgi
            </Typography>
            <List>
              {reliefs
                .filter((r) => r.id !== 'all')
                .map((r) => (
                  <ListItem
                    sx={{ p: 0 }}
                    component="li"
                    button
                    key={r.id}
                    onClick={() => onSelect(r.id)}
                    aria-label={`Wybierz ${r.name}`}>
                    <ListItemIcon>
                      <Checkbox
                        checked={reliefIds.includes(r.id)}
                        color="primary"
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': `${r.id}-checkbox` }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`${r.id}-checkbox`} primary={r.name} />
                  </ListItem>
                ))}
            </List>
          </FieldsetWrapper>
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

ReliefsSummaryReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  schemeReportType: PropTypes.string.isRequired,
  reliefIds: PropTypes.array.isRequired,
  reliefs: PropTypes.array.isRequired,
  reliefsDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ReliefsSummaryReport;
