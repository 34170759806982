import moment from 'moment';
import ApiBase, { ROOT_URL } from './ApiBase';

class PsychologicalApi extends ApiBase {
  static loadChildren() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/individualClasses/todayAttendance`).type('application/json')
    );
  }

  static loadChildrenDetails(assistanceId) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/psychologicalPedagogicalAssistance/loadPsychologicalChildDetails/${assistanceId}`)
        .type('application/json')
    );
  }

  static loadPsychologicalHelpList() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/psychologicalPedagogicalAssistance/`).type('application/json')
    );
  }

  static loadPsychologiclDetails(helpId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/psychologicalPedagogicalAssistance/getChild/${helpId}`).type('application/json')
    );
  }

  static loadIndividualWorkPlan(date, helpId) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/digitalDiary/psychologicalPedagogicalIndividualClassesTimetable/${date}/${helpId}`)
        .type('application/json')
    );
  }

  static loadGroupWorkPlan(date, groupId) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/digitalDiary/psychologicalPedagogicalGroupClassesTimetable/${date}/${groupId}`)
        .type('application/json')
    );
  }

  static loadGroupClasses(date, groupId) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/digitalDiary/psychologicalPedagogicalGroupClassesActivities/${date}/${groupId}`)
        .type('application/json')
    );
  }

  static getTodayGroupAttendance(groupId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/groupClasses/${groupId}/todayAttendance`).type('application/json')
    );
  }

  static addPsychologicalHelp(child) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/`)
        .send({ childId: child.child.id, from: child.from, to: child.to, reason: child.cause })
        .type('application/json')
    );
  }

  static archivePsychologicalHelp(helpId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/archive`).type('application/json')
    );
  }

  static editChild(child) {
    // return super.toPromise((agent) =>
    //   agent
    //     .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/`)
    //     .send({ childId: child.child.id, from: child.from, to: child.to, reason: child.cause })
    //     .type('application/json')
    // );
  }

  static addNotification(data, id) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${id}/addLetterOfNotification/`)
        .send(data)
        .type('application/json')
    );
  }

  static editNotification(data, id, helpId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/editLetterOfNotification/${id}`)
        .send({
          dateOfMeeting: data.dateOfMeeting,
          hourOfMeeting: data.hourOfMeeting,
          name: data.name,
          placeOfMeeting: data.placeOfMeeting,
          purposeOfMeeting: data.purposeOfMeeting,
          type: data.type
        })
        .type('application/json')
    );
  }

  static archiveNotification(helpId, id) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/archiveLetterOfNotification/${id}`)
        .type('application/json')
    );
  }

  static addIpet(data, id) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${id}/addIPET `).send(data).type('application/json')
    );
  }

  static editIpet(data, ipetId, helpId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/editIPET/${ipetId}`)
        .send(data)
        .type('application/json')
    );
  }

  static archiveIpet(ipetId, helpId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/archiveIPET/${ipetId}`)
        .type('application/json')
    );
  }

  static addWopfu(data, helpId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/addWOPFU `)
        .send(data)
        .type('application/json')
    );
  }

  static editWopfu(data, wopfuId, helpId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/editWOPFU/${wopfuId}`)
        .send(data)
        .type('application/json')
    );
  }

  static archiveWopfu(wopfuId, helpId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${helpId}/archiveWOPFU/${wopfuId}`)
        .type('application/json')
    );
  }

  static loadSchemes() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/psychologicalPedagogicalAssistance/templates`).type('application/json')
    );
  }

  static addScheme(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/psychologicalPedagogicalAssistance/templates `).send(data).type('application/json')
    );
  }

  static createWorkPlanElement(data, assistanceId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${assistanceId}/createNewProgramElement `)
        .send(data)
        .type('application/json')
    );
  }

  static createWorkPlanGroupElement(data, groupId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalGroups/${groupId}/createNewProgramElement `)
        .send(data)
        .type('application/json')
    );
  }

  static editWorkPlanElement(data, assistanceId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalAssistance/${assistanceId}/editProgramElement `)
        .send(data)
        .type('application/json')
    );
  }

  static editWorkPlanGroupElement(data, groupId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/psychologicalPedagogicalGroups/${groupId}/editProgramElement `)
        .send(data)
        .type('application/json')
    );
  }

  static deleteWorkPlanElement(data, assistanceId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/psychologicalPedagogicalAssistance/${assistanceId}/deleteProgramElement/${data.id} `)
        .type('application/json')
    );
  }

  static deleteWorkPlanGroupElement(data, groupId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/psychologicalPedagogicalGroups/${groupId}/deleteProgramElement/${data.id} `)
        .type('application/json')
    );
  }

  static reportGroupAttendance(data, assistanceId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/groupClasses/${assistanceId}/child/${data.id}/setDailyAttendanceEntry `)
        .send({ ...data, droppedOffAt: moment(), pickedUpAt: moment() })
        .type('application/json')
    );
  }

  static reportGroupAbsence(data, assistanceId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/groupClasses/${assistanceId}/child/${data.id}/reportAbsence `)
        .send({
          ...data,
          absenceFrom: moment().format('YYYY-MM-DD'),
          absenceTo: moment().format('YYYY-MM-DD'),
          absenceNotes: ''
        })
        .type('application/json')
    );
  }
}

export default PsychologicalApi;
