import api from '../api/ReportsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function generateReportStart() {
  return { type: types.GENERATE_REPORT };
}

export function generateReportCancel() {
  return { type: types.GENERATE_REPORT_CANCEL };
}

// from http://stackoverflow.com/questions/283956/
function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export function generateReportAsync(reportType, format, parametersObject) {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_REPORT_SUBMIT });
    return api
      .downloadReport(reportType, format, parametersObject)
      .then((xhr) => {
        dispatch({ type: types.GENERATE_REPORT_SUCCESS });
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        const fileName = decodeURIComponent(contentDisposition).match(/filename=(.+)/)[1];
        dispatch(notificationActions.showSuccess(`Wygenerowano raport: '${fileName}'`));
        storeBlobAs(xhr.response, fileName);
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.GENERATE_REPORT_FAILURE, errors: error.response.body });
        dispatch({ type: types.GENERATE_REPORT_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się wygenerować raportu. ${error.message}`));
        return logger.error(error);
      });
  };
}

export function generateDuesListReportAsync(duesIds) {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_REPORT_SUBMIT });
    return api
      .downloadDuesList(duesIds)
      .then((xhr) => {
        dispatch({ type: types.GENERATE_REPORT_SUCCESS });
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        const fileName = decodeURIComponent(contentDisposition).match(/filename=(.+)/)[1];
        dispatch(notificationActions.showSuccess(`Wygenerowano raport: '${fileName}'`));
        storeBlobAs(xhr.response, fileName);
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.GENERATE_REPORT_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się wygenerować raportu'));
        return logger.error(error);
      });
  };
}

export function generateBalanceFundsPayoutConfirmationAsync(childId, operationDate) {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_REPORT_SUBMIT });
    return api
      .downloadBalanceFundsPayoutConfirmation(childId, operationDate)
      .then((xhr) => {
        dispatch({ type: types.GENERATE_REPORT_SUCCESS });
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        const fileName = decodeURIComponent(contentDisposition).match(/filename=(.+)/)[1];
        dispatch(notificationActions.showSuccess(`Wygenerowano potwierdzenie: '${fileName}'`));
        storeBlobAs(xhr.response, fileName);
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.GENERATE_REPORT_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się wygenerować potwierdzenia'));
        return logger.error(error);
      });
  };
}

export function generatePaidDueReportAsync(dueId, saveAs) {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_REPORT_SUBMIT });
    return api
      .downloadPaidDuesReport(dueId)
      .then((xhr) => {
        dispatch({ type: types.GENERATE_REPORT_SUCCESS });
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        const fileName = decodeURIComponent(contentDisposition).match(/filename=(.+)/)[1];
        dispatch(notificationActions.showSuccess(`Wygenerowano raport: '${fileName}'`));
        storeBlobAs(xhr.response, saveAs);
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.GENERATE_REPORT_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się wygenerować raportu'));
        return logger.error(error);
      });
  };
}

export function generateGuardianRequestReportAsync(childId, guardianId) {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_REPORT_SUBMIT });
    return api
      .downloadGuardianRequest(childId, guardianId)
      .then((xhr) => {
        dispatch({ type: types.GENERATE_REPORT_SUCCESS });
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        const fileName = decodeURIComponent(contentDisposition).match(/filename=(.+)/)[1];
        dispatch(notificationActions.showSuccess(`Wygenerowano wydruk: '${fileName}'`));
        storeBlobAs(xhr.response, fileName);
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.GENERATE_REPORT_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się wygenerować wydruku'));
        return logger.error(error);
      });
  };
}

export function loadReportsAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_REPORTS });
    return api
      .getReports()
      .then((reports) => dispatch({ type: types.LOAD_REPORTS_SUCCESS, reports }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy raportów'));
        return logger.error(error);
      });
  };
}
