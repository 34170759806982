import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function digitalDiaryChoosenGroupReducer(state = initialState.digitalDiary.choosenGroup, action) {
  switch (action.type) {
    case types.CHANGE_DIGITAL_DIARY_CHOOSEN_GROUP: {
      return { ...action.group };
    }
    default:
      return state;
  }
}
