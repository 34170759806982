import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { routePaths } from '../../../routePaths';
import { Box, Grid, Stack, Typography } from '@mui/material';
import * as digitalDiaryActions from '../../../actions/digitalDiaryActions';
import Masonry from '@mui/lab/Masonry';
import userRoles from '../../../constants/userRoles';

const GroupsAttendanceSummary = (props) => {
  const { attendanceSummary, children, selectedTheme } = props;
  const today = moment().format('DD.MM');

  const getNamedays = (groupId) =>
    children.filter(
      (c) =>
        c.enrolledToGroup &&
        c.enrolledToGroup.id === groupId &&
        c.nameDayDate &&
        (moment(c.nameDayDate).format('DD.MM') === today ||
          (moment(c.nameDayDate).format('DD.MM') === '29.02' && !moment().isLeapYear() && today === '28.02'))
    );

  const getBirthdays = (groupId) =>
    children.filter(
      (c) =>
        c.enrolledToGroup &&
        c.enrolledToGroup.id === groupId &&
        c.birthDate &&
        (moment(c.birthDate).format('DD.MM') === today ||
          (moment(c.birthDate).format('DD.MM') === '29.02' && !moment().isLeapYear() && today === '28.02'))
    );

  const groupSelect = (groupSummary) => {
    props.digitalDiaryEvents.changeChoosenGroup(props.groups.find((group) => group.id === groupSummary.group.id));
    browserHistory.push({ pathname: routePaths.attendance, params: { groupId: groupSummary.group.id } });
  };

  const filterGroups = useMemo(() => {
    const gropus = props.staffMembers.filter((e) => e.id === props.userId)[0].groups;
    const isFiltered = !props.unit.teacherAllGroupVisibility && props.userRole === userRoles.staffMemberTeacher;
    return isFiltered
      ? attendanceSummary.filter((e) => gropus.map((el) => el.id).includes(e.group.id))
      : attendanceSummary;
  }, [attendanceSummary, props.staffMembers, props.unit.teacherAllGroupVisibility, props.userId, props.userRole]);

  return (
    <Box sx={{ mx: -1 }}>
      <Masonry columns={{ xs: 1, sm: 2, md: 3, xl: 4 }} spacing={2} sx={{ mt: 1, mx: 0, width: 'auto' }}>
        {filterGroups.map((groupSummary) => {
          const namedays = getNamedays(groupSummary.group.id);
          const birthdays = getBirthdays(groupSummary.group.id);
          return (
            <Box key={groupSummary.group.id}>
              <Paper
                onClick={() => groupSelect(groupSummary)}
                sx={{
                  cursor: 'pointer',
                  background: (theme) =>
                    selectedTheme === 'contrast'
                      ? theme.palette.color.wcag
                      : groupSummary.group.color || theme.palette.color.color4,
                  p: 2,
                  color: (theme) => theme.palette.color.primary
                }}>
                <Stack>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
                    {groupSummary.group.name}
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mb: 0.5 }}>
                        Obecne
                      </Typography>
                      <Typography variant="h4">{groupSummary.present}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mb: 0.5 }}>
                        Nieobecne
                      </Typography>
                      <Typography variant="h4">{groupSummary.enrolled - groupSummary.present}</Typography>
                    </Grid>
                  </Grid>
                  {namedays.length > 0 || birthdays.length > 0 ? (
                    <Grid container sx={{ mt: 2 }}>
                      {namedays.length > 0 ? (
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mb: 0.5 }}>
                            🥳 Imieniny
                          </Typography>
                          {namedays.map((c) => (
                            <Typography key={c.id} variant="subtitle2">
                              {c.firstName} {c.lastName}
                            </Typography>
                          ))}
                        </Grid>
                      ) : null}
                      {birthdays.length > 0 ? (
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">🎂 Urodziny</Typography>
                          {birthdays.map((c) => (
                            <Typography key={c.id} variant="subtitle2">
                              {c.firstName} {c.lastName}
                            </Typography>
                          ))}
                        </Grid>
                      ) : null}
                    </Grid>
                  ) : null}
                </Stack>
              </Paper>
            </Box>
          );
        })}
      </Masonry>
    </Box>
  );
};

GroupsAttendanceSummary.propTypes = {
  attendanceSummary: PropTypes.array.isRequired,
  children: PropTypes.array,
  isContrastColor: PropTypes.string,
  onSelect: PropTypes.func,
  digitalDiaryEvents: PropTypes.object.isRequired,
  digitalDiaryActions: PropTypes.func,
  selectedTheme: PropTypes.string
};

GroupsAttendanceSummary.defaultProps = {
  children: []
};

function mapStateToProps(state) {
  return {
    unit: state.configuration.unit,
    isContrastColor: state.contrastColor,
    selectedTheme: state.theme,
    themeContrast: state.contrastColor,
    userRole: state.auth.userRole,
    staffMembers: state.staffMembers,
    userId: state.auth.userId,
    groups: state.groups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsAttendanceSummary);
