import PropTypes from 'prop-types';
import React from 'react';
import { Drawer as Drawer2, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';

const drawerStyle = makeStyles({
  drawer: {
    marginTop: 64,
    padding: 10,
    height: 'calc(100% - 64px) !important',
    overflowX: 'hidden',
    width: 320
  },
  '@media(maxWidth: 900px)': {
    display: 'none'
  }
});

const ThreadViewDrawer = ({ children, isDrawerOpen, isRequiredSize, closeDrawer, sx, newMessageButton }) => {
  const classes = drawerStyle();
  return (
    <Drawer2
      sx={{ ...sx }}
      open={isDrawerOpen}
      classes={{
        paper: classes.drawer
      }}
      PaperProps={{
        sx: { borderRadius: 0, borderTopLeftRadius: 10 }
      }}
      onClose={closeDrawer}
      variant={isRequiredSize ? null : 'permanent'}
      anchor="right">
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {newMessageButton && newMessageButton}
          {isRequiredSize && (
            <IconButton
              onClick={closeDrawer}
              sx={{
                mr: 1,
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <CloseIcon sx={{ fontSize: 40 }} />
            </IconButton>
          )}
        </Box>

        {children}
      </>
    </Drawer2>
  );
};

ThreadViewDrawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  isRequiredSize: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  sx: PropTypes.object
};

export default ThreadViewDrawer;
