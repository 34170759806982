import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import moment from 'moment';
import TableBody from '@mui/material/TableBody';
import PersonAvatar from '../../../forms/PersonAvatar';
import FromToTransfer from '../FromToTransfer';
import Status from '../Status';

const ScheduledTransferTableBody = ({
  transfers,
  itemSelected,
  onSelect,
  onEdit,
  onDelete,
  page,
  rowsPerPage,
  areActionsDisabled,
  isHistory
}) => {
  return (
    <TableBody>
      {transfers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {
        return (
          <TableRow
            key={`${row.id}`}
            sx={{
              '.MuiTableCell-root': { border: 'none' },

              '&:hover': {
                background: (theme) => theme.palette.background.hover
              }
            }}>
            <TableCell align="left">
              <Box
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                sx={{ display: 'flex', alignItems: 'center' }}>
                {!isHistory && (
                  <Checkbox
                    size="medium"
                    inputProps={{ 'aria-label': `Wybierz wiersz ${rowIndex}` }}
                    checked={itemSelected(row.id)}
                    onChange={() => onSelect(row.id)}
                    sx={{ mr: 2 }}
                  />
                )}
                <PersonAvatar
                  initials
                  alt={row.firstName}
                  url={row.avatarUrl}
                  firstName={row.firstName}
                  lastName={row.lastName}
                  sx={{ mr: 2 }}
                />
                <Typography variant="tableHeader">
                  {row.firstName} {row.lastName}
                </Typography>
              </Box>
            </TableCell>
            <TableCell align="left">
              <Typography>{moment(row.date).format('YYYY-MM-DD')}</Typography>
            </TableCell>
            <TableCell align="left">
              <FromToTransfer type={row.type} from={row.from} to={row.to} archiveChild={row.archiveChild} />
            </TableCell>
            <TableCell align={isHistory ? 'center' : 'right'}>
              {isHistory ? (
                <Status type={row.status} />
              ) : (
                <Box sx={{ display: 'flex', flexWrap: 'no-wrap', justifyContent: 'center' }}>
                  <Button
                    variant="text"
                    aria-label="Edytuj"
                    onClick={() => onEdit(row.id)}
                    sx={{
                      textTransform: 'none',
                      color: (theme) => theme.palette.color.color7,
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      fontSize: 16,
                      px: 'auto'
                    }}
                    disabled={areActionsDisabled}>
                    edytuj
                  </Button>
                  <Button
                    variant="text"
                    aria-label="usuń"
                    onClick={() => onDelete(row)}
                    sx={{
                      textTransform: 'none',
                      color: (theme) => theme.palette.color.color6,
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      fontSize: 16,
                      px: 'auto'
                    }}
                    disabled={areActionsDisabled}>
                    usuń
                  </Button>
                </Box>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

ScheduledTransferTableBody.propTypes = {
  areActionsDisabled: PropTypes.bool,
  itemSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  transfers: PropTypes.array.isRequired,
  isHistory: PropTypes.bool
};

export default ScheduledTransferTableBody;
