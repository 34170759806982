import { MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import DotsMenu from '../menu/DotsMenu';

const ProgramElement = ({ data, onEdit, onClose, onDelete }) => {
  return (
    <Box
      sx={{
        pl: 2
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          {data.title}
        </Typography>
        <DotsMenu>
          {() => [
            <MenuItem key={1} onClick={onEdit}>
              Edytuj
            </MenuItem>,
            <ActionConfirmationMenuItem
              id={data.id}
              key={`action-${data.id}`}
              actionLabel="Usuń"
              confirmationText="Czy na pewno chcesz usunąć element z listy?"
              confirmationTitle="Usuń"
              confirmLabel="Usuń"
              onItemClick={() => {}}
              onConfirm={() => onDelete()}
              onCancel={onClose}
            />
          ]}
        </DotsMenu>
      </Box>
      <Typography sx={{ mr: 6 }} variant="subtitle2">
        {data.content}
      </Typography>
    </Box>
  );
};

export default ProgramElement;
