import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: ({ isContrastColor }) => (isContrastColor ? theme.palette.color.color4 : theme.palette.grey[600]),
    marginRight: 10
  },
  text: {
    fontSize: 14
  },
  container: {
    border: ({ isContrastColor }) =>
      `2px solid ${isContrastColor ? theme.palette.color.color4 : theme.palette.grey[600]}`,
    borderRadius: 5,
    padding: '4px 8px'
  }
}));

const PrivateFile = ({ text }) => {
  const isContrastColor = useSelector((state) => state.contrastColor);
  const cl = useStyles({ isContrastColor });
  return (
    <Grid container alignItems="center" justifyContent="center" className={cl.container}>
      <LockIcon className={cl.icon} />
      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }} className={cl.text}>
        {text}
      </Typography>
    </Grid>
  );
};

PrivateFile.propTypes = { text: PropTypes.string.isRequired };

export default PrivateFile;
