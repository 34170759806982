import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DietsModel } from '../models/meals/DietModels';

export default function dietsReducer(state = initialState.diets, action) {
  switch (action.type) {
    case types.LOAD_DIETS_SUCCESS: {
      return action.data.map((diet) => new DietsModel().assign(diet));
    }
    default:
      return state;
  }
}
