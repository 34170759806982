import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getComparator, stableSort } from '../../../../../utils/tableSorting';
import { Box, Checkbox, MenuItem, Typography, Chip, TableBody, TableRow, TableCell } from '@mui/material';
import moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PropTypes from 'prop-types';
import React from 'react';
import FileSpecificIcon from '../../../../common/FileSpecificIcon/FileSpecificIcon';
import DotsMenu from '../../../../common/menu/DotsMenu';
import userRoles from '../../../../../constants/userRoles';
import GetAppIcon from '@mui/icons-material/GetApp';
import DoneIcon from '@mui/icons-material/Done';
import ShareIcon from '@mui/icons-material/Share';
import FileShareAvatars from '../../FileShareAvatars/FileShareAvatars';
import LinkIcon from '@mui/icons-material/Link';
import FolderMenu from '../../FolderMenu/FolderMenu';
import { useDispatch } from 'react-redux';
import { getFolderContent } from '../../../../../actions/virtualDiscActions';

const useCellBodyStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    fontSize: 13,
    padding: '0px 12px'
  },
  row: {
    '&:hover': {
      background: theme.palette.background.hover
    }
  }
}));

function humanFileSize(size) {
  const i = size && Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / 1024 ** i).toLocaleString('pl-PL', { maximumFractionDigits: 2 }) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

const VirtualDiscTableBody = ({
  data,
  itemSelected,
  onSelect,
  onDownload,
  onDelete,
  onShare,
  page,
  rowsPerPage,
  onCopyLink,
  order,
  orderBy,
  areActionsDisabled,
  isManagement,
  hasSuperPermission,
  user,
  handleOpenMoveToFolderDialog,
  cancelSelectedFiles
}) => {
  const cl = useCellBodyStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleOpenFolder = (id) => {
    cancelSelectedFiles();
    dispatch(getFolderContent(id));
  };
  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, rowIndex) => {
          const hasPermissionToFile = row.isOwnByUser || hasSuperPermission;
          return (
            <TableRow
              key={`${row.id}`}
              onDoubleClick={row.type === 'folder' ? () => handleOpenFolder(row.id) : null}
              // button={row.type === 'folder'}
              className={cl.row}
              sx={{
                background: itemSelected(row.id) ? theme.palette.background.hover : undefined,
                cursor: row.type === 'folder' ? 'pointer' : 'default'
              }}>
              <TableCell sx={{ width: 30 }} align="center">
                <Typography variant="span" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                  {row.type !== 'folder' && (
                    <Checkbox
                      aria-label={`Wybierz wiersz ${rowIndex}`}
                      inputProps={{ 'aria-label': `Wybierz wiersz ${rowIndex}` }}
                      checked={itemSelected(row.id)}
                      onChange={() => onSelect(row)}
                    />
                  )}
                </Typography>
              </TableCell>
              <TableCell classes={{ root: cl.root }} align="left">
                <Box sx={{ display: 'flex', alignItems: ' center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: ' center' }}>
                    <FileSpecificIcon
                      item={row}
                      style={{ fontSize: 40, marginRight: 5 }}
                      type={row.type === 'folder' ? 'folder' : row.mimeType}
                    />
                    <Typography>{row.name}</Typography>
                  </Box>
                  {row.isNew && (
                    <Chip
                      sx={{
                        '& .MuiChip-label': {
                          pt: 0.5
                        },
                        ml: 10,
                        width: 80,
                        color: (theme) => theme.palette.color.color25,
                        border: (theme) => `2px solid ${theme.palette.color.color25}`
                      }}
                      icon={<DoneIcon color="inherit" />}
                      label={
                        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>NOWY</Typography>
                      }
                      size="small"
                      variant="outlined"
                    />
                  )}
                </Box>
              </TableCell>
              <TableCell align="left">
                {row.uploadedBy || row.createdBy ? (
                  <Typography>{row.uploadedBy || row.createdBy}</Typography>
                ) : (
                  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                    Brak informacji
                  </Typography>
                )}
              </TableCell>
              <TableCell align="left">
                <Typography>{moment(row.uploadedAt || row.createdAt).format('YYYY-MM-DD')}</Typography>
              </TableCell>
              <TableCell align="left">
                {row.size ? (
                  <Typography>{humanFileSize(row.size)}</Typography>
                ) : (
                  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                    Brak informacji
                  </Typography>
                )}
              </TableCell>
              {user !== userRoles.legalGuardian ? (
                <TableCell align="left">
                  <FileShareAvatars
                    userRolesList={row.shareForUserRoles}
                    isShareToUsers={row.shareForUserIds.length > 0}
                    isOwnByUser={row.isOwnByUser}
                  />
                </TableCell>
              ) : (
                false
              )}
              <TableCell sx={{ width: 30 }} align="right">
                {row.type !== 'folder' ? (
                  <DotsMenu disabled={areActionsDisabled} sx={{ m: 0, p: 0 }}>
                    {() => [
                      <MenuItem
                        key="1"
                        onClick={() => onDownload(row.fileUrl, row.name, row.mimeType)}
                        disabled={areActionsDisabled}>
                        <GetAppIcon sx={{ mr: 3 }} />
                        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                          Pobierz
                        </Typography>
                      </MenuItem>,
                      isManagement && hasPermissionToFile && (
                        <MenuItem key="3" onClick={() => onShare(row.id)} disabled={areActionsDisabled}>
                          <ShareIcon sx={{ mr: 3 }} />
                          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                            Udostępnij
                          </Typography>
                        </MenuItem>
                      ),
                      <MenuItem
                        key="5"
                        onClick={() => onCopyLink(row.fileUrl, row.name, row.mimeType)}
                        disabled={areActionsDisabled}>
                        <LinkIcon sx={{ mr: 3 }} />
                        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                          Skopiuj link do pliku
                        </Typography>
                      </MenuItem>,
                      isManagement && hasPermissionToFile && (
                        <MenuItem key="4" onClick={() => onDelete(row.id)} disabled={areActionsDisabled}>
                          <DeleteForeverIcon sx={{ mr: 3, mt: -1 }} />
                          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                            Usuń
                          </Typography>
                        </MenuItem>
                      )
                    ]}
                  </DotsMenu>
                ) : (
                  <FolderMenu
                    item={row}
                    isTableView
                    handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog}
                    cancelSelectedFiles={cancelSelectedFiles}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

VirtualDiscTableBody.propTypes = {
  areActionsDisabled: PropTypes.bool,
  data: PropTypes.array.isRequired,
  itemSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopyLink: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  isManagement: PropTypes.bool.isRequired,
  hasSuperPermission: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired
};

export default VirtualDiscTableBody;
