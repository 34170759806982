import PropTypes from 'prop-types';
import CustomTextField from '../../common/TextField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const AddMealCategoryForm = ({ category, onChange, errors }) => {
  return (
    <FieldsetWrapper legend="Pola dodania nowej kategorii posiłku">
      <CustomTextField
        hintText="Nazwa kategorii"
        floatingLabelText="Nazwa kategorii"
        floatingLabelFixed={false}
        name="name"
        value={category.name}
        onChange={onChange}
        fullWidth={true}
        errorText={errors.name}
      />
    </FieldsetWrapper>
  );
};

AddMealCategoryForm.propTypes = {
  category: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object
};

export default AddMealCategoryForm;
