import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingIndicator from './LoadingIndicator';

const LoadingIndicatorWrapper = ({ isProcessing, children, shouldUnmountChildrenWhenProcessing = false }) => {
  return (
    <Box>
      {isProcessing ? <LoadingIndicator /> : null}
      {shouldUnmountChildrenWhenProcessing && isProcessing ? null : (
        <Box sx={{ visibility: isProcessing ? 'hidden' : 'inherit', opacity: isProcessing ? 0.0 : 1.0 }}>
          {children}
        </Box>
      )}
    </Box>
  );
};

LoadingIndicatorWrapper.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  shouldUnmountChildrenWhenProcessing: PropTypes.bool,
  className: PropTypes.string
};

export default LoadingIndicatorWrapper;
