import React from 'react';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import VirtualDiscContainer from './containers/VirtualDiscContainer';

const VirtualDiscPage = () => {
  return (
    <LoadingRenderWrapper>
      <VirtualDiscContainer />
    </LoadingRenderWrapper>
  );
};

export default VirtualDiscPage;
