export const psychologicalSchemesTypes = Object.freeze({
  individualDevelopmentAndEducationalNeeds: {
    title: 'Indywidualne potrzeby rozwojowe i edukacyjne',
    value: 'individualDevelopmentAndEducationalNeeds'
  },
  studentStrengthsAndPreferences: {
    title: 'Mocne strony, predyspozycje i zainteresowania ucznia',
    value: 'studentStrengthsAndPreferences'
  },
  parentSupportScope: {
    title: 'Zakres i charakter wsparcia ze strony nauczycieli, specjalistów lub pomocy nauczyciela',
    value: 'parentSupportScope'
  },
  reasonsForEducationalFailures: {
    title: 'Przyczyny niepowodzeń edukacyjnych i trudności w funkcjonowaniu dziecka',
    value: 'reasonsForEducationalFailures'
  },
  programEffectivenessAssessment: { title: 'Ocena efektywności programu', value: 'programEffectivenessAssessment' },

  programAdaptationScopeAndMethod: {
    title:
      'Zakres i sposób dostosowania programu wychowania przedszkolnego oraz wymagań edukacyjnych do indywidualnych potrzeb rozwojowych i edukacyjnych  oraz możliwości psychofizycznych ucznia',
    value: 'programAdaptationScopeAndMethod'
  },
  parentSupportActions: { title: 'Działania wspierające rodziców dziecka', value: 'parentSupportActions' },
  integratedActions: { title: 'Zintegrowane działania', value: 'integratedActions' },
  psychologicalCounselingRecommendations: {
    title: 'Zalecenia poradni psychologicznej',
    value: 'psychologicalCounselingRecommendations'
  },
  psychologicalSupport: { title: 'Udzielana pomoc psychologiczno-pedagogiczna', value: 'psychologicalSupport' },
  rehabilitationSessions: { title: 'Zajęcia rewalidacyjne', value: 'rehabilitationSessions' },
  teacherSpecialistCooperationScope: {
    title: 'Zakres współpracy nauczycieli z rodzicami',
    value: 'teacherSpecialistCooperationScope'
  },
  educationConditionAdaptationTypeAndMethod: {
    title:
      'Rodzaj i sposób dostosowania warunków organizacji kształcenia do rodzaju niepełnosprawności ucznia w tym w zakresie wykorzystywania technologii wspomagających to kształcenie - w zależności od potrzeb',
    value: 'educationConditionAdaptationTypeAndMethod'
  },
  basicGoals: {
    title: 'Cele ogólne',
    value: 'basicGoals'
  },
  specificGoals: {
    title: 'Cele szczegółowe',
    value: 'basicGoals'
  },
  methods: { title: 'Metody', value: 'methods' },
  conclusions: { title: 'Wnioski', value: 'conclusions' },
  individualSchedules: { title: 'Rozkład zajęć indywidualnych', value: 'individualSchedules' },
  classesSchedules: { title: 'Rozkład zajęć grupowych', value: 'classesSchedules' },

  basicGroupGoals: {
    title: 'Cele ogólne',
    value: 'basicGroupGoals'
  },
  specificGroupGoals: {
    title: 'Cele szczegółowe',
    value: 'specificGroupGoals'
  },
  groupMethods: { title: 'Metody', value: 'groupMethods' },
  groupConclusions: { title: 'Wnioski', value: 'groupConclusions' },
  groupSchedules: { title: 'Rozkład zajęć grupowych', value: 'groupSchedules' },
  groupProgram: { title: 'Realizacja programu', value: 'groupProgram' }
});
