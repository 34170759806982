// import moment from 'moment';
import BaseModel from '../BaseModel';

export class EntranceFileModel extends BaseModel {
  constructor() {
    super();
    this.fileId = '';
    this.name = '';
    this.uploadDate = '';
    this.file = null;
    this.dataType = null;
    this.fileStorageUrl = '';
    this.fileUrl = '';
    this.mimeType = '';
  }
}
