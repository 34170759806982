import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import SelectField from '../../common/SelectField';
import { ReportTypes } from '../../../constants/reportTypes';
import { Box, Checkbox, Typography, MenuItem, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';

const DuesDetailsOverallReportXLS = (props) => {
  const [sortBy, setSortBy] = useState('name');
  const [sortOptions] = useState([
    { name: 'Po nazwisku', value: 'name' },
    { name: 'Po numerze', value: 'documentNumber' }
  ]);
  const [dueTypes, setDueTypes] = useState([
    ChargingReportTypes.stay.value,
    ChargingReportTypes.catering.value,
    ChargingReportTypes.other.value
  ]);

  const onSelect = (dueType) => {
    let newVal;
    if (dueTypes.includes(dueType)) newVal = [...dueTypes.filter((id) => id !== dueType)];
    else newVal = [...dueTypes, dueType];
    setDueTypes(newVal);
    props.onChange({ target: { name: 'dueTypes', value: newVal } });
  };

  return (
    <Box>
      <GroupAndMonthTemplate
        errors={props.errors}
        manyGroupsSelector
        year={props.year}
        month={props.month}
        groups={props.groups}
        onChange={props.onChange}
        legend={ReportTypes.duesDetailsXls.name}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.duesDetailsXls.name}`}>
        <Typography variant="h6" sx={{ pt: 2 }}>
          Uwzględnij opłaty za
        </Typography>
        <List>
          {Object.values(ChargingReportTypes)
            .filter((item) => item.value !== ChargingReportTypes.staffCatering.value)
            .map((due) => (
              <ListItem
                sx={{ p: 0 }}
                component="li"
                button
                key={due.value}
                onClick={() => onSelect(due.value)}
                aria-label="Wybierz {due.name}">
                <ListItemIcon>
                  <Checkbox
                    checked={dueTypes.includes(due.value)}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': `${due}-checkbox` }}
                  />
                </ListItemIcon>
                <ListItemText id={`${due}-checkbox`} primary={due.name} />
              </ListItem>
            ))}
        </List>
      </FieldsetWrapper>
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.duesDetailsXls.name}`}>
        <Typography variant="h6" sx={{ pt: 2 }}>
          Ustawienia raportu
        </Typography>
        <SelectField
          contrast
          floatingLabelText="Sortowanie"
          id="sortBy"
          value={sortBy}
          onChange={(event) => {
            setSortBy(event.target.value);
            props.onChange({ target: { name: 'sortBy', value: event.target.value } });
          }}>
          {sortOptions.map((so) => {
            return (
              <MenuItem key={so.value} value={so.value}>
                {so.name}
              </MenuItem>
            );
          })}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

DuesDetailsOverallReportXLS.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DuesDetailsOverallReportXLS;
