import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Keypad from './Keypad';
import * as pinActions from '../../actions/pinActions';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Alert, Collapse, Grid, IconButton, useTheme, useMediaQuery, Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from 'react-qr-code';
import { getTenantName } from '../../utils/getTenantName';
import moment from 'moment';
import { convertStringToBase64 } from '../../utils/convertStringToBase64';
import backgroundAdorment from '../../assets/svg/hero-bg-dots.svg';
import logo from '../../img/sign-in/4parens-logo.png';

const requiredPinLength = 6;

const tenantName = getTenantName();

// const RefreshQRCodeInterval = 4 * 60 * 60 * 1000;
const RefreshQRCodeInterval = 30 * 1000;

const KeypadPage = (props) => {
  const [pin, setPin] = useState('');
  const [date, setDate] = useState(moment().toISOString());
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const isButtonDisable = pin.length !== requiredPinLength;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('lg'));
  const isQrEnabled = useSelector((state) => state.configuration.unit.guardiansQRServiceSettings.enabled);
  const isPinEnabled = useSelector((state) => state.configuration.guardiansPinService.enabled);

  useEffect(() => {
    if (props.location.state) {
      setMessage(props.location.state.message);
      setSeverity(props.location.state.severity);
      setShowNotification(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment().toISOString());
    }, RefreshQRCodeInterval);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const dispatch = useDispatch();

  const handleNumberPress = (number) => {
    if (pin.length < 6) setPin(pin + number);
    if (showNotification) {
      setShowNotification(false);
    }
  };

  const handleUndo = () => {
    setPin(pin.slice(0, -1));
    if (showNotification) {
      setShowNotification(false);
    }
  };

  const handleClick = async () => {
    try {
      await dispatch(pinActions.getEntries(pin));
    } catch (e) {
      setMessage('Nie udało się uwierzytelnić podanym numerem PIN');
      setSeverity('error');
      setShowNotification(true);
    }
  };

  return (
    <LoadingRenderWrapper>
      <Box
        sx={{
          p: 2,
          minHeight: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          background: ` url(${backgroundAdorment}), transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`,
          [theme.breakpoints.down('740')]: {
            background: ` transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`
          }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img src={logo} alt="4parents_logo" />
          {/* <Typography sx={{ width: '100%', fontWeight: (theme) => theme.typography.fontWeightBold }} component="h1" variant="h5" align="center">
            Witamy w systemie 4parents.
          </Typography> */}
        </Box>

        <Grid container justify="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
          {isPinEnabled && (
            <Grid item sx={{ alignItems: 'center', p: 1 }} md={isQrEnabled ? 6 : 12} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <Typography sx={{ width: '100%' }} fontWeight="bold" component="h1" variant="h5" align="center">
                  Podaj PIN aby zarejestrować wejście/wyjście dziecka
                </Typography>
                <Typography
                  fontWeight="bold"
                  sx={{
                    fontSize: 40,
                    width: 300,
                    my: 3,
                    borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`,
                    height: 48
                  }}
                  align="center">
                  {pin.replace(/./g, '*')}
                </Typography>
                <Box>
                  <Keypad
                    onNumberPress={handleNumberPress}
                    onUndo={handleUndo}
                    disabled={isButtonDisable}
                    onClick={handleClick}
                  />
                </Box>
              </Box>
            </Grid>
          )}

          {isQrEnabled && (
            <Grid
              item
              md={isPinEnabled && isQrEnabled ? 6 : 12}
              xs={12}
              sx={{
                borderLeft: (theme) => (isTablet && isPinEnabled ? `1px solid ${theme.palette.color.primary}` : ''),
                p: 1
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mt: isTablet ? 0 : 3,
                  pt: isTablet ? 0 : 3,
                  borderTop: (theme) => (isTablet ? '' : `1px solid ${theme.palette.color.primary}`)
                }}>
                <Typography sx={{ width: '100%', mb: 3 }} fontWeight="bold" component="h1" variant="h5" align="center">
                  Zeskanuj kod QR w aplikacji mobilnej 4Parents
                </Typography>
                <QRCode id="QRCode" title="Kod QR" value={`4parents:${tenantName}:${convertStringToBase64(date)}`} />
              </Box>
            </Grid>
          )}
        </Grid>
        <Collapse
          in={showNotification}
          sx={{
            position: 'fixed',
            left: '50%',
            bottom: '0px',
            transform: 'translate(-50%, -50%)',
            width: '70%'
          }}>
          <Alert
            variant="filled"
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowNotification(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            <Box sx={{ fontSize: isTablet ? 20 : null }}>{message}</Box>
          </Alert>
        </Collapse>
      </Box>
    </LoadingRenderWrapper>
  );
};

KeypadPage.propTypes = {
  location: PropTypes.object
};

export default KeypadPage;
