import PropTypes from 'prop-types';
import React from 'react';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import EmptyState from '../../common/EmptyState';
import { ListItem, List, ListItemText, ListItemSecondaryAction } from '@mui/material';
import DotsMenu from '../../common/menu/DotsMenu';

const NumberingSchemesList = ({ schemes, onSelected, onRemove }) => {
  return (
    <List sx={{ p: 0 }}>
      {schemes.length === 0 ? <EmptyState contrast message="Nie zdefiowano jeszcze żadnej serii numeracji" /> : null}
      {schemes.map((numberingScheme) => {
        return (
          <ListItem
            button
            key={numberingScheme.id}
            onClick={() => onSelected(numberingScheme)}
            component="li"
            aria-label={`Edytuj serie numeracji ${numberingScheme}`}>
            <ListItemText
              secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.text.primary } }}
              primary={numberingScheme.name}
              secondary={
                <span>
                  Kolejny numer <strong>{numberingScheme.nextNumber}</strong>
                </span>
              }
            />
            <ListItemSecondaryAction>
              <DotsMenu>
                {(onClose) => [
                  <ActionConfirmationMenuItem
                    key="1"
                    onItemClick={onClose}
                    actionLabel="Usuń"
                    confirmationText="Czy na pewno chcesz usunąć numerację? Numeracja zostanie odłączona od wszystkich typów opłat do których jest aktualnie przypisana."
                    confirmationTitle="Usuń serię numeracji"
                    confirmLabel="Usuń"
                    onConfirm={() => onRemove(numberingScheme.id)}
                    onCancel={() => {}}
                  />
                ]}
              </DotsMenu>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

NumberingSchemesList.propTypes = {
  schemes: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default NumberingSchemesList;
