import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Grid, List, ListItem, ListItemAvatar, Paper, Typography } from '@mui/material';
import PersonAvatar from '../forms/PersonAvatar';
import EmptyState from '../common/EmptyState';
import React, { useMemo } from 'react';

export const actionTypes = {
  attendant: 'attendant',
  absence: 'absence'
};
const IndividualAttendanceSheetList = ({
  title,
  entries,
  onReportAttendance,
  onReportAbsence,
  actionTypes,
  actionType,
  searchText,
  onCustomRedirect,
  contrast,
  isNotPaper
}) => {
  const filteredEntities = useMemo(() => {
    return entries
      .filter(
        (entry) =>
          entry &&
          ((entry.firstName + ' ' + entry.lastName).toLowerCase().includes(searchText.toLowerCase()) ||
            (entry.lastName + ' ' + entry.firstName).toLowerCase().includes(searchText.toLowerCase()))
      )
      .sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
  }, [entries, searchText]);
  return (
    <>
      {title ? (
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary),
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {title}
          </Typography>
          <Avatar
            sx={{
              width: '25px',
              height: '25px',
              background: (theme) => theme.palette.background.transparent,
              color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary),
              opacity: 0.7,
              mx: 1,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {entries.length}
          </Avatar>
        </Box>
      ) : null}
      {filteredEntities.length > 0 ? (
        <Box>
          <Paper
            sx={{
              my: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              px: 2,
              py: 1
            }}
            elevation={isNotPaper ? 0 : undefined}>
            <List>
              {filteredEntities.map((entry) =>
                entry ? (
                  <ListItem
                    key={entry.id}
                    sx={{
                      display: 'flex',
                      minHeight: 80,
                      '&:hover': {
                        cursor: 'pointer',
                        background: (theme) => theme.palette.background.hover
                      }
                    }}>
                    <Grid container>
                      <Grid
                        item
                        onClick={() => {
                          onCustomRedirect(entry);
                        }}
                        xs={6}
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemAvatar>
                          <PersonAvatar
                            initials
                            alt={entry.firstName}
                            url={entry.photoUrl}
                            firstName={entry.firstName}
                            lastName={entry.lastName}
                          />
                        </ListItemAvatar>
                        <Typography
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline'
                            },
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            fontWeight: (theme) => theme.typography.fontWeightBold
                          }}>{`${entry.lastName} ${entry.firstName}`}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          justifyContent: { xs: 'flex-end' }
                        }}>
                        {!actionType || actionType === actionTypes.absence ? (
                          <Button
                            variant="text"
                            aria-label="obecność"
                            key="1"
                            onClick={() => onReportAttendance(entry)}
                            sx={{
                              color: (theme) => theme.palette.color.color7,
                              fontWeight: (theme) => theme.typography.fontWeightBold,
                              textTransform: 'none',
                              fontSize: 16
                            }}>
                            obecność
                          </Button>
                        ) : null}
                        {!actionType || actionType === actionTypes.attendant ? (
                          <Button
                            variant="text"
                            aria-label="nieobecność"
                            key="2"
                            onClick={() => onReportAbsence(entry)}
                            sx={{
                              textTransform: 'none',
                              color: (theme) => theme.palette.color.color25,
                              fontWeight: (theme) => theme.typography.fontWeightBold,
                              fontSize: 16
                            }}>
                            nieobecność
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : null
              )}
            </List>
          </Paper>
        </Box>
      ) : (
        <EmptyState paperSx={{ my: 2 }} isPaper={!isNotPaper} contrast message="Brak dzieci" />
      )}
    </>
  );
};
export default IndividualAttendanceSheetList;
