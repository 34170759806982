import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@mui/material/Paper';
import ValidationSummary, { validationTypes } from '../common/validation/ValidationSummary';
import TextField from '../common/TextField';
import { Box, Button } from '@mui/material';

const PinSignInForm = ({ signInData, onChange, onSignIn, errorMessage, isProcessing }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Paper
        sx={{
          textAlign: 'center',
          p: 2
        }}
        zdepth={0}>
        <Box>Wprowadź login urządzenia i hasło</Box>
        {errorMessage ? (
          <ValidationSummary messages={errorMessage} title="Logowanie nie powiodło się" type={validationTypes.error} />
        ) : null}
        <form onSubmit={onSignIn}>
          <Box>
            <TextField
              hintText="Login"
              disabled={isProcessing}
              floatingLabelText="Login"
              floatingLabelFixed={false}
              type="text"
              name="userName"
              value={signInData.userName}
              onChange={onChange}
              fullWidth={true}
              autoFocus={true}
            />
            <TextField
              hintText="Hasło"
              disabled={isProcessing}
              floatingLabelText="Hasło"
              floatingLabelFixed={false}
              type="password"
              name="password"
              value={signInData.password}
              onChange={onChange}
              fullWidth={true}
            />
          </Box>
          <Button
            variant="contained"
            aria-label={isProcessing ? 'Logowanie...' : 'Zaloguj'}
            type="submit"
            disabled={isProcessing}
            sx={{
              width: '100%',
              my: '1em',
              mx: 0
            }}>
            {isProcessing ? 'Logowanie...' : 'Zaloguj'}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

PinSignInForm.propTypes = {
  signInData: PropTypes.object.isRequired,
  onSignIn: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired
};

export default PinSignInForm;
