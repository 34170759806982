import { check } from '../validation/validateHelper';
import {
  ChildModel,
  GuardianModel,
  LegalGuardianModel,
  FileModel,
  OpeningBalance,
  OpinionModel,
  DiagnoseModel,
  UserModel,
  OpinionFileModel,
  VacationPeriodModel
} from './ChildModels';
import { BaseModelValidator } from '../BaseModelValidator';
import Pesel from '../../utils/Pesel';
import { PersonalIdTypes } from '../../constants/personalIdTypes';
import iban from 'iban';
import moment from 'moment';

const AccountCountryCode = 'PL';

export class ChildModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = ChildModel;

    this.constraints = {
      firstName: (value) => check.isEmpty(value) && 'Nie podano imienia',
      lastName: (value) => check.isEmpty(value) && 'Nie podano nazwiska',
      birthDate: (value) => check.isEmpty(value) && 'Nie podano daty urodzenia',
      personalId: (value, model) => {
        if (model.personalIdType !== PersonalIdTypes.pesel.value) return null;
        if (check.isEmpty(value)) return 'Nie podano numeru PESEL';
        if (!new Pesel(value).isValid()) return 'Podany numer PESEL jest nieprawidłowy';
        return null;
      },
      address: {
        city: (value) => check.isEmpty(value) && 'Nie podano miasta',
        address: (value) => check.isEmpty(value) && 'Nie podano adresu',
        zipCode: (value) => {
          if (check.isEmpty(value)) return 'Nie podano kodu pocztowego';
          if (check.single(value, { format: '^[0-9]{2}-[0-9]{3}$' })) return 'Kod pocztowy powinien mieć postać 00-000';
          return null;
        }
      },
      registeredAddress: {
        zipCode: (value) => {
          if (check.single(value, { format: '^[0-9]{2}-[0-9]{3}$' })) return 'Kod pocztowy powinien mieć postać 00-000';
          return null;
        }
      },
      declaredDropOffAt: (value, model) => {
        if (value.isEmpty() && !model.declaredPickUpAt.isEmpty()) return 'Nie zadeklarowano godziny';
        if (value.isAfter(model.declaredPickUpAt))
          return `Zadeklarowana godzina musi być wcześniejsza niż ${model.declaredPickUpAt.toString()}`;
        return null;
      },
      declaredPickUpAt: (value, model) => {
        if (value.isEmpty() && !model.declaredDropOffAt.isEmpty()) return 'Nie zadeklarowano godziny';
        return null;
      },
      cateringBankAccount: (value) => {
        if (value && !iban.isValid(`${AccountCountryCode}${value}`)) return 'Numer rachunku jest nieprawidłowy';
        return null;
      },
      stayBankAccount: (value) => {
        if (value && !iban.isValid(`${AccountCountryCode}${value}`)) return 'Numer rachunku jest nieprawidłowy';
        return null;
      },
      otherBankAccount: (value) => {
        if (value && !iban.isValid(`${AccountCountryCode}${value}`)) return 'Numer rachunku jest nieprawidłowy';
        return null;
      },
      // eslint-disable-next-line no-confusing-arrow
      childIntegrationId: (value) =>
        value.length > 4 ? 'Identyfikator integracji nie może być dłuższy niż 4 znaki' : null,
      additionalChildIntegrationId: (value) =>
        value.length > 4 ? 'Identyfikator integracji nie może być dłuższy niż 4 znaki' : null
    };
  }
}

export class LegalGuardianModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = LegalGuardianModel;

    this.constraints = {
      firstName: (value) => check.isEmpty(value) && 'Nie podano imienia',
      lastName: (value) => check.isEmpty(value) && 'Nie podano nazwiska',
      email: (value) => {
        if (check.isEmpty(value)) return 'Nie podano adresu email';
        if (check.single(value, { email: true })) return 'Podany adres email jest nieprawidłowy';
        return null;
      },
      documentNumber: (value) => check.isEmpty(value) && 'Nie podano numeru dokumentu',
      bankAccountNumber: (value) => {
        if (iban.isValid(`PL${value}`) || iban.isValid(`${value}`)) return null;
        if (!check.isEmpty(value)) return 'Niepoprawny numer konta';
      },
      address: (value) => check.isEmpty(value) && 'Nie podano adresu zamieszkania',
      worksFrom: (value, model) => {
        if (value.isEmpty() && model.worksTo && !model.worksTo.isEmpty()) return 'Nie zadeklarowano godziny';
        if (value.isAfter(model.worksTo))
          return `Zadeklarowana godzina musi być wcześniejsza niż ${model.worksTo.toString()}`;
        return null;
      },
      worksTo: (value, model) => {
        if (value.isEmpty() && model.worksFrom && !model.worksFrom.isEmpty()) return 'Nie zadeklarowano godziny';
        return null;
      },
      personalId: (value) => {
        if (value && !new Pesel(value).isValid()) return 'Podany numer PESEL jest nieprawidłowy';
        return null;
      },
      phoneNumber: (value) => {
        if (value && check.single(value, { format: '^[+]?[0-9]{9,}$' })) {
          return 'Numer telefonu powinien mieć co najmniej 9 cyfr i składać się tylko z cyfr i znaku "+"';
        }
        return null;
      }
    };
  }
}

export class UserModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = UserModel;

    this.constraints = {
      password: (value) => {
        if (check.isEmpty(value)) return 'Hasło nie może być puste';
        if (value.length <= 7) return 'Hasło powinno mieć co najmniej 8 znaków';
      },
      userName: (value) => {
        if (check.isEmpty(value)) return 'Nie podano adresu email';
        if (check.single(value, { email: true })) return 'Podany adres email jest nieprawidłowy';
        return null;
      }
    };
  }
}

export class GuardianModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = GuardianModel;
    this.constraints = {
      firstName: (value) => check.isEmpty(value) && 'Nie podano imienia',
      lastName: (value) => check.isEmpty(value) && 'Nie podano nazwiska',
      documentNumber: (value) => check.isEmpty(value) && 'Nie podano numeru dokumentu',
      authorizationExpirationDate: (value) => {
        if (!value) return null;
        const momentDate = moment(value);
        if (!momentDate.isValid()) return 'Podana data jest nieprawidłowa';
        if (momentDate.isBefore(moment().startOf('day'))) return 'Data nie może być wcześniejsza niż dzisiejsza';
        return null;
      }
    };
  }
}

export class OpinionModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = OpinionModel;

    this.constraints = {
      opinionName: (value) => check.isEmpty(value) && 'Nie podano nazwy.',
      issuedBy: (value) => check.isEmpty(value) && 'Nie podano informacji przez kogo opinia jest wydana',
      issuedDate: (value) => check.isEmpty(value) && 'Nie podano daty'
    };
  }
}

export class DiagnoseModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = DiagnoseModel;

    this.constraints = {
      title: (value) => check.isEmpty(value) && 'Nie podano tytułu.',
      content: (value) => check.isEmpty(value) && 'Nie wprowadzono diagnozy'
    };
  }
}

export class VacationPeriodModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.constraints = {
      vacationPeriodStart: (value) => check.isEmpty(value) && 'Nie podano daty rozpoczęcia urlopu.',
      vacationPeriodEnd: (value) => check.isEmpty(value) && 'Nie podano daty zakończenia urlopu.',
    };
  }
}


export class FileModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = FileModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy pliku.',
      generalError: (value, model) => {
        if (check.isEmpty(model.file)) return 'Nie dodano pliku.';
        if (model.file.size > 4 * 1024 * 1024) return 'Plik jest zbyt duży. Maksymalna wielkość pliku to 5 MB.';
        return null;
      }
    };
  }
}

const checkExtension = ['cmd', 'html', 'htm', 'js', 'py', 'bat', 'exe', 'deb'];

export class OpinionFileModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = OpinionFileModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy pliku.',
      opinionType: (value) => check.isEmpty(value) && 'Nie podano rodzaju.',
      opinionTypeOtherValue: (value, model) => {
        if (check.isEmpty(value) && model.opinionType === 'other') return 'Wprowadz wartość.';
      },
      helpDateStart: (value, model) => {
        if (model.needHelp !== true) return;
        if (check.isEmpty(value)) return 'To pole jest obowiązkowe.';
        if (!moment(value).isValid()) return 'Wprowadz poprawną datę.';
        if (moment(value).isAfter(model.helpDateEnd))
          return 'Data rozpoczęcia nie może być późniejsza niż data zakończenia.';
        // if(!check.isEmpty(value) && )
      },
      helpDateEnd: (value, model) => {
        if (model.needHelp !== true) return;
        if (check.isEmpty(value)) return 'To pole jest obowiązkowe.';
        if (!moment(value).isValid()) return 'Wprowadz poprawną datę.';
      },

      generalError: (value, model) => {
        if (check.isEmpty(model.file)) return 'Nie dodano pliku.';
        if (checkExtension.includes(model.file.path.split('.')[1])) return 'Plik ma nieodpowienie rozszerzenie.';
        if (model.file.size > 4 * 1024 * 1024) return 'Plik jest zbyt duży. Maksymalna wielkość pliku to 5 MB.';
        return null;
      }
    };
  }
}

export class SurveyModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = FileModel;

    this.constraints = {
      surveyName: (value) => check.isEmpty(value) && 'Nie podano nazwy ankiety.',
      generalError: (value, model) => {
        if (check.isEmpty(model.file)) return 'Nie dodano pliku.';
        if (model.file.size > 4 * 1024 * 1024) return 'Plik jest zbyt duży. Maksymalna wielkość pliku to 5 MB.';
        return null;
      }
    };
  }
}

export class OpeningBalanceModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = OpeningBalance;

    this.constraints = {
      totalTime: {
        minutes: (value) => value && (value < 0 || value > 60) && 'Minuty muszą być podane w zakresie 0 - 59.'
      },
      totalChargeableTime: {
        minutes: (value) => value && (value < 0 || value > 60) && 'Minuty muszą być podane w zakresie 0 - 59.'
      }
    };
  }
}
