import React from 'react';
import PropTypes from 'prop-types';
import ShareFileHeaderOptions from './ShareFileHeaderOptions';
import ShareFileHeaderFilesList from './ShareFileHeaderFiles';
import { Box, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ShareFileMobileHeader from '../MobileHeader/ShareFileMobileHeader';
import { useShareFileContext } from '../../context/ShareFileContext';

const useStyles = makeStyles(() => ({
  panelContent: {
    flexBasis: '30%',
    boxSizing: 'border-box',
    maxHeight: ({ isMobile }) => (isMobile ? null : 160),
    minWidth: ({ isMobile }) => (isMobile ? null : 500),

    width: '100%'
  },
  listContent: {
    flex: 1,
    overflowY: 'auto',
    boxSizing: 'border-box',
    maxHeight: 160,
    padding: 5,
    position: 'relative',
    maxWidth: '100%'
  }
}));

const ShareFileHeader = ({ selectedItems, missingPermissionsItemsArray, ...rest }) => {
  const { isMobile } = useShareFileContext();
  const cl = useStyles({ isMobile });

  if (isMobile) return <ShareFileMobileHeader {...rest} />;

  return (
    <>
      <Divider />
      <Grid container direction={isMobile ? 'column-reverse' : 'row'}>
        <Box className={cl.panelContent}>
          <ShareFileHeaderOptions {...rest} />
        </Box>
        <Divider orientation="vertical" />
        <Box className={cl.listContent}>
          <ShareFileHeaderFilesList
            selectedItems={selectedItems}
            missingPermissionsItemsArray={missingPermissionsItemsArray}
          />
        </Box>
      </Grid>
      <Divider />
    </>
  );
};

ShareFileHeader.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  selectedSharingOption: PropTypes.number.isRequired,
  onChangeSharingOption: PropTypes.func.isRequired,
  onPrivate: PropTypes.func.isRequired,
  missingPermissionsItemsArray: PropTypes.array
};

export default ShareFileHeader;
