import FileSpecificIcon from './FileSpecificIcon/FileSpecificIcon';
import React from 'react';
import { Box } from '@mui/system';
import { Link, Stack, Typography } from '@mui/material';
import GifMessage from '../privateMessages/components/GifMessage';
import AttachmentIcon from '@mui/icons-material/Attachment';

export const isGif = (text) => {
  return text.substr(text.length - 4) === '.gif';
};

export const convertMessage = (text, filesWithPermission = []) => {
  let words = text.split(' ');
  const response = words.map((word, index) => {
    if (isGif(word)) {
      return <GifMessage src={word} key={word} />;
    }
    if (isDiskLink(word)) {
      const startIdx = word.indexOf('?name=') + 6;
      const endIdx = word.indexOf('&mimeType=');

      const mimeIdx = word.indexOf('&mimeType=') + 10;
      const mimeType = word.slice(mimeIdx);
      if (
        filesWithPermission.find(
          (file) =>
            word ===
            `${file.fileUrl}?name=${encodeURIComponent(file.name)}&mimeType=${encodeURIComponent(file.mimeType)}`
        )
      ) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={word + index}>
            <FileSpecificIcon style={{ fontSize: 32, marginRight: 5 }} type={decodeURIComponent(mimeType)} />
            <Link href={word}>{decodeURIComponent(word.slice(startIdx, endIdx))}</Link>
          </Box>
        );
      } else
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={word + index}>
            <FileSpecificIcon style={{ fontSize: 32, marginRight: 5 }} type={decodeURIComponent(mimeType)} />
            <Typography>Nie posiadasz uprawnień do wyświetlenia tego pliku.</Typography>
          </Box>
        );
    }
    if (isLink(word)) {
      return (
        <Link href={word} target="_blank">
          {word}
        </Link>
      );
    }
    return ' ' + word;
  });
  return response;
};

export const convertThreadLastMessage = (text) => {
  let message = '';
  if (text) {
    let words = text.split(' ');
    message = words.map((word, index) => {
      if (isGif(word))
        return (
          <Stack direction={'row'} key={word + index}>
            <AttachmentIcon fontSize="small" />
            <Typography sx={{ fontSize: 14 }}>Przesłano GIF.</Typography>
          </Stack>
        );
      if (isDiskLink(word))
        return (
          <Stack direction={'row'} key={word + index}>
            <AttachmentIcon fontSize="small" />
            <Typography sx={{ fontSize: 14 }}>Przesłano Plik.</Typography>
          </Stack>
        );
      return ' ' + word;
    });
  }
  return message;
};

export const isDiskLink = (text) => {
  return (
    text.trim().slice(0, 7) === 'https:/' &&
    text.includes('?name=') &&
    text.includes('&mimeType=') &&
    text.includes('/virtualDisc/')
  );
};
const isLink = (text) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d,-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~,+]*)*(\\?[;&a-z\\d%_.~,+=-]*)?(\\#[-a-z\\d_,]*)?$',
    'i'
    // '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
    // 'i'
  );
  return !!urlPattern.test(text);
};
