import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import NumberingSchemeForm from '../forms/NumberingSchemeForm';
import { NumberingSchemeModelValidator } from '../../../../models/configuration/numberingSchemes/NumberingSchemeModelsValidators';

class NumberingSchemeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheme: this.props.scheme,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const scheme = Object.assign({}, this.state.scheme);
    scheme[field] = event.target.value;
    return this.setState({ scheme });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header={this.state.scheme.id ? `Edycja numeracji ${this.state.scheme.name}` : 'Nowa numeracja'}
        onSave={() => onSave(this.state.scheme)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new NumberingSchemeModelValidator().validate(this.state.scheme)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.numberingSchemesUi">
        <NumberingSchemeForm scheme={this.state.scheme} onChange={this.handleChange} errors={this.state.errors} />
      </DialogForm>
    );
  }
}

NumberingSchemeDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  scheme: PropTypes.object
};

export default NumberingSchemeDialog;
