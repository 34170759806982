import { Stack } from '@mui/material';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import { DietDetailsCard } from './DietDetailsCard';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import { DietChildrenCard } from './DietChildrenCard';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import { deleteDietAsync, updateDietAsync } from '../../actions/dietActions';

export const DietDetailsPage = () => {
  const diet = useSelector((state) => state.dietDetails);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCancel = useCallback(() => {
    browserHistory.push(routePaths.diets);
  }, []);

  const handleSave = useCallback(
    async (updatedDiet) => {
      try {
        setIsLoading(true);

        const parsedDiet = {
          id: updatedDiet.id,
          name: updatedDiet.name,
          mealIds: updatedDiet.meals.map((meal) => meal.id)
        };

        await dispatch(updateDietAsync(parsedDiet));
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      await dispatch(deleteDietAsync(diet.id));
      browserHistory.push(routePaths.diets);
    } finally {
      setIsDeleting(false);
    }
  }, [diet.id, dispatch]);

  return (
    <LoadingRenderWrapper>
      <Stack spacing={2} sx={{ mb: 2 }}>
        <PageHeaderText title="Szczegóły diety" titleIcon={<DinnerDiningOutlinedIcon />} />
        <DietDetailsCard
          diet={diet}
          title={diet.name}
          onCancel={handleCancel}
          handleSave={handleSave}
          isLoading={isLoading}
        />
        <DietChildrenCard diet={diet} />
        <ActionConfirmationButton
          actionLabel="usuń dietę"
          confirmationText="Czy na pewno chcesz usunąć dietę?"
          confirmationTitle="Usuń dietę"
          confirmLabel="usuń"
          variant="outlined"
          isLoading={isDeleting}
          onConfirm={handleDelete}
          onCancel={() => {}}
        />
      </Stack>
    </LoadingRenderWrapper>
  );
};
