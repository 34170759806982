import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function psychologicalSchemesUIReducer(state = initialState.psychologicalSchemesUI, action) {
  switch (action.type) {
    case types.PSYCHOLOGICAL_SCHEMES_START: {
      return UiModel.start();
    }
    case types.EDIT_PSYCHOLOGICAL_SCHEMES_SUBMIT:
    case types.ADD_PSYCHOLOGICAL_SCHEMES_SUBMIT: {
      return UiModel.submit();
    }
    case types.EDIT_PSYCHOLOGICAL_SCHEMES:
    case types.ADD_PSYCHOLOGICAL_SCHEMES: {
      return UiModel.success();
    }
    case types.EDIT_PSYCHOLOGICAL_SCHEMES_FAILURE:
    case types.ADD_PSYCHOLOGICAL_SCHEMES_FAILURE: {
      return UiModel.failure();
    }
    case types.PSYCHOLOGICAL_SCHEMES_CANCEL: {
      return UiModel.cancel();
    }
    default:
      return state;
  }
}
