export const ChargingDeductionModes = Object.freeze({
  reduceByAmount: {
    value: 'reduceBy',
    name: 'obniża kwotę płatności o',
    inRowName: 'obniża kwotę płatności za każdy kolejny dzień nieobecności o'
  },
  reduceByPercent: {
    value: 'reduceByPercent',
    name: 'obniża kwotę płatności o %',
    inRowName: 'obniża kwotę płatności za każdy kolejny dzień nieobecności o %'
  },
  setTo: { value: 'setTo', name: 'ustawia kwotę płatności na', inRowName: 'ustawia kwotę płatności na' }
});
