import BaseModel from '../../BaseModel';
import { AttributeDefinitionModel } from './AttributeDefinitionModel';

/*eslint no-use-before-define: 0*/
export class AttributeSetModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.objectType = '';
    this.attributes = [];
  }

  onAssignment(assigned) {
    super.onAssignment(assigned);
    /* eslint no-param-reassign: 'off' */
    assigned.attributes = assigned.attributes.map((attribute) => new AttributeDefinitionModel().assign(attribute));
  }
}
