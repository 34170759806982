import galleryApi from '../api/GalleryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import * as Status from '../constants/status';

export const loadingTypes = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  FETCH: 'FETCH'
};

export function createAlbum(albumData) {
  return (dispatch) => {
    dispatch({ type: types.GALLERY_LOADING, target: loadingTypes.CREATE });
    return galleryApi
      .createAlbum(albumData)
      .then((newAlbum) => {
        dispatch({ type: types.GALLERY_CREATED_ALBUM, newAlbum });
        dispatch({ type: types.GALLERY_LOADING_SUCCESS });
        dispatch(notificationActions.showSuccess(`Utworzono nowy album o nazwie ${albumData.name}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się dodać albumu'));
        dispatch({ type: types.GALLERY_LOADING_RESET });
        logger.error(error);
      });
  };
}

export function fetchAlbums() {
  return (dispatch) => {
    return galleryApi
      .getAlbums()
      .then((albums) => dispatch({ type: types.GALLERY_FETCH_ALBUMS, albums }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy albumów'));
        logger.error(error);
      });
  };
}

export function updateAlbum(albumData, oldAlbum) {
  return (dispatch) => {
    dispatch({ type: types.GALLERY_LOADING, target: loadingTypes.UPDATE });
    return galleryApi
      .updateAlbum(albumData)
      .then((updatedAlbum) => {
        dispatch({ type: types.GALLERY_UPDATED_ALBUM, updatedAlbum });
        dispatch({ type: types.GALLERY_LOADING_SUCCESS });
        dispatch(notificationActions.showSuccess(`Zaaktualizowano album o nazwie ${oldAlbum.name}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się edytować albumu'));
        dispatch({ type: types.GALLERY_LOADING_RESET });
        logger.error(error);
      });
  };
}

export function deleteAlbum({ id, name }) {
  return (dispatch) => {
    dispatch({ type: types.GALLERY_LOADING, target: loadingTypes.DELETE });
    return galleryApi
      .deleteAlbum(id)
      .then(() => {
        dispatch({ type: types.GALLERY_DELETED_ALBUM, albumId: id });
        dispatch({ type: types.GALLERY_LOADING_SUCCESS });
        dispatch(notificationActions.showSuccess(`Usunięto album o nazwie ${name} `));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się usunąć albumu'));
        dispatch({ type: types.GALLERY_LOADING_RESET });
        logger.error(error);
      });
  };
}

export function addMultimedia(uuid, album, newMultimedia, dateFrom, dateTo, sortType, fetchCount, status) {
  return (dispatch, getState) => {
    return galleryApi
      .addMultimedia({ albumId: album.id, ids: newMultimedia.map((item) => item.id) })
      .then(() => {
        const { gallery } = getState();
        dispatch({
          type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE,
          payload: { uuid, data: { status } }
        });
        if (album.id === gallery.multimedia.albumId) {
          dispatch({
            type: types.GALLERY_ADD_MULTIMEDIA,
            payload: {
              newMultimedia,
              dateFrom,
              dateTo,
              sortType,
              fetchCount
            }
          });
        }
        dispatch({ type: types.GALLERY_UPDATED_ALBUM, updatedAlbum: album });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się dodać multimediów do albumu'));
        dispatch({
          type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE,
          payload: {
            data: {
              status: Status.ERROR,
              errorMessage: 'Nie udało się dodać multimediów do albumu'
            }
          }
        });
        logger.error(error);
      });
  };
}

export function fetchMultimedia(albumId, params) {
  return async (dispatch) => {
    dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING, target: loadingTypes.FETCH });
    try {
      const multimedia = await galleryApi.getMultimedia(albumId, params);
      dispatch({ type: types.GALLERY_FETCH_MULTIMEDIA, multimedia, albumId });
      dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING_SUCCESS });
    } catch (error) {
      dispatch(notificationActions.showError('Nie udało się pobrać multimediów'));
      dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING_RESET });
      logger.error(error);
    }
  };
}

export function moveMultimedia(fromAlbum, toAlbum, multimediaList) {
  return (dispatch) => {
    dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING, target: loadingTypes.UPDATE });
    return galleryApi
      .updateMultimedia({
        fromAlbumId: fromAlbum.id,
        toAlbumId: toAlbum.id,
        ids: multimediaList
      })
      .then(() => {
        dispatch({ type: types.GALLERY_MOVED_MULTIMEDIA, multimediaList });
        dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING_SUCCESS });
        dispatch(notificationActions.showSuccess(`Przeniesiono multimedia do albumu o nazwie ${toAlbum.name}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się przenieści multimediów'));
        dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING_RESET });
        logger.error(error);
      });
  };
}

export function deleteMultimedia(albumId, multimediaList) {
  return (dispatch) => {
    dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING, target: loadingTypes.DELETE });
    return galleryApi
      .deleteMultimedia(albumId, multimediaList)
      .then(() => {
        dispatch({ type: types.GALLERY_DELETED_MULTIMEDIA, multimediaList });
        dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING_SUCCESS });
        dispatch(
          notificationActions.showSuccess(
            multimediaList.length > 1 ? `Usunięto wybrane multimedia` : `Usunięto wybrane zdjęcie`
          )
        );
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało usunąć się multimediów'));
        dispatch({ type: types.GALLERY_MULTIMEDIA_LOADING_RESET });
        logger.error(error);
      });
  };
}
