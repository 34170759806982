'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, ListItem, ListItemText, ListItemAvatar, Box, Typography, Button } from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';
import * as childrenActions from '../../../actions/childrenActions';
import FilterBar from '../../common/FilterBar';
import EmptyState from '../../common/EmptyState';
import SimpleDialog from '../../common/Dialog';

class LegalGuardiansListDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredLegalGuardians: props.legalGuardians
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadAllLegalGuardiansAsync();
  }

  componentDidUpdate(prevProps) {
    if (this.props.legalGuardians !== prevProps.legalGuardians) {
      this.handleFilterChange(prevProps.legalGuardians);
    }
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredLegalGuardians: filteredList
    });
  }

  renderEmptyState() {
    return <EmptyState message="Brak opiekunów do przypisania" />;
  }

  renderList() {
    return (
      <Box>
        <FilterBar
          contrast
          itemsToFilter={this.props.legalGuardians}
          onFilter={this.handleFilterChange}
          filter={(guardian, keyword) =>
            (guardian.firstName.toLowerCase() + ' ' + guardian.lastName.toLowerCase()).includes(keyword) ||
            (guardian.lastName.toLowerCase() + ' ' + guardian.firstName.toLowerCase()).includes(keyword)
          }
        />
        <List>
          {this.state.filteredLegalGuardians.map((guardian) => {
            return (
              <ListItem
                sx={{ '&:hover': { backgroundColor: (theme) => theme.palette.background.hover } }}
                key={guardian.id}
                onClick={() => this.props.onSelect(guardian)}
                component="li"
                aria-label={`Przypisz opiekuna prawnego ${guardian.firstName} ${guardian.lastName} `}>
                <ListItemAvatar>
                  <PersonAvatar
                    initials
                    alt={guardian.firstName}
                    url={guardian.photoUrl}
                    firstName={guardian.firstName}
                    lastName={guardian.lastName}
                  />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mb: 1 }}>
                      {guardian.firstName} {guardian.lastName}
                      <Typography
                        component="span"
                        sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, display: 'block' }}>
                        {guardian.email}
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', sm: 'row' } }}>
                      <Typography sx={{ display: { xs: 'block', sm: 'inline' } }}>
                        Numer dokumentu
                        <Typography
                          component="span"
                          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mx: 1 }}>
                          {guardian.documentNumber || 'brak'}
                        </Typography>
                      </Typography>
                      <Typography sx={{ display: { xs: 'block', sm: 'inline' } }}>
                        Numer telefonu
                        <Typography
                          component="span"
                          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mx: 1 }}>
                          {guardian.phoneNumber || 'brak'}
                        </Typography>
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            );
          })}
          {this.state.filteredLegalGuardians.length === 0 && <EmptyState message="Brak wyników" contrast />}
        </List>
      </Box>
    );
  }

  render() {
    const actions = [
      <Button variant="contained" aria-label="Anuluj" key="1" onClick={this.props.onClose}>
        Anuluj
      </Button>
    ];

    return (
      <SimpleDialog
        title="Przypisz opiekuna prawnego"
        modal={false}
        open={this.props.open}
        actions={actions}
        onRequestClose={this.props.onClose}>
        {this.props.legalGuardians.length === 0 ? this.renderEmptyState() : this.renderList()}
      </SimpleDialog>
    );
  }
}

LegalGuardiansListDialog.propTypes = {
  ignoredLegalGuardians: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  legalGuardians: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    legalGuardians: state.legalGuardians.filter(
      (guardian) => !ownProps.ignoredLegalGuardians.some((id) => id === guardian.id)
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(childrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalGuardiansListDialog);
