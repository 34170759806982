import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import StaffCalendarContainer from './StaffCalendarContainer';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box } from '@mui/material';

const StaffCalendarPage = (props) => {
  const { params } = props;
  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Kalendarz wydarzeń - pracownicy" titleIcon={<EventOutlinedIcon />} />
        <ContentWrapper>
          <StaffCalendarContainer month={params.month} year={params.year} />
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

StaffCalendarPage.propTypes = {
  params: PropTypes.object.isRequired
};

export default withRouter(StaffCalendarPage);
