import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

const StackRow = ({ children, ...props }) => {
  return (
    <Stack direction="row" {...props}>
      {children}
    </Stack>
  );
};

StackRow.propTypes = {
  children: PropTypes.node.isRequired
};

export default StackRow;
