import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Box, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import { getConstantByValue } from '../../../utils/getConstantByValue';
import staffMemberRoles from '../../../constants/staffMemberRoles';
import normalUserRoleNames from '../../../constants/userRoleNames';
import reducedUserRoleNames from '../../../constants/reducedUserRoleNames';
import Avatar from '@mui/material/Avatar';
import { makeStyles, useTheme } from '@mui/styles';
import { AvatarGroup } from '@mui/lab';
import { create } from '../../../utils/AvatarContentGenerator';
import GroupsIcon from '@mui/icons-material/Groups';
import { convertThreadLastMessage } from '../../common/messageFunctions';

const useStyles = makeStyles((theme) => ({
  unread: {
    fontWeight: 900,
    color: theme.palette.color.color22
  },
  secondaryText: {
    fontSize: 14,
    lineHeight: '18px',
    height: '18px',
    margin: '0px',
    color: theme.palette.color.color22,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));
const ThreadMultiHeader = ({
  thread,
  onSelect,
  sx,
  showLastMessage = false,
  nadarzyn,
  user,
  showRoles,
  isBold,
  groupThread
}) => {
  const theme = useTheme();

  const classes = useStyles();
  const userRoleNames = nadarzyn ? reducedUserRoleNames : normalUserRoleNames;

  const getRole = (role) => {
    return getConstantByValue(userRoleNames, role) || getConstantByValue(staffMemberRoles, role) || { name: '' };
  };
  const participant1 = thread.participants[0] || {};
  const participant2 = thread.participants[1] || {};
  return (
    <ListItem
      sx={{ maxWidth: '100%', pl: 1, py: 0.5 }}
      button={!!onSelect}
      component="li"
      key={thread.threadId}
      onClick={() => onSelect && onSelect(thread)}
      aria-label={`Wybierz rozmowę pomiędzy ${participant1.fullName} a ${participant2.fullName}`}>
      <ListItemAvatar sx={{ mr: 0.5 }}>
        <Tooltip placement="left" title={thread.participants.map((r) => r.fullName).join(', ')}>
          <Badge badgeContent={!thread.readByMe && showLastMessage ? '!' : null} color={'primary'}>
            {thread.participants.length === 2 ? (
              <AvatarGroup max={2} total={2} spacing={25} sx={{ width: 60 }}>
                <Avatar
                  src={participant2.photoUrl}
                  sx={{
                    background: (theme) =>
                      theme.palette.color.wcag ||
                      create().generateColorFromName(
                        participant2.fullName.split(' ')[0] + ' ' + participant2.fullName.split(' ')[1][0]
                      )
                  }}>
                  {`${
                    participant2.fullName
                      .split(' ')
                      .filter((s) => s)
                      .join(' ')
                      .split(' ')[0][0]
                  }${
                    participant2.fullName
                      .split(' ')
                      .filter((s) => s)
                      .join(' ')
                      .split(' ')[1][0]
                  }`}
                </Avatar>
                <Avatar
                  src={participant1.photoUrl}
                  sx={{
                    background: (theme) =>
                      theme.palette.color.wcag ||
                      create().generateColorFromName(
                        participant1.fullName.split(' ')[0] + ' ' + participant1.fullName.split(' ')[1][0]
                      )
                  }}>
                  {`${
                    participant1.fullName
                      .split(' ')
                      .filter((s) => s)
                      .join(' ')
                      .split(' ')[0][0]
                  }${
                    participant1.fullName
                      .split(' ')
                      .filter((s) => s)
                      .join(' ')
                      .split(' ')[1][0]
                  }`}
                </Avatar>
              </AvatarGroup>
            ) : (
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  border: (theme) => `2px solid ${theme.palette.color.wcag || theme.palette.color.color9}`,

                  background: (theme) => theme.palette.color.primary
                }}>
                <GroupsIcon sx={{ color: (theme) => theme.palette.color.wcag || theme.palette.color.color9 }} />
              </Avatar>
            )}
          </Badge>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        sx={{ maxWidth: '100%' }}
        disableTypography
        primary={
          <Box sx={{ maxWidth: '100%', pr: 2 }}>
            {groupThread ? (
              <>
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    wordWrap: 'break-word',
                    color: theme.palette.text.primary,
                    fontWeight:
                      (thread.readByMe || thread.lastMessage.senderId === user?.userId) && !isBold
                        ? theme.typography.fontWeightRegular
                        : theme.typography.fontWeightBlack
                  }}>
                  {thread.participants.map((p) => p.fullName.split(' ')[0]).join(', ')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: theme.palette.text.primary,
                    fontWeight:
                      (thread.readByMe || thread.lastMessage.senderId === user?.userId) && !isBold
                        ? theme.typography.fontWeightRegular
                        : theme.typography.fontWeightBlack
                  }}>
                  {thread.participants.length} uczestników
                </Typography>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex' }}>
                  {' '}
                  <Typography
                    sx={{
                      ...sx,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      fontWeight: (theme) =>
                        showRoles ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
                      color: (theme) => theme.palette.text.primary
                    }}>
                    {participant1.fullName}
                  </Typography>
                  {showRoles && (
                    <Typography
                      sx={{
                        ...sx,
                        ml: 1,
                        opacity: 0.5,
                        display: { xs: 'none', sm: 'block' },
                        color: (theme) => theme.palette.text.primary
                      }}>
                      {getRole(participant1.role).name}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      ...sx,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      color: (theme) => theme.palette.text.primary,
                      fontWeight: (theme) =>
                        showRoles ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium
                    }}>
                    {participant2.fullName}
                  </Typography>
                  {showRoles && (
                    <Typography
                      sx={{
                        ...sx,
                        color: (theme) => theme.palette.text.primary,
                        ml: 1,
                        opacity: 0.5,
                        display: { xs: 'none', sm: 'block' }
                      }}>
                      {getRole(participant2.role).name}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        }
        secondary={
          showLastMessage && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box className={`last-message${!thread.readByMe ? ` ` + classes.unread : ''} ` + classes.secondaryText}>
                {thread?.lastMessage && thread?.lastMessage?.content
                  ? convertThreadLastMessage(thread?.lastMessage?.content.text)
                  : convertThreadLastMessage(thread?.lastMessageContent?.text)}
              </Box>
            </Box>
          )
        }
      />
    </ListItem>
  );
};

ThreadMultiHeader.propTypes = {
  thread: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  showRoles: PropTypes.bool
};

export default ThreadMultiHeader;
