import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as daycareActions from '../../actions/daycareActions';
import * as childrenActions from '../../actions/childrenActions';
import ChildrenList from './ChildrenList';
import EmptyState from '../common/EmptyState';
import FilterBar from '../common/FilterBar';
import moment from 'moment';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import _ from 'lodash';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import DaycareIcon from '@mui/icons-material/ChildCare';
import GroupSwitch from '../common/groups/GroupSwitch';
import * as digitalDiaryActions from '../../actions/digitalDiaryActions';
import { CircularProgress, Grid, Paper, Stack } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';

class DaycarePage extends React.Component {
  constructor(props) {
    super(props);

    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.state = {
      selectedFilter: [],
      filteredPupils: [],
      isLoading: false
    };
    this.props.digitalDiaryEvents.changeChoosenGroup(this.props.choosenGroup);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    if (this.props.choosenGroup.id) {
      this.handleGroupChange({ id: this.props.choosenGroup.id });
    }
    this.props.digitalDiaryEvents.changeChoosenGroup(this.props.choosenGroup);
    this.props.childrenActions.loadChildrenAsync().then(() => {
      this.setState({
        filteredPupils: [...this.props.pupils],
        isLoading: false
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.state.selectedFilter && prevProps.groups.length) {
      this.handleGroupChange(prevProps.groups[0].id);
    }
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredPupils: filteredList
    });
  }

  handleGroupChange(value) {
    this.setState({ isLoading: true });
    this.props.digitalDiaryEvents.changeChoosenGroup(value);
    this.setState({ selectedFilter: value }, () =>
      this.props.actions.loadGroupDaycareSheet(value.id, moment()).then(() => this.setState({ isLoading: false }))
    );
  }

  getJournalWithPupils(childrenAttendance, pupils) {
    return childrenAttendance
      .map((a) => {
        const child = pupils.find((c) => c.id === a.child.id);
        return child
          ? Object.assign({}, a, {
              childId: child.id,
              firstName: child.firstName,
              lastName: child.lastName,
              photoUrl: child.photoUrl
            })
          : null;
      })
      .filter((a) => a !== null)
      .sort((a, b) => {
        return a.lastName.localeCompare(b.lastName, 'pl');
      });
  }

  render() {
    const daycareEntries = this.getJournalWithPupils(this.props.daycareEntries, this.state.filteredPupils);

    return (
      <LoadingRenderWrapper>
        <Stack sx={{ flexWrap: 'wrap' }}>
          <PageHeaderText
            title="Aktywność dzieci"
            additionalInfo={this.props.date ? moment(this.props.date).format('DD.MM.YYYY') : null}
            titleIcon={<DaycareIcon />}
          />
          <ContentWrapper>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <FilterBar
                  sx={{ my: 3 }}
                  itemsToFilter={this.props.pupils}
                  onFilter={this.handleFilterChange}
                  filter={(child, keyword) =>
                    (child.lastName + ' ' + child.firstName).toLowerCase().includes(keyword) ||
                    (child.firstName + ' ' + child.lastName).toLowerCase().includes(keyword)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GroupSwitch onGroupChange={this.handleGroupChange} showArchivedGroups={false} />
              </Grid>
            </Grid>
            {this.state.isLoading && (
              <Paper
                sx={{
                  mb: 4,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  py: 3,
                  px: 2
                }}>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress size={30} left={10} top={0} sx={{ display: 'inline-block' }} />
                </Stack>
              </Paper>
            )}
            {this.props.daycareEntries.length === 0 && !this.state.isLoading && (
              <EmptyState isPaper contrast message="Do wybranej grupy nie zostały jeszcze przypisane dzieci" />
            )}
            {!this.state.isLoading && this.props.daycareEntries.length > 0 && (
              <ChildrenList
                daycareEntries={daycareEntries}
                ui={this.props.ui}
                onSave={(entry) => this.props.actions.updateDaycareEntry(entry)}
                propertiesDefinition={this.props.daycareConfiguration.propertiesDefinition}
              />
            )}
          </ContentWrapper>
        </Stack>
      </LoadingRenderWrapper>
    );
  }
}

DaycarePage.propTypes = {
  defaultGroupId: PropTypes.string,
  choosenGroup: PropTypes.object,
  digitalDiaryEvents: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  daycareEntries: PropTypes.array.isRequired,
  pupils: PropTypes.array.isRequired,
  date: PropTypes.string,
  actions: PropTypes.object.isRequired,
  childrenActions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  daycareConfiguration: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const loggedInStaffMember = state.staffMembers.find((s) => s.id === state.auth.userId);
  const staffMemberGroup = loggedInStaffMember ? _.head(loggedInStaffMember.groups) : '';
  const getDefaultGroup = () => (state.groups.length ? state.groups[0].id : '');

  return {
    groups: state.groups,
    defaultGroupId: staffMemberGroup ? staffMemberGroup.id : getDefaultGroup(),
    date: state.daycare.date,
    daycareEntries: state.daycare.entries,
    pupils: state.children,
    ui: state.daycareUi,
    daycareConfiguration: state.configuration.daycare,
    choosenGroup: state.digitalDiary.choosenGroup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(daycareActions, dispatch),
    childrenActions: bindActionCreators(childrenActions, dispatch),
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaycarePage);
