import PropTypes from 'prop-types';
import React from 'react';
import ManyGroupsSelector from './common/ManyGroupsPickerTemplate';
import SelectField from '../../common/SelectField';
import { Box, MenuItem } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { ReportTypes } from '../../../constants/reportTypes';

const AttributeSetAttendanceReport = ({ attributeSets, attributeSetId, onChange, groups }) => {
  return (
    <Box>
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.attributeSetAttendanceReport.name}`}>
        <SelectField
          contrast
          floatingLabelText="Zestaw atrybutów"
          sx={{ width: 'auto', overflow: 'hidden', mb: 1 }}
          id="attributeSetId"
          value={attributeSetId}
          onChange={(event) => {
            onChange({ target: { name: 'attributeSetId', value: event.target.value } });
          }}>
          {attributeSets &&
            attributeSets.map((attributeSet) => {
              return (
                <MenuItem key={attributeSet.id} value={attributeSet.id}>
                  {attributeSet.name}
                </MenuItem>
              );
            })}
        </SelectField>
      </FieldsetWrapper>
      <ManyGroupsSelector groups={groups} onChange={onChange} />
    </Box>
  );
};

AttributeSetAttendanceReport.propTypes = {
  attributeSetId: PropTypes.string.isRequired,
  attributeSets: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default AttributeSetAttendanceReport;
