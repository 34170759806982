/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ActivityArchiveDetailsModel } from '../models/activities/ActivityArchiveModels';

export default function activityArchiveDetailsReducer(state = initialState.archives.activityDetails, action) {
  switch (action.type) {
    case types.LOAD_ACTIVITY_ARCHIVE_DETAILS_SUCCESS: {
      return new ActivityArchiveDetailsModel().assign(action.activityDetails);
    }
    default:
      return state;
  }
}
