/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { GroupArchiveModel } from '../models/groups/GroupArchiveModels';

export default function groupsArchiveReducer(state = initialState.archives.groups, action) {
  switch (action.type) {
    case types.LOAD_GROUPS_ARCHIVE_SUCCESS: {
      return action.groups.map((group) => new GroupArchiveModel().assign(group));
    }
    default:
      return state;
  }
}
