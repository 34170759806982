import PropTypes from 'prop-types';
import React from 'react';
import ColorPicker from '../../common/ColorPicker';
import { avatarColorPaletteArray } from '../../../constants/avatarColorPalette';
import { create } from '../../../utils/AvatarContentGenerator';
import TextField from '../../common/TextField';
import { Box, FormControlLabel, ListSubheader, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const useStyles = makeStyles({
  MuiFormControlLabel: {
    fontSize: 14
  }
});

const ActivityDetailsForm = ({ activity, onChange, errors }) => {
  const classes = useStyles();
  return (
    <Box>
      <FieldsetWrapper legend="Pola dodania nowych zajęć">
        <TextField
          hintText="Nazwa zajęć"
          floatingLabelText="Nazwa zajęć*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="name"
          value={activity.name}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.name}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="caption">Kolor zajęć</Typography>
            <ColorPicker
              palette={avatarColorPaletteArray}
              defaultColor={activity.color || create().generateColorFromName(activity.name)}
              onChange={(color) => onChange({ target: { name: 'color', value: color } })}
            />
          </Box>
          <FormControlLabel
            label={
              <Typography className={classes.MuiFormControlLabel}>
                Zezwól na składanie wniosków przez opiekuna prawnego
              </Typography>
            }
            sx={{ mt: 5, fontSize: 14 }}
            className={classes.MuiFormControlLabel}
            control={
              <Switch
                sx={{ transform: 'scale(1.7)', mx: 2 }}
                checked={activity.availableForLegalGuardians}
                onChange={(e) => onChange({ target: { name: 'availableForLegalGuardians', value: e.target.checked } })}
                name="availableForLegalGuardians"
              />
            }
          />
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

ActivityDetailsForm.propTypes = {
  activity: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ActivityDetailsForm;
