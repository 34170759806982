import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import * as GroupsAndActivitiesTypes from '../../../../constants/groupsAndActivitiesTypes';
import SingleMemberChip from '../../../gallery/Components/Albums/SingleMemberChip';
import RichMultiSelectField from '../../../formik/RichMultiSelectField/RichMultiSelectField';

const Filter = (props) => {
  const { itemsToFilter, onFilter, onAssignedChildrenFilterChange, showOnlyLegalGuardiansWithoutChildren } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredActive, setFilteredActive] = useState(itemsToFilter);
  const [onlyLegalGuardiansWithoutChildren, setOnlyLegalGuardiansWithoutChildren] = useState(
    showOnlyLegalGuardiansWithoutChildren
  );
  const groups = useSelector((state) => state.groups);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const filters = { all: '', active: 'active', inactive: 'inactive' };

  const handleChangeLegalGuardiansWithoutChildren = () => {
    setOnlyLegalGuardiansWithoutChildren((prev) => !prev);
    onAssignedChildrenFilterChange(!onlyLegalGuardiansWithoutChildren);
    setFilteredGroups([]);
  };

  useEffect(() => {
    if (filteredActive.length > 0 && filteredGroups.length > 0) {
      const legalGuardians = filteredActive.filter((item) =>
        item.children.some((child) => filteredGroups.some((group) => group.id === child.groupId))
      );
      onFilter(legalGuardians);
    } else onFilter(filteredActive);
  }, [filteredActive, filteredGroups]);

  const handleChangeActive = (value) => {
    setFilter(value);
    if (value === filters.all) {
      setFilteredActive(itemsToFilter);
    }
    if (value === filters.active) {
      setFilteredActive(itemsToFilter.filter((item) => item.userAccountActivated === true));
    }
    if (value === filters.inactive) {
      setFilteredActive(itemsToFilter.filter((item) => item.userAccountActivated === false));
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
        filtruj
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <FormLabel component="legend">
              <Typography sx={{ color: (theme) => theme.palette.color.contrast }}>Opcje wyświetlania:</Typography>
            </FormLabel>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row' }}>
              <RadioGroup
                aria-label="Sortowanie plików"
                name="sort"
                value={filter}
                sx={{ p: 1 }}
                onChange={(e) => handleChangeActive(e.target.value)}>
                <FormControlLabel value="" control={<Radio />} label="Wszyscy" />
                <FormControlLabel value="active" control={<Radio />} label="Aktywni" />
                <FormControlLabel value="inactive" control={<Radio />} label="Nieaktywni" />
              </RadioGroup>
            </FormControl>

            <FormControlLabel
              label="Pokaż tylko opiekunów bez przypisanych dzieci"
              control={
                <Switch
                  checked={onlyLegalGuardiansWithoutChildren}
                  onChange={handleChangeLegalGuardiansWithoutChildren}
                />
              }
            />
          </Box>
          {!onlyLegalGuardiansWithoutChildren && (
            <RichMultiSelectField
              sx={{ width: 350, overflow: 'hidden' }}
              value={filteredGroups}
              onChange={(newValue) => {
                setFilteredGroups(newValue);
              }}
              onDelete={(newValue) => {
                setFilteredGroups(newValue);
              }}
              options={groups}
              name="groups"
              label="Wybierz grupy"
              type={GroupsAndActivitiesTypes.GROUP}
              selectElement={<SingleMemberChip />}
              chipElement={<SingleMemberChip />}
              isSelectAll={true}
            />
          )}
        </Box>
      </Popover>
    </>
  );
};

Filter.propTypes = {
  itemsToFilter: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  onAssignedChildrenFilterChange: PropTypes.func.isRequired,
  showOnlyLegalGuardiansWithoutChildren: PropTypes.bool.isRequired,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number.isRequired
};

export default Filter;
