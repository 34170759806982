import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from '../../common/DatePicker';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

const CloneEventsDialog = ({
  onCancel,
  onSave,
  isOpen,
  isProcessing,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate
}) => {
  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Klonowanie wydarzeń</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sx={{ px: 4, py: 2 }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                color: (theme) => theme.palette.text.primary
              }}>
              Wybierz przedział do którego chcesz sklonować wydarzenia z bieżącego dnia
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ p: 2, pb: 0 }}>
            <DatePicker
              sx={{
                display: 'flex',

                my: 0,
                mx: 'auto',
                justifyContent: 'space-between'
              }}
              hintText="Od"
              floatingLabelText="Od"
              floatingLabelFixed={false}
              value={startDate ? new Date(startDate) : undefined}
              onChange={(e, value) => onChangeStartDate(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ p: 2, pb: 0 }}>
            <DatePicker
              sx={{
                display: 'flex',

                my: 0,
                mx: 'auto',
                justifyContent: 'space-between'
              }}
              hintText="Do"
              floatingLabelText="Do"
              floatingLabelFixed={false}
              value={endDate ? new Date(endDate) : undefined}
              onChange={(e, value) => onChangeEndDate(value)}
            />
          </Grid>
          {startDate > endDate && (
            <Grid item xs={12} sx={{ px: 2, pt: 1 }}>
              <Typography align="justify" sx={{ fontSize: 12, color: (theme) => theme.palette.background.error }}>
                Data, do której wydarzenie ma być sklonowane powinna być późniejsza niż data, od której wydarzenie ma
                być sklonowane
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', py: 2, px: 1 }}>
        <Box>
          <Button variant="outlinedContrast" onClick={onCancel} sx={{ mr: 1 }}>
            Anuluj
          </Button>
          <Button
            variant="contained"
            aria-label="Klonuj wydarzenia"
            onClick={onSave}
            disabled={isProcessing || startDate > endDate}>
            Klonuj wydarzenia
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CloneEventsDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  startDate: PropTypes.object.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
  onChangeEndDate: PropTypes.func.isRequired
};

export default CloneEventsDialog;
