import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const LabelValue = ({ label, value }) => {
  return (
    <Grid container sx={{ my: 1, mx: 0, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
      <Grid xs={12} md={4} lg={2}>
        <Typography>{label}:</Typography>
      </Grid>
      <Grid xs={12} md={8} lg={6}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

LabelValue.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default LabelValue;
