import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import { Box, MenuItem } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';

const ReportedAbsencesByTheHoursOfPreviousDayReport = ({ groupId, groups, year, month, hour, onChange, errors }) => {
  const possibleHours = Array.from({ length: 23 }, (_, i) => {
    const h = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
    return `${h}:00`;
  });
  possibleHours.push('23:59');

  return (
    <Box>
      <GroupAndMonthTemplate
        legend="Raport obecności zgłoszonych w poprzednim dniu"
        groupId={groupId}
        groups={groups}
        year={year}
        month={month}
        onChange={onChange}
        errors={errors}
      />
      <FieldsetWrapper legend="Godzina, do której należy zgłosić obecność w poprzednim dniu">
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden' }}
          floatingLabelText="Wybrana godzina, do której należy zgłosić obecność w poprzednim dniu"
          id="hour"
          value={hour}
          defaultValue="15:00"
          onChange={(event) => {
            onChange({ target: { name: 'hour', value: event.target.value } });
          }}>
          {possibleHours.map((h) => {
            return (
              <MenuItem key={h} value={h}>
                {h}
              </MenuItem>
            );
          })}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

ReportedAbsencesByTheHoursOfPreviousDayReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  hour: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ReportedAbsencesByTheHoursOfPreviousDayReport;
