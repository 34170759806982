import {
  LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN,
  LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS,
  LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE,
  LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN,
  LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE,
  LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS,
  CREATE_STAFF_MESSAGE_BOARD_POST_BEGIN,
  CREATE_STAFF_MESSAGE_BOARD_POST_SUCCESS,
  CREATE_STAFF_MESSAGE_BOARD_POST_FAILURE,
  UPDATE_STAFF_MESSAGE_BOARD_POST_BEGIN,
  UPDATE_STAFF_MESSAGE_BOARD_POST_SUCCESS,
  UPDATE_STAFF_MESSAGE_BOARD_POST_FAILURE,
  DELETE_STAFF_MESSAGE_BOARD_POST_BEGIN,
  DELETE_STAFF_MESSAGE_BOARD_POST_SUCCESS,
  DELETE_STAFF_MESSAGE_BOARD_POST_FAILURE,
  READALL_STAFF_MESSAGE_BOARD_START,
  READALL_STAFF_MESSAGE_BOARD_SUCCESS,
  READALL_STAFF_MESSAGE_BOARD_FAILURE,
  LOAD_UNREADED_POSTS_SUCCESS
} from '../actions/actionTypes';
import { BoardPostModel, MessageBoardModel } from '../models/messageBoard/MessageBoardModels';
import initialState from './initial';

export default (state = initialState.staffMessageBoard, action) => {
  switch (action.type) {
    case LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN: {
      return {
        ...state,
        posts: new MessageBoardModel([], 0),
        loading: true
      };
    }
    case LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS: {
      return {
        ...state,
        posts: new MessageBoardModel(action.payload.data, action.payload.totalCount),
        loading: false
      };
    }
    case LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE: {
      return {
        ...state,
        posts: new MessageBoardModel([], 0),
        loading: false
      };
    }
    case LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN: {
      return {
        ...state,
        loadingMore: true
      };
    }
    case LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS: {
      return {
        ...state,
        posts: new MessageBoardModel(action.payload.data, action.payload.totalCount),
        loadingMore: false
      };
    }
    case LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE: {
      return {
        ...state,
        posts: new MessageBoardModel([], 0),
        loadingMore: false
      };
    }
    case CREATE_STAFF_MESSAGE_BOARD_POST_BEGIN: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_STAFF_MESSAGE_BOARD_POST_SUCCESS: {
      return {
        ...state,
        posts: new MessageBoardModel([action.payload, ...state.posts.posts], +state.posts.totalCount + 1),
        loading: false
      };
    }
    case CREATE_STAFF_MESSAGE_BOARD_POST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_STAFF_MESSAGE_BOARD_POST_BEGIN: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_STAFF_MESSAGE_BOARD_POST_SUCCESS: {
      return {
        ...state,
        posts: new MessageBoardModel(
          state.posts.posts.map((post) =>
            post.id === action.payload.id ? new BoardPostModel().assign(action.payload) : post
          ),
          state.posts.totalCount
        ),
        loading: false
      };
    }
    case UPDATE_STAFF_MESSAGE_BOARD_POST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case DELETE_STAFF_MESSAGE_BOARD_POST_BEGIN: {
      return {
        ...state,
        loading: true
      };
    }
    case DELETE_STAFF_MESSAGE_BOARD_POST_SUCCESS: {
      return {
        ...state,
        posts: new MessageBoardModel(
          state.posts.posts.filter((post) => post.id !== action.payload.id),
          +state.posts.totalCount - 1
        ),
        loading: false
      };
    }
    case DELETE_STAFF_MESSAGE_BOARD_POST_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case READALL_STAFF_MESSAGE_BOARD_START: {
      return {
        ...state,
        readingAll: true
      };
    }

    case READALL_STAFF_MESSAGE_BOARD_SUCCESS: {
      return {
        ...state,
        readingAll: false,
        unreadedPost: []
      };
    }
    case READALL_STAFF_MESSAGE_BOARD_FAILURE: {
      return {
        ...state,
        readingAll: false
      };
    }

    case LOAD_UNREADED_POSTS_SUCCESS: {
      return {
        ...state,
        unreadedPosts: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
