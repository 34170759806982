import PropTypes from 'prop-types';
import React from 'react';
import ExposureIcon from '@mui/icons-material/Exposure';
import numberFormatter from '../../../utils/numberFormatter';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import TextField from '../../common/TextField';
import { Box, Grid, Typography } from '@mui/material';

const SettlementAdjustmentDetails = ({ calculation, canAdjust, onAdjustmentChange }) => {
  if (canAdjust) {
    return (
      <Grid container>
        <Grid item xs={6} sm={8} sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
          <ExposureIcon
            sx={{
              mt: 2,
              mr: 1,
              color: (theme) => theme.palette.grey[500]
            }}
          />
          <TextField
            sx={{ width: '100%' }}
            name="adjustmentReason"
            value={calculation.adjustmentReason}
            floatingLabelText="Wprowadź opis korekty"
            onChange={(e) => {
              onAdjustmentChange({
                target: {
                  schemeSnapshotId: calculation.schemeSnaphot.id,
                  name: 'adjustmentReason',
                  value: e.target.value
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box sx={{ pl: 2, pr: 1, display: 'flex', justifyContent: 'end' }}>
            <TextField
              name="adjustmentAmount"
              value={calculation.adjustmentAmount}
              floatingLabelText="Wartość korekty"
              endAdornment={
                <Typography
                  sx={{
                    fontSize: 22,
                    textTransform: 'lowercase',
                    fontWeight: (theme) => theme.typography.fontWeightFeather,
                    mb: '-1px'
                  }}>
                  &nbsp;zł
                </Typography>
              }
              onChange={(e) => {
                onAdjustmentChange({
                  target: {
                    schemeSnapshotId: calculation.schemeSnaphot.id,
                    name: 'adjustmentAmount',
                    value: e.target.value
                  }
                });
              }}
              onBlur={(e) => {
                onAdjustmentChange({
                  target: {
                    schemeSnapshotId: calculation.schemeSnaphot.id,
                    name: 'adjustmentAmount',
                    value: tryConvertToNumber(e.target.value)
                  }
                });
              }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  if (calculation.adjustmentReason || calculation.adjustmentAmount) {
    return (
      <Grid container>
        <Grid xs={6} sm={9} item>
          {calculation.adjustmentReason}
        </Grid>
        <Grid xs={6} sm={3} item>
          <Typography
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(calculation.adjustmentAmount)}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
};

SettlementAdjustmentDetails.propTypes = {
  calculation: PropTypes.object.isRequired,
  canAdjust: PropTypes.bool.isRequired,
  onAdjustmentChange: PropTypes.func
};

export default SettlementAdjustmentDetails;
