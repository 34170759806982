import ApiBase, { ROOT_URL } from './ApiBase';

class VirtualDiscApi extends ApiBase {
  static addFile(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/virtualDisc/addFile`).send(data).type('application/json')
    );
  }

  static fetchFiles() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/virtualDisc`).type('application/json'));
  }

  static getSignedUploadUrls(count) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/virtualDisc/getUploadFileUrls/${count}`).type('application/json')
    );
  }

  static deleteFile(id) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/virtualDisc/deleteFile/${id}`).type('application/json')
    );
  }

  static deleteFiles(ids) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/virtualDisc/deleteFiles`).query({ ids }).type('application/json')
    );
  }

  static shareFiles(data) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/virtualDisc/shareFiles`).send(data).type('application/json')
    );
  }

  // static publicFiles(ids) {
  //   return super.toPromise((agent) =>
  //     agent.put(`${ROOT_URL}/virtualDisc/publicFiles`).send(ids).type('application/json')
  //   );
  // }

  // static privateFiles(ids) {
  //   return super.toPromise((agent) =>
  //     agent.put(`${ROOT_URL}/virtualDisc/privateFiles`).send(ids).type('application/json')
  //   );
  // }
}

export default VirtualDiscApi;
