import TableBody from '@mui/material/TableBody';
import { getComparator, stableSort } from '../../../utils/tableSorting';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import numberFormatter from '../../../utils/numberFormatter';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { Checkbox, Typography } from '@mui/material';

const OverdueListBody = ({ overdue, page, rowsPerPage, order, orderBy, notificationList, setNotificationList }) => {
  const data = overdue.map((element) => {
    return {
      ...element,
      firstName: element.person.firstName,
      lastName: element.person.lastName,
      toPay: element.calculatedTotal - element.paidAmount
    };
  });

  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return (
            <TableRow
              key={`${row.id}`}
              sx={{
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}>
              <TableCell align="left">
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
                  {row.firstName}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
                  {row.lastName}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{row.number}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{numberFormatter.format(row.calculatedTotal - (row.paidAmount || 0))}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{ChargingReportTypes[row.dueType].name}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{moment(row.maturityDate).format('YYYY-MM-DD')}</Typography>
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={Boolean(notificationList.find((notification) => notification === row.id)) || false}
                  onChange={(e) => {
                    setNotificationList(
                      e.target.checked
                        ? [...notificationList, row.id]
                        : notificationList.filter((item) => item !== row.id)
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

OverdueListBody.propTypes = {
  overdue: PropTypes.array.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
};

export default OverdueListBody;
