import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from '../../../common/DatePicker';
import TextField from '../../../common/TextField';

const DayOffForm = ({ dayOff, onChange, errors }) => {
  return (
    <Box>
      <DatePicker
        hintText="Data"
        floatingLabelText="Data"
        floatingLabelFixed={false}
        name="date"
        disabled={true}
        onChange={(e, date) => {
          onChange({ target: { name: 'date', value: date } });
        }}
        value={new Date(dayOff.date)}
        errorText={errors.date}
      />
      <TextField
        hintText="Nazwa"
        floatingLabelText="Nazwa*"
        floatingLabelFixed={false}
        autoFocus={true}
        name="name"
        value={dayOff.name}
        onChange={onChange}
        fullWidth={true}
        errorText={errors.name}
      />
    </Box>
  );
};

DayOffForm.propTypes = {
  dayOff: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DayOffForm;
