import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as daycareSettingsActions from '../../../actions/daycareSettingsActions';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';
import DaycareProperties from './DaycareProperties';
import { DaycareSettingsModel } from '../../../models/configuration/daycare/DaycareSettingsModels';
import { Box, Paper } from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

class DaycareConfigurationPage extends React.Component {
  constructor() {
    super();
    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
  }

  handleConfigurationChange(enabled) {
    const updatedSettings = new DaycareSettingsModel().assign(this.props.configuration);
    updatedSettings.enabled = enabled;
    this.props.actions.updateConfiguration(updatedSettings);
  }

  render() {
    const { configuration } = this.props;
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Aktywność dzieci" titleIcon={<ChildCareIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
              <ActionConfirmationButton
                useRaisedButton={true}
                wrapperSx={{ justifyContent: 'flex-start' }}
                actionLabel={
                  configuration.enabled
                    ? 'Wyłącz możliwość rejestracji aktywności'
                    : 'Włącz możliwość rejestracji aktywności'
                }
                confirmationText={
                  configuration.enabled
                    ? 'Czy na pewno chcesz wyłączyć możliwość rejestracji aktywności?'
                    : 'Czy na pewno chcesz włączyć możliwość rejestracji aktywności?'
                }
                confirmationTitle="Potwierdzenie"
                isSecondary={configuration.enabled}
                isPrimary={!configuration.enabled}
                confirmLabel={configuration.enabled ? 'Wyłącz' : 'Włącz'}
                onConfirm={() => this.handleConfigurationChange(!configuration.enabled)}
                onCancel={() => {}}
              />
            </Box>
            {this.props.configuration.enabled && (
              <Paper sx={{ mb: 2, p: 3 }}>
                <DaycareProperties />
              </Paper>
            )}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

DaycareConfigurationPage.propTypes = {
  configuration: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration.daycare
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(daycareSettingsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaycareConfigurationPage);
