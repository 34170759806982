import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  CardActionArea,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  useTheme,
  useMediaQuery,
  Divider,
  ListItemSecondaryAction,
  Box,
  Typography
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import moment from 'moment';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButtonPipe from '../../common/IconButtonPipe';
import FileSpecificIcon from '../../common/FileSpecificIcon/FileSpecificIcon';
import isSubdomain from '../../../utils/isSubdomain';
import { GDANSK_SUBDOMAINS } from '../../../constants/gdanskSubdomains';
import GetAppIcon from '@mui/icons-material/GetApp';

const ChildDocumentsCard = (props) => {
  const { childId } = props;
  const [isOpen, setIsOpen] = useState(props.isInitiallyExpanded);
  const [selectedDateForPayment, setSelectedDateForPayment] = useState({
    year: moment().format('YYYY'),
    month: moment().format('MM')
  });
  const [selectedDateForStay, setSelectedDateForStay] = useState({
    year: moment().format('YYYY'),
    month: '09'
  });
  const [startDate, setStartDate] = useState({ year: moment().format('YYYY'), month: moment().format('MM') });
  const [endDate, setEndDate] = useState({ year: moment().format('YYYY'), month: moment().format('MM') });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleReportGenerate = (reportType, extension, values) => {
    props.reportsActions.generateReportAsync(reportType, extension, values);
  };

  return (
    <Paper elevation={1} sx={{ my: 2, p: 1 }}>
      <CardActionArea onClick={handleOpen}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1, alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: '4px' }}>
            Dokumenty
          </Typography>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </CardContent>
      </CardActionArea>
      <Collapse in={isOpen}>
        <List>
          <ListItem sx={{ my: 1 }}>
            <ListItemIcon>
              <FileSpecificIcon type="application/pdf" />
            </ListItemIcon>
            <ListItemText primary="Karta informacyjna dziecka" primaryTypographyProps={{ sx: { fontSize: 16 } }} />
            <ListItemSecondaryAction>
              <IconButtonPipe
                edge="end"
                tooltip="Pobierz kartę informacyjną dziecka"
                onClick={() => handleReportGenerate('childInformationSheetReport', 'pdf', { childId })}>
                <FileDownloadOutlinedIcon />
              </IconButtonPipe>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          {isSubdomain(GDANSK_SUBDOMAINS) ? (
            <>
              <ListItem sx={{ my: 1 }}>
                <ListItemIcon>
                  <FileSpecificIcon type="application/pdf" />
                </ListItemIcon>
                <ListItemText
                  primary="Zaświadczenie o uczęszczaniu do placówki (Gdańsk)"
                  primaryTypographyProps={{ sx: { fontSize: 16 } }}
                />
                <ListItemSecondaryAction>
                  <IconButtonPipe
                    edge="end"
                    tooltip="Pobierz zaświadczenie o uczęszczaniu do placówki Gdańsk"
                    onClick={() => handleReportGenerate('certificateGdanskReport', 'pdf', { childId })}>
                    <FileDownloadOutlinedIcon />
                  </IconButtonPipe>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem style={{ marginTop: 10, marginBottom: 10 }}>
                <ListItemIcon>
                  <FileSpecificIcon type="application/pdf" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography sx={{ fontSize: 16 }}>Upoważnienie do odbioru – profil zaufany</Typography>}
                />
                <ListItemSecondaryAction>
                  <IconButtonPipe
                    edge="end"
                    tooltip="Pobierz upoważnienie do odbioru – profil zaufany"
                    onClick={() => handleReportGenerate('authorizationToPickUpChildReport', 'pdf', { childId })}>
                    <GetAppIcon />
                  </IconButtonPipe>
                </ListItemSecondaryAction>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem sx={{ my: 1 }}>
                <ListItemIcon>
                  <FileSpecificIcon type="application/pdf" />
                </ListItemIcon>
                <ListItemText
                  primary="Zaświadczenie o uczęszczaniu do placówki"
                  primaryTypographyProps={{ sx: { fontSize: 16 } }}
                />
                <ListItemSecondaryAction>
                  <IconButtonPipe
                    edge="end"
                    tooltip="Pobierz zaświadczenie o uczęszczaniu do placówki"
                    onClick={() => handleReportGenerate('childAttendanceCertificate', 'pdf', { childId })}>
                    <FileDownloadOutlinedIcon />
                  </IconButtonPipe>
                </ListItemSecondaryAction>
              </ListItem>
            </>
          )}
          <Divider />
          <ListItem sx={{ my: 1 }}>
            <ListItemIcon>
              <FileSpecificIcon type="application/pdf" />
            </ListItemIcon>
            <ListItemText
              disableTypography
              sx={{ overflow: 'auto' }}
              primary={<Typography>Zestawienie płatności dziecka</Typography>}
              primaryTypographyProps={{ sx: { fontSize: 16 } }}
              secondary={
                <>
                  {isMobile && (
                    <YearAndMonthOptionsSlider
                      small
                      contrast
                      isMarginLeft={true}
                      onSelected={(nextYear, nextMonth) => {
                        setSelectedDateForPayment({ year: nextYear, month: nextMonth });
                      }}
                      defaultMonth={selectedDateForPayment.month}
                      defaultYear={selectedDateForPayment.year}
                    />
                  )}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Box sx={{ display: 'flex' }}>
                {!isMobile && (
                  <YearAndMonthOptionsSlider
                    contrast
                    isMarginLeft={true}
                    onSelected={(nextYear, nextMonth) => {
                      setSelectedDateForPayment({ year: nextYear, month: nextMonth });
                    }}
                    defaultMonth={selectedDateForPayment.month}
                    defaultYear={selectedDateForPayment.year}
                  />
                )}
                <IconButtonPipe
                  edge="end"
                  tooltip="Pobierz zestawienie płatności dziecka dla wybranej daty"
                  onClick={() =>
                    handleReportGenerate('childPaymentStatement', 'pdf', {
                      childId,
                      month: `${selectedDateForPayment.year}-${selectedDateForPayment.month}`
                    })
                  }>
                  <FileDownloadOutlinedIcon />
                </IconButtonPipe>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem sx={{ my: 1 }}>
            <ListItemIcon>
              <FileSpecificIcon type="application/pdf" />
            </ListItemIcon>
            <ListItemText
              sx={{ overflow: 'auto' }}
              disableTypography
              primary="Kartoteka opłat za pobyt dziecka"
              primaryTypographyProps={{ sx: { fontSize: 16 } }}
              secondary={
                <>
                  {isMobile && (
                    <YearAndMonthOptionsSlider
                      small
                      contrast
                      isMarginLeft={true}
                      sx={{ mr: 1 }}
                      onSelected={(nextYear, nextMonth) => {
                        setSelectedDateForStay({ year: nextYear, month: nextMonth });
                      }}
                      defaultMonth={selectedDateForStay.month}
                      defaultYear={selectedDateForStay.year}
                    />
                  )}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Box sx={{ display: 'flex' }}>
                {!isMobile && (
                  <YearAndMonthOptionsSlider
                    contrast
                    sx={{ overflow: 'auto' }}
                    onSelected={(nextYear, nextMonth) => {
                      setSelectedDateForStay({ year: nextYear, month: nextMonth });
                    }}
                    defaultMonth={selectedDateForStay.month}
                    defaultYear={selectedDateForStay.year}
                  />
                )}
                <IconButtonPipe
                  edge="end"
                  tooltip="Pobierz kartotekę opłat za pobyt dziecka dla wybranej daty"
                  onClick={() =>
                    handleReportGenerate('fileOfFeesForTheChildStayReport', 'pdf', {
                      childId,
                      year: `${selectedDateForStay.year}`,
                      month: `${selectedDateForStay.month}`
                    })
                  }>
                  <FileDownloadOutlinedIcon />
                </IconButtonPipe>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem sx={{ my: 4 }}>
            <ListItemIcon>
              <FileSpecificIcon type="application/pdf" />
            </ListItemIcon>
            <ListItemText
              sx={{ overflow: 'auto' }}
              disableTypography
              primary="Ewidencja dziennego pobytu dziecka"
              primaryTypographyProps={{ sx: { fontSize: 16 } }}
              secondary={
                <>
                  {isMobile && (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ width: 40 }}>Od</Typography>
                        <YearAndMonthOptionsSlider
                          small
                          contrast
                          isMarginLeft={true}
                          onSelected={(nextYear, nextMonth) => {
                            setStartDate({ year: nextYear, month: nextMonth });
                          }}
                          defaultMonth={startDate.month}
                          defaultYear={startDate.year}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ width: 40 }}>Do</Typography>
                        <YearAndMonthOptionsSlider
                          small
                          contrast
                          isMarginLeft={true}
                          onSelected={(nextYear, nextMonth) => {
                            setEndDate({ year: nextYear, month: nextMonth });
                          }}
                          defaultMonth={endDate.month}
                          defaultYear={endDate.year}
                        />
                      </Box>
                    </>
                  )}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Box sx={{ display: 'flex' }}>
                {!isMobile && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', xl: 'row' },
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ mr: 3 }}>Od </Typography>
                      <YearAndMonthOptionsSlider
                        // isMarginLeft
                        contrast
                        onSelected={(nextYear, nextMonth) => {
                          setStartDate({ year: nextYear, month: nextMonth });
                        }}
                        defaultMonth={startDate.month}
                        defaultYear={startDate.year}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ mr: 3 }}>Do</Typography>
                      <YearAndMonthOptionsSlider
                        // isMarginLeft
                        contrast
                        onSelected={(nextYear, nextMonth) => {
                          setEndDate({ year: nextYear, month: nextMonth });
                        }}
                        defaultMonth={endDate.month}
                        defaultYear={endDate.year}
                      />
                    </Box>
                  </Box>
                )}
                <IconButtonPipe
                  edge="end"
                  tooltip="Pobierz ewidencję dziennego pobytu dziecka dla wybranej daty"
                  onClick={() =>
                    handleReportGenerate('childDailyStayRecordsReport', 'pdf', {
                      childId,
                      startDate: `${startDate.year}-${startDate.month}`,
                      endDate: `${endDate.year}-${endDate.month}`
                    })
                  }>
                  <FileDownloadOutlinedIcon />
                </IconButtonPipe>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Collapse>
    </Paper>
  );
};

ChildDocumentsCard.propTypes = {
  reportsActions: PropTypes.object,
  childId: PropTypes.string,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default ChildDocumentsCard;
