/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ExpiringReliefModel } from '../models/dashboard/StaffDashboardModels';

export default function expiringReliefsReducer(state = initialState.staffDashboard.expiringReliefs, action) {
  switch (action.type) {
    case types.LOAD_EXPIRING_RELIEFS_SUCCESS: {
      return action.expiringReliefs.map((relief) => new ExpiringReliefModel().assign(relief));
    }
    default:
      return state;
  }
}
