import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChildMealsCalendarModel } from '../models/meals/ChildMealsCalendarModel';

export default function mealsCalendarReducer(state = initialState.childMealsCalendar, action) {
  switch (action.type) {
    case types.LOAD_CHILDREN_CALENDAR_MEALS_SUCCESS: {
      return new ChildMealsCalendarModel().assign(action.data);
    }
    default:
      return state;
  }
}
