import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectWrapper } from '../common/themeWrapers';
import { SearchBar } from '../common/SearchBar';
import RichMultiSelectField from '../formik/RichMultiSelectField/RichMultiSelectField';
import SingleMemberChip from '../gallery/Components/Albums/SingleMemberChip';
import { useSelector } from 'react-redux';
import userRoles from '../../constants/userRoles';

const AuthorizedToPickUpChildHeader = (props) => {
  const {
    filteredGroups,
    onChangeFilteredGroups,
    handleChangePage,
    groups,
    searchText,
    setSearchText,
    onRowsPerPageChange,
    rowsPerPage
  } = props;
  const staffMembers = useSelector((state) => state.staffMembers);
  const userId = useSelector((state) => state.auth.userId);
  const auth = useSelector((state) => state.auth);
  const unit = useSelector((state) => state.configuration.unit);

  const availableGroups = useMemo(() => {
    const teacherGroupsId = staffMembers.filter((e) => e.id === userId)[0].groups.map((element) => element.id);

    return auth.userRole === userRoles.staffMemberTeacher && !unit.teacherAllGroupVisibility
      ? groups.filter((group) => teacherGroupsId.includes(group.id))
      : groups;
  }, [auth.userRole, groups, staffMembers, unit.teacherAllGroupVisibility, userId]);

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ mb: 2, display: 'flex', alignItems: 'flex-end' }}>
        <SearchBar
          label="Wyszukaj"
          SearchOnClick={(e) => {
            setSearchText(e.target.value);
            handleChangePage(1);
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            handleChangePage(1);
          }}
          onClear={() => {
            setSearchText('');
          }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <RichMultiSelectField
          sx={{ width: '100%', px: 1, py: 2 }}
          contrast
          value={filteredGroups}
          onChange={(newValue) => {
            onChangeFilteredGroups(newValue);
          }}
          onDelete={(newValue) => {
            onChangeFilteredGroups(newValue);
          }}
          options={availableGroups}
          name="groups"
          label="Wybierz grupy"
          selectElement={<SingleMemberChip />}
          chipElement={<SingleMemberChip />}
          isSelectAll={true}
        />
      </Grid>
      <Grid item xs={6} md={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', mb: 2 }}>
        <SelectWrapper
          label="Pozycji na stronę"
          value={rowsPerPage}
          options={[
            { label: 5, value: 5 },
            { label: 10, value: 10 },
            { label: 25, value: 25 },
            { label: 50, value: 50 }
          ]}
          sx={{ minWidth: 88 }}
          onChange={(e) => {
            onRowsPerPageChange(e);
            handleChangePage(1);
          }}
        />
      </Grid>
    </Grid>
  );
};

AuthorizedToPickUpChildHeader.propTypes = {
  filteredGroups: PropTypes.array,
  onChangeFilteredGroups: PropTypes.func,
  groups: PropTypes.array,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  types: PropTypes.object,
  setType: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func
};

export default AuthorizedToPickUpChildHeader;
