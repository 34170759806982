import ApplicationsApi from '../api/ApplicationsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadLegalGuardianApplications(childId) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_LEGAL_GUARDIAN_APPLICATIONS });
    return ApplicationsApi.getLegalGuardianApplicationsForChild(childId)
      .then((applications) => dispatch({ type: types.LOAD_LEGAL_GUARDIAN_APPLICATIONS_SUCCESS, applications }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wniosków dla wskazanego dziecka'));
        logger.error(error);
      });
  };
}

export function createApplication(application) {
  return (dispatch) => {
    dispatch({ type: types.LEGAL_GUARDIAN_CREATE_APPLICATION });
    return ApplicationsApi.createApplication(application)
      .then(() => {
        dispatch(notificationActions.showSuccess('Wniosek został złożony poprawnie'));
        dispatch({ type: types.LEGAL_GUARDIAN_CREATE_APPLICATION_SUCCESS });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Podczas zapisu wniosku wystąpił błąd'));
        logger.error(error);
      });
  };
}

export function updateApplication(id, application) {
  return (dispatch) => {
    dispatch({ type: types.LEGAL_GUARDIAN_UPDATE_APPLICATION });
    return ApplicationsApi.updateApplication(id, application)
      .then(() => {
        dispatch(notificationActions.showSuccess('Wniosek został pomyślnie zaktualizowany'));
        dispatch({ type: types.LEGAL_GUARDIAN_UPDATE_APPLICATION_SUCCESS });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Podczas aktualizacji wniosku wystąpił błąd'));
        logger.error(error);
      });
  };
}

export function archiveApplication(applicationId) {
  return (dispatch) => {
    dispatch({ type: types.LEGAL_GUARDIAN_ARCHIVE_APPLICATION });
    return ApplicationsApi.archiveApplication(applicationId)
      .then(() => {
        dispatch(notificationActions.showSuccess('Wniosek został poprawnie zarchiwizowany'));
        dispatch({ type: types.LEGAL_GUARDIAN_ARCHIVE_APPLICATION_SUCCESS });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Podczas archiwizacji wniosku wystąpił błąd'));
        logger.error(error);
      });
  };
}

export function loadAllApplications() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ALL_APPLICATIONS });
    return ApplicationsApi.getAllApplications()
      .then((applications) => {
        dispatch({ type: types.LOAD_ALL_APPLICATIONS_SUCCESS, applications });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wniosków'));
        logger.error(error);
      });
  };
}

export function approveApplication(applicationId) {
  return (dispatch) => {
    dispatch({ type: types.APPROVE_APPLICATION });
    return ApplicationsApi.approveApplication(applicationId)
      .then(() => {
        dispatch(notificationActions.showSuccess('Wniosek został poprawnie zaakceptowany'));
        dispatch({ type: types.APPROVE_APPLICATION_SUCCESS });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Podczas akceptacji wniosku wystąpił błąd'));
        logger.error(error);
      });
  };
}

export function rejectApplication(applicationId) {
  return (dispatch) => {
    dispatch({ type: types.REJECT_APPLICATION });
    return ApplicationsApi.rejectApplication(applicationId)
      .then(() => {
        dispatch(notificationActions.showSuccess('Wniosek został poprawnie odrzucony'));
        dispatch({ type: types.REJECT_APPLICATION_SUCCESS });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Podczas akceptacji wniosku wystąpił błąd'));
        logger.error(error);
      });
  };
}
