import ApiBase, { ROOT_URL } from './ApiBase';

class ProfileHistoryApi extends ApiBase {
  static getProfileHistory(id, params) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/events/${id}/userAccount`).query(params).type('application/json')
    );
  }
}

export default ProfileHistoryApi;
