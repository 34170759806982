import { showError } from './notificationActions';
import axios from 'axios';
import * as types from './actionTypes';
import * as rightBottomNotificationTypes from '../constants/rightBottomNotificationTypes';
import * as Status from '../constants/status';
import VirtualDiscApi from '../api/VirtualDiscApi';
import { VirtualDiscSaveFileModel } from '../models/virtualDiscFile/virtualDiscFileModel';
import generateUuid from 'uuid/v4';

const upload = (uuid, url, file, cancelToken, updateFunction) => {
  const instance = axios.create();
  delete instance.defaults.headers;
  let progress = 0;
  const config = {
    onUploadProgress: (event) => {
      const temp = Math.ceil((event.loaded / event.total) * 100);
      if (temp > progress) {
        progress = temp;
        updateFunction(uuid, progress);
      }
    },
    headers: {
      'Content-Type': file.type,
      'x-ms-blob-type': 'BlockBlob'
    },
    cancelToken: cancelToken.token
  };
  return instance.put(url, file, config);
};

const uploadProcess = async (dispatch, id, url, file, folderId, token, updateProgress) => {
  try {
    await upload(id, url, file, token, updateProgress);
    const data = new VirtualDiscSaveFileModel().assign({
      name: file.name,
      folderId: folderId,
      size: file.size,
      mimeType: file.type,
      id
    });
    const uploadedFile = await VirtualDiscApi.addFile(data);
    dispatch({
      type: types.VIRTUAL_DISC_ADD_FILE,
      newFile: uploadedFile
    });
    dispatch({
      type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE,
      payload: {
        uuid: id,
        target: rightBottomNotificationTypes.VIRTUAL_DRIVE_UPLOAD,
        data: {
          status: Status.SUCCESS
        }
      }
    });
  } catch (e) {
    dispatch({
      type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE,
      payload: {
        uuid: id,
        target: rightBottomNotificationTypes.VIRTUAL_DRIVE_UPLOAD,
        data: {
          status: Status.ERROR
        }
      }
    });
  }
};

export function uploadFiles(acceptedFiles, forbiddenArray, folderId) {
  return async (dispatch) => {
    const updateProgress = (uuid, progress) => {
      dispatch({
        type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE,
        payload: {
          uuid,
          target: rightBottomNotificationTypes.VIRTUAL_DRIVE_UPLOAD,
          data: {
            progress
          }
        }
      });
    };

    forbiddenArray.forEach((item) => {
      dispatch({
        type: types.RIGHT_BOTTOM_NOTIFICATION_START,
        payload: {
          uuid: generateUuid(),
          target: rightBottomNotificationTypes.VIRTUAL_DRIVE_UPLOAD,
          data: {
            status: Status.ERROR,
            name: item.name,
            mimeType: item.type,
            errorMessage: 'Niedozwolony plik'
          }
        }
      });
    });
    if (acceptedFiles.length > 0) {
      try {
        const signedUploadUrls = await VirtualDiscApi.getSignedUploadUrls(acceptedFiles.length);
        const tempArray = acceptedFiles.map((item) => {
          return {
            file: item,
            ...signedUploadUrls.shift()
          };
        });
        tempArray.forEach((item) => {
          const { CancelToken } = axios;
          const token = CancelToken.source();
          const cancelRequest = () => {
            token.cancel('Cancel request');
            dispatch({
              type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE,
              payload: {
                uuid: item.id,
                target: rightBottomNotificationTypes.VIRTUAL_DRIVE_UPLOAD,
                data: {
                  status: Status.CANCELED
                }
              }
            });
          };
          dispatch({
            type: types.RIGHT_BOTTOM_NOTIFICATION_START,
            payload: {
              uuid: item.id,
              target: rightBottomNotificationTypes.VIRTUAL_DRIVE_UPLOAD,
              data: {
                name: item.file.name,
                mimeType: item.file.type,
                progress: 0,
                status: Status.PENDING,
                cancelRequest
              }
            }
          });
          uploadProcess(dispatch, item.id, item.url, item.file, folderId, token, updateProgress);
        });
      } catch (e) {
        if (!axios.isCancel(e)) {
          dispatch(showError('Nie udało się połączyć z serwisem.'));
        }
      }
    }
  };
}
