import api from '../api/DuesFkExportApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import moment from 'moment';

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export function loadConfiguration() {
  return (dispatch) => {
    return api
      .getConfiguration()
      .then((configuration) => dispatch({ type: types.LOAD_DUES_FK_EXPORT_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać konfiguracji eksportu'));
        return logger.error(error);
      });
  };
}

export function loadExportsHistory() {
  return (dispatch) => {
    return api
      .getExportsHistory()
      .then((history) => {
        dispatch({ type: types.LOAD_DUES_FK_EXPORTS_HISTORY_SUCCESS, history });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać historii eksportu'));
        return logger.error(error);
      });
  };
}

export function updateConfiguration(configuration) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_DUES_FK_EXPORT_SETTINGS });
    return api
      .updateConfiguration(configuration)
      .then(() => dispatch({ type: types.UPDATE_DUES_FK_EXPORT_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień eksportu'));
        return logger.error(error);
      });
  };
}

export function runExport({ month, type, dueType, groupIds }) {
  return (dispatch) => {
    dispatch({ type: types.RUN_DUES_FK_EXPORT });
    if (type === 'payments') {
      return api
        .runOldExport({ month: moment(month).format('MM'), year: moment(month).format('YYYY'), dueType, groupIds })
        .then((xhr) => {
          dispatch({ type: types.RUN_DUES_FK_EXPORT_SUCCESS, month });
          const contentDisposition = xhr.getResponseHeader('Content-Disposition');
          const fileName = decodeURIComponent(contentDisposition).match(/filename=(.+)/)[1];
          dispatch(notificationActions.showSuccess(`Wygenerowano raport: '${fileName}'`));
          storeBlobAs(xhr.response, fileName);
        })
        .catch((error) => {
          dispatch(notificationActions.showError('Wystąpił błąd podczas eksportu'));
          return logger.error(error);
        });
    }
    return api
      .runExport({ month, type })
      .then(() => dispatch({ type: types.RUN_DUES_FK_EXPORT_SUCCESS, month }))
      .catch((error) => {
        dispatch(notificationActions.showError('Wystąpił błąd podczas eksportu'));
        return logger.error(error);
      });
  };
}
