import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as groupActions from '../../actions/groupsActions';
import * as childrenActions from '../../actions/childrenActions';
import * as teachersActions from '../../actions/staffMembersActions';
import GroupDetailsCard from './details/GroupDetailsCard';
import GroupChildrenCard from './children/GroupChildrenCard';
import GroupTeachersCard from './teachers/GroupTeachersCard';
import staffMemberRoles from '../../constants/staffMemberRoles';
import { GroupDetailsModelValidator } from '../../models/groups/GroupModelsValidator';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ArchiveGroupDialog from './archiveGroupDialog';

import PageHeaderText from '../common/pageHeader/PageHeaderText';
import GroupIcon from '@mui/icons-material/Group';
import { Box, Button } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';

class GroupDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      group: Object.assign({}, this.props.group)
    };
    this.onUpdateGroupState = this.onUpdateGroupState.bind(this);
    this.onSaveGroup = this.onSaveGroup.bind(this);
    this.onCancelGroup = this.onCancelGroup.bind(this);
    this.onEditGroup = this.onEditGroup.bind(this);
    this.onArchiveGroup = this.onArchiveGroup.bind(this);

    this.onSaveEnrolledChildren = this.onSaveEnrolledChildren.bind(this);
    this.onCancelEnrolledChildren = this.onCancelEnrolledChildren.bind(this);
    this.onEditEnrolledChildren = this.onEditEnrolledChildren.bind(this);
    this.onRemoveEnrolledChild = this.onRemoveEnrolledChild.bind(this);

    this.onSaveAssociatedTeachers = this.onSaveAssociatedTeachers.bind(this);
    this.onCancelAssociatedTeachers = this.onCancelAssociatedTeachers.bind(this);
    this.onEditAssociatedTeachers = this.onEditAssociatedTeachers.bind(this);
    this.onRemoveAssociatedTeacher = this.onRemoveAssociatedTeacher.bind(this);
    this.getTeachers = this.getTeachers.bind(this);

    this.handleLoaded = this.handleLoaded.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.group !== prevProps.group) {
      this.handleChangeGroupState(this.props.group);
    }
  }

  handleChangeGroupState(value) {
    this.setState({ group: Object.assign({}, value) });
  }

  onUpdateGroupState(event) {
    const field = event.target.name;
    const { group } = this.state;
    group[field] = event.target.value;
    return this.setState({ group: JSON.parse(JSON.stringify(group)) });
  }

  onSaveGroup() {
    this.props.actions.updateGroupDetailsAsync(this.state.group);
  }

  onCancelGroup() {
    this.props.actions.updateGroupDetailsCancel();
    this.setState({ group: Object.assign({}, this.props.group) });
  }

  onEditGroup() {
    this.props.actions.updateGroupDetailsStart();
  }

  onEditEnrolledChildren() {
    this.props.actions.updateEnrolledChildrenStart();
  }

  onSaveEnrolledChildren(addedChildren) {
    this.props.actions.updateEnrolledChildrenAsync(this.props.groupId, [
      ...this.state.group.enrolledChildren.map((child) => child.id),
      ...addedChildren.map((child) => child.id)
    ]);
  }

  onCancelEnrolledChildren() {
    this.props.actions.updateEnrolledChildrenCancel();
    this.setState({ group: Object.assign({}, this.props.group) });
  }

  onRemoveEnrolledChild(childId) {
    this.props.actions.removeChildAsync(this.props.groupId, childId);
  }

  onEditAssociatedTeachers() {
    this.props.actions.associateTeacherStart();
  }

  onSaveAssociatedTeachers(teacherId) {
    this.props.actions.associateTeacherAsync(this.props.groupId, teacherId);
  }

  onCancelAssociatedTeachers() {
    this.props.actions.associateTeacherCancel();
    this.setState({ group: Object.assign({}, this.props.group) });
  }

  onRemoveAssociatedTeacher(teacherId) {
    this.props.actions.removeTeacherAsync(this.props.groupId, teacherId);
  }

  onArchiveGroup(archiveChildren, archiveLegalGuardians, archiveBoardPosts) {
    this.props.actions.archiveGroupDetailsAsync(
      this.props.groupId,
      archiveChildren,
      archiveLegalGuardians,
      archiveBoardPosts
    );
  }

  getTeachers() {
    return this.props.teachers.filter(
      (teacher) => teacher.role === staffMemberRoles.teacher.value || teacher.role === staffMemberRoles.principal.value
    );
  }

  handleLoaded() {
    this.setState({ group: Object.assign({}, this.props.group) });
  }

  renderArchiveGroupDialog() {
    if (!this.props.archivalUi.isEditing) {
      return null;
    }

    return (
      <ArchiveGroupDialog
        group={this.props.group}
        isDialogOpen={this.props.archivalUi.isEditing}
        isProcessing={this.props.archivalUi.isProcessing}
        onArchive={(archiveChildren, archiveLegalGuardians, archiveBoardPosts) =>
          this.onArchiveGroup(archiveChildren, archiveLegalGuardians, archiveBoardPosts)
        }
        onCancel={() => this.props.actions.archiveGroupCancel()}
      />
    );
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Grupy" titleIcon={<GroupIcon />} />
          <ContentWrapper>
            <GroupDetailsCard
              group={this.state.group}
              onChange={this.onUpdateGroupState}
              onSave={this.onSaveGroup}
              onCancel={this.onCancelGroup}
              onEdit={this.onEditGroup}
              onValidate={() => new GroupDetailsModelValidator().validate(this.state.group)}
              isEditing={this.props.detailsUi.isEditing}
              isProcessing={this.props.detailsUi.isProcessing}
            />
            <GroupChildrenCard
              group={this.state.group}
              pupils={this.props.pupils}
              onChange={this.onUpdateGroupState}
              onSave={this.onSaveEnrolledChildren}
              onCancel={this.onCancelEnrolledChildren}
              onEdit={this.onEditEnrolledChildren}
              onRemove={this.onRemoveEnrolledChild}
              isEditing={this.props.childrenUi.isEditing}
              isProcessing={this.props.childrenUi.isProcessing}
            />
            <GroupTeachersCard
              group={this.state.group}
              teachers={this.getTeachers()}
              onChange={this.onUpdateGroupState}
              onSave={this.onSaveAssociatedTeachers}
              onCancel={this.onCancelAssociatedTeachers}
              onEdit={this.onEditAssociatedTeachers}
              onRemove={this.onRemoveAssociatedTeacher}
              isEditing={this.props.teachersUi.isEditing}
              isProcessing={this.props.teachersUi.isProcessing}
            />
            <Box
              justifyContent="flex-end"
              sx={{
                display: 'flex'
              }}>
              <Button variant="outlined" aria-label="Archiwizuj" onClick={this.props.actions.archiveGroupStart}>
                Archiwizuj
              </Button>
            </Box>
            {this.renderArchiveGroupDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GroupDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  childrenActions: PropTypes.object.isRequired,
  teachersActions: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  groupId: PropTypes.string,
  pupils: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  detailsUi: PropTypes.object.isRequired,
  childrenUi: PropTypes.object.isRequired,
  teachersUi: PropTypes.object.isRequired,
  archivalUi: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const groupId = ownProps.params.id;
  return {
    groupId,
    detailsUi: state.groupDetailsUi.details,
    childrenUi: state.groupDetailsUi.enrolledChildren,
    teachersUi: state.groupDetailsUi.associatedTeachers,
    archivalUi: state.groupDetailsUi.archival,
    group: state.groupDetails,
    pupils: state.children,
    teachers: state.staffMembers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupActions, dispatch),
    childrenActions: bindActionCreators(childrenActions, dispatch),
    teachersActions: bindActionCreators(teachersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailsPage);
