import ApiBase, { ROOT_URL } from './ApiBase';
// import moment from 'moment';

export default class StaffCalendarApi extends ApiBase {
  static getStaffEvents(month) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/boardPosts/internal/${month}`).type('application/json'));
  }

  static getFileterdStaffEvents(month, filters) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/boardPosts/${month}/byFilter`)
        .query({ ...filters, internal: 'true' })
        .type('application/json')
    );
  }

  static getStaffEventsAtDay({ year, month, day }) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/internal/${year}-${month}-${day}`).type('application/json')
    );
  }

  static createStaffEvent(data) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/boardPosts`).send(data).type('application/json'));
  }

  static updateStaffEvent(data) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/boardPosts/${data.id}`).send(data).type('application/json')
    );
  }

  static deleteStaffEvent(data) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/boardPosts/${data.id}/archive`).type('application/json'));
  }
}
