export const ReportTypes = Object.freeze({
  childrenList: { type: 'childrenList', name: 'Lista dzieci' },
  childrenActivities: { type: 'childrenActivities', name: 'Lista dzieci uczęszczających na zajęcia dodatkowe' },
  reliefsByChildren: { type: 'reliefsByChildren', name: 'Przyznane ulgi' },
  reliefsSummary: { type: 'reliefsSummary', name: 'Podsumowanie przyznanych ulg' },
  childrenAttendanceInDays: { type: 'childrenAttendanceInDays', name: 'Lista obecności (dniowa)' },
  childrenAttendanceInDaysXls: { type: 'childrenAttendanceInDaysXls', name: 'Lista obecności (dniowa) XLSX' },
  childrenAttendanceInHours: { type: 'childrenAttendanceInHours', name: 'Lista obecności (godzinowa)' },
  childrenAttendanceInHoursXls: { type: 'childrenAttendanceInHoursXls', name: 'Lista obecności (godzinowa) XLSX' },
  childrenAttendanceInHoursDuesXls: {
    type: 'childrenAttendanceInHoursDuesXls',
    name: 'Lista obecności godzin płatnych'
  },
  childrenDiagnosesReport: {
    name: 'Rejestr diagnoz',
    type: 'childrenDiagnosesReport'
  },
  childrenAbsenceInDays: { type: 'childrenAbsenceInDays', name: 'Lista nieobecności (dniowa)' },
  childrenAbsenceInDaysXls: { type: 'childrenAbsenceInDaysXls', name: 'Lista nieobecności (dniowa) XLSX' },
  childrenAbsenceReportedAtDetails: {
    type: 'childrenAbsenceReportedAtDetails',
    name: 'Lista zgłoszonych nieobecności'
  },
  childrenOpinionsSummary: {
    type: 'childrenOpinionsSummary',
    name: 'Podsumowanie orzeczeń o potrzebie kształcenia specjalnego'
  },
  childrenAttendanceJournal: { type: 'childrenAttendanceJournal', name: 'Dziennik obecności' },
  groupAttendanceDailySummary: { type: 'groupAttendanceDailySummary', name: 'Podsumowanie obecności w grupach' },
  groupActivitiesAttendanceDailySummary: {
    type: 'groupActivitiesAttendanceDailySummary',
    name: 'Podsumowanie obecności w grupach (zajęcia dodatkowe)'
  },
  stayDuesPayments: { type: 'stayDuesPayments', name: 'Zestawienie odpłatności za pobyt' },
  cateringDuesPayments: { type: 'cateringDuesPayments', name: 'Zestawienie odpłatności za żywienie' },
  paymentsSummaryPerChild: { type: 'paymentsSummaryPerChild', name: 'Podsumowanie płatności dla dziecka' },
  detailedPaymentsSummaryPerChild: {
    type: 'detailedPaymentsSummaryPerChild',
    name: 'Szczegółowe podsumowanie płatności dla dziecka'
  },
  paymentsSummary: { type: 'paymentsSummary', name: 'Podsumowanie płatności i odpisów' },
  childrenAverageAttendanceInHourIntervals: {
    type: 'childrenAverageAttendanceInHourIntervals',
    name: 'Średnia liczba dzieci przebywających w placówce'
  },
  duesDetails: { type: 'duesDetails', name: 'Wydruk kwitariusza', hidden: true },
  duesStayDetailsOverall: { type: 'duesStayDetailsOverall', name: 'Wydruk kwitariuszy pobytu' },
  duesActivitiesOverallReport: { type: 'duesActivitiesDetailsOverall', name: 'Wydruk kwitariuszy zajęć dodatkowych' },
  duesCateringDetailsOverall: { type: 'duesCateringDetailsOverall', name: 'Wydruk kwitariuszy wyżywienia' },
  duesOtherDetailsOverall: { type: 'duesOtherDetailsOverall', name: 'Wydruk kwitariuszy dodatkowych' },
  duesDetailsOverall: { type: 'duesDetailsOverall', name: 'Wydruk kwitariuszy (ogólny)' },
  duesDetailsXls: { type: 'duesDetailsXls', name: 'Wydruk kwitariuszy (ogólny) (.xls)' },
  childrenAttributeSetValues: { type: 'childrenAttributeSetValues', name: 'Wartości atrybutów dzieci' },
  childrenAttributeSetValuesSummary: {
    type: 'childrenAttributeSetValuesSummary',
    name: 'Wartości atrybutów dzieci - podsumowanie'
  },
  schoolYearInauguration: {
    type: 'schoolYearInauguration',
    name: 'Zestawienie pierwszych obecności w nowym roku szkolnym'
  },
  legalGuardianDebts: { type: 'legalGuardianDebts', name: 'Zestawienie zadłużonych opiekunów prawnych' },
  attributeSetAttendanceReport: { type: 'attributeSetAttendanceReport', name: 'Zestawienie "cateringowe"' },
  childrenOpinionReport: { type: 'childrenOpinionReport', name: 'Rejestr opinii, orzeczeń' },
  digitalDiaryAdditionalActivities: {
    type: 'digitalDiaryAdditionalActivities',
    name: 'Dziennik elektroniczny - zajęcia dodatkowe'
  },
  digitalDiaryDaySchedule: { type: 'digitalDiaryDaySchedule', name: 'Dziennik elektroniczny - ramowy rozkład dnia' },
  digitalDiarySaveWork: {
    type: 'digitalDiarySaveWork',
    name: 'Dziennik elektroniczny - zapis pracy wychowawczo-dydaktycznej'
  },
  digitalDiaryWorkPlan: { type: 'digitalDiaryWorkPlan', name: 'Dziennik elektroniczny - plan pracy' },
  digitalDiaryWorkWithParents: {
    type: 'digitalDiaryWorkWithParents',
    name: 'Dziennik elektroniczny - roczny plan współpracy z rodzicami'
  },
  childrenPlannedAttendances: { type: 'childrenPlannedAttendances', name: 'Zaplanowana obecność dzieci' },
  boardPostsListReport: { type: 'boardPostsReport', name: 'Tablica ogłoszeń' },
  childrenAttendanceInHourIntervalsMonth: {
    type: 'childrenAttendanceInHourIntervalsMonth',
    name: 'Obecność dzieci w interwałach godzinowych'
  },
  childrenSummaryPerGroup: { type: 'childrenSummaryPerGroup', name: 'Podsumowanie przypisania do grup' },
  canteenSummaryReport: { type: 'canteenSummaryReport', name: 'Podsumowanie dla pracowników stołówki' },
  canteenSummaryByMealsAtDayReport: {
    type: 'canteenSummaryByMealsAtDayReport',
    name: 'Podsumowanie zamówienia posiłków'
  },
  childrenAttendanceInDaysByGroup: {
    type: 'childrenAttendanceInDaysByGroup',
    name: 'Lista obecności dzieci (dzienna) oddzielna dla każdej grupy',
    info: 'Aby dzieci były uwzględnione w raporcie - wymagane jest wprowadzenie daty rozpoczęcia i zakończenia edukacji w rejestrze dzieci'
  },
  fileOfFeesForTheChildStayReport: {
    type: 'fileOfFeesForTheChildStayReport',
    name: 'Kartoteka opłat za pobyt dziecka'
  },
  listOfManDaysReport: { type: 'listOfManDaysReport', name: 'Zestawienie osobodni (obecności dzieci)' },
  listOfArrearsForMonthReport: { type: 'listOfArrearsForMonthReport', name: 'Lista zaległości za miesiąc' },
  listOfTransfersReport: { type: 'listOfTransfersReport', name: 'Zestawienie przelewów' },
  statementOfInterestReport: { type: 'statementOfInterestReport', name: 'Zestawienie odsetek' },
  statementOfOverpaymentsReport: { type: 'statementOfOverpaymentsReport', name: 'Zestawienie nadpłat' },
  staySettlementsDetailsFromReaderReport: {
    type: 'staySettlementsDetailsFromReaderReport',
    name: 'Rozliczenie - pobyt (szczegóły) (.xls)'
  },
  cateringSettlementsDetailsFromReaderReport: {
    type: 'cateringSettlementsDetailsFromReaderReport',
    name: 'Rozliczenie - żywnienie (szczegóły) (.xls)'
  },
  listOfFeesPaidReport: { type: 'listOfFeesPaidReport', name: 'Zestawienie opłat wnoszonych' },
  calendarEventsListReport: { type: 'calendarEventsListReport', name: 'Lista wydarzeń z kalendarza' },
  dailyAbsenceOfChildrenReport: {
    type: 'dailyAbsenceOfChildrenReport',
    name: 'Lista nieobecności dzieci w danym dniu miesiąca'
  },
  reportedAbsencesByTheHourOfPreviousDayReport: {
    type: 'reportedAbsencesByTheHourOfPreviousDayReport',
    name: 'Lista zgłoszonych nieobecności do wybranej godziny w poprzednim dniu'
  },
  statementOfAccrualsForMonthReport: {
    type: 'statementOfAccrualsForMonthReport',
    name: 'Zestawienie naliczeń za miesiąc - początek miesiąca'
  },
  statementOfSettlementsForMonthReport: {
    type: 'statementOfSettlementsForMonthReport',
    name: 'Zestawienie rozliczeń za miesiąc'
  },
  simplifiedPaymentsSummaryPerChild: {
    type: 'simplifiedPaymentsSummaryPerChild',
    name: 'Uproszczone podsumowanie płatności dziecka'
  },
  statementOfExpectedInterestAmountForDayReport: {
    type: 'statementOfExpectedInterestAmountForDayReport',
    name: 'Zestawienie przewidywanej kwoty odsetek na wybrany dzień'
  },
  kindergartenActivityLog: { type: 'kindergartenActivityLog', name: 'Dziennik zajęć przedszkola' },
  psychologicalPedagogicalIndividualClassesTimetable: {
    type: 'psychologicalPedagogicalIndividualClassesTimetable',
    name: 'Dziennik specjalistyczny - zajęcia indywidualne'
  },
  deductionAndSettlementOfPaymentForStayAndCatering: {
    type: 'deductionAndSettlementOfPaymentForStayAndCatering',
    name: 'Odpis i rozliczenie odpłatności za pobyt/wyżywienie'
  },
  summaryOfAttendanceAtMealsReport: {
    type: 'summaryOfAttendanceAtMealsReport',
    name: 'Podsumowanie obecności na posiłkach'
  },
  settlementForTheMonth: { type: 'settlementForTheMonth', name: 'Rozliczenie za miesiąc' },
  paymentAssignment: { type: 'paymentAssignment', name: 'Przypis odpłatności za pobyt/wyżywienie' }
});
