import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportsList from './ReportsList';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { reports as reportsList } from './Configurations/ReducedReportsWithCategories';
import { generateReportAsync, generateReportCancel, generateReportStart } from '../../actions/reportsActions';
import GenerateReportDialog from './GenerateReportDialog';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box, Paper } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';

const ReducedReportsPage = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const generateReportUi = useSelector((state) => state.generateReportUi);
  const attributeSets = useSelector((state) => state.configuration.attributesSets);
  const groups = useSelector((state) => state.groups);
  const reliefs = useSelector((state) => state.configuration.reliefs);
  const activities = useSelector((state) => state.activities);

  const ReducedReportsWithCategories = [{ reports: reportsList }];

  const [selectedReport, setSelectedReport] = useState();
  const [reportWithErrors, setReportWithErrors] = useState(false);

  const handleReportSelected = (reportDefinition) => {
    setSelectedReport(reportDefinition);
    dispatch(generateReportStart());
  };

  const handleGenerateReport = (parameters) => {
    setReportWithErrors(false);
    let params = Object.assign({}, parameters);
    if (parameters.groupId === 'all') {
      params = Object.assign({}, parameters, { groupId: null });
    }
    if (parameters.activityId === 'all') {
      params = Object.assign({}, parameters, { activityId: null });
    }
    if (params.reliefId === 'all') {
      params = Object.assign({}, params, { reliefId: null });
    }
    dispatch(generateReportAsync(selectedReport.type, selectedReport.formats[0], params));
  };

  const handleCancelReportGeneration = () => {
    dispatch(generateReportCancel());
    setReportWithErrors(false);
  };

  const handleDisableSaveButton = (disabled) => {
    setReportWithErrors(disabled);
  };

  const renderGenerateReportDialog = () =>
    generateReportUi.isEditing ? (
      <GenerateReportDialog
        onSave={handleGenerateReport}
        onCancel={handleCancelReportGeneration}
        isDialogOpen={generateReportUi.isEditing}
        isProcessing={generateReportUi.isProcessing}
        reportType={selectedReport}
        attributeSets={attributeSets}
        reportWithErrors={reportWithErrors}
        disableSaveButton={handleDisableSaveButton}
        groups={[{ name: 'Wszystkie', id: 'all' }, ...groups]}
        reliefs={[{ name: 'Wszystkie', id: 'all' }, ...reliefs]}
        activities={[{ name: 'Wszystkie', id: 'all' }, ...activities]}
      />
    ) : null;

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Raporty" titleIcon={<InsertChartIcon />} />
        <ContentWrapper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <ReportsList
              reports={reports}
              onReportSelected={handleReportSelected}
              reportCategory={ReducedReportsWithCategories[0]}
            />
          </Paper>
          {renderGenerateReportDialog()}
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default ReducedReportsPage;
