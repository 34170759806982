/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function chargingSchemesUiReducer(state = initialState.configuration.chargingSchemesUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_CHARGING_SCHEME_DETAILS: {
      return UiModel.start();
    }
    case types.CREATE_CHARGING_SCHEME_DETAILS_CANCEL: {
      return UiModel.cancel();
    }
    case types.CREATE_CHARGING_SCHEME_DETAILS_SUBMIT: {
      return UiModel.submit();
    }
    case types.CREATE_CHARGING_SCHEME_DETAILS_SUCCESS: {
      return UiModel.success();
    }
    case types.CREATE_CHARGING_SCHEME_DETAILS_FAILURE: {
      return UiModel.failure(action.errors);
    }
    // update
    case types.UPDATE_CHARGING_SCHEME_DETAILS: {
      return UiModel.start();
    }
    case types.UPDATE_CHARGING_SCHEME_DETAILS_CANCEL: {
      return UiModel.cancel();
    }
    case types.UPDATE_CHARGING_SCHEME_DETAILS_SUBMIT: {
      return UiModel.submit();
    }
    case types.UPDATE_CHARGING_SCHEME_DETAILS_SUCCESS: {
      return UiModel.success();
    }
    case types.UPDATE_CHARGING_SCHEME_DETAILS_FAILURE: {
      return UiModel.failure(action.errors);
    }
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.configuration.chargingSchemesUi);
    }
    default:
      return state;
  }
}
