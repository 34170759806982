import { Divider, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import AdvancedFormField from './AdvancedFormField';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { psychologicalSchemesTypes } from '../../constants/psychologicalSchemesTypes';

const UserDefinedFields = ({ fields = [], setFields, fieldLabel, isReadonly, errors }) => {
  const [tempTitle, setTempTitle] = useState();
  const [error, setError] = useState();

  const fieldnameToSchemeType = () => {
    const asArray = Object.entries(psychologicalSchemesTypes);
    const schemeType = asArray.filter(([key, value]) => value.title === fieldLabel);
    return schemeType[0][0];
  };

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
        {fieldLabel}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isReadonly && (
          <TextField
            error={!!error}
            helperText={error}
            value={tempTitle}
            label="Podaj tytuł nowego pola"
            onChange={(e) => setTempTitle(e.target.value)}
            variant="outlined"
            sx={{ mr: 2, width: '100%', mt: 2 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  variant="contained"
                  onClick={() => {
                    if (tempTitle) {
                      setFields([...fields, { title: tempTitle, description: '' }]);
                      setError('');
                      setTempTitle('');
                    } else {
                      setError('Podaj Tytuł');
                    }
                  }}>
                  <AddIcon />
                </IconButton>
              )
            }}
          />
        )}
      </Box>
      {fields.map((field, index) => (
        <>
          <AdvancedFormField
            error={errors !== undefined && errors[index]}
            isReadonly={isReadonly}
            value={field}
            setData={(e) => {
              let tempFields = fields;
              tempFields[index] = { title: field.title, content: e.content };
              setFields(tempFields);
            }}
            index={index}
            key={index}
            label={field.title}
            schemeType={fieldnameToSchemeType()}
          />
        </>
      ))}
      {!isReadonly && (
        <Typography
          variant="subtitle"
          sx={{
            fontSize: 14,
            color: (theme) => (!!errors && errors.empty ? theme.palette.color.error : theme.palette.text.primary)
          }}>
          Wymagane co najmniej 1 pole
        </Typography>
      )}
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default UserDefinedFields;
