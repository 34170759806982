import ApiBase, { ROOT_URL } from './ApiBase';
import moment from 'moment';

class MessageBoardApi extends ApiBase {
  static getMessageBoardPosts(limit, offset) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts?limit=${limit}&offset=${offset}`).type('application/json')
    );
  }

  static getLatestMessageBoardEvents(date, limit, offset) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM-DD')}/latest?limit=${limit}&offset=${offset}`)
        .type('application/json')
    );
  }

  static getLatestMessageBoardEventsToMe(date, limit, offset) {
    return super.toPromise((agent) =>
      agent
        .get(
          `${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM-DD')}/mygroups/latest?limit=${limit}&offset=${offset}`
        )
        .type('application/json')
    );
  }

  static getMessageBoardEventsAtMonth(date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM')}`).type('application/json')
    );
  }

  static getFilteredMessageBoardEventsAtMonth(date, params) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM')}/byFilter`)
        .query(params)
        .type('application/json')
    );
  }

  static getMessageBoardEventsAtDay(date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM-DD')}`).type('application/json')
    );
  }

  static getMessageBoardEventsToMeAtMonth(date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM')}/mygroups`).type('application/json')
    );
  }

  static getFilteredMessageBoardEventsToMeAtMonth(date, params) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM')}/mygroups/byFilter`)
        .query(params)
        .type('application/json')
    );
  }

  static getMessageBoardEventsToMeAtDay(date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM-DD')}/mygroups`).type('application/json')
    );
  }

  static getMessageBoardPostsToMe(limit, offset) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/mygroups?limit=${limit}&offset=${offset}`).type('application/json')
    );
  }

  static getFilteredMessageBoardPostsToMe(params) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/mygroups/byFilter`).query(params).type('application/json')
    );
  }

  static getFilteredMessageBoardPosts(params) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/byFilter`).query(params).type('application/json')
    );
  }

  static createMessageBoardPostOrEvent(post) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/boardPosts`).send(post).set('Location', '').type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateMessageBoardPostOrEvent(postId, post) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/boardPosts/${postId}`).send(post).type('application/json')
    );
  }

  static archiveMessageBoardPost(id) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/boardPosts/${id}/archive`).type('application/json'));
  }

  static getStaffEventsForMonth(date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/${moment(date).format('YYYY-MM')}?internal=true`).type('application/json')
    );
  }

  static getStaffMessageBoardPosts(limit, offset) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts?limit=${limit}&offset=${offset}&internal=true`).type('application/json')
    );
  }

  static getLegalGuardiansList(postId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/getUnreadGuardians/${postId}`).type('application/json')
    );
  }
}

export default MessageBoardApi;
