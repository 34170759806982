import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';

const LoadingIndicator = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        mt: 6
      }}>
      <Typography sx={{ color: (theme) => theme.palette.color.primary }}>Wczytywanie...</Typography>
      <LinearProgress sx={{ width: '100%', my: 2, height: 6 }} />
    </Box>
  );
};

export default LoadingIndicator;
