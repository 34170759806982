import React, { useState } from 'react';
import { Card, CardContent, MenuItem, Typography } from '@mui/material';
import { DigitalDiaryEventModel } from '../../../models/digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
import moment from 'moment';
import DotsMenu from '../../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import BoardPostEditDialog from './BoardPostEditDialog';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as digitalDiaryDetailsActions from '../../../actions/messageBoardDigitalDiaryActions';
import StackRow from '../../common/StackRow';
import { momentCalendarFormatWithoutHours } from '../../../constants/momentCalendarFormat';

const BoardPost = ({ post, onDelete, state, isProcessing, eventType, onClearFilters }) => {
  const currentPost = new DigitalDiaryEventModel().assign(post);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSave = async (newPost) => {
    await dispatch(
      digitalDiaryDetailsActions.updateEventAsync({
        eventData: newPost,
        eventType: newPost.eventType,
        eventDate: newPost.eventDate,
        groupId: newPost.group
      })
    );
    setIsEditing(false);
    onClearFilters();
  };

  const onCancel = () => {
    setIsEditing((prev) => !prev);
  };

  return (
    <Card sx={{ m: 3 }} key={currentPost.id} variant="outlined">
      <CardContent>
        <StackRow sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              wordBreak: 'break-word',
              fontWeight: (theme) => theme.typography.fontWeightLarge,
              fontSize: 24,
              color: (theme) => theme.palette.color.color22
            }}>
            {currentPost.title}
          </Typography>
          <DotsMenu>
            {(onClose) => [
              <MenuItem
                key="edit"
                onClick={() => {
                  onClose();
                  onEdit();
                }}>
                Edytuj
              </MenuItem>,
              <ActionConfirmationMenuItem
                key="delete"
                onItemClick={() => onClose()}
                actionLabel="Usuń"
                confirmationText="Czy na pewno chcesz usunąć wpis?"
                confirmationTitle="Usuń wpis"
                confirmLabel="Usuń"
                onConfirm={() => onDelete(currentPost)}
                onCancel={() => {}}
              />
            ]}
          </DotsMenu>
        </StackRow>
        <Typography sx={{ fontSize: 14, mb: 0 }}>
          Wydarzenie utworzone {moment(currentPost.publishedAt).calendar().toLowerCase()} przez{' '}
          {currentPost.author.userFullName}
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 0 }}>
          Data wydarzenia:{' '}
          {moment(currentPost.eventDate).calendar(null, momentCalendarFormatWithoutHours).toLowerCase()}{' '}
          {currentPost.eventStartTime.toString()} - {currentPost.eventEndTime.toString()}
        </Typography>
        <Typography
          sx={{
            wordBreak: 'break-word',
            '& img': {
              maxWidth: '100%'
            }
          }}
          dangerouslySetInnerHTML={{ __html: currentPost.content }}
        />
      </CardContent>
      {isEditing ? (
        <BoardPostEditDialog
          post={currentPost}
          onSave={(newPost) => onSave(newPost)}
          onCancel={onCancel}
          groups={state.groups}
          activities={state.activities}
          isEditing={isEditing}
          isProcessing={isProcessing}
          eventType={eventType}
          staffMembers={state.staffMembers}
        />
      ) : null}
    </Card>
  );
};

BoardPost.propTypes = {
  post: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  isEditing: PropTypes.bool,
  state: PropTypes.object,
  isProcessing: PropTypes.bool,
  eventType: PropTypes.string,
  onClearFilters: PropTypes.func
};

export default BoardPost;