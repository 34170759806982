import PropTypes from 'prop-types';
import React from 'react';
import { PaymentMethods } from '../../../constants/paymentMethods';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const PaymentMethodOptions = ({ onSelected, value, isDisabled }) => {
  return (
    <FormControl component="fieldset" style={{ padding: '20px 0' }}>
      <FormLabel sx={{ color: (theme) => theme.palette.color.contrast }}>Sposób płatności</FormLabel>
      <RadioGroup
        aria-label="Sposób płatności"
        onClick={(e) => {
          onSelected(e.target.value);
        }}
        value={value || PaymentMethods.cash.value}
        style={{ flexDirection: 'row' }}
        name="paymentMethod">
        <FormControlLabel
          value={PaymentMethods.transfer.value}
          control={<Radio color="primary" disabled={isDisabled} />}
          label={PaymentMethods.transfer.name}
          key={PaymentMethods.transfer.value}
        />
        <FormControlLabel
          value={PaymentMethods.cash.value}
          control={<Radio color="primary" disabled={isDisabled} />}
          label={PaymentMethods.cash.name}
          key={PaymentMethods.cash.value}
        />
        <FormControlLabel
          value={PaymentMethods.fromBalance.value}
          control={<Radio color="primary" disabled={isDisabled} />}
          label={PaymentMethods.fromBalance.name}
          key={PaymentMethods.fromBalance.value}
        />
      </RadioGroup>
    </FormControl>
  );
};

PaymentMethodOptions.propTypes = {
  onSelected: PropTypes.func.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default PaymentMethodOptions;
