export function getValue(n) {
  return typeof n === 'number' ? n : n.replace(',', '.');
}

export function isNumeric(n) {
  return (
    n !== null &&
    n !== undefined &&
    (typeof n === 'number' || (!isNaN(Number.parseFloat(getValue(n))) && isFinite(getValue(n))))
  );
}

export function isNumericGreaterThanOrEqual(n, threshold) {
  return isNumeric(n) && Number.parseFloat(getValue(n)) >= threshold;
}

export function isNumericGreaterThan(n, threshold) {
  return isNumeric(n) && Number.parseFloat(getValue(n)) > threshold;
}

export function isNumericBetween(n, min, max) {
  if (isNumeric(n)) {
    const parsedValue = Number.parseFloat(getValue(n));
    return parsedValue >= min && parsedValue <= max;
  }
  return false;
}
