import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../../calendar/Grid/CalendarDayContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, MenuItem } from '@mui/material';
import IconMenu from '../../../menu/IconMenu';
import { useTheme } from '@emotion/react';

const AbsentDayContent = ({
  day,
  noDescription,
  containerWidth,
  absenceNotes,
  isWeekend,
  onReportAttendance,
  onRevertAbsence,
  onDetailsOpen,
  isReadOnly,
  isParent ,
  exceededAbsenceReportTime,
  isActivityCalendar = false,
  showMore = true
}) => {
  const theme = useTheme();
  return (
    <CalendarDayContent
      day={day}
      containerWidth={containerWidth}
      primaryText={absenceNotes || ''}
      primaryIcon={
        <Box>
          {exceededAbsenceReportTime && <InfoOutlinedIcon sx={{ ml: 1 }} color={theme.palette.color.color20} />}
          <IconMenu
            sx={{ ml: 1 }}
            icon={AccountCircleIcon}
            color={theme.palette.color.color14}
            isReadOnly={isReadOnly}
            isParent={isParent}
            dense>
            {(onClose) => [
              !isWeekend && !isParent && (
                <MenuItem
                  key="1"
                  onClick={() => {
                    onClose();
                    onReportAttendance(day);
                  }}>
                  {!isActivityCalendar ? 'Wprowadź godziny obecności' : 'Wprowadź obecność'}
                </MenuItem>
              ),
              !isActivityCalendar ? (
                <MenuItem
                  key="2"
                  onClick={() => {
                    onClose();
                    onRevertAbsence(day);
                  }}>
                  Wycofaj nieobecność
                </MenuItem>
              ) : null,
              !isActivityCalendar && !isParent && showMore ? (
                <MenuItem
                  key="3"
                  onClick={() => {
                    onClose();
                    onDetailsOpen(day);
                  }}>
                  Więcej...
                </MenuItem>
              ) : null
            ]}
          </IconMenu>
        </Box>
      }
    />
  );
};

AbsentDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  absenceNotes: PropTypes.string,
  onReportAttendance: PropTypes.func.isRequired,
  onRevertAbsence: PropTypes.func,
  onDetailsOpen: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isWeekend: PropTypes.bool,
  exceededAbsenceReportTime: PropTypes.bool,
  isActivityCalendar: PropTypes.bool
};

export default AbsentDayContent;
