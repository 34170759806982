import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from '../../common/DatePicker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import DialogForm from '../../forms/DialogForm';
import numberFormatter from '../../../utils/numberFormatter';
import moment from 'moment';

import { PaymentMethods } from '../../../constants/paymentMethods';
import TextField from '../../common/TextField';
import * as settlementsActions from '../../../actions/settlementsActions';
import _ from 'lodash';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import Strong from '../../common/Strong';
import LoadingIndicator from '../../common/loading/LoadingIndicator';

class RegisterStaffMemberPaymentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDetails: {
        paymentMethod: PaymentMethods.transfer.value,
        paymentDate: moment().format('YYYY-MM-DD'),
        paymentAmount: '',
        payments: [],
        balance: {}
      },
      errors: {}
    };
    this.handleParamsChange = this.handleParamsChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.dueAmountDisabled = this.dueAmountDisabled.bind(this);
    this.getDueAmount = this.getDueAmount.bind(this);
  }

  componentDidMount() {
    this.props.actions.getStaffUnpaidDues(this.props.settlement.person.id);
  }

  handleParamsChange(event) {
    /*eslint no-param-reassign: 0*/
    const field = event.target.name;
    const { paymentDetails } = this.state;
    paymentDetails[field] = event.target.value;

    /*eslint default-case: 0*/
    switch (field) {
      case 'paymentAmount':
        paymentDetails.payments.forEach((payment) => {
          payment.amount = 0;
        });
        break;
      case 'paymentDate':
        paymentDetails.payments.forEach((payment) => {
          payment.paymentDate = paymentDetails[field];
        });
        break;
      case 'paymentMethod':
        paymentDetails.payments.forEach((payment) => {
          payment.method = paymentDetails[field];
        });
        if (paymentDetails.paymentMethod === PaymentMethods.fromBalance.value) {
          paymentDetails.paymentAmount = this.props.availableFunds;
        }
        break;
    }

    paymentDetails.balance = this.getBalance(paymentDetails);

    return this.setState({ paymentDetails });
  }

  handleCheck(dueId, isChecked, paidAmount) {
    const { paymentDetails } = this.state;
    paymentDetails.payments = [...paymentDetails.payments];

    const diff = _.round(this.calculateBalanceAmount(paymentDetails) - paidAmount, 2);
    let initialAmount = diff >= 0 ? paidAmount : _.round(paidAmount + diff, 2);
    if (this.calculateBalanceAmount(paymentDetails) < 0) initialAmount = 0;

    if (isChecked) {
      paymentDetails.payments.push({
        dueId,
        method: paymentDetails.paymentMethod,
        date: paymentDetails.paymentDate,
        amount: initialAmount
      });
    } else {
      _.remove(paymentDetails.payments, (payment) => payment.dueId === dueId);
    }
    paymentDetails.balance = this.getBalance(paymentDetails);
    this.setState({ paymentDetails });
  }

  handleAmountChange(event) {
    const dueId = event.target.name;
    const amount = typeof event.target.value === 'string' ? event.target.value.replace(',', '.') : event.target.value;
    const amountNumber = tryConvertToNumber(amount);
    const { paymentDetails } = this.state;
    paymentDetails.payments = [...paymentDetails.payments];
    const otherPaymentsSum = _.round(
      _.sumBy(
        this.state.paymentDetails.payments.filter((payment) => payment.dueId !== dueId),
        (payment) => payment.amount
      ),
      2
    );
    const payment = _.find(paymentDetails.payments, (p) => p.dueId === dueId);

    if (payment) {
      if (
        !this.state.paymentDetails.paymentAmount ||
        amountNumber > _.round(this.state.paymentDetails.paymentAmount - otherPaymentsSum, 2)
      ) {
        payment.amount = 0;
      } else {
        payment.amount = amount;
      }
    }

    paymentDetails.balance = this.getBalance(paymentDetails);

    return this.setState({ paymentDetails });
  }

  getBalance(paymentDetails) {
    const balanceAmount = this.calculateBalanceAmount(paymentDetails);
    if (!balanceAmount || paymentDetails.paymentMethod === PaymentMethods.fromBalance.value) return null;
    return {
      method: paymentDetails.paymentMethod,
      date: paymentDetails.paymentDate,
      amount: this.calculateBalanceAmount(paymentDetails)
    };
  }

  getDueAmount(dueId) {
    const payment = _.find(this.state.paymentDetails.payments, (p) => p.dueId === dueId);
    return payment ? payment.amount : '';
  }

  dueAmountDisabled(dueId) {
    return !this.state.paymentDetails.payments.some((payment) => payment.dueId === dueId);
  }

  calculateBalanceAmount(paymentDetails) {
    const paymentsSum = _.sumBy(paymentDetails.payments, (payment) => _.round(payment.amount || 0, 2));
    const amount = _.round(paymentDetails.paymentAmount || 0, 2) - _.round(paymentsSum, 2);
    return _.round(amount || 0, 2);
  }

  renderSummary() {
    return (
      <Typography sx={{ mt: 1 }}>
        Stan środków po rozliczeniu{' '}
        <Strong>
          {numberFormatter.format(this.calculateBalanceAmount(this.state.paymentDetails) + this.props.availableFunds)}{' '}
          zł
        </Strong>
      </Typography>
    );
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing, settlement, dues, isLoading } = this.props;
    const { paymentDetails } = this.state;
    return (
      <DialogForm
        header={`Rozliczanie opłaty numer ${settlement.number}`}
        onSave={() => onSave(this.state.paymentDetails)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing || isLoading}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="registerPaymentUi">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Box>
              Aktualny stan środków <Strong>{numberFormatter.format(this.props.availableFunds)} zł</Strong>
            </Box>
            <Typography>
              Rejestracja wpłaty za <strong>{ChargingReportTypes[settlement.dueType].name.toLowerCase()}</strong> w
              okresie <Strong>{settlement.month}</Strong>.
            </Typography>
            <Typography>
              Pracownik{' '}
              <Strong>
                {settlement.person.firstName} {settlement.person.lastName}
              </Strong>
            </Typography>
            <Typography>
              Kwota <Strong>{numberFormatter.format(settlement.getTotalAmount())} zł</Strong>
            </Typography>
            <Box>
              <Typography
                sx={{
                  pl: 0,
                  fontSize: 12,
                  color: (theme) => theme.palette.color.contrast,
                  opacity: 0.5
                }}>
                Sposób płatności
              </Typography>
              <FormControl>
                <RadioGroup
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                  onChange={(e, value) => this.handleParamsChange({ target: { name: 'paymentMethod', value } })}
                  defaultSelected={PaymentMethods.cash.value}
                  name="paymentMethod">
                  <FormControlLabel
                    key={PaymentMethods.cash.value}
                    label={PaymentMethods.cash.name}
                    value={PaymentMethods.cash.value}
                    disabled={false}
                    control={<Radio />}
                  />
                  {/* <Radio
                key={PaymentMethods.cash.value}
                label={PaymentMethods.cash.name}
                value={PaymentMethods.cash.value}
                disabled={false}
              /> */}
                  <FormControlLabel
                    key={PaymentMethods.transfer.value}
                    label={PaymentMethods.transfer.name}
                    value={PaymentMethods.transfer.value}
                    disabled={false}
                    control={<Radio />}
                  />
                  {/* <Radio
                key={PaymentMethods.transfer.value}
                label={PaymentMethods.transfer.name}
                value={PaymentMethods.transfer.value}
                disabled={false}
              /> */}
                  <FormControlLabel
                    key={PaymentMethods.fromBalance.value}
                    label={PaymentMethods.fromBalance.name}
                    value={PaymentMethods.fromBalance.value}
                    disabled={false}
                    control={<Radio />}
                  />
                  {/* <Radio
                key={PaymentMethods.fromBalance.value}
                label={PaymentMethods.fromBalance.name}
                value={PaymentMethods.fromBalance.value}
                disabled={false}
              /> */}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ py: 1, px: 0 }}>
              <TextField
                name="paymentAmount"
                value={paymentDetails.paymentAmount}
                hintText="Wprowadź kwotę"
                floatingLabelText="Kwota wpłaty"
                floatingLabelFixed={true}
                onChange={this.handleParamsChange}
                sx={{ m: 0 }}
                onBlur={(e) => {
                  this.handleParamsChange({
                    target: { name: 'paymentAmount', value: tryConvertToNumber(e.target.value) }
                  });
                }}
                disabled={this.state.paymentDetails.paymentMethod === PaymentMethods.fromBalance.value}
              />
              <DatePicker
                hintText="Data wpłaty"
                floatingLabelText="Data wpłaty"
                floatingLabelFixed={false}
                name="paymentDate"
                value={new Date(paymentDetails.paymentDate)}
                errorText={this.state.errors.paymentDate}
                minDate={new Date('2000/01/01')}
                a
                maxDate={new Date()}
                onChange={(empty, date) => {
                  this.handleParamsChange({
                    target: { name: 'paymentDate', value: moment(date).format('YYYY-MM-DD') }
                  });
                }}
                sx={{ width: 200, ml: 3 }}
              />
            </Box>
            <TableContainer>
              <Table size="small" aria-label="Tabela rejestracji opłat">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 30 }} />
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Numer
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Opłata
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Kwota
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Do zapłaty
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Wpłata
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dues.map((due, index) => (
                    <TableRow key={due.id}>
                      <TableCell sx={{ minWidth: 30 }} align="center">
                        <Checkbox
                          color="primary"
                          inputProps={{ 'aria-label': `Wybierz wiersz ${index}` }}
                          onChange={(e) => this.handleCheck(due.id, e.target.checked, due.getOutstandingAmount())}
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 50 }} align="left">
                        {due.number}
                      </TableCell>
                      <TableCell sx={{ minWidth: 50 }} align="left">
                        {ChargingReportTypes[due.dueType].name}
                      </TableCell>
                      <TableCell sx={{ minWidth: 50 }} align="left">
                        <strong>{numberFormatter.format(due.getTotalAmount())}</strong>
                      </TableCell>
                      <TableCell sx={{ minWidth: 50 }} align="left">
                        <strong>{numberFormatter.format(due.getOutstandingAmount())}</strong>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150, maxWidth: 200 }} align="right">
                        <TextField
                          sx={{ minWidth: '180px', height: 80 }}
                          fullWidth={true}
                          floatingLabelText="Podaj kwote  "
                          name={due.id}
                          disabled={this.dueAmountDisabled(due.id)}
                          value={this.getDueAmount(due.id)}
                          onChange={this.handleAmountChange}
                          endAdornment={<InputAdornment position="end">zł</InputAdornment>}
                          onBlur={(e) => {
                            this.handleAmountChange({
                              target: { name: due.id, value: tryConvertToNumber(e.target.value) }
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <Typography variant="caption" sx={{ ml: 2, color: (theme) => theme.palette.color.contrast }}>
                  Tabela rejestracji opłat
                </Typography>
              </Table>
            </TableContainer>
            {this.state.paymentDetails.paymentMethod !== PaymentMethods.fromBalance.value ? this.renderSummary() : null}
          </>
        )}
      </DialogForm>
    );
  }
}

RegisterStaffMemberPaymentDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  settlement: PropTypes.object.isRequired,
  person: PropTypes.object,
  dues: PropTypes.array,
  availableFunds: PropTypes.number
};

function mapStateToProps(state) {
  return {
    dues: _.orderBy(state.staffDues.dues, ['dueType'], ['asc']),
    availableFunds: state.staffDues.balance.availableFunds || 0,
    isLoading: state.staffDues.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStaffMemberPaymentDialog);
