import React from 'react';
import PropTypes from 'prop-types';
import * as GlobalTypes from '../../constants/groupsAndActivitiesTypes';
import PhotoLibraryIcon from '@mui/icons-material/CollectionsOutlined';
import StarIcon from '@mui/icons-material/StarBorderOutlined';
import PublicIcon from '@mui/icons-material/Public';
import MoreActivitiesIcon from '../../assets/icons/MoreActivitiesIcon';
import MoreGroupsIcon from '@mui/icons-material/GroupsOutlined';
import GroupIcon from '@mui/icons-material/PeopleAltOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const SpecificIcon = ({ type, className, ...rest }) => {
  switch (type) {
    case GlobalTypes.GROUP:
      return <GroupIcon className={className} {...rest} />;
    case GlobalTypes.MORE_GROUPS:
      return <MoreGroupsIcon className={className} {...rest} />;
    case GlobalTypes.ACTIVITY:
      return <StarIcon className={className} {...rest} />;
    case GlobalTypes.MORE_ACTIVITIES:
      return <MoreActivitiesIcon className={className} {...rest} />;
    case GlobalTypes.PUBLIC:
      return <PublicIcon className={className} {...rest} />;
    case GlobalTypes.NONE:
      return <ClearOutlinedIcon className={className} {...rest} />;
    default:
      return <PhotoLibraryIcon className={className} {...rest} />;
  }
};

SpecificIcon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default SpecificIcon;
