import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, useTheme, Box, Typography } from '@mui/material';
import FileSpecificIcon from '../../../../components/common/FileSpecificIcon/FileSpecificIcon';
import FileShareAvatars from '../../../../components/virtualDisc/components/FileShareAvatars/FileShareAvatars';
import ErrorIcon from '@mui/icons-material/Error';
import AccessibleTooltip from '../../../../components/common/AccessibleTooltip';
import { useShareFileContext } from '../../context/ShareFileContext';

const ShareFileHeaderFilesList = ({ selectedItems, missingPermissionsItemsArray }) => {
  const { isMobile, isDisabled, isSingleShare, isFolder } = useShareFileContext();
  const theme = useTheme();
  return (
    <>
      {!isMobile && (
        <Typography
          align="center"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightMedium
          }}>
          {isFolder ? 'Wybrany folder' : isSingleShare ? 'Wybrany plik' : 'Wybrane pliki'}
        </Typography>
      )}
      <List sx={{ pb: 0 }}>
        {selectedItems.map((item) => {
          const isInvalid = missingPermissionsItemsArray.some((invalidItem) => invalidItem.id === item.id);
          return (
            <ListItem
              key={item.id}
              sx={{
                background: isDisabled ? theme.palette.background.color3 : theme.palette.background.color5,
                borderRadius: 50,
                display: 'inline-flex',
                width: 'auto',
                flexWrap: 'nowrap',
                margin: 1,
                zIndex: theme.zIndex.fab
              }}>
              <ListItemIcon>
                <FileSpecificIcon type={item.type === 'folder' ? 'folder' : item.mimeType} item={item} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: (theme) => theme.palette.text.primary, mr: 1 }}>{item.name}</Typography>
                    <FileShareAvatars
                      userRolesList={item.shareForUserRoles}
                      isShareToUsers={item.shareForUserIds.length > 0}
                      isOwnByUser={item.isOwnByUser}
                      containerStyles={{ width: 'auto', flexGrow: 1, gridAutoFlow: 'column' }}>
                      {isInvalid && (
                        <AccessibleTooltip
                          text={`Nie posiadasz uprawnień do udostępniania wybranego ${isFolder ? 'folderu' : 'pliku'}`}>
                          <ErrorIcon sx={{ fontSize: 30, color: theme.palette.color.color10 }} />
                        </AccessibleTooltip>
                      )}
                    </FileShareAvatars>
                  </Box>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

ShareFileHeaderFilesList.propTypes = {
  selectedItems: PropTypes.array,
  missingPermissionsItemsArray: PropTypes.array
};

export default ShareFileHeaderFilesList;
