import React from 'react';
// import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

//v5
import { Tooltip } from '@mui/material';

function useEventListener(eventName, handler, element = window) {
  React.useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    element.addEventListener(eventName, handler);

    // eslint-disable-next-line consistent-return
    return () => element.removeEventListener(eventName, handler);
  }, [eventName, element]);
}

const AccessibleTooltip = ({ text, children, ...rest }) => {
  const [open, setOpen] = React.useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handler = React.useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, []);

  useEventListener('keydown', handler);

  return (
    <Tooltip
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      // interactive
      title={text}
      aria-label={text}
      {...rest}>
      {children}
    </Tooltip>
  );
};

AccessibleTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default AccessibleTooltip;
