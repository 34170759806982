import initialState from './initial';
import * as types from '../actions/actionTypes';
import { MealDetailsModel } from '../models/meals/MealModels';

export default function mealsDetailsReducer(state = initialState.mealDetails, action) {
  switch (action.type) {
    case types.LOAD_MEAL_DETAILS_SUCCESS: {
      return new MealDetailsModel().assign(action.meal);
    }
    default:
      return state;
  }
}
