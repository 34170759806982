import React from 'react';

import { useTheme, useMediaQuery } from '@mui/material';

const withMediaQuery = (size) => (Component) => (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isRequiredSize = size ? useMediaQuery(theme.breakpoints.down(size)) : null;
  return <Component isMobile={isMobile} isRequiredSize={isRequiredSize} {...props} />;
};

export default withMediaQuery;
