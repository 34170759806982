import queryString from 'query-string';
import uuid from 'uuid/v4';
import ApiBase, { ROOT_URL } from './ApiBase';

class PaymentsApi extends ApiBase {
  static createOnlinePayment(dueIds) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/payments/`).send({ dueIds }).set('Location', '').type('application/json'),
      (response) => response.header.location
    );
  }

  static postAsFormToGateway(paymentUrl) {
    const postUrl = /(https.*)\?/.exec(paymentUrl)[1];
    const data = queryString.parse(queryString.extract(paymentUrl));
    const form = document.createElement('form');
    form.id = uuid();
    form.method = 'POST';
    form.action = postUrl;
    Object.keys(data).forEach((key) => {
      const element = document.createElement('input');
      element.type = 'hidden';
      element.name = key;
      element.value = data[key];

      form.appendChild(element);
    });

    document.body.appendChild(form);
    form.submit();
  }
}

export default PaymentsApi;
