import initialState from './initial';
import * as types from '../actions/actionTypes';

const childBalanceSummaryReducer = (state = initialState.childBalanceSummary, action) => {
  switch (action.type) {
    case types.LOAD_CHILD_BALANCE_SUMMARY:
      return {
        ...initialState.childBalanceSummary,
        isLoading: true
      };
    case types.LOAD_CHILD_BALANCE_SUMMARY_SUCCESS:
      return {
        balanceAmount: action.childBalanceSummary.balanceAmount,
        overdueAmount: action.childBalanceSummary.overdueAmount,
        interestAmount: action.childBalanceSummary.interestAmount,
        isLoading: false
      };
    default:
      return state;
  }
};

export default childBalanceSummaryReducer;
