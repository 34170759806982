import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ReportTypes } from '../../../constants/reportTypes';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Typography, Box, MenuItem } from '@mui/material';
import GroupAndMonthTemplate from './common/YearMonthAndGroupPickerTemplate';

const chargingTypes = [
  ChargingReportTypes.stay,
  ChargingReportTypes.catering,
  ChargingReportTypes.other,
  ChargingReportTypes.staffCatering,
  ChargingReportTypes.activities
];

class DetailedPaymentsSummaryPerChildReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'name',
      sortOptions: [
        { name: 'Po nazwisku', value: 'name' },
        { name: 'Po numerze', value: 'documentNumber' }
      ],
      dueType: ChargingReportTypes.stay.value,
      showInterest: false
    };
  }

  render() {
    const { month, year, onChange, groups, errors } = this.props;
    return (
      <Box>
        <GroupAndMonthTemplate
          errors={errors}
          manyGroupsSelector
          month={month}
          year={year}
          onChange={onChange}
          groups={groups}
          legend={ReportTypes.detailedPaymentsSummaryPerChild.name}
        />
        <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.simplifiedPaymentsSummaryPerChild.name}`}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Ustawienia raportu
          </Typography>
          <SelectField
            contrast
            sx={{ mt: 2 }}
            floatingLabelText="Rodzaj opłat"
            id="dueType"
            value={this.state.dueType}
            onChange={(event) => {
              this.setState({ dueType: event.target.value });
              onChange({ target: { name: 'dueType', value: event.target.value } });
            }}>
            {chargingTypes.map((type) => {
              return (
                <MenuItem key={type.value} value={type.value} className="menu-item">
                  {type.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            sx={{ mt: 2 }}
            floatingLabelText="Sortowanie"
            id="sortBy"
            value={this.state.sortBy}
            onChange={(event) => {
              this.setState({ sortBy: event.target.value });
              onChange({ target: { name: 'sortBy', value: event.target.value } });
            }}>
            {this.state.sortOptions.map((so) => {
              return (
                <MenuItem key={so.value} value={so.value} className="menu-item">
                  {so.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <Box sx={{ mt: 2 }}>
            <Checkbox
              label="Uwzględnij odsetki"
              defaultChecked={this.state.showInterest}
              onCheck={(e, isChecked) => {
                this.setState({ showInterest: isChecked });
                onChange({ target: { name: 'showInterest', value: isChecked } });
              }}
            />
          </Box>
        </FieldsetWrapper>
      </Box>
    );
  }
}

DetailedPaymentsSummaryPerChildReport.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DetailedPaymentsSummaryPerChildReport;
