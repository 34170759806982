import { Autocomplete, FormControl, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from '../../common/DatePicker';

const PsychologicalHelpForm = ({ selectedHelp, setData, errors }) => {
  const [child, setChild] = useState(selectedHelp);
  const [fromDate, setFromDate] = useState(selectedHelp.from ? selectedHelp.from : null);
  const [toDate, setToDate] = useState(selectedHelp.to ? selectedHelp.to : null);
  const [cause, setCause] = useState(selectedHelp.reason);
  const childrenList = useSelector((state) => state.children);
  const psychologicalChildrenList = useSelector((state) => state.psychologicalChildrenList);

  const handleChildChange = (e) => {
    setChild(e);
  };

  useEffect(() => {
    setData({ child: child, from: fromDate, to: toDate, cause: cause });
  }, [cause, child, fromDate, setData, toDate]);

  return (
    <FormControl fullWidth>
      {selectedHelp.length ? (
        <Typography>{selectedHelp.childFirstName + ' ' + selectedHelp.childLastName}</Typography>
      ) : (
        <Autocomplete
          value={childrenList.filter((child) => child.firstName === selectedHelp.name)[0]}
          options={childrenList.filter(
            (child) => !psychologicalChildrenList.find((psychologicalChild) => psychologicalChild.childId === child.id)
          )}
          getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Wybierz dziecko*"
              variant="standard"
              error={errors?.errors?.child}
              helperText={errors?.errors?.child}
            />
          )}
          onChange={(event, newValue) => {
            handleChildChange(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <li
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                {...props}
                key={option.id}>
                <Typography sx={{ ml: 1, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                  {option.firstName + ' ' + option.lastName}
                </Typography>
              </li>
            );
          }}
        />
      )}
      <Box sx={{ display: 'flex' }}>
        <DatePicker
          errorText={errors?.errors?.from}
          unlimitedMaxDate
          sx={{ my: 1, mr: 2, width: 150 }}
          hintText="Data od*"
          floatingLabelText="Data od*"
          name="date_from"
          onChange={(empty, date) => {
            setFromDate(date);
          }}
          value={fromDate}
        />
        <DatePicker
          errorText={errors?.errors?.to}
          unlimitedMaxDate
          sx={{ my: 1, width: 150 }}
          hintText="Data do*"
          floatingLabelText="Data do*"
          name="date_to"
          onChange={(empty, date) => {
            setToDate(date);
          }}
          value={toDate}
        />
      </Box>
      <TextField
        error={errors?.errors?.cause}
        helperText={errors?.errors?.cause}
        multiline
        rows={4}
        sx={{ my: 1 }}
        id="cause"
        label="Przyczyna*"
        InputProps={{ 'aria-label': 'Przyczyna' }}
        variant="outlined"
        value={cause}
        onChange={(e) => setCause(e.target.value)}
      />
    </FormControl>
  );
};

export default PsychologicalHelpForm;
