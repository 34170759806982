import * as types from './actionTypes';
import api from '../api/DigitalDiaryApi';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function initLoadWorkPlanSetStart(date) {
  return (dispatch, getState) => {
    const { choosenGroup } = getState().digitalDiary;
    if (choosenGroup.id) {
      return api
        .loadWorksPlans({ eventDate: date, group: choosenGroup.id })
        .then((data) => {
          dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS });
          dispatch({
            type: types.LOAD_DIGITAL_DIARY_WORKPLAN_SETS_SUCCESS,
            data
          });
        })
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać danych'));
          return logger.error(error);
        });
    }
    return null;
  };
}

export function loadWorkPlanSetStart(set) {
  return (dispatch) => {
    dispatch({ type: types.PRELOAD_ON_NAVIGATION });
    return api
      .loadWorksPlans(set)
      .then((data) => {
        dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS });
        dispatch({
          type: types.LOAD_DIGITAL_DIARY_WORKPLAN_SETS_SUCCESS,
          data
        });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać danych'));
        dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS });
        return logger.error(error);
      });
  };
}

export function addWorkPlanSetStart() {
  return { type: types.CREATE_DIGITAL_DIARY_WORKPLAN_SET };
}

export function addWorkPlanSetCancel() {
  return { type: types.CREATE_DIGITAL_DIARY_WORKPLAN_SET_CANCEL };
}

export function addWorkPlanEducationalSet(set) {
  return (dispatch) => {
    dispatch({
      type: types.CREATE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS,
      set
    });
    dispatch(
      notificationActions.showWarning(
        `Dodano zamierzenie wychowawczo-dydaktyczne '${set.goalName}'. Pamiętaj o zapisaniu zmian.`
      )
    );
  };
}

export function addWorkPlanTopicSet(set) {
  return (dispatch) => {
    dispatch(notificationActions.showWarning(`Dodano temat '${set.topicName}'. Pamiętaj o zapisaniu zmian.`));
    dispatch({
      type: types.CREATE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS,
      set
    });
  };
}

export function updateWorkPlanSetStart() {
  return { type: types.UPDATE_DIGITAL_DIARY_WORKPLAN_SET };
}

export function updateWorkPlanSetCancel() {
  return { type: types.UPDATE_DIGITAL_DIARY_WORKPLAN_SET_CANCEL };
}

export function updateWorkPlanEducationalSet(set) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS,
      set
    });
    dispatch(
      notificationActions.showWarning(
        `Zaktualizowano zamierzenie wychowawczo-dydaktyczne '${set.newGoal.goalName}'. Pamiętaj o zapisaniu zmian.`
      )
    );
  };
}

export function updateWorkPlanTopicSet(set) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS,
      set
    });
    dispatch(
      notificationActions.showWarning(`Zaktualizowano temat '${set.newTopic.topicName}'. Pamiętaj o zapisaniu zmian.`)
    );
  };
}

export function removeWorkPlanEducationalSet(set) {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS, set });
    dispatch(
      notificationActions.showWarning(`Usunięto zamierzenie wychowawczo-dydaktyczne. Pamiętaj o zapisaniu zmian.`)
    );
  };
}

export function removeWorkPlanTopicSet(set) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS,
      set
    });
    dispatch(notificationActions.showWarning(`Usunięto temat. Pamiętaj o zapisaniu zmian.`));
  };
}

export function saveWorkPlanData(set) {
  return (dispatch) => {
    dispatch({ type: types.SAVEDATA_DIGITAL_DIARY_WORKPLAN });
    api
      .addNewWorkPlan(set)
      .then((newWorkPlan) => {
        dispatch(notificationActions.showSuccess('Pomyślnie zapisano plan pracy'));
        dispatch({ type: types.SAVEDATA_DIGITAL_DIARY_WORKPLAN_SUCCESS, set: newWorkPlan });
      })
      .catch(() => {
        dispatch(notificationActions.showError('Wystąpił błąd podczas zapisu planu pracy'));
      });
  };
}

export function updateWorkPlanData(set) {
  return (dispatch) => {
    dispatch({ type: types.SAVEDATA_DIGITAL_DIARY_WORKPLAN });
    api
      .updateWorkPlan(set)
      .then(() => {
        dispatch(notificationActions.showSuccess('Pomyślnie zaktualizowano plan pracy'));
        dispatch({ type: types.SAVEDATA_DIGITAL_DIARY_WORKPLAN_SUCCESS, set });
      })
      .catch(() => {
        dispatch(notificationActions.showError('Wystąpił błąd podczas zapisu planu pracy'));
      });
  };
}
