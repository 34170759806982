import ApiBase, { ROOT_URL } from './ApiBase';

class PublisherApi extends ApiBase {
  static getCourseBookByPublisherId(publisherId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/publisherHouse/${publisherId}`));
  }

  static getBookdetailsById(bookId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/publisherHouse/book/${bookId}`));
  }

  static getPublishers() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/publisherHouse/`));
  }

  static clearDefaultPublisher() {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/defaultPublishingHouseId`)
        .send({ defaultPublishingHouseId: '' })
        .type('application/json')
    );
  }

  static updateDefaultPublisher(defaultPublishingHouseId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/defaultPublishingHouseId`)
        .send({ defaultPublishingHouseId })
        .type('application/json')
    );
  }
}

export default PublisherApi;
