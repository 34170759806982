'use strict';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker as Picker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { useTheme } from '@emotion/react';

const TimePicker = (props) => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Picker
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        id={props.name}
        label={props.floatingLabelText || 'Godzina'}
        value={props.value || null}
        cancelText="Anuluj"
        ampm={false}
        okText="Zatwierdź"
        onChange={(value, context) => {
          const momentValue = moment(value);
          if (context || momentValue.isValid()) props.onChange(momentValue, momentValue);
          else props.onChange();
        }}
        renderInput={(params) => (
          <TextField
            variant="standard"
            error={!!props.errorText}
            helperText={props.errorText}
            {...params}
            sx={{
              minWidth: 110,
              maxWidth: 500,

              mr: 1,
              svg: {
                color: props.errorText ? theme.palette.color.error : theme.palette.color.contrast
              },
              input: {
                color: props.errorText ? theme.palette.color.error : theme.palette.color.contrast
              },
              label: {
                color: props.errorText ? theme.palette.color.error : theme.palette.color.contrast,
                '&.Mui-focused': {
                  color: props.errorText ? theme.palette.color.error : theme.palette.color.contrast
                }
              },
              color: (theme) => theme.palette.color.error,
              '& .Mui-error': {
                color: (theme) => theme.palette.color.error
              },
              '& .MuiFormHelperText-root': {
                color: (theme) => theme.palette.color.error
              }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

TimePicker.propTypes = {
  floatingLabelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string
};

export default TimePicker;

// <MaterialTimePicker
//   {...props}
//   okLabel="OK"
//   cancelLabel="Anuluj"
//   format="24hr"
// />
