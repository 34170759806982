import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import GroupsList from './GroupsArchiveList';
import Paper from '@mui/material/Paper';
import GroupIcon from '@mui/icons-material/Group';
import FilterBar from '../common/FilterBar';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box, Button, Grid } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';
import { fromTemplate, routePaths } from '../../routePaths';
import { browserHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

class GroupsArchivePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredGroups: []
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  handleLoaded() {
    this.setState({
      filteredGroups: this.props.groups
    });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredGroups: filteredList
    });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Zarchiwizowane grupy" titleIcon={<GroupIcon />} />
          <ContentWrapper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FilterBar
                  itemsToFilter={this.props.groups}
                  onFilter={this.handleFilterChange}
                  filter={(group, keyword) => group.name.toLowerCase().includes(keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  sx={{ mb: { xs: 0, md: 4 }, mt: { xs: 2, md: 0 } }}
                  variant="outlined"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => browserHistory.replace(fromTemplate(routePaths.groups))}>
                  wróć do listy grup
                </Button>
              </Grid>
            </Grid>
            <Paper sx={{ mt: 2, p: 1 }}>
              <GroupsList groups={this.state.filteredGroups} />
            </Paper>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GroupsArchivePage.propTypes = {
  groups: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    groups: state.archives.groups
  };
}

export default connect(mapStateToProps)(GroupsArchivePage);
