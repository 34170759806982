import { Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { useCallback } from 'react';
import { MealModel } from '../../models/meals/MealModels';
import MealAvatar from './MealAvatar';

export const MealPicker = ({ meals, categories, handleSelect }) => {
  const handleSelectMeal = useCallback(
    (meal) => {
      handleSelect(new MealModel().assign(meal));
    },
    [handleSelect]
  );

  return (
    <Box>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {meals.map((meal) => {
          const labelId = `meals-list-label-${meal.name}`;

          return (
            <ListItem key={meal.id} disablePadding>
              <ListItemButton
                sx={{ py: 1 }}
                role={undefined}
                dense
                onClick={() => {
                  handleSelectMeal(meal);
                }}>
                <ListItemAvatar>
                  <MealAvatar meal={meal} />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={meal.name}
                  primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
