import PropTypes from 'prop-types';
import React from 'react';
import SpecialLogo from '../../specialLogo/specialLogo';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import backgroundAdorment from '../../../assets/svg/hero-bg-dots.svg';
import image1 from '../../../assets/images/logo.png';
import badgeGoogle from '../../../assets/images/google-play-badge.png';
import badgeApple from '../../../assets/images/app-store-badge.png';
import { useSelector } from 'react-redux';

function WelcomeScreen({ children }) {
  const theme = useTheme();
  const selectedTheme = useSelector((state) => state.theme);
  const currentYear = new Date().getFullYear();
  return (
    <Box
      sx={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        // background: ` url(${backgroundAdorment}), transparent linear-gradient(33deg, ${theme.palette.background.color1} 0%, ${theme.palette.background.color1} 0%, ${theme.palette.background.color2} 100%) 0% 0% no-repeat padding-box`,
        background: (theme) =>
          selectedTheme !== 'contrast'
            ? ` url(${backgroundAdorment}), transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`
            : `  transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`,

        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
        alignItems: 'center'
      }}>
      <Grid container sx={{ maxWidth: 750, my: 10, px: 1 }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
            maxHeight: 1200,
            minHeight: 700,
            background: theme.palette.background.default,
            p: 2,
            borderRadius: '15px 0px 0px 15px ',
            [theme.breakpoints.down('sm')]: {
              height: '50%',
              width: '100%',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15
            },
            [theme.breakpoints.up('sm')]: {
              height: '100%'
            }
          }}>
          <SpecialLogo />
          {children}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              maxWidth: '100%',
              minHeight: { xs: '100%', md: 600 },
              maxHeight: 1200,
              [theme.breakpoints.down('sm')]: {
                height: '50%',
                width: '100%',
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15
              },
              [theme.breakpoints.up('sm')]: {
                height: '100%'
              },
              background: `transparent linear-gradient(149deg, ${theme.palette.background.color8} 0%, ${theme.palette.background.color2} 100%) 0% 0% no-repeat padding-box`,
              borderRadius: '0px 15px 15px 0px',
              px: 6,
              py: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            <img src={image1} alt="logo" />
            <Typography
              sx={{
                fontSize: 34,
                fontWeight: (theme) => theme.typography.fontWeightBold,
                letterSpacing: '0.26px',
                color: theme.palette.color.primary,
                my: 5,
                [theme.breakpoints.down('sm')]: {
                  fontSize: 28
                }
              }}>
              Pobierz darmową aplikację 4Parents
            </Typography>
            <Typography sx={{ letterSpacing: '0.12px', color: theme.palette.color.primary, mb: 6 }}>
              Rodzicu, zainstaluj aplikację i korzystaj z jej wszystkich zalet:
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <a
                target="_blank"
                style={{
                  background: `url(${badgeGoogle})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat'
                }}
                href="https://play.google.com/store/apps/details?id=com.softwarehub.for_parents&gl=PL">
                <Button
                  sx={{
                    height: 37,
                    width: 120
                  }}
                />
              </a>
              <a
                target="_blank"
                style={{
                  background: `url(${badgeApple}) `,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat'
                }}
                href="https://apps.apple.com/pl/app/4parents/id1191079838?l=pl">
                <Button
                  sx={{
                    height: 37,
                    width: 110
                  }}
                />
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ maxWidth: 750, color: theme.palette.color.primary, px: 2 }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: 14 }}>© Copyrights 2017 - {currentYear} Software Hub sp. z o.o. </Typography>
          <Typography sx={{ fontSize: 14 }}>02-495 Warszawa, ul. Ryżowa 33a/7 </Typography>
          <Typography sx={{ fontSize: 14 }}>+48 22 398 74 85 | biuro@softwarehub.pl</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'end',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
              alignItems: 'start',
              my: 1
            }
          }}>
          <Typography sx={{ fontSize: 14 }}>Inspektor ochrony danych </Typography>
          <Typography sx={{ fontSize: 14 }}>Małgorzata Ganczar </Typography>
          <Typography sx={{ fontSize: 14 }}>iod@softwarehub.pl</Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 2,
              color: theme.palette.color.primary,
              fontSize: 14
            }}>
            <Typography sx={{ fontSize: 14 }}>
              KRS: 0000629823, NIP: 7831745232, REGON: 36508360600000. Kapitał zakładowy 100 000 PLN (wpłacony w
              całości).
            </Typography>

            <Typography sx={{ my: 5 }}>© 4Parents {currentYear} | sukces zaczyna się w Hubie</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

WelcomeScreen.propTypes = {
  children: PropTypes.element.isRequired
};

export default WelcomeScreen;
