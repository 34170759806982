import moment from 'moment';
import ApiBase, { ROOT_URL } from './ApiBase';

class PsychologicalApi extends ApiBase {
  static loadMonthlyIndividualPsychologicalAttendance(childId, date) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/individualClasses/child/${childId}/monthlyAttendance/${date}`).type('application/json')
    );
  }

  static loadMonthlyGroupPsychologicalAttendance(assistanceId, childId, date) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/groupClasses/${assistanceId}/child/${childId}/monthlyAttendance/${date}`)
        .type('application/json')
    );
  }

  static reportChildAbsence(childId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/individualClasses/child/${childId}/reportAbsence`)
        .send({
          absenceFrom: moment().format('YYYY-MM-DD'),
          absenceTo: moment().format('YYYY-MM-DD'),
          absenceNotes: '',
          droppedOffAt: null,
          pickedUpAt: null
        })
        .type('application/json')
    );
  }

  static revertChildAbsence(childId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/individualClasses/child/${childId}/setDailyAttendanceEntry`)
        .send({
          absencesFrom: null,
          absencesTo: null,
          droppedOffAt: moment(),
          pickedUpAt: moment()
        })
        .type('application/json')
    );
  }

  static reportGroupPreviousAbsence(childId, groupId, absenceFrom, absenceTo, absenceNotes) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/groupClasses/${groupId}/child/${childId}/reportAbsence`)
        .send({
          childId: childId,
          absenceFrom: moment(absenceFrom).format('YYYY-MM-DD'),
          absenceTo: moment(absenceTo).format('YYYY-MM-DD'),
          absenceNotes: absenceNotes
        })
        .type('application/json')
    );
  }

  static revertIndividualCLassesChildAbsence(childId, from, to, assistanceId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/individualClasses/child/${childId}/removeAbsence`)
        .send({
          absencesFrom: from.date.format('YYYY-MM-DD'),
          absencesTo: to.date.format('YYYY-MM-DD')
        })
        .type('application/json')
    );
  }

  static revertGroupCLassesChildAbsence(childId, from, to, groupId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/groupClasses/${groupId}/child/${childId}/removeAbsence`)
        .send({
          absenceFrom: from.date.format('YYYY-MM-DD'),
          absenceTo: to.date.format('YYYY-MM-DD'),
          absenceNotes: ''
        })
        .type('application/json')
    );
  }

  static reportIndividualPreviousAbsence(childId, absenceFrom, absenceTo, absenceNotes) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/individualClasses/child/${childId}/reportAbsence`)
        .send({
          absenceFrom: absenceFrom.format('YYYY-MM-DD'),
          absenceTo: absenceTo.format('YYYY-MM-DD'),
          absenceNotes: absenceNotes
        })
        .type('application/json')
    );
  }

  static reportGroupPreviousAttendance(data) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/groupClasses/${data.groupId}/child/${data.childId}/setDailyAttendanceEntry`)
        .send({
          id: data.childId,
          droppedOffAt: data.droppedOfAt,
          pickedUpAt: data.pickedUpAt
        })
        .type('application/json')
    );
  }

  static reportIndividualPreviousAttendance(data) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/individualClasses/child/${data.childId}/setDailyAttendanceEntry`)
        .send({
          id: data.childId,
          droppedOffAt: data.droppedOfAt,
          pickedUpAt: data.pickedUpAt,
          absenceFrom: null,
          absencaTo: null
        })
        .type('application/json')
    );
  }
}

export default PsychologicalApi;
