import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DotsMenu from '../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';
import EmptyState from '../common/EmptyState';
import moment from 'moment';

const NotifyTable = ({ rows = [], psychologicalData, handleEdit, handleArchive, id, childId }) => {
  const generateChip = (status) => {
    switch (status) {
      case null:
        return <Box>Oczekuje</Box>;
      default:
        return <Box>Wysłano</Box>;
    }
  };

  return rows.length > 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontSize: 18 }}>Treść zawiadomienia</TableCell>
          <TableCell sx={{ fontSize: 18 }}>Data utworzenia</TableCell>
          <TableCell sx={{ fontSize: 18 }}>Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row, index) => (
          <TableRow
            onClick={() => handleEdit({ row: row, data: psychologicalData }, true)}
            key={row?.id || index}
            sx={{ '&:hover': { background: (theme) => theme.palette.background.hover }, cursor: 'pointer' }}>
            <TableCell>
              <Box
                sx={{
                  fontSize: 18,
                  maxWidth: 300,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}>
                {row.name}
              </Box>
            </TableCell>
            <TableCell sx={{ fontSize: 18 }}>{moment(row.creationDate).format('DD.MM.YYYY')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>{generateChip(row.receiptByParent)}</TableCell>
            <TableCell
              align="right"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              <DotsMenu>
                {() => [
                  <MenuItem key="edytuj" onClick={() => handleEdit({ row: row, data: psychologicalData }, false)}>
                    Edytuj
                  </MenuItem>,
                  <ActionConfirmationMenuItem
                    key="1"
                    onItemClick={() => {}}
                    actionLabel="Archiwizuj"
                    confirmationText="Czy na pewno chcesz zarchiwizować pismo pawiadamiające?"
                    confirmationTitle="Archiwizuj pismu pawiadamiającego"
                    confirmLabel="Archiwizuj"
                    onConfirm={() => {
                      handleArchive(row.id);
                    }}
                    onCancel={() => {}}
                  />

                  // <ActionConfirmationMenuItem
                  //   key="1"
                  //   onItemClick={() => {}}
                  //   actionLabel="Udostępnij rodzicowi"
                  //   confirmationText="Czy na pewno chcesz udostępnić plik?"
                  //   confirmationTitle="Udostępnij"
                  //   confirmLabel="Udostepnij"
                  //   onConfirm={() => {
                  //     console.log('udostępniono');
                  //   }}
                  //   onCancel={() => {}}
                  // />
                ]}
              </DotsMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <EmptyState contrast message="Brak pism zawiadamiających" />
  );
};

export default NotifyTable;
