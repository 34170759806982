import ApiBase, { ROOT_URL } from './ApiBase';
import moment from 'moment';

class StaffMembersApi extends ApiBase {
  static createStaffMember(member) {
    return super.toPromise(
      (agent) =>
        agent
          .post(`${ROOT_URL}/staffMembers`)
          .send({
            firstName: member.firstName,
            lastName: member.lastName,
            email: member.email,
            role: member.role
          })
          .type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateStaffMember(id, member) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/staffMembers/${id}`)
        .send({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          role: member.role
        })
        .type('application/json')
    );
  }

  static cashOutMoneyFromPersonalBalance(staffId, amount, method) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/staffMembers/${staffId}/cashBalance`).send({ amount, method }).type('application/json')
    );
  }

  static getStaffMembers() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/staffMembers`).type('application/json'));
  }

  static getStaffMembersArchive() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/staffMembers/archive`).type('application/json'));
  }

  static getStaffMemberDetails(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/staffMembers/${id}`).type('application/json'));
  }

  static getStaffMemberDetailsArchive(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/staffMembers/archive/${id}`).type('application/json'));
  }

  static archiveStaffMember(id) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/staffMembers/${id}/archive`).type('application/json'));
  }

  static updateChargingScheme(staffId, scheme) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/staffMembers/${staffId}/contract/schemes/${scheme.schemeId}`)
        .send({
          reliefId: scheme.reliefId,
          reliefExpiryDate: scheme.reliefExpiryDate ? moment(scheme.reliefExpiryDate).format('YYYY-MM-DD') : null,
          singleUse: scheme.singleUse
        })
        .type('application/json')
    );
  }

  static removeChargingScheme(staffId, schemeId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/staffMembers/${staffId}/contract/schemes/${schemeId}`).type('application/json')
    );
  }
}

export default StaffMembersApi;
