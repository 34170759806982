import { useCallback, useMemo, useState } from 'react';
import EmptyState from '../common/EmptyState';
import CardDialogEditForm from '../forms/CardDialogEditForm';
import ActionButton from '../forms/buttons/ActionButton';
import { EnrolledChildrenList } from './EnrolledChildrenList';
import { ChildrenListDialog } from './ChildrenListDialog';
import { useDispatch, useSelector } from 'react-redux';
import ChildrenPicker from './ChildrenPicker';
import { enrollChildrenToDietAsync, unrollChildrenToDietAsync } from '../../actions/dietActions';
import { Stack } from '@mui/material';
import DatePicker from '../common/DatePicker';
import moment from 'moment';

export const DietChildrenCard = ({ diet }) => {
  const dispatch = useDispatch();

  const [showUnrollChildrenDialog, setShowUnrollChildrenDialog] = useState(false);
  const [showEnrollChildrenDialog, setShowEnrollChildrenDialog] = useState(false);
  const [childrenToEnroll, setChildrenToEnroll] = useState([]);
  const [childrenToUnroll, setChildrenToUnroll] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const children = useSelector((state) => state.children);

  const [isUnrollmentLoading, setIsUnrollmentLoading] = useState(false);
  const [isEnrollmentLoading, setIsEnrollmentLoading] = useState(false);

  const enrolledChildren = useMemo(() => {
    return diet.enrolledChildren.map((childId) => children.find((child) => child.id === childId));
  }, [children, diet.enrolledChildren]);

  const unrolledChildrenIds = useMemo(() => {
    return children.filter((child) => !diet.enrolledChildren.includes(child.id)).map(({ id }) => id);
  }, [children, diet.enrolledChildren]);

  const handleUnrollChildrenDialogOpen = () => {
    setShowUnrollChildrenDialog(true);
  };

  const handleUnrollChildrenDialogClose = () => {
    setShowUnrollChildrenDialog(false);
  };

  const handleEnrollChildren = useCallback(
    async (children) => {
      try {
        console.log('enroll children', children);
        setIsEnrollmentLoading(true);
        await dispatch(
          enrollChildrenToDietAsync({
            menuId: diet.id,
            childIds: childrenToEnroll.map((child) => child.id),
            startDate: moment(startDate).format('YYYY-MM-DD')
          })
        );
        setShowEnrollChildrenDialog(false);
      } finally {
        setIsEnrollmentLoading(false);
      }
    },
    [childrenToEnroll, diet.id, dispatch, startDate]
  );

  const handleUnrollChildren = useCallback(
    async (children) => {
      try {
        console.log('unroll children', children);
        setIsUnrollmentLoading(true);
        await dispatch(
          unrollChildrenToDietAsync({
            menuId: diet.id,
            childIds: childrenToUnroll.map((child) => child.id)
          })
        );
        setShowUnrollChildrenDialog(false);
      } finally {
        setIsUnrollmentLoading(false);
      }
    },
    [childrenToUnroll, diet.id, dispatch]
  );

  const handleEnrollChildrenDialogOpen = () => {
    setShowEnrollChildrenDialog(true);
  };

  const handleEnrollChildrenDialogClose = () => {
    setShowEnrollChildrenDialog(false);
  };

  const renderUnrollChildrenActionButton = () => {
    return (
      <ActionButton
        variant="outlinedContrast"
        onAction={handleUnrollChildrenDialogOpen}
        actionLabel="odpisz dietę"
        inProgress={false}
      />
    );
  };

  const handleEnrolledChildrenChange = (childrenToEnroll) => {
    setChildrenToEnroll(childrenToEnroll);
  };

  const handleUnrolledChildrenChange = (childrenToUnroll) => {
    setChildrenToUnroll(childrenToUnroll);
  };

  const renderEditForm = () => {
    return (
      <Stack spacing={4} sx={{ height: '100%' }}>
        <DatePicker
          value={startDate}
          onChange={(date) => setStartDate(date)}
          name="startDate"
          floatingLabelText="Data rozpoczęcia obowiązywania"
        />
        {/* <Divider /> */}
        <ChildrenPicker
          name="childIds"
          allPupils={children}
          exclude={diet.enrolledChildren}
          onChange={handleEnrolledChildrenChange}
        />
      </Stack>
    );
  };

  const renderReadonlyForm = () => {
    return <EnrolledChildrenList enrolledChildren={enrolledChildren} diet={diet} />;
  };

  const renderUnrollChildrenDialog = () => {
    return (
      <ChildrenListDialog
        name="childIds"
        allPupils={children}
        exclude={unrolledChildrenIds}
        onChange={handleUnrolledChildrenChange}
        open={showUnrollChildrenDialog}
        onClose={handleUnrollChildrenDialogClose}
        onSelect={handleUnrollChildren}
        isLoading={isUnrollmentLoading}
      />
    );
  };

  return (
    <>
      <CardDialogEditForm
        maxWidth="md"
        title="Dzieci"
        subtitle={`Liczba: ${diet.enrolledChildren.length}`}
        isInitiallyExpanded={true}
        onSave={handleEnrollChildren}
        onCancel={handleEnrollChildrenDialogClose}
        onAction={handleEnrollChildrenDialogOpen}
        isProcessing={isEnrollmentLoading}
        noValidate
        isDialogOpen={showEnrollChildrenDialog}
        readonlyForm={renderReadonlyForm()}
        editForm={renderEditForm()}
        customActions={renderUnrollChildrenActionButton()}
        isEmpty={diet.enrolledChildren.length === 0}
        emptyForm={<EmptyState message={`Brak przypisanych dzieci do diety ${diet.name}`} contrast />}
        actionTitle="Przypisz dietę"
        actionLabel="Przypisz dietę"
      />
      {renderUnrollChildrenDialog()}
    </>
  );
};
