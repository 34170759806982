import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Message from './Message';
import WaypointScroll from '../../common/lazyLoaders/WaypointScroll';
import { connect } from 'react-redux';
import { Box, CircularProgress, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PersonAvatar from '../../forms/PersonAvatar';

const style = {
  refresh: {
    display: 'inline-block',
    position: 'relative'
  }
};

const MessagesList = ({
  messages,
  canLoadMore,
  onLoadMore,
  isLoading,
  isInitial,
  onSelect,
  onDelete,
  onUpdate,
  userId,
  thread,
  readonly,
  isContrastColor,
  files
}) => {
  const canSelect = (message) => {
    if (readonly) return false;
    const lastByUser = !!messages.find((m) => m.senderId === userId);
    return (
      lastByUser &&
      lastByUser.id === message.id &&
      !message.deletedAt &&
      onSelect != null &&
      message.senderId === userId
    );
  };

  const canDelete = (message) => !readonly && message.senderId === userId && !message.deletedAt;

  const getType = (message) => {
    if (!thread || thread.participants.some((p) => p.id === userId)) {
      return message.senderId === userId ? 'outgoing' : 'incoming';
    }

    return thread.participants.map((p) => p.id).indexOf(message.senderId) === 0 ? 'outgoing' : 'incoming';
  };

  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const itemsRefs = useRef({});
  const [isFocus, setIsFocus] = useState(false);

  const onDeleteMiddleware = (index) => {
    setDeleteDialogStatus(false);
    onDelete(index);
  };

  const previousMessage = (index) => {
    if (index < messages.length - 1) itemsRefs.current[index + 1].focus();
  };
  const nextMessage = (index) => {
    if (index > 0) itemsRefs.current[index - 1].focus();
  };
  const deleteMessage = (index) => {
    setDeleteDialogStatus(true);
    if (itemsRefs.current[index].querySelector('.delete-button')) {
      itemsRefs.current[index].querySelector('.delete-button').children[0].click();
    }
  };

  const handleKeyup = (e, index) => {
    e.preventDefault();
    if (e) {
      if (e.which === 38) previousMessage(index);
      else if (e.which === 40) nextMessage(index);
      else if (e.which === 39) deleteMessage(index);
    }
    return null;
  };

  let isMouseClick = false;

  const setMouseClick = () => {
    isMouseClick = !isMouseClick;
  };

  const onFocus = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (!isMouseClick && !deleteDialogStatus) {
        itemsRefs.current[0].focus();
        setIsFocus(true);
      }
    }
  };

  const onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocus(false);
    }
  };
  const getAuthor = (message) => {
    return thread.participants.find((p) => p.id === message.senderId);
  };
  return (
    <WaypointScroll
      customscrollbar
      aria-selected={true}
      isReverse
      autoScroll
      canLoadMore={canLoadMore}
      onLoadMore={onLoadMore}
      isLoading={isLoading}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseUp={setMouseClick}
      onMouseDown={setMouseClick}
      isFocus={isFocus}
      isInitial={isInitial}
      sx={{ display: 'flex', flexDirection: 'column', outline: 0 }}
      loadIndicator={
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} left={10} top={0} sx={style.refresh} />
        </Stack>
      }>
      {messages
        .map((message, index) => (
          <Box sx={{ mx: 2 }} key={message.id}>
            <Message
              selectUser={(x, index) => x.participants.filter((p) => p.id !== this.props.userId)[index]}
              message={message}
              canSelect={canSelect(message)}
              key={message.id}
              ref={itemsRefs}
              index={index}
              thread={thread}
              onCancelDeleteMessage={() => setDeleteDialogStatus(false)}
              handleKeyup={handleKeyup}
              onSelect={() => canSelect(message) && onSelect(message)}
              type={getType(message)}
              canDelete={!!onDelete && canDelete(message)}
              onDelete={onDeleteMiddleware}
              onUpdate={onUpdate}
              currentUserId={userId}
              filesWithPermission={files}
              isContrastColor={isContrastColor === 'contrast'}
              author={messages[index + 1]?.senderId !== message.senderId && getAuthor(message)?.fullName}
              avatar={
                messages[index - 1]?.senderId !== message.senderId && (
                  <PersonAvatar
                    initials
                    alt={getAuthor(message)?.fullName}
                    url={getAuthor(message)?.photoUrl}
                    fullName={getAuthor(message)?.fullName}
                    sx={{ width: 28, height: 28, fontSize: 14 }}
                  />
                )
              }
            />
          </Box>
        ))
        .reverse()}
    </WaypointScroll>
  );
};

MessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInitial: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  userId: PropTypes.string.isRequired,
  thread: PropTypes.object,
  readonly: PropTypes.bool,
  isContrastColor: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isContrastColor: state.contrastColor
  };
}

export default connect(mapStateToProps)(MessagesList);
