import * as types from '../actions/actionTypes';
import { ImportChildrenSummaryModel } from '../models/children/ChildModels';
import initialState from './initial';

export default function messageBoardUiReducer(state = initialState.configuration.importChildrenSummary, action) {
  switch (action.type) {
    case types.IMPORT_CHILDREN_SUMMARY_START:
      return Object.assign({}, new ImportChildrenSummaryModel(), action.result);
    default:
      return state;
  }
}
