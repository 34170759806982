import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as childrenActions from '../../actions/childrenActions';
import { StudentDetailsModel } from '../../models/children/ChildModels';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ContentWrapper from '../common/contentWrapper';
import FaceIcon from '@mui/icons-material/Face';
import StudentDetailsForm from './details/StudentDetailsForm';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box } from '@mui/material';

const StudentRegisterPage = () => {
  const pupil = new StudentDetailsModel();
  const dispatch = useDispatch();
  const symphonyIntegration = useSelector(
    (state) => state.configuration.settlementsSettings.symphonyIntegrationEnabled
  );

  const handleChildChangeSubmit = async (childDetails) => {
    await dispatch(childrenActions.createStudentAsync(childDetails));
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Dodaj ucznia" titleIcon={<FaceIcon />} />
        <ContentWrapper>
          <StudentDetailsForm
            pupil={pupil}
            onSubmit={handleChildChangeSubmit}
            symphony={symphonyIntegration}
            isInitiallyExpanded={true}
          />
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default StudentRegisterPage;
