import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DialogForm from '../forms/DialogForm';
import PersonAvatar from '../forms/PersonAvatar';
import EmptyState from '../common/EmptyState';
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { MealMultiPicker } from './MealMultiPicker';
import { MealModel } from '../../models/meals/MealModels';
import { useDispatch } from 'react-redux';
import { loadChildMealsByMonthAsync, updateMealByDayAsync } from '../../actions/mealCalendarActions';
import _ from 'lodash';

const MealsDetailsDialog = ({
  pupil,
  title,
  date,
  onCancel,
  isDialogOpen,
  meals,
  userRole,
  allMeals,
  allCategories
}) => {
  const dispatch = useDispatch();

  const mealDay = useMemo(() => {
    if (date !== false) {
      return meals.find((meal) => meal.date === date);
    }
    return null;
  }, [meals, date]);

  const [isAddMealSectionOpen, setIsAddMealSectionOpen] = useState(false);
  const [mealsToEdit, setMealsToEdit] = useState(mealDay !== null && mealDay !== undefined ? mealDay.meals : []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (mealDay !== null && mealDay !== undefined) {
      setMealsToEdit(mealDay.meals);
    }
  }, [mealDay]);

  const disableEditingInPast =
    date < moment().format('YYYY-MM-DD') && (userRole === 'legalGuardian' || userRole === 'staffMemberTeacher');

  const categoriesWithMeals = useMemo(() => {
    const groupedMeals = mealsToEdit.reduce((acc, meal) => {
      const { categoryName, categoryId } = meal;
      if (!acc[categoryId]) {
        acc[categoryId] = {
          categoryId: categoryId ? categoryId : _.uniqueId(),
          categoryName: categoryName ? categoryName : 'Dodatkowe posiłki',
          meals: []
        };
      }
      acc[categoryId].meals.push(meal);

      return acc;
    }, {});
    return Object.values(groupedMeals)
      .sort((a, b) => {
        return a.categoryName.localeCompare(b.categoryName);
      })
      .map((category) => ({
        ...category,
        meals: category.meals.sort((mealA, mealB) => mealA.name.localeCompare(mealB.name))
      }));
  }, [mealsToEdit]);

  const removeMeal = (mealId) => {
    const newMeals = mealsToEdit.filter((meal) => meal.id !== mealId);
    setMealsToEdit(newMeals);
  };

  const selectedModels = mealsToEdit.map((meal) => new MealModel().assign(meal));

  const selectedMealsIds = mealsToEdit.map((meal) => meal.id);

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(updateMealByDayAsync(pupil.id, date, selectedMealsIds));
      await dispatch(loadChildMealsByMonthAsync(pupil.id, moment(date).format('YYYY'), moment(date).format('MM')));
      onCancel();
      setIsAddMealSectionOpen(false);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, date, pupil, selectedMealsIds, onCancel]);

  return (
    <DialogForm
      maxWidth="xs"
      header={title}
      saveDisabled={disableEditingInPast}
      onSave={handleSave}
      onCancel={() => {
        onCancel();
        setMealsToEdit(mealDay !== null ? mealDay.meals : []);
        setIsAddMealSectionOpen(false);
      }}
      isDialogOpen={isDialogOpen}
      isProcessing={isLoading}
      onValidationDone={() => {}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PersonAvatar
          initials
          alt={pupil.firstName}
          url={pupil.photoUrl}
          firstName={pupil.firstName}
          lastName={pupil.lastName}
        />
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 18,

            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>{`${pupil.firstName} ${pupil.lastName}`}</Typography>
        <Typography
          sx={{
            textAlign: 'center',
            my: 1,
            mb: 2,
            mx: 0,
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {date}
        </Typography>
        {disableEditingInPast && (
          <Typography align="justify" sx={{ pb: 2, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
            Nie możesz edytować historycznych posiłków, aby edytować posiłki wybierz dzień dzisiejszy lub z przyszłości
          </Typography>
        )}
      </Box>
      {categoriesWithMeals.length !== 0 ? (
        <>
          {!isAddMealSectionOpen && (
            <>
              {categoriesWithMeals.map((category) => {
                return (
                  <Box sx={{ py: 1 }} key={category.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                        {category.categoryName}
                      </Typography>
                    </Box>
                    {category.meals.map((meal) => {
                      return (
                        <Box
                          key={meal.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                          <Typography>{meal.name}</Typography>
                          {!disableEditingInPast && (
                            <Button aria-label="Usuń" variant="clearText" onClick={() => removeMeal(meal.id)}>
                              Usuń
                            </Button>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
              {!disableEditingInPast && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                  <Button
                    aria-label="Dodaj niestandardowy posiłek"
                    variant="clearText"
                    onClick={() => setIsAddMealSectionOpen(true)}
                    sx={{
                      color: (theme) => theme.palette.color.color7,
                      '&:hover': {
                        color: (theme) => theme.palette.color.color7
                      }
                    }}>
                    dodaj niestandardowy posiłek
                  </Button>
                </Box>
              )}
            </>
          )}
          {isAddMealSectionOpen && (
            <MealMultiPicker
              meals={allMeals}
              categories={allCategories}
              selected={selectedModels}
              handleSelect={(meal) => {
                setMealsToEdit(meal);
              }}
            />
          )}
        </>
      ) : (
        <Box>
          {!isAddMealSectionOpen && <EmptyState contrast message="Brak posiłków przypisanych na dany dzień" />}
          {!disableEditingInPast && (
            <>
              {!isAddMealSectionOpen && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      aria-label="Dodaj posiłek"
                      variant="contained"
                      onClick={() => {
                        setIsAddMealSectionOpen(true);
                      }}>
                      Dodaj posiłek
                    </Button>
                  </Box>
                </>
              )}
              {isAddMealSectionOpen && (
                <MealMultiPicker
                  meals={allMeals}
                  categories={allCategories}
                  selected={selectedModels}
                  handleSelect={(meal) => {
                    setMealsToEdit(meal);
                  }}
                />
              )}
            </>
          )}
        </Box>
      )}
    </DialogForm>
  );
};

MealsDetailsDialog.propTypes = {
  pupil: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  meals: PropTypes.array.isRequired,
  userRole: PropTypes.string,
  allMeals: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired
};

export default MealsDetailsDialog;
