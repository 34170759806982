import ApiBase, { ROOT_URL } from './ApiBase';

class GroupsApi extends ApiBase {
  static getGroups() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/groups`).type('application/json'));
  }

  static getUserAssociatedGroups() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/groups`).type('application/json'));
  }

  static getGroupsArchive() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/groups/archive`).type('application/json'));
  }

  static getGroup(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/groups/${id}`).type('application/json'));
  }

  static getGroupArchive(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/groups/archive/${id}`).type('application/json'));
  }

  static createGroup(group) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/groups`).send(group).set('Location', '').type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateGroup(id, group) {
    return super.toPromise((agent) => agent.put(`${ROOT_URL}/groups/${id}`).send(group).type('application/json'));
  }

  static archiveGroup(id, archiveChildren, archiveLegalGuardians, archiveBoardPosts) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/groups/${id}/archive`)
        .send({ archiveChildren, archiveLegalGuardians, archiveBoardPosts })
        .type('application/json')
    );
  }

  //enrolled children

  static patchGroupChildren(id, childrenIds) {
    return super.toPromise((agent) =>
      agent.patch(`${ROOT_URL}/groups/${id}/children`).send(childrenIds).type('application/json')
    );
  }

  static removeChild(id, childId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/groups/${id}/children/${childId}`).send().type('application/json')
    );
  }

  //associated teachers

  static associateTeacher(id, teacherId) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/groups/${id}/teachers/${teacherId}`).send().type('application/json')
    );
  }

  static removeTeacher(id, teacherId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/groups/${id}/teachers/${teacherId}`).send().type('application/json')
    );
  }
}

export default GroupsApi;
