import PropTypes from 'prop-types';
import React from 'react';
import GuardiansWithoutPinList from './GuardiansWithoutPinList';
import _ from 'lodash';
import { Box, Button, Typography } from '@mui/material';
import StackRow from '../../common/StackRow';
import Strong from '../../common/Strong';

class PinDevicesList extends React.Component {
  constructor() {
    super();
    this.state = {
      showGuardians: false
    };
    this.handleShowGuardians = this.handleShowGuardians.bind(this);
  }

  handleShowGuardians() {
    this.setState({ showGuardians: !this.state.showGuardians });
  }

  renderGuardiansList() {
    return this.state.showGuardians && <GuardiansWithoutPinList guardians={this.props.guardians} />;
  }

  render() {
    const { guardians, onGeneratePins, enabled } = this.props;
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Lista opiekunów
          </Typography>
          <StackRow sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            {!!guardians.length && (
              <Button
                variant="outlinedContrast"
                aria-label={this.state.showGuardians ? 'Ukryj' : 'Pokaż'}
                sx={{ mr: 1 }}
                onClick={this.handleShowGuardians}
                disabled={!enabled}>
                {this.state.showGuardians ? 'Ukryj' : 'Pokaż'}
              </Button>
            )}
            <Button variant="contained" aria-label="Generuj piny" onClick={onGeneratePins} disabled={!enabled}>
              Generuj piny
            </Button>
          </StackRow>
        </Box>
        Obecnie <Strong>{_.sum(guardians.map((g) => g.guardians.length))}</Strong> opiekunów nie ma przypisanego pinu
        {this.renderGuardiansList()}
      </Box>
    );
  }
}

PinDevicesList.propTypes = {
  guardians: PropTypes.array.isRequired,
  onGeneratePins: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired
};

export default PinDevicesList;
