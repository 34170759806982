/*eslint indent:0*/
import initialState from './initial';
import { AttendanceJournalModel } from '../models/attendance/AttendanceModels';
import * as types from '../actions/actionTypes';

export default function attendanceReducer(state = initialState.attendance, action) {
  switch (action.type) {
    case types.LOAD_CURRENT_ATTENDANCE_SUCCESS: {
      return new AttendanceJournalModel().assign(action.attendanceJournal);
    }
    case types.LOAD_ALL_CURRENT_ATTENDANCE_SUCCESS: {
      return new AttendanceJournalModel().assign({
        groupId: null,
        date: action.attendanceJournals.length ? action.attendanceJournals[0].date : null,
        childrenAttendance: action.attendanceJournals
          .map((a) => a.childrenAttendance)
          .reduce((prev, next) => prev.concat(next))
      });
    }
    case types.LOAD_PREVIOUS_ATTENDANCE_SUCCESS: {
      return new AttendanceJournalModel().assign(action.attendanceJournal);
    }
    case types.LOAD_ALL_PREVIOUS_ATTENDANCE_SUCCESS: {
      return new AttendanceJournalModel().assign({
        groupId: null,
        date: action.attendanceJournals.length ? action.attendanceJournals[0].date : null,
        childrenAttendance: action.attendanceJournals
          .map((a) => a.childrenAttendance)
          .reduce((prev, next) => prev.concat(next))
      });
    }
    case types.REPORT_CHILD_DROPPED_OFF_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              droppedOffAt: action.time,
              receivedBy: action.receivedBy,
              isAdaptation: action.isAdaptation
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REPORT_CHILD_ATTENDANCE_NO_HOURS_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              droppedOffAt: action.droppedOffAt,
              pickedUpAt: action.pickedUpAt,
              receivedBy: action.receivedBy
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REPORT_CHILD_PICKED_UP_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              pickedUpAt: action.time,
              pickedUpBy: action.pickedUpBy,
              releasedBy: action.releasedBy
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REPORT_CHILD_ABSENCE_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              absenceFrom: action.from,
              absenceTo: action.to,
              absenceNotes: action.notes,
              absenceReportedBy: action.absenceReportedBy
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REVERT_CHILD_DROPPED_OFF_REPORT_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId ? Object.assign({}, e, { droppedOffAt: null }) : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REVERT_CHILD_PICKED_UP_REPORT_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              pickedUpAt: null,
              pickedUpBy: null
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REVERT_CHILD_ABSENCE_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              absenceFrom: null,
              absenceTo: null,
              absenceNotes: null,
              absenceReportedBy: null
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }

    case types.REVERT_CHILD_ATTENDANCE_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              absenceFrom: action.date,
              absenceTo: action.date,
              pickedUpAt: null,
              pickedUpBy: null,
              droppedOffAt: null,
              absenceNotes: '',
              absenceReportedBy: action.absenceReportedBy,
              receivedBy: null,
              releasedBy: null
            })
          : e;
      });
      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              droppedOffAt: action.droppedOfAt,
              pickedUpAt: action.pickedUpAt,
              pickedUpBy: action.pickedUpBy,
              absenceFrom: null,
              absenceTo: null,
              absenceNotes: null,
              absenceReportedBy: null,
              receivedBy: action.receivedBy,
              releasedBy: action.releasedBy
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS: {
      const nextEntries = state.childrenAttendance.map((e) => {
        return e.child.id === action.childId
          ? Object.assign({}, e, {
              droppedOffAt: null,
              pickedUpAt: null,
              pickedUpBy: null,
              absenceFrom: action.absenceFrom,
              absenceTo: action.absenceTo,
              absenceNotes: action.absenceNotes,
              absenceReportedBy: action.absenceReportedBy
            })
          : e;
      });

      return Object.assign({}, state, { childrenAttendance: [...nextEntries] });
    }
    default:
      return state;
  }
}
