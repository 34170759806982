/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function reportsConfigurationUiReducer(
  state = initialState.configuration.reportsConfigurationUi,
  action
) {
  switch (action.type) {
    case types.UPDATE_REPORTS_CONFIGURATION: {
      return UiModel.start();
    }
    case types.UPDATE_REPORTS_CONFIGURATION_CANCEL: {
      return UiModel.cancel();
    }
    case types.UPDATE_REPORTS_CONFIGURATION_SUBMIT: {
      return UiModel.submit();
    }
    case types.UPDATE_REPORTS_CONFIGURATION_SUCCESS: {
      return UiModel.success();
    }
    case types.UPDATE_REPORTS_CONFIGURATION_FAILURE: {
      return UiModel.failure(action.errors);
    }
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.configuration.reportsConfigurationUi);
    }
    default:
      return state;
  }
}
