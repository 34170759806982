import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import SaveCancelButtons from '../../forms/buttons/SaveCancelButtons';
import PropTypes from 'prop-types';

const FormDialog = ({
  title,
  titleIcon,
  onSubmit,
  titleStyles,
  onClose,
  open,
  initialValues,
  validationSchema,
  validate,
  children,
  inProgress,
  requiredFieldText,
  maxWidth = 'lg',
  hideSubmit,
  isInvalid = false
}) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth onClose={onClose}>
      <DialogTitle sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
            width: '100%',
            justifyContent: { xs: 'center', sm: 'flex-start' },
            ...titleStyles
          }}>
          {titleIcon}
          <Typography variant="h5" sx={{ ml: 2, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} validate={validate} onSubmit={onSubmit}>
        {({ isValid }) => (
          <Form>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{ mx: 2, display: 'flex', justifyContent: 'space-between' }}>
              {requiredFieldText && <Typography component="span">* - Pola wymagane</Typography>}
              <SaveCancelButtons
                saveLabel="Zapisz"
                cancelLabel="Anuluj"
                onCancel={onClose}
                inProgress={inProgress}
                type="submit"
                hideSubmit={hideSubmit}
                saveDisabled={inProgress || !isValid || isInvalid}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

FormDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleIcon: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  validate: PropTypes.func,
  children: PropTypes.node.isRequired,
  inProgress: PropTypes.bool,
  requiredFieldText: PropTypes.bool,
  maxWidth: PropTypes.string,
  hideSubmit: PropTypes.bool,
  isInvalid: PropTypes.bool,
  titleStyles: PropTypes.object
};

export default FormDialog;
