import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import pl from 'date-fns/locale/pl';
import ClearButtonWrapper from '../common/ClearButtonWrapper';
import { Box } from '@mui/system';

const FormikDatePicker = (props) => {
  const { name, helperText, fullWidth, disabled, format = 'dd/MM/yyyy', sx, noClear, ...other } = props;
  const { setFieldValue, setFieldError, status } = useFormikContext();
  const [field, meta] = useField(name);

  const showError = !!meta.error;
  const errorText = meta.error || helperText;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const renderNoclear = () => {
    return (
      <Box sx={{ ...sx }}>
        <DesktopDatePicker
          inputFormat={format}
          components={{
            OpenPickerIcon: EventOutlinedIcon
          }}
          name={field.name}
          invalidDateMessage="Podaj datę w formacie DD/MM/RRRR"
          maxDateMessage="Zbyt odległa data"
          minDateMessage="Zbyt odległa data"
          autoComplete={other.autoComplete || 'off'}
          okLabel="Zatwierdź"
          cancelLabel="Anuluj"
          clearLabel="Wyczyść"
          todayLabel="Dziś"
          value={field.value}
          disabled={status?.disabled || disabled}
          onChange={(date) => {
            // eslint-disable-next-line no-underscore-dangle
            if (date && !isValidDate(date)) {
              setFieldError(field.name, `Podaj datę w formacie ${format}`);
              // eslint-disable-next-line no-underscore-dangle
            } else if (date && isValidDate(date)) {
              setFieldError(field.name, undefined);
            }
            setFieldValue(field.name, date, false);
          }}
          onBlur={() => {
            // if (e && e.target && e.target.value === '') {
            //   setFieldError(field.name, undefined, true);
            // }
            // try {
            //   validateField(field.name);
            // } catch (error) {
            //   console.error(error);
            // }
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                mt: 1,
                color: (theme) => theme.palette.color.error,
                '& .Mui-error': {
                  color: (theme) => theme.palette.color.error
                },
                '& .MuiFormHelperText-root': {
                  color: (theme) => theme.palette.color.error
                }
              }}
              helperText={errorText}
              error={showError}
              variant="standard"
              {...params}
              fullWidth={fullWidth}
            />
          )}
          {...other}
        />
      </Box>
    );
  };

  const renderClear = () => {
    return (
      <ClearButtonWrapper
        onClear={() => setFieldValue(field.name, null, false)}
        disabled={status?.disabled || disabled}
        contrast
        fullWidth>
        {renderNoclear()}
      </ClearButtonWrapper>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
      {noClear ? renderNoclear() : renderClear()}
    </LocalizationProvider>
  );
};

FormikDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  format: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default FormikDatePicker;
