import React from 'react';
import PropTypes from 'prop-types';
import FormikSwitch from '../../../formik/FormikToggle';
import { FieldArray } from 'formik';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Box, Typography, ListItem } from '@mui/material';

const ChildDetailsFormAgreements = (props) => {
  const { values } = props;
  return (
    <FieldsetWrapper
      legend={
        <Typography
          component="span"
          sx={{
            color: (theme) => theme.palette.color.contrast,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            pl: 2
          }}>
          Zgody
        </Typography>
      }
      invisible={false}>
      <FieldArray
        name="agreements"
        render={() => (
          <Box sx={{ pb: 3 }}>
            {values.agreements.map((agreement, index) => {
              return (
                <ListItem key={agreement.key}>
                  <FormikSwitch name={`agreements.${index}.value`} label={agreement.key} />
                </ListItem>
              );
            })}
          </Box>
        )}
      />
    </FieldsetWrapper>
  );
};

ChildDetailsFormAgreements.propTypes = {
  values: PropTypes.object
};

export default ChildDetailsFormAgreements;
