import BaseModel from '../BaseModel';
import moment from 'moment';
import { TimeModel } from '../TimeModel';

export class LimitedToGroupModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.color = '';
  }
}

export class LimitedToActivityModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.color = '';
  }
}

export class LimitedToAuthorModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
  }
}

export class AuthorModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.fullName = '';
  }
}

export class BoardPostModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = '';
    this.limitedToGroups = [];
    this.limitedToActivities = [];
    this.publishedAt = Date.now();
    this.author = new AuthorModel();
    this.lastEditedBy = new AuthorModel();
    this.lastEditedAt = null;
    this.availableToAll = false;
    this.eventDate = '';
    this.eventStartTime = new TimeModel();
    this.eventEndTime = new TimeModel();
    this.pinned = false;
    this.files = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.limitedToGroups = assigned.limitedToGroups.map((e) => new LimitedToGroupModel().assign(e));
    assigned.limitedToActivities = assigned.limitedToActivities.map((e) => new LimitedToActivityModel().assign(e));
    assigned.author = new AuthorModel().assign(assigned.author);
    assigned.lastEditedBy = new AuthorModel().assign(assigned.lastEditedBy);
    assigned.eventDate = assigned.eventDate ? moment(assigned.eventDate).format('YYYY-MM-DD') : '';
    assigned.eventStartTime = new TimeModel().assign(assigned.eventStartTime);
    assigned.eventEndTime = new TimeModel().assign(assigned.eventEndTime);
  }
}

export class CoursebookBoardPostModel extends BaseModel {
  constructor() {
    super();
    this.coursebook = '';
    this.title = '';
    this.content = '';
    this.thematicCicle = '';
    this.note = '';
    this.learningMaterials = '';
    this.programPoints = [];
  }
}

export class BoardPostCommandModel extends BaseModel {
  constructor() {
    super();
    this.title = '';
    this.content = '';
    this.limitedToGroups = [];
    this.limitedToActivities = [];
    this.eventDate = '';
    this.eventStartTime = new TimeModel();
    this.eventEndTime = new TimeModel();
    this.pinned = false;
    this.files = [];
  }

  assignFromQueryModel(post) {
    return this.assign({
      title: post.title,
      content: post.content,
      limitedToGroups: post.limitedToGroups,
      limitedToActivities: post.limitedToActivities,
      eventDate: post.eventDate,
      eventStartTime: post.eventStartTime,
      eventEndTime: post.eventEndTime,
      pinned: post.pinned === true,
      files: post.files.map((f) => f.id)
    });
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.limitedToGroups = assigned.limitedToGroups.map((e) => e.id);
    assigned.limitedToActivities = assigned.limitedToActivities.map((e) => e.id);
    assigned.eventDate = assigned.eventDate ? moment(assigned.eventDate).format('YYYY-MM-DD') : '';
    assigned.eventStartTime = new TimeModel().assign(assigned.eventStartTime);
    assigned.eventEndTime = new TimeModel().assign(assigned.eventEndTime);
  }
}

export class MessageBoardModel extends BaseModel {
  constructor(posts, totalCount) {
    super();
    this.posts = posts.map((p) => new BoardPostModel().assign(p));
    this.limit = 10;
    this.totalCount = totalCount;
    this.pageCount = Math.abs(totalCount / this.limit);
    this.hasNextPage = this.posts.length < totalCount;
    this.offset = this.posts.length;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.posts = assigned.posts.map((e) => new BoardPostModel().assign(e));
  }
}
