import React from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';

const ChildVacationPeriodForm = ({ onStartMonthChange, onEndMonthChange, startMonth, endMonth, errors, readonly }) => {
  const [ startYear, startMonthValue ] = startMonth.split('-');
  const [ endYear, endMonthValue ] = endMonth.split('-'); 
  return (
    <Box sx={{ padding: 2 }}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography component="label"> Początek okresu urlopowania:</Typography>
          <YearAndMonthOptionsSlider
            contrast
            onSelected={(nextYear, nextMonth) => {
              const date = moment(`${nextYear}-${nextMonth}`, 'YYYY-MM');
                onStartMonthChange(date.format('YYYY-MM'));
            }}
            defaultMonth={startMonthValue}
            defaultYear={startYear}
          />
        </Box>
        <Box>
          <Typography component="label"> Koniec okresu urlopowania:</Typography>
          <YearAndMonthOptionsSlider
            contrast
            onSelected={(nextYear, nextMonth) => {
              const date = moment(`${nextYear}-${nextMonth}`, 'YYYY-MM');
                onEndMonthChange(date.format('YYYY-MM'));
            }}
            defaultMonth={endMonthValue}
            defaultYear={endYear}
          />
        </Box>
      </Stack>
    </Box>
  );
};

ChildVacationPeriodForm.propTypes = {
  onStartMonthChange: PropTypes.func.isRequired,
  onEndMonthChange: PropTypes.func.isRequired,
  startMonth: PropTypes.string.isRequired,
  endMonth: PropTypes.string.isRequired,
  errors: PropTypes.object,
  readonly: PropTypes.bool
};

ChildVacationPeriodForm.defaultProps = {
  errors: {},
  readonly: false
};

export default ChildVacationPeriodForm;
