import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { useField } from 'formik';

const FormikSelectField = ({
  children,
  onChange,
  label,
  fullWidth,
  inputProps,
  displayEmpty,
  className,
  id,
  selectStyle,
  selectClassName,
  autoWidth,
  disabled,
  name,
  contrast,
  ...rest
}) => {
  const labelId = id ? `select-${id}` : null;

  const [field, meta] = useField(name);

  const selectConfig = {
    value: field.value,
    onChange,
    ...rest,
    labelId,
    id,
    autoWidth,
    inputProps,
    displayEmpty,
    disabled,
    style: selectStyle,
    className: selectClassName
  };

  const formControlConfig = {
    className,
    style: { minWidth: `${fullWidth ? '100%' : '200px'}`, marginRight: '5px' },
    error: meta.touched && !!meta.error
  };

  return (
    <FormControl {...formControlConfig} variant="standard">
      {label && (
        <InputLabel
          id={labelId}
          sx={{
            color: contrast ? (theme) => theme.palette.color.contrast : (theme) => theme.palette.color.primary,
            '&.Mui-focused': {
              color: contrast ? (theme) => theme.palette.color.contrast : (theme) => theme.palette.color.primary
            }
          }}>
          {label}
        </InputLabel>
      )}
      <Select
        disableUnderline
        {...selectConfig}
        sx={
          contrast && {
            color: (theme) => theme.palette.color.color2,
            borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`,
            '&:hover': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`
            },
            '& .MuiSvgIcon-root': {
              color: (theme) => theme.palette.color.color2
            }
          }
        }>
        {children}
      </Select>
      {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

FormikSelectField.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  errorText: PropTypes.any,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  displayEmpty: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  selectStyle: PropTypes.object,
  selectClassName: PropTypes.string,
  autoWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default FormikSelectField;
