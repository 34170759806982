export const ChargingReportTypes = Object.freeze({
  stay: { value: 'stay', name: 'Pobyt' },
  catering: { value: 'catering', name: 'Wyżywienie' },
  staffCatering: { value: 'staffCatering', name: 'Wyżywienie pracowników' },
  activities: { value: 'activities', name: 'Zajęcia dodatkowe' },
  meals: { value: 'meals', name: 'Posiłki' },
  other: { value: 'other', name: 'Inne' }
});

export const chargingReportTypesComparator = (a, b, orderBy, order) => {
  const mappedA = a[orderBy] && ChargingReportTypes[a[orderBy]].name;
  const mappedB = b[orderBy] && ChargingReportTypes[b[orderBy]].name;
  const tempA = mappedA && mappedA.toLowerCase();
  const tempB = mappedB && mappedB.toLowerCase();
  if (tempA !== null) {
    return tempA.localeCompare(tempB, 'pl');
  }
  return order === 'asc' ? 1 : -1;
};
