import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import _ from 'lodash';
import DotsMenu from '../../common/menu/DotsMenu';

const ChildDiagnosesCardList = ({ onSelected, diagnoses, onRemove }) => {
  const renderListItem = (item) => {
    return (
      <ListItem
        button
        key={item.diagnoseId}
        onClick={() => onSelected(item)}
        component="li"
        aria-label={`Edytuj schemat diagnoz ${item.title}`}>
        <ListItemAvatar>
          <ReceiptIcon />
        </ListItemAvatar>
        <ListItemText primary={item.title} />
        <ListItemSecondaryAction>
          <DotsMenu>
            {(onClose) => [
              <ActionConfirmationMenuItem
                key="1"
                onItemClick={onClose}
                actionLabel="Usuń"
                confirmationText="Czy na pewno chcesz usunąć przygotowany schemat diagnoz?"
                confirmationTitle="Usuń schemat diagnoz"
                confirmLabel="Usuń"
                onConfirm={() => onRemove(item)}
                onCancel={() => {}}
              />
            ]}
          </DotsMenu>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderList = (array) => {
    const newSortedArray = _.sortBy(array, (s) => s.title.toLowerCase());
    return newSortedArray.map((item) => renderListItem(item));
  };

  return <List>{renderList(diagnoses)}</List>;
};

ChildDiagnosesCardList.propTypes = {
  diagnoses: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ChildDiagnosesCardList;
