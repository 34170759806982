import api from '../api/ReportsSettingsApi';
import {
  LOAD_REPORTS_CONFIGURATION,
  LOAD_REPORTS_CONFIGURATION_SUCCESS,
  UPDATE_REPORTS_CONFIGURATION,
  UPDATE_REPORTS_CONFIGURATION_CANCEL,
  UPDATE_REPORTS_CONFIGURATION_SUBMIT,
  UPDATE_REPORTS_CONFIGURATION_FAILURE,
  UPDATE_REPORTS_CONFIGURATION_SUCCESS
} from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadReportsConfigurationAsync() {
  return (dispatch) => {
    dispatch({ type: LOAD_REPORTS_CONFIGURATION });
    return api
      .getReportsSettings()
      .then((reports) => dispatch({ type: LOAD_REPORTS_CONFIGURATION_SUCCESS, reports }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać ustawień raportów'));
        return logger.error(error);
      });
  };
}

export function updateReportsConfigurationStart() {
  return { type: UPDATE_REPORTS_CONFIGURATION };
}

export function updateReportsConfigurationCancel() {
  return { type: UPDATE_REPORTS_CONFIGURATION_CANCEL };
}

export function updateReportsConfigurationAsync(reports) {
  return (dispatch) => {
    dispatch({ type: UPDATE_REPORTS_CONFIGURATION_SUBMIT });
    return api
      .updateReportsSettings(reports)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia raportów'));
        dispatch({ type: UPDATE_REPORTS_CONFIGURATION_SUCCESS, reports });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: UPDATE_REPORTS_CONFIGURATION_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień raportów'));
        return logger.error(error);
      });
  };
}
