import PropTypes from 'prop-types';
import React from 'react';
import ActivityAvatar from '../common/activities/ActivityAvatar';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const ActivitiesArchiveList = ({ activities }) => {
  return (
    <List sx={{ py: 0 }}>
      {activities.map((activity) => {
        return (
          <ListItem
            key={activity.id}
            sx={{
              '&:hover': {
                background: (theme) => theme.palette.background.hover
              }
            }}>
            <ActivityAvatar activity={activity} />
            <ListItemText
              disableTypography
              sx={{ ml: 3 }}
              primary={
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>{activity.name}</Typography>
              }
              secondary={<ArchivalInfo archivalInfo={activity.archivalInfo} />}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

ActivitiesArchiveList.propTypes = {
  activities: PropTypes.array.isRequired
};

export default ActivitiesArchiveList;
