'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import SettlementCalculationDetails from './SettlementCalculationDetails';
import SettlementAdjustmentDetails from './SettlementAdjustmentDetails';
import ConfirmationIconButton from '../../forms/buttons/ConfirmationIconButton';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';
import CancelIcon from '@mui/icons-material/Cancel';
import numberFormatter from '../../../utils/numberFormatter';
import { CalculationStatus } from '../../../constants/calculationStatus';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import EditInterestsDialog from './EditInterestsDialog';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: 'right',
    mx: 'auto',
    textTransform: 'uppercase',
    fontSize: 14,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const SettlementDetails = ({
  settlement,
  onAdjustmentChange,
  onCancelDelayInterest,
  onSaveCustomInterest,
  onReserveBalanceFunds,
  onRemoveReservedFunds
}) => {
  const cl = useStyles();
  const renderDueDate = () => (
    <Grid container>
      <Grid item className={cl.item} xs={6} sm={9}>
        <Typography
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          Termin płatności:
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            fontWeight: 400,
            fontSize: '1.25rem'
          }}>
          <Box>{settlement.maturityDate ? moment(settlement.maturityDate).format('YYYY-MM-DD') : '-'}</Box>
        </Box>
      </Grid>
    </Grid>
  );

  const renderTotalDelayInterest = () => (
    <Grid container>
      <Grid item xs={6} sm={9} className={cl.item}>
        {settlement.isApproved() && onCancelDelayInterest && (
          <>
            <EditInterestsDialog value={settlement.totalDelayInterests} onSave={onSaveCustomInterest} />
            <ConfirmationIconButton
              confirmationText="Czy na pewno chcesz anulować naliczone odsetki? Spowoduje to również zaprzestanie naliczania dalszych odsetek za tę opłatę."
              confirmationTitle="Anulowanie odsetek"
              confirmLabel="Tak"
              cancelLabel="Nie"
              tooltip="Anuluj odsetki"
              onConfirm={onCancelDelayInterest}
              onCancel={() => {}}>
              <CancelIcon
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              />
            </ConfirmationIconButton>
          </>
        )}
        <Typography>Odsetki za zwłokę</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(settlement.totalDelayInterests)} zł
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  const renderReservedBalanceFundsSection = () => (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={9}
        sx={{
          textAlign: 'right',
          mb: 'auto',
          mt: 'auto',
          fontSize: 14,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'end'
        }}>
        {
          // eslint-disable-next-line no-nested-ternary
          onReserveBalanceFunds && !settlement.reservedBalanceFunds ? (
            !settlement.calculatedTotal || settlement.calculatedTotal <= 0 ? null : (
              <ActionConfirmationButton
                sx={{ whiteSpace: 'normal', textAlign: 'center', height: { xs: 48, sm: 40 } }}
                variant="outlinedContrast"
                confirmationTitle="Zastosuj obniżenie opłaty z tytułu nadpłaty"
                confirmationText={`Czy na pewno chcesz wykorzystać dostępne środki aby obniżyć kwotę rozliczenia? Możliwe obniżenie o ${numberFormatter.format(
                  Math.min(settlement.calculatedTotal, settlement.personAvailableFunds)
                )} zł`}
                confirmLabel="Tak"
                cancelLabel="Nie"
                isPrimary={true}
                onConfirm={onReserveBalanceFunds}
                onCancel={() => {}}
                actionLabel="zastosuj obniżenie opłaty z tytułu nadpłaty"
              />
            )
          ) : (
            (onRemoveReservedFunds ? (
              <ConfirmationIconButton
                confirmationText="Czy na pewno chcesz anulować obniżenie opłaty?"
                confirmationTitle="Anuluj obniżenie opłaty"
                confirmLabel="Tak"
                cancelLabel="Nie"
                tooltip="Anuluj obniżenie opłaty"
                onConfirm={onRemoveReservedFunds}
                onCancel={() => {}}>
                <CancelIcon color={(theme) => theme.palette.color.primary} />
              </ConfirmationIconButton>
            ) : null,
            (<Typography>Obniżka z tytułu nadpłaty</Typography>))
          )
        }
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(-settlement.reservedBalanceFunds || 0)} zł
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  const renderUsedBalanceFundsSection = () => (
    <Grid container>
      <Grid item xs={6} sm={9} className={cl.item}>
        <Typography>Obniżka z tytułu nadpłaty</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(-settlement.usedBalanceFunds || 0)} zł
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  const renderCorrectiveDueCorrectedDueInfo = () => (
    <Grid container>
      <Grid item xs={6} sm={9} className={cl.item}>
        <Typography>{`Wartość korygowanego rozliczenia ${settlement.correctedDue.number}`}</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box>
          <Box
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(-settlement.correctedDue.calculatedTotal)} zł
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  const renderSixYearOldExemptionInfo = () => (
    <Grid container>
      <Grid item xs={12} sx={{ fontSize: 13 }}>
        <InfoIcon
          sx={{
            verticalAlign: 'middle',
            ml: 1,
            color: (theme) => theme.palette.color.primary
          }}
        />
        <Typography>Zastosowano zwolnienie dla sześciolatków</Typography>
      </Grid>
    </Grid>
  );

  const renderPaidAmount = () => {
    return settlement.paidAmount ? (
      <Grid container>
        <Grid item xs={6} sm={9}>
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              Wpłacono
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(settlement.paidAmount)} zł
          </Typography>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <Box>
      {settlement.schemeResultItems.map((calculation) => {
        return (
          <Box key={calculation.schemeSnaphot.id}>
            <SettlementCalculationDetails calculation={calculation} />
            {calculation.attendanceSummarySnapshot.eligibleForSixYearExemption && renderSixYearOldExemptionInfo()}
            <SettlementAdjustmentDetails
              calculation={calculation}
              canAdjust={settlement.calculationStatus === CalculationStatus.draft.value}
              onAdjustmentChange={onAdjustmentChange}
            />
          </Box>
        );
      })}
      {settlement.calculationStatus !== CalculationStatus.draft.value ? renderDueDate() : null}
      {Boolean(settlement.totalDelayInterests) && renderTotalDelayInterest()}
      {
        // eslint-disable-next-line no-nested-ternary
        settlement.usedBalanceFunds
          ? renderUsedBalanceFundsSection()
          : settlement.reservedBalanceFunds ||
            (settlement.hasFundsAvailable && settlement.calculationStatus === CalculationStatus.draft.value)
          ? renderReservedBalanceFundsSection()
          : null
      }
      {settlement.isCorrective() && renderCorrectiveDueCorrectedDueInfo()}
      <Grid container>
        <Grid item xs={6} sm={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            Do zapłaty:
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              fontWeight: 400,
              fontSize: '1.25rem'
            }}>
            {numberFormatter.format(settlement.getTotalAmount())}&nbsp;zł
          </Typography>
        </Grid>
      </Grid>
      {renderPaidAmount()}
    </Box>
  );
};

SettlementDetails.propTypes = {
  settlement: PropTypes.object.isRequired,
  onAdjustmentChange: PropTypes.func,
  onCancelDelayInterest: PropTypes.func,
  onReserveBalanceFunds: PropTypes.func,
  onRemoveReservedFunds: PropTypes.func,
  onSaveCustomInterest: PropTypes.func
};

export default SettlementDetails;
