import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as guardianPinServiceActions from '../../../actions/guardianPinServiceActions';
import { updateQrCodeServiceSettingAsync } from '../../../actions/unitActions';
import { Paper, Box, Typography } from '@mui/material';
import DialpadIcon from '@mui/icons-material/Dialpad';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';
import PinDeviceList from './PinDevicesList';
import GeneratePinsPanel from './GeneratePinsPanel';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import QRCode from 'react-qr-code';
import { getTenantName } from '../../../utils/getTenantName';
import { downloadQrCode } from '../../../utils/downloadQrCode';
import QrCodeScannerIcon from '../../../assets/icons/QrCodeScannerIcon';
import { convertStringToBase64 } from '../../../utils/convertStringToBase64';
import moment from 'moment';
import { PhonelinkSetup, Tablet } from '@mui/icons-material';
import DownloadPoster from '../../common/qrPoster/downloadPoster';
import { QRCode as QRCodes } from 'react-qrcode-logo';

const tenantName = getTenantName();

class GuardiansPinServicePage extends React.Component {
  constructor() {
    super();
    this.state = {
      isUpadating: false
    };
    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    this.handleQrConfigurationChange = this.handleQrConfigurationChange.bind(this);
    this.handleDownloadQrCode = this.handleDownloadQrCode.bind(this);

    this.selectorRef = React.createRef(null);
  }

  handleConfigurationChange(enabled) {
    this.props.actions.updateGuardianPinServiceConfiguration(enabled);
  }

  async handleQrConfigurationChange(enabled, dynamic = false) {
    this.setState({ isUpadating: true });
    await this.props.qrCodeActions.updateQrCodeServiceSettingAsync(enabled, dynamic);
    this.setState({ isUpadating: false });
  }

  handleDownloadQrCode() {
    const svg = document.getElementById('QRCode');
    downloadQrCode(svg);
  }

  render() {
    const { configuration, qrCodeConfiguration } = this.props;
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Urządzenia do rejestracji obecności" titleIcon={<PhonelinkSetup />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
              <DialpadIcon sx={{ color: (theme) => theme.palette.color.primary }} />
              <Typography variant="h5" sx={{ ml: 1, color: (theme) => theme.palette.color.primary }}>
                Rejestrowanie obecności za pomocą pinu
              </Typography>
            </Box>
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <ActionConfirmationButton
                useRaisedButton={true}
                wrapperSx={{ justifyContent: 'flex-start' }}
                actionLabel={
                  configuration.enabled ? 'wyłącz logowanie za pomocą pinu' : 'włącz logowanie za pomocą pinu'
                }
                confirmationText={
                  configuration.enabled
                    ? 'Czy na pewno chcesz wyłączyć możliwość rejestrowania obecności za pomocą pinu?'
                    : 'Czy na pewno chcesz włączyć możliwość rejestrowania obecności za pomocą pinu?'
                }
                confirmationTitle="Potwierdzenie"
                isSecondary={configuration.enabled}
                isPrimary={!configuration.enabled}
                confirmLabel={configuration.enabled ? 'Wyłącz' : 'Włącz'}
                onConfirm={() => this.handleConfigurationChange(!configuration.enabled)}
                onCancel={() => {}}
              />
            </Box>
            <Paper sx={{ p: 2, mb: 2 }}>
              <GeneratePinsPanel
                guardians={configuration.guardiansWithoutPin}
                onGeneratePins={this.props.actions.generatePinsToGuardiansWithout}
                enabled={configuration.enabled}
              />
            </Paper>
            <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
              <QrCodeScannerIcon sx={{ color: (theme) => theme.palette.color.primary }} />
              <Typography variant="h5" sx={{ ml: 1, color: (theme) => theme.palette.color.primary }}>
                Rejestrowanie obecności za pomocą kodu QR
              </Typography>
            </Box>
            <Paper sx={{ display: 'flex', p: 2, mb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: { xs: 'column', lg: 'row' }
                }}>
                <ActionConfirmationButton
                  useRaisedButton={true}
                  actionLabel={
                    qrCodeConfiguration.enabled
                      ? 'wyłącz rejestrowanie obecności za pomocą kodu QR'
                      : 'włącz rejestrowanie obecności za pomocą kodu QR'
                  }
                  confirmationText={
                    qrCodeConfiguration.enabled
                      ? 'Czy na pewno chcesz wyłączyć możliwość rejestrowania obecności za pomocą kodu QR?'
                      : 'Czy na pewno chcesz włączyć możliwość rejestrowania obecności za pomocą kodu QR?'
                  }
                  confirmationTitle="Potwierdzenie"
                  isSecondary={qrCodeConfiguration.enabled}
                  isPrimary={!qrCodeConfiguration.enabled}
                  confirmLabel={qrCodeConfiguration.enabled ? 'Wyłącz' : 'Włącz'}
                  onConfirm={() => this.handleQrConfigurationChange(!qrCodeConfiguration.enabled, false)}
                  onCancel={() => {}}
                  isDisabled={this.state.isUpadating}
                />
                <ActionConfirmationButton
                  useRaisedButton={true}
                  actionLabel={qrCodeConfiguration.dynamic ? 'wyłącz zmienny kod QR' : 'włącz zmienny kod QR'}
                  confirmationText={
                    qrCodeConfiguration.dynamic
                      ? 'Czy na pewno chcesz wyłączyć zmienny kod QR?'
                      : 'Czy na pewno chcesz włączyć zmienny kod QR?'
                  }
                  confirmationTitle="Potwierdzenie"
                  isSecondary={qrCodeConfiguration.dynamic}
                  isPrimary={!qrCodeConfiguration.dynamic}
                  confirmLabel={qrCodeConfiguration.dynamic ? 'Wyłącz' : 'Włącz'}
                  onConfirm={() =>
                    this.handleQrConfigurationChange(qrCodeConfiguration.enabled, !qrCodeConfiguration.dynamic)
                  }
                  onCancel={() => {}}
                  isDisabled={
                    this.state.isUpadating || !qrCodeConfiguration.enabled || configuration.devices.length === 0
                  }
                />
              </Box>
            </Paper>
            {qrCodeConfiguration.enabled && !qrCodeConfiguration.dynamic ? (
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                  mb: 2
                }}>
                <QRCode
                  id="QRCode"
                  title="Kod QR"
                  value={`4parents:${tenantName}:${convertStringToBase64(moment().toISOString())}`}
                />
                <DownloadPoster
                  format="a4"
                  qr={
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <QRCodes
                        value={`4parents:${tenantName}:${convertStringToBase64(moment().toISOString())}`}
                        size="152"
                      />
                    </Box>
                  }
                />
                <DownloadPoster
                  format="a3"
                  qr={
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <QRCodes
                        value={`4parents:${tenantName}:${convertStringToBase64(moment().toISOString())}`}
                        size="245"
                      />
                    </Box>
                  }
                />
              </Paper>
            ) : null}
            <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
              <Tablet sx={{ color: (theme) => theme.palette.color.primary }} />
              <Typography variant="h5" sx={{ ml: 1, color: (theme) => theme.palette.color.primary }}>
                Zarządzanie urządzeniami
              </Typography>
            </Box>
            <Paper className="card panel">
              <PinDeviceList
                devices={configuration.devices}
                onRemove={this.props.actions.removePinDevice}
                onAdd={this.props.actions.addPinDevice}
                onCancelAdd={this.props.actions.cancelAddPinDevice}
                onPasswordRestart={this.props.actions.resetPinDevicePassword}
                onCancelPasswordRestart={this.props.actions.cancelResetPinDevicePassword}
                enabled={true}
              />
            </Paper>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GuardiansPinServicePage.propTypes = {
  configuration: PropTypes.object.isRequired,
  qrCodeConfiguration: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  qrCodeActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration.guardiansPinService,
    qrCodeConfiguration: state.configuration.unit.guardiansQRServiceSettings
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(guardianPinServiceActions, dispatch),
    qrCodeActions: bindActionCreators({ updateQrCodeServiceSettingAsync }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardiansPinServicePage);
