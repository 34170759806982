import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, MenuItem, Typography } from '@mui/material';
import DatePicker from '../../../common/DatePicker';
import SelectField from '../../../common/SelectField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import ManyGroupsSelector from './ManyGroupsPickerTemplate';

const DateAndGroupPickerTemplate = ({
  groupId,
  activityId,
  activities,
  startDate,
  endDate,
  groups,
  onChange,
  errors,
  legend,
  disabledGroupPicker,
  manyGroupsSelector
}) => {
  return (
    <Box>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        {!manyGroupsSelector ? (
          <SelectField
            errorText={errors.groupId}
            contrast
            disabled={disabledGroupPicker}
            sx={{ width: 'auto', overflow: 'hidden' }}
            floatingLabelText={groupId ? 'Grupa' : 'Zajęcia dodatkowe'}
            id={groupId ? 'groupId' : 'activityId'}
            value={groupId ? groupId : activityId}
            onChange={(event) => {
              onChange({ target: { name: groupId ? 'groupId' : 'activityId', value: event.target.value } });
            }}>
            {groupId
              ? groups.map((group) => {
                  return (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  );
                })
              : null}
            {activityId
              ? activities.map((activity) => {
                  return (
                    <MenuItem key={activity.id} value={activity.id}>
                      {activity.name}
                    </MenuItem>
                  );
                })
              : null}
          </SelectField>
        ) : (
          <ManyGroupsSelector groups={groups} onChange={onChange} errorText={errors.groupId} />
        )}
        <Typography variant="h6" sx={{ pt: 2, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          Zakres
        </Typography>
        <Box sx={{ display: { sm: 'flex' }, justifyContent: { xs: 'center', sm: 'space-between' } }}>
          <Box
            sx={{
              pb: { xs: 1, sm: 0 },
              mr: { sm: 2 },
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}>
            <DatePicker
              hintText="Od"
              floatingLabelText="Od"
              floatingLabelFixed={false}
              name="startDate"
              value={startDate ? new Date(startDate) : undefined}
              onChange={(e, value) =>
                onChange({ target: { name: 'startDate', value: moment(value).format('YYYY-MM-DD') } })
              }
              errorText={errors.startDate}
              maxDate={endDate ? new Date(endDate) : null}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            <DatePicker
              hintText="Do"
              floatingLabelText="Do"
              floatingLabelFixed={false}
              name="endDate"
              value={endDate ? new Date(endDate) : undefined}
              onChange={(e, value) =>
                onChange({ target: { name: 'endDate', value: moment(value).format('YYYY-MM-DD') } })
              }
              errorText={errors.endDate}
              minDate={startDate ? new Date(startDate) : null}
            />
          </Box>
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

DateAndGroupPickerTemplate.propTypes = {
  groupId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  groups: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  legend: PropTypes.string.isRequired,
  activities: PropTypes.array,
  activityId: PropTypes.string,
  manyGroupsSelector: PropTypes.bool,
  disabledGroupPicker: PropTypes.bool
};

export default DateAndGroupPickerTemplate;
