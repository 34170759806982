import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Mood from './Mood';
import Meal from './Meal';
import EmojiCounter from './EmojiCounter';
import _ from 'lodash';
import { tryConvertMinutesToString } from '../../utils/TimeSpanConverter';
import { Box, Typography } from '@mui/material';
import Small from '../common/Small';
import Strong from '../common/Strong';

const DaycareReadonlyDetails = ({ daycareEntry }) => {
  function sortFood(meals) {
    if (!!meals) {
      const mealscopy = _.cloneDeep(meals);
      const base = {
        breakfast: 0,
        secondBreakfast: 1,
        soup: 2,
        lunch: 3,
        afternoonMeal: 4
      };
      const sortedtable = mealscopy.sort((item1, item2) => {
        if (base[item1] < base[item2]) {
          return -1;
        }
        if (base[item1] > base[item2]) {
          return 1;
        }
        return 0;
      });
      return sortedtable;
    }
  }

  const sortedfoodmemo = useMemo(() => {
    return sortFood(daycareEntry.meals);
  }, [daycareEntry.meals]);

  const Boxing = ({ children }) => {
    Boxing.propTypes = {
      children: PropTypes.any
    };
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          width: '100%'
        }}>
        {children}
      </Box>
    );
  };

  const renderMood = () => (
    <Boxing sx={{ mb: 1 }}>
      <Typography>Nastrój</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          flexWrap: 'wrap'
        }}>
        {daycareEntry.mood.map((mood) => (
          <Mood key={mood} mood={mood} />
        ))}
      </Box>
    </Boxing>
  );

  const renderSleep = () => (
    <Boxing>
      <Typography>Drzemka</Typography>
      <Typography>{tryConvertMinutesToString(daycareEntry.sleepTimeInMinutes)}</Typography>
    </Boxing>
  );

  const renderMeals = () => (
    <Boxing sx={{ mb: 1 }}>
      <Typography sx={{ mt: 1 }}>Posiłki</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'nowrap'
        }}>
        {sortedfoodmemo.map((mealx) => (
          <Meal
            key={mealx}
            meal={mealx}
            mealsScale={!!daycareEntry.mealsScale && daycareEntry.mealsScale.find((scale) => scale.name === mealx)}
          />
        ))}
      </Box>
    </Boxing>
  );

  const renderNappies = () => (
    <Boxing>
      <Typography>Pieluszki</Typography> <EmojiCounter count={daycareEntry.nappies} emoji="🚽" />
    </Boxing>
  );

  const renderPoos = () => (
    <Boxing>
      <Typography>Kupki</Typography> <EmojiCounter count={daycareEntry.poos} emoji="💩" />
    </Boxing>
  );

  const renderNote = (note) => (
    <Boxing>
      <Typography>Dodatkowe informacje</Typography>
      <Small sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', wordBreak: 'break-word' }}>{note}</Small>
    </Boxing>
  );

  const renderMealNote = (note) => (
    <Boxing>
      <Typography>Opis posiłku</Typography>
      <Small sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', wordBreak: 'break-word' }}>{note}</Small>
    </Boxing>
  );

  const sleepLabel = () => {
    return daycareEntry.sleepTimeInMinutes !== 0 && renderSleep();
  };

  return (
    <Box sx={{ maxWidth: { xs: '240px', sm: '340px' } }}>
      {daycareEntry.mood && daycareEntry.mood.length > 0 && renderMood()}
      {daycareEntry.meals && daycareEntry.meals.length > 0 && renderMeals()}
      {daycareEntry.mealsNote && renderMealNote(daycareEntry.mealsNote)}
      {daycareEntry.nappies > 0 && renderNappies()}
      {daycareEntry.poos > 0 && renderPoos()}
      {daycareEntry.sleepTimeInMinutes !== undefined ? sleepLabel() : null}
      {daycareEntry.note && renderNote(daycareEntry.note)}
    </Box>
  );
};

DaycareReadonlyDetails.propTypes = {
  daycareEntry: PropTypes.object.isRequired
};

export default DaycareReadonlyDetails;
