// @flow
import React, { useState } from 'react';
import * as Yup from 'yup';
import BaseModel from '../../models/BaseModel';
import PropTypes from 'prop-types';
import { FormikTextField } from '../../components/formik/FormikTextField';
import FieldsetWrapper from '../../components/forms/FieldsetWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  ListItemIcon,
  ListItemText,
  ListItem,
  Typography,
  FormControl,
  InputAdornment,
  IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { useField, useFormikContext } from 'formik';
import Grow from '@mui/material/Grow';
import InfoIcon from '@mui/icons-material/Info';
import {
  ONE_DIGIT_REGEXP,
  ONE_LOWER_CHARACTER_REGEXP,
  ONE_SPECIAL_CHARACTER_REGEXP,
  ONE_UPPER_CHARACTER_REGEXP,
  PASSWORD_REGEXP
} from '../../utils/validationRegExps';
import AccessibleTooltip from '../../components/common/AccessibleTooltip';

const conditions = {
  passLength: 'Hasło musi zawierać co najmniej 12 znaków',
  passOneUpLetter: 'Hasło musi zawierać jedną wielką literę',
  passOneDownLetter: 'Hasło musi zawierać jedną małą literę',
  passOneDigit: 'Hasło musi zawierać jedną cyfrę',
  passSpecialCharacter: 'Hasło musi zawierać specjalny znak',
  passDiff: 'Nowe hasło nie może być identyczne jak poprzednie'
};

export const Condition = ({ isFulfilled, conditionText, specialIcon }) => (
  <ListItem sx={{ p: 0 }}>
    <ListItemIcon>
      {
        // eslint-disable-next-line no-nested-ternary
        specialIcon ? (
          <InfoIcon />
        ) : isFulfilled ? (
          <CheckIcon style={{ color: (theme) => theme.palette.color.success, fontSize: 10 }} />
        ) : (
          <ClearIcon style={{ color: (theme) => theme.palette.color.error, fontSize: 10 }} />
        )
      }
    </ListItemIcon>
    <ListItemText
      disableTypography
      primary={
        <Typography
          sx={{
            color: (theme) => (isFulfilled || specialIcon ? theme.palette.color.color22 : theme.palette.color.error),
            fontSize: 12,
            //odstęp pomiędzy ikoną a textem, domyślnu mui'owy jest za duży
            ml: -4
          }}>
          {conditionText}
        </Typography>
      }
    />
  </ListItem>
);

Condition.propTypes = {
  isFulfilled: PropTypes.bool.isRequired,
  conditionText: PropTypes.string.isRequired,
  specialIcon: PropTypes.bool
};

const UserPasswordTemplate = ({ passDiff = false }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [showCondition, setShowCondition] = useState(false);
  const [specialIcon, setSpecialIcon] = useState(true);

  const { errors } = useFormikContext();
  const [, meta] = useField('password');
  if ((meta.value.length || meta.touched) && specialIcon) setSpecialIcon(false);

  const tempCondtions = { ...conditions };
  if (!passDiff) delete tempCondtions.passDiff;

  return (
    <FieldsetWrapper legend="Podaj hasła">
      <FormikTextField
        label="Nowe hasło*"
        type={showPassword ? 'text' : 'password'}
        name="password"
        onFocus={() => {
          if (!showCondition) setShowCondition(true);
        }}
        endAdornment={
          <InputAdornment position="end">
            <AccessibleTooltip text={showPassword ? 'Ukryj hasło' : 'Wyświetl hasło'}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}>
                {showPassword ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
              </IconButton>
            </AccessibleTooltip>
          </InputAdornment>
        }
      />
      {showCondition && (
        <Grow in={showCondition} {...(showCondition ? { timeout: 1400 } : {})}>
          <Grid container>
            <List>
              {Object.entries(tempCondtions).map(([key, value], index) => {
                return (
                  <Condition
                    key={index}
                    isFulfilled={!!errors[key] === false}
                    conditionText={value}
                    specialIcon={specialIcon}
                  />
                );
              })}
            </List>
          </Grid>
        </Grow>
      )}
      <FormControl>
        <FormikTextField
          label="Powtórz hasło*"
          type={showPasswordConfirmation ? 'text' : 'password'}
          name="passwordConfirmation"
          endAdornment={
            <InputAdornment position="end">
              <AccessibleTooltip text={showPasswordConfirmation ? 'Ukryj hasło' : 'Wyświetl hasło'}>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPasswordConfirmation(!showPasswordConfirmation);
                  }}>
                  {showPasswordConfirmation ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </AccessibleTooltip>
            </InputAdornment>
          }
        />
      </FormControl>
    </FieldsetWrapper>
  );
};

UserPasswordTemplate.propTypes = {
  passDiff: PropTypes.bool
};

export default UserPasswordTemplate;

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.password = '';
    this.passwordConfirmation = '';
  }
}

export const VALIDATION_SCHEMA = {
  password: Yup.string()
    .matches(PASSWORD_REGEXP, 'Hasło nie spełnia wymaganych kryteriów')
    .required('Hasło jest wymagane'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Hasła nie są identyczne')
    .required('Powtórzenie hasła jest wymagane')
};

export const validate = ({ password }) => {
  const errors = {};
  if (password.length < 12) errors.passLength = true;
  if (!password.match(ONE_LOWER_CHARACTER_REGEXP)) errors.passOneDownLetter = true;
  if (!password.match(ONE_UPPER_CHARACTER_REGEXP)) errors.passOneUpLetter = true;
  if (!password.match(ONE_DIGIT_REGEXP)) errors.passOneDigit = true;
  if (!password.match(ONE_SPECIAL_CHARACTER_REGEXP)) errors.passSpecialCharacter = true;
  return errors;
};
