import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScheduledTransferForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../forms/scheduledTransfer/ScheduledTransferForm';
import FormDialog from '../../../formik/formDialog/FormDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import * as Type from '../../../../constants/groupsAndActivitiesTypes';
import { useDispatch } from 'react-redux';
import { createScheduledTransfer } from '../../../../actions/scheduledTransferActions';
import moment from 'moment';

const ScheduledTransferAddDialogContainer = ({ isOpen, onClose, childrenList, groups, activities }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values, actions) => {
    let enrolmentValue;

    if (values.type === Type.GROUP) {
      if (values.selectedGroup !== 'none') {
        enrolmentValue = [values.selectedGroup];
      }
    } else {
      if (!values.selectedActivities.includes('none')) {
        enrolmentValue = values.selectedActivities;
      }
    }

    setLoading(true);
    const data = {
      childId: values.selectedChild.id,
      type: values.type.toLowerCase(),
      date: moment(values.date).format('YYYY-MM-DD'),
      enrollmentIDs: enrolmentValue,
      archiveChild: values.type === Type.GROUP && values.selectedGroup === 'none' && values.archiveChild,
      archiveLegalGuardians:
        values.type === Type.GROUP &&
        values.selectedGroup === 'none' &&
        values.archiveChild &&
        values.archiveLegalGuardians
    };
    const child = childrenList.find((item) => item.id === data.childId);
    let from;
    let to;
    if (values.type === Type.GROUP) {
      from =
        child.enrolledToGroup &&
        child.enrolledToGroup.id &&
        groups.find((item) => item.id === child.enrolledToGroup.id);
      to = groups.find((item) => item.id === values.selectedGroup);
    } else {
      from = activities.filter((item) => child.enrolledToActivities.some((x) => x.id === item.id));
      to = activities.filter((item) => values.selectedActivities.includes(item.id));
    }
    const storeData = {
      ...data,
      from,
      to,
      firstName: child.firstName,
      lastName: child.lastName,
      avatarUrl: child.photoUrl
    };
    const error = await dispatch(createScheduledTransfer(data, storeData));
    setLoading(false);
    if (error) {
      if (error.date) {
        actions.setFieldError('date', error.date);
      } else {
        actions.setFieldError('generalError', error.generalError);
      }
    } else {
      onClose();
    }
  };

  return (
    <FormDialog
      title="Zaplanuj przenosiny dziecka"
      titleIcon={<AutorenewIcon sx={{ ml: 0 }} />}
      open={isOpen}
      onClose={onClose}
      initialValues={new InitialValues()}
      validationSchema={VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={onSubmit}
      requiredFieldText
      maxWidth="md"
      inProgress={loading}>
      <ScheduledTransferForm childrenList={childrenList} groups={groups} activities={activities} />
    </FormDialog>
  );
};
ScheduledTransferAddDialogContainer.propTypes = {
  activities: PropTypes.array.isRequired,
  childrenList: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ScheduledTransferAddDialogContainer;
