import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import BaseModel from '../../models/BaseModel';
import SquareRadioButton from '../../components/common/squareRadioButton/SquareRadioButton';
import FieldsetWrapper from '../../components/forms/FieldsetWrapper';
import * as Types from '../../constants/groupsAndActivitiesTypes';
import SpecificIcon from '../../components/groupsAndActivities/SpecificIcon';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import FormikAutocompletePickChild from '../../components/formik/FormikAutocompletePickChild';
import FormikDatePicker from '../../components/formik/FormikDatePicker';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { GroupModel } from '../../models/groups/GroupModels';
import { ActivityModel } from '../../models/activities/ActivityModels';

const useStyles = makeStyles({
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.75)'
  }
});

const OPTIONS = {
  activities: {
    label: 'Zajęcia dodatkowe',
    value: Types.ACTIVITY,
    icon: <SpecificIcon type={Types.MORE_ACTIVITIES} />
  },
  groups: { label: 'Grupy', value: Types.GROUP, icon: <SpecificIcon type={Types.MORE_GROUPS} /> }
};

const ScheduledTransferForm = ({ isEdit = false, childrenList, groups, activities, to }) => {
  const cl = useStyles();
  const localStorageScheduledTransferTooltip = JSON.parse(localStorage.getItem('scheduledTransferTooltip'));

  const { setFieldValue } = useFormikContext();
  const [typeValue] = useField('type');
  const [selectedGroup, meta] = useField('selectedGroup');
  const [archiveChild] = useField('archiveChild');
  const [selectedChild] = useField('selectedChild');

  const [, errorMeta] = useField('generalError');
  const { value: group } = selectedGroup;
  const { value: type } = typeValue;
  const { value: child } = selectedChild;

  const [selectedActivities] = useField('selectedActivities');
  const [selectGroup, setGroup] = useState(!!to ? new GroupModel().assign(to) : '');

  const { error } = errorMeta;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // if (!!to) handleChange(new GroupModel().assign(to));
    if (type === Types.ACTIVITY) {
      if (!localStorageScheduledTransferTooltip) {
        localStorage.setItem('scheduledTransferTooltip', JSON.stringify(true));
      }
    }
  }, [type]);

  const noneActivity = useMemo(
    () => new ActivityModel().assign({ id: 'none', name: 'Odepnij od wszystkich zajęć' }),
    []
  );

  if (group && child && child.enrolledToGroup && child.enrolledToGroup.id === group) {
    setFieldValue('selectedGroup', '');
  }
  const availableGroups =
    child && child.enrolledToGroup ? groups.filter((item) => item.id !== child.enrolledToGroup.id) : groups;

  const handleTriangleChange = (e) => {
    setFieldValue('type', e.target.value);
    setFieldValue('selectedGroup', '');
    setFieldValue('selectedActivities', []);
  };
  const handleChange = (value) => {
    setFieldValue('selectedGroup', value.id);
    setGroup(value);
  };

  const [activityValue, setActivityValue] = useState(
    activities.filter((activity) => [...selectedActivities.value].includes(activity.id)) || []
  );

  const handleActivityChange = (e) => {
    const ids = e.target.value.map((element) => element.id);

    if (ids.includes('none')) {
      setActivityValue([noneActivity]);
      setFieldValue('selectedActivities', ['none']);
    } else {
      setActivityValue(e.target.value);
      setFieldValue('selectedActivities', ids);
    }
  };

  const handleTriggerArchiveChild = (value) => {
    if (typeof value === 'boolean') setFieldValue('archiveChild', value);
  };

  const info =
    child &&
    (type === Types.GROUP
      ? child.enrolledToGroup && groups.find((item) => item.id === child.enrolledToGroup.id)
      : activities.filter((item) => child.enrolledToActivities.some((x) => x.id === item.id)));

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: isMobile ? '20px 0' : 1,
            alignItems: 'center'
          }}>
          <FormikAutocompletePickChild
            childrenList={childrenList}
            groups={groups}
            name="selectedChild"
            label="Wybierz dziecko*"
            sx={{ pb: 4 }}
            disabled={isEdit}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <FieldsetWrapper
            contrast
            legend="Wybierz typ przenosin:"
            invisible={false}
            sx={{
              width: 'auto',
              textAlign: 'center',
              '&.Mui-focused': {
                color: (theme) => theme.palette.color.contrast
              },
              color: (theme) => theme.palette.color.contrast
            }}
            labelStyle={{ mb: 1 }}>
            <RadioGroup
              row
              sx={{ my: 2 }}
              aria-label="position"
              name="position"
              value={type}
              onChange={handleTriangleChange}>
              <SquareRadioButton
                disabled={!child || isEdit}
                {...OPTIONS.activities}
                checked={OPTIONS.activities.value === type}
              />
              <SquareRadioButton
                {...OPTIONS.groups}
                checked={OPTIONS.groups.value === type}
                disabled={!child || isEdit}
              />
            </RadioGroup>
          </FieldsetWrapper>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightMedium, ml: 1 }}>
            Dane szczegółowe przeniesienia dziecka
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 1 }}>
              {error}
            </Alert>
          )}
        </Grid>

        <Grid container sx={{ mb: 2, mt: 4, mx: 2 }}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}>
            <FormikDatePicker
              noClear
              sx={{ width: 150, mb: 2 }}
              name="date"
              InputLabelProps={{
                classes: { root: cl.shrink }
              }}
              label={
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 18,
                    color: theme.palette.color.contrast
                  }}>
                  Data przenosin*
                  <Tooltip
                    title={`Wybrana data wskazuje dzień w którym dziecko będzie znajdowało się w ${
                      type === Types.GROUP ? 'wybranej grupie' : 'wybranych zajęciach dodatkowych'
                    }`}
                    tabIndex={0}>
                    <InfoIcon sx={{ ml: 1, cursor: 'help' }} />
                  </Tooltip>
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} md={8} sx={{ pt: 0.4 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              {type === Types.GROUP ? (
                <FormControl>
                  <InputLabel
                    sx={{
                      color: theme.palette.color.contrast,
                      background: (theme) => theme.palette.background.default,
                      px: 0.5,
                      ml: -0.5
                    }}
                    shrink={info ? true : false}
                    id="picker_label_select">
                    {type === Types.GROUP ? 'Z grupy' : 'Z zajęć dodatkowych'}
                  </InputLabel>
                  <Select
                    label={type === Types.GROUP ? 'Z grupy' : 'Z zajęć dodatkowych'}
                    sx={{
                      width: 200,
                      color: (theme) => theme.palette.color.contrast,
                      '& .MuiSvgIcon-root': {
                        color: (theme) => theme.palette.color.hidden
                      }
                    }}
                    value={(info && info?.name) || ''}
                    labelId="picker_label_select"
                    disabled>
                    <MenuItem value={info ? info.name : ''}>{info ? info.name : ''}</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <InputLabel sx={{ color: theme.palette.color.contrast }} shrink={true} id="picker_label">
                    {type === Types.GROUP ? 'Z grupy' : 'Z zajęć dodatkowych'}
                  </InputLabel>
                  <Box sx={{ pt: 2, pl: 2, minWidth: 150 }}>
                    {info !== null && (
                      <Tooltip
                        title={
                          <Box>
                            {info.length === 0 && 'Brak'}
                            <List>
                              {info.map((element) => {
                                return (
                                  <ListItem key={element.id}>
                                    <ListItemText primary={element.name} />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Box>
                        }>
                        <Box>
                          Wybrano:
                          <Chip label={info.length} sx={{ ml: 1 }} />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </FormControl>
              )}
              <ArrowForwardIosIcon sx={{ fontSize: 30, transform: isMobile ? 'rotate(90deg)' : undefined }} />
              {type === Types.GROUP ? (
                <>
                  <FormControl>
                    <InputLabel sx={{ color: theme.palette.color.contrast }} id="picker_to_label">
                      {type === Types.GROUP ? 'Do grupy' : 'Do zajęć dodatkowych'}
                    </InputLabel>
                    <Select
                      label="Do grupy"
                      sx={{
                        width: 200,
                        color: (theme) => theme.palette.color.contrast,
                        '& .MuiSvgIcon-root': {
                          color: (theme) => theme.palette.color.contrast
                        }
                      }}
                      value={
                        selectGroup.id !== 'none'
                          ? availableGroups.filter((e) => e.id === selectGroup.id)[0] || ''
                          : selectGroup
                      }
                      labelId="picker_to_label"
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}>
                      <MenuItem value={{ id: 'none', name: 'Odepnij od grupy' }}>Odepnij od grupy</MenuItem>
                      <MenuItem key={selectGroup} value={selectGroup} sx={{ display: 'none' }}>
                        {selectGroup.name}
                      </MenuItem>
                      {availableGroups.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl>
                    <InputLabel sx={{ color: theme.palette.color.contrast }} id="picker_to_label">
                      {type === Types.GROUP ? 'Do grupy' : 'Do zajęć dodatkowych'}
                    </InputLabel>
                    <Select
                      label="Do zajęć dodatkowych"
                      value={activityValue}
                      multiple
                      onChange={(e) => {
                        handleActivityChange(e);
                      }}
                      sx={{
                        width: 200,
                        color: (theme) => theme.palette.color.colontrast,
                        '& .MuiSvgIcon-root': {
                          color: (theme) => theme.palette.color.contrast
                        }
                      }}>
                      <MenuItem value={noneActivity}>Odepnij od wszystkich zajęć</MenuItem>
                      {activities.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            {type === Types.GROUP && selectGroup.id === 'none' && (
              <Stack direction={'row'} justifyContent={'end'}>
                <FormControl sx={{ width: 200 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    onClick={(e) => handleTriggerArchiveChild(e.target.checked)}
                    label="Zarchwizuj dziecko"
                  />
                </FormControl>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ScheduledTransferForm;

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.type = OPTIONS.groups.value;
    this.selectedChild = null;
    this.selectedGroup = '';
    this.selectedActivities = [];
    this.archiveChild = false;
    this.archiveLegalGuardians = false;
    this.date = moment().add(1, 'days');
  }
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  selectedChild: Yup.object().required('Pole jest wymagane').nullable(),
  date: Yup.date()
    .min(moment().add(1, 'day'), 'Nie można wybrać daty wcześniejszej niż jutrzejsza')
    .required('Pole jest wymagane')
    .nullable()
    .typeError('Podaj datę w formacie DD/MM/RRRR'),
  selectedGroup: Yup.string().when('type', {
    is: OPTIONS.groups.value,
    then: Yup.string().required('Pole jest wymagane').nullable()
  }),
  selectedActivities: Yup.array().when('type', {
    is: OPTIONS.activities.value,
    then: Yup.array().min(1, 'Pole jest wymagane').required('Pole jest wymagane')
  })
});

export const validate = () => {
  const errors = {};
  return errors;
};

ScheduledTransferForm.propTypes = {
  activities: PropTypes.array.isRequired,
  childrenList: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isEdit: PropTypes.bool
};
