import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  Box,
  Avatar,
  Button,
  Grid
} from '@mui/material';
import MealAvatar from './MealAvatar';
import EmptyState from '../common/EmptyState';
import { useCallback, useMemo } from 'react';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import { useState } from 'react';
import DialogForm from '../forms/DialogForm';
import AddMealToCategoryForm from './forms/AddMealToCategoryForm';
import { useDispatch } from 'react-redux';
import { addMealToCategory } from '../../actions/mealCategoriesActions';

const MealsWithoutCategoryList = ({ meals, filterBy, mealsCategories }) => {
  const [isAddMealToCategoryDialogOpen, setIsAddMealToCategoryDialogOpen] = useState(false);
  const [choosedCategoryId, setChoosedCategoryId] = useState('');
  const [choosedMeal, setChoosedMeal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const sortedAndFilteredMeals = useMemo(() => {
    if (!filterBy) {
      return meals.sort((a, b) => a.name.localeCompare(b.name));
    }

    const filteredMeals = meals.filter((meal) => meal.name.toLowerCase().includes(filterBy.toLowerCase()));
    return filteredMeals.sort((a, b) => a.name.localeCompare(b.name));
  }, [meals, filterBy]);

  const handleMealClick = (mealId) => {
    browserHistory.push(fromTemplate(routePaths.mealDetails, [mealId]));
  };

  const handleOnAddMealToCategoryClick = (meal) => {
    setChoosedMeal(meal);
    setIsAddMealToCategoryDialogOpen((prev) => !prev);
  };

  const handleCancel = () => {
    setIsAddMealToCategoryDialogOpen((prev) => !prev);
    setChoosedCategoryId('');
    setChoosedMeal({});
  };

  const handleOnAddMealToCategory = useCallback(
    async (choosedCategory, choosedMeal) => {
      if (choosedCategory !== '' && choosedMeal !== '') {
        try {
          setIsLoading(true);
          await dispatch(addMealToCategory(choosedCategory, choosedMeal));
          setIsAddMealToCategoryDialogOpen((prev) => !prev);
          setChoosedCategoryId('');
        } finally {
          setIsLoading(false);
        }
      }
    },
    [dispatch]
  );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 2, mb: 0 }}>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-word',
            color: (theme) => theme.palette.color.primary,
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          Posiłki bez kategorii
        </Typography>
        <Avatar
          sx={{
            width: '25px',
            height: '25px',
            background: (theme) => theme.palette.background.transparent,
            color: (theme) => theme.palette.color.primary,
            opacity: 0.7,
            mx: 1,
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {sortedAndFilteredMeals.length}
        </Avatar>
      </Box>
      <Paper sx={{ minHeight: 104 }}>
        {sortedAndFilteredMeals.length === 0 && meals.length !== 0 && (
          <EmptyState contrast message="Brak wyników wyszukiwania" />
        )}
        {meals.length === 0 && <EmptyState contrast message="Brak posiłków bez kategorii" />}
        {sortedAndFilteredMeals.length !== 0 && (
          <List sx={{ p: 2 }}>
            {sortedAndFilteredMeals
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((meal) => (
                <ListItem
                  key={meal.id}
                  sx={{
                    '&:hover': {
                      background: (theme) => theme.palette.background.hover
                    }
                  }}>
                  <Grid container>
                    <Grid
                      item
                      onClick={() => {
                        handleMealClick(meal.id);
                      }}
                      xs={12}
                      sm={6}
                      md={8}
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemAvatar>
                          <MealAvatar meal={meal} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ wordBreak: 'break-word' }}
                          primary={meal.name}
                          primaryTypographyProps={{
                            fontWeight: (theme) => theme.typography.fontWeightBold
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                        alignItems: 'center'
                      }}>
                      <Button
                        onClick={() => {
                          handleOnAddMealToCategoryClick(meal);
                        }}
                        variant="clearText"
                        sx={{
                          color: (theme) => theme.palette.color.color7,
                          '&:hover': {
                            color: (theme) => theme.palette.color.color7
                          }
                        }}>
                        Przypisz do kategorii
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
          </List>
        )}
      </Paper>
      {isAddMealToCategoryDialogOpen && (
        <DialogForm
          maxWidth="xs"
          header="Dodaj posiłek do kategorii"
          isDialogOpen={isAddMealToCategoryDialogOpen}
          onCancel={handleCancel}
          onSave={() => handleOnAddMealToCategory(choosedCategoryId, choosedMeal)}
          isProcessing={isLoading}>
          <AddMealToCategoryForm
            mealsCategories={mealsCategories}
            choosedCategory={choosedCategoryId}
            setChoosedCategory={setChoosedCategoryId}
          />
        </DialogForm>
      )}
    </>
  );
};

MealsWithoutCategoryList.propTypes = {
  meals: PropTypes.array.isRequired,
  mealsCategories: PropTypes.array.isRequired,
  filterBy: PropTypes.string
};

export default MealsWithoutCategoryList;
