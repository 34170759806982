import api from '../api/ChildrenApi';
import attributesApi from '../api/AttributesApi';
import legalGuardiansApi from '../api/LegalGuardiansApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../routePaths';
import * as get from './getStateHelpers';
import { ChildDetailsModel, OpeningBalance, StudentDetailsModel } from '../models/children/ChildModels';
import moment from 'moment';
import * as logger from '../utils/logger';

export function loadChildrenAsync() {
  return (dispatch, getState) => {
    if (getState().children.length === 0) {
      dispatch({ type: types.LOAD_CHILDREN });
      return api
        .getChildren()
        .then((children) => dispatch({ type: types.LOAD_CHILDREN_SUCCESS, children }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać zarejestrowanych dzieci'));
          return logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function loadChildrenArchiveAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHILDREN_ARCHIVE });
    return api
      .getChildrenArchive()
      .then((children) => dispatch({ type: types.LOAD_CHILDREN_ARCHIVE_SUCCESS, children }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać zarchiwizowanych dzieci'));
        return logger.error(error);
      });
  };
}

export function loadLegalGuardiansChildrenAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_LEGAL_GUARDIANS_CHILDREN });
    return api
      .getLegalGuardiansChildren()
      .then((children) => dispatch({ type: types.LOAD_LEGAL_GUARDIANS_CHILDREN_SUCCESS, children }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać dzieci zarejestrowanych opiekunów prawnych'));
        return logger.error(error);
      });
  };
}

export function loadAllLegalGuardiansAsync() {
  return (dispatch, getState) => {
    if (getState().legalGuardians.length === 0) {
      dispatch({ type: types.LOAD_LEGAL_GUARDIANS });
      return legalGuardiansApi
        .getLegalGuardians()
        .then((legalGuardians) => dispatch({ type: types.LOAD_LEGAL_GUARDIANS_SUCCESS, legalGuardians }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać zarejestrowanych opiekunów prawnych'));
          return logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function deleteLegalGuardianAsync(legalGuardianId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_LEGAL_GUARDIAN });
    return legalGuardiansApi
      .deleteLegalGuardian(legalGuardianId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Usunięto opiekuna prawnego '${get.legalGuardianName(getState, legalGuardianId)}'`
          )
        );
        dispatch({ type: types.REMOVE_LEGAL_GUARDIAN_SUCCESS, legalGuardianId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć opiekuna prawnego '${get.legalGuardianName(getState, legalGuardianId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadChildAsync(childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD });
    return api
      .getChild(childId)
      .then((childDetails) => dispatch({ type: types.LOAD_CHILD_SUCCESS, childDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się pobrać szczegółów dziecka '${get.childName(getState, childId)}'`)
        );
        return logger.error(error);
      });
  };
}

export function loadStudentAsync(studentId) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD });
    return api
      .getChild(studentId)
      .then((childDetails) => {
        const student = childDetails.legalGuardians.length
          ? new StudentDetailsModel().assign({
              ...childDetails,
              email: childDetails.legalGuardians[0].email,
              guardianContactNumber: childDetails.legalGuardians[0].phoneNumber,
              guardianLastName: childDetails.legalGuardians[0].lastName,
              guardianFirstName: childDetails.legalGuardians[0].firstName,
              guardiansAddress: {
                address: childDetails.legalGuardians[0].address.split(',')[0],
                zipCode: childDetails.legalGuardians[0].address.split(',')[1].trim().split(' ')[0],
                city: childDetails.legalGuardians[0].address.split(',')[1].trim().split(' ').slice(1).join(' ')
              }
              // string in format: 'ul. Ulica 1, 00-000 Miasto' is splited into: address, zipCode and city
            })
          : childDetails;
        dispatch({ type: types.LOAD_STUDENT_SUCCESS, childDetails: student });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać szczegółów ucznia '${get.childName(getState, studentId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadChildPersonalBalanceAsync(childId) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHILD_PERSONAL_BALANCE });
    return api
      .getChildPersonalBalance(childId)
      .then((personalBalance) => dispatch({ type: types.LOAD_CHILD_PERSONAL_BALANCE_SUCCESS, personalBalance }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać szczegółów.'));
        return logger.error(error);
      });
  };
}

export function cashOutMoneyFromPersonalBalanceAsync(childId, amount, method, walletType) {
  return (dispatch) => {
    dispatch({ type: types.CASH_CHILD_BALANCE });
    return api
      .cashOutMoneyFromPersonalBalance(childId, amount, method, walletType)
      .then(() => dispatch({ type: types.CASH_CHILD_BALANCE_SUCCESS }))
      .catch((error) => {
        if (error.status === 412) {
          const errorText =
            error.response && error.response.body && error.response.body.generalError
              ? error.response.body.generalError
              : '';
          return dispatch(notificationActions.showError(`Nie udało się zarejestrować wypłaty środków - ${errorText}`));
        }
        dispatch(notificationActions.showError('Nie udało się zarejestrować wypłaty środków.'));
        return logger.error(error);
      });
  };
}

export function loadLegalGuardiansChildAsync(childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD });
    return api
      .getLegalGuardiansChild(childId)
      .then((childDetails) => dispatch({ type: types.LOAD_CHILD_SUCCESS, childDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się pobrać szczegółów dziecka '${get.childName(getState, childId)}'`)
        );
        return logger.error(error);
      });
  };
}

export function loadChildArchiveAsync(childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD_ARCHIVE });
    return api
      .getChildArchive(childId)
      .then((childDetails) => dispatch({ type: types.LOAD_CHILD_ARCHIVE_SUCCESS, childDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać szczegółów zarchiwizowanego dziecka '${get.childArchiveName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function createChildStart() {
  return { type: types.CREATE_CHILD };
}

export function createChildCancel() {
  return { type: types.CREATE_CHILD_CANCEL };
}

export function createChildAsync(nextChildDetails) {
  const childDetails = Object.assign({}, nextChildDetails);
  return (dispatch) => {
    dispatch({ type: types.CREATE_CHILD_SUBMIT });
    return api
      .createChild(childDetails)
      .then((id) => {
        dispatch(
          notificationActions.showSuccess(`Zarejestrowano dziecko - ${childDetails.firstName} ${childDetails.lastName}`)
        );
        dispatch({ type: types.CREATE_CHILD_SUCCESS, childDetails: Object.assign(childDetails, { id }) });
        browserHistory.push(fromTemplate(routePaths.childDetails, [id]));
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.CREATE_CHILD_FAILURE, errors: error.response.body });
          throw error.response.body;
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zarejestrować dziecka - ${childDetails.firstName} ${childDetails.lastName}`
          )
        );
        return logger.error(error);
      });
  };
}

export function createStudentAsync(nextStudentDetails) {
  const studentDetails = Object.assign({}, nextStudentDetails);
  return (dispatch) => {
    dispatch({ type: types.CREATE_CHILD_SUBMIT });
    return api
      .createStudent(studentDetails)
      .then((id) => {
        dispatch(
          notificationActions.showSuccess(
            `Zarejestrowano ucznia ${studentDetails.firstName} ${studentDetails.lastName}`
          )
        );
        dispatch({ type: types.CREATE_STUDENT_SUCCESS, childDetails: Object.assign(studentDetails, { id }) });
        browserHistory.push(fromTemplate(routePaths.studentDetails, [id]));
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.CREATE_CHILD_FAILURE, errors: error.response.body });
          throw error.response.body;
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zarejestrować ucznia - ${studentDetails.firstName} ${studentDetails.lastName}`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateChildStart() {
  return { type: types.UPDATE_CHILD };
}

export function updateChildCancel() {
  return { type: types.UPDATE_CHILD_CANCEL };
}

export function updateStudentAsync(nextStudentDetails) {
  const studentDetails = new StudentDetailsModel().assign(nextStudentDetails);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CHILD_SUBMIT });
    return api
      .updateStudent(studentDetails.id, studentDetails)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano dane ucznia - ${studentDetails.firstName} ${studentDetails.lastName}`
          )
        );
        dispatch({ type: types.UPDATE_STUDENT_SUCCESS, childDetails: studentDetails });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.UPDATE_CHILD_FAILURE, errors: error.response.body });
          throw error.response.body;
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować danych ucznia - ${studentDetails.firstName} ${studentDetails.lastName}`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateChildAsync(nextChildDetails) {
  const childDetails = new ChildDetailsModel().assign(nextChildDetails);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CHILD_SUBMIT });
    return api
      .updateChild(childDetails.id, childDetails)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano dane dziecka - ${childDetails.firstName} ${childDetails.lastName}`
          )
        );
        dispatch({ type: types.UPDATE_CHILD_SUCCESS, childDetails });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.UPDATE_CHILD_FAILURE, errors: error.response.body });
          throw error.response.body;
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować danych dziecka - ${childDetails.firstName} ${childDetails.lastName}`
          )
        );
        return logger.error(error);
      });
  };
}

export function archiveChildStart() {
  return { type: types.ARCHIVE_CHILD };
}

export function archiveChildCancel() {
  return { type: types.ARCHIVE_CHILD_CANCEL };
}

export function archiveChildAsync(childId, deleteLegalGuardians) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_CHILD_SUBMIT, childId });
    return api
      .archiveChild(childId, deleteLegalGuardians)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zarchiwizowano ${deleteLegalGuardians ? 'ucznia' : 'dziecko'} '${get.childName(getState, childId)}'`
          )
        );
        dispatch({ type: types.ARCHIVE_CHILD_SUCCESS, childId });
        browserHistory.push(deleteLegalGuardians ? routePaths.student : routePaths.children);
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się zarchiwizować ${deleteLegalGuardians ? 'ucznia' : 'dziecko'}`)
        );
        return logger.error(error);
      });
  };
}

export function addLegalGuardianToChildStart() {
  return { type: types.ADD_LEGAL_GUARDIAN_TO_CHILD };
}

export function addLegalGuardianToChildCancel() {
  return { type: types.ADD_LEGAL_GUARDIAN_TO_CHILD_CANCEL };
}

export function addLegalGuardianToChildAsync(childId, nextLegalGuardian) {
  const legalGuardian = Object.assign({}, nextLegalGuardian);
  if (legalGuardian.bankAccountNumber && Object.keys(legalGuardian.bankAccountNumber).length > 0) {
    let accountNumber = legalGuardian.bankAccountNumber.replace(/\D/g, '');
    accountNumber = `PL${accountNumber}`;
    legalGuardian.bankAccountNumber = accountNumber;
  }
  return (dispatch) => {
    dispatch({ type: types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUBMIT });
    return api
      .addChildLegalGuardian(childId, legalGuardian)
      .then((guardianData) => {
        dispatch(
          notificationActions.showSuccess(
            `Dodano opiekuna prawnego '${legalGuardian.firstName} ${legalGuardian.lastName}'`
          )
        );
        dispatch({
          type: types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS,
          legalGuardian: Object.assign(legalGuardian, guardianData),
          childId
        });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.ADD_LEGAL_GUARDIAN_TO_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać opiekuna prawnego '${legalGuardian.firstName} ${legalGuardian.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function assignLegalGuardianToChildAsync(childId, legalGuardian) {
  return (dispatch) => {
    return api
      .assignLegalGuardianToChild(childId, legalGuardian.id)
      .then((response) => {
        dispatch(
          notificationActions.showSuccess(
            `Dodano opiekuna prawnego '${legalGuardian.firstName} ${legalGuardian.lastName}'`
          )
        );
        dispatch({
          type: types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS,
          legalGuardian: Object.assign({}, legalGuardian, response),
          childId
        });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.ADD_LEGAL_GUARDIAN_TO_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać opiekuna prawnego '${legalGuardian.firstName} ${legalGuardian.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateLegalGuardianOfChildStart() {
  return { type: types.UPDATE_LEGAL_GUARDIAN_IN_CHILD };
}

export function updateLegalGuardianOfChildCancel() {
  return { type: types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_CANCEL };
}

export function updateLegalGuardianOfChildAsync(childId, nextLegalGuardian) {
  const legalGuardian = Object.assign({}, nextLegalGuardian);
  if (legalGuardian.bankAccountNumber && Object.keys(legalGuardian.bankAccountNumber).length > 0) {
    let accountNumber = legalGuardian.bankAccountNumber.replace(/\D/g, '');
    accountNumber = `PL${accountNumber}`;
    legalGuardian.bankAccountNumber = accountNumber;
  }
  return (dispatch) => {
    dispatch({ type: types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUBMIT });
    return api
      .updateChildLegalGuardian(childId, legalGuardian.id, legalGuardian)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano dane opiekuna prawnego '${legalGuardian.firstName} ${legalGuardian.lastName}'`
          )
        );
        dispatch({ type: types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUCCESS, legalGuardian, childId });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować danych opiekuna prawnego '${legalGuardian.firstName} ${legalGuardian.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeLegalGuardianFromChildAsync(childId, guardianId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_LEGAL_GUARDIAN_FROM_CHILD });
    return api
      .deleteChildLegalGuardian(childId, guardianId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(`Odpięto opiekuna prawnego '${get.legalGuardianName(getState, guardianId)}'`)
        );
        dispatch({ type: types.REMOVE_LEGAL_GUARDIAN_FROM_CHILD_SUCCESS, childId, guardianId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się odpiąć opiekuna prawnego '${get.legalGuardianName(getState, guardianId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function addGuardianToChildStart() {
  return { type: types.ADD_GUARDIAN_TO_CHILD };
}

export function addGuardianToChildCancel() {
  return { type: types.ADD_GUARDIAN_TO_CHILD_CANCEL };
}

export function addGuardianToChildAsync(childId, nextGuardian) {
  const guardian = Object.assign({}, nextGuardian);
  return (dispatch) => {
    dispatch({ type: types.ADD_GUARDIAN_TO_CHILD_SUBMIT });
    return api
      .addChildGuardian(childId, guardian)
      .then((guardianData) => {
        dispatch(
          notificationActions.showSuccess(
            `Dodano osobę '${guardian.firstName} ${guardian.lastName}' uprawnioną do odbioru dziecka`
          )
        );
        dispatch({
          type: types.ADD_GUARDIAN_TO_CHILD_SUCCESS,
          guardian: Object.assign(guardian, guardianData),
          childId
        });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.ADD_GUARDIAN_TO_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać osoby '${guardian.firstName} ${guardian.lastName}' uprawnionej do odbioru dziecka`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateGuardianOfChildStart() {
  return { type: types.UPDATE_GUARDIAN_IN_CHILD };
}

export function updateGuardianOfChildCancel() {
  return { type: types.UPDATE_GUARDIAN_IN_CHILD_CANCEL };
}

export function updateGuardianOfChildAsync(childId, nextGuardian) {
  const guardian = Object.assign({}, nextGuardian);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_GUARDIAN_IN_CHILD_SUBMIT });
    return api
      .updateChildGuardian(childId, guardian.id, guardian)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano dane osoby '${guardian.firstName} ${guardian.lastName}' uprawnionej do odbioru dziecka`
          )
        );
        dispatch({ type: types.UPDATE_GUARDIAN_IN_CHILD_SUCCESS, guardian, childId });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.UPDATE_GUARDIAN_IN_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować danych osoby '${guardian.firstName} ${guardian.lastName}' uprawnionej do odbioru dziecka`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeGuardianFromChildAsync(childId, guardianId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_GUARDIAN_FROM_CHILD });
    return api
      .deleteChildGuardian(childId, guardianId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Usunięto osobę '${get.guardianName(getState, childId, guardianId)}' uprawnioną do odbioru dziecka`
          )
        );
        dispatch({ type: types.REMOVE_GUARDIAN_FROM_CHILD_SUCCESS, childId, guardianId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć osoby '${get.guardianName(
              getState,
              childId,
              guardianId
            )}' uprawnionej do odbioru dziecka`
          )
        );
        return logger.error(error);
      });
  };
}

export function authorizeGuardianOnChildAsync(childId, guardianId) {
  return (dispatch, getState) => {
    dispatch({ type: types.AUTHORIZE_GUARDIAN_ON_CHILD });
    return api
      .updateChildGuardianAuthorization(childId, guardianId, true)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Potwierdzono upoważenie osoby '${get.guardianName(getState, childId, guardianId)}' do odbioru dziecka`
          )
        );
        dispatch({ type: types.AUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS, childId, guardianId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się potwierdzić upoważnienia osoby '${get.guardianName(
              getState,
              childId,
              guardianId
            )}' do odbioru dziecka`
          )
        );
        return logger.error(error);
      });
  };
}

export function unauthorizeGuardianOnChildAsync(childId, guardianId) {
  return (dispatch, getState) => {
    dispatch({ type: types.UNAUTHORIZE_GUARDIAN_ON_CHILD });
    return api
      .updateChildGuardianAuthorization(childId, guardianId, false)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Anulowano upoważnienie osoby '${get.guardianName(getState, childId, guardianId)}' do odbioru dziecka`
          )
        );
        dispatch({ type: types.UNAUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS, childId, guardianId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się anulować upoważnienia osoby '${get.guardianName(
              getState,
              childId,
              guardianId
            )}' do odbioru dziecka`
          )
        );
        return logger.error(error);
      });
  };
}

export function extendGuardianAuthorizationExpiryOnChildAsync(childId, nextGuardian) {
  const guardian = Object.assign({}, nextGuardian);
  guardian.isAuthorized = false;
  return (dispatch) => {
    dispatch({ type: types.UPDATE_GUARDIAN_IN_CHILD_SUBMIT });
    return api
      .extendChildGuardianAuthorizationExpiry(childId, guardian.id, guardian.authorizationExpirationDate)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano dane osoby '${guardian.firstName} ${guardian.lastName}' uprawnionej do odbioru dziecka`
          )
        );
        dispatch({ type: types.UPDATE_GUARDIAN_IN_CHILD_SUCCESS, guardian, childId });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.UPDATE_GUARDIAN_IN_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować danych osoby '${guardian.firstName} ${guardian.lastName}' uprawnionej do odbioru dziecka`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateProfilePhoto(childId, file) {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CHILD_PROFILE_PHOTO });
    if (file.size > 750 * 1024) {
      dispatch(notificationActions.showError('Rozmiar wybranego pliku przekracza 750KB'));
      return Promise.resolve();
    }

    return api
      .updateProfilePhoto(childId, file)
      .then((result) => {
        dispatch(
          notificationActions.showSuccess(`Zaktualizowano zdjecie dziecka '${get.childName(getState, childId)}'`)
        );
        dispatch({ type: types.UPDATE_CHILD_PROFILE_PHOTO_SUCCESS, photoUrl: result.photoUrl });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować zdjęcia dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeProfilePhoto(childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CHILD_PROFILE_PHOTO });
    return api
      .removeProfilePhoto(childId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Usunięto zdjecie dziecka '${get.childName(getState, childId)}'`));
        dispatch({ type: types.REMOVE_CHILD_PROFILE_PHOTO_SUCCESS });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się usunąć zdjęcia dziecka '${get.childName(getState, childId)}'`)
        );
        return logger.error(error);
      });
  };
}

export function updateChargingSchemeStart() {
  return { type: types.ASSIGN_CHARGING_SCHEME_TO_CHILD };
}

export function updateChargingSchemeCancel() {
  return { type: types.ASSIGN_CHARGING_SCHEME_TO_CHILD_CANCEL };
}

export function updateChargingScheme(childId, nextChildChargingScheme) {
  const chargingScheme = Object.assign({}, nextChildChargingScheme);

  return (dispatch, getState) => {
    dispatch({ type: types.ASSIGN_CHARGING_SCHEME_TO_CHILD_SUBMIT });
    return api
      .updateChargingScheme(childId, chargingScheme)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Przypisano schemat płatności '${get.schemeName(
              getState,
              chargingScheme.schemeId
            )}' do dziecka '${get.childName(getState, childId)}'`
          )
        );
        dispatch({ type: types.ASSIGN_CHARGING_SCHEME_TO_CHILD_SUCCESS, scheme: chargingScheme });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.ASSIGN_CHARGING_SCHEME_TO_CHILD_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać schematu płatności '${get.schemeName(
              getState,
              chargingScheme.schemeId
            )}' do dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeChargingScheme(childId, schemeId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CHARGING_SCHEME_FROM_CHILD });
    return api
      .removeChargingScheme(childId, schemeId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odpięto schemat płatności '${get.schemeName(getState, schemeId)}' od dziecka '${get.childName(
              getState,
              childId
            )}'`
          )
        );
        dispatch({ type: types.REMOVE_CHARGING_SCHEME_FROM_CHILD_SUCCESS, schemeId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się odpiąć schematu płatności '${get.schemeName(
              getState,
              schemeId
            )}' od dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateOpeningBalanceStart() {
  return { type: types.UPDATE_CHILD_OPENING_BALANCE };
}

export function updateOpeningBalanceCancel() {
  return { type: types.UPDATE_CHILD_OPENING_BALANCE_CANCEL };
}

export function updateOpeningBalance(childId, nextBalance) {
  const balance = new OpeningBalance().assign(nextBalance);
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CHILD_OPENING_BALANCE_SUBMIT });
    return api
      .updateOpeningBalance(childId, balance)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano bilans otwarcia dziecka '${get.childName(getState, childId)}'`
          )
        );
        dispatch({ type: types.UPDATE_CHILD_OPENING_BALANCE_SUCCESS, balance });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.UPDATE_CHILD_OPENING_BALANCE_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować bilansu otwarcia dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function addFileAttachmentStart() {
  return { type: types.ATTACH_FILE_TO_CHILD };
}

export function addFileAttachmentCancel() {
  return { type: types.ATTACH_FILE_TO_CHILD_CANCEL };
}

export function addFileAttachment(childId, fileAttachment) {
  return (dispatch, getState) => {
    dispatch({ type: types.ATTACH_FILE_TO_CHILD_SUBMIT });
    return api
      .addFileAttachment(childId, fileAttachment)
      .then((fileId) => {
        dispatch(
          notificationActions.showSuccess(`Załączono plik dla profilu dziecka '${get.childName(getState, childId)}'`)
        );
        dispatch({
          type: types.ATTACH_FILE_TO_CHILD_SUCCESS,
          fileAttachment: { fileId, name: fileAttachment.name, uploadDate: moment().format() }
        });
      })
      .catch((error) => {
        dispatch({ type: types.ATTACH_FILE_TO_CHILD_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się załączyć pliku do profilu dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeFileAttachment(childId, fileAttachmentId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_FILE_FROM_CHILD });
    return api
      .deleteFileAttachment(childId, fileAttachmentId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Usunięto załączony plik z profilu dziecka '${get.childName(getState, childId)}'`
          )
        );
        dispatch({ type: types.REMOVE_FILE_FROM_CHILD_SUCCESS, fileAttachmentId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć załączonego pliku z profilu dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

// Surveys and diagnoses

export function addSurveyToChildStart() {
  return { type: types.ADD_SURVEY_TO_CHILD };
}

export function addSurveyToChildCancel() {
  return { type: types.ADD_SURVEY_TO_CHILD_CANCEL };
}

export function addSurveyAsync(childId, fileAttachment) {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_SURVEY_TO_CHILD_SUBMIT });
    return api
      .addSurvey(childId, fileAttachment)
      .then((fileId) => {
        dispatch(
          notificationActions.showSuccess(`Załączono plik dla profilu dziecka '${get.childName(getState, childId)}'`)
        );
        dispatch({
          type: types.ADD_SURVEY_TO_CHILD_SUCCESS,
          fileAttachment: {
            fileId,
            name: fileAttachment.name,
            fileInfo: fileAttachment.fileInfo,
            uploadDate: moment().format()
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.ADD_SURVEY_TO_CHILD_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się załączyć pliku do profilu dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function addChildDiagnoseStart() {
  return { type: types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET };
}

export function addChildDiagnoseCancel() {
  return { type: types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL };
}

export function addChildDiagnoseAsync(childId, diagnose) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT });
    //Api
    api.addDiagnose(childId, diagnose).then((newDiagnose) => {
      dispatch({
        type: types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS,
        diagnose: {
          ...diagnose,
          diagnoseId: newDiagnose.diagnoseId
        }
      });
      dispatch(notificationActions.showSuccess('Pomyślnie dodano diagnozę'));
    });
  };
}

export function removeChildDiagnoseAsync(childId, diagnose) {
  return (dispatch) => {
    api.deleteDiagnose(childId, diagnose).then(() => {
      dispatch({
        type: types.REMOVE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS,
        diagnose
      });
      dispatch(notificationActions.showSuccess('Pomyślnie usunieto diagnozę'));
    });
  };
}

export function updateChildDiagnoseAsync(nextSet) {
  const set = { ...nextSet };
  return (dispatch) => {
    dispatch({ type: types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT });
    //Api
    dispatch(notificationActions.showSuccess(`Zaaktualizowano schemat diagnoz o nazwie '${set.title}'`));
    dispatch({
      type: types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS,
      set
    });
  };
}

export function addOpinionToChildStart() {
  return { type: types.ADD_OPINION_TO_CHILD };
}

export function addOpinionToChildCancel() {
  return { type: types.ADD_OPINION_TO_CHILD_CANCEL };
}

export function addOpinionAsync(childId, fileAttachment) {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_OPINION_TO_CHILD_SUBMIT });
    return api
      .addOpinion(childId, fileAttachment)
      .then((fileId) => {
        dispatch(
          notificationActions.showSuccess(`Załączono plik dla profilu dziecka '${get.childName(getState, childId)}'`)
        );
        dispatch({
          type: types.ADD_OPINION_TO_CHILD_SUCCESS,
          fileAttachment: {
            fileId,
            name: fileAttachment.name,
            fileInfo: fileAttachment.fileInfo,
            uploadDate: moment().format()
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.ADD_OPINION_TO_CHILD_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się załączyć pliku do profilu dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function addVacationPeriodToChildStart() {
  return { type: types.ADD_VACATION_PERIOD_TO_CHILD };
}

export function addVacationPeriodToChildCancel() {
  return { type: types.ADD_VACATION_PERIOD_TO_CHILD_CANCEL };
}

export function addVacationPeriodToChildAsync(childId, vacationPeriod) {
  const { vacationPeriodStart, vacationPeriodEnd } = vacationPeriod;
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_VACATION_PERIOD_TO_CHILD_SUBMIT });
    return api
      .addVacationPeriod(childId, vacationPeriodStart, vacationPeriodEnd)
      .then((newVacationPeriod) => {
        dispatch(
          notificationActions.showSuccess(`Dodano okres urlopu dla dziecka '${get.childName(getState, childId)}'`)
        );
        dispatch({ type: types.ADD_VACATION_PERIOD_TO_CHILD_SUCCESS, newVacationPeriod });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać okresu urlopu dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeVacationPeriodFromChildAsync(childId, vacationPeriodId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_VACATION_PERIOD_FROM_CHILD });
    return api
      .deleteVacationPeriod(childId, vacationPeriodId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(`Usunięto okres urlopu dla dziecka '${get.childName(getState, childId)}'`)
        );
        dispatch({ type: types.REMOVE_VACATION_PERIOD_FROM_CHILD_SUCCESS, vacationPeriodId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć okresu urlopu dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function importDataAsync(fileAttachment) {
  return (dispatch) => {
    dispatch({ type: types.ADD_OPINION_TO_CHILD_SUBMIT });
    return api
      .importData(fileAttachment)
      .then((fileId) => {
        dispatch(notificationActions.showSuccess('Załączono plik dla profilu dziecka'));
        dispatch({
          type: types.ADD_OPINION_TO_CHILD_SUCCESS,
          fileAttachment: {
            fileId,
            name: fileAttachment.name,
            fileInfo: fileAttachment.fileInfo,
            uploadDate: moment().format()
          }
        });
        browserHistory.push(routePaths.children);
      })
      .catch((error) => {
        dispatch({ type: types.ADD_OPINION_TO_CHILD_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się załączyć pliku do profilu dziecka'));
        return logger.error(error);
      });
  };
}

// export function removeSurveyAsync(childId, fileAttachmentId) {
//   return (dispatch, getState) => {
//     dispatch({ type: types.REMOVE_SURVEY_FROM_CHILD });
//     return api
//       .deleteFileAttachment(childId, fileAttachmentId)
//       .then(() => {
//         dispatch(notificationActions.showSuccess(`Usunięto załączony plik z profilu dziecka '${get.childName(getState, childId)}'`));
//         dispatch({ type: types.REMOVE_SUVEY_FROM_CHILD_SUCCESS, fileAttachmentId });
//       })
//       .catch(error => {
//         dispatch(notificationActions.showError(`Nie udało się usunąć załączonego pliku z profilu dziecka '${get.childName(getState, childId)}'`));
//         return logger.error(error);
//       });
//   };
// }

export function loadChildAttributesAsync(childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD_ATTRIBUTES });
    return attributesApi
      .getAttributesValues(childId)
      .then((attributesValues) => dispatch({ type: types.LOAD_CHILD_ATTRIBUTES_SUCCESS, childId, attributesValues }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się pobrać attrybutów dziecka '${get.childName(getState, childId)}`)
        );
        return logger.error(error);
      });
  };
}

export function updateChildAttributesAsync(childId, attributeSet) {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CHILD_ATTRIBUTES });
    return api
      .updateAttributes(childId, attributeSet)
      .then(() => dispatch({ type: types.UPDATE_CHILD_ATTRIBUTES_SUCCESS, childId, attributeSet }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować attrybutów ${attributeSet.name} dla dziecka '${get.childName(
              getState,
              childId
            )}`
          )
        );
        return logger.error(error);
      });
  };
}

export function fetchSignUrl(childId, guardianId) {
  return (dispatch) => {
    dispatch({ type: types.FETCH_SIGN_URL });
    return api
      .fetchSignUrl(childId, guardianId, {
        surl: `${window.location.origin}${routePaths.authorizedBySign}?status=ok&child=${childId}&guardian=${guardianId}`,
        furl: `${window.location.origin}${routePaths.authorizedBySign}?status=error&child=${childId}`
      })
      .then(({ sputnikLink }) => {
        localStorage.setItem('sputnikLink', sputnikLink);
        window.location.href = sputnikLink;
      })
      .then(() => {})
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało podpisać zgłoszenia'));
        return logger.error(error);
      });
  };
}

export function completeAuthorizationBySign(childId, guardianId) {
  return (dispatch) => {
    dispatch({ type: types.COMPLETE_AUTHORIZATION_BY_SIGN });
    const url = localStorage.getItem('sputnikLink');
    if (!url) {
      dispatch(notificationActions.showError('Wystąpił błąd podczas podpisu zgłoszenia'));
      return browserHistory.replace(fromTemplate(routePaths.guardianChildDetails, [childId]));
    }
    return api
      .completeAuthorizationBySign(childId, guardianId, url)
      .then(() => {
        localStorage.removeItem('sputnikLink');
        dispatch(notificationActions.showSuccess('Zgłoszenie zostało podpisane pomyślnie'));
        browserHistory.replace(fromTemplate(routePaths.guardianChildDetails, [childId]));
      })
      .catch((error) => {
        browserHistory.replace(fromTemplate(routePaths.guardianChildDetails, [childId]));
        dispatch(notificationActions.showError('Wystąpił błąd podczas podpisu zgłoszenia'));
        return logger.error(error);
      });
  };
}

export function completeAuthorizationBySignFailure(childId) {
  return (dispatch) => {
    dispatch({ type: types.COMPLETE_AUTHORIZATION_BY_SIGN_FAILURE });
    browserHistory.replace(fromTemplate(routePaths.guardianChildDetails, [childId]));
    dispatch(notificationActions.showError('Wystąpił błąd podczas podpisu zgłoszenia'));
  };
}

export function walletTransferAsync(from, target, amount, childId) {
  return async (dispatch) => {
    dispatch({ type: types.WALLET_TRANSFER_SUBMIT });
    try {
      const response = await api.walletTransfer({ from, target, amount }, childId);
      dispatch({ type: types.WALLET_TRANSFER_SUCCESS, response });
      dispatch(notificationActions.showSuccess(`Pomyślnie dokonano transferu między portfelami`));
    } catch (error) {
      dispatch({ type: types.WALLET_TRANSFER_FAILURE });
    }
  };
}

export function loadChildrenTakenOverByDebtCollection() {
  return async (dispatch) => {
    dispatch({ type: types.GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUBMIT });
    try {
      const response = await api.loadChildrenTakenOverByDebtCollection();
      dispatch({ type: types.GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUCCESS, response });
    } catch (error) {
      dispatch({ type: types.GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_FAILURE });
    }
  };
}

export function debtCollectionConfirmationStart() {
  return { type: types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION };
}

export function debtCollectionConfirmationCancel() {
  return { type: types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_CANCEL };
}

export function removeTakenOverByDebtCollectionChildrenStart() {
  return { type: types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN };
}

export function removeTakenOverByDebtCollectionChildrenCancel() {
  return { type: types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_CANCEL };
}

export function debtCollectionConfirmation(childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUBMIT });
    return api
      .addTakenOverByDebtCollectionChildren({ childrenIds: [childId] })
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Potwierdzono przejęcie do windykacji długu dziecka '${get.childName(getState, childId)}'`
          )
        );
        dispatch({ type: types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUCCESS, childId });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się potwierdzić przejęcia do windykacji długu dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeTakenOverByDebtCollectionChildren(childrenIds) {
  return async (dispatch) => {
    dispatch({ type: types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUBMIT });
    try {
      const response = await api.removeTakenOverByDebtCollectionChildren(childrenIds);
      dispatch({ type: types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUCCESS, response });
    } catch (error) {
      dispatch({ type: types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_FAILURE });
    }
  };
}

export function loadChildrenOnVacationPeriodAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHILDREN_ON_VACATION_PERIOD });
    return api
      .getChildrenOnVacationPeriod()
      .then((childrenOnVacationPeriod) =>
        dispatch({ type: types.LOAD_CHILDREN_ON_VACATION_PERIOD_SUCCESS, childrenOnVacationPeriod })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać dzieci objętych okresem urlopowania'));
        return logger.error(error);
      });
  };
}
