import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settlementsSettingsActions from '../../../actions/settlementsSettingsActions';
import { Paper, Divider, Box, Typography, Button } from '@mui/material';
import SettlementsSettingsDialog from './dialogs/SettlementsSettingsDialog';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import AdvancedInterests from './components/AdvancedInterests';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import Strong from '../../common/Strong';
import ContentWrapper from '../../common/contentWrapper';

class SettlementsSettingsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleEdit() {
    this.props.actions.updateSettlementsSettingsStart();
  }

  handleSave(settings) {
    this.props.actions.updateSettlementsSettingsAsync(settings);
  }

  handleCancel() {
    this.props.actions.updateSettlementsSettingsCancel();
  }

  getNumberingSchemeName(id) {
    const scheme = this.props.numberingSchemes.find((s) => s.id === id);
    return scheme ? scheme.name : null;
  }

  getAdvancedInterestEnabled() {
    return (
      this.props.settlementsSettings.useAdvancedInterestSystemStay ||
      this.props.settlementsSettings.useAdvancedInterestSystemCatering ||
      this.props.settlementsSettings.useAdvancedInterestSystemOther
    );
  }

  renderDialog() {
    if (this.props.ui.isEditing) {
      return (
        <SettlementsSettingsDialog
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          settlementsSettings={this.props.settlementsSettings}
          numberingSchemes={this.props.numberingSchemes}
          isDialogOpen={this.props.ui.isEditing}
          isProcessing={this.props.ui.isProcessing}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Ustawienia rozliczeń" titleIcon={<SettingsApplicationsIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" aria-label="Zmień ustawienia" onClick={this.handleEdit} sx={{ mb: 2 }}>
                Zmień ustawienia
              </Button>
            </Box>
            <Paper sx={{ p: 4 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Integracja z systemem Symfonia
              </Typography>
              <Box>
                <Typography component="span">Integracja z systemem Symfonia: </Typography>
                {this.props.settlementsSettings.symphonyIntegrationEnabled === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Integracja z systemem Sputnik
              </Typography>
              <Box>
                <Typography component="span">Integracja z systemem Sputnik: </Typography>
                {this.props.settlementsSettings.integrationEnabled === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              {this.props.settlementsSettings.integrationEnabled && (
                <Box>
                  <Box>
                    <Typography component="span">ID przedszkola w systemie FK: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.integrationID || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">URL systemu FK: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.integrationURL || 'brak'}</Strong>
                  </Box>
                  <br />
                  <Box>
                    <Typography component="span">Wyżywienie - Konto Ma: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.cateringHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Wyżywienie - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.cateringOwed || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Wyżywienie odsetki - Konto Ma : </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.cateringPenaltyHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Wyżywienie odsetki - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.cateringPenaltyOwed || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Wyżywienie wpłata razem - Konto Ma: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.cateringPaidHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Wyżywienie wpłata razem - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.cateringPaidOwed || 'brak'}</Strong>
                  </Box>
                  <br />
                  <Box>
                    <Typography component="span">Pobyt - Konto Ma: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.stayHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Pobyt - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.stayOwed || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Pobyt odsetki - Konto Ma : </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.stayPenaltyHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Pobyt odsetki - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.stayPenaltyOwed || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Pobyt wpłata razem - Konto Ma: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.stayPaidHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Pobyt wpłata razem - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.stayPaidOwed || 'brak'}</Strong>
                  </Box>

                  <br />

                  <Box>
                    <Typography component="span">Dodatkowe - Konto Ma: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.otherHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Dodatkowe - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.otherOwed || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Dodatkowe odsetki - Konto Ma : </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.otherPenaltyHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Dodatkowe odsetki - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.otherPenaltyOwed || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Dodatkowe wpłata razem - Konto Ma: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.otherPaidHas || 'brak'}</Strong>
                  </Box>
                  <Box>
                    <Typography component="span">Dodatkowe wpłata razem - Konto Winien: </Typography>
                    <Strong>{this.props.settlementsSettings.integration.accounts.otherPaidOwed || 'brak'}</Strong>
                  </Box>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                  Płatności online
                </Typography>
                Grupowanie płatności online według rodzaju rozliczenia:{' '}
                {this.props.settlementsSettings.mergeBMProductsWithSameAccountNumber === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                  Korzystanie z portfeli dodatkowych:
                </Typography>
                {this.props.settlementsSettings.useAdditionalWallets === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Zwolnienia z opłat
              </Typography>
              <Box>
                <Typography component="span">Zwolnienie sześciolatków z opłat za pobyt: </Typography>
                {this.props.settlementsSettings.staySixYearOldExemption === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Ustawienia odsetek
              </Typography>
              <Box>
                <Typography component="span">Rozdzielaj opłaty podstawowe od odsetkowych: </Typography>
                {this.props.settlementsSettings.separateBasicDuesFromInterestDues === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Stosuj system naliczania odsetek podatkowych: </Typography>
                {this.props.settlementsSettings.taxInterestCalculationSystem === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Odsetki za opóźnienia płatności za pobyt: </Typography>
                {this.props.settlementsSettings.stayDelayInterestCharging === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              {this.props.settlementsSettings.taxInterestCalculationSystem === true && (
                <Box sx={{ display: 'flex' }}>
                  <Typography>Minimalna kwota odsetek podatkowych: </Typography>
                  <Strong>{this.props.settlementsSettings.taxInterestMinAmount} zł</Strong>
                </Box>
              )}
              <Box>
                <Typography component="span">Odsetki za opóźnienia płatności za wyżywienie: </Typography>
                {this.props.settlementsSettings.cateringDelayInterestCharging === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>{this.props.settlementsSettings.cateringDelayInterestRate}%</Strong>
                )}
              </Box>

              <Box>
                <Typography component="span">Odsetki za opóźnienia innych płatności: </Typography>
                {this.props.settlementsSettings.otherDelayInterestCharging === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>{this.props.settlementsSettings.otherDelayInterestRate}%</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Odsetki za opóźnienia płatności za wyżywienie pracowników: </Typography>
                {this.props.settlementsSettings.staffCateringDelayInterestCharging === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>{this.props.settlementsSettings.staffCateringDelayInterestRate}%</Strong>
                )}
              </Box>
              {this.getAdvancedInterestEnabled() ? (
                <>
                  <Divider sx={{ my: 2 }} />
                  <AdvancedInterests data={this.props.settlementsSettings.advancedInterests} editable={false} />
                </>
              ) : null}
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Ustawienia zaokrąglania
              </Typography>
              <Box>
                Zaokrąglanie naliczonej kwoty odsetek do pełnych dziesiątek groszy:{' '}
                {this.props.settlementsSettings.interestToNearestTenRounding === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                Zaokrąglanie naliczonej kwoty odsetek do pełnych złotówek:{' '}
                {this.props.settlementsSettings.interestToNearestFullRounding === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Zaokrąglanie opłat za pobyt: </Typography>
                {this.props.settlementsSettings.stayDuesRounding === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Zaokrąglanie opłat za wyżywienie: </Typography>
                {this.props.settlementsSettings.cateringDuesRounding === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Zaokrąglanie innych opłat: </Typography>
                {this.props.settlementsSettings.otherDuesRounding === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Zaokrąglanie opłat za wyżywienie pracowników: </Typography>
                {this.props.settlementsSettings.staffCateringDuesRounding === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Ustawienia numeracji
              </Typography>
              <Box>
                <Typography component="span">Pobyt: </Typography>
                {!this.props.settlementsSettings.stayNumberingSchemeId ? (
                  <Strong>brak</Strong>
                ) : (
                  <Strong>{this.getNumberingSchemeName(this.props.settlementsSettings.stayNumberingSchemeId)}</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Wyżywienie: </Typography>
                {!this.props.settlementsSettings.cateringNumberingSchemeId ? (
                  <Strong>brak</Strong>
                ) : (
                  <Strong>
                    {this.getNumberingSchemeName(this.props.settlementsSettings.cateringNumberingSchemeId)}
                  </Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Inne płatności: </Typography>
                {!this.props.settlementsSettings.otherNumberingSchemeId ? (
                  <Strong>brak</Strong>
                ) : (
                  <Strong>{this.getNumberingSchemeName(this.props.settlementsSettings.otherNumberingSchemeId)}</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Wyżywienie pracowników: </Typography>
                {!this.props.settlementsSettings.staffCateringNumberingSchemeId ? (
                  <Strong>brak</Strong>
                ) : (
                  <Strong>
                    {this.getNumberingSchemeName(this.props.settlementsSettings.staffCateringNumberingSchemeId)}
                  </Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Zajęcia dodatkowe: </Typography>
                {!this.props.settlementsSettings.activitiesNumberingSchemeId ? (
                  <Strong>brak</Strong>
                ) : (
                  <Strong>
                    {this.getNumberingSchemeName(this.props.settlementsSettings.activitiesNumberingSchemeId)}
                  </Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Posiłki: </Typography>
                {!this.props.settlementsSettings.mealsNumberingSchemeId ? (
                  <Strong>brak</Strong>
                ) : (
                  <Strong>{this.getNumberingSchemeName(this.props.settlementsSettings.mealsNumberingSchemeId)}</Strong>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Ustawienia księgowania nadpłat
              </Typography>
              <Box>
                <Typography component="span">Księgowanie nadpłat z opłat za pobyt na saldo dziecka: </Typography>
                {this.props.settlementsSettings.stayCreditDueOverpaymentsToBalance === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Księgowanie nadpłat z opłat za wyżywienie na saldo dziecka: </Typography>
                {this.props.settlementsSettings.cateringCreditDueOverpaymentsToBalance === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
              <Box>
                <Typography component="span">Księgowanie nadpłat z innych opłat na saldo dziecka: </Typography>
                {this.props.settlementsSettings.otherCreditDueOverpaymentsToBalance === false ? (
                  <Strong>wyłączone</Strong>
                ) : (
                  <Strong>włączone</Strong>
                )}
              </Box>
            </Paper>
            {this.renderDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

SettlementsSettingsPage.propTypes = {
  settlementsSettings: PropTypes.object.isRequired,
  numberingSchemes: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    settlementsSettings: state.configuration.settlementsSettings,
    numberingSchemes: state.configuration.numberingSchemes,
    ui: state.configuration.settlementsSettingsUi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsSettingsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsSettingsPage);
