import api from '../api/UnitApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import PublisherApi from '../api/PublisherApi';
import QrCodeApi from '../api/QrCodeApi';

export function loadUnitDataAsync() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_UNIT_DATA });
    const authModel = getState().auth;
    return api
      .getUnitData()
      .then((unit) => dispatch({ type: types.LOAD_UNIT_DATA_SUCCESS, unit, authModel }))
      .catch((error) => {
        if (error.status === 404) return dispatch({ type: types.LOAD_UNIT_DATA_NOT_FOUND });

        dispatch(notificationActions.showError('Nie udało się pobrać ustawień placówki'));
        return logger.error(error);
      });
  };
}

export function updateUnitAddressStart() {
  return { type: types.UPDATE_UNIT_ADDRESS };
}

export const clearDefaultPublisher = () => (dispatch) => {
  PublisherApi.clearDefaultPublisher()
    .then(() => {
      dispatch({ type: types.REMOVE_UNIT_DEFAULT_PUBLISHER });
      dispatch(notificationActions.showSuccess('Usunięto domyślnego wydawcę'));
    })
    .catch(() => dispatch(notificationActions.showError('Coś poszło nie tak')));
};

export const updateDefaultPublisher = (defaultPublishingHouseId) => (dispatch) => {
  PublisherApi.updateDefaultPublisher(defaultPublishingHouseId)
    .then(() => {
      dispatch({ type: types.UPDATE_UNIT_DEFAULT_PUBLISHER_SUCCESS, defaultPublishingHouseId });
      dispatch(notificationActions.showSuccess('Zmieniono domyślnego wydawcę'));
    })
    .catch(() => {
      dispatch({ type: types.UPDATE_UNIT_DEFAULT_PUBLISHER_FAIL });
      dispatch(notificationActions.showError('Coś poszło nie tak'));
    });
};

export function updateUnitAddressCancel() {
  return { type: types.UPDATE_UNIT_ADDRESS_CANCEL };
}

export function updateUnitAddressAsync(nextAddress) {
  const address = Object.assign({}, nextAddress);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_UNIT_ADDRESS_SUBMIT });
    return api
      .updateUnitAddress(address)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zaktualizowano adres placówki'));
        dispatch({ type: types.UPDATE_UNIT_ADDRESS_SUCCESS, address });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_UNIT_ADDRESS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować adresu placówki'));
        return logger.error(error);
      });
  };
}

export function updateUnitPenaltiesStart() {
  return { type: types.UPDATE_UNIT_PENALTIES };
}

export function updateUnitPenaltiesCancel() {
  return { type: types.UPDATE_UNIT_PENALTIES_CANCEL };
}

export function updateUnitPenaltiesAsync(nextPenaltiesSettings) {
  const penalties = Object.assign({}, nextPenaltiesSettings);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_UNIT_PENALTIES_SUBMIT });
    return api
      .updatePenaltiesSettings(penalties)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zaktualizowano konfigurację kar'));
        dispatch({ type: types.UPDATE_UNIT_PENALTIES_SUCCESS, penalties });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_UNIT_PENALTIES_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować konfiguracji kar'));
        return logger.error(error);
      });
  };
}

export function updateUnitWorkingHoursStart() {
  return { type: types.UPDATE_UNIT_WORKING_HOURS };
}

export function updateUnitWorkingHoursCancel() {
  return { type: types.UPDATE_UNIT_WORKING_HOURS_CANCEL };
}

export function updateUnitWorkingHoursAsync(nextWorkingHours) {
  const workingHours = Object.assign({}, nextWorkingHours);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_UNIT_WORKING_HOURS_SUBMIT });
    return api
      .updateUnitWorkingHours(workingHours)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zaktualizowano godziny pracy placówki'));
        dispatch({ type: types.UPDATE_UNIT_WORKING_HOURS_SUCCESS, workingHours });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_UNIT_WORKING_HOURS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować godziny pracy placówki'));
        return logger.error(error);
      });
  };
}

export function updateUnitAbsenceRemovalDeadlineStart() {
  return { type: types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE };
}

export function updateUnitAbsenceRemovalDeadlineCancel() {
  return { type: types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_CANCEL };
}

export function updateUnitAbsenceRemovalDeadlineAsync(value) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUBMIT });
    return api
      .updateUnitAbsenceRemovalDeadline(value)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zaktualizowano czas na wycofywanie nieobecności'));
        dispatch({
          type: types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUCCESS,
          legalGuardiansAbsenceRemovalDeadlineHours: value
        });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować limitu czasu na wycofywanie nieobecności'));
        return logger.error(error);
      });
  };
}

export function updateTwoWayMessagingSettingAsync(enabled) {
  return (dispatch) => {
    return api
      .updateTwoWayMessagingSettings(enabled)
      .then(() => {
        if (enabled) dispatch({ type: types.ENABLE_TWO_WAY_MESSAGING_SUCCESS });
        else dispatch({ type: types.DISABLE_TWO_WAY_MESSAGING_SUCCESS });
        dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia komunikacji dwukierunkowej'));
      })
      .catch(() => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień komunikacji dwukierunkowej'));
      });
  };
}

export function updateQrCodeServiceSettingAsync(enabled, enabledDynamic) {
  return async (dispatch) => {
    try {
      await QrCodeApi.updateQrCodeSetting(enabled, enabledDynamic);
      if (enabled) dispatch({ type: types.ENABLE_QR_CODE_SERVICE_SUCCESS, payload: { dynamic: enabledDynamic } });
      else dispatch({ type: types.DISABLE_QR_CODE_SERVICE_SUCCESS });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia rejestrowania obecności kodem QR'));
    } catch (_) {
      dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień rejestrowania obecności kodem QR'));
    }
  };
}

export function updateReportAbsenceInDaysOffAsync(enabled) {
  return (dispatch) => {
    return api
      .updateReportAbsenceInDaysOff(enabled)
      .then(() => {
        if (enabled) dispatch({ type: types.ENABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS });
        else dispatch({ type: types.DISABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS });
        dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia zgłaszania nieobecności w dni wolne'));
      })
      .catch(() => {
        dispatch(
          notificationActions.showError('Nie udało się zaktualizować ustawień zgłaszania nieobecności w dni wolne')
        );
      });
  };
}

export function updateNoHoursAttendanceAsync(enabled) {
  return (dispatch) => {
    return api
      .updateNoHoursAttendance(enabled)
      .then(() => {
        if (enabled) dispatch({ type: types.ENABLE_NO_HOURS_ATTENDANCE_SUCCESS });
        else dispatch({ type: types.DISABLE_NO_HOURS_ATTENDANCE_SUCCESS });
        dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia wprowadzania obecności'));
      })
      .catch(() => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień wprowadzania obecności'));
      });
  };
}

export function postsModifyingEnabledAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updatePostsModifyingEnabled(enabled);
      if (enabled) dispatch({ type: types.ENABLE_POSTS_MODIFYING_SUCCESS });
      else dispatch({ type: types.DISABLE_POSTS_MODIFYING_SUCCESS });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia edycji postów'));
    } catch (e) {
      dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień edycji postów'));
    }
  };
}

export function individualBankAccountsAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateIndividualBankAccounts(enabled);
      if (enabled) dispatch({ type: types.ENABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS });
      else dispatch({ type: types.DISABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia edycji postów'));
    } catch (e) {
      dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień edycji postów'));
    }
  };
}

export function addContractNumberToSettlementNumberAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateAddContractNumberToSettlementNumber(enabled);
      if (enabled) dispatch({ type: types.ENABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS });
      else dispatch({ type: types.DISABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS });
      dispatch(
        notificationActions.showSuccess('Zaktualizowano ustawienia dołączania numeru umowy do numeru rozliczenia')
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dołączania numeru umowy do numeru rozliczenia'
        )
      );
    }
  };
}

export function childrenAgeInfoEnabledAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateChildrenAgeInfo(enabled);
      if (enabled) dispatch({ type: types.ENABLE_CHILDREN_AGE_INFO });
      else dispatch({ type: types.DISABLE_CHILDREN_AGE_INFO });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące wyświetlania wieku dziecka'));
    } catch (e) {
      dispatch(
        notificationActions.showError('Nie udało się zaktualizować ustawień dotyczących wyświetlania wieku dziecka')
      );
    }
  };
}

export function sendingChildPickedUpNotificationBlockedAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateSendingChildPickedUpNotificationBlocked(enabled);
      if (enabled) dispatch({ type: types.ENABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED });
      else dispatch({ type: types.DISABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED });
      dispatch(
        notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące blokowania powiadomień o odbiorze dziecka')
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dotyczących blokowania powiadomień o odbiorze dziecka'
        )
      );
    }
  };
}

export function editingChildDataByTeacherBlockedAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateEditingChildDataByTeacherBlocked(enabled);
      if (enabled) dispatch({ type: types.ENABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED });
      else dispatch({ type: types.DISABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED });
      dispatch(
        notificationActions.showSuccess(
          'Zaktualizowano ustawienia dotyczące uprawnień nauczyciela do edycji danych dzieci'
        )
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dotyczących uprawnień nauczyciela do edycji danych dzieci'
        )
      );
    }
  };
}

export function mealsCateringDues(enabled) {
  return async (dispatch) => {
    try {
      await api.updateMealsCateringDues(enabled);
      if (enabled) dispatch({ type: types.ENABLE_MEALS_CATERING_DUES });
      else dispatch({ type: types.DISABLE_MEALS_CATERING_DUES });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące korzystania z modułu posiłków'));
    } catch (e) {
      dispatch(
        notificationActions.showError('Nie udało się zaktualizować ustawień dotyczących korzystania z modułu posiłków')
      );
    }
  };
}

export function teacherGropuAccessAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateTeacherGroupAccess(enabled);
      if (enabled) dispatch({ type: types.ENABLE_TEACHER_GROUP_ACCESS });
      else dispatch({ type: types.DISABLE_TEACHER_GROUP_ACCESS });
      dispatch(
        notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące dostępu do grup przez nauczycieli')
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dotyczących dostępu do grup przez nauczycieli'
        )
      );
    }
  };
}

export function allowDebtCollection(enabled) {
  return async (dispatch) => {
    try {
      await api.updateAllowDebtCollection(enabled);
      if (enabled) dispatch({ type: types.ENABLE_ALLOW_DEBT_COLLECTION });
      else dispatch({ type: types.DISABLE_ALLOW_DEBT_COLLECTION });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące możliwości windykacji'));
    } catch (e) {
      dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień dotyczących możliwości windykacji'));
    }
  };
}

export function allowAdaptation(enabled) {
  return async (dispatch) => {
    try {
      await api.updateAllowAdaptation(enabled);
      if (enabled) dispatch({ type: types.ENABLE_ALLOW_ADAPTATION });
      else dispatch({ type: types.DISABLE_ALLOW_ADAPTATION });
      dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące możliwości adaptacji'));
    } catch (e) {
      dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień dotyczących możliwości adaptacji'));
    }
  };
}

export function teacherPostsAccessAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateTeacherPostsAccess(enabled);
      if (enabled) dispatch({ type: types.ENABLE_TEACHER_POSTS_ACCESS });
      else dispatch({ type: types.DISABLE_TEACHER_POSTS_ACCESS });
      dispatch(
        notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące dostępu do ogłoszeń przez nauczycieli')
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dotyczących dostępu do ogłoszeń przez nauczycieli'
        )
      );
    }
  };
}

export function legalGuardianPostsAccessAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.updateLegalGuardianPostsAccess(enabled);
      if (enabled) dispatch({ type: types.ENABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS });
      else dispatch({ type: types.DISABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS });
      dispatch(
        notificationActions.showSuccess('Zaktualizowano ustawienia dotyczące dostępu do ogłoszeń przez rodziców')
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dotyczących dostępu do ogłoszeń przez rodziców'
        )
      );
    }
  };
}

export function sendingOverduesNotificationAsync(enabled) {
  return async (dispatch) => {
    try {
      await api.sendingOverduesNotification(enabled);
      if (enabled) dispatch({ type: types.ENABLE_SENDING_OVERDUES_NOTIFICATIONS });
      else dispatch({ type: types.DISABLE_SENDING_OVERDUES_NOTIFICATIONS });
      dispatch(
        notificationActions.showSuccess(
          'Zaktualizowano ustawienia dotyczące wysyłania powiadomień do rodziców o zaległych opłatach'
        )
      );
    } catch (e) {
      dispatch(
        notificationActions.showError(
          'Nie udało się zaktualizować ustawień dotyczących wysyłania powiadomień do rodziców o zaległych opłatach'
        )
      );
    }
  };
}

//   export function notificationSendingEnabledAsync(enabled) {
//   return async (dispatch) => {
//     try {
//       await api.updateNotificationSendingEnabled(enabled);
//       if (enabled) dispatch({ type: types.ENABLE_NOTIFICATION_SENDING_SUCCESS });
//       else dispatch({ type: types.DISABLE_NOTIFICATION_SENDING_SUCCESS });
//       dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia wysyłania powiadomień'));
//     } catch (e) {
//       dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień wysyłania powiadomień'));
//     }
//   };
// }
