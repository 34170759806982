import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';
import PostUpdateInfo from '../../../messageBoard/post/PostUpdateInfo';

const PostHeader = ({ post, sx, noAvatar }) => {
  const { title, eventDate, eventStartTime, eventEndTime, pinned, author } = post;
  return (
    <Box sx={{ py: 1, ...sx }}>
      {(author?.userFullName || author?.fullName) && <PostUpdateInfo post={post} noAvatar={noAvatar} />}
      {eventDate && (
        <Typography
          sx={{
            mb: 1,
            wordBreak: 'break-word',
            color: (theme) => theme.palette.text.primary,
            fontWeight: (theme) => theme.typography.fontWeightMedium
          }}>
          {eventDate && `${moment(eventDate).format('DD MMMM')}`}
          {!!eventStartTime && typeof eventStartTime === 'string'
            ? `, godzina ${eventStartTime.toString()}`
            : `, godzina ${eventStartTime.hourLocal}:${moment(eventStartTime.minuteLocal, 'minutes').format('mm')}`}
          {!!eventEndTime && typeof eventStartTime === 'string'
            ? ` do ${eventEndTime.toString()}`
            : ` do ${eventEndTime.hourLocal}:${moment(eventEndTime.minuteLocal, 'minutes').format('mm')}`}
        </Typography>
      )}
      <Box>
        {pinned && <StarIcon sx={{ mr: 1, color: (theme) => theme.palette.color.color20 }} />}
        {title}
      </Box>
    </Box>
  );
};

PostHeader.propTypes = {
  post: PropTypes.object.isRequired
};

export default PostHeader;
