import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../actions/attendanceActions';
import * as daysOffActions from '../../actions/daysOffActions';
import * as childrenActions from '../../actions/childrenActions';
import * as reportsActions from '../../actions/reportsActions';
import * as daycareActions from '../../actions/daycareActions';
import * as mealActions from '../../actions/mealActions';
import * as mealCalendarActions from '../../actions/mealCalendarActions';
import * as mealCategoriesActions from '../../actions/mealCategoriesActions';
import ChildAttendanceCalendar from '../common/children/attendance/calendar/ChildAttendanceCalendar';
import ChildDaycareCalendar from '../common/children/daycare/ChildDaycareCalendar';
import ChildrenDropDown from '../common/children/childrenDropDown/ChildrenDropDown';
import GuardianChildGuardiansCard from './GuardianChildGuardiansCard';
import DaycareDetailsDialog from './DaycareDetailsDialog';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box, CardHeader, Paper } from '@mui/material';
import ChildMealsCalendar from '../common/children/meals/ChildMealsCalendar';
import MealsDetailsDialog from '../meals/MealsDetailsDialog';

class GuardianChildDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDay: null,
      isDaycareDetailsDialogOpen: false,
      isMealDetailsDialogOpen: false
    };

    this.loadAttendanceData = this.loadAttendanceData.bind(this);
    this.loadDaycareData = this.loadDaycareData.bind(this);
    this.handlePupilChange = this.handlePupilChange.bind(this);
    this.handleDaycareDetailsOpen = this.handleDaycareDetailsOpen.bind(this);
    this.getDaycareDetailsByDay = this.getDaycareDetailsByDay.bind(this);
    this.handleMealDetailsOpen = this.handleMealDetailsOpen.bind(this);
  }

  getDaycareDetailsByDay(date) {
    return date
      ? this.props.daycare.entries.find(
          (a) => moment(a.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
        ) || {}
      : {};
  }

  loadAttendanceData(date) {
    this.props.attendanceActions.loadChildAttendancesAsync(this.props.pupilId, date);
    this.props.daysOffActions.loadDaysOffAsync(moment(date).format('YYYY'));
  }

  loadDaycareData(date) {
    this.props.daycareActions.loadChildDaycare(this.props.pupilId, date);
  }

  loadMealsCalendarData = (date) => {
    this.props.mealCalendarActions.loadChildMealsByMonthAsync(
      this.props.pupilId,
      moment(date).format('YYYY'),
      moment(date).format('MM')
    );
  };

  whenChildIsEnrolledToGroup(day, actionWhenEnrolled) {
    if (this.props.pupil.enrolledToGroup) {
      this.setState({ currentDay: day }, actionWhenEnrolled);
    }
  }

  handlePupilChange(value) {
    browserHistory.push(fromTemplate(routePaths.guardianChildDetails, [value]));
  }

  handleDaycareDetailsOpen(day) {
    if (day.data !== '') {
      this.setState({
        currentDay: day,
        isDaycareDetailsDialogOpen: true
      });
    }
  }

  handleMealDetailsOpen(day) {
    this.setState({
      currentDay: day,
      isMealDetailsDialogOpen: true
    });
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ChildrenDropDown
              pupils={this.props.pupils}
              pupilId={this.props.pupilId}
              onPupilChange={this.handlePupilChange}
            />
          </Box>
          <Paper sx={{ p: 2, my: 2 }}>
            <CardHeader title="Obecności" sx={{ pb: 0 }} />
            <ChildAttendanceCalendar
              allDays={this.props.attendances}
              markedDays={this.props.attendances.entries}
              attendanceSummary={this.props.attendances.summary}
              hidePaidDays={true}
              daysOff={this.props.daysOff}
              onDateChange={(date) => this.loadAttendanceData(date)}
              onDaySelected={() => {}}
              onReportAttendance={() => {}}
              onReportAbsence={() => {}}
              onRevertAbsence={(day)=>
                this.whenChildIsEnrolledToGroup(day, () =>
                this.props.attendanceActions.revertChildAbsenceAsync(this.props.pupil.id, day.date, day.date)
              )
              }
              onDetailsOpen={() => {}}
              isReadOnly={true}
              isParent
              isLoading={this.props.isCalendarLoading}
            />
          </Paper>
          {this.props.isDaycareEnabled && (
            <Paper sx={{ p: 2, my: 2 }}>
              <CardHeader title="Aktywność" sx={{ pb: 0 }} />
              <ChildDaycareCalendar
                markedDays={this.props.daycare.entries}
                daysOff={this.props.daysOff}
                onDateChange={(date) => this.loadDaycareData(date)}
                onDaySelected={() => {}}
                onDetailsOpen={(day) => this.handleDaycareDetailsOpen(day)}
                isLoading={this.props.isCalendarLoading}
              />
            </Paper>
          )}
          <Paper sx={{ p: 2, my: 2 }}>
            <CardHeader title="Posiłki" sx={{ pb: 0 }} />
            <ChildMealsCalendar
              markedDays={this.props.mealsCalendar.calendar}
              daysOff={this.props.daysOff}
              onDateChange={(date) => this.loadMealsCalendarData(date)}
              onDaySelected={() => {}}
              onDetailsOpen={(day) => this.handleMealDetailsOpen(day)}
              isLoading={this.props.isCalendarLoading}
            />
          </Paper>
          {this.state.isDaycareDetailsDialogOpen && (
            <DaycareDetailsDialog
              title="Aktywność dziecka"
              pupilId={this.props.pupil.id}
              pupilFirstName={this.props.pupil.firstName}
              pupilLastName={this.props.pupil.lastName}
              pupilPhotoUrl={this.props.pupil.photoUrl}
              isDialogOpen={this.state.isDaycareDetailsDialogOpen}
              date={moment(this.state.currentDay.date).format('YYYY-MM-DD')}
              entry={this.getDaycareDetailsByDay(this.state.currentDay ? this.state.currentDay.date : null)}
              onCancel={() => this.setState({ isDaycareDetailsDialogOpen: false })}
            />
          )}
          <MealsDetailsDialog
            title="Posiłki dziecka"
            meals={this.props.mealsCalendar.calendar}
            allMeals={this.props.meals}
            allCategories={this.props.mealsCategories}
            pupil={this.props.pupil}
            isDialogOpen={this.state.isMealDetailsDialogOpen}
            date={this.state.currentDay !== null && moment(this.state.currentDay.date).format('YYYY-MM-DD')}
            onCancel={() => this.setState({ isMealDetailsDialogOpen: false })}
            userRole={this.props.auth.userRole}
          />
          <GuardianChildGuardiansCard
            onSign={(guardianId) => this.props.actions.fetchSignUrl(this.props.pupil.id, guardianId)}
            guardians={this.props.pupil.guardians}
            onSave={(guardian) =>
              !guardian.id
                ? this.props.actions.addGuardianToChildAsync(this.props.pupilId, guardian)
                : this.props.actions.extendGuardianAuthorizationExpiryOnChildAsync(this.props.pupilId, guardian)
            }
            onCancel={(guardian) =>
              !guardian.id
                ? this.props.actions.addGuardianToChildCancel()
                : this.props.actions.updateGuardianOfChildCancel()
            }
            onCreate={() => this.props.actions.addGuardianToChildStart()}
            isEditing={this.props.guardiansUi.isEditing}
            isProcessing={this.props.guardiansUi.isProcessing}
            onExtendAuthorizationDate={() => this.props.actions.updateGuardianOfChildStart()}
            onDownloadAuthorizationDoc={(guardian) =>
              this.props.reportsActions.generateGuardianRequestReportAsync(this.props.pupilId, guardian.id)
            }
            unit={this.props.unit}
          />
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GuardianChildDetailsPage.propTypes = {
  pupil: PropTypes.object.isRequired,
  pupils: PropTypes.array.isRequired,
  pupilId: PropTypes.string.isRequired,
  attendances: PropTypes.object.isRequired,
  daysOff: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  attendanceActions: PropTypes.object.isRequired,
  daysOffActions: PropTypes.object.isRequired,
  reportsActions: PropTypes.object.isRequired,
  isCalendarLoading: PropTypes.bool,
  guardiansUi: PropTypes.object,
  isDaycareEnabled: PropTypes.bool,
  daycare: PropTypes.object.isRequired,
  daycareActions: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const pupilId = ownProps.params.id; //from group/:id path !parameter is always a string!
  return {
    pupilId,
    pupil: state.childDetails,
    pupils: state.legalGuardiansChildren,
    attendances: state.childAttendances,
    mealsCalendar: state.childMealsCalendar,
    meals: state.meals,
    mealsCategories: state.mealsCategories,
    daysOff: state.configuration.daysOff,
    isCalendarLoading: state.childDetailsUi.isCalendarLoading,
    guardiansUi: state.childDetailsUi.guardians,
    isDaycareEnabled: state.configuration.unit.daycareEnabled,
    daycare: state.childDaycare,
    unit: state.configuration.unit,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(childrenActions, dispatch),
    attendanceActions: bindActionCreators(attendanceActions, dispatch),
    meal: bindActionCreators(mealActions, dispatch),
    mealCategoriesActions: bindActionCreators(mealCategoriesActions, dispatch),
    mealCalendarActions: bindActionCreators(mealCalendarActions, dispatch),
    daysOffActions: bindActionCreators(daysOffActions, dispatch),
    reportsActions: bindActionCreators(reportsActions, dispatch),
    daycareActions: bindActionCreators(daycareActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardianChildDetailsPage);
