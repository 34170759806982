import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as guardianDuesActions from '../../../actions/guardianDuesActions';
import Paper from '@mui/material/Paper';
import EmptyState from '../../common/EmptyState';
import SettlementDetails from '../../settlements/details/SettlementDetails';
import Payments from '../../settlements/payments/RegisteredPaymentsList';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { CalculationStatus } from '../../../constants/calculationStatus';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Button, Typography } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import ContentWrapper from '../../common/contentWrapper';

class GuardianDueDetailsPage extends React.Component {
  componentDidMount() {
    if (this.props.dueId) {
      this.props.actions.loadDueDetailsAsync(this.props.dueId);
    }
    this.handlePrint = this.handlePrint.bind(this);
  }

  handlePrint(due) {
    this.props.actions.print(due.id, due.number);
  }

  render() {
    const { due } = this.props;
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText
            title={`Opłata - ${due.number + ' - ' + due.person.firstName + ' ' + due.person.lastName}`}
            titleIcon={<AttachMoneyOutlinedIcon />}
          />
          <ContentWrapper>
            {due.calculationStatus === CalculationStatus.paid.value ? (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                  variant="contained"
                  aria-label="Pobierz wydruk(.pdf)"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={() => this.props.actions.print(due.id, due.number)}>
                  Pobierz wydruk(.pdf)
                </Button>
              </Box>
            ) : null}
            <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.color.primary }}>
              <DoneAllOutlinedIcon sx={{ mr: 1, color: (theme) => theme.palette.color.primary }} />
              <Typography variant="h6" sx={{ my: 2, color: (theme) => theme.palette.color.primary }}>
                Opłata za {due.dueType ? ChargingReportTypes[due.dueType].name.toLowerCase() : null}
              </Typography>
            </Box>
            <Paper sx={{ p: 2 }}>
              <SettlementDetails settlement={due} onCancelDelayInterest={null} />
            </Paper>
            <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.color.primary }}>
              <DoneAllOutlinedIcon sx={{ mr: 1, color: (theme) => theme.palette.color.primary }} />
              <Typography variant="h6" sx={{ my: 2, color: (theme) => theme.palette.color.primary }}>
                Wpłaty
              </Typography>
            </Box>
            <Paper>
              {due.payments && due.payments.length > 0 ? (
                <Payments payments={due.payments} />
              ) : (
                <Box sx={{ p: 2 }}>
                  <EmptyState message="Nie zarejetrowano jeszcze żadnych wpłat" noIcon={false} contrast />
                </Box>
              )}
            </Paper>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GuardianDueDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  dueId: PropTypes.string,
  due: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    dueId: ownProps.params.id,
    due: state.guardianDueDetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(guardianDuesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardianDueDetailsPage);
