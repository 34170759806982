import moment from 'moment';
import { BaseModelValidator } from '../../../models/BaseModelValidator';
import { check } from '../../../models/validation/validateHelper';

export class PsychologicalClassesValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      title: (value) => {
        return check.isEmpty(value) && 'Nie podano nazwy';
      },
      content: (value) => check.isEmpty(value) && 'Nie podano treści wniosku'
    };
  }
}
