import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveChildAsync,
  archiveChildCancel,
  removeProfilePhoto,
  updateProfilePhoto,
  archiveChildStart,
  addFileAttachmentCancel,
  addFileAttachment,
  removeFileAttachment,
  addFileAttachmentStart,
  updateStudentAsync
} from '../../actions/childrenActions';
import ChildDetailsHeader from '../childDetails/header/ChildDetailsHeader';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ArchiveStudentDialog from '../student/ArchiveStudentDialog';
import StudentDetailsForm from './details/StudentDetailsForm';
import ChildFileAttachmentsCard from '../childDetails/fileAttachments/ChildFileAttachmentsCard';
import GroupEnrollmentHistoryCard from '../childDetails/groupEnrollmentHistory/GroupEnrollmentHistoryCard';
import { Box, Button } from '@mui/material';

const StudentDetailsPage = () => {
  const pupil = useSelector((state) => state.childDetails);
  const dispatch = useDispatch();
  const symphonyIntegration = useSelector(
    (state) => state.configuration.settlementsSettings.symphonyIntegrationEnabled
  );
  const archivalUi = useSelector((state) => state.childDetailsUi.archival);
  const attachmentsUi = useSelector((state) => state.childDetailsUi.attachments);
  const groups = useSelector((state) => state.groups);

  const onPhotoDrop = (file) => dispatch(updateProfilePhoto(pupil.id, file));
  const onPhotoRemove = () => dispatch(removeProfilePhoto(pupil.id));
  const archiveChildStartFun = () => dispatch(archiveChildStart());
  const onArchiveStudent = () => dispatch(archiveChildAsync(pupil.id, true));

  const renderArchiveStudentDialog = () => {
    return archivalUi.isEditing ? (
      <ArchiveStudentDialog
        pupil={pupil}
        isDialogOpen={archivalUi.isEditing}
        isProcessing={archivalUi.isProcessing}
        onArchive={() => onArchiveStudent()}
        onCancel={() => dispatch(archiveChildCancel())}
      />
    ) : null;
  };

  const handleStudentChangeSubmit = async (childDetails) => {
    await dispatch(updateStudentAsync(childDetails));
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <ChildDetailsHeader
          name={`${pupil.firstName} ${pupil.lastName}`}
          group={pupil.enrolledToGroup}
          photoUrl={pupil.photoUrl}
          onPhotoDrop={onPhotoDrop}
          onPhotoRemove={onPhotoRemove}
          sx={{ mb: 2 }}
        />
        <StudentDetailsForm
          pupil={pupil}
          onSubmit={handleStudentChangeSubmit}
          symphony={symphonyIntegration}
          isInitiallyExpanded={true}
        />
        <ChildFileAttachmentsCard
          pupilId={pupil.id}
          attachments={pupil.attachments.filter((attachment) => attachment.fileInfo === null)}
          onAdd={(fileAttachment) => dispatch(addFileAttachment(pupil.id, fileAttachment))}
          onCancel={addFileAttachmentCancel}
          onRemove={(id) => dispatch(removeFileAttachment(pupil.id, id))}
          onCreate={addFileAttachmentStart}
          isEditing={attachmentsUi.isEditing}
          isProcessing={attachmentsUi.isProcessing}
          isInitiallyExpanded={false}
        />
        <GroupEnrollmentHistoryCard
          enrolledToGroupHistory={pupil.enrolledToGroupHistory}
          groups={groups}
          isInitiallyExpanded={false}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" aria-label="Archiwizuj" onClick={archiveChildStartFun}>
            Archiwizuj
          </Button>
        </Box>
        {renderArchiveStudentDialog()}
      </Box>
    </LoadingRenderWrapper>
  );
};

export default StudentDetailsPage;
