import PropTypes from 'prop-types';
import React from 'react';
import { Box, MenuItem } from '@mui/material';
import ConfirmationDialog from '../../forms/ConfirmationDialog';
import normalStaffMemberRoles from '../../../constants/staffMemberRoles';
import reducedStaffMemberRoles from '../../../constants/reducedStaffMemberRoles';
import _ from 'lodash';
import TextField from '../../common/TextField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { connect } from 'react-redux';
import Small from '../../common/Small';
import Strong from '../../common/Strong';
import SelectField from '../../common/SelectField';

class StaffMemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRoleChangeConfirmationDialogOpen: false,
      nextRole: null,
      staffMemberRoles: props.nadarzyn ? reducedStaffMemberRoles : normalStaffMemberRoles
    };
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.getRoleChangeMessage = this.getRoleChangeMessage.bind(this);
    this.rolesAssociatedWithGroups = [
      this.state.staffMemberRoles.principal.value,
      this.state.staffMemberRoles.teacher.value
    ];
  }

  getRoleChangeMessage(role, nextRole) {
    if (!this.props.staffMember.id) {
      return '';
    }
    if (
      this.rolesAssociatedWithGroups.some((x) => x === role) &&
      this.rolesAssociatedWithGroups.every((x) => x !== nextRole)
    ) {
      return `Zmian roli pracownika spowoduje zmianę jego uprawnień w systemie. ${
        _.values(this.state.staffMemberRoles).find((r) => r.value === role).name
      } zostanie odłączony od wszystkich grup do których został przypisany. Czy rola na pewno powinna zostać zmieniona?`;
    }
    return 'Zmian roli pracownika spowoduje zmianę jego uprawnień w systemie. Czy rola na pewno powinna zostać zmieniona?';
  }

  handleRoleChange(nextRole) {
    if (!this.props.staffMember.id) {
      this.props.onChange({ target: { name: 'role', value: nextRole } });
    } else {
      this.setState({
        isRoleChangeConfirmationDialogOpen: true,
        nextRole
      });
    }
  }

  render() {
    const { staffMember, onChange, errors, nadarzyn } = this.props;
    const { error } = errors;
    return (
      <Box sx={{ flexDirection: 'column', display: 'flex' }}>
        <FieldsetWrapper legend="Dane pracownika">
          <TextField
            hintText="Imię"
            floatingLabelText="Imię*"
            floatingLabelFixed={false}
            autocomplete="given-name"
            name="firstName"
            value={staffMember.firstName}
            onChange={onChange}
            errorText={error && error.firstName}
            autoFocus={true}
          />
          <TextField
            hintText="Nazwisko"
            floatingLabelText="Nazwisko*"
            floatingLabelFixed={false}
            autocomplete="family-name"
            name="lastName"
            value={staffMember.lastName}
            onChange={onChange}
            fullWidth={true}
            errorText={error && error.lastName}
          />
          {nadarzyn && (
            <TextField
              hintText="Pesel"
              floatingLabelText="Pesel*"
              floatingLabelFixed={false}
              autocomplete="family-name"
              name="personalId"
              value={staffMember.personalId}
              onChange={onChange}
              fullWidth={true}
              errorText={error && error.personalId}
            />
          )}
          <TextField
            hintText="E-mail posłuży jako login do systemu"
            floatingLabelText="E-mail*"
            floatingLabelFixed={false}
            autocomplete="email"
            name="email"
            value={staffMember.email}
            onChange={onChange}
            fullWidth={true}
            errorText={error && error.email}
          />
          <Small>
            <Strong>Uwaga</Strong> - na podany adres email zostanie wysłany link aktywacyjny
          </Small>
          <SelectField
            contrast
            onChange={(event) => this.handleRoleChange(event.target.value)}
            floatingLabelText="Rola*"
            id="role"
            value={staffMember.role}
            errorText={error && error.role}
            sx={{ my: 1, mx: 0, color: (theme) => theme.palette.color.contrast }}>
            {_.values(this.state.staffMemberRoles).map((role) => {
              return (
                <MenuItem key={role.value} value={role.value}>
                  {role.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </FieldsetWrapper>
        <ConfirmationDialog
          confirmationText={this.getRoleChangeMessage(staffMember.role, this.state.nextRole)}
          confirmationTitle="Zmiana roli"
          onConfirm={() =>
            this.setState({ isRoleChangeConfirmationDialogOpen: false }, () =>
              onChange({ target: { name: 'role', value: this.state.nextRole } })
            )
          }
          onCancel={() => this.setState({ isRoleChangeConfirmationDialogOpen: false })}
          isOpen={this.state.isRoleChangeConfirmationDialogOpen}
        />
      </Box>
    );
  }
}

StaffMemberForm.propTypes = {
  staffMember: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  nadarzyn: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
};

export default connect(mapStateToProps)(StaffMemberForm);
