import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const EmojiCounter = ({ count, emoji }) => <span>{_.range(0, count).map(() => emoji)}</span>;

EmojiCounter.propTypes = {
  count: PropTypes.number.isRequired,
  emoji: PropTypes.string.isRequired
};

export default EmojiCounter;
