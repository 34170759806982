import PropTypes from 'prop-types';
import React from 'react';
import { Clear as ClearIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';

const ClearButtonWrapper = ({
  contrast,
  children,
  onClear,
  disabled = false,
  tooltip = '',
  style,
  sx,
  fullWidth,
  childStyles = undefined
}) => {
  return (
    <Box sx={{ ...style, ...sx, display: 'flex', alignItems: 'center', width: fullWidth ? '100%' : undefined }}>
      <Box sx={{ mb: 2, width: fullWidth ? '100%' : undefined, ...childStyles }}>{children}</Box>

      {!disabled && (
        <IconButton onClick={onClear} aria-label="usuń" disabled={disabled} tooltip={tooltip} size="large">
          <ClearIcon
            sx={{
              color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary)
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

ClearButtonWrapper.propTypes = {
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
  sx: PropTypes.object,
  contrast: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default ClearButtonWrapper;
