import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import { useMemo } from 'react';

export const DietsList = ({ diets }) => {
  const handleDietClick = (dietId) => {
    browserHistory.push(fromTemplate(routePaths.dietDetails, [dietId]));
  };

  const sortedDiets = useMemo(() => {
    return diets.sort((a, b) => a.name.localeCompare(b.name));
  }, [diets]);

  return (
    <List sx={{ p: 2 }}>
      {sortedDiets.map((diet) => {
        return (
          <ListItem key={diet.id} sx={{ p: 0 }}>
            <ListItemButton
              sx={{
                height: '72px',
                cursor: 'pointer',
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}
              onClick={() => {
                handleDietClick(diet.id);
              }}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    background: (theme) => theme.palette.background.color2
                  }}>
                  <DinnerDiningOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={diet.name}
                primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                secondary={`Liczba posiłków: ${diet.meals.length} | Przypisanych dzieci: ${diet.enrolledChildren.length}`}
                secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
