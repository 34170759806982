import VirtualDiscApi from '../api/VirtualDiscApi';
import VirtualDiscFoldersApi from '../api/VirtualDiscFoldersApi';
import * as types from './actionTypes';
import { showError, showSuccess } from './notificationActions';

export function deleteFile(id) {
  return async (dispatch) => {
    try {
      await VirtualDiscApi.deleteFile(id);
      dispatch({ type: types.VIRTUAL_DISC_DELETE_FILE, id });
      dispatch(showSuccess('Pomyślnie usunięto plik'));
    } catch (e) {
      if (e.status === 403) dispatch(showError(`${e.response.text}`));
      else dispatch(showError('Nie udało się usunąć pliku'));
    }
  };
}

export function deleteFiles(ids) {
  return async (dispatch) => {
    try {
      await VirtualDiscApi.deleteFiles(ids);
      dispatch({ type: types.VIRTUAL_DISC_DELETE_MANY_FILES, ids });
      dispatch(showSuccess('Pomyślnie usunięto pliki'));
    } catch (e) {
      if (e.status === 403) dispatch(showError(`${e.response.text}`));
      else dispatch(showError('Nie udało się usunąć plików'));
    }
  };
}

export function fetchFiles() {
  return async (dispatch) => {
    try {
      const files = await VirtualDiscApi.fetchFiles();
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FILES, files });
    } catch (e) {
      dispatch(showError('Nie udało się pobrać plików z dysku wirutalnego'));
    }
  };
}

export function shareFiles(data) {
  return async (dispatch) => {
    try {
      await VirtualDiscApi.shareFiles(data);
      dispatch({ type: types.VIRTUAL_DISC_SHARE_FILES, data });
      dispatch(showSuccess('Pomyślnie zmieniono uprawnienia dla plików'));
    } catch (e) {
      dispatch(
        showError(e.response?.body?.generalError || 'Nie udało się zmienić uprawnień plików w dysku wirtualnym')
      );
    }
  };
}

export function createFolder(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      const res = await VirtualDiscFoldersApi.createFolder(data);
      dispatch({ type: types.VIRTUAL_DISC_CREATE_FOLDER, data: res });
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Utworzono folder'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się utworzyć folderu'));
    }
  };
}

export function deleteFolder(folderId) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.deleteFolder(folderId);
      dispatch({ type: types.VIRTUAL_DISC_DELETE_FOLDER, folderId });
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Usunięto folder'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się usunąć folderu'));
    }
  };
}

export function editFolder(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.editFolder(data);
      dispatch({ type: types.VIRTUAL_DISC_EDIT_FOLDER, data });
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Zedytowano folder'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || `Nie udało się edytować folderu.`));
    }
  };
}
export function moveFolder(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.moveToFolder(data);
      dispatch({ type: types.VIRTUAL_DISC_MOVE_FOLDER, data });
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Przeniesiono folder'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się przenieść folderu'));
    }
  };
}

export function moveToFolder(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.moveToFolder(data);
      dispatch({ type: types.VIRTUAL_DISC_MOVE_TO_FOLDER, data });
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Przeniesiono wybrane elementy do folderu'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się przenieść wybranych elementów do folderu'));
    }
  };
}

export function getFolderContent(folderId) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_SUBMIT });
      const data = await VirtualDiscFoldersApi.getFolderContent(folderId);
      dispatch({ type: types.VIRTUAL_DISC_GET_FOLDER_CONTENT, data: { ...data, folderId } });
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_SUCCESS });
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się pobrać zawartości folderu'));
    }
  };
}

export function getRootFolderContent() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_SUBMIT });
      const res = await VirtualDiscFoldersApi.getRootFolderContent();
      dispatch({ type: types.VIRTUAL_DISC_GET_FOLDER_CONTENT, data: { ...res, folderId: null } });
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_SUCCESS });
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się pobrać zawartości folderu'));
    }
  };
}

export function getFolderMetadata(folderId) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.getFolderMetadata(folderId);
      dispatch({ type: types.VIRTUAL_DISC_GET_FOLDER_METADATA, folderId });
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_SUCCESS });
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_FETCH_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się pobrać danych folderu'));
    }
  };
}

export function shareFolder(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.shareFolder(data);
      dispatch({ type: types.VIRTUAL_DISC_SHARE_FOLDER, data });
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Zmieniono uprawnienia do folderu'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się zmienić uprawnień do folderu'));
    }
  };
}

export function downloadFolder(folderId) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT });
      await VirtualDiscFoldersApi.downloadFolder(folderId);
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS });
      dispatch(showSuccess('Pobrano folder'));
    } catch (e) {
      dispatch({ type: types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE });
      dispatch(showError(e.response?.body?.generalError || 'Nie udało się pobrać folderu'));
    }
  };
}
