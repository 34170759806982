/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function psychologicalGroupProgramUiReducer(state = initialState.psychologicalGroupProgramUi, action) {
  switch (action.type) {
    case types.GROUP_PROGRAM_START: {
      return { ...state, groupProgram: UiModel.start() };
    }
    case types.GROUP_PROGRAM_CANCEL: {
      return { ...state, groupProgram: UiModel.cancel() };
    }
    case types.GROUP_PROGRAM_SUBMIT: {
      return { ...state, groupProgram: UiModel.submit() };
    }
    case types.ADD_GROUP_PROGRAM: {
      return { ...state, groupProgram: UiModel.success() };
    }
    case types.EDIT_GROUP_PROGRAM: {
      return { ...state, groupProgram: UiModel.success() };
    }
    case types.DELETE_GROUP_PROGRAM: {
      return { ...state, groupProgram: UiModel.success() };
    }
    case types.GROUP_PROGRAM_FAILURE: {
      return { ...state, groupProgram: UiModel.failure(action.errors) };
    }
    //classes
    case types.GROUP_CLASSES_START: {
      return { ...state, groupClasses: UiModel.start() };
    }
    case types.GROUP_CLASSES_CANCEL: {
      return { ...state, groupClasses: UiModel.cancel() };
    }
    case types.GROUP_CLASSES_SUBMIT: {
      return { ...state, groupClasses: UiModel.submit() };
    }
    case types.ADD_GROUP_CLASSES: {
      return { ...state, groupClasses: UiModel.success() };
    }
    case types.EDIT_GROUP_CLASSES: {
      return { ...state, groupClasses: UiModel.success() };
    }
    case types.DELETE_GROUP_CLASSES: {
      return { ...state, groupClasses: UiModel.success() };
    }
    case types.GROUP_CLASSES_FAILURE: {
      return { ...state, groupClasses: UiModel.failure(action.errors) };
    }
    //groupDiary
    case types.GROUP_DIARY_START: {
      return { ...state, groupDiary: UiModel.start() };
    }
    case types.GROUP_DIARY_CANCEL: {
      return { ...state, groupDiary: UiModel.cancel() };
    }
    case types.GROUP_DIARY_SUBMIT: {
      return { ...state, groupDiary: UiModel.submit() };
    }
    case types.ADD_GROUP_DIARY: {
      return { ...state, groupDiary: UiModel.success() };
    }
    case types.EDIT_GROUP_DIARY: {
      return { ...state, groupDiary: UiModel.success() };
    }
    case types.DELETE_GROUP_DIARY: {
      return { ...state, groupDiary: UiModel.success() };
    }
    case types.GROUP_DIARY_FAILURE: {
      return { ...state, groupDiary: UiModel.failure(action.errors) };
    }

    default:
      return state;
  }
}
