/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { GroupAttendanceSummaryModel } from '../models/dashboard/StaffDashboardModels';

export default function attendanceSummaryReducer(state = initialState.staffDashboard.attendanceSummary, action) {
  switch (action.type) {
    case types.LOAD_ATTENDANCE_SUMMARY_SUCCESS: {
      return {
        present: action.attendanceSummary.present,
        enrolled: action.attendanceSummary.enrolled,
        absentTomorrow: action.attendanceSummary.absentTomorrow,
        groups: action.attendanceSummary.groups.map((groupSummary) =>
          new GroupAttendanceSummaryModel().assign(groupSummary)
        )
      };
    }
    default:
      return state;
  }
}
