import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const EditInterestsDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.value || 0);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await props.onSave(value);
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Edit />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Zmień odsetki</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>Podaj nową wartość odsetek.</DialogContentText>
          <TextField
            variant="standard"
            type="number"
            label="Odsetki"
            autoFocus
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">zł</InputAdornment>
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Anuluj
          </Button>
          <Button disabled={isLoading} onClick={handleSave}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditInterestsDialog.propTypes = {
  value: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired
};

export default EditInterestsDialog;
