import React, { useState } from 'react';
import IconButtonPipe from '../../../common/IconButtonPipe';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFolder, downloadFolder, editFolder, getFolderContent } from '../../../../actions/virtualDiscActions';
import CreateOrEditFolderDialog from '../Dialogs/CreateOrEditFolderDialog';
import ShareIcon from '@mui/icons-material/Share';
import ConfirmationDialog from '../../../forms/ConfirmationDialog';
import userRoles from '../../../../constants/userRoles';
import ShareFileDialog from '../../containers/Dialogs/ShareFileDialog';

export default function FolderMenu({ item, isTableView = false, handleOpenMoveToFolderDialog, cancelSelectedFiles }) {
  const { name, color, id } = item;
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth.userRole);
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.action);
  const isManagement = userRole !== userRoles.legalGuardian && userRole !== userRoles.staffMemberCanteenWorker;
  const hasSuperPermission = userRole === userRoles.staffMemberPrincipal;
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemoveFolder = () => {
    dispatch(deleteFolder(id));
  };
  const handleOpenEdit = () => {
    setIsEditOpen(true);
  };
  const handleCloseEdit = () => {
    setIsEditOpen(false);
    handleClose();
  };
  const handleSubmitEdit = async (values) => {
    await dispatch(editFolder({ ...values, id }));
    handleCloseEdit();
  };

  const handleOpenFolder = () => {
    cancelSelectedFiles();
    dispatch(getFolderContent(id));
  };
  const handleOpenShareDialog = () => {
    setIsShareOpen(true);
  };
  const handleDownloadFolder = () => {
    dispatch(downloadFolder(id));
  };

  return (
    <>
      {isEditOpen && (
        <CreateOrEditFolderDialog
          open={isEditOpen}
          onClose={handleCloseEdit}
          onSubmit={handleSubmitEdit}
          inProgress={isProcessing}
          initialValues={{ name, color }}
        />
      )}
      {isDeleteOpen && (
        <ConfirmationDialog
          isOpen={isDeleteOpen}
          confirmationText="Jest to akcja nieodwracalna. Usunięte zostanę wszystkie pliki oraz podfoldery, które znajdują się w folderze."
          confirmationTitle="Czy na pewno chcesz usunąć folder?"
          isSecondary={true}
          inProgress={isProcessing}
          confirmLabel="Usuń"
          onConfirm={handleRemoveFolder}
          onCancel={() => setIsDeleteOpen(false)}
        />
      )}

      {isShareOpen && (
        <ShareFileDialog
          isFolder
          isDialogOpen={isShareOpen}
          onClose={() => {
            setIsShareOpen(false);
          }}
          onSubmit={() => {
            setIsShareOpen(false);
          }}
          selectedFiles={[item]}
          isSingleShare
          isMobile={isMobile}
          hasSuperPermission={hasSuperPermission}
        />
      )}
      <IconButtonPipe
        tooltip={`Akcję dla folderu ${name}`}
        aria-label={`Akcję dla folderu ${name}`}
        aria-controls={open ? 'folder-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVertIcon sx={{ color: !isTableView ? (theme) => theme.palette.color.color22 : undefined }} />
      </IconButtonPipe>
      <Menu
        id="folder-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'folder-menu'
        }}>
        {isTableView && (
          <MenuItem key={0} onClick={handleOpenFolder}>
            <FolderOpenIcon sx={{ mr: 3 }} />
            Otwórz folder
          </MenuItem>
        )}
        {isManagement && [
          <MenuItem key={1} onClick={handleOpenEdit}>
            <EditIcon sx={{ mr: 3 }} />
            Edytuj
          </MenuItem>,
          <MenuItem key={2} onClick={() => handleOpenMoveToFolderDialog([item])}>
            <DriveFileMoveIcon sx={{ mr: 3 }} />
            Przenieś do folderu
          </MenuItem>,
          <MenuItem key={3} onClick={handleOpenShareDialog}>
            <ShareIcon sx={{ mr: 3 }} /> Udostępnij
          </MenuItem>,
          // <MenuItem key={3} onClick={handleOpenShareDialog}>
          //   <FileDownloadOutlinedIcon sx={{ mr: 3 }} /> Pobierz
          // </MenuItem>,
          <MenuItem
            key={4}
            onClick={() => {
              setIsDeleteOpen(true);
            }}>
            <DeleteForeverIcon sx={{ mr: 3 }} /> Usuń
          </MenuItem>
        ]}
      </Menu>
    </>
  );
}
