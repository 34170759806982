import initialState from './initial';
import * as types from '../actions/actionTypes';
import * as notificationTypes from '../constants/notificationTypes';
import { NotificationModel } from '../models/notifications/NotificationModel';

export default function notificationsReducer(state = initialState.notifications, action) {
  switch (action.type) {
    case types.SHOW_ERROR_NOTIFICATION: {
      return [
        ...state,
        new NotificationModel().assign({ message: action.message, type: notificationTypes.unhandledError })
      ];
    }
    case types.SHOW_SUCCESS_NOTIFICATION: {
      return [...state, new NotificationModel().assign({ message: action.message, type: notificationTypes.success })];
    }
    case types.SHOW_WARNING_NOTIFICATION: {
      return [...state, new NotificationModel().assign({ message: action.message, type: notificationTypes.warning })];
    }
    case types.CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
}
