import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../forms/DialogForm';
import Checkbox from '../common/Checkbox';
import FormGroup from '@mui/material/FormGroup';

class ArchiveGroupDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archiveChildren: false,
      archiveLegalGuardians: false,
      archiveBoardPosts: false
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(property, value) {
    if (property === 'archiveChildren') this.setState({ archiveChildren: value, archiveLegalGuardians: false });
    if (property === 'archiveLegalGuardians')
      this.setState({ archiveLegalGuardians: this.state.archiveChildren && value });
    if (property === 'archiveBoardPosts') this.setState({ archiveBoardPosts: value });
  }

  render() {
    const { onArchive, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header={`Archiwizowanie grupy - ${this.props.group.name}`}
        onSave={() =>
          onArchive(this.state.archiveChildren, this.state.archiveLegalGuardians, this.state.archiveBoardPosts)
        }
        onCancel={onCancel}
        saveLabel="Archiwizuj"
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        noValidate>
        <FormGroup row sx={{ display: 'flex', flexDirection: 'column' }}>
          <Checkbox
            label="Archiwizuj dzieci przypisane do grupy"
            defaultChecked={this.state.archiveChildren}
            onCheck={(event, isChecked) => this.handleChanged('archiveChildren', isChecked)}
          />
          <Checkbox
            label="Archiwizuj opiekunów dzieci"
            defaultChecked={this.state.archiveLegalGuardians}
            onCheck={(event, isChecked) => this.handleChanged('archiveLegalGuardians', isChecked)}
            disabled={!this.state.archiveChildren}
          />
          <Checkbox
            label="Archiwizuj ogłoszenia opublikowane wyłącznie dla tej grupy"
            defaultChecked={this.state.archiveBoardPosts}
            onCheck={(event, isChecked) => this.handleChanged('archiveBoardPosts', isChecked)}
          />
        </FormGroup>
      </DialogForm>
    );
  }
}

ArchiveGroupDialog.propTypes = {
  onArchive: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired
};

export default ArchiveGroupDialog;
