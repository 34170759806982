import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import { GoalModel, TopicModel } from './WorkPlanModel';

export class WorkPlanValidationGoalModel extends BaseModelValidator {
  constructor() {
    super();
    this.model = GoalModel;

    this.constraints = {
      goalName: (value) => check.isEmpty(value) && 'Nie podano nazwy'
    };
  }
}

export class WorkPlanValidationTopicModel extends BaseModelValidator {
  constructor() {
    super();
    this.model = TopicModel;

    this.constraints = {
      topicName: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      todo: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      books: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      comments: (value) => check.isEmpty(value) && 'Nie podano nazwy'
    };
  }
}
