import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Typography, IconButton, Box, useMediaQuery } from '@mui/material';
import { Menu } from '@mui/icons-material';
import NavigationAvatar from './NavigationAvatar';

const NavigationBar = ({ title, onMenuButtonClick, userFirstName, userLastName, profilePhoto, containerWidth }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
        width: '100%',
        background: 'transparent',
        boxShadow: 'none'
      }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '64px' }}>
        <IconButton
          sx={{
            display: containerWidth <= 1150 ? 'block' : 'none',
            color: 'inherit'
          }}
          onClick={onMenuButtonClick}
          aria-label="menu"
          size="large">
          <Menu />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          <Typography
            component="h1"
            sx={{
              ml: 5,
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-around',
              font: 'normal normal 14px League Spartan',
              letterSpacing: '0.12px'
            }}>
            {!isMobile && title}
          </Typography>
        </Box>
        <NavigationAvatar userFirstName={userFirstName} userLastName={userLastName} profilePhoto={profilePhoto} />
      </Toolbar>
    </AppBar>
  );
};

NavigationBar.propTypes = {
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  profilePhoto: PropTypes.string,
  title: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
  containerWidth: PropTypes.number
};

function mapStateToProps(state) {
  const fullNameSplitted = state.auth.userFullName.split(' ');
  return {
    userFirstName: fullNameSplitted[0],
    userLastName: fullNameSplitted[1],
    profilePhoto: state.auth.profilePhoto
  };
}

export default connect(mapStateToProps)(NavigationBar);
