import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as psychologicalGroupActions from '../../actions/psychologicalChoosenGroupActions';
import GroupSwitch from '../common/groups/GroupSwitch';
import ContentWrapper from '../common/contentWrapper';
import { Box } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import AccordeonElement from './AccordeaoElement';
import PsychologicalChildList from './PsychologicalChildList ';

import Groups2Icon from '@mui/icons-material/Groups2';
import GroupProgram from './GroupProgram';
import ClassesCalendar from '../individualClassesDetails/ClassesCalendar';
import {
  addGroupClasses,
  addGroupDiary,
  loadGroupClassesWorkPlan,
  loadGroupDiaryCalendar,
  loadPsychologicalGroupAttendance,
  setClassesDialogClosed,
  setClassesDialogOpen,
  setDiaryDialogClosed,
  setDiaryDialogOpen
} from '../../actions/psychologicalGroupClassesActions';
import { routePaths } from '../../routePaths';
import EmptyState from '../common/EmptyState';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';

const PsychologicalGroupClassesPage = () => {
  const dispatch = useDispatch();
  const psychologicalChoosenGroup = useSelector((state) => state.psychologicalChoosenGroup);
  const ui = useSelector((state) => state.psychologicalGroupProgramUi);
  const posts = useSelector((state) => state.psychologicalGroupWorkProgram.posts);
  const diary = useSelector((state) => state.psychologicalGroupWorkProgram.diary);
  const attendance = useSelector((state) => state.psychologicalGroupClassesAttendance);
  const schemesArray = useSelector((state) => state.psychologicalSchemes);
  const [classesDate, setClassesDate] = useState(moment());
  const [diaryDate, setDiaryDate] = useState(moment());
  useEffect(() => {
    dispatch(loadGroupClassesWorkPlan(moment(classesDate).format('YYYY-MM'), psychologicalChoosenGroup.id));
  }, [classesDate, dispatch, psychologicalChoosenGroup.id]);

  useEffect(() => {
    dispatch(loadGroupDiaryCalendar(moment(diaryDate).format('YYYY-MM'), psychologicalChoosenGroup.id));
  }, [diaryDate, dispatch, psychologicalChoosenGroup.id]);

  useEffect(() => {
    if (psychologicalChoosenGroup.id) dispatch(loadPsychologicalGroupAttendance(psychologicalChoosenGroup.id));
  }, [dispatch, psychologicalChoosenGroup.id]);

  const handleClassDateChange = (value) => {
    setClassesDate(value);
  };
  const handleDiaryDateChange = (value) => {
    setDiaryDate(value);
  };

  const handleGroupChange = (value) => {
    dispatch(psychologicalGroupActions.changePsychologicalGroup(value));
    dispatch(psychologicalGroupActions.loadPsychologicalGroupWorkPlan(value));
    dispatch(loadPsychologicalGroupAttendance(value.id));
  };
  const handleClassesOpen = () => {
    dispatch(setClassesDialogOpen());
  };
  const handleClassesCancel = () => {
    dispatch(setClassesDialogClosed());
  };
  const handleDiaryOpen = () => {
    dispatch(setDiaryDialogOpen());
  };
  const handleDiaryCancel = () => {
    dispatch(setDiaryDialogClosed());
  };
  const handleSave = (event) => {
    let data = event;
    data.group = psychologicalChoosenGroup.id;
    data.eventType = 'psychologicalPedagogicalGroupClassesTimetable';
    dispatch(addGroupClasses(data));
  };
  const handleDiarySave = (event) => {
    let data = event;
    data.group = psychologicalChoosenGroup.id;
    data.eventType = 'psychologicalPedagogicalGroupClassesActivities';
    dispatch(addGroupDiary(data));
  };
  return (
    <Box>
      <PageHeaderText
        title="Dziennik zajęć grupowych"
        titleIcon={<Groups2Icon />}
        additionalInfo={moment().format('DD.MM.YYYY')}
      />
      <ContentWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <GroupSwitch onGroupChange={handleGroupChange} showArchivedGroups={false} isPsychological />
        </Box>
        {!!psychologicalChoosenGroup.id ? (
          <>
            <AccordeonElement
              title="Lista Dzieci"
              content={
                <PsychologicalChildList entries={psychologicalChoosenGroup?.enrolledChildren} attendance={attendance} />
              }
            />
            <AccordeonElement
              title="Rozkład zajęć"
              content={
                <ClassesCalendar
                  helpId={psychologicalChoosenGroup.id}
                  handleDateChange={handleClassDateChange}
                  sx={{ my: 0, pt: 0 }}
                  lessons={posts}
                  onSave={(e) => handleSave(e)}
                  routePath={routePaths.groupClassesEventsDetails}
                  schemesArray={schemesArray.groupSchedules}
                  elevation={0}
                  ui={ui.groupClasses}
                  onOpen={handleClassesOpen}
                  onCancel={handleClassesCancel}
                />
              }
            />
            <AccordeonElement title="Program pracy grupy" content={<GroupProgram />} />

            <AccordeonElement
              title="Dziennik zajęć grupowych"
              content={
                <ClassesCalendar
                  helpId={psychologicalChoosenGroup.id}
                  handleDateChange={handleDiaryDateChange}
                  sx={{ my: 0, pt: 0 }}
                  lessons={diary}
                  onSave={(e) => handleDiarySave(e)}
                  routePath={routePaths.groupDiaryEventsDetails}
                  elevation={0}
                  ui={ui.groupDiary}
                  onOpen={handleDiaryOpen}
                  onCancel={handleDiaryCancel}
                />
              }
            />
          </>
        ) : (
          <EmptyState message="Nie wybrano grupy" isPaper contrast />
        )}
      </ContentWrapper>
    </Box>
  );
};
export default PsychologicalGroupClassesPage;
