export const ChargingSchemeTypes = Object.freeze({
  hourly: {
    value: 'hourly',
    name: 'godzinowe',
    unit: 'zł/godzinę',
    description: 'Schemat nalicza opłatę za czas pobytu w placówce.'
  },
  daily: {
    value: 'daily',
    name: 'dzienne',
    unit: 'zł/dzień',
    description:
      'Schemat umożliwia naliczanie opłaty za cały dzień. Opłata jest naliczana gdy dziecko pojawiło się w danym dniu w placówce.'
  },
  monthly: {
    value: 'monthly',
    name: 'miesięczne',
    unit: 'zł/miesiąc',
    description:
      "Schemat umożliwia wprowadzenia opłaty za cały miesiąc - zawsze będzie to ta sama kwota. Kwota może zostać pomniejszona o 'odjęcie' naliczone na podstawie dni nieobecności"
  }
});
