const config = {
  apiUrl: 'https://prodapi.4parents.pl',
  staffManualUrl: 'https://4parentsprod.blob.core.windows.net/assets/instrukcja_obslugi_4parents.pdf',
  staffManualReducedUrl: 'https://4parentsprod.blob.core.windows.net/assets/instrukcja_obslugi_4students.pdf',
  guardianManualUrl: 'https://4parentsprod.blob.core.windows.net/assets/instrukcja_obslugi_opiekunowie_4parents.pdf',
  pz_login: 'https://e-spdp.pl/logowanieSAML',
  insuranceUrl: 'https://bezpieczny.pl/'
};

module.exports = config;
