import PropTypes from 'prop-types';
import React from 'react';
import { ReportAbsenceValidator } from '../../../models/attendance/AttendanceModelsValidator';
import moment from 'moment';
import DialogForm from '../../forms/DialogForm';
import DatePicker from '../../common/DatePicker';
import PersonAvatar from '../../forms/PersonAvatar';
import ExceededAbsenceReportDeadlineWarning from './ExceededAbsenceReportDeadlineWarning';
import TextField from '../../common/TextField';
import { Box, Typography } from '@mui/material';

class ReportAbsenceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      absenceFrom: this.props.absenceFrom,
      absenceTo: this.props.absenceTo,
      absenceNotes: props.absenceNotes,
      absenceReportedAt: moment(),
      errors: {}
    };
    this.handleDateFromChange = this.handleDateFromChange.bind(this);
    this.handleDateToChange = this.handleDateToChange.bind(this);
  }

  handleDateFromChange(absenceFrom) {
    if (absenceFrom > this.state.absenceTo) this.setState({ absenceFrom, absenceTo: absenceFrom });
    else this.setState({ absenceFrom });
  }

  handleDateToChange(absenceTo) {
    this.setState({ absenceTo });
  }

  handleNoteChange(absenceNotes) {
    this.setState({ absenceNotes });
  }

  render() {
    const {
      pupilFirstName,
      pupilLastName,
      pupilPhotoUrl,
      isAbsenceToBlocked,
      isAbsenceFromBlocked,
      onSave,
      onCancel,
      isDialogOpen,
      isProcessing,
      statePathToUi,
      minDate
    } = this.props;
    return (
      <DialogForm
        maxWidth="xs"
        header="Nieobecność"
        onSave={() => onSave(this.state)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new ReportAbsenceValidator().validate(this.state)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi={statePathToUi || 'attendanceUi.absence'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <ExceededAbsenceReportDeadlineWarning
            absenceFrom={this.state.absenceFrom}
            absenceReportedAt={this.state.absenceReportedAt}
            schemes={this.props.pupilContract ? this.props.pupilContract.schemes : []}
            force={false}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: 2
            }}>
            <PersonAvatar
              initials
              alt={pupilFirstName}
              url={pupilPhotoUrl}
              firstName={pupilFirstName}
              lastName={pupilLastName}
            />
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mx: 2 }}>
              {`${pupilFirstName} ${pupilLastName}`}
            </Typography>
          </Box>
          <Box>
            <DatePicker
              floatingLabelText="Od"
              minDate={minDate}
              maxDate={moment().add(3, 'M')}
              value={this.state.absenceFrom}
              onChange={(e) => this.handleDateFromChange(e)}
              disabled={isAbsenceFromBlocked}
              name="from"
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <DatePicker
              floatingLabelText="Do"
              minDate={minDate}
              maxDate={moment().add(3, 'M')}
              value={this.state.absenceTo}
              onChange={(e) => this.handleDateToChange(e)}
              disabled={isAbsenceToBlocked}
              name="to"
            />
          </Box>
          <TextField
            sx={{ width: 205, mr: 1 }}
            hintText="Notatka"
            floatingLabelText="Notatka"
            floatingLabelFixed={false}
            name="absenceNotes"
            value={this.state.absenceNotes}
            onChange={(e) => this.handleNoteChange(e.target.value)}
          />
        </Box>
      </DialogForm>
    );
  }
}

ReportAbsenceDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  absenceFrom: PropTypes.object.isRequired,
  absenceTo: PropTypes.object.isRequired,
  absenceNotes: PropTypes.string.isRequired,
  pupilFirstName: PropTypes.string.isRequired,
  pupilLastName: PropTypes.string.isRequired,
  pupilContract: PropTypes.object,
  isAbsenceToBlocked: PropTypes.bool,
  isAbsenceFromBlocked: PropTypes.bool,
  pupilPhotoUrl: PropTypes.string,
  statePathToUi: PropTypes.string,
  minDate: PropTypes.object
};

ReportAbsenceDialog.defaultProps = {
  isAbsenceToBlocked: false,
  isAbsenceFromBlocked: true,
  pupilDeadlines: []
};

export default ReportAbsenceDialog;
