import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as groupActions from '../../actions/groupsActions';
import GroupDetailsCard from './details/GroupDetailsCard';
import { GroupDetailsModel } from '../../models/groups/GroupModels';
import { GroupDetailsModelValidator } from '../../models/groups/GroupModelsValidator';
import { create } from '../../utils/AvatarContentGenerator';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import { Box } from '@mui/material';

class GroupRegisterPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      group: Object.assign({}, new GroupDetailsModel())
    };
    this.onUpdateGroupState = this.onUpdateGroupState.bind(this);
    this.onSaveGroup = this.onSaveGroup.bind(this);
    this.onCancelGroup = this.onCancelGroup.bind(this);
    this.onEditGroup = this.onEditGroup.bind(this);
  }

  componentDidMount() {
    this.onEditGroup();
  }

  onUpdateGroupState(event) {
    const field = event.target.name;
    const { group } = this.state;
    group[field] = event.target.value;
    return this.setState({ group });
  }

  onSaveGroup() {
    let { group } = this.state;
    if (!group.color) {
      group = Object.assign({}, group, { color: create().generateColorFromName(group.name) });
    }
    this.props.actions.createGroupDetailsAsync(group);
  }

  onEditGroup() {
    this.props.actions.createGroupDetailsStart();
  }

  onCancelGroup() {
    this.props.actions.createGroupDetailsCancel();
    browserHistory.push(routePaths.groups);
  }

  render() {
    return (
      <Box>
        <GroupDetailsCard
          group={this.state.group}
          onChange={this.onUpdateGroupState}
          onSave={this.onSaveGroup}
          onCancel={this.onCancelGroup}
          onEdit={this.onEditGroup}
          onValidate={() => new GroupDetailsModelValidator().validate(this.state.group)}
          isEditing={this.props.ui.isEditing}
          isProcessing={this.props.ui.isProcessing}
          title="Nowa grupa"
        />
      </Box>
    );
  }
}

GroupRegisterPage.propTypes = {
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    ui: state.groupDetailsUi.details
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupRegisterPage);
