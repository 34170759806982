import PropTypes from 'prop-types';
import React from 'react';
import normalUserRoleNames from '../../../constants/userRoleNames';
import reducedUserRoleNames from '../../../constants/reducedUserRoleNames';
import staffMemberRoles from '../../../constants/staffMemberRoles';
import { getConstantByValue } from '../../../utils/getConstantByValue';
import KidsList from '../components/KidsList';
import { FilterByRoleType } from '../../common/filters/filterByRole/FilterByRole';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, Typography } from '@mui/material';
import ThreadMultiHeader from '../ThreadView/ThreadMultiHeader';
import PersonAvatar from '../../forms/PersonAvatar';
import ParticipantsList from '../components/ParticipantsList';

const MessageHeader = ({ thread, selectUser, display, style, sx, manualLink, allMessages }) => {
  const users = selectUser(thread);
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const userRoleNames = nadarzyn ? reducedUserRoleNames : normalUserRoleNames;
  const align = display === 'left' ? 'justify-start flex-row' : 'justify-start flex-row-reverse';
  const descriptionAlign = display === 'left' ? 'align-items-start' : 'align-items-end';
  const textAlign = display === 'left' ? 'text-align-start' : 'text-align-end';
  const getRoleObject = (user) => {
    const res = user?.role
      ? getConstantByValue(userRoleNames, user.role) || getConstantByValue(staffMemberRoles, user.role) || { name: '' }
      : { name: '' };
    return res;
  };

  return (
    <>
      <Grid
        container
        className={align}
        sx={{
          ...style,
          ...sx,
          mt: 1,
          px: 1
        }}>
        <Grid item xs={10}>
          <Box sx={{ display: 'flex' }}>
            {allMessages || thread.participants.length > 2 ? (
              <ThreadMultiHeader
                isBold
                thread={thread}
                sx={{ color: (theme) => theme.palette.color.primary }}
                showRoles
                groupThread={thread.participants.length > 2}
              />
            ) : (
              <>
                <PersonAvatar
                  initials
                  alt={users[0]?.fullName}
                  url={users[0]?.photoUrl}
                  fullName={users[0]?.fullName}
                  sx={{ mr: 1 }}
                />
                <Box className={descriptionAlign} sx={{ flexDirection: 'column', alignContent: 'center' }}>
                  <Typography
                    variant="h3"
                    component="h2"
                    sx={{ fontSize: '1.5em', marginBlock: '0.3em', color: (theme) => theme.palette.text.primary }}
                    className={textAlign}>
                    {users[0]?.fullName}
                  </Typography>
                  <Typography sx={{ color: (theme) => theme.palette.text.primary, opacity: 0.5 }}>
                    {getRoleObject(users[0]).name}
                  </Typography>
                </Box>
              </>
            )}
            {manualLink && manualLink}
          </Box>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          {thread.personType === FilterByRoleType.LEGAL_GUARDIAN &&
            thread.children &&
            thread.children.length > 0 &&
            thread.participants.length <= 2 && <KidsList placement="top-end" childrenArray={thread.children} />}
          {thread.participants.length > 2 && (
            <ParticipantsList participants={thread.participants} getUserRole={getRoleObject} />
          )}
        </Grid>
      </Grid>
      <Divider light />
    </>
  );
};

MessageHeader.propTypes = {
  thread: PropTypes.object.isRequired,
  selectUser: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  style: PropTypes.object,
  sx: PropTypes.object
};

export default MessageHeader;
