import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import EmptyState from '../../common/EmptyState';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import ChildDiagnoseDialog from './dialog/ChildDiagnosesDialog';
import ChildDiagnosesCardList from './ChildDiagnosesCardList';
import { DiagnoseModel } from '../../../models/children/ChildModels';
import { DiagnoseModelValidator } from '../../../models/children/ChildModelsValidator';
import ActionButton from '../../forms/buttons/ActionButton';
import userRoles from '../../../constants/userRoles';
import { Box } from '@mui/material';

const ChildDiagnosesCard = (props) => {
  const [childDiagnose, setChildDiagnose] = useState(null);
  const [errors, setErrors] = useState({});
  const [chosenDiagnosticTemplate, setChosenDiagnosticTemplate] = useState('');

  const handleCreate = () => {
    setChosenDiagnosticTemplate('');
    setChildDiagnose(new DiagnoseModel().assign({ createdBy: props.auth.userFullName }));
    props.onAdd();
  };

  const handleSave = () => {
    if (childDiagnose.id) {
      props.onUpdate(childDiagnose);
    } else props.onSave(childDiagnose);
  };

  const handleSelectDiagnose = (chosenChildDiagnosticScheme) => {
    setChildDiagnose(chosenChildDiagnosticScheme);
    props.onAdd();
  };

  const handleDiagnoseChange = (newChildDiagnose) => {
    setChildDiagnose(newChildDiagnose);
  };

  const handleChosenDiagnosticTemplate = (event) => {
    const newObject = { ...childDiagnose };
    newObject.content = props.diagnosticSchemesArray.find((element) => element.id === event.target.value).content;
    setChosenDiagnosticTemplate(event.target.value);
    setChildDiagnose(newObject);
  };

  const renderReadonlyForm = () => {
    return (
      <ChildDiagnosesCardList
        diagnoses={props.diagnoses}
        onSelected={(chosenChildDiagnose) => handleSelectDiagnose(chosenChildDiagnose)}
        onRemove={(chosenChildDiagnosticScheme) => props.onRemove(chosenChildDiagnosticScheme)}
      />
    );
  };

  const renderEditForm = () => {
    return (
      <ChildDiagnoseDialog
        diagnosticSchemesArray={props.diagnosticSchemesArray}
        errors={errors}
        childDiagnose={childDiagnose}
        handleDiagnoseChange={handleDiagnoseChange}
        chosenDiagnosticTemplate={chosenDiagnosticTemplate}
        handleChosenDiagnosticTemplate={handleChosenDiagnosticTemplate}
      />
    );
  };

  return (
    <Box>
      <CardDialogEditForm
        title="Diagnozy"
        isInitiallyExpanded={props.isInitiallyExpanded}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        onAction={() => handleCreate()}
        onCancel={() => props.onCancel()}
        onValidate={() => new DiagnoseModelValidator().validate(childDiagnose)}
        onValidationDone={(err) => setErrors(err)}
        onSave={handleSave}
        actionLabel="Dodaj diagnozę"
        statePathToUi="childDetailsUi.diagnoses"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Nie dołączono żadnych schematów diagnoz" contrast />}
        editForm={renderEditForm()}
        isEmpty={props.diagnoses.length < 1}
        customActions={
          props.auth.isStaffMember(userRoles.staffMemberPrincipal) ? (
            <ActionButton onAction={props.onGeneratePdf} actionLabel="Wygeneruj pdf" />
          ) : null
        }
        requiredFieldText
      />
    </Box>
  );
};

ChildDiagnosesCard.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onGeneratePdf: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  diagnosticSchemesArray: PropTypes.array.isRequired,
  diagnoses: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    diagnosticSchemesArray: state.configuration.diagnosticSchemesSets
  };
}

export default connect(mapStateToProps)(ChildDiagnosesCard);
