import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import locale from 'moment/locale/pl';
import PostRecipients from './PostRecipients';
import userRoles from '../../../constants/userRoles';
import * as childrenAction from '../../../actions/childrenActions';
import * as staffMembersAction from '../../../actions/staffMembersActions';
import StaffBoardPostCardDialog from './StaffBoardPostCardDialog';
import { MenuItem, Card, CardHeader, CardContent, Box, Alert } from '@mui/material';
import DotsMenu from '../../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import PostHeader from './PostHeader';
import Files from '../dialog/Files';
import BoardPostCardDialog from './BoardPostCardDialog';
import PhotoGallery from '../dialog/PhotoGallery';
import { loadPostSeenBy } from '../../../actions/messageBoardActions';
import boardpostbackground from '../../../assets/svg/blops.svg.svg';

moment.locale('pl', locale);

const BoardPostCard = ({ post, canEdit, onEdit, onDelete, dontDisplayHistory, isStaffMemberBoard }) => {
  const dispatch = useDispatch();
  const [seenBy, setSeenBy] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const unreadedPosts = useSelector((state) => state.staffMessageBoard.unreadedPosts);

  const staffMembers = useSelector((state) => state.staffMembers);
  const legalGuardians = useSelector((state) => state.legalGuardians);

  const group = useMemo(() => {
    return isStaffMemberBoard ? staffMembers : legalGuardians;
  }, [isStaffMemberBoard, legalGuardians, staffMembers]);

  const displayHistory = isStaffMemberBoard
    ? !dontDisplayHistory && auth.userRole === userRoles.staffMemberPrincipal
    : !dontDisplayHistory &&
      (auth.userRole === userRoles.staffMemberPrincipal ||
        auth.userRole === userRoles.staffMemberTeacher ||
        auth.userRole === userRoles.staffMemberAccountant ||
        auth.userRole === userRoles.staffMemberSecretary);
  const isLegalGuardian = auth.userRole === userRoles.legalGuardian;
  const loadSeenBy = async (id) => {
    setIsLoading(true);
    setSeenBy(await dispatch(loadPostSeenBy(id, isStaffMemberBoard)));
    if (isStaffMemberBoard) dispatch(staffMembersAction.loadStaffMembersAsync());
    else if (auth.userRole !== userRoles.legalGuardian) dispatch(childrenAction.loadAllLegalGuardiansAsync());
    setIsLoading(false);
  };

  const handleSetIsDialogOpen = () => {
    setIsDialogOpen((prev) => !prev);
  };

  const isPostUnread = isStaffMemberBoard && unreadedPosts && unreadedPosts.some((value) => value === post.id);
  const selectedTheme = useSelector((state) => state.theme);
  return (
    <Card
      sx={{
        mb: 3,
        overflow: 'hidden',
        borderRadius: 2,
        background: selectedTheme === 'contrast' ? null : `url(${boardpostbackground})`,
        backgroundSize: '300px',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (theme) => theme.palette.color.color26,
        p: 2
      }}>
      <CardHeader
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          px: 1,
          pb: 0
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexWrap: 'wrap'
            }}>
            <PostHeader post={post} isPargraph sx={{ pb: 0 }} />
            {isPostUnread && (
              <Alert severity="success" color="error" sx={{ height: 48 }}>
                Nieprzeczytane
              </Alert>
            )}
          </Box>
        }
        action={
          (canEdit || displayHistory) && (
            <DotsMenu>
              {(onClose) => [
                displayHistory ? (
                  <MenuItem
                    key="viewHistory"
                    onClick={() => {
                      loadSeenBy(post.id);
                      onClose();
                      handleSetIsDialogOpen(true);
                    }}>
                    Wyświetlenia
                  </MenuItem>
                ) : null,
                canEdit ? (
                  <MenuItem
                    key="edit"
                    onClick={() => {
                      onClose();
                      onEdit();
                    }}>
                    Edytuj
                  </MenuItem>
                ) : null,
                canEdit ? (
                  <ActionConfirmationMenuItem
                    key="delete"
                    onItemClick={() => onClose()}
                    actionLabel="Usuń"
                    confirmationText="Czy na pewno chcesz usunąć wpis?"
                    confirmationTitle="Usuń wpis"
                    confirmLabel="Usuń"
                    onConfirm={onDelete}
                    onCancel={() => {}}
                  />
                ) : null
              ]}
            </DotsMenu>
          )
        }
      />
      {isStaffMemberBoard ? (
        <StaffBoardPostCardDialog
          isLoading={isLoading}
          seenBy={seenBy}
          setDialogState={handleSetIsDialogOpen}
          dialogState={isDialogOpen}
          staffMembers={group}
          post={post}
        />
      ) : (
        <BoardPostCardDialog
          isLoading={isLoading}
          seenBy={seenBy}
          setDialogState={handleSetIsDialogOpen}
          dialogState={isDialogOpen}
          legalGuardians={group}
          post={post}
        />
      )}
      <CardContent
        sx={{
          pt: 1,
          px: 1,
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          '& img': {
            maxWidth: '100%'
          }
        }}
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
      {post.files.length ? (
        <CardContent sx={{ p: 1 }}>
          <PhotoGallery photos={post.files.filter((f) => f.type === 'image')} />
          <Files files={post.files.filter((f) => f.type !== 'image')} />
        </CardContent>
      ) : null}
      {!isStaffMemberBoard && !isLegalGuardian && (
        <CardContent
          sx={{
            py: 1,
            px: 1,
            height: 'auto',
            minHeight: 4,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}>
          <PostRecipients
            sx={{ my: 0 }}
            limitedToGroups={post.limitedToGroups}
            limitedToActivities={post.limitedToActivities}
          />
        </CardContent>
      )}
    </Card>
  );
};

BoardPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  dontDisplayHistory: PropTypes.bool,
  isStaffMemberBoard: PropTypes.bool,
  unreadedPosts: PropTypes.array,
  sx: PropTypes.object
};

export default BoardPostCard;