import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../Components/Albums/Header';
import SingleAlbum from '../../Components/Albums/SingleAlbum/SingleAlbum';
import * as AlbumTypes from '../../../../constants/groupsAndActivitiesTypes';
import * as SortTypes from '../../../../constants/sortTypes';
import Collapse from '@mui/material/Collapse';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButtonPipe from '../../../common/IconButtonPipe';
import { browserHistory } from 'react-router';
import { routePaths } from '../../../../routePaths';
import GalleryAlbumForm, {
  VALIDATION_SCHEMA,
  validate,
  InitialValues
} from '../../../../forms/gallery/GalleryAlbumForm';
import FormDialog from '../../../formik/formDialog/FormDialog';
import { useDispatch, useSelector } from 'react-redux';
import * as galleryActions from '../../../../actions/galleryActions';
import EmptyState from '../../../common/EmptyState';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

export const prepareData = ({ limitedToGroups, limitedToActivities }) => {
  if (!Array.isArray(limitedToGroups) && !Array.isArray(limitedToGroups)) return null;

  if (!limitedToGroups.length && !limitedToActivities.length) return { data: null, type: AlbumTypes.GLOBAL };
  if (limitedToGroups.length === 1 && !limitedToActivities.length) {
    return {
      data: [...limitedToGroups].pop(),
      type: AlbumTypes.GROUP
    };
  }
  if (!limitedToGroups.length && limitedToActivities.length === 1) {
    return {
      data: [...limitedToActivities].pop(),
      type: AlbumTypes.ACTIVITY
    };
  }
  if (limitedToGroups.length > 1 && !limitedToActivities.length) {
    return {
      data: limitedToGroups,
      type: AlbumTypes.MORE_GROUPS
    };
  }
  if (!limitedToGroups.length && limitedToActivities.length > 1) {
    return {
      data: limitedToActivities,
      type: AlbumTypes.MORE_ACTIVITIES
    };
  }
  return null;
};

const Albums = ({ selectAlbum, sortType, setSortType, groups, activities, albums, isEditAvailable }) => {
  const cl = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [expanded, setExpanded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [preparedAlbums, setPreparedAlbums] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.gallery.ui.isLoading);
  const isSuccess = useSelector((state) => state.gallery.ui.isSuccess);
  const target = useSelector((state) => state.gallery.ui.target);

  const sort = (value, array) => {
    switch (value) {
      case SortTypes.LATEST:
        return array.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case SortTypes.NEWEST:
        return array.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      case SortTypes.LATEST_MODIFIED:
        return array.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      case SortTypes.A_Z:
        return array.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'pl'));
      case SortTypes.Z_A:
        return array.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase(), 'pl'));
      default:
        return null;
    }
  };

  const sortedAlbums = useMemo(() => {
    return sort(sortType, _.cloneDeep(albums));
  }, [albums, sortType]);

  useEffect(() => {
    setPreparedAlbums(sortedAlbums);
  }, [albums, sortedAlbums]);

  useEffect(() => {
    if (isLoading === false && isSuccess) {
      setIsDialogOpen(false);
    }
  }, [isLoading, isSuccess]);

  const handleSort = (value, array) => {
    setPreparedAlbums(sort(value, array));
    setSortType(value);
  };

  const filterAlbums = (text, isText) => {
    if (isText) {
      setPreparedAlbums(
        sortedAlbums.filter(
          (item) =>
            item.name.toLowerCase().includes(text.toLowerCase()) ||
            item.description.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  const handleOnClick = (id) => {
    browserHistory.push({
      pathname: routePaths.gallery,
      query: { id }
    });
    selectAlbum(id);
  };

  const handleAddAlbum = (values) => dispatch(galleryActions.createAlbum(values));

  const isCollapseOpen = preparedAlbums.length < 25;
  return (
    <Box>
      <Header
        openDialog={() => setIsDialogOpen(!isDialogOpen)}
        filterAlbums={filterAlbums}
        clearFilters={() => setPreparedAlbums(_.cloneDeep(sort(sortType, albums)))}
        sortType={sortType}
        handleSort={(value) => handleSort(value, preparedAlbums)}
        isEditAvailable={isEditAvailable}
      />
      <Box sx={{ p: isMobile ? 1 : 2 }}>
        {preparedAlbums && preparedAlbums.length ? (
          <>
            <Collapse in={expanded || isCollapseOpen} collapsedSize="480px">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap'
                }}>
                {preparedAlbums.map((item) => {
                  return (
                    <SingleAlbum
                      key={item.id}
                      onClick={() => handleOnClick(item.id)}
                      name={item.name}
                      id={item.id}
                      isPublic={item.isPublic}
                      {...prepareData(item)}
                    />
                  );
                })}
              </Box>
            </Collapse>
            {!isCollapseOpen && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Paper
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    color: (theme) => theme.palette.color.contrast
                  }}>
                  <IconButtonPipe
                    className={`${cl.expand} ${expanded ? cl.expandOpen : null}`}
                    onClick={() => setExpanded((prev) => !prev)}
                    aria-expanded={expanded}
                    tooltip={expanded ? 'Pokaż mniej' : 'Pokaż więcej'}>
                    <ExpandMoreIcon />
                  </IconButtonPipe>
                </Paper>
              </Box>
            )}
          </>
        ) : (
          <EmptyState
            isPaper
            contrast
            message={
              preparedAlbums.length !== albums.length
                ? 'Brak wyników wyszukiwania dla zastosowanych filtrów'
                : 'Nie dodano żadnego albumu'
            }
          />
        )}
      </Box>

      <FormDialog
        title="Dodaj album"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        initialValues={new InitialValues()}
        validationSchema={VALIDATION_SCHEMA}
        inProgress={isLoading && target === galleryActions.loadingTypes.CREATE}
        validate={validate}
        onSubmit={handleAddAlbum}
        requiredFieldText>
        <GalleryAlbumForm groups={groups} activities={activities} />
      </FormDialog>
    </Box>
  );
};

Albums.propTypes = {
  selectAlbum: PropTypes.func,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  sortType: PropTypes.string.isRequired,
  setSortType: PropTypes.func.isRequired,
  albums: PropTypes.array.isRequired,
  isEditAvailable: PropTypes.bool.isRequired
};

export default Albums;
