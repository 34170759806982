import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActionArea,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const IncorrectPaymentsList = ({ incorrectPayments }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Accordion sx={{ '&:hover': { background: (theme) => theme.palette.background.hover } }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: 0.5 }}>
            Płatności pominięte:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List style={{ maxHeight: '40vh', overflow: 'auto', mt: 2 }}>
            {incorrectPayments.map((incorrectPayment, index) => (
              <ListItem
                key={index}
                sx={{
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}>
                <ListItemText primary={incorrectPayment.description} secondary={`Kwota: ${incorrectPayment.amount}`} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

IncorrectPaymentsList.propTypes = {
  incorrectPayments: PropTypes.array
};

export default IncorrectPaymentsList;
