import * as colors from '../constants/avatarColorPalette';

export default class AvatarContentGenerator {
  getHashCode(str) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash;
    }
    return Math.abs(hash);
  }

  generateColorFromName(name) {
    const index = this.getHashCode(name) % colors.avatarColorPaletteArray.length;
    return colors.avatarColorPaletteArray[index].color;
  }

  generateInitialsFromName(name) {
    return name.slice(0, 2);
  }

  generateInitialsFromFullName(lastName, firstName) {
    return lastName?.slice(0, 1) + firstName?.slice(0, 1);
  }
}

export function create() {
  return new AvatarContentGenerator();
}
