import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../../forms/PersonAvatar';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../../routePaths';
import { Box, Button, Paper, Typography } from '@mui/material';

const ChildInfoCard = ({ child, onAbsenceReport }) => {
  return (
    <Paper
      sx={{
        p: 3,
        m: 1,
        borderRadius: '8px',
        cursor: 'pointer',
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === 13) browserHistory.push(fromTemplate(routePaths.guardianChildDetails, [child.id]));
      }}
      onClick={() => browserHistory.push(fromTemplate(routePaths.guardianChildDetails, [child.id]))}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <PersonAvatar
          initials
          alt={child.firstName}
          url={child.photoUrl}
          firstName={child.firstName}
          lastName={child.lastName}
          size={60}
          sx={{ flexShrink: 0 }}
        />
        <Typography
          sx={{
            ml: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {child.firstName} {child.lastName}
        </Typography>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          aria-label="Zgłoś nieobecność"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            onAbsenceReport();
          }}>
          Zgłoś nieobecność
        </Button>
      </Box>
    </Paper>
  );
};

ChildInfoCard.propTypes = {
  child: PropTypes.object.isRequired,
  onAbsenceReport: PropTypes.func.isRequired
};

export default ChildInfoCard;
