import api from '../api/SettlementsSettingsApi';
import {
  LOAD_SETTLEMENTS_SETTINGS,
  LOAD_SETTLEMENTS_SETTINGS_SUCCESS,
  UPDATE_SETTLEMENTS_SETTINGS,
  UPDATE_SETTLEMENTS_SETTINGS_CANCEL,
  UPDATE_SETTLEMENTS_SETTINGS_FAILURE,
  UPDATE_SETTLEMENTS_SETTINGS_SUBMIT,
  UPDATE_SETTLEMENTS_SETTINGS_SUCCESS
} from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadSettlementsSettingsAsync() {
  return (dispatch) => {
    dispatch({ type: LOAD_SETTLEMENTS_SETTINGS });
    return api
      .getSettlementsSettings()
      .then((settings) => dispatch({ type: LOAD_SETTLEMENTS_SETTINGS_SUCCESS, settings }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać ustawień rozliczeń'));
        return logger.error(error);
      });
  };
}

export function updateSettlementsSettingsStart() {
  return { type: UPDATE_SETTLEMENTS_SETTINGS };
}

export function updateSettlementsSettingsCancel() {
  return { type: UPDATE_SETTLEMENTS_SETTINGS_CANCEL };
}

export function updateSettlementsSettingsAsync(nextSettings) {
  const settings = Object.assign({}, nextSettings);
  settings.stayDelayInterestRate = settings.stayDelayInterestRate === '' ? null : settings.stayDelayInterestRate;
  settings.cateringDelayInterestRate =
    settings.cateringDelayInterestRate === '' ? null : settings.cateringDelayInterestRate;
  settings.otherDelayInterestRate = settings.otherDelayInterestRate === '' ? null : settings.otherDelayInterestRate;

  return (dispatch) => {
    dispatch({ type: UPDATE_SETTLEMENTS_SETTINGS_SUBMIT });
    return api
      .updateSettlementsSettings(settings)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zaktualizowano ustawienia rozliczeń'));
        dispatch({ type: UPDATE_SETTLEMENTS_SETTINGS_SUCCESS, settings });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: UPDATE_SETTLEMENTS_SETTINGS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień rozliczeń'));
        return logger.error(error);
      });
  };
}
