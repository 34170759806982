import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { unrollChildrenToDietAsync } from '../../actions/dietActions';
import PersonAvatar from '../forms/PersonAvatar';

export const EnrolledChildrenList = ({ enrolledChildren, diet }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleUnrollChild = useCallback(
    async (childId) => {
      try {
        setIsLoading(true);
        await dispatch(unrollChildrenToDietAsync({ menuId: diet.id, childIds: [childId] }));
      } finally {
        setIsLoading(false);
      }
    },
    [diet.id, dispatch]
  );

  const sortedEnrolledChildren = useMemo(() => {
    return enrolledChildren.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }, [enrolledChildren]);

  return (
    <List>
      {sortedEnrolledChildren.map((child) =>
        child ? (
          <ListItem key={child.id}>
            <ListItemAvatar>
              <PersonAvatar
                initials
                alt={child.firstName}
                url={child.photoUrl}
                firstName={child.firstName}
                lastName={child.lastName}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${child.lastName} ${child.firstName}`}
              primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
            />
            <ActionConfirmationButton
              actionLabel="usuń"
              confirmationText={`Czy na pewno chcesz usunąć dietę dziecku ${child.firstName} ${child.lastName}?`}
              confirmationTitle="Usuń dietę"
              confirmLabel="usuń"
              variant="contained"
              isLoading={isLoading}
              onConfirm={() => handleUnrollChild(child.id)}
              onCancel={() => {}}
            />
          </ListItem>
        ) : null
      )}
    </List>
  );
};
