import MealsApi from '../api/MealsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { loadMealsCategoriesAsync } from './mealCategoriesActions';

export function loadMealsAsync() {
  return async (dispatch) => {
    try {
      const meals = await MealsApi.getMeals();
      dispatch({ type: types.LOAD_MEALS_SUCCESS, meals });
    } catch (e) {
      dispatch({ type: types.LOAD_MEALS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać posiłków'));
    }
  };
}

export function createMealDetailsAsync(meal) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.createMeal(meal);
      console.log(data);
      dispatch(loadMealsAsync());
      dispatch(notificationActions.showSuccess(`Utworzono posiłek ${meal.name}`));
    } catch (e) {
      console.log(e);
      dispatch({ type: types.CREATE_MEAL_DETAILS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się utworzyć posiłku'));
      throw e;
    }
  };
}

export function updateMealAsync(meal) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.updateMeal(meal);
      console.log(data);
      dispatch(loadMealDetailsAsync(meal.id));
      dispatch(loadMealsCategoriesAsync());
      dispatch(notificationActions.showSuccess(`Zaktualizowano posiłek ${meal.name}`));
    } catch (e) {
      console.log(e);
      dispatch({ type: types.UPDATE_MEAL_DETAILS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się zaktualizować posiłku'));
      throw e;
    }
  };
}

export function loadMealDetailsAsync(id) {
  return async (dispatch) => {
    try {
      const meal = await MealsApi.getMealDetails(id);
      console.log(meal);
      dispatch({ type: types.LOAD_MEAL_DETAILS_SUCCESS, meal });
    } catch (e) {
      console.log(e);
      dispatch({ type: types.LOAD_MEAL_DETAILS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać szczegółów posiłku'));
    }
  };
}

export function archiveMealAsync(meal) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.archiveMeal(meal.id, meal.name, meal.color);
      console.log(data);
      dispatch(loadMealsAsync());
      dispatch(notificationActions.showSuccess(`Zarchiwizowano posiłek ${meal.name}`));
    } catch (e) {
      if (e.status === 412) {
        dispatch({ type: types.ARCHIVE_MEAL_FAILURE });
        dispatch(
          notificationActions.showError(
            `Nie udało się zarchiwizować posiłku, ${e.response.body.generalError.toLowerCase()}`
          )
        );
        throw new Error(`Nie udało się zarchiwizować posiłku, ${e.response.body.generalError.toLowerCase()}`);
      } else {
        dispatch({ type: types.ARCHIVE_MEAL_FAILURE });
        dispatch(notificationActions.showError('Nie udało się zarchiwizować posiłku'));
        throw new Error('Nie udało się zarchiwizować posiłku');
      }
    }
  };
}
