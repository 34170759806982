import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const ManualLink = ({ index, sx }) => {
  const handleClick = () => {
    window.open(`${window.location.origin}/instrukcja-obslugi${index ? '#' + index : ''}`, '_blank');
  };

  return (
    <IconButton
      sx={{ mx: 1, color: (theme) => theme.palette.color.color5, width: 40, height: 40, ...sx }}
      onClick={handleClick}
      aria-label="Przejdź do instrukcji obsługi"
      size="large">
      <HelpOutlineOutlinedIcon sx={{ color: (theme) => theme.palette.color.primary }} />
    </IconButton>
  );
};

ManualLink.propTypes = {
  index: PropTypes.string,
  sx: PropTypes.object
};

export default ManualLink;
