'use strict';

import PropTypes from 'prop-types';

import React from 'react';
import DuesToPaySummary from './DuesToPaySummary';
import { Box, Button, Typography } from '@mui/material';

const OnlinePaymentsSummary = ({ duesToPay, selectedDuesIds, onPay, includesCommission }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', m: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DuesToPaySummary duesToPay={duesToPay.filter((due) => selectedDuesIds.some((i) => i === due.id))} />
        <Button variant="contained" aria-label="Zapłać teraz" disabled={selectedDuesIds.length === 0} onClick={onPay}>
          Zapłać teraz
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'right' }}>
          {includesCommission && (
            <Typography sx={{ color: (theme) => theme.palette.color.primary }}>
              Do transakcji zostanie doliczona prowizja
            </Typography>
          )}
          <Typography sx={{ color: (theme) => theme.palette.color.primary }}>
            Bezpieczeństwo płatności online zapewnia Autopay S.A.
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.color.primary,
              textDecoration: 'none',
              '&:hover': {
                opacity: 0.8
              }
            }}>
            <a
              href="http://www.autopay.pl"
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}>
              www.autopay.pl
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

OnlinePaymentsSummary.propTypes = {
  duesToPay: PropTypes.array.isRequired,
  selectedDuesIds: PropTypes.array.isRequired,
  onPay: PropTypes.func.isRequired,
  includesCommission: PropTypes.bool.isRequired
};

export default OnlinePaymentsSummary;
