import BaseModel from '../../BaseModel';

class DaycareProperties extends BaseModel {
  constructor() {
    super();
    this.sleepTimeInMinutes = false;
    this.meals = false;
    this.mealsNote = false;
    this.mealsScale = false;
    this.mood = false;
    this.nappies = false;
    this.poos = false;
    this.note = false;
  }
}

export class DaycareSettingsModel extends BaseModel {
  constructor() {
    super();
    this.enabled = false;
    this.propertiesDefinition = new DaycareProperties();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.propertiesDefinition = new DaycareProperties().assign(assigned.propertiesDefinition);
  }
}
