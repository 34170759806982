import PropTypes from 'prop-types';
import React from 'react';
import { useTheme, ToggleButtonGroup, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ToggleButton from '@mui/material/ToggleButton';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButtonPipe from '../../../../components/common/IconButtonPipe';
import CloseIcon from '@mui/icons-material/Close';
import MoreGroupsIcon from '../../../../assets/icons/MoreGroupsIcon';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ListIcon from '@mui/icons-material/List';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { SHARE_OPTIONS } from '../../containers/ShareFileFormContainer';
import { useFormikContext } from 'formik';
import { useShareFileContext } from '../../context/ShareFileContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%'
  },
  divider: {
    margin: theme.spacing(1, 0.5)
  }
}));

export const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const ShareFileMobileToolkit = ({
  selectedSharingOption,
  onPrivate,
  onChangeSharingOption,
  onCloseDialog,
  shareToUsersTouched,
  setShareToUsersTouched
}) => {
  const { isDisabled, isForbidden, isPrivate, isSingleShare } = useShareFileContext();
  const theme = useTheme();

  const { errors } = useFormikContext();
  const error = errors.atLeastOneItem;
  const isError = !!error || isForbidden;
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();
  return (
    <Box className={classes.paper}>
      <Box sx={{ display: 'flex' }}>
        <StyledToggleButtonGroup
          size="small"
          value={selectedSharingOption}
          exclusive
          onChange={(event, newValue) => {
            if (!shareToUsersTouched && newValue === SHARE_OPTIONS.userShare) setShareToUsersTouched(true);
            if (newValue !== null && newValue !== selectedSharingOption) onChangeSharingOption(newValue);
          }}
          aria-label="text alignment">
          <ToggleButton value={0} disabled={isDisabled} aria-label="Udostępnianie dla ról">
            <MoreGroupsIcon color={selectedSharingOption === SHARE_OPTIONS.roleShare ? 'primary' : undefined} />
          </ToggleButton>
          <ToggleButton value={1} disabled={isDisabled} aria-label="Udostępnianie dla pojedynczych osób">
            {shareToUsersTouched ? (
              <PersonIcon color={selectedSharingOption === SHARE_OPTIONS.userShare ? 'primary' : undefined} />
            ) : (
              <PersonAddIcon color={selectedSharingOption === SHARE_OPTIONS.userShare ? 'primary' : undefined} />
            )}
          </ToggleButton>
          <ToggleButton value={2} disabled={isDisabled && !isForbidden} aria-label="Wybrane pliki">
            <ListIcon color={selectedSharingOption === SHARE_OPTIONS.filesScreen ? 'primary' : undefined} />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <IconButtonPipe
          disabled={isDisabled && !isPrivate}
          onClick={(e) => {
            e.preventDefault();
            onPrivate();
          }}
          tooltip={
            isPrivate
              ? `Przywróć możliwość udostępniania ${isSingleShare ? 'pliku' : 'plików'}`
              : `Wyłącz udostępnianie ${isSingleShare ? 'wybranego pliku' : ' wybranych plików'}`
          }>
          {isPrivate ? <LockOpenIcon /> : <LockIcon />}
        </IconButtonPipe>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <IconButtonPipe
          onClick={(e) => {
            e.preventDefault();
            onCloseDialog();
          }}
          tooltip="Anuluj udostępnianie plików">
          <CloseIcon />
        </IconButtonPipe>
        <IconButtonPipe
          type="submit"
          tooltip="Udostępnij"
          disabled={isSubmitting || isError}
          style={{
            background: theme.palette.color.color25,
            margin: 3,
            padding: 9
          }}>
          <DoneAllIcon sx={{ color: (theme) => theme.palette.color.primary }} />
        </IconButtonPipe>
      </Box>
    </Box>
  );
};

ShareFileMobileToolkit.propTypes = {
  onChangeSharingOption: PropTypes.func,
  onCloseDialog: PropTypes.func,
  onPrivate: PropTypes.func,
  selectedSharingOption: PropTypes.number,
  setShareToUsersTouched: PropTypes.func,
  shareToUsersTouched: PropTypes.bool
};

export default ShareFileMobileToolkit;
