import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchInputV2Styles } from './SearchInputV2Styles';
import PropTypes from 'prop-types';
import { Divider, Box, Input, InputAdornment } from '@mui/material';

const SearchInputV2 = ({
  isMobile = false,
  searchText,
  onSearch,
  onClear,
  InputProps,
  isDivider = true,
  disabled,
  contrast,
  endAdornment,
  sx,
  ...rest
}) => {
  const cl = useSearchInputV2Styles({ isMobile });

  return (
    <Box className={cl.root} sx={{ alignSelf: 'start', ...sx }} {...rest}>
      <Input
        className={cl.input}
        value={searchText}
        onChange={({ target }) => onSearch(target.value)}
        disabled={disabled}
        {...InputProps}
        fullWidth
        sx={{
          color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary),
          ':before': {
            borderBottomColor: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary)
          },
          ':after': {
            borderBottomColor: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary)
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon
              sx={{ color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary) }}
            />
          </InputAdornment>
        }
        endAdornment={!!endAdornment && endAdornment}
      />
      {!isMobile && isDivider && <Divider orientation="vertical" flexItem className={cl.divider} />}
    </Box>
  );
};

SearchInputV2.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  isMobile: PropTypes.bool,
  searchText: PropTypes.string.isRequired,
  InputProps: PropTypes.object,
  isDivider: PropTypes.bool,
  disabled: PropTypes.bool,
  contrast: PropTypes.bool,
  sx: PropTypes.object,
  endAdornment: PropTypes.object
};

export default SearchInputV2;
