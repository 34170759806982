'use strict';

export const themeTypes = {
  light: {
    name: 'Jasny',
    value: 'light'
  },
  dark: {
    name: 'Ciemny',
    value: 'dark'
  },
  contrast: {
    name: 'Wysoki kontrast',
    value: 'contrast'
  }
};

export const standardTheme = {
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#104F93'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: ({ theme }) => ({
          background: `${theme.palette.color.success}`,
          color: `#333`,
          fontSize: 16
        }),
        filledError: ({ theme }) => ({
          background: `${theme.palette.color.error}`,
          color: `#fff`,
          fontSize: 16
        }),
        filledWarning: ({ theme }) => ({
          background: `${theme.palette.color.warning}`,
          color: `#333`,
          fontSize: 16
        }),
        filledInfo: ({ theme }) => ({
          background: `${theme.palette.color.info}`,
          color: `#333`,
          fontSize: 16
        })
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'p',
          h2: 'p',
          h3: 'p',
          h4: 'p',
          h5: 'p',
          h6: 'p',
          subtitle1: 'p',
          subtitle2: 'p',
          body1: 'p',
          body2: 'p'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '@media(min-width: 1200px)': {
            scrollbarColor: '#959595 #fff',

            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              background: '#fbfbfb',
              width: '12px',
              borderRadius: 0
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              background: '#959595',
              minHeight: 24
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              background: '#6b6b6b'
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              background: '#6b6b6b'
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              background: '#6b6b6b'
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              background: '#fff'
            }
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '16px !important'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          py: 1,
          my: 0
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: 4,
          background: '#19ba9b',
          '& .MuiLinearProgress-bar': {
            background: '#aff5e7'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: `${theme.palette.color.contrast}`,
          '&.Mui-checked': {
            color: `${theme.palette.color.color25}`
          },
          '&.MuiCheckbox-indeterminate': {
            color: `${theme.palette.color.color7}`
          }
        })
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: `${theme.palette.color.color25}`,
          '&.Mui-checked': {
            color: `${theme.palette.color.color25}`
          }
        })
      }
    },
    //tło autocomplete, do włączenia jeśli bedzie potrzebne

    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       '&:-webkit-autofill': {
    //         transition: 'background-color 9999s ease-in-out 0s',
    //         color: 'white !important',
    //         '-webkit-text-fill-color': '#fff'
    //       }
    //     }
    //   }
    // },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 50,
            fontWeight: 200
          }
        },
        ul: ({ theme }) => ({
          '& .Mui-selected': {
            background: `${theme.palette.color.color25} !important`,
            color: `${theme.palette.color.primary} !important`
          }
        })
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme }) => ({
          background: `${theme.palette.color.color25} !important`,
          color: '#FFF'
        })
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: { background: '#444444', width: '72px' },
        switchBase: { color: '#444444' },
        thumb: { width: '20px', height: '20px' }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          overflow: 'hidden',
          '@media(min-width: 1200px)': {
            scrollbarColor: '#959595 #fff',

            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              background: '#fbfbfb',
              width: '12px',
              borderBottomRightRadius: 15,
              borderTopRightRadius: 15,
              overflow: 'hidden'
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              background: '#959595',
              minHeight: 24,
              borderRadius: 8
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              background: '#6b6b6b'
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              background: '#6b6b6b'
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              background: '#6b6b6b'
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              background: '#fff'
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#444'
        },
        shrink: {
          '&.Mui-focused': {
            color: '#444'
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'transparent'
        }
      }
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            color: '#fff',
            borderBottom: '1px solid white',
            '&:hover': {
              borderBottom: '1px solid #bbb'
            },
            '& .MuiSvgIcon-root': {
              color: '#fff'
            },
            '&:before': {
              borderColor: 'white'
            },
            '&:after': {
              borderColor: 'white'
            },
            svg: { color: 'white' }
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            whiteSpace: 'nowrap',
            border: '3px solid white',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            color: 'white',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            fontSize: 16,
            '&:hover': {
              border: '3px solid grey !important',
              color: 'grey'
            }
          }
        },
        {
          props: { variant: 'outlinedContrast' },
          style: {
            whiteSpace: 'nowrap',
            border: '3px solid #777777 !important',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            color: '#777777',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            fontSize: 16,
            height: 40,
            '&:hover': {
              border: '3px solid #444 !important',
              color: '#444'
            },
            '&:disabled': {
              color: '#999',
              border: '3px solid #999 !important'
            }
          }
        },
        {
          props: { variant: 'clearText' },
          style: {
            whiteSpace: 'nowrap',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            fontWeight: 700,
            color: '#FB5B85',
            margin: 5,
            fontSize: 16,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              color: '#FB5B85',
              background: 'transparent',
              fontWeight: 800
            }
          }
        },

        {
          props: { variant: 'text' },
          style: {
            border: 'none',
            opacity: 1,
            textTransform: 'capitalize',
            color: '#444444',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              background: 'transparent',
              fontWeight: 800
            }
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            whiteSpace: 'nowrap',
            background: '#ff3d8b',
            borderRadius: '46px',
            opacity: 1,
            padding: '10px 40px',
            boxShadow: 'none',
            height: 40,
            margin: 4,
            fontSize: 16,
            fontWeight: 600,
            textTransform: 'lowercase',
            '&:hover': {
              boxShadow: 'none',
              background: '#ff3d8b'
            },
            '&:disabled': {
              boxShadow: 'none',
              background: '#ff3d8b'
            }
          }
        },
        {
          props: { variant: 'textOutlined' },
          style: {
            border: 'none',
            opacity: 1,
            textTransform: 'capitalize',
            color: '#FFF',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40
          }
        }
      ]
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: '#444444'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#ffffff'
    },

    gradient: {
      mainColor1: '#4352A4',
      mainColor2: '#903E97'
    },
    //Typography texts colors
    text: {
      primary: '#444444',
      //AppDrawerNavItem
      secondary: '#999999',
      contrast: '#FFD700',
      //GroupDetailsForm
      color1: '#9e9e9e',
      dark: '#444'
    },
    //Elements (borders etc) colors
    color: {
      //SettlementsList, RecipientsDialog
      hidden: 'transparent',
      //GuardianDashboardPage CanteenWorkerDashboardPage DigitalDiaryTeacherDashboardPage CreateThreadButton, header ImportErrorsComponent ThreadHeader, SettlementCalculationDetails, SettlementDetails, ScheduledTransferTableToolbar
      primary: '#ffffff',
      contrast: '#444444',
      //SettlementsStatistics, UploadedMultimediaDetailsContent SettlementsList, Status, SuccessNotification
      success: '#9ccc65',
      //SettlementsStatistics, SettlementsList
      cancel: '#f06292',
      info: '#03A5F4',
      //SettlementsList, RichTextEditor GroupTeachersList SettlementsStatistics, Status
      error: '#d32f2f',
      warning: '#FF9800',
      //StyledToggleButtonGroup, StyledToggleButtonGroup, PrivateButton, SearchInputV2, ShareToRoleCard, AvailableRolesContent, SelectedRolesContent, ShareSearchBar, SingleUser, Expand, SelectedUsersContent, SharePersonListComponent, ReducedSharePersonListComponent
      disabled: '#1976d2', //'#333333',
      //SharePersonListComponent, SecretaryMenu TeacherMenu ReducedSharePersonListComponent, CreateThreadButton, TeacherSupportMenu
      color1: '#03A5F4',
      //ReliefForm TeacherSupportMenu Meal Mood TeacherMenu SecretaryMenu
      color2: '#444444',
      //useSharePersonListComponentStyles, TeacherSupportMenu TeacherMenu SecretaryMenu
      color3: '#FAB378',
      //AdvancedInterests ManualHeader TeacherSupportMenu, GroupsAttendanceSummary SingleAuthorChip PersonAvatar SingleMemberChip SingleAlbumStyles SingleMultimedia SpecialButton importPage TeacherMenu RightBottomNotification, SecretaryMenu ThreadsList, ScheduledTransferTableToolbar, SearchInputV2Styles, SettlementDetailsDialog, FileAvatar, MobileSearch, PrivateFile, SingleFile, SingleUserRole, SingleUserRoleChip, TableView, Toolkit, ToolkitStyles, VirtualDiscTableHeader
      color4: '#67A9FE',
      //SettlementsList, ScheduledTransferTableToolbar, TeacherSupportMenu TeacherMenu SecretaryMenu
      color5: '#005FEA',
      //ScheduledTransferTableBody, PostRecipients TeacherSupportMenu TeacherMenu SecretaryMenu
      color6: '#E60013',
      //TeacherSupportMenu, TeacherMenu SecretaryMenu SettlementDetailsDialog,SettlementCalculationDetails, ScheduledTransferTableBody, ReadIndicatorIcon
      color7: '#15846E',
      //ReadIndicatorIcon, TeacherSupportMenu TeacherMenu SecretaryMenu
      color8: '#ef4b82',
      //FileAvatar, UnitAttendanceSummaryItem TeacherSupportMenu, TeacherMenu SecretaryMenu
      color9: '#9c27b0',
      //MessageBody, SettlementsImport, ImportErrorsComponent ShareFileHeaderFilesList, NotificationsHandler, ReducedSharePersonListComponent
      color10: '#D0180B',
      //authorizationIconStyle RegisteredPaymentsList, SettlementsImport
      color12: '#9CCC65',
      //DrawerButton
      color13: '#29B6F6',
      //authorizationIconStyle RegisteredPaymentsList UnreadThreadsBadgeWrapper SingleLegalGuardianItem
      color14: '#c2390e',
      color16: 'gold',
      //ExportChildrenDialog
      color17: '#F44336',
      //SettlementsList
      color18: '#9C9ECC',
      color19: '#8E3F98',
      //PostHeader PostHeader NumberingSchemeForm
      color20: '#FFF107',
      //ShareFileHeaderFilesList, RegisteredPaymentsList
      color21: '#007897',
      //SingleLegalGuardianItem cloneeventsdialog FormikTextField GroupsArchiveList GuardianApplicationsPage UnhandledErrorNotification, ThreadHeader, DetailedPaymentsSummaryPerChildReport, DuesActivitiesOverallReport, DuesCateringOverallReport, DuesDetailsOverallReport, DuesDetailsOverallReportXLS, DuesOtherOverallReport, DuesPaymentsReport, DuesStayOverallReport, RegisterStaffMemberPaymentDialog, SettlementDetailsDialog, YearAndMonthTemplate, GroupAndMonthTemplate
      color22: '#000000',
      //AttendanceDashboardPage
      color23: '#754047',
      //BoardPostCardDialogItem
      color24: '#2d7230',
      //SquareRadioButton
      color25: '#FC5A86',
      color26: '#ffffff',
      color27: '#CA3E47'
    },
    //Components backgrounds colors
    background: {
      // ManualHeader Toolkit, MobileToolkit, RichTooltip, UnhandledErrorNotification
      default: '#FFFFFF',
      error: '#D0180B',
      success: '#28a745',
      transparent: 'transparent',
      hover: '#EFEFEF',
      //Toolkit
      shadow: '#000000',
      color1: '#4352A4',
      color2: '#903E97',
      color3: '#f7f7f7',
      color4: '#f7f7f7',
      color5: '#E5F6FD',
      color6: '#afafaf',
      color7: 'black',
      color8: '#C471F5',
      color9: '#FA71CD',
      color10: '#efefef',
      color11: '#CDEEFB'
    },
    //Everything that needs special color replacements when changing themes
    components: {
      messenger: {
        messengerText: '#444444',
        background: '#ffffff',
        inputBackground: '#F1F1F1'
      },
      fileIcons: {
        pdfFile: '#FC5A86',
        spreadsheetFile: '#1DDBB6',
        textFile: '#03A5F4',
        audioFile: '#ED8300',
        compressedFile: '#FFC107',
        imageFile: '#903E97',
        presentationFile: '#F77062',
        systemFile: '#413F3F',
        videoFile: '#00A475',
        programmingFile: '#6B5F5F',
        defaultFile: '#3f3fbc',
        folder: '#202060'
      },
      welcomeScreen: {
        color1: 'rgba(255, 255, 255, 0.8)',
        color2: 'white',
        color3: '#80ddea',
        color4: '#4d4d4d',
        color5: '#202020',
        color6: '#00bbd4'
      },
      wysiwyg: {
        borderError: '#D0180B',
        borderPass: '#F1F1F1'
      },
      calendar: {
        headerIconColor: '#d3d3d3',
        weekColor: '#bdbdbd',
        dayColor: '#bdbdbd',
        calendarBackground: '#eeeeee',
        calendarDay: '#FFFFFF',
        calendarDayColor: '#9E9E9E',
        calendarWeekend: '#F5F5F5',
        disabled: '#F5F5F5',
        notCurrentMonth: '#E0E0E0',
        calendarDayColorHover: '#59CBE8',
        calendarDayBgColorHover: '#e7e7e7',
        absentDayContentColor: '#FFC107',
        attendantDayContentColor: '#90ee90',
        invalidDayContentColor: '#FF5722',
        dayOffDayContentColor: '#ffffff'
      },
      //SingleUserRoleChip, SingleUserRole, FileAvatar
      role: {
        legalGuardian: '#03A5F4',
        staffMemberAccountant: '#903E97',
        staffMemberSecretary: '#ED8300',
        staffMemberTeacher: '#FC5A86',
        staffMemberDigitalDiary: '#20dfb2',
        staffMemberCanteenWorker: '#F77062',
        staffMemberPrincipal: '#253F5B',
        shareToMoreThenOneUser: '#FE5196'
      }
    }
  },
  typography: {
    fontFamily: 'League Spartan',
    fontSize: 14,
    fontWeightFeather: 100,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLarge: 600,
    fontWeightBold: 700,
    fontWeightBulky: 600,
    fontWeightBlack: 800,
    chipContent: {
      fontSize: 14,
      fontWeight: 700
    },
    //Typography custom variants
    MealsAndMoods: {
      fontFamily: 'League Spartan',
      fontSize: 12,
      fontWeight: 500,
      color: 'red'
    },
    //Typography custom variants
    medium_large: {
      fontFamily: 'League Spartan',
      fontSize: 16,
      fontWeight: 450
    },
    medium_bold: {
      fontFamily: 'League Spartan',
      fontSize: 18,
      fontWeight: 650
    },
    tableHeader: {
      fontFamily: 'League Spartan',
      fontSize: 16,
      fontWeight: 500
    },
    tableBodyCell: {
      fontFamily: 'League Spartan',
      fontSize: 18
    }
  },
  layout: {
    drawerWidth: 350,
    smallContainerWidth: 1150
  }
};

export const darkTheme = {
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: ({ theme }) => ({
          background: `${theme.palette.color.success}`,
          color: `#333`,
          fontSize: 16
        }),
        filledError: ({ theme }) => ({
          background: `${theme.palette.color.error}`,
          color: `#fff`,
          fontSize: 16
        }),
        filledWarning: ({ theme }) => ({
          background: `${theme.palette.color.warning}`,
          color: `#333`,
          fontSize: 16
        }),
        filledInfo: ({ theme }) => ({
          background: `${theme.palette.color.info}`,
          color: `#333`,
          fontSize: 16
        })
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '@media(min-width: 1200px)': {
            scrollbarColor: '#6b6b6b #2b2b2b',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              background: '#2b2b2b',
              borderRadius: 15
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              background: '#6b6b6b',
              minHeight: 24,
              border: '3px solid #2b2b2b'
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              background: '#959595'
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              background: '#959595'
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              background: '#959595'
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              background: '#2b2b2b'
            }
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '16px !important'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: '#fff'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          background: '#FC5A86',
          color: '#FFF'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: 4,
          background: '#cc3a66',
          '& .MuiLinearProgress-bar': {
            background: '#90004b'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: `${theme.palette.color.contrast}`,
          '&.Mui-checked': {
            color: `${theme.palette.color.color25}`
          },
          '&.MuiCheckbox-indeterminate': {
            color: `${theme.palette.color.color7}`
          }
        })
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: `${theme.palette.color.color25}`,
          '&.Mui-checked': {
            color: `${theme.palette.color.color25}`
          }
        })
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: { background: '#666666', width: '72px' },
        switchBase: { color: '#666666' },
        thumb: { width: '20px', height: '20px' }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          overflow: 'hidden',
          background: '#1A1A2E'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff'
        },
        shrink: {
          '&.Mui-focused': {
            color: '#fff'
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'transparent'
        }
      }
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            color: 'rgba(255, 255, 255)',
            borderBottom: '1px solid rgba(255, 255, 255, 0.5) ',
            '&:hover': {
              borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
            },
            '& .MuiSvgIcon-root': {
              color: 'rgba(255, 255, 255)'
            }
          }
        },
        {
          props: { variant: 'standardContrast' },
          style: {
            color: 'black',
            borderBottom: '1px solid rgba(0,0,0, 0.5) ',
            '&:hover': {
              borderBottom: '1px solid rgba(0,0,0, 0.5)'
            },
            '& .MuiSvgIcon-root': {
              color: 'black'
            }
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: '3px solid #fff',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            color: '#fff',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            fontSize: 16,
            height: 40,
            '&:hover': {
              border: '3px solid #fff !important',
              color: '#fff'
            }
          }
        },
        {
          props: { variant: 'outlinedContrast' },
          style: {
            border: '3px solid #fff ',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            color: '#fff ',
            margin: 5,
            fontSize: 16,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              border: '3px solid #fff  !important',
              color: '#fff '
            },
            '&:disabled': {
              color: '#444444',
              border: '3px solid #444444 !important'
            }
          }
        },
        {
          props: { variant: 'clearText' },
          style: {
            whiteSpace: 'nowrap',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            fontWeight: 700,
            color: '#FB5B85',
            margin: 5,
            fontSize: 16,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              color: '#FB5B85',
              background: 'transparent',
              fontWeight: 800
            }
          }
        },
        {
          props: { variant: 'text' },
          style: {
            border: 'none',
            opacity: 1,
            textTransform: 'capitalize',
            color: '#fff',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              background: 'transparent',
              fontWeight: 800
            }
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            background: '#670036',
            borderRadius: '46px',
            opacity: 1,
            padding: '10px 40px',
            boxShadow: 'none',
            height: 40,
            fontSize: 16,
            margin: 4,
            textTransform: 'lowercase',
            '&:hover': {
              boxShadow: 'none',
              background: '#670036'
            },
            '&:disabled': {
              boxShadow: 'none',
              background: '#670036'
            }
          }
        },
        {
          props: { variant: 'textOutlined' },
          style: {
            border: 'none',
            opacity: 1,
            textTransform: 'capitalize',
            color: '#FFF',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40
          }
        },
        {
          props: { variant: 'textContrast' },
          style: {
            border: 'none',
            opacity: 1,
            textTransform: 'capitalize',
            color: '#000',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40
          }
        }
      ]
    }
  },

  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#ffffff'
    },

    gradient: {
      mainColor1: '#1A1A40',
      mainColor2: '#1A1A40'
    },
    //Typography texts colors
    text: {
      primary: '#fff',
      //AppDrawerNavItem
      secondary: '#E1E1F1',
      contrast: '#000000',
      //GroupDetailsForm
      color1: '#9e9e9e',
      dark: '#444'
    },
    //Elements (borders etc) colors
    color: {
      //SettlementsList, RecipientsDialog
      hidden: 'transparent',
      //GuardianDashboardPage CanteenWorkerDashboardPage DigitalDiaryTeacherDashboardPage CreateThreadButton, header ImportErrorsComponent ThreadHeader, SettlementCalculationDetails, SettlementDetails, ScheduledTransferTableToolbar
      primary: '#ffffff',
      contrast: '#ffffff',
      //SettlementsStatistics, UploadedMultimediaDetailsContent SettlementsList, Status, SuccessNotification
      success: '#9ccc65',
      //SettlementsStatistics, SettlementsList
      cancel: '#f06292',
      info: '#03A5F4',
      //SettlementsList, RichTextEditor GroupTeachersList SettlementsStatistics, Status
      error: '#d32f2f',
      warning: '#FF9800',
      //StyledToggleButtonGroup, StyledToggleButtonGroup, PrivateButton, SearchInputV2, ShareToRoleCard, AvailableRolesContent, SelectedRolesContent, ShareSearchBar, SingleUser, Expand, SelectedUsersContent, SharePersonListComponent, ReducedSharePersonListComponent
      disabled: '#1976d2', //'#333333',
      //SharePersonListComponent, SecretaryMenu TeacherMenu ReducedSharePersonListComponent, CreateThreadButton, TeacherSupportMenu
      color1: '#03A5F4',
      //ReliefForm TeacherSupportMenu Meal Mood TeacherMenu SecretaryMenu
      color2: '#ffffff',
      //useSharePersonListComponentStyles, TeacherSupportMenu TeacherMenu SecretaryMenu
      color3: '#FAB378',
      //AdvancedInterests ManualHeader TeacherSupportMenu, GroupsAttendanceSummary SingleAuthorChip PersonAvatar SingleMemberChip SingleAlbumStyles SingleMultimedia SpecialButton importPage TeacherMenu RightBottomNotification, SecretaryMenu ThreadsList, ScheduledTransferTableToolbar, SearchInputV2Styles, SettlementDetailsDialog, FileAvatar, MobileSearch, PrivateFile, SingleFile, SingleUserRole, SingleUserRoleChip, TableView, Toolkit, ToolkitStyles, VirtualDiscTableHeader
      color4: '#67A9FE',
      //SettlementsList, ScheduledTransferTableToolbar, TeacherSupportMenu TeacherMenu SecretaryMenu
      color5: '#005FEA',
      //ScheduledTransferTableBody, PostRecipients TeacherSupportMenu TeacherMenu SecretaryMenu
      color6: '#F96573',
      //TeacherSupportMenu, TeacherMenu SecretaryMenu SettlementDetailsDialog,SettlementCalculationDetails, ScheduledTransferTableBody, ReadIndicatorIcon
      color7: '#1DDBB6',
      //ReadIndicatorIcon, TeacherSupportMenu TeacherMenu SecretaryMenu
      color8: '#ef4b82',
      //FileAvatar, UnitAttendanceSummaryItem TeacherSupportMenu, TeacherMenu SecretaryMenu
      color9: '#9c27b0',
      //MessageBody, SettlementsImport, ImportErrorsComponent ShareFileHeaderFilesList, NotificationsHandler, ReducedSharePersonListComponent
      color10: '#D0180B',
      //authorizationIconStyle RegisteredPaymentsList, SettlementsImport
      color12: '#9CCC65',
      //DrawerButton
      color13: '#29B6F6',
      //authorizationIconStyle RegisteredPaymentsList UnreadThreadsBadgeWrapper SingleLegalGuardianItem
      color14: '#FF5722',
      color16: 'gold',
      //ExportChildrenDialog
      color17: '#F44336',
      //SettlementsList
      color18: '#9C9ECC',
      color19: '#8E3F98',
      //PostHeader PostHeader NumberingSchemeForm
      color20: '#FFF107',
      //ShareFileHeaderFilesList, RegisteredPaymentsList
      color21: '#007897',
      //SingleLegalGuardianItem cloneeventsdialog FormikTextField GroupsArchiveList GuardianApplicationsPage UnhandledErrorNotification, ThreadHeader, DetailedPaymentsSummaryPerChildReport, DuesActivitiesOverallReport, DuesCateringOverallReport, DuesDetailsOverallReport, DuesDetailsOverallReportXLS, DuesOtherOverallReport, DuesPaymentsReport, DuesStayOverallReport, RegisterStaffMemberPaymentDialog, SettlementDetailsDialog, YearAndMonthTemplate, GroupAndMonthTemplate
      color22: '#fff',
      //AttendanceDashboardPage
      color23: '#754047',
      //BoardPostCardDialogItem
      color24: '#2d7230',
      //SquareRadioButton
      color25: '#FC5A86',
      color26: '#1A1A2E',
      color27: '#AF0404'
    },
    //Components backgrounds colors
    background: {
      // ManualHeader Toolkit, MobileToolkit, RichTooltip, UnhandledErrorNotification
      default: '#1A1A2E', //'#1A1A40',
      error: '#D0180B',
      success: '#28a745',
      transparent: 'transparent',
      hover: '#303053',
      //Toolkit
      shadow: '#000000',
      color1: '#4352A4',
      color2: '#903E97',
      color3: '#1A1A40',
      color4: '#f7f7f7',
      color5: '#071318',
      color6: '#afafaf',
      color7: 'black',
      color8: '#C471F5',
      color9: '#FA71CD',
      color10: '#1A1A40',
      color11: '#303053'
    },
    //Everything that needs special color replacements when changing themes
    components: {
      messenger: {
        background: 'rgb(31,31,55)',
        messengerText: '#ffffff',
        inputBackground: '#071318'
      },
      fileIcons: {
        pdfFile: '#FC5A86',
        spreadsheetFile: '#1DDBB6',
        textFile: '#03A5F4',
        audioFile: '#ED8300',
        compressedFile: '#FFC107',
        imageFile: '#903E97',
        presentationFile: '#F77062',
        systemFile: '#413F3F',
        videoFile: '#00A475',
        programmingFile: '#6B5F5F',
        defaultFile: '#3f3fbc',
        folder: '#202060'
      },
      welcomeScreen: {
        color1: 'rgba(255, 255, 255, 0.8)',
        color2: 'white',
        color3: '#80ddea',
        color4: '#4d4d4d',
        color5: '#202020',
        color6: '#00bbd4'
      },
      wysiwyg: {
        borderError: '#D0180B',
        borderPass: '#F1F1F1'
      },
      calendar: {
        headerIconColor: 'red',
        weekColor: '#fff',
        dayColor: 'white',
        calendarBackground: '#283149',
        calendarDay: '#16213E',
        calendarDayColor: 'white',
        calendarWeekend: '#0f1d38',
        disabled: '#160F30',
        notCurrentMonth: '#E0E0E0',
        calendarDayColorHover: 'white',
        calendarDayBgColorHover: '#283149',
        absentDayContentColor: '#FFC107',
        attendantDayContentColor: '#90ee90',
        invalidDayContentColor: '#FF5722',
        dayOffDayContentColor: '#ffffff'
      },
      //SingleUserRoleChip, SingleUserRole, FileAvatar
      role: {
        legalGuardian: '#03A5F4',
        staffMemberAccountant: '#903E97',
        staffMemberSecretary: '#ED8300',
        staffMemberTeacher: '#FC5A86',
        staffMemberDigitalDiary: '#20dfb2',
        staffMemberCanteenWorker: '#F77062',
        staffMemberPrincipal: '#253F5B',
        shareToMoreThenOneUser: '#FE5196'
      }
    }
  },
  typography: {
    fontFamily: 'League Spartan',
    fontSize: 14,
    fontWeightFeather: 100,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLarge: 600,
    fontWeightBold: 700,
    fontWeightBulky: 600,
    fontWeightBlack: 900,
    primaryText: {
      fontFamily: 'League Spartan',
      fontSize: 18,
      fontWeight: 700
    },
    formTitle: {
      fontWeight: 700,
      color: '#fff'
    },
    chipContent: {
      fontSize: 14,
      fontWeight: 700
    },
    //Typography custom variants
    MealsAndMoods: {
      fontFamily: 'League Spartan',
      fontSize: 12,
      fontWeight: 500,
      color: 'red'
    },
    //Typography custom variants
    medium_large: {
      fontFamily: 'League Spartan',
      fontSize: 16,
      fontWeight: 450
    },
    medium_bold: {
      fontFamily: 'League Spartan',
      fontSize: 18,
      fontWeight: 650
    }
  },
  layout: {
    drawerWidth: 350,
    smallContainerWidth: 1150
  }
};

export const wcagPalette = {
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: ({ theme }) => ({
          background: `${theme.palette.background.success}`,
          color: `#000`,
          fontSize: 16
        }),
        filledError: ({ theme }) => ({
          background: `${theme.palette.color.error}`,
          color: `#fff`,
          fontSize: 16
        }),
        filledWarning: ({ theme }) => ({
          background: `${theme.palette.color.warning}`,
          color: `#333`,
          fontSize: 16
        }),
        filledInfo: ({ theme }) => ({
          background: `${theme.palette.color.color13}`,
          color: `#333`,
          fontSize: 16
        })
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            whiteSpace: 'nowrap',
            background: '#A51D63',
            borderRadius: '46px',
            opacity: 1,
            padding: '10px 40px',
            boxShadow: 'none',
            height: 40,
            margin: 4,
            fontSize: 16,
            fontWeight: 600,
            textTransform: 'lowercase',
            '&:hover': {
              boxShadow: 'none',
              background: '#A51D63'
            },
            '&:disabled': {
              boxShadow: 'none',
              background: '#A51D63'
            }
          }
        },
        {
          props: { variant: 'outlinedContrast' },
          style: {
            whiteSpace: 'nowrap',
            border: '3px solid #444 !important',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            color: '#444',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            fontSize: 16,
            height: 40,
            '&:hover': {
              border: '3px solid #333 !important',
              color: '#333'
            },
            '&:disabled': {
              color: '#777',
              border: 'none !important',
              background: '#d4d4d4 !important',
              pointerEvents: 'unset', // allow :hover styles to be triggered
              cursor: 'not-allowed'
            }
          }
        },
        {
          props: { variant: 'clearText' },
          style: {
            whiteSpace: 'nowrap',
            borderRadius: '46px',
            opacity: 1,
            textTransform: 'lowercase',
            fontWeight: 700,
            color: '#202060',
            margin: 5,
            fontSize: 16,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              background: 'transparent',
              color: '##202060',
              fontWeight: 800
            }
          }
        },

        {
          props: { variant: 'text' },
          style: {
            border: 'none',
            opacity: 1,
            textTransform: 'capitalize',
            color: '#202060',
            margin: 5,
            background: 'transparent',
            padding: '10px 40px',
            height: 40,
            '&:hover': {
              background: 'transparent',
              color: '#202060',
              fontWeight: 800
            }
          }
        }
      ]
    }
  },
  palette: {
    gradient: {
      mainColor1: '#263378',
      mainColor2: '#5a1561'
    },
    text: {
      primary: '#444444',
      //AppDrawerNavItem
      secondary: '#444',
      contrast: '#FFD700',
      //GroupDetailsForm
      color1: '#444444',
      dark: '#222'
    },
    //Elements (borders etc) colors
    color: {
      wcag: '#202060',
      //SettlementsList, RecipientsDialog
      hidden: 'transparent',
      //GuardianDashboardPage CanteenWorkerDashboardPage DigitalDiaryTeacherDashboardPage CreateThreadButton, header ImportErrorsComponent ThreadHeader, SettlementCalculationDetails, SettlementDetails, ScheduledTransferTableToolbar
      primary: '#ffffff',
      contrast: '#444444',
      //SettlementsStatistics, UploadedMultimediaDetailsContent SettlementsList, Status, SuccessNotification
      success: '#365414',
      //SettlementsStatistics, SettlementsList
      cancel: ' #59142b ',
      info: '#0327f4',
      //SettlementsList, RichTextEditor GroupTeachersList SettlementsStatistics, Status
      error: '#d32f2f',
      warning: '#FF9800',
      //StyledToggleButtonGroup, StyledToggleButtonGroup, PrivateButton, SearchInputV2, ShareToRoleCard, AvailableRolesContent, SelectedRolesContent, ShareSearchBar, SingleUser, Expand, SelectedUsersContent, SharePersonListComponent, ReducedSharePersonListComponent
      disabled: '#1976d2', //'#333333',
      //SharePersonListComponent, SecretaryMenu TeacherMenu ReducedSharePersonListComponent, CreateThreadButton, TeacherSupportMenu
      color1: '#202060',
      //ReliefForm TeacherSupportMenu Meal Mood TeacherMenu SecretaryMenu
      color2: '#444444',
      //useSharePersonListComponentStyles, TeacherSupportMenu TeacherMenu SecretaryMenu
      color3: '#FAB378',
      //AdvancedInterests ManualHeader TeacherSupportMenu, GroupsAttendanceSummary SingleAuthorChip PersonAvatar SingleMemberChip SingleAlbumStyles SingleMultimedia SpecialButton importPage TeacherMenu RightBottomNotification, SecretaryMenu ThreadsList, ScheduledTransferTableToolbar, SearchInputV2Styles, SettlementDetailsDialog, FileAvatar, MobileSearch, PrivateFile, SingleFile, SingleUserRole, SingleUserRoleChip, TableView, Toolkit, ToolkitStyles, VirtualDiscTableHeader
      color4: '#1c2f47',
      //SettlementsList, ScheduledTransferTableToolbar, TeacherSupportMenu TeacherMenu SecretaryMenu
      color5: '#002459',
      //ScheduledTransferTableBody, PostRecipients TeacherSupportMenu TeacherMenu SecretaryMenu
      color6: '#D0180B',
      //TeacherSupportMenu, TeacherMenu SecretaryMenu SettlementDetailsDialog,SettlementCalculationDetails, ScheduledTransferTableBody, ReadIndicatorIcon
      color7: '#202060',
      //ReadIndicatorIcon, TeacherSupportMenu TeacherMenu SecretaryMenu
      color8: '#ef4b82',
      //FileAvatar, UnitAttendanceSummaryItem TeacherSupportMenu, TeacherMenu SecretaryMenu
      color9: '#9c27b0',
      //MessageBody, SettlementsImport, ImportErrorsComponent ShareFileHeaderFilesList, NotificationsHandler, ReducedSharePersonListComponent
      color10: '#D0180B',
      //authorizationIconStyle RegisteredPaymentsList, SettlementsImport
      color12: '#365414',
      //DrawerButton
      color13: '#29B6F6',
      //authorizationIconStyle RegisteredPaymentsList UnreadThreadsBadgeWrapper SingleLegalGuardianItem
      color14: '#7d2509',
      color16: 'gold',
      //ExportChildrenDialog
      color17: '#F44336',
      //SettlementsList
      color18: '#1a1c47',
      color19: '#8E3F98',
      //PostHeader PostHeader NumberingSchemeForm
      color20: '#FFF107',
      //ShareFileHeaderFilesList, RegisteredPaymentsList
      color21: '#007897',
      //SingleLegalGuardianItem cloneeventsdialog FormikTextField GroupsArchiveList GuardianApplicationsPage UnhandledErrorNotification, ThreadHeader, DetailedPaymentsSummaryPerChildReport, DuesActivitiesOverallReport, DuesCateringOverallReport, DuesDetailsOverallReport, DuesDetailsOverallReportXLS, DuesOtherOverallReport, DuesPaymentsReport, DuesStayOverallReport, RegisterStaffMemberPaymentDialog, SettlementDetailsDialog, YearAndMonthTemplate, GroupAndMonthTemplate
      color22: '#000000',
      //AttendanceDashboardPage
      color23: '#754047',
      //BoardPostCardDialogItem
      color24: '#2d7230',
      //SquareRadioButton
      color25: '#202060',
      color26: '#ffffff'
    },
    //Components backgrounds colors
    background: {
      // ManualHeader Toolkit, MobileToolkit, RichTooltip, UnhandledErrorNotification
      default: '#FFFFFF',
      error: '#D0180B',
      success: '#28a745',
      transparent: 'transparent',
      hover: '#EDEDED',
      //Toolkit
      shadow: '#000000',
      color1: '#4352A4',
      color2: '#903E97',
      color3: '#f7f7f7',
      color4: '#f7f7f7',
      color5: '#E5F6FD',
      color6: '#afafaf',
      color7: '#000',
      color8: '#C471F5',
      color9: '#FA71CD',
      color10: '#efefef',
      color11: '#CDEEFB'
    },
    //Everything that needs special color replacements when changing themes
    components: {
      messenger: {
        messengerText: '#333333',
        background: '#ffffff',
        inputBackground: '#F1F1F1'
      },
      fileIcons: {
        pdfFile: '#202060',
        spreadsheetFile: '#202060',
        textFile: '#202060',
        audioFile: '#202060',
        compressedFile: '#202060',
        imageFile: '#202060',
        presentationFile: '#202060',
        systemFile: '#202060',
        videoFile: '#202060',
        programmingFile: '#202060',
        defaultFile: '#202060',
        folder: '#202060'
      },
      welcomeScreen: {
        color1: 'rgba(255, 255, 255, 0.8)',
        color2: '#fff',
        color3: '#80ddea',
        color4: '#4d4d4d',
        color5: '#202020',
        color6: '#00bbd4'
      },
      wysiwyg: {
        borderError: '#D0180B',
        borderPass: '#F1F1F1'
      },
      calendar: {
        headerIconColor: '#d3d3d3',
        weekColor: '#202060',
        dayColor: '#202060',
        calendarBackground: '#eeeeee',
        calendarDay: '#FFFFFF',
        calendarDayColor: '#000',
        calendarWeekend: '#F5F5F5',
        disabled: '#F5F5F5',
        notCurrentMonth: '#E0E0E0',
        calendarDayColorHover: '#202060',
        calendarDayBgColorHover: '#E0E0E0',
        absentDayContentColor: '#000',
        attendantDayContentColor: '#000',
        invalidDayContentColor: '#000',
        dayOffDayContentColor: '#000'
      },
      //SingleUserRoleChip, SingleUserRole, FileAvatar
      role: {
        legalGuardian: '#202060',
        staffMemberAccountant: '#202060',
        staffMemberSecretary: '#202060',
        staffMemberTeacher: '#202060',
        staffMemberDigitalDiary: '#202060',
        staffMemberCanteenWorker: '#202060',
        staffMemberPrincipal: '#202060',
        shareToMoreThenOneUser: '#202060'
      }
    }
  }
};

export const getTheme = (mode) => {
  if (mode === themeTypes.light.value) {
    return {
      ...standardTheme,
      palette: {
        mode,
        ...standardTheme.palette
      }
    };
  }
  if (mode === themeTypes.dark.value) {
    return {
      ...darkTheme,
      palette: {
        mode,
        ...darkTheme.palette
      }
    };
  }
  if (mode === themeTypes.contrast.value) {
    return {
      ...standardTheme,
      components: {
        ...standardTheme.components,
        MuiButton: {
          variants: [...standardTheme.components.MuiButton.variants, ...wcagPalette.components.MuiButton.variants]
        },
        MuiAlert: {
          styleOverrides: {
            ...standardTheme.components.MuiAlert.styleOverrides,
            ...wcagPalette.components.MuiAlert.styleOverrides
          }
        }
      },
      palette: {
        mode: 'light',
        ...standardTheme.palette,
        ...wcagPalette.palette
      }
    };
  }
  return {
    ...standardTheme,
    palette: {
      mode,
      ...standardTheme.palette
    }
  };
};
