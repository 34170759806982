import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteStaffEventAsync,
  loadStaffEventsAtDayAsync,
  updateStaffEventAsync
} from '../../actions/staffCalendarActions';
import BoardPostsList from '../messageBoard/BoardPostsList';
import LoadingIndicatorWrapper from '../common/loading/LoadingIndicatorWrapper';
import EventDialog from '../StaffCalendar/Dialogs/EventDialog';
import { BoardPostModel } from '../../models/messageBoard/MessageBoardModels';
import { TimeModel } from '../../models/TimeModel';
import { Box } from '@mui/material';

const StaffCalendarDetailsContainer = (props) => {
  const { year, month, day } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStaffEventsAtDayAsync({ year, month, day }));
  }, []);

  const [eventDialog, setEventDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(new BoardPostModel());

  const eventDetails = useSelector((state) => state.staffCalendar.eventDetails);
  const loading = useSelector((state) => state.staffCalendar.loading);

  const handleOpen = (post) => {
    setCurrentEvent(
      Object.assign(new BoardPostModel().assign(post), {
        eventEndTime: post.eventEndTime.toMoment(),
        eventStartTime: post.eventStartTime.toMoment()
      })
    );
    setEventDialog(true);
  };

  const handleEdit = async (values) => {
    try {
      await dispatch(
        updateStaffEventAsync(
          Object.assign(new BoardPostModel().assign(values), {
            eventStartTime: new TimeModel().fromMoment(values.eventStartTime),
            eventEndTime: new TimeModel().fromMoment(values.eventEndTime),
            files: values.files.map((file) => file.id),
            internal: true
          }),
          new BoardPostModel().assign(values)
        )
      );
      setEventDialog(false);
      setCurrentEvent(new BoardPostModel());
    } catch (error) {
      setEventDialog(true);
    }
  };

  const handleDelete = (post) => {
    dispatch(deleteStaffEventAsync(post));
  };

  const handleLoadMore = () => {};

  const handleClose = () => {
    setEventDialog(false);
    setCurrentEvent(new BoardPostModel());
  };

  return (
    <Box>
      <LoadingIndicatorWrapper shouldUnmountChildrenWhenProcessing isProcessing={loading || !eventDetails}>
        <BoardPostsList
          posts={eventDetails.posts}
          pageCount={eventDetails.pageCount}
          hasNextPage={eventDetails.hasNextPage}
          customPermissions
          onEdit={handleOpen}
          onDelete={handleDelete}
          onLoadMoreClick={handleLoadMore}
          dontDisplayHistory={true}
          isStaffMemberBoard={true}
        />
      </LoadingIndicatorWrapper>
      {eventDialog && (
        <EventDialog
          initialValues={currentEvent}
          open={eventDialog}
          onSubmit={handleEdit}
          onClose={handleClose}
          title="Edytuj wydarzenie"
          inProgress={loading}
        />
      )}
    </Box>
  );
};

StaffCalendarDetailsContainer.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired
};

export default StaffCalendarDetailsContainer;
