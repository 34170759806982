import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import ExceededWorkingHoursWarningWrapper from './ExceededWorkingHoursWarningWrapper';
import { TimeModel } from '../../../models/TimeModel';
import { ReportAttendanceValidator } from '../../../models/attendance/AttendanceModelsValidator';
import moment from 'moment';
import { List, ListItem, ListItemText, ListItemIcon, Box, Typography, Grid } from '@mui/material';
import DialogForm from '../../forms/DialogForm';
import TimePicker from '../../common/TimePicker';
import PersonAvatar from '../../forms/PersonAvatar';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

class ReportPickUpDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickedUpAt: this.props.pickedUpAt,
      pickedUpBy: this.props.guardians.length ? this.props.guardians[0].id : null,
      errors: {}
    };
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleGuardianChange = this.handleGuardianChange.bind(this);
  }

  handleTimeChange(pickedUpAt) {
    this.setState({ pickedUpAt });
  }

  handleGuardianChange(pickedUpBy) {
    this.setState({ pickedUpBy });
  }

  renderGuardiansList(guardians) {
    return (
      <List>
        {guardians.map((guardian) => {
          return (
            <ListItem
              key={guardian.id}
              button
              selected={guardian.id === this.state.pickedUpBy}
              onClick={() => this.handleGuardianChange(guardian.id)}
              component="li"
              aria-label="Odbierz dziecko">
              <ListItemIcon>
                <PersonAvatar
                  initials
                  alt={guardian.firstName}
                  url={guardian.photoUrl}
                  firstName={guardian.firstName}
                  lastName={guardian.lastName}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${guardian.firstName} ${guardian.lastName} ${
                  guardian.relationship && `(${guardian.relationship})`
                }`}
                primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                secondary={`Numer dokumentu: ${guardian.documentNumber}`}
                secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  renderEmptyGuardiansListError() {
    return (
      <Box
        sx={{
          mt: 2,
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          display: 'flex',
          alignItems: 'center'
        }}>
        <PriorityHighOutlinedIcon
          sx={{
            color: (theme) => theme.palette.color.error
          }}
        />
        Brak osób przypisanych do dziecka
      </Box>
    );
  }

  render() {
    const { pupilFirstName, pupilLastName, pupilPhotoUrl, guardians, onSave, onCancel, isDialogOpen, isProcessing } =
      this.props;

    return (
      <DialogForm
        maxWidth="xs"
        header="Wydanie dziecka"
        onSave={() => onSave(this.state)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() =>
          new ReportAttendanceValidator().validate({
            droppedOffAt: moment(this.props.droppedOffAt),
            pickedUpAt: this.state.pickedUpAt
          })
        }
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="attendanceUi.pickUp"
        saveDisabled={_.isEmpty(guardians)}>
        <ExceededWorkingHoursWarningWrapper pickedUpAt={new TimeModel().parse(this.state.pickedUpAt)} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <PersonAvatar
            initials
            alt={pupilFirstName}
            url={pupilPhotoUrl}
            firstName={pupilFirstName}
            lastName={pupilLastName}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              display: 'block',
              pt: 1,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>{`${pupilFirstName} ${pupilLastName}`}</Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                mt: 2,
                alignSelf: 'flex-start'
              }}>
              <Typography
                sx={{
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}>
                Osoba odbierająca
              </Typography>
            </Box>
            {_.isEmpty(guardians) ? this.renderEmptyGuardiansListError() : this.renderGuardiansList(guardians)}
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TimePicker
              sx={{
                width: '128px',
                '&input': {
                  textAlign: 'center',
                  fontSize: 2
                }
              }}
              floatingLabelText="Godzina wydania"
              value={moment(this.state.pickedUpAt)}
              onChange={(e) => this.handleTimeChange(e)}
              name="picked"
            />
          </Grid>
        </Grid>
      </DialogForm>
    );
  }
}

ReportPickUpDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  pickedUpAt: PropTypes.object.isRequired,
  droppedOffAt: PropTypes.object.isRequired,
  pupilFirstName: PropTypes.string.isRequired,
  pupilLastName: PropTypes.string.isRequired,
  pupilPhotoUrl: PropTypes.string,
  guardians: PropTypes.array.isRequired
};

export default ReportPickUpDialog;
