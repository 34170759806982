import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import config from '../../../config/config';
import { Link } from 'react-router';
import { routePaths } from '../../../routePaths';
import ValidationSummary, { validationTypes } from '../../common/validation/ValidationSummary';
import TextField from '../../common/TextField';
import { Button, Typography, Box } from '@mui/material';
import govBadge from '../../../assets/svg/logingov.svg';
import * as authActions from '../../../actions/authActions';

const SignInForm = ({ signInData, onChange, onSignIn, errorMessage, isProcessing, features, errors }) => {
  const [url, setUrl] = useState();
  const [base64, setBase64] = useState();
  const formRef = useRef();

  useEffect(() => {
    if (base64) {
      formRef.current.submit();
      setTimeout(() => {}, 1000);
    }
  }, [base64]);

  const getData = () => {
    authActions.getToken().then((e) => {
      setUrl(e.url);
      setBase64(e.base64);
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', p: 3 }}>
      <Typography
        sx={{
          fontSize: 20,
          color: (theme) => theme.palette.text.primary,
          fontWeight: (theme) => theme.typography.fontWeightBold,
          mx: 6,
          textAlign: 'center'
        }}>
        Wprowadź swój adres email i hasło
      </Typography>

      {errorMessage ? (
        <ValidationSummary messages={errorMessage} title="Logowanie nie powiodło się" type={validationTypes.error} />
      ) : null}

      <form onSubmit={onSignIn} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ mx: 2 }}>
          <TextField
            disabled={isProcessing}
            floatingLabelText="Adres email"
            floatingLabelFixed={false}
            type="text"
            name="userName"
            value={signInData.userName}
            onChange={onChange}
            fullWidth={true}
            autoFocus={true}
            errorText={errors.userName}
          />
          <TextField
            disabled={isProcessing}
            floatingLabelText="Hasło"
            floatingLabelFixed={false}
            type="password"
            name="password"
            value={signInData.password}
            onChange={onChange}
            fullWidth={true}
            errorText={errors.password}
          />
        </Box>
        <Button
          variant="contained"
          aria-label={isProcessing ? 'Logowanie...' : 'Zaloguj'}
          type="submit"
          disabled={isProcessing}
          fullWidth
          sx={{ my: 1, width: 130 }}>
          {isProcessing ? 'Logowanie...' : 'Zaloguj'}
        </Button>
      </form>
      {features.govIntegration && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <Typography align="center" sx={{ fontSize: 16, color: (theme) => theme.palette.text.primary, my: 2 }}>
            lub zaloguj się przez węzeł krajowy:
          </Typography>

          <Box sx={{ p: '0 40px' }}>
            {base64 && url && (
              <form ref={formRef} action={url} method="post">
                <input type="HIDDEN" value={base64} name="SAMLRequest" />
              </form>
            )}
            <Button
              sx={{
                mb: 1,
                mx: 0,
                width: 130,
                height: 45,
                background: `url(${govBadge})`,
                backgroundColor: 'white',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
              variant="outlinedContrast"
              disabled={isProcessing}
              onClick={() => getData()}
            />
          </Box>
        </Box>
      )}
      <Link to={routePaths.passwordRecovery}>
        <Typography sx={{ color: (theme) => theme.palette.text.primary, ':hover': { textDecoration: 'none' } }}>
          Zapomniałem hasła
        </Typography>
      </Link>
    </Box>
  );
};

SignInForm.propTypes = {
  signInData: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  onSignIn: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired
};

export default SignInForm;
