import PropTypes from 'prop-types';
import React from 'react';
import { Box, Divider, Grid, ListItem, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { tryConvertToNumber } from '../../../../utils/numberConverters';
import { NumberingSchemeModel } from '../../../../models/configuration/numberingSchemes/NumberingSchemeModels';
import Strong from '../../../common/Strong';

//v4
import TextField from '../../../common/TextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const NumberingSchemeForm = ({ scheme, onChange, errors }) => {
  const theme = useTheme();
  const displayExample = () => NumberingSchemeModel.getNumberExample(scheme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'space-around' }}>
      <Grid sx={{ width: isMobile ? '100%' : '45%' }}>
        <FieldsetWrapper legend="Pola serii numeracji">
          <TextField
            hintText="Nazwa numeracji"
            floatingLabelText="Nazwa numeracji"
            floatingLabelFixed={false}
            name="name"
            value={scheme.name}
            errorText={errors.name}
            onChange={onChange}
            fullWidth={true}
          />
          <TextField
            hintText="Wzorzec"
            floatingLabelText="Wzorzec"
            floatingLabelFixed={false}
            name="template"
            value={scheme.template}
            errorText={errors.template}
            onChange={onChange}
            fullWidth={true}
          />
          <TextField
            hintText="Kolejny numer"
            floatingLabelText="Kolejny numer"
            floatingLabelFixed={false}
            name="nextNumber"
            value={scheme.nextNumber}
            errorText={errors.nextNumber}
            onChange={onChange}
            onBlur={(e) => {
              onChange({ target: { name: 'nextNumber', value: tryConvertToNumber(e.target.value) } });
            }}
            fullWidth={true}
          />
        </FieldsetWrapper>
        <Box
          variant="body1"
          sx={{
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.augmentColor.color20}`,
            my: 2,
            p: 2
          }}>
          Podgląd: <Strong>{displayExample()}</Strong>
        </Box>
      </Grid>
      <Grid sx={{ width: isMobile ? '100%' : '45%' }}>
        <Box
          sx={{
            background: theme.palette.background.default,
            p: 2
          }}>
          <Typography>
            Wzorzec numeracji może być stworzony z wykorzystaniem kilku specjalnych znaczników. Zawsze wymagane jest
            podanie znacznika{' '}
            <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightLight }}>
              {'{numer}'}
            </Typography>
            .
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography>Dostępne znaczniki:</Typography>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightLight }}>
              {'{numer}'}
            </Typography>{' '}
            - kolejny numer
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightLight }}>
              {'{0000:{numer}}'}
            </Typography>{' '}
            - kolejny numer z&nbsp;zerami wiodącymi
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightLight }}>
              {'{rok}'}
            </Typography>{' '}
            - aktualny rok
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightLight }}>
              {'{miesiąc}'}
            </Typography>{' '}
            - aktualny miesiąc
          </ListItem>
        </Box>
      </Grid>
    </Stack>
  );
};

NumberingSchemeForm.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default NumberingSchemeForm;
