import React from 'react';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import SenderMessageContainer from './containers/SenderMessageContainer';

const SenderMessagesPage = () => {
  return (
    <LoadingRenderWrapper>
      <SenderMessageContainer />
    </LoadingRenderWrapper>
  );
};

export default SenderMessagesPage;
