import { browserHistory } from 'react-router';
import api from '../api/PinApi';
import { routePaths } from '../routePaths';
import * as types from './actionTypes';
// import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function cancelAttendanceRegistration(message, severity) {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_AUTHENTICATED_PIN_STATE });
    browserHistory.push({ pathname: routePaths.pinKeypad, state: { message, severity } });
  };
}

export function getEntries(pin) {
  return (dispatch) => {
    return api
      .getEntries(pin)
      .then((data) => {
        dispatch({ type: types.AUTHENTICATE_PIN_SUCCESS, entries: data });
        browserHistory.push(routePaths.pinEntries);
      })
      .catch((error) => {
        logger.error(error);
        throw error;
        // dispatch(notificationActions.showError('Nie udało się uwierzytelnić podanym numerem PIN'));
        // return logger.error(error);
      });
  };
}
