import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from '../common/EmptyState';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import PersonAvatar from '../forms/PersonAvatar';
import makeStyles from '@mui/styles/makeStyles';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import {
  Checkbox,
  Divider,
  Grid,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';

const useStyles = makeStyles(() => ({
  font: {
    fontSize: 20
  }
}));

const getActionDescription = (entry) => {
  if (entry.wasDroppedOff) return 'Rejestracja wyjścia';
  return 'Rejestracja wejścia';
};

const LinkedChildren = ({ onCheck, entries, selectedEntries }) => {
  const cl = useStyles();

  const enabledEntries = entries.filter((entry) => !(entry.wasDroppedOff && entry.wasPickedUp));
  const disabledEntries = entries.filter((entry) => entry.wasDroppedOff && entry.wasPickedUp);

  return (
    <Paper sx={{ p: 2 }}>
      {entries.length === 0 ? (
        <EmptyState
          className={cl.font}
          message="Brak przypisanych dzieci lub upoważnienie do odbioru wygasło"
          contrast
        />
      ) : null}
      {enabledEntries && enabledEntries.length !== 0 && (
        <List>
          {enabledEntries.map((entry) => {
            const isSelected = selectedEntries.includes(entry.id);
            return (
              <ListItem key={entry.id} button onClick={() => onCheck(entry.id, isSelected)}>
                <ListItemAvatar>
                  <PersonAvatar
                    initials
                    alt={entry.firstName}
                    url={entry.photoUrl}
                    firstName={entry.firstName}
                    lastName={entry.lastName}
                  />
                </ListItemAvatar>
                <ListItemText
                  disableTypography={true}
                  primary={
                    <Typography fontWeight="medium" className={cl.font}>
                      {entry.firstName} {entry.lastName}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction sx={{ display: 'flex' }}>
                  {isSelected && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      {entry && entry.wasDroppedOff ? (
                        <VerticalAlignTopIcon fontSize="large" />
                      ) : (
                        <VerticalAlignBottomIcon fontSize="large" />
                      )}
                      <Typography className={cl.font} sx={{ ml: 1 }}>
                        {getActionDescription(entry)}
                      </Typography>
                    </Grid>
                  )}

                  <Checkbox
                    edge="end"
                    onChange={() => onCheck(entry.id, isSelected)}
                    checked={isSelected}
                    inputProps={{ 'aria-label': 'Zaznacz' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      {enabledEntries.length !== 0 && disabledEntries.length !== 0 ? <Divider /> : null}
      {disabledEntries && disabledEntries.length !== 0 && (
        <List sx={{ fontSize: 20 }}>
          {disabledEntries.map((entry) => {
            return (
              <ListItem key={entry.id} disabled={true}>
                <ListItemAvatar>
                  <PersonAvatar
                    alt={entry.firstName}
                    url={entry.photoUrl}
                    firstName={entry.firstName}
                    lastName={entry.lastName}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={cl.font}
                  primary={`${entry.firstName} ${entry.lastName}`}
                  secondary="W dniu dzisiejszym zgłoszono już wejście i wyjście dziecka"
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </Paper>
  );
};

LinkedChildren.propTypes = {
  entries: PropTypes.array.isRequired,
  onCheck: PropTypes.func.isRequired,
  selectedEntries: PropTypes.array.isRequired
};

export default LinkedChildren;
