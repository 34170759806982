import * as types from './actionTypes';

export function clearMessages() {
  return { type: types.CLEAR_NOTIFICATIONS };
}

export function showError(error) {
  return { type: types.SHOW_ERROR_NOTIFICATION, message: error };
}

export function showSuccess(message) {
  return { type: types.SHOW_SUCCESS_NOTIFICATION, message };
}

export function showWarning(message) {
  return { type: types.SHOW_WARNING_NOTIFICATION, message };
}

export function startRightBottomNotification(uuid, target, data) {
  return { type: types.RIGHT_BOTTOM_NOTIFICATION_START, payload: { uuid, target, data } };
}

export function updateRightBottomNotification(uuid, data) {
  return { type: types.RIGHT_BOTTOM_NOTIFICATION_UPDATE, payload: { uuid, data } };
}

export function removeRightBottomNotification(uuid) {
  return { type: types.RIGHT_BOTTOM_NOTIFICATION_REMOVE, payload: { uuid } };
}
