import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as childrenActions from '../../../actions/childrenActions';
import PropTypes from 'prop-types';
import { Box, Paper, Typography, LinearProgress } from '@mui/material';

const AuthorizedBySign = (props) => {
  useEffect(() => {
    if (props.location.query.status === 'ok') {
      const { child, guardian } = props.location.query;
      props.actions.completeAuthorizationBySign(child, guardian);
    } else {
      const { child } = props.location.query;
      props.actions.completeAuthorizationBySignFailure(child);
    }
  }, []);
  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Typography variant="body1" align="center">
          Proszę czekać, podpis jest przetwarzany...
        </Typography>
        <Box sx={{ my: 2, mx: 0 }}>
          <LinearProgress mode="indeterminate" />
        </Box>
      </Paper>
    </Box>
  );
};

AuthorizedBySign.propTypes = {
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(childrenActions, dispatch)
});

export default connect(null, mapDispatchToProps)(AuthorizedBySign);
