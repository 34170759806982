import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@mui/material';
import ConfirmationIconButton from '../../forms/buttons/ConfirmationIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MovieIcon from '@mui/icons-material/Movie';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { handleDownloadFile } from '../../../utils/handleDownloadFile.js';

const videoMimeTypes = [
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv'
];

const getFileIcon = (mimeType) => {
  if (videoMimeTypes.some((t) => t === mimeType)) return <MovieIcon />;
  return <AttachmentIcon />;
};

const Files = ({ files, onRemoveFile }) => {
  const handleOnConfirmDeleteFile = (file, event) => {
    onRemoveFile(file);
    event.stopPropagation();
  };

  return (
    <>
      {files.length > 0 ? (
        <List>
          {files.map((file) => (
            <ListItem
              sx={{ cursor: 'pointer' }}
              key={file.id}
              component="li"
              aria-label={`Pobierz załącznik ${file.name}`}
              onClick={() => handleDownloadFile(file)}>
              <ListItemIcon>{getFileIcon(file.mimeType)}</ListItemIcon>
              <ListItemText
                primary={file.name}
                primaryTypographyProps={{ sx: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
              />
              {onRemoveFile && (
                <ListItemSecondaryAction>
                  <ConfirmationIconButton
                    confirmationTitle="Usuń załącznik"
                    actionLabel="usuń"
                    confirmationText="Czy na pewno chcesz usunąć załącznik?"
                    confirmLabel="Usuń"
                    onConfirm={(event) => handleOnConfirmDeleteFile(file, event)}
                    onCancel={() => {}}>
                    <DeleteIcon />
                  </ConfirmationIconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      ) : null}
    </>
  );
};

Files.propTypes = {
  files: PropTypes.array.isRequired,
  onRemoveFile: PropTypes.func
};

export default Files;
