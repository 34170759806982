import { Box } from '@mui/material';
import Checkbox from '../../common/Checkbox';
import PropTypes from 'prop-types';
import YearMonthAndGroupPickerTemplate from './common/YearMonthAndGroupPickerTemplate';

const ChildrenAttendanceInDaysByGroupReport = ({
  groupId,
  month,
  year,
  groups,
  addIncorrectAbsencesData,
  onChange,
  info,
  errors
}) => {
  return (
    <Box>
      <YearMonthAndGroupPickerTemplate
        groupId={groupId}
        groups={groups}
        year={year}
        month={month}
        onChange={onChange}
        errors={errors}
        legend="obecności dzieci w danym dniu"
        info={info}
      />
      <Checkbox
        label="Wyświetl nieobecności zgłoszone po terminie"
        defaultChecked={addIncorrectAbsencesData}
        onCheck={(event, isChecked) => onChange({ target: { name: 'addIncorrectAbsencesData', value: isChecked } })}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

ChildrenAttendanceInDaysByGroupReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  addIncorrectAbsencesData: PropTypes.bool.isRequired,
  info: PropTypes.string
};

export default ChildrenAttendanceInDaysByGroupReport;
