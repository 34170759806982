import PropTypes from 'prop-types';
import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';

export const downloadAuthorizationDocMenuItem = (isAuthorized, onTouch, key) => {
  return !isAuthorized ? (
    <MuiMenuItem key={key} onClick={() => onTouch()}>
      <ListItemIcon>
        <AttachmentIcon />
      </ListItemIcon>
      <ListItemText primary="Pobierz upoważnienie(.pdf)" />
    </MuiMenuItem>
  ) : null;
};

const MuiMenuItem = React.forwardRef((props, ref) => {
  return <MenuItem ref={ref} {...props} />;
});

downloadAuthorizationDocMenuItem.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  onTouch: PropTypes.func,
  key: PropTypes.any
};
