import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class LoadingRenderWrapper extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.onLoaded && this.props.isLoading && !nextProps.isLoading) {
      this.props.onLoaded();
    }
  }

  render() {
    return this.props.isLoading ? null : this.props.children;
  }
}

LoadingRenderWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onLoaded: PropTypes.func,
  children: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading
  };
}

export default connect(mapStateToProps)(LoadingRenderWrapper);
