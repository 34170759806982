import { Collapse, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import ChildMealsCalendar from '../../common/children/meals/ChildMealsCalendar';
import { useSelector } from 'react-redux';
import MealsDetailsDialog from '../../meals/MealsDetailsDialog';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  listItemWithGap: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const ChildMealsCalendarCard = ({
  isInitiallyExpanded,
  pupil,
  onDateChange,
  meals,
  allMeals,
  allCategories,
  userRole,
  isLoading
}) => {
  const cl = useStyles();
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);
  const [isMealDetailsDialogOpen, setIsMealDetailsDialogOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);
  const daysOff = useSelector((state) => state.configuration.daysOff);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleMealDetailsOpen = (day) => {
    setCurrentDay(day);
    setIsMealDetailsDialogOpen(true);
  };

  return (
    <Paper elevation={1} sx={{ p: 1, mb: 2 }}>
      <ListItem className={cl.listItemWithGap} sx={{ p: 0, m: 0, display: 'flex', alignItems: 'center' }}>
        <ListItemButton onClick={() => handleOpen()}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Obecności na posiłkach
              </Typography>
            }
          />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isOpen}>
        <ChildMealsCalendar
          markedDays={meals.calendar}
          daysOff={daysOff}
          onDaySelected={() => {}}
          onDateChange={onDateChange}
          onDetailsOpen={(day) => handleMealDetailsOpen(day)}
          isLoading={isLoading}
        />
        <MealsDetailsDialog
          title="Zarządzaj posiłkami dziecka"
          meals={meals.calendar}
          allMeals={allMeals}
          allCategories={allCategories}
          pupil={pupil}
          isDialogOpen={isMealDetailsDialogOpen}
          date={currentDay !== null && moment(currentDay.date).format('YYYY-MM-DD')}
          onCancel={() => setIsMealDetailsDialogOpen(false)}
          userRole={userRole}
        />
      </Collapse>
    </Paper>
  );
};

ChildMealsCalendarCard.propTypes = {
  isInitiallyExpanded: PropTypes.bool,
  pupil: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  meals: PropTypes.object.isRequired,
  allMeals: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  userRole: PropTypes.string,
  isLoading: PropTypes.bool
};

export default ChildMealsCalendarCard;
