import PropTypes from 'prop-types';
import SelectField from '../../common/SelectField';
import { MenuItem } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const AddMealToCategoryForm = ({ mealsCategories, choosedCategory, setChoosedCategory }) => {
  const handleChoosedCategoryChange = (event) => {
    setChoosedCategory(event.target.value);
  };

  return (
    <FieldsetWrapper legend="Pola dla wyboru kategorii przypisania posiłku">
      <SelectField
        contrast
        value={choosedCategory}
        onChange={(e) => {
          handleChoosedCategoryChange(e);
        }}
        floatingLabelText="Wybierz kategorię">
        {mealsCategories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </SelectField>
    </FieldsetWrapper>
  );
};

export default AddMealToCategoryForm;

AddMealToCategoryForm.propTypes = {
  mealsCategories: PropTypes.array.isRequired,
  choosedCategory: PropTypes.object,
  setChoosedCategory: PropTypes.func
};
