import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import { AttributeSetModel } from './AttributeSetModel';

export class AttributeSetModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = AttributeSetModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      objectType: (value) => check.isEmpty(value) && 'Atrybuty muszą być przypisane'
    };
  }
}
