import profileHistoryApi from '../api/ProfileHistoryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadProfileHistoryAsync(id, params) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_PROFILE_HISTORY });
    return profileHistoryApi
      .getProfileHistory(id, params)
      .then((profileHistory) => dispatch({ type: types.LOAD_PROFILE_HISTORY_SUCCESS, profileHistory }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy zdarzeń'));
        dispatch({ type: types.LOAD_PROFILE_HISTORY_FAILURE, profileHistory: { data: [], rowNumber: '0' } });
        logger.error(error);
      });
  };
}
