import PropTypes from 'prop-types';
import React from 'react';
import { Mood as MoodTypes } from '../../constants/moodTypes';
import Typography from '@mui/material/Typography';

import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const generateIcon = (type) => {
  switch (type) {
    case 'calm':
      return <SentimentSatisfiedAltOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'annoyed':
      return <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'sad':
      return <SentimentDissatisfiedIcon sx={{ fontSize: 30 }} />;

    case 'happy':
      return <SentimentVerySatisfiedOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'tearful':
      return <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: 30 }} />;

    default:
      break;
  }
  return null;
};

const Mood = ({ mood, noDescription }) => (
  <Typography sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', ml: 1 }}>
    {generateIcon(MoodTypes[mood].value)}{' '}
    {noDescription ? null : (
      <Typography
        variant="MealsAndMoods"
        sx={{
          color: (theme) => theme.palette.color.color2,
          ml: 1
        }}>
        {MoodTypes[mood].name}
      </Typography>
    )}
  </Typography>
);

Mood.propTypes = {
  mood: PropTypes.string,
  noDescription: PropTypes.bool
};

export default Mood;
