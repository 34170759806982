import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import GalleryContainer from './Containers/GalleryContainer';

const GalleryPage = ({ location }) => {
  return (
    <LoadingRenderWrapper>
      <GalleryContainer location={location} />
    </LoadingRenderWrapper>
  );
};

GalleryPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default GalleryPage;
