import staffMemberRoles from '../../../constants/staffMemberRoles';
import { mapStaffRoleToUserRole } from '../../../utils/mapStaffRoleToUserRole';

export const prepareStaffMemberList = (role, array) => {
  switch (role) {
    case staffMemberRoles.teacher.value:
      return {
        title: 'Nauczyciele',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    case staffMemberRoles.principal.value:
      return {
        title: 'Dyrektorzy',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    case staffMemberRoles.canteenWorker.value:
      return {
        title: 'Pracownicy stołówki',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    case staffMemberRoles.accountant.value:
      return {
        title: 'Księgowi',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    case staffMemberRoles.secretary.value:
      return {
        title: 'Pracownicy sekretariatu',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    case staffMemberRoles.digitalDiary.value:
      return {
        title: 'Nauczyciele zajęć dodatkowych',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    case staffMemberRoles.teacherSupport.value:
      return {
        title: 'Pomoc nauczyciela',
        type: mapStaffRoleToUserRole(role),
        users: array
      };
    default:
      return undefined;
  }
};
