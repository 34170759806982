import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _ from 'lodash';
import { Box, MenuItem } from '@mui/material';
import RichTextEditor from '../../../forms/richTextEditor/RichTextEditor';
import EmptyState from '../../../common/EmptyState';
import TextField from '../../../common/TextField';
import SelectField from '../../../common/SelectField';

const ChildDiagnoseDialog = (props) => {
  const handleTitleChange = (event) => {
    const newObject = { ...props.childDiagnose };
    newObject.title = event.target.value;
    props.handleDiagnoseChange(newObject);
  };

  const handleSuperText = (value) => {
    const newObject = { ...props.childDiagnose };
    newObject.content = value;
    props.handleDiagnoseChange(newObject);
  };

  const newSortedArray = _.sortBy(props.diagnosticSchemesArray, (s) => s.title.toLowerCase());

  const [counter, setCounter] = useState(1);

  return (
    <Box>
      <TextField
        floatingLabelText="Nazwa diagnozy*"
        autoFocus={true}
        name="title"
        value={props.childDiagnose.title}
        onChange={handleTitleChange}
        fullWidth={true}
        errorText={!!props.childDiagnose.diagnoseId ? null : props.errors.title}
        disabled={!!props.childDiagnose.diagnoseId}
      />
      {props.diagnosticSchemesArray.length ? (
        <SelectField
          contrast
          value={props.chosenDiagnosticTemplate}
          onChange={(e) => {
            props.handleChosenDiagnosticTemplate(e);
            setCounter((prev) => prev + 1);
          }}
          floatingLabelText="Wybierz przygotowany schemat"
          id="scheme"
          disabled={!!props.childDiagnose.diagnoseId}
          sx={{ width: '50%', mb: 1 }}>
          {newSortedArray.map((item) => {
            return (
              <MenuItem value={item.id} key={item.id}>
                {item.title}
              </MenuItem>
            );
          })}
        </SelectField>
      ) : (
        <EmptyState message="Nie zdefiniowano szablonów diagnoz" />
      )}
      <RichTextEditor
        key={`${counter}`}
        onChange={handleSuperText}
        initialText={props.childDiagnose.content}
        readOnly={!!props.childDiagnose.diagnoseId}
      />
    </Box>
  );
};

ChildDiagnoseDialog.propTypes = {
  diagnosticSchemesArray: PropTypes.array,
  errors: PropTypes.object,
  childDiagnose: PropTypes.object,
  handleDiagnoseChange: PropTypes.func,
  chosenDiagnosticTemplate: PropTypes.string,
  handleChosenDiagnosticTemplate: PropTypes.func
};

export default ChildDiagnoseDialog;
