import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ArchivedMealModel } from '../models/meals/MealModels';

export default function ArchivedMealsReducer(state = initialState.archivedMeals, action) {
  switch (action.type) {
    case types.LOAD_ARCHIVED_MEALS_SUCCESS: {
      return action.archivedMeals.map((meal) => new ArchivedMealModel().assign(meal));
    }
    default:
      return state;
  }
}
