import React, { useState } from 'react';
import { Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
// import FilterBar from '../../common/FilterBar';
import _ from 'lodash';
// import EmptyState from '../../common/EmptyState';
import PropTypes from 'prop-types';
import FilterBar from '../common/FilterBar';
import EmptyState from '../common/EmptyState';

const styles = {
  useTableStyles: {
    minWidth: 650
  },
  cellHeaderStyles: {
    px: 2,
    width: 100,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: 13
  },
  useCellBodyStyles: {
    width: 100,
    fontSize: 13,
    padding: '0px 12px'
  }
};

const ChildrenPicker = ({ allPupils, exclude, onChange, name }) => {
  const [selectablePupils] = useState(allPupils.filter((x) => !exclude.includes(x.id)));
  const [selectedPupils, setSelectedPupils] = useState([]);
  const [filteredSelectablePupils, setFilteredSelectablePupils] = useState(_.cloneDeep(selectablePupils));

  const handleCheck = (pupil) => {
    const tempTable = selectedPupils.some((x) => x.id === pupil.id)
      ? selectedPupils.filter((x) => x.id !== pupil.id)
      : [...selectedPupils, pupil];
    setSelectedPupils(tempTable);
    onChange(tempTable);
  };

  return (
    <>
      <FilterBar
        contrast
        itemsToFilter={selectablePupils}
        onFilter={(filteredList) => setFilteredSelectablePupils(_.cloneDeep(filteredList))}
        filter={(pupil, keyword) =>
          pupil.lastName.toLowerCase().includes(keyword) || pupil.firstName.toLowerCase().includes(keyword)
        }
      />
      {selectablePupils.length > 0 && filteredSelectablePupils.length > 0 && (
        <Table
          size="small"
          sx={
            (styles.useTableStyles,
            {
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            })
          }
          aria-label="Lista nieprzepisanych dzieci">
          <caption>Lista nieprzepisanych dzieci</caption>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cellHeaderStyles} align="left">
                Nazwisko
              </TableCell>
              <TableCell sx={styles.cellHeaderStyles} align="left">
                Imię
              </TableCell>
              <TableCell sx={styles.cellHeaderStyles} align="left">
                Data Urodzenia
              </TableCell>
              <TableCell sx={({ background: 'red' }, styles.cellHeaderStyles)} align="left">
                Pesel
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSelectablePupils.map((pupil, rowIndex) => {
              return (
                <TableRow
                  key={pupil.id}
                  sx={{ '&:hover': { background: (theme) => theme.palette.background.hover } }}
                  onClick={() => handleCheck(pupil)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) handleCheck(pupil);
                  }}
                  tabIndex={0}>
                  {[pupil.lastName, pupil.firstName, moment(pupil.birthDate).format('L'), pupil.personalId].map(
                    (item, index) => (
                      <TableCell
                        key={index}
                        sx={(styles.useCellBodyStyles, { pl: index === 0 ? 0 : undefined })}
                        align="left">
                        {index === 0 && (
                          <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': `wybierz wiersz ${rowIndex}` }}
                            checked={selectedPupils.some((x) => x.id === pupil.id)}
                            tabIndex={-1}
                          />
                        )}
                        <Typography
                          component="span"
                          varaint="h6"
                          sx={{
                            wordBreak: 'break-word',
                            width: index === 0 ? '100px' : 'undefined',
                            display: index === 0 ? 'inline-block' : 'undefined',
                            fontWeight: (theme) => theme.typography.fontWeightMedium
                          }}>
                          {item}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {selectablePupils.length > 0 && filteredSelectablePupils.length === 0 && (
        <EmptyState message="Brak wyników wyszukiwania" contrast />
      )}
      {selectablePupils.length === 0 && filteredSelectablePupils.length === 0 && (
        <EmptyState message="Brak dzieci przypisanych do diety" contrast />
      )}
    </>
  );
};

ChildrenPicker.propTypes = {
  allPupils: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ChildrenPicker;
