import PropTypes from 'prop-types';
import React from 'react';
import CalculationMethodOptions from './CalculationMethodOptions';
import ReportTypesOptions from './ReportTypesOptions';
import PaymentsOptions from './PaymentsOptions';
import { ChargingPrecisions } from '../../../../constants/chargingPrecisions';
import { ChargingPrecisionRounding } from '../../../../constants/chargingPrecisionsRounding';
import SelectField from '../../../common/SelectField';
import {
  Box,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import TimePicker from '../../../common/TimePicker';
import { tryConvertToNumber, tryConvertToInteger } from '../../../../utils/numberConverters';
import { NonChargeableMinutesMode } from '../../../../models/configuration/chargingSchemes/ChargingSchemeModels';
import { TimeModel } from '../../../../models/TimeModel';
import Toggle from '../../../common/Toggle';

//v4
import TextField from '../../../common/TextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const getArticleByValue = (value) => {
  const precision = Object.values(ChargingPrecisions).find((x) => x.value === value);
  return precision ? precision.article : 'neuter';
};

const HourlyChargingScheme = ({ scheme, onChange, errors }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <TextField
        hintText="Nazwa"
        floatingLabelText="Nazwa*"
        floatingLabelFixed={false}
        autoFocus={true}
        name="name"
        value={scheme.name || ''}
        onChange={onChange}
        fullWidth={true}
        errorText={errors.name}
      />
      <CalculationMethodOptions
        defaultSelected={scheme.calculationMethod}
        onSelected={(value) => onChange({ target: { value, name: 'calculationMethod' } })}
      />
      <ReportTypesOptions
        defaultSelected={scheme.reportType}
        onSelected={(value) => onChange({ target: { value, name: 'reportType' } })}
        isDisabled={true}
      />
      <TextField
        floatingLabelText="Stawka (zł/godzinę)*"
        floatingLabelFixed={false}
        name="rate"
        value={scheme.rate || ''}
        onChange={onChange}
        onBlur={(e) => {
          onChange({ target: { name: 'rate', value: tryConvertToNumber(e.target.value) } });
        }}
        fullWidth={true}
        errorText={errors.rate}
      />
      <FieldsetWrapper legend="Naliczanie" invisible={false} labelStyle={{ color: theme.palette.color.color22 }}>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          <SelectField
            contrast
            id="chargingPrecisionRounding"
            value={scheme.chargingPrecisionRounding}
            floatingLabelText="Typ"
            sx={{ mr: 2 }}
            onChange={(e) => {
              onChange({ target: { name: 'chargingPrecisionRounding', value: e.target.value } });
            }}
            errorText={errors.chargingPrecisionRounding}>
            {Object.values(ChargingPrecisionRounding).map((precision) => {
              return (
                <MenuItem key={precision.value} value={precision.value}>
                  {precision.name[getArticleByValue(scheme.chargingPrecision)]}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            id="chargingPrecision"
            value={scheme.chargingPrecision}
            floatingLabelText="Czas"
            onChange={(e) => {
              onChange({ target: { name: 'chargingPrecision', value: e.target.value } });
            }}
            errorText={errors.chargingPrecision}>
            {Object.values(ChargingPrecisions).map((precision) => {
              return (
                <MenuItem key={precision.value} value={precision.value}>
                  {precision.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </Box>
        <Toggle
          label="Naliczaj opłatę każdorazowo po przekroczeniu określonego progu czasu w ramach dnia"
          labelPosition="right"
          defaultToggled={scheme.chargingPrecisionCalculatePerDay}
          onToggle={(event, value) => onChange({ target: { name: 'chargingPrecisionCalculatePerDay', value } })}
        />
        {scheme.chargingPrecisionCalculatePerDay && (
          <Toggle
            label="Naliczaj przekroczenia każdorazowo przed rozpoczęciem czasu darmowego oraz po jego zakończeniu"
            labelPosition="right"
            defaultToggled={scheme.chargingPrecisionCalculatePerDaySeparatelyBeforeAndAfterNonChargeable}
            onToggle={(event, value) =>
              onChange({
                target: { name: 'chargingPrecisionCalculatePerDaySeparatelyBeforeAndAfterNonChargeable', value }
              })
            }
          />
        )}
      </FieldsetWrapper>
      <Typography sx={{ mt: 2 }}>Darmowy pobyt</Typography>
      <FieldsetWrapper
        legend={
          <ListSubheader component="span" sx={{ pl: 0, fontSize: 12, color: (theme) => theme.palette.color.contrast }}>
            Sposób rozliczania darmowego pobytu
          </ListSubheader>
        }
        invisible={false}>
        <RadioGroup
          row
          aria-label="Sposób rozliczania darmowego pobytu"
          name="nonChargeableInMinutes"
          value={scheme.nonChargeableInMinutes || false}
          onClick={(e) => {
            return onChange({ target: { name: 'nonChargeableInMinutes', value: e.target.value === 'true' } });
          }}>
          <FormControlLabel
            control={<Radio />}
            key={false}
            label="Przedział czasowy"
            value={false}
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Radio />}
            key={true}
            label="Dzienny limit darmowych minut"
            value={true}
            labelPlacement="end"
          />
        </RadioGroup>
      </FieldsetWrapper>
      <FieldsetWrapper
        legend={
          <ListSubheader component="span" sx={{ pl: 0, fontSize: 12, color: (theme) => theme.palette.color.contrast }}>
            Sposób rozliczania darmowych minut
          </ListSubheader>
        }
        invisible={false}
        sx={{ width: '100%' }}>
        {scheme.nonChargeableInMinutes && (
          <RadioGroup
            row
            aria-label="Sposób rozliczania darmowych minut"
            name="nonChargeableMinutesMode"
            value={scheme.nonChargeableMinutesMode || NonChargeableMinutesMode.declaredBased.value}
            onClick={(e) => {
              onChange({ target: { name: 'nonChargeableMinutesMode', value: e.target.value } });
            }}>
            <FormControlLabel
              control={<Radio />}
              key={NonChargeableMinutesMode.declaredBased.value}
              label={NonChargeableMinutesMode.declaredBased.name}
              value={NonChargeableMinutesMode.declaredBased.value}
              labelPlacement="end"
            />
            <FormControlLabel
              control={<Radio />}
              key={NonChargeableMinutesMode.actualBased.value}
              label={NonChargeableMinutesMode.actualBased.name}
              value={NonChargeableMinutesMode.actualBased.value}
              labelPlacement="end"
            />
          </RadioGroup>
        )}
        {scheme.nonChargeableInMinutes ? (
          <TextField
            hintText="Ilość darmowych minut"
            floatingLabelText="Ilość darmowych minut"
            floatingLabelFixed={false}
            name="nonChargeableMinutesAmount"
            value={scheme.nonChargeableMinutesAmount || ''}
            onChange={onChange}
            onBlur={(e) => {
              onChange({ target: { name: 'nonChargeableMinutesAmount', value: tryConvertToInteger(e.target.value) } });
            }}
            fullWidth={true}
            errorText={errors.nonChargeableMinutesAmount}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              gap: 4
            }}>
            <Box>
              <TimePicker
                floatingLabelText="Od"
                floatingLabelFixed={true}
                name="nonChargeableFrom"
                value={scheme.nonChargeableFrom.toDate()}
                onChange={(e, value) =>
                  onChange({ target: { name: 'nonChargeableFrom', value: new TimeModel().parse(value) } })
                }
                errorText={errors.nonChargeableFrom}
                disabled={scheme.nonChargeableInMinutes}
              />
            </Box>
            <Box>
              <TimePicker
                floatingLabelText="Do"
                floatingLabelFixed={true}
                name="nonChargeableTo"
                value={scheme.nonChargeableTo.toDate()}
                onChange={(e, value) =>
                  onChange({ target: { name: 'nonChargeableTo', value: new TimeModel().parse(value) } })
                }
                errorText={errors.nonChargeableTo}
                disabled={scheme.nonChargeableInMinutes}
              />
            </Box>
          </Box>
        )}
        <TextField
          hintText="Tolerancja obliczeń płatnego czasu (minuty)"
          floatingLabelText="Tolerancja obliczeń płatnego czasu (minuty)*"
          floatingLabelFixed={false}
          name="nonChargeableToleranceInMinutes"
          value={scheme.nonChargeableToleranceInMinutes || ''}
          onChange={onChange}
          onBlur={(e) => {
            onChange({
              target: { name: 'nonChargeableToleranceInMinutes', value: tryConvertToNumber(e.target.value) }
            });
          }}
          fullWidth={true}
          errorText={errors.rate}
        />
      </FieldsetWrapper>
      <PaymentsOptions scheme={scheme} onChange={onChange} errors={errors} />
    </Box>
  );
};

HourlyChargingScheme.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default HourlyChargingScheme;
