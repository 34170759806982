import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';

const CountContext = React.createContext();

function ShareFileProvider({ children, isForbidden, isPrivate, isDisabled, isSingleShare, isFolder }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const value = { isMobile, isForbidden, isPrivate, isDisabled, isSingleShare, isFolder };
  return <CountContext.Provider value={value}>{children}</CountContext.Provider>;
}

ShareFileProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isForbidden: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  isSingleShare: PropTypes.bool,
  isFolder: PropTypes.bool
};

function useShareFileContext() {
  const context = React.useContext(CountContext);
  if (context === undefined) {
    throw new Error('useShareFileContext must be used within a CountProvider');
  }
  return context;
}

export { ShareFileProvider, useShareFileContext };
