import React, { useEffect, useState } from 'react';
import ScheduledTransferTableContainer from './ScheduledTransferTableContainer';
import ScheduledTransferAddDialogContainer from './dialogs/ScheduledTransferAddDialogContainer';
import ScheduledTransferEditDialogContainer from './dialogs/ScheduledTransferEditDialogContainer';
import ConfirmationDialog from '../../forms/ConfirmationDialog';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchScheduledTransfers,
  deleteManyScheduledTransfer,
  deleteScheduledTransfer
} from '../../../actions/scheduledTransferActions';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Button, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { SearchBar } from '../../common/SearchBar';
import ContentWrapper from '../../common/contentWrapper';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import SelectField from '../../common/SelectField';
import ScheduledTransferFilter from '../components/ScheduledTransferFilter';

const ScheduledTransferContainer = () => {
  const [isAddDialog, setIsAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(null);
  const [deleteManyDialog, setDeleteMoreDialog] = useState(null);
  const [isScheduledTransfersListLoading, setIsScheduledTransfersListLoading] = useState(false);
  const [selectedTransfersIds, setSelectedTransfersIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reset, setReset] = useState(false);
  const [isHistory, setHistory] = useState('false');
  const [page, setPage] = useState(0);

  const childrenList = useSelector((state) => state.children);
  const groups = useSelector((state) => state.groups);
  const activities = useSelector((state) => state.activities);
  const scheduledTransfers = useSelector((state) => state.scheduledTransfers);
  const [filteredScheduledTransfers, setFilteredScheduledTransfers] = useState(scheduledTransfers);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const typesOfTransfer = [
    { value: 'false', name: 'Zaplanowane' },
    { value: 'true', name: 'Historia' }
  ];

  const dispatch = useDispatch();

  const getScheduledTransfers = async () => {
    setIsScheduledTransfersListLoading(true);
    await dispatch(fetchScheduledTransfers());
    setIsScheduledTransfersListLoading(false);
  };

  useEffect(() => {
    getScheduledTransfers();
  }, []);

  const handleAdd = () => {
    setIsAddDialog(true);
  };

  const handleEdit = (id) => {
    setEditDialog(id);
  };

  const handleDelete = (item) => {
    setDeleteDialog(item);
  };

  const handleDeleteMany = (ids) => {
    setDeleteMoreDialog(ids);
  };

  const handleSearch = ({ target }) => {
    setSearchText(target.value);
    setPage(0);
  };
  const handleTypeChange = ({ target }) => {
    setHistory(target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSelectedTransfersDelete = () => {
    handleDeleteMany(selectedTransfersIds);
  };

  const handleCheckboxesCancel = () => {
    setSelectedTransfersIds([]);
  };
  const handleFilter = (value) => {
    setFilteredScheduledTransfers(value);
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Planowane przeniesienia" titleIcon={<ShuffleIcon />} />
        <ContentWrapper>
          <Grid container sx={{ mb: 2 }}>
            <Grid
              item
              xs={12}
              xl={5}
              sx={{ pr: { md: 3 }, mt: 1, mb: '11px', display: 'flex', alignItems: 'flex-end' }}>
              <SearchBar label="Szukaj" SearchOnClick={handleSearch} value={searchText} onChange={handleSearch} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              lg={7}
              xl={4}
              sx={{
                my: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: { xs: 'flex-end', sm: 'flex-start', md: 'flex-end' }
              }}>
              <SelectField
                sx={{
                  minWidth: 138
                }}
                inputProps={{
                  name: 'Rodzaj_przeniesienia',
                  id: 'isHistory'
                }}
                labelId="isHistory"
                id="isHistory"
                value={isHistory}
                onChange={handleTypeChange}
                floatingLabelText="Rodzaj przeniesienia">
                {typesOfTransfer.map((type) => {
                  return (
                    <MenuItem value={type.value} key={type.name}>
                      {type.name}
                    </MenuItem>
                  );
                })}
              </SelectField>
              <SelectField
                sx={{
                  minWidth: 88,
                  mx: 2
                }}
                id="rowsPerPage"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                floatingLabelText="Wierszy na stronę">
                {numberArrayOfOptions.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              lg={5}
              xl={3}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <ScheduledTransferFilter
                sx={{ my: 1, mt: { xs: 2, sm: 0 } }}
                itemsToFilter={scheduledTransfers}
                onFilter={handleFilter}
              />
              <Button sx={{ my: 1, mt: { xs: 2, sm: 0 } }} variant="contained" onClick={handleAdd}>
                Zaplanuj
              </Button>
            </Grid>
          </Grid>
          {selectedTransfersIds.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', color: (theme) => theme.palette.color.primary }}>
                <Typography>Wybrano pozycji:</Typography>
                <Typography
                  sx={{
                    ml: 1,

                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}>
                  {selectedTransfersIds.length}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="outlined" onClick={handleCheckboxesCancel} aria-label="Anuluj">
                  Anuluj
                </Button>
                <Button variant="contained" onClick={handleSelectedTransfersDelete} aria-label="Usuń">
                  Usuń
                </Button>
              </Box>
            </Box>
          )}
          <Paper>
            <ScheduledTransferTableContainer
              isHistory={isHistory === 'true'}
              searchText={searchText}
              reset={reset}
              setReset={setReset}
              rowsPerPage={rowsPerPage}
              transfers={filteredScheduledTransfers.length ? filteredScheduledTransfers : scheduledTransfers}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onDeleteMore={handleDeleteMany}
              isLoading={isScheduledTransfersListLoading}
              groups={groups}
              activities={activities}
              selectedTransfersIds={selectedTransfersIds}
              setSelectedTransfersIds={setSelectedTransfersIds}
              page={page}
              setPage={setPage}
            />
          </Paper>
          <ScheduledTransferAddDialogContainer
            isOpen={isAddDialog}
            onClose={() => setIsAddDialog(false)}
            childrenList={childrenList}
            groups={groups}
            activities={activities}
          />
          {editDialog && (
            <ScheduledTransferEditDialogContainer
              isOpen={!!editDialog}
              data={scheduledTransfers.find((item) => item.id === editDialog)}
              onClose={() => setEditDialog(null)}
              childrenList={childrenList}
              groups={groups}
              activities={activities}
            />
          )}
          {deleteDialog && (
            <ConfirmationDialog
              confirmationTitle="Potwierdzenie akcji usunięcia"
              confirmationText={`Czy napewno chcesz usunąć zaplanowane przeniesienie dziecka: ${deleteDialog.firstName} ${deleteDialog.lastName}?`}
              onConfirm={async () => {
                setLoading(true);
                await dispatch(deleteScheduledTransfer(deleteDialog.id));
                setDeleteDialog(null);
                setLoading(false);
              }}
              inProgress={loading}
              onCancel={() => {
                setDeleteDialog(null);
              }}
              confirmLabel="Usuń"
              isOpen={!!deleteDialog}
              isSecondary
            />
          )}
          {deleteManyDialog && (
            <ConfirmationDialog
              confirmationTitle="Potwierdzenie akcji usunięcia"
              confirmationText={`Czy napewno chcesz usunąć zaplanowane przenosiny dzieci. Ilość: ${deleteManyDialog.length} `}
              onConfirm={async () => {
                setLoading(true);
                await dispatch(deleteManyScheduledTransfer({ enrollmentIds: deleteManyDialog }));
                setDeleteMoreDialog(null);
                setLoading(false);
                setSelectedTransfersIds([]);
              }}
              inProgress={loading}
              onCancel={() => {
                setDeleteMoreDialog(null);
              }}
              confirmLabel="Usuń"
              isOpen={!!deleteManyDialog}
              isSecondary
            />
          )}
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};
export default ScheduledTransferContainer;
