import ApiBase, { ROOT_URL } from './ApiBase';

class SettlementsApi extends ApiBase {
  static getSettlements(year, month) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/settlements/${year}-${month}`).type('application/json'));
  }

  static getStaffSettlements(year, month) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/settlements/staff/${year}-${month}`).type('application/json')
    );
  }

  static getDueDetails(dueId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/settlements/dues/${dueId}`).type('application/json'));
  }

  static getChildUnpaidDues(childId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/settlements/dues/child/${childId}/unpaid`).type('application/json')
    );
  }

  static getChildBalanceSummary(childId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/settlements/dues/child/${childId}/balanceSummary`).type('application/json')
    );
  }

  static getStaffUnpaidDues(staffId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/settlements/dues/staff/${staffId}/unpaid`).type('application/json')
    );
  }

  static approveDues(year, month, approveDetails, refreshStaffDues) {
    return super
      .toPromise((agent) =>
        agent.post(`${ROOT_URL}/settlements/dues/approve`).send(approveDetails).type('application/json')
      )
      .then(() => (refreshStaffDues ? this.getStaffSettlements(year, month) : this.getSettlements(year, month)));
  }

  static cancelSettlement(settlementId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/dues/${settlementId}/cancel`).type('application/json')
    );
  }

  static cancelSettlements(year, month, dueCalculationsIds) {
    return super
      .toPromise((agent) =>
        agent.post(`${ROOT_URL}/settlements/dues/cancel`).send({ dueCalculationsIds }).type('application/json')
      )
      .then(() => this.getSettlements(year, month));
  }

  static deleteSettlements(year, month, dueCalculationsIds, refreshStaffDues) {
    return super
      .toPromise((agent) =>
        agent.post(`${ROOT_URL}/settlements/dues/delete`).send({ dueCalculationsIds }).type('application/json')
      )
      .then(() => (refreshStaffDues ? this.getStaffSettlements(year, month) : this.getSettlements(year, month)));
  }

  static calculateSettlements(year, month, params) {
    return super
      .toPromise((agent) =>
        agent.post(`${ROOT_URL}/settlements/${year}-${month}/calculate`).send(params).type('application/json')
      )
      .then(() => this.getSettlements(year, month));
  }

  static calculateCorrectiveDues(year, month, dueIds, options) {
    return super
      .toPromise((agent) =>
        agent
          .post(`${ROOT_URL}/settlements/${year}-${month}/calculateCorrections`)
          .send(Object.assign({}, { correctedDueIds: dueIds, options }))
          .type('application/json')
      )
      .then(() => this.getSettlements(year, month));
  }

  static calculateStaffSettlements(year, month, params) {
    return super
      .toPromise((agent) =>
        agent.post(`${ROOT_URL}/settlements/staff/${year}-${month}/calculate`).send(params).type('application/json')
      )
      .then(() => this.getStaffSettlements(year, month));
  }

  static updateAdjustment(dueId, schemeSnapshotId, reason, amount) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/settlements/dues/${dueId}/adjustment/${schemeSnapshotId}`)
        .send({ reason, amount: Number.parseFloat(amount) })
        .type('application/json')
    );
  }

  static cancelDelayInterests(dueId) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/settlements/dues/${dueId}/cancelDelayInterests`).send());
  }

  static saveCustomDelayInterests(dueId, interestValue) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/dues/setCustomInterestValue`).send({ dueId, interestValue })
    );
  }

  static reserveBalanceFunds(dueId) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/settlements/dues/${dueId}/reserveAvailableFunds`).send());
  }

  static removeReservedFunds(dueId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/dues/${dueId}/removeFundsReservation`).send()
    );
  }

  static registerPayments(childId, paymentDetails, year, month) {
    return super
      .toPromise((agent) =>
        agent
          .post(`${ROOT_URL}/settlements/${childId}/registerMultiplePayments`)
          .send(paymentDetails)
          .type('application/json')
      )
      .then(() => this.getSettlements(year, month));
  }

  static registerStaffPayments(childId, paymentDetails, year, month) {
    return super
      .toPromise((agent) =>
        agent
          .post(`${ROOT_URL}/settlements/${childId}/registerMultiplePaymentsForStaffMember`)
          .send(paymentDetails)
          .type('application/json')
      )
      .then(() => this.getStaffSettlements(year, month));
  }

  static registerDeposit(childId, paymentDetails) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/settlements/${childId}/registerMultiplePayments`)
        .send(paymentDetails)
        .type('application/json')
    );
  }

  static registerStaffDeposit(staffId, paymentDetails) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/settlements/${staffId}/registerMultiplePaymentsForStaffMember`)
        .send(paymentDetails)
        .type('application/json')
    );
  }

  static undoPayment(dueId, paymentId) {
    return super
      .toPromise((agent) =>
        agent.post(`${ROOT_URL}/settlements/dues/${dueId}/undoPayment/${paymentId}`).type('application/json')
      )
      .then(() => this.getDueDetails(dueId));
  }

  static cancelOnlinePayment(dueId, onlinePaymentId) {
    return super
      .toPromise((agent) => agent.post(`${ROOT_URL}/payments/${onlinePaymentId}/cancel`).type('application/json'))
      .then(() => this.getDueDetails(dueId));
  }

  static getOverdue() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/settlements/overdue`).type('application/json'));
  }

  static getOverpayments() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/settlements/overpayments`).type('application/json'));
  }

  static sendNotify(notificationList) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/sendOverdueNotifications`).send(notificationList).type('application/json')
    );
  }

  static refundOverpayments(notificationList) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/refundOverpayments`).send(notificationList).type('application/json')
    );
  }

  static registerRebate(settlementId, rebateDetails) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/settlements/dues/${settlementId}/paymentRebate`)
        .send(rebateDetails)
        .type('application/json')
    );
  }

  static removeRebate(settlementId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/settlements/dues/${settlementId}/paymentRebate`).type('application/json')
    );
  }

  static validateMt940File(file, bankName, childNumberScheme) {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('name', file.name);
    data.append('bankName', bankName);
    data.append('childNumberScheme', childNumberScheme);
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/children/validate_mt940_file`).send(data));
  }

  static importMt940File(payload) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/dues/registerMultiplePaymentsBulk`).send(payload)
    );
  }

  static loadAllSettlements() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/settlements/all`));
  }

  static calculateDelayInterests(payload) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/settlements/dues/calculateDelayInterestsByIds`).send(payload)
    );
  }
}

export default SettlementsApi;
