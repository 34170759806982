/*eslint indent:0*/
import initialState from './initial';
import {
  ChildDetailsModel,
  GuardianModel,
  LegalGuardianModel,
  ChildChargingScheme,
  FileModel,
  StudentDetailsModel,
  OpinionFileModel
} from '../models/children/ChildModels';
import * as types from '../actions/actionTypes';
// import _ from 'lodash';

export default function childDetailsReducer(state = initialState.childDetails, action) {
  switch (action.type) {
    case types.LOAD_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(action.childDetails);

      if (state.attributes?.length > 0) {
        return childDetails.assign({ attributes: state.attributes });
      } else {
        return childDetails;
      }
    }
    case types.LOAD_STUDENT_SUCCESS: {
      const studentDetails = new StudentDetailsModel().assign(state);
      return studentDetails.assign(action.childDetails);
    }
    case types.CREATE_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      return childDetails.assign(action.childDetails);
    }
    case types.UPDATE_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      return childDetails.assign(action.childDetails);
    }
    case types.ADD_GUARDIAN_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.guardians = [...childDetails.guardians, new GuardianModel().assign(action.guardian)];
      return childDetails;
    }
    case types.UPDATE_GUARDIAN_IN_CHILD_SUCCESS: {
      const guardians = [
        ...state.guardians.map((g) => (g.id !== action.guardian.id ? g : new GuardianModel().assign(action.guardian)))
      ];
      const childDetails = new ChildDetailsModel().assign(state);
      return Object.assign(childDetails, { guardians });
    }
    case types.REMOVE_GUARDIAN_FROM_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.guardians = [...childDetails.guardians.filter((g) => g.id !== action.guardianId)];
      return childDetails;
    }
    case types.AUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      const nextGuardian = new GuardianModel().assign(childDetails.guardians.find((g) => g.id === action.guardianId));
      nextGuardian.isAuthorized = true;

      childDetails.guardians = [...childDetails.guardians.map((g) => (g.id !== action.guardianId ? g : nextGuardian))];
      return childDetails;
    }
    case types.UNAUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      const nextGuardian = new GuardianModel().assign(childDetails.guardians.find((g) => g.id === action.guardianId));
      nextGuardian.isAuthorized = false;

      childDetails.guardians = [...childDetails.guardians.map((g) => (g.id !== action.guardianId ? g : nextGuardian))];
      return childDetails;
    }
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.legalGuardians = [
        ...childDetails.legalGuardians,
        new LegalGuardianModel().assign(action.legalGuardian)
      ];
      return childDetails;
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      const guardianIdx = childDetails.legalGuardians.findIndex((g) => g.id === action.legalGuardian.id);
      childDetails.legalGuardians[guardianIdx] = new LegalGuardianModel().assign(action.legalGuardian);

      return childDetails;
    }
    case types.REMOVE_LEGAL_GUARDIAN_FROM_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.legalGuardians = [...childDetails.legalGuardians.filter((g) => g.id !== action.guardianId)];
      return childDetails;
    }
    case types.UPDATE_CHILD_PROFILE_PHOTO_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.photoUrl = action.photoUrl;
      return childDetails;
    }
    case types.REMOVE_CHILD_PROFILE_PHOTO_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.photoUrl = null;
      return childDetails;
    }
    case types.ASSIGN_CHARGING_SCHEME_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.contract.schemes = [
        ...childDetails.contract.schemes.filter((scheme) => scheme.schemeId !== action.scheme.schemeId),
        new ChildChargingScheme().assign(action.scheme)
      ];
      return childDetails;
    }
    case types.REMOVE_CHARGING_SCHEME_FROM_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.contract.schemes = [
        ...childDetails.contract.schemes.filter((scheme) => scheme.schemeId !== action.schemeId)
      ];
      return childDetails;
    }
    case types.ATTACH_FILE_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.attachments = [...childDetails.attachments, new FileModel().assign(action.fileAttachment)];
      return childDetails;
    }
    case types.REMOVE_FILE_FROM_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.attachments = [...childDetails.attachments.filter((g) => g.fileId !== action.fileAttachmentId)];
      return childDetails;
    }
    case types.UPDATE_CHILD_OPENING_BALANCE_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.contract.openingBalance.assign(action.balance);
      return childDetails;
    }
    case types.LOAD_CHILD_ATTRIBUTES_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      return childDetails.assign({ attributes: action.attributesValues });
    }
    case types.UPDATE_CHILD_ATTRIBUTES_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.attributes = childDetails.attributes.map((set) =>
        set.attributeSetId === action.attributeSet.attributeSetId ? action.attributeSet : set
      );
      return childDetails;
    }
    case types.ADD_SURVEY_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.attachments = [...childDetails.attachments, new FileModel().assign(action.fileAttachment)];
      return childDetails;
    }
    case types.ADD_OPINION_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      let attachment = new FileModel().assign(action.fileAttachment);
      if (action.fileAttachment.fileInfo && action.fileAttachment.fileInfo.fileType === 'opinion')
        attachment = new OpinionFileModel().assign(action.fileAttachment);
      childDetails.attachments = [...childDetails.attachments, attachment];
      return childDetails;
    }
    case types.CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      const newDiagnosticArray = [...childDetails.diagnoses];
      newDiagnosticArray.push(action.diagnose);
      childDetails.diagnoses = [...newDiagnosticArray];
      return childDetails;
    }
    case types.REMOVE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      const newDiagnosticArray = [...childDetails.diagnoses.filter((g) => g.diagnoseId !== action.diagnose.diagnoseId)];
      childDetails.diagnoses = [...newDiagnosticArray];
      return childDetails;
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      const newDiagnosticArray = [
        ...childDetails.diagnoses.map((g) => (g.diagnoseId === action.diagnose.diagnoseId ? action.diagnose : g))
      ];
      childDetails.diagnoses = [...newDiagnosticArray];
      return childDetails;
    }
    case types.ADD_VACATION_PERIOD_TO_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.vacationPeriods = [...childDetails.vacationPeriods, action.newVacationPeriod];
      return childDetails;
    }
    case types.REMOVE_VACATION_PERIOD_FROM_CHILD_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.vacationPeriods = [...childDetails.vacationPeriods.filter((g) => g.id !== action.vacationPeriodId)];
      return childDetails;
    }
    case types.TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.takenOverByDebtCollection = true;
      return childDetails;
    }
    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUCCESS: {
      const childDetails = new ChildDetailsModel().assign(state);
      childDetails.takenOverByDebtCollection = false;
      return childDetails;
    }
    default:
      return state;
  }
}