/*eslint indent:0*/
import initialState from "./initial";
import * as types from "../actions/actionTypes";

export default function psychologicalChildrenListReducer(state = initialState.psychologicalChildrenList, action) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_HELP_LIST: {
      return action.payload;
    }
    case types.ARCHIVE_PSYCHOLOGICAL_HELP: {
      return state.filter((e) => e.id !== action.helpId);
    }

    case types.ADD_PSYCHOLOGICAL_HELP: {
      const preparedChildData = {
        IPETs: [],
        WOPFUs: [],
        childFirstName: action.child.child.firstName,
        childId: action.child.child.id,
        childLastName: action.child.child.lastName,
        from: action.child.from,
        to: action.child.to,
        lettersOfNotification: [],
        others: [],
        reason: action.child.cause,
        id: action.id.id
      };
      return [...state, preparedChildData];
    }

    default:
      return state;
  }
}
