import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../actions/attendanceActions';
import * as daysOffActions from '../../actions/daysOffActions';
import ChildAttendanceCalendar from '../common/children/attendance/calendar/ChildAttendanceCalendar';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ChildrenDropDown from '../common/children/childrenDropDown/ChildrenDropDown';
import { Box } from '@mui/material';

class GuardianChildAttendancePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.loadAttendanceData = this.loadAttendanceData.bind(this);
    this.handlePupilChange = this.handlePupilChange.bind(this);
  }

  loadAttendanceData(date) {
    this.props.attendanceActions.loadChildAttendancesAsync(this.props.pupilId, date);
    this.props.daysOffActions.loadDaysOffAsync(moment(date).format('YYYY'));
  }

  handlePupilChange(value) {
    browserHistory.push(fromTemplate(routePaths.guardianChildAttendance, [value, this.props.year, this.props.month]));
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ChildrenDropDown
              pupils={this.props.pupils}
              pupilId={this.props.pupilId}
              onPupilChange={this.handlePupilChange}
            />
          </Box>
          <Paper>
            <ChildAttendanceCalendar
              markedDays={this.props.attendances.entries}
              attendanceSummary={this.props.attendances.summary}
              daysOff={this.props.daysOff}
              onDateChange={this.loadAttendanceData}
              onDaySelected={() => {}}
              onReportAttendance={() => {}}
              onReportAbsence={() => {}}
              onRevertAbsence={() => {}}
              onDetailsOpen={(day) => this.handleDetailsOpen(day)}
              isReadOnly={true}
              hidePaidDays={true}
              isLoading={this.props.isCalendarLoading}
            />
          </Paper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GuardianChildAttendancePage.propTypes = {
  pupils: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  pupilId: PropTypes.string.isRequired,
  attendances: PropTypes.object.isRequired,
  attendanceActions: PropTypes.object.isRequired,
  daysOff: PropTypes.array.isRequired,
  daysOffActions: PropTypes.object.isRequired,
  isCalendarLoading: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  const pupilId = ownProps.params.childId;
  return {
    pupils: state.legalGuardiansChildren,
    year: ownProps.params.year,
    month: ownProps.params.month,
    pupilId,
    attendances: state.childAttendances,
    daysOff: state.configuration.daysOff,
    isCalendarLoading: state.childDetailsUi.isCalendarLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    attendanceActions: bindActionCreators(attendanceActions, dispatch),
    daysOffActions: bindActionCreators(daysOffActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardianChildAttendancePage);
