import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import makeStyles from '@mui/styles/makeStyles';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useField, useFormikContext } from 'formik';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  editorWrapper: {
    border: (error) =>
      `1px solid ${error ? theme.palette.components.wysiwyg.borderError : theme.palette.components.wysiwyg.borderPass}`,
    borderRadius: '2px'
  },
  editor: {
    minHeight: '250px'
  },
  errorText: {
    color: theme.palette.components.wysiwyg.borderError,
    fontSize: '0.75rem',
    margin: '8px 0'
  }
}));

const prepareDraft = (value) => {
  const draft = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
  return EditorState.createWithContent(contentState);
};

const FormikWYSIWYG = (props) => {
  const { name } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles(meta.touched && !!meta.error);
  const [editorState, setEditorState] = useState(!!field.value ? prepareDraft(field.value) : EditorState.createEmpty());

  const onEditorStateChange = (state) => {
    const parsedToHTML = draftToHtml(convertToRaw(state.getCurrentContent()));
    setFieldValue(name, parsedToHTML);
    setEditorState(state);
  };

  return (
    <Box>
      <Box className={classes.editorWrapper}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName={classes.editor}
          onEditorStateChange={onEditorStateChange}
          onBlur={() => setFieldTouched(field.name, true)}
          localization={{
            locale: 'pl'
          }}
          toolbar={{
            options: [
              'emoji',
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'remove'
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true }
          }}
        />
      </Box>
      {meta.touched && !!meta.error && <Typography className={classes.errorText}>{meta.error}</Typography>}
    </Box>
  );
};

FormikWYSIWYG.propTypes = {
  name: PropTypes.string.isRequired
};

export default FormikWYSIWYG;
