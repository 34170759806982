import * as types from './actionTypes';
import api from '../api/ChildrenApi';
import * as notificationActions from './notificationActions';
// import * as logger from '../utils/logger';

export function exportChildrenStart() {
  return { type: types.EXPORT_CHILDREN };
}

export function exportChildrenCancel() {
  return { type: types.EXPORT_CHILDREN_CANCEL };
}

export function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.download = filename;
  link.href = url;
  link.click();
  document.body.removeChild(link);
}

function prepareQuery(config) {
  return `${config.dates ? 'y' : 'n'}/${config.groups.join(';')}`;
}

export const exportStudentAsync = (groups) => (dispatch) => {
  dispatch({ type: types.EXPORT_CHILDREN_SUBMIT });
  api.exportStudents(groups).then((response) => {
    notificationActions.showSuccess('Pobrano listę uczniów');
    dispatch({ type: types.EXPORT_CHILDREN_SUCCESS });
    notificationActions.showSuccess('Wygenerowano plik pomyślnie');
    storeBlobAs(response.response, 'export_uczniow.xml');
  });
};

export function exportChildrenAsync(config) {
  return (dispatch) => {
    dispatch({ type: types.EXPORT_CHILDREN_SUBMIT });
    const query = prepareQuery(config);
    api.exportChildrenList(query).then((res) => {
      notificationActions.showSuccess('Pobrano listę dzieci');
      dispatch({ type: types.EXPORT_CHILDREN_SUCCESS });
      dispatch(notificationActions.showSuccess('Wygenerowano plik pomyślnie'));
      storeBlobAs(res.response, 'export_dzieci.xml');
    });
  };
}
