import { Box, Button } from '@mui/material';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeader from '../common/PageHeader';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BoardPostsList from '../digitalDiary/digitalDiaryMessageBoardPage/BoardPostsList';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IndividualClassesDialog from '../individualClassesDetails/ClassesDialog';
import { PsychologicalClassesModel } from '../../models/psychological/PsychologicalClassesModel';
import {
  addIndividualClasses,
  deleteIndividualClasses,
  editIndividualClasses,
  setIndividualClassesDialogClosed,
  setIndividualClassesDialogOpen
} from '../../actions/psychologicalIndividualClassesActions';
import PropTypes from 'prop-types';

const IndividualClassesEventsPage = (props) => {
  const schemesArray = useSelector((state) => state.psychologicalSchemes);
  const today = `${props.params.year}-${props.params.month}-${props.params.day}`;
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.psychologicalIndividualWorkProgram.posts);

  const ui = useSelector((state) => state.psychologicalIndividualProgramUi.individualClasses);
  const [currentPost, setCurrentPost] = useState(null);

  const handleOpenEditPost = (post) => {
    dispatch(setIndividualClassesDialogOpen());
    setCurrentPost(new PsychologicalClassesModel().assign(post));
  };
  const handleOpenCreatePost = () => {
    dispatch(setIndividualClassesDialogOpen());
    setCurrentPost(
      new PsychologicalClassesModel().assign({
        eventDate: `${props.params.year}-${props.params.month}-${props.params.day}`
      })
    );
  };

  const handlePostSave = (post) => {
    let data = post;
    data.group = props.params.id;
    data.eventType = 'psychologicalPedagogicalIndividualClassesTimetable';
    if (post.id) {
      dispatch(editIndividualClasses(post));
    } else {
      dispatch(addIndividualClasses(post));
    }
  };

  const handlePostDelete = (post) => {
    setCurrentPost(null);
    dispatch(deleteIndividualClasses(post.id));
  };
  const handleCancel = () => {
    dispatch(setIndividualClassesDialogClosed());
  };

  const renderAddPostButton = () => {
    return (
      <Button sx={{ mb: 1 }} variant="contained" aria-label="Nowy wpis" onClick={handleOpenCreatePost}>
        Nowy wpis
      </Button>
    );
  };
  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeader>
          <PageHeaderText
            title="Rozkład zajęć"
            titleIcon={<EventNoteIcon />}
            additionalInfo={`${props.params.day}.${props.params.month}.${props.params.year}`}
          />
          {renderAddPostButton()}
        </PageHeader>
        {ui.isEditing && (
          <IndividualClassesDialog
            onSave={handlePostSave}
            onCancel={handleCancel}
            post={currentPost}
            isEditing={ui.isEditing}
            isProcessing={ui.isProcessing}
            canSetDate={true}
            schemesArray={schemesArray.individualSchedules}
          />
        )}
        <BoardPostsList
          posts={posts?.filter((post) => post.eventDate === today)}
          pageCount={1}
          hasNextPage={false}
          onEdit={handleOpenEditPost}
          onDelete={handlePostDelete}
        />
      </Box>
    </LoadingRenderWrapper>
  );
};
export default IndividualClassesEventsPage;

IndividualClassesEventsPage.propTypes = {
  params: PropTypes.object.isRequired
};
