/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { SettlementModel } from '../models/settlements/SettlementModels';

export default function settlementsReducer(state = initialState.settlements, action) {
  switch (action.type) {
    case types.LOAD_SETTLEMENTS_SUCCESS:
    case types.CALCULATE_SETTLEMENTS_SUCCESS:
    case types.CALCULATE_CORRECTIVE_DUES_SUCCESS:
    case types.APPROVE_DUE_SUCCESS:
    case types.CANCEL_SETTLEMENTS_SUCCESS:
    case types.DELETE_SETTLEMENTS_SUCCESS:
    case types.REGISTER_PAYMENTS_SUCCESS: {
      return action.settlements.map((settlement) => new SettlementModel().assign(settlement));
    }
    case types.CANCEL_SETTLEMENT_SUCCESS:
    case types.CANCEL_SETTLEMENT_DELAY_INTERESTS_SUCCESS:
    case types.EDIT_SETTLEMENT_DELAY_INTERESTS_SUCCESS:
    case types.UPDATE_SETTLEMENT_SUCCESS:
    case types.REGISTER_PAYMENT_SUCCESS:
    case types.REGISTER_REBATE_SUCCESS:
    case types.UNDO_PAYMENT_SUCCESS:
    case types.CANCEL_ONLINE_PAYMENT_SUCCESS:
    case types.RESERVE_BALANCE_FUNDS_SUCCESS:
    case types.REMOVE_RESERVED_BALANCE_FUNDS_SUCCESS:
    case types.REMOVE_REBATE_SUCCESS: {
      return [...state.filter((s) => s.id !== action.settlement.id), new SettlementModel().assign(action.settlement)];
    }
    case types.AVAILABLE_FUNDS_UPDATED: {
      return [
        ...state.filter((s) => s.person.id !== action.personId),
        ...state
          .filter((s) => s.person.id === action.personId)
          .map((s) => {
            const updatedSettlement = new SettlementModel().assign(s);
            updatedSettlement.personAvailableFunds = action.newAvailableFunds;
            updatedSettlement.hasFundsAvailable = action.hasFundsAvailable;
            return updatedSettlement;
          })
      ];
    }
    case types.SEND_NOTIFICATION:
    default:
      return state;
  }
}
