import BaseModel from '../BaseModel';
import { MealModel } from './MealModels';

export class DietDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.meals = [];
    this.enrolledChildren = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.meals = assigned.meals.map((meal) => new MealModel().assign(meal));
    assigned.enrolledChildren = [...assigned.enrolledChildren];
  }
}

export class DietsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.meals = [];
    this.enrolledChildren = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.meals = assigned.meals.map((meal) => new MealModel().assign(meal));
    assigned.enrolledChildren = [...assigned.enrolledChildren];
  }
}

export class ChildDietModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.meals = [];
    this.startDate = '';
    this.endDate = null;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.meals = assigned.meals.map((meal) => new MealModel().assign(meal));
  }
}
