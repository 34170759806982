import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import _ from 'lodash';
import * as privateMessagesActions from '../../actions/privateMessagesActions';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ThreadViewDrawer from './ThreadView/ThreadViewDrawer';
import ThreadsList from './ThreadView/ThreadsList';
import MessagesList from './MessageView/MessagesList';
import MessageHeader from './MessageView/MessageHeader';
import EmptyState from '../common/EmptyState';
import MailIcon from '@mui/icons-material/Mail';
import SendMessageCard from './MessageView/SendMessageCard';
import CreateThreadButton from './ThreadView/CreateThreadButton';
import FilterBar from '../common/FilterBar';
import withMediaQuery from '../../hoc/withMediaQuery';
import DrawerButton from './DrawerButton/DrawerButton';
import Box from '@mui/material/Box';
import ManualLink from '../ManualLink';
import { Paper, Typography } from '@mui/material';
import * as virtualDiscActions from '../../actions/virtualDiscActions';

class GuardianMessagesPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleThreadSelection = this.handleThreadSelection.bind(this);
    this.handleMessageSelect = this.handleMessageSelect.bind(this);
    this.handleMessagesLoaded = this.handleMessagesLoaded.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.handleLoadMoreMessages = this.handleLoadMoreMessages.bind(this);
    this.handleLoadMoreThreads = this.handleLoadMoreThreads.bind(this);
    this.handleMessageUpdate = this.handleMessageUpdate.bind(this);
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
    this.handleMessageDelete = this.handleMessageDelete.bind(this);
    this.getRecipients = this.getRecipients.bind(this);
    this.getTypingParticipant = this.getTypingParticipant.bind(this);
    this.handleThreadsFiltered = this.handleThreadsFiltered.bind(this);
    this.handleFilterThreads = this.handleFilterThreads.bind(this);
    this.handleChangeDrawer = this.handleChangeDrawer.bind(this);
    this.handleGifSelected = this.handleGifSelected.bind(this);
    this.handleMessageEdit = this.handleMessageEdit.bind(this);
    this.state = {
      thread: null,
      threads: [],
      nextMessageText: undefined,
      isDrawerOpen: false,
      gif: ''
    };
  }

  componentDidMount() {
    this.props.actions.loadPossibleRecipients();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.thread && this.props.threads.length) {
      this.handleThreadSelection(this.props.threads[0]);
    }
    if (this.props.messages !== prevProps.messages) {
      this.handleMessagesLoaded(this.props.messages, this.state.thread);
    }
    if (this.props.threads !== prevProps.threads) {
      this.handleThreadsFiltered(this.props.threads);
    }
  }

  getRecipients() {
    const participants = _.flatMap(this.props.threads, (t) => t.participants);
    return this.props.possibleRecipients;
  }

  getTypingParticipant() {
    if (!this.state.thread || !this.props.threads) return null;
    const thread = this.props.threads.find((t) => t.threadId === this.state.thread.threadId);
    return thread ? thread.typingParticipant : null;
  }

  handleMessageUpdate(nextMessageText) {
    if (!this.state.nextMessageText && nextMessageText && this.state.thread.threadId) {
      this.props.actions.notifyTyping(this.state.thread.threadId);
    }

    if (this.state.nextMessageText && !nextMessageText && this.state.thread.threadId) {
      this.props.actions.notifyNotTyping(this.state.thread.threadId);
    }

    this.setState({ nextMessageText });
  }

  handleMessageSubmit() {
    if (this.state.thread.threadId) {
      this.props.actions.notifyNotTyping(this.state.thread.threadId);
    }

    const text =
      (!!this.state.nextMessageText ? this.state.nextMessageText : '') + ' ' + (this.state.gif && this.state.gif);
    const message = {
      id: this.state.editingMessageId,
      recipientId: this.state.thread.recipientId,
      content: {
        text: text
      }
    };
    this.setState(() => ({
      gif: ''
    }));
    if (this.state.editingMessageId) {
      this.props.actions.updateMessageAsync(message, this.state.thread, this.props.userId);
      this.setState({ nextMessageText: '', editingMessageId: undefined });
    } else {
      const { threadId } = this.props.actions.sendMessageAsync(message, this.state.thread, this.props.userId);
      this.setState({
        nextMessageText: '',
        editingMessageId: undefined,
        thread: Object.assign({}, this.state.thread, { threadId })
      });
    }
  }

  handleMessageDelete(message) {
    this.props.actions.deleteMessageAsync(message, this.state.thread);
  }

  handleEditCancel() {
    this.setState({ nextMessageText: '', editingMessageId: undefined });
  }

  handleThreadSelection(thread) {
    this.handleMessageUpdate('');
    if (thread) {
      this.props.virtualDistActions.fetchFiles();
      this.setState({ thread }, () => {
        if (!thread.threadId) {
          this.props.actions.clearMessages();
        } else {
          this.props.actions.loadMessagesAsync(thread.threadId, 20, 0, 'my');
        }
      });
    }
  }

  handleMessagesLoaded(messages) {
    const lastUnread = messages.find((x) => !x.readByMe);
    if (!lastUnread) return;
    this.props.actions.markMessageRead(lastUnread.threadId, lastUnread.sentAt);
  }

  handleMessageSelect(message) {
    this.setState({ nextMessageText: message.content.text, editingMessageId: message.id });
  }

  handleLoadMoreMessages() {
    if (!this.state.thread.threadId) return;

    this.props.actions.loadNextMessagesAsync(this.state.thread.threadId, 10, this.props.messages.length, 'my');
  }

  handleLoadMoreThreads() {
    this.props.actions.loadNextThreadsAsync(10, this.props.threads.length);
  }

  handleThreadsFiltered(filteredThreads) {
    this.setState({ threads: filteredThreads });
  }

  handleFilterThreads(thread, keyword) {
    return thread.participants.some(
      (p) => p.id !== this.props.userId && p.fullName.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  handleChangeDrawer() {
    this.setState((prevState) => {
      return { isDrawerOpen: !prevState.isDrawerOpen };
    });
  }

  handleMessageEdit(message, content = '', gif = '') {
    const newMessage = Object.assign({}, message);
    newMessage.content = { text: content };
    if (gif) newMessage.content.text += ' ' + gif;
    this.props.actions.updateMessageAsync(newMessage, this.state.thread, message.senderId);
  }

  handleGifSelected(gif) {
    this.setState(() => ({
      gif
    }));
  }

  render() {
    const { userId, twoWayMessagingEnabled, isRequiredSize } = this.props;
    const { isDrawerOpen } = this.state;
    const typingParticipant = this.getTypingParticipant();
    return (
      <LoadingRenderWrapper>
        <>
          {isRequiredSize && <DrawerButton closeDrawer={this.handleChangeDrawer} />}
          <Paper
            sx={{
              background: (theme) => theme.palette.components.messenger.background,
              height: '100%',
              mr: '0',
              '@media(min-width: 900px)': {
                mr: '320px'
              }
            }}>
            {this.state.thread && (
              <Box className="message-panel">
                <MessageHeader
                  thread={this.state.thread}
                  selectUser={(x) => x.participants.filter((p) => p.id !== userId)}
                  display="left"
                  manualLink={
                    <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                      {!this.props.nadarzyn && <ManualLink index="2" />}
                    </Box>
                  }
                />

                <MessagesList
                  messages={this.props.messages}
                  canLoadMore={this.props.canLoadMoreMessages}
                  onLoadMore={this.handleLoadMoreMessages}
                  isLoading={this.props.isLoadingMessages}
                  isInitial={this.props.isInitialLoadingMessages}
                  onSelect={this.handleMessageSelect}
                  userId={this.props.userId}
                  onDelete={this.handleMessageDelete}
                  onUpdate={this.handleMessageEdit}
                  thread={this.state.thread}
                  readonly={!twoWayMessagingEnabled}
                  files={this.props.virtualDisc}
                />
                {typingParticipant && (
                  <Typography sx={{ ml: 1, color: (theme) => theme.palette.components.messenger.messengerText }}>
                    {typingParticipant.fullName} pisze...
                  </Typography>
                )}
                {twoWayMessagingEnabled && (
                  <SendMessageCard
                    maxHeight="60vh"
                    text={this.state.nextMessageText}
                    onChange={this.handleMessageUpdate}
                    onSubmit={this.handleMessageSubmit}
                    isEditing={!!this.state.editingMessageId}
                    onCancelEdit={this.handleEditCancel}
                    isGifSelected={this.state.gif}
                    onGifSelected={(gifUrl) => {
                      this.handleGifSelected(gifUrl);
                    }}
                    user={this.props.user}
                    files={this.props.virtualDiscFolder.disc}
                  />
                )}
              </Box>
            )}
            <ThreadViewDrawer
              isDrawerOpen={isDrawerOpen}
              isRequiredSize={isRequiredSize}
              closeDrawer={this.handleChangeDrawer}
              newMessageButton={
                twoWayMessagingEnabled && (
                  <CreateThreadButton
                    isCheckbox
                    onlyOneRecipient
                    threads={this.props.threads}
                    recipients={this.getRecipients()}
                    onCreate={this.handleThreadSelection}
                    userId={this.props.userId}
                    user={this.props.user}
                    staffMembers={this.props.staffMembers}
                    sx={{ height: 'auto' }}
                  />
                )
              }>
              <Box sx={{ pt: isRequiredSize ? 3 : null }}>
                {!!this.props.threads.length && (
                  <>
                    <FilterBar
                      contrast
                      itemsToFilter={this.props.threads}
                      onFilter={this.handleThreadsFiltered}
                      filter={this.handleFilterThreads}
                    />
                    <ThreadsList
                      authUser={this.props.user}
                      showLastMessage
                      threads={this.state.threads}
                      selectUser={(x) => x.participants.filter((p) => p.id !== userId)[0]}
                      onSelect={this.handleThreadSelection}
                      onLoadMore={this.handleLoadMoreThreads}
                      isLoading={this.props.isLoadingThreads}
                      canLoadMore={this.props.canLoadMoreThreads}
                    />
                  </>
                )}
              </Box>
            </ThreadViewDrawer>
          </Paper>
          {!this.props.threads.length && (
            <EmptyState
              contrast
              isPaper
              paperSx={{
                mr: '0',
                '@media(min-width: 900px)': {
                  mr: '320px'
                }
              }}
              message="Nie masz żadnych wiadomości do odczytania"
              icon={<MailIcon />}
            />
          )}
        </>
      </LoadingRenderWrapper>
    );
  }
}

GuardianMessagesPage.propTypes = {
  threads: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  canLoadMoreMessages: PropTypes.bool.isRequired,
  canLoadMoreThreads: PropTypes.bool.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  isInitialLoadingMessages: PropTypes.bool.isRequired,
  isLoadingThreads: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  possibleRecipients: PropTypes.array.isRequired,
  twoWayMessagingEnabled: PropTypes.bool.isRequired,
  isRequiredSize: PropTypes.bool,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    threads: state.privateThreads.threads,
    messages: state.privateMessages.messages,
    possibleRecipients: state.privateMessages.possibleRecipients,
    canLoadMoreMessages: state.privateMessages.canLoadMore,
    isInitialLoadingMessages: state.privateMessages.isInitial,
    canLoadMoreThreads: state.privateThreads.canLoadMore,
    isLoadingMessages: state.privateMessages.isLoading,
    isLoadingThreads: state.privateThreads.isLoading,
    userId: state.auth.userId,
    user: state.auth,
    virtualDiscFolder: state.virtualDiscFolder,
    virtualDisc: state.virtualDisc,
    twoWayMessagingEnabled: state.configuration.unit.twoWayMessagingEnabled,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(privateMessagesActions, dispatch),
    virtualDistActions: bindActionCreators(virtualDiscActions, dispatch)
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withMediaQuery('md'))(GuardianMessagesPage);
