import { useDispatch } from 'react-redux';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { MealDetailsCard } from './MealDetailsCard';
import { createMealDetailsAsync } from '../../actions/mealActions';
import { MealModel } from '../../models/meals/MealModels';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import { useCallback, useState } from 'react';
import { create } from '../../utils/AvatarContentGenerator';

const emptyMeal = new MealModel();

export const MealRegisterPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = useCallback(
    async (newMeal) => {
      const parsedMeal = {
        name: newMeal.name,
        color: newMeal.color || create().generateColorFromName(newMeal.name)
      };
      try {
        setIsLoading(true);
        await dispatch(createMealDetailsAsync(parsedMeal));
        browserHistory.push(routePaths.meals);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    browserHistory.push(routePaths.meals);
  }, []);

  return (
    <LoadingRenderWrapper>
      <MealDetailsCard
        meal={emptyMeal}
        title="Nowy posiłek"
        handleSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </LoadingRenderWrapper>
  );
};
