import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import RichTextEditor from '../../forms/richTextEditor/RichTextEditor';
import DialogForm from '../../forms/DialogForm';
import { BoardPostModelValidator } from '../../../models/messageBoard/MessageBoardModelsValidator';
import DatePicker from '../../common/DatePicker';
import TimePicker from '../../common/TimePicker';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import { TimeModel } from '../../../models/TimeModel';
import moment from 'moment';
import { MenuItem, FormControl, FormHelperText, Box } from '@mui/material';
import SelectField from '../../common/SelectField';
import withMediaQuery from '../../../hoc/withMediaQuery';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const ActivitiesDialog = (props) => {
  const [repeat, setRepeat] = useState(false);
  const [repeatUntil, setRepeatUntil] = useState('');
  const [post, setPost] = useState(Object.assign({}, props.post));
  const [errors, setErrors] = useState({});

  const { isMobile } = props;

  const handleTitleChange = (e) => {
    setPost((prevPost) => Object.assign(prevPost, { title: e.target.value }));
  };

  const handleContentChange = (htmlContent) => {
    setPost((prevPost) => Object.assign(prevPost, { content: htmlContent }));
  };

  const handleEventDateChange = (date) => {
    setPost((prevPost) =>
      Object.assign(prevPost, {
        eventDate: date ? moment(date).format('YYYY-MM-DD') : null
      })
    );
    if (!date) {
      handleEventStartTimeChange(undefined);
      handleEventEndTimeChange(undefined);
    }
  };

  const handleRepeatUntilChange = (date) => {
    setRepeatUntil(moment(date).format('YYYY-MM-DD'));
    if (!date) {
      handleEventStartTimeChange(undefined);
      handleEventEndTimeChange(undefined);
    }
  };

  const handleEventStartTimeChange = (time) => {
    setPost((prevPost) =>
      Object.assign(prevPost, { eventStartTime: time ? new TimeModel().parse(time) : new TimeModel() })
    );
    if (!time) {
      handleEventEndTimeChange(undefined);
    }
  };

  const handleEventEndTimeChange = (time) => {
    setPost((prevPost) =>
      Object.assign(prevPost, { eventEndTime: time ? new TimeModel().parse(time) : new TimeModel() })
    );
  };

  const handleRepeatCheck = () => {
    setRepeat((prevState) => !prevState);
  };

  const handleOnSave = () => {
    if (repeat) {
      const { eventDate } = post;
      const dates = [];
      let nextDate = moment(eventDate, 'YYYY-MM-DD').add(7, 'd').format('YYYY-MM-DD');
      while (moment(nextDate).isSameOrBefore(repeatUntil)) {
        dates.push(nextDate);
        nextDate = moment(nextDate, 'YYYY-MM-DD').add(7, 'd').format('YYYY-MM-DD');
      }
      // todo send data :)
      props.onSave({
        ...post,
        eventDate,
        cloneDays: dates
      });
    } else {
      props.onSave(post);
    }
  };

  return (
    <DialogForm
      errors={errors}
      onSave={handleOnSave}
      onCancel={() => props.onCancel(post)}
      onValidate={() => new BoardPostModelValidator().validate(post)}
      onValidationDone={(errors) => {
        setErrors(errors);
      }}
      statePathToUi="messageBoardUi"
      isDialogOpen={props.isEditing}
      isProcessing={props.isProcessing}
      header="Utwórz nowe wydarzenie"
      saveDisabled={props.isGroupArchived || Object.keys(errors).length > 0}
      requiredFieldText>
      <FieldsetWrapper legend="Pola na temat wydarzenia dotyczącego zajęcia dodatkowego">
        <SelectField
          contrast
          onChange={handleTitleChange}
          value={post.title}
          fullWidth={true}
          sx={{ mb: 3 }}
          floatingLabelText="Zajęcie dodatkowe*"
          id="additional-activities"
          errorText={errors.title}>
          {props.activities.map((activity) => (
            <MenuItem key={activity.name} value={activity.name}>
              {activity.name}
            </MenuItem>
          ))}
        </SelectField>
        <FormControl
          error={errors.content}
          sx={{
            width: '100%',
            mb: 3,
            borderBottom: !!errors && !!errors.content ? (theme) => `1px solid ${theme.palette.color.error}` : null
          }}>
          <RichTextEditor onChange={handleContentChange} initialText={post.content} />
          <FormHelperText>{errors.content}</FormHelperText>
        </FormControl>

        {props.canSetDate && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'space-between' }}>
            <ClearButtonWrapper
              contrast
              onClear={() => handleEventDateChange(undefined)}
              disabled={!props.canClearDate}>
              <DatePicker
                sx={{ width: 200, m: 0 }}
                floatingLabelText="Data wydarzenia"
                textFieldStyle={{ width: 128 }}
                onChange={(e, date) => handleEventDateChange(date)}
                value={post.eventDate}
                name="eventDate"
                errorText={errors.eventDate}
                id="dataWydarzeniaPicker"
              />
            </ClearButtonWrapper>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
              <ClearButtonWrapper
                sx={{ mr: props.canClearDate ? 0 : 2 }}
                contrast
                onClear={() => handleEventStartTimeChange(undefined)}
                disabled={!props.canClearDate}>
                <TimePicker
                  floatingLabelText="Rozpoczęcie"
                  disabled={!post.eventDate}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => handleEventStartTimeChange(time)}
                  value={!post.eventStartTime.isEmpty() ? post.eventStartTime.toDate() : undefined}
                  name="eventStartTime"
                  errorText={errors.eventStartTime}
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper
                contrast
                onClear={() => handleEventEndTimeChange(undefined)}
                disabled={!props.canClearDate}>
                <TimePicker
                  floatingLabelText="Zakończenie"
                  disabled={post.eventStartTime.isEmpty()}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => handleEventEndTimeChange(time)}
                  value={!post.eventEndTime.isEmpty() ? post.eventEndTime.toDate() : undefined}
                  name="eventEndTime"
                  errorText={errors.eventEndTime}
                />
              </ClearButtonWrapper>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}>
          <Checkbox label="Powtarzaj co tydzień" defaultChecked={repeat} onCheck={handleRepeatCheck} />
        </Box>
        {repeat ? (
          <DatePicker
            sx={{ width: 200 }}
            floatingLabelText="Powtarzaj do"
            textFieldStyle={{ width: 128 }}
            onChange={(e, date) => handleRepeatUntilChange(date)}
            value={repeatUntil ? new Date(repeatUntil) : new Date(post.eventDate)}
            name="eventDate"
            errorText={errors.eventDate}
          />
        ) : null}
      </FieldsetWrapper>
    </DialogForm>
  );
};

ActivitiesDialog.propTypes = {
  post: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  canClearDate: PropTypes.bool,
  canSetDate: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  isGroupArchived: PropTypes.bool
};

ActivitiesDialog.defaultProps = {
  canClearDate: true,
  canSetDate: true
};

export default withMediaQuery()(ActivitiesDialog);
