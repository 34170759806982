import { useField, useFormikContext } from 'formik';
import { FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const FormikSwitch = ({ name, label, value, disabled }) => {
  const { setFieldValue, status } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const switchConfig = {
    ...field,
    checked: field.value,
    onChange: handleChange
  };

  return (
    <FormControlLabel
      control={<Switch {...switchConfig} value={value} disabled={status?.disabled || disabled} />}
      label={label}
    />
  );
};

FormikSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool
};

export default FormikSwitch;
