import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportAttendanceCancelAsync,
  reportGroupAttendanceCancelAsync,
  revertAttendance,
  revertGroupAttendance
} from '../../../actions/psychologicalAttendanceActions';
import ReportAbsenceDialog from '../reportDialogs/ReportAbsenceDialog';

const PsychologicalAbsenceDialog = ({
  childDetails,
  groupId,
  Ui,
  isGroup,
  journalDate,
  isAbsenceFromBlocked,
  isAbsenceToBlocked
}) => {
  const dispatch = useDispatch();

  const onSave = (e) => {
    dispatch(
      isGroup
        ? revertGroupAttendance({
            groupId: groupId,
            childId: childDetails.id,
            absenceFrom: e.absenceFrom,
            absenceTo: moment(e.absenceTo),
            absenceNotes: e.absenceNotes
          })
        : revertAttendance({
            childId: childDetails.id,
            absenceFrom: e.absenceFrom,
            absenceTo: moment(e.absenceTo),
            absenceNotes: e.absenceNotes
          })
    );
  };
  const onCancel = () => {
    dispatch(isGroup ? reportGroupAttendanceCancelAsync() : reportAttendanceCancelAsync());
  };

  return (
    <ReportAbsenceDialog
      pupilFirstName={childDetails.firstName}
      pupilLastName={childDetails.lastName}
      pupilPhotoUrl={childDetails.pupilPhotoUrl}
      pupilContract={childDetails.pupilContract}
      absenceFrom={journalDate}
      absenceTo={journalDate}
      absenceNotes=""
      isAbsenceFromBlocked={isAbsenceFromBlocked}
      isAbsenceToBlocked={isAbsenceToBlocked}
      isProcessing={Ui.isProcessing}
      isDialogOpen={Ui.isEditing}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

export default PsychologicalAbsenceDialog;
