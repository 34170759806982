import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const SimpleDialog = ({ children, title, actions, open, className, contentStyle, onClose, sx }) => {
  return (
    <>
      <Dialog className={className} open={open} fullWidth sx={sx} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={contentStyle}>{children}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </>
  );
};

SimpleDialog.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actions: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  sx: PropTypes.object,
  onClose: PropTypes.func
};

export default SimpleDialog;
