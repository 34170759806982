import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Box, MenuItem } from '@mui/material';
import SelectField from '../SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

class YearAndMonthOptions extends React.Component {
  constructor(props) {
    super(props);
    const months = this.getMonths();
    this.state = {
      year: this.props.defaultYear,
      month: this.props.defaultMonth,
      months
    };
    this.onYearSelected = this.onYearSelected.bind(this);
    this.onMonthSelected = this.onMonthSelected.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultYear !== prevProps.defaultYear || this.props.defaultMonth !== prevProps.defaultMonth) {
      this.handleDateChange(this.props.defaultYear, this.props.defaultMonth);
    }
  }

  handleDateChange(year, month) {
    this.setState({
      year,
      month
    });
  }

  onYearSelected(year) {
    this.setState({ year }, () => this.props.onSelected(this.state.year, this.state.month));
  }

  onMonthSelected(month) {
    this.setState({ month }, () => this.props.onSelected(this.state.year, this.state.month));
  }

  getMonths() {
    return moment.months().map((n, id) => {
      return { name: moment().month(id).format('MMMM'), value: moment().month(id).format('MM') };
    });
  }

  render() {
    return (
      <FieldsetWrapper
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        legend="Wybór roku i miesiąca">
        <Box
          sx={{
            width: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end'
          }}>
          <SelectField
            contrast={this.props.contrast}
            sx={{
              width: '80px',
              textAlign: 'center'
            }}
            value={this.state.year}
            disabled={this.props.disabled}
            onChange={(e) => this.onYearSelected(e.target.value)}>
            {this.props.years.map((y) => {
              return (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast={this.props.contrast}
            sx={{
              width: '120px',
              textAlign: 'center'
            }}
            value={this.state.month}
            disabled={this.props.disabled}
            onChange={(e) => this.onMonthSelected(e.target.value)}
            autoWidth={true}
            inputProps={{ 'aria-label': 'wybierz miesiąc' }}>
            {this.state.months.map((m) => {
              return (
                <MenuItem key={m.value} value={m.value}>
                  {m.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </Box>
      </FieldsetWrapper>
    );
  }
}

YearAndMonthOptions.propTypes = {
  onSelected: PropTypes.func.isRequired,
  defaultYear: PropTypes.string,
  defaultMonth: PropTypes.string,
  years: PropTypes.array,
  disabled: PropTypes.bool,
  contrast: PropTypes.bool
};

YearAndMonthOptions.defaultProps = {
  years: [
    moment().subtract(1, 'years').format('YYYY'),
    moment().format('YYYY'),
    moment().add(1, 'years').format('YYYY')
  ],
  defaultYear: moment().format('YYYY'),
  defaultMonth: moment().format('MM')
};

export default YearAndMonthOptions;
