import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as chargingSchemesActions from '../../../actions/chargingSchemesActions';
import ChargingSchemesList from './ChargingSchemesList';
import { Grid, Paper, Button } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import ChargingSchemeDialog from './dialogs/ChargingSchemeDialog';
import AssignedChildrenDialog from './assignedChildren/AssignedChildrenDialog';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { SearchBar } from '../../common/SearchBar';
import ContentWrapper from '../../common/contentWrapper';

const ChargingSchemesPage = (props) => {
  const [currentScheme, setCurrentScheme] = useState(null);
  const [showAssignedChildren, setShowAssignedChildren] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleEdit = useCallback(
    (scheme) => {
      setCurrentScheme(scheme);
      props.actions.updateChargingSchemeDetailsStart();
    },
    [props.actions, setCurrentScheme]
  );

  const handleCreate = () => {
    setCurrentScheme(null);
    props.actions.createChargingSchemeDetailsStart();
  };

  const handleSave = useCallback(
    (scheme) => {
      if (scheme.id) {
        props.actions.updateChargingSchemeDetailsAsync(scheme);
      } else {
        props.actions.createChargingSchemeDetailsAsync(scheme);
      }
    },
    [props.actions]
  );

  const handleArchive = useCallback(
    (schemeId) => {
      props.actions.archiveChargingSchemeDetailsAsync(schemeId);
    },
    [props.actions]
  );

  const handleCancel = useCallback(() => {
    if (currentScheme) {
      props.actions.updateChargingSchemeDetailsCancel();
    } else {
      props.actions.createChargingSchemeDetailsCancel();
    }
    setCurrentScheme(null);
  }, [props.actions, currentScheme, setCurrentScheme]);

  const handleShowAssignedChildren = useCallback(
    (scheme) => {
      props.actions.loadAssignedChildrenAsync(scheme.id);
      setCurrentScheme(scheme);
      setShowAssignedChildren(true);
    },
    [props.actions]
  );

  const handleHideAssignedChildren = () => {
    setCurrentScheme(null);
    setShowAssignedChildren(false);
  };

  const renderSchemeDialog = useMemo(() => {
    if (props.ui.isEditing) {
      return (
        <ChargingSchemeDialog
          onSave={handleSave}
          onCancel={handleCancel}
          isDialogOpen={props.ui.isEditing}
          isProcessing={props.ui.isProcessing}
          scheme={currentScheme}
        />
      );
    }
    return null;
  }, [props.ui.isEditing, props.ui.isProcessing, handleSave, handleCancel, currentScheme]);

  const renderAssignedChildrenDialog = useMemo(() => {
    if (showAssignedChildren) {
      return (
        <AssignedChildrenDialog
          onClose={handleHideAssignedChildren}
          isDialogOpen={showAssignedChildren}
          pupils={props.pupils}
          assignedPupilsSetup={props.details.assignedChildrenSetup}
          scheme={currentScheme}
          onAssignChildren={props.actions.AssignChildrenAsync}
          onUnassignChildren={props.actions.UnassignChildrenAsync}
        />
      );
    }
    return null;
  }, [props.pupils, props.details.assignedChildrenSetup, props.actions, showAssignedChildren, currentScheme]);

  const renderChargingSchemesList = useMemo(() => {
    return () => (
      <ChargingSchemesList
        filterText={searchText}
        schemes={props.schemes}
        onSelected={handleEdit}
        onRemove={handleArchive}
        onShowAssignedChildren={handleShowAssignedChildren}
      />
    );
  }, [props.schemes, searchText, handleEdit, handleArchive, handleShowAssignedChildren]);

  return (
    <LoadingRenderWrapper>
      <>
        <PageHeaderText title="Schematy płatności" titleIcon={<AttachMoneyIcon />} />
        <ContentWrapper>
          <Grid container>
            <Grid item xs={12} md={6}>
              <SearchBar
                label="Wyszukaj"
                SearchOnClick={(value) => {
                  setSearchText(value.target.value);
                }}
                value={searchText}
                onChange={(value) => {
                  setSearchText(value.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleCreate} sx={{ mb: 2 }} variant="contained" aria-label="Dodaj Schemat">
                Dodaj Schemat
              </Button>
            </Grid>
          </Grid>
          <Paper
            sx={{
              px: searchText && 2,
              py: searchText && 1,
              boxShadow: 'none',
              background: !searchText ? (theme) => theme.palette.background.transparent : null
            }}>
            {renderChargingSchemesList()}
          </Paper>
          {renderSchemeDialog}
          {renderAssignedChildrenDialog}
        </ContentWrapper>
      </>
    </LoadingRenderWrapper>
  );
};

ChargingSchemesPage.propTypes = {
  schemes: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  pupils: PropTypes.array.isRequired,
  details: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    schemes: state.configuration.chargingSchemes,
    ui: state.configuration.chargingSchemesUi,
    pupils: state.children,
    details: state.configuration.chargingSchemeDetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(chargingSchemesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargingSchemesPage);
