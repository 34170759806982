import BaseModel from '../../BaseModel';

export class DuesExportSettingsModel extends BaseModel {
  constructor() {
    super();
    this.email = '';
    this.additionalEmail = '';
    this.unitSymbol = '';
  }
}
