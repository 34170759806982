import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ValidationWrapper from './ValidationWrapper';

const FormContent = ({ onValidate, onValidationDone, statePathToUi, displayValidationSummary, children }) => {
  return (
    <Box>
      <ValidationWrapper
        validate={onValidate}
        onValidationDone={onValidationDone}
        statePathToUi={statePathToUi}
        displayValidationSummary={displayValidationSummary}>
        {children}
      </ValidationWrapper>
    </Box>
  );
};

FormContent.propTypes = {
  onValidate: PropTypes.func,
  onValidationDone: PropTypes.func,
  statePathToUi: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  displayValidationSummary: PropTypes.bool
};

export default FormContent;
