import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../forms/DialogForm';
import DuesExportSettingsForm from './DuesExportSettingsForm';

class DuesExportSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duesExportSettings: Object.assign({}, this.props.duesExportSettings),
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const duesExportSettings = Object.assign({}, this.state.duesExportSettings);
    duesExportSettings[field] = event.target.value;
    return this.setState({ duesExportSettings });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header="Zmiana ustawień eksportu"
        onSave={() => onSave(this.state.duesExportSettings)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.duesExportSettingsUi">
        <DuesExportSettingsForm
          duesExportSettings={this.state.duesExportSettings}
          onChange={this.handleChange}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

DuesExportSettingsDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  duesExportSettings: PropTypes.object.isRequired
};

export default DuesExportSettingsDialog;
