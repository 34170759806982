import styled from '@emotion/styled';
import { Rating, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import EggAltIcon from '@mui/icons-material/EggAlt';
import EggAltOutlinedIcon from '@mui/icons-material/EggAltOutlined';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75'
  },
  '& .MuiRating-iconHover': {
    color: '#FB5B85'
  }
});

const MealScale = ({ disabledMeals, mealsScale = [], onSelection, formname, title, type, readOnly }) => {
  const handleScaleChange = (name, value) => {
    onSelection({
      target: {
        name: formname,
        value: [...mealsScale?.filter((meal) => meal.name !== name), { name: name, value: value }]
      }
    });
  };
  return (
    <Box sx={{ diaplsy: 'flex', flexDirection: 'column' }}>
      <Typography>{title}</Typography>
      <StyledRating
        readOnly={readOnly}
        max={4}
        defaultValue={null}
        precision={1}
        value={!disabledMeals?.includes(type) ? null : mealsScale?.find((meal) => meal.name === type)?.value || null}
        disabled={!disabledMeals?.includes(type)}
        icon={<EggAltIcon fontSize="inherit" />}
        emptyIcon={
          <EggAltOutlinedIcon
            fontSize="inherit"
            sx={{ color: (theme) => theme.palette.color.wcag || theme.palette.color.color2 }}
          />
        }
        onChange={(event, newValue) => {
          handleScaleChange(type, newValue);
        }}
      />
    </Box>
  );
};

export default MealScale;
