import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

const ProgrammingFileIcon = (props) => {
  const theme = useTheme();
  const programmingFileIconColor = theme.palette.components.fileIcons.programmingFile;

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2L6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.89 22 5.99 22L18 22C19.1 22 20 21.1 20 20L20 8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20L7 20C6.44772 20 6 19.5523 6 19L6 5C6 4.44772 6.44772 4 7 4L12 4C12.5523 4 13 4.44772 13 5L13 8C13 8.55228 13.4477 9 14 9L17 9C17.5523 9 18 9.44772 18 10L18 19Z"
        fill={programmingFileIconColor}
      />
      <path
        d="M16.9383 13.85L14.3861 11.2979C14.3067 11.2185 14.1663 11.2185 14.087 11.2979L13.5337 11.8512C13.451 11.9338 13.451 12.0678 13.5337 12.1504L15.383 13.9995L13.5338 15.849C13.4511 15.9316 13.4511 16.0656 13.5338 16.1482L14.0871 16.7015C14.1268 16.7412 14.1806 16.7637 14.2367 16.7637C14.2927 16.7637 14.3466 16.7412 14.3863 16.7015L16.9383 14.1495C17.0209 14.0667 17.0209 13.9327 16.9383 13.85Z"
        fill={programmingFileIconColor}
      />
      <path
        d="M10.4665 15.849L8.61747 13.9998L10.4668 12.1506C10.5065 12.111 10.5288 12.0572 10.5288 12.001C10.5288 11.9449 10.5066 11.8911 10.4668 11.8514L9.9135 11.2981C9.87382 11.2584 9.81996 11.2361 9.76388 11.2361C9.7078 11.2361 9.65394 11.2584 9.61425 11.2981L7.06198 13.85C6.97934 13.9327 6.97934 14.0667 7.06198 14.1494L9.61415 16.7015C9.65383 16.7411 9.70769 16.7636 9.76377 16.7636C9.81985 16.7636 9.87371 16.7411 9.9134 16.7015L10.4667 16.1482C10.5493 16.0656 10.5493 15.9317 10.4665 15.849Z"
        fill={programmingFileIconColor}
      />
      <path
        d="M13.4513 10.4392C13.4244 10.3899 13.3791 10.3533 13.3253 10.3374L12.8768 10.205C12.7648 10.1718 12.647 10.2361 12.6139 10.3481L10.5316 17.4C10.5157 17.4538 10.5219 17.5117 10.5486 17.5609C10.5754 17.6103 10.6208 17.6468 10.6746 17.6628L11.1231 17.7952C11.1431 17.8011 11.1633 17.804 11.1831 17.804C11.2744 17.804 11.3588 17.7443 11.386 17.6521L13.4683 10.6002C13.4842 10.5463 13.4781 10.4884 13.4513 10.4392Z"
        fill={programmingFileIconColor}
      />
    </SvgIcon>
  );
};

export default ProgrammingFileIcon;
