import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportsConfigurationActions from '../../../actions/reportsConfigurationActions';
import { Box, Button, Paper } from '@mui/material';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import ReportsList from './ReportsList';
import ReportsConfigurationDialog from './dialogs/ReportsConfigurationDialog';

//v4
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

class ReportsConfigurationPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentRelief: null
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleEdit() {
    this.props.actions.updateReportsConfigurationStart();
  }

  handleSave(reports) {
    this.props.actions.updateReportsConfigurationAsync(reports);
  }

  handleCancel() {
    this.props.actions.updateReportsConfigurationCancel();
  }

  renderDialog() {
    if (this.props.ui.isEditing) {
      return (
        <ReportsConfigurationDialog
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          reports={this.props.reports}
          isDialogOpen={this.props.ui.isEditing}
          isProcessing={this.props.ui.isProcessing}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Ustawienia raportów" titleIcon={<InsertChartIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" aria-label="Zmień ustawienia" onClick={this.handleEdit} sx={{ mb: 2 }}>
                Zmień ustawienia
              </Button>
            </Box>
            <Paper sx={{ p: 2 }}>
              <ReportsList reports={this.props.reports} disabled={true} />
            </Paper>
            {this.renderDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ReportsConfigurationPage.propTypes = {
  reports: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reports: state.configuration.reportsConfiguration,
    ui: state.configuration.reportsConfigurationUi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(reportsConfigurationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsConfigurationPage);
