import api from '../api/psychologicalApi';
import diaryApi from '../api/DigitalDiaryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadIndividualClassesAttendanceData() {
  return (dispatch) => {
    return api
      .loadChildren()
      .then((payload) => dispatch({ type: types.LOAD_INDIVIDUAL_CLASSES_ATTENDANCE, payload }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy obecności`));
        return logger.error(error);
      });
  };
}

export function loadIndividualClassesDataDetails(id) {
  return (dispatch) => {
    return api
      .loadChildrenDetails(id)
      .then((payload) => dispatch({ type: types.LOAD_INDIVIDUAL_CLASSES, payload, id }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy obecności`));
        return logger.error(error);
      });
  };
}

export function loadIndividualClassesWorkPlan(date, helpId) {
  return (dispatch) => {
    return api
      .loadIndividualWorkPlan(date, helpId)
      .then((response) => dispatch({ type: types.LOAD_INDIVIDUAL_CLASSES_CALENDAR, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać rozkładu zajęć`));
        return logger.error(error);
      });
  };
}

export function setIndividualClassesDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_CLASSES_START });
  };
}

export function setIndividualClassesDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_CLASSES_CANCEL });
  };
}

export function addIndividualClasses(data) {
  return (dispatch) => {
    return diaryApi
      .addCustomNewEvent(data, data.eventType)
      .then((response) => dispatch({ type: types.ADD_INDIVIDUAL_CLASSES, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać wpisu`));
        return logger.error(error);
      });
  };
}

export function editIndividualClasses(data) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_CLASSES_SUBMIT });
    return diaryApi
      .editCustomEvent(data)
      .then(() => dispatch({ type: types.EDIT_INDIVIDUAL_CLASSES, data }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować wpisu`));
        return logger.error(error);
      });
  };
}

export function deleteIndividualClasses(postId) {
  return (dispatch) => {
    return diaryApi
      .deleteCustomEvent(postId)
      .then(() => dispatch({ type: types.DELETE_INDIVIDUAL_CLASSES, postId }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu`));
        return logger.error(error);
      });
  };
}

export function setGeneralDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_PROGRAM_START });
  };
}

export function setGeneralDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_PROGRAM_CANCEL });
  };
}

export function createNewProgramElement(data, assistanceId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_PROGRAM_SUBMIT });
    return api
      .createWorkPlanElement(data, assistanceId)
      .then((id) => dispatch({ type: types.ADD_INDIVIDUAL_PROGRAM, data, id }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać elementu`));
        return logger.error(error);
      });
  };
}

export function editProgramElement(data, assistanceId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_PROGRAM_SUBMIT });
    return api
      .editWorkPlanElement(data, assistanceId)
      .then(() => dispatch({ type: types.EDIT_INDIVIDUAL_PROGRAM, data }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}

export function deleteProgramElement(data, assistanceId) {
  return (dispatch) => {
    return api
      .deleteWorkPlanElement(data, assistanceId)
      .then(() => dispatch({ type: types.DELETE_INDIVIDUAL_PROGRAM, data }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}
