import { Stack } from "@mui/material";
import React from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import SelectField from "../../../common/SelectField";
import TimePicker from "../../../common/TimePicker";
import DatePicker from "../../../common/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { editNotification, newNotification } from "../../../../actions/psychologicalActions";
import DialogForm from "../../../forms/DialogForm";
import { NotifyValidator } from "./NotifyValidator";
import moment from "moment";
import { TimeModel } from "../../../../models/TimeModel";

const NotifyDialog = ({ handleCancel, id, selectedNotify, isReadonly, dialogTitle }) => {
  const [title, setTitle] = useState(selectedNotify?.row.name);
  const [type, setType] = useState(selectedNotify?.row.type || "info");
  const [date, setDate] = useState(selectedNotify?.row.dateOfMeeting || null);
  const [time, setTime] = useState(selectedNotify ? new TimeModel().parse(selectedNotify?.row.hourOfMeeting)?.toDate() : null);
  const [place, setPlace] = useState(selectedNotify?.row.placeOfMeeting);
  const [purpose, setPuproseOfMeeting] = useState(selectedNotify?.row.purposeOfMeeting);
  const dispatch = useDispatch();
  const address = useSelector((state) => state.configuration.unit.address);

  const Ui = useSelector((state) => state.psychologicalHelpUi.notify);
  const [errors, setErrors] = useState();

  const handleSave = () => {
    const data = {
      id: id,
      name: title,
      type: type,
      dateOfMeeting: date,
      hourOfMeeting: moment(time).format("HH:mm"),
      placeOfMeeting: place,
      purposeOfMeeting: purpose
    };
    selectedNotify ? dispatch(editNotification(data, selectedNotify.row.id, id)) : dispatch(newNotification(data, id));
  };

  useEffect(() => {
    setPlace(`${address.city} ${address.address}`);
  }, [address.address, address.city]);

  return (
    <DialogForm
      header={dialogTitle}
      maxWidth="md"
      hideSubmit={isReadonly}
      onSave={handleSave}
      isEditing={Ui.isEditing}
      isProcessing={Ui.isProcessing}
      onCancel={handleCancel}
      isDialogOpen={true}
      onValidate={() =>
        new NotifyValidator().validate({
          id: id,
          name: title,
          type: type,
          dateOfMeeting: date,
          hourOfMeeting: time,
          placeOfMeeting: place,
          purposeOfMeeting: purpose
        })
      }
      onValidationDone={(errors) => setErrors({ errors })}
      statePathToUi=""
      requiredFieldText>
      <Box sx={{ display: "flex" }}>
        <SelectField
          errorText={errors?.errors?.type}
          defaultValue="info"
          contrast
          disabled={isReadonly}
          fullWidth
          floatingLabelText="Typ zawiadomienia"
          floatingLabelFixed={false}
          id="objectType"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}>
          <MenuItem key="informacja" value="info">
            Informacja
          </MenuItem>
          <MenuItem key="zawiadomienie" value="notify">
            Zawiadomienie
          </MenuItem>
        </SelectField>
      </Box>
      <Stack direction={{ xs: "column", sm: "row" }} sx={{ my: 1 }}>
        <DatePicker
          disabled={isReadonly}
          unlimitedMaxDate
          errorText={errors?.errors?.dateOfMeeting}
          hintText="Data wydarzenia*"
          floatingLabelText="Data wydarzenia*"
          name="date"
          value={date}
          onChange={(empty, date) => {
            setDate(date);
          }}
        />
        <TimePicker
          disabled={isReadonly}
          errorText={errors?.errors?.hourOfMeeting}
          floatingLabelText="Godzina spotkania*"
          value={time}
          onChange={(e) => setTime(e)}
          name="time"
        />
      </Stack>
      <TextField
        disabled={isReadonly}
        error={errors?.errors?.placeOfMeeting}
        helperText={errors?.errors?.placeOfMeeting}
        multiline
        fullWidth
        variant="standard"
        sx={{ my: 1 }}
        id="cause"
        label="Miejsce spotkania*"
        value={place}
        onChange={(e) => setPlace(e.target.value)}
      />
      <TextField
        disabled={isReadonly}
        error={errors?.errors?.purposeOfMeeting}
        helperText={errors?.errors?.purposeOfMeeting}
        multiline
        fullWidth
        variant="standard"
        sx={{ my: 1 }}
        id="purpose"
        label="Cel spotkania*"
        value={purpose}
        onChange={(e) => setPuproseOfMeeting(e.target.value)}
      />
      <TextField
        disabled={isReadonly}
        multiline
        error={errors?.errors?.name}
        helperText={errors?.errors?.name}
        fullWidth
        variant="standard"
        sx={{ my: 1 }}
        id="cause"
        label="Treść zawiadomienia*"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </DialogForm>
  );
};

export default NotifyDialog;
