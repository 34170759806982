import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@mui/material/Paper';
import SaveCancelButtons from './buttons/SaveCancelButtons';
import FormContent from './FormContent';
import { Box, Divider, Typography } from '@mui/material';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.state = {
      showAllValidationErrors: false
    };
  }

  handleSave() {
    if (this.props.onValidate && !this.props.onValidate().isValid) {
      this.setState({ showAllValidationErrors: true });
    } else {
      this.props.onSave();
    }
  }

  renderHeader() {
    if (this.props.hasHeader) {
      return <Typography variant="h6">{this.props.header}</Typography>;
    }
    return null;
  }

  renderDialogButtons() {
    return (
      <SaveCancelButtons onSave={this.handleSave} onCancel={this.props.onCancel} inProgress={this.props.isProcessing} />
    );
  }

  render() {
    const { children, onValidate, onValidationDone, statePathToUi } = this.props;
    return (
      <Paper sx={{ p: 2 }}>
        {this.renderHeader()}
        <FormContent
          validate={onValidate}
          onValidationDone={onValidationDone}
          statePathToUi={statePathToUi}
          showAllErrors={this.state.showAllValidationErrors}>
          {children}
        </FormContent>
        <Divider
          sx={{
            mt: 2,
            mx: -2
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2
          }}>
          {this.renderDialogButtons()}
        </Box>
      </Paper>
    );
  }
}

Form.propTypes = {
  hasHeader: PropTypes.bool,
  header: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  onValidationDone: PropTypes.func,
  statePathToUi: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

Form.defaultProps = {
  hasHeader: true
};

export default Form;
