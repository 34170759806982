import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';
import { List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';
import { routePaths, fromTemplate } from '../../../routePaths';
import LinkAdapter from '../../common/LinkAdapter/LinkAdapter';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';

const GroupChildrenList = ({ pupils, onRemoveChild }) => {
  const theme = useTheme();

  const sortedPupils = useMemo(() => {
    return pupils.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }, [pupils]);

  return (
    <List>
      {sortedPupils.map((pupil) => {
        return (
          <ListItem key={pupil.id}>
            <ListItemAvatar>
              <PersonAvatar
                initials
                alt={pupil.childFirstName}
                url={pupil.photoUrl}
                firstName={pupil.firstName}
                lastName={pupil.lastName}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  component={LinkAdapter}
                  to={fromTemplate(routePaths.childDetails, pupil.id)}
                  sx={{
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    color: theme.palette.color.color2,
                    textDecoration: 'none'
                  }}>
                  {`${pupil.lastName} ${pupil.firstName}`}
                </Typography>
              }
              secondary={
                <Typography sx={{ opacity: 0.7 }}>
                  {pupil.enrolledToGroup
                    ? `W grupie od ${moment(pupil.enrolledToGroup.enrolledAt).format('YYYY-MM-DD')}`
                    : ''}{' '}
                  | Data urodzenia {moment(pupil.birthDate).format('YYYY-MM-DD')}
                </Typography>
              }
            />
            <ActionConfirmationButton
              actionLabel="odłącz"
              confirmationText="Czy na pewno chcesz odłączyć dziecko od grupy?"
              confirmationTitle="Odłącz dziecko"
              confirmLabel="odłącz"
              variant="clearText"
              onConfirm={() => onRemoveChild(pupil.id)}
              onCancel={() => {}}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

GroupChildrenList.propTypes = {
  pupils: PropTypes.array.isRequired,
  onRemoveChild: PropTypes.func.isRequired
};

export default GroupChildrenList;
