import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel
} from '@mui/material';
import Header from '../../Components/Multimedia/Header';
import MultimediaList from '../../Components/Multimedia/List/MultimediaList';
import ConfirmationDialog from '../../../forms/ConfirmationDialog';
import DialogContent from '../../Components/Multimedia/MoveDialog/DialogContent';
import MoveDialog from '../../Components/Multimedia/MoveDialog/MoveDialog';
import { useDispatch, useSelector } from 'react-redux';
import * as galleryActions from '../../../../actions/galleryActions';
import moment from 'moment';
import { useIsMount } from '../../../../utils/useIsMount';
import * as SortTypes from '../../../../constants/sortTypes';
import * as Status from '../../../../constants/status';
import { downloadAndZip } from '../../../../utils/prepareZip';
import * as rightBottomNotificationTypes from '../../../../constants/rightBottomNotificationTypes';
import * as notificationActions from '../../../../actions/notificationActions';
import localStorageProvider from '../../../../utils/localStorageProvider';
import { useRefState } from '../../../../utils/useRefState';
import uuid from 'uuid/v4';
import { uploadFilesWithNotify } from '../../../../utils/uploadFilesWithNotify';
import { useDropzone } from 'react-dropzone';
import UploadBackdrop from '../../../common/UploadBackdrop/UploadBackdrop';
import UploadDialog from '../../Components/Multimedia/MoveDialog/UploadDialog';

const Multimedia = ({ multimedia, albums, album, isEditAvailable }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = useState(false);
  const [isMultipleEdit, setIsMultipleEdit] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dateFrom, dateToRef, setDateFrom] = useRefState(null);
  const [dateTo, dateFromRef, setDateTo] = useRefState(null);
  const [sortType, sortTypeRef, setSortType] = useRefState(SortTypes.NEWEST);
  const [fetchCount, setFetchCount] = useState(8);
  const token = localStorageProvider.getItem('authToken');
  const scrollRef = useRef();
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);

  const isLoading = useSelector((state) => state.gallery.multimediaUi.isLoading);
  const isSuccess = useSelector((state) => state.gallery.multimediaUi.isSuccess);
  const target = useSelector((state) => state.gallery.multimediaUi.target);
  const dispatch = useDispatch();
  const isFirstRender = useIsMount();
  const { list: multimediaList, totalFetchCount } = multimedia;

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (target === galleryActions.loadingTypes.UPDATE) setIsMoveDialogOpen(false);
      if (target === galleryActions.loadingTypes.DELETE) setIsDeleteDialogOpen(false);
      if (!isMultipleEdit) setSelectedItems([]);
    }
  }, [isLoading]);

  const handleSelectAll = () => {
    setSelectedItems(multimediaList);
  };

  const sendRequestWithData = async (newSortType, newFetchCount) => {
    if (
      (dateFrom && !dateTo && moment(dateFrom).isValid()) ||
      (dateTo && !dateFrom && moment(dateTo).isValid()) ||
      (!dateFrom && !dateTo) ||
      (dateTo && dateFrom && moment(dateTo).isValid() && moment(dateFrom).isValid())
    ) {
      return dispatch(
        galleryActions.fetchMultimedia(album.id, {
          ...(dateFrom && {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD')
          }),
          ...(dateTo && {
            dateTo: moment(dateTo).format('YYYY-MM-DD')
          }),
          fetchCount: newFetchCount || fetchCount,
          sortType: newSortType || sortType
        })
      );
    }
    return null;
  };

  useEffect(() => {
    if (!isFirstRender) {
      sendRequestWithData();
    }
  }, [dateFrom, dateTo]);

  const handleSort = (value) => {
    setSortType(value);
    sendRequestWithData(value);
  };

  const handleFetchCount = async () => {
    const newFetchCount = fetchCount + 8;
    setFetchCount(newFetchCount);
    await sendRequestWithData(null, newFetchCount);
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    });
  };

  const handleOnSelect = (tile) => {
    if (selectedItems.some((item) => tile.id === item.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== tile.id));
    } else {
      setSelectedItems([...selectedItems, tile]);
    }
  };

  const handleDeleteOne = (tile) => {
    setSelectedItems([tile]);
    setIsDeleteDialogOpen(true);
  };

  const handleMoveOne = (tile) => {
    setSelectedItems([tile]);
    setIsMoveDialogOpen(true);
  };

  const handleDownloadMore = () => {
    const notifyUuid = uuid();
    downloadAndZip(
      selectedItems,
      () =>
        dispatch(
          notificationActions.startRightBottomNotification(notifyUuid, rightBottomNotificationTypes.DOWNLOAD, {
            status: Status.PENDING
          })
        ),
      () =>
        dispatch(
          notificationActions.updateRightBottomNotification(notifyUuid, {
            status: Status.SUCCESS
          })
        ),
      () =>
        dispatch(
          notificationActions.updateRightBottomNotification(notifyUuid, {
            status: Status.ERROR
          })
        ),
      'Wybrane-zdjęcia'
    );
  };

  const handleMoveMultimedia = ({ album: toAlbum }) =>
    dispatch(
      galleryActions.moveMultimedia(
        album,
        toAlbum,
        selectedItems.map((item) => item.id)
      )
    );

  const handleDeleteMultimedia = () => {
    dispatch(
      galleryActions.deleteMultimedia(
        album.id,
        selectedItems.map((item) => item.id)
      )
    ).then(() => {
      sendRequestWithData();
    });
  };

  const handleAddMultimedia = useCallback((files, descriptionArray) => {
    setFetchCount(fetchCount);
    const maxFileSize = 20 * 1024 * 1024;
    const invalidFiles = [];
    const validatedFiles = files.filter((file) => {
      if (file.size > maxFileSize) {
        invalidFiles.push({
          file: { name: file.name },
          errorMessage: 'Plik przekroczył maksymalny rozmiar wynoszący 10mb'
        });
        return false;
      }
      if (!/.*?(gif|jpg|jpeg|png|tiff)$/.test(file.type)) {
        invalidFiles.push({
          file: { name: file.name },
          errorMessage: 'Nieodpowiednie rozszerzenie pliku'
        });
        return false;
      }
      return true;
    });
    const notifyUuid = uuid();
    uploadFilesWithNotify(
      validatedFiles,
      invalidFiles,
      files.length,
      token,
      () => {
        dispatch(
          notificationActions.startRightBottomNotification(notifyUuid, rightBottomNotificationTypes.GALLERY_UPLOAD, {
            totalCount: files.length,
            status: Status.PENDING,
            actualCount: 0,
            albumName: album.name
          })
        );
      },
      (actualCount) => {
        dispatch(notificationActions.updateRightBottomNotification(notifyUuid, { actualCount: actualCount + 1 }));
      },
      (status, newMultimedia, errorMultimedia) => {
        dispatch(
          notificationActions.updateRightBottomNotification(notifyUuid, {
            status,
            newMultimedia,
            errorMultimedia
          })
        );
      },
      (newMultimedia, status) => {
        dispatch(
          galleryActions.addMultimedia(
            notifyUuid,
            { ...album, updatedAt: new Date() },
            newMultimedia,
            dateFromRef.current,
            dateToRef.current,
            sortTypeRef.current,
            fetchCount,
            status
          )
        );
      },
      descriptionArray
    );
  }, []);

  const handleUploadDrop = (file) => {
    setUploadOpen(true);
    setUploadFile(file);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleUploadDrop,
    noClick: true,
    noKeyboard: true
  });
  const { ref, ...rootProps } = getRootProps();

  const isListNotEmpty = !!multimediaList && multimediaList.length > 0;
  return (
    <Box>
      <Paper sx={{ mt: 2, pb: 5, position: 'relative' }} {...rootProps} ref={ref}>
        <FormControl>
          <InputLabel htmlFor="file-upload-select"> Wybierz plik</InputLabel>
          <input {...getInputProps()} aria-label="Upload plików" id="file-upload-select" />
        </FormControl>

        <UploadBackdrop
          isOpen={isDragActive}
          isMobile={isMobile}
          text={
            <>
              Upuść multimedia, <br /> aby dodać do albumu
            </>
          }
        />
        <Header
          isMultipleEdit={isMultipleEdit}
          areEditButtonsDisabled={selectedItems.length === 0}
          isEditAvailable={isEditAvailable}
          isListNotEmpty={isListNotEmpty}
          isFiltered={dateTo || dateFrom}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          dateTo={dateTo}
          sortType={sortType}
          onSelectAll={handleSelectAll}
          handleDownloadMore={handleDownloadMore}
          handleSort={handleSort}
          openAddDialog={() => open()}
          openDeleteDialog={() => setIsDeleteDialogOpen(true)}
          openMoveDialog={() => setIsMoveDialogOpen(true)}
          setMultipleEdit={() => {
            if (isMultipleEdit) setSelectedItems([]);
            setIsMultipleEdit(!isMultipleEdit);
          }}
        />
        {isLoading && target === galleryActions.loadingTypes.FETCH ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress size={80} />
          </Grid>
        ) : (
          <Box sx={{ mx: 10 }}>
            <MultimediaList
              multimediaList={multimediaList}
              isMultipleEdit={isMultipleEdit}
              isFiltered={dateTo || dateFrom}
              isEditAvailable={isEditAvailable}
              selectedItems={selectedItems}
              handleOnSelect={handleOnSelect}
              deleteOne={handleDeleteOne}
              moveOne={handleMoveOne}
            />
          </Box>
        )}
      </Paper>
      {totalFetchCount > fetchCount && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" aria-label="Pokaż więcej" sx={{ py: 1, px: 8 }} onClick={handleFetchCount}>
            Pokaż więcej
          </Button>
        </Box>
      )}

      <Box ref={scrollRef} />

      <ConfirmationDialog
        confirmationTitle={`Czy napewno chcesz usunąć ${selectedItems.length === 1 ? 'zdjęcie' : 'zdjęcia'}?`}
        onConfirm={handleDeleteMultimedia}
        inProgress={isLoading && target === galleryActions.loadingTypes.DELETE}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
          if (!isMultipleEdit) setTimeout(() => setSelectedItems([]), 300);
        }}
        confirmLabel="Usuń"
        isOpen={isDeleteDialogOpen}
        isSecondary>
        <DialogContent selectedItems={selectedItems} />
      </ConfirmationDialog>
      <MoveDialog
        isDialogOpen={isMoveDialogOpen}
        onClose={() => {
          setIsMoveDialogOpen(false);
          if (!isMultipleEdit) setTimeout(() => setSelectedItems([]), 300);
        }}
        onSubmit={handleMoveMultimedia}
        selectedItems={selectedItems}
        inProgress={isLoading && target === galleryActions.loadingTypes.UPDATE}
        albums={albums.filter((item) => item.id !== album.id)}
      />
      <UploadDialog open={uploadOpen} setOpen={setUploadOpen} file={uploadFile} handleUpload={handleAddMultimedia} />
    </Box>
  );
};

Multimedia.propTypes = {
  multimedia: PropTypes.object.isRequired,
  albums: PropTypes.array.isRequired,
  album: PropTypes.object.isRequired,
  isEditAvailable: PropTypes.bool.isRequired
};

export default Multimedia;
