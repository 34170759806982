import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import ChildOpinionForm from './ChildOpinionForm';
import { OpinionFileModel, OpinionModel } from '../../../models/children/ChildModels';
import { OpinionFileModelValidator } from '../../../models/children/ChildModelsValidator';
import EmptyState from '../../common/EmptyState';
import ChildFileAttachmentsList from '../fileAttachments/ChildFileAttachmentsList';
import { ROOT_URL } from '../../../api/ApiBase';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ChildOpinionCard = (props) => {
  const [activeOpinion, setActiveOpinion] = useState(new OpinionFileModel().assign({ fileInfo: new OpinionModel() }));
  const [showData, setShowData] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpinionTypeChange = (opinionType) => {
    setActiveOpinion(
      Object.assign(
        {},
        activeOpinion,
        {
          opinionType: opinionType,
          opinionTypeOtherValue: ''
        },
        { fileInfo: { ...activeOpinion.fileInfo } }
      )
    );
  };
  const handleOpinionReasonChange = (value) => {
    setActiveOpinion(Object.assign({}, activeOpinion, { reason: value }));
  };
  const handleOpinionNumberChange = (value) => {
    setActiveOpinion(Object.assign({}, activeOpinion, { opinionNumber: value }));
  };
  const handleNeedHeloChange = (val) => {
    setActiveOpinion(Object.assign({}, activeOpinion, { needHelp: val }));
  };
  const handleOpinionTitleChange = (title) => {
    setActiveOpinion(
      Object.assign({}, activeOpinion, { name: title }, { fileInfo: { ...activeOpinion.fileInfo, title } })
    );
  };
  const handleOpinionTypeValueChange = (value) => {
    setActiveOpinion(Object.assign({}, activeOpinion, { opinionTypeOtherValue: value }));
  };

  const handleOpinionReleasedByChange = (releasedBy) => {
    setActiveOpinion(
      Object.assign({}, activeOpinion, {
        fileInfo: { ...activeOpinion.fileInfo, releasedBy }
      })
    );
  };

  const handleOpinionDateChange = (date) => {
    setActiveOpinion(
      Object.assign({}, activeOpinion, {
        fileInfo: { ...activeOpinion.fileInfo, date }
      })
    );
  };
  const handleHelpDateStartChange = (helpDateStart) => {
    setActiveOpinion(Object.assign({}, activeOpinion, { helpDateStart: helpDateStart }));
  };
  const handleHelpDateEndChange = (helpDateEnd) => {
    setActiveOpinion(Object.assign({}, activeOpinion, { helpDateEnd: helpDateEnd }));
  };

  const handleSuperTextEditor = (comments) => {
    setActiveOpinion(
      Object.assign({}, activeOpinion, {
        fileInfo: { ...activeOpinion.fileInfo, comments }
      })
    );
  };

  const handleDownloadFile = (fileId) => {
    const path = `${ROOT_URL}/children/${props.pupilId}/files/${fileId}`;
    window.open(path, '_blank');
  };

  const handleSaveOpinion = () => {
    props.onAdd(activeOpinion);
  };

  const handleFilePicked = (file) => {
    setActiveOpinion(
      Object.assign({}, new OpinionFileModel().assign(activeOpinion, { fileInfo: new OpinionModel() }), { file })
    );
  };

  const handleCancel = () => {
    setActiveOpinion(new OpinionFileModel().assign({ fileInfo: new OpinionModel() }));
    props.onCancel();
  };

  const renderEditForm = (readonly = false) => {
    return (
      <ChildOpinionForm
        onTitleChange={handleOpinionTitleChange}
        onReleasedByChange={handleOpinionReleasedByChange}
        onDateChange={handleOpinionDateChange}
        onTypeChange={handleOpinionTypeChange}
        onTypeValueChange={handleOpinionTypeValueChange}
        onNumberChange={handleOpinionNumberChange}
        handleFilePicked={handleFilePicked}
        onNeedHelp={handleNeedHeloChange}
        onDateStartChange={handleHelpDateStartChange}
        onDateEndChange={handleHelpDateEndChange}
        onReasonChange={handleOpinionReasonChange}
        onSuperTextEditorChange={(comments) => handleSuperTextEditor(comments)}
        errors={errors}
        fileAttachment={activeOpinion}
        readonly={readonly}
      />
    );
  };

  const renderReadonlyForm = () => {
    return (
      <ChildFileAttachmentsList
        attachments={props.attachments}
        onRemove={props.onRemove}
        onDownloadFile={handleDownloadFile}
        onShowData={(id) => {
          const attachment = props.attachments.find((a) => a.fileId === id);
          if (!!attachment) {
            setShowData(true);
            setActiveOpinion(new OpinionFileModel().assign(attachment));
          }
        }}
      />
    );
  };

  const renderReadonlyAttachmentData = () => {
    return (
      <Dialog
        open={showData}
        onClose={() => {
          setShowData(false);
          setActiveOpinion(new OpinionFileModel().assign({ fileInfo: new OpinionModel() }));
        }}>
        <DialogTitle>Opinie i orzeczenia</DialogTitle>
        <DialogContent>{renderEditForm(true)}</DialogContent>
        <DialogActions>
          <Button
            variant="outlinedContrast"
            onClick={() => {
              setShowData(false);
              setActiveOpinion(new OpinionFileModel().assign({ fileInfo: new OpinionModel() }));
            }}>
            zamknij
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <Box>
      {renderReadonlyAttachmentData()}
      <CardDialogEditForm
        title="Opinie i orzeczenia"
        isInitiallyExpanded={props.isInitiallyExpanded}
        onSave={handleSaveOpinion}
        onCancel={handleCancel}
        onAction={props.onCreate}
        onValidate={() => new OpinionFileModelValidator().validate(activeOpinion)}
        onValidationDone={(err) => setErrors(err)}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        statePathToUi="childDetailsUi.opinions"
        actionLabel="Dodaj nową"
        actionTitle="Pomoc psychologiczno-pedagogiczna"
        emptyForm={<EmptyState message="Brak opinii" contrast />}
        readonlyForm={renderReadonlyForm()}
        editForm={renderEditForm()}
        isEmpty={props.attachments < 1}
      />
    </Box>
  );
};

ChildOpinionCard.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired,
  pupilId: PropTypes.string.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default ChildOpinionCard;
