import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import AccessibleTooltip from './AccessibleTooltip';

const IconButtonPipe = React.forwardRef(({ sx, className, onClick, children, tooltip, type = null, ...rest }, ref) => {
  const renderButton = (optionalRef) => (
    <IconButton
      ref={optionalRef}
      className={className}
      onClick={onClick}
      type={type}
      {...rest}
      size="large"
      sx={{ ...sx }}>
      {children}
    </IconButton>
  );

  return tooltip ? <AccessibleTooltip text={tooltip}>{renderButton()}</AccessibleTooltip> : renderButton(ref);
});

IconButtonPipe.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
  type: PropTypes.string,
  sx: PropTypes.object
};

export default IconButtonPipe;
