import { Box } from '@mui/material';
import Checkbox from '../../common/Checkbox';
import PropTypes from 'prop-types';
import DatePickerAndGroupTemplate from './common/DateAndGroupPickerTemplate';
import { ReportTypes } from '../../../constants/reportTypes';

const ChildrenAbsenceReportedAtDetailsReport = ({
  groupId,
  startDate,
  endDate,
  groups,
  onChange,
  errors,
  division
}) => {
  return (
    <Box>
      <DatePickerAndGroupTemplate
        legend={`Pola dla generowania raportu ${ReportTypes.childrenAttendanceInHoursDuesXls.name}`}
        groupId={groupId}
        startDate={startDate}
        endDate={endDate}
        groups={groups}
        onChange={onChange}
        errors={errors}
      />
      <Checkbox
        label="Podział ze względu na osobę zgłaszającą"
        defaultChecked={division}
        onCheck={(event, isChecked) => onChange({ target: { name: 'division', value: isChecked } })}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

ChildrenAbsenceReportedAtDetailsReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,

  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  division: PropTypes.bool
};

export default ChildrenAbsenceReportedAtDetailsReport;
