import { Chip, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';

const NumberAvatar = ({ sx, tooltipList }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (tooltipList.length) {
      const str = tooltipList.join(', ');
      setTitle(str);
    }
  }, [tooltipList]);

  return (
    <Tooltip title={title} arrow>
      <Chip
        sx={{
          ...sx,
          display: 'flex',
          alignItems: 'center',
          '& .MuiChip-label': {
            p: 0,
            pt: 0.5
          }
        }}
        label={`+${tooltipList.length}`}></Chip>
    </Tooltip>
  );
};
export default NumberAvatar;
