/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function unitUiReducer(state = initialState.configuration.unitPenaltiesUi, action) {
  switch (action.type) {
    case types.UPDATE_UNIT_PENALTIES: {
      return UiModel.start();
    }
    case types.UPDATE_UNIT_PENALTIES_CANCEL: {
      return UiModel.cancel();
    }
    case types.UPDATE_UNIT_PENALTIES_SUBMIT: {
      return UiModel.submit();
    }
    case types.UPDATE_UNIT_PENALTIES_SUCCESS: {
      return UiModel.success();
    }
    case types.UPDATE_UNIT_PENALTIES_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.configuration.unitPenaltiesUi);
    }
    default:
      return state;
  }
}
