const WEIGHTS = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
const LENGTH = 11;
const MODULO = 10;

function decodeBirthDate(peselString) {
  const digits = peselString.split('').map((c) => parseInt(c, 10));
  let year = 1900 + digits[0] * 10 + digits[1];

  if (digits[2] >= 2 && digits[2] < 8) {
    year += Math.floor(digits[2] / 2) * 100;
  }

  if (digits[2] >= 8) {
    year -= 100;
  }

  const month = (digits[2] % 2) * 10 + digits[3];
  const day = digits[4] * 10 + digits[5];
  return { year, month, day };
}

class Pesel {
  constructor(peselString) {
    this.value = peselString;
  }

  decode() {
    const birthday = decodeBirthDate(this.value);

    return {
      ordinal: +this.value.substring(6, 10),
      checksum: +this.value[10],
      sex: this.value[9] % 2 === 0 ? 'F' : 'M',
      year: birthday.year,
      month: birthday.month,
      day: birthday.day
    };
  }

  isValid() {
    if (typeof this.value === 'string' && this.value.length === LENGTH) {
      let i;
      let sum = 0;
      const digits = [];

      for (i = 0; i < WEIGHTS.length; i++) {
        digits.push(+this.value[i]);
        sum += digits[i] * WEIGHTS[i];
      }
      const checksum = (10 - (sum % MODULO)) % 10;
      return checksum === +this.value[LENGTH - 1];
    }

    return false;
  }
}

export default Pesel;
