import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import RichMultiSelectField from './RichMultiSelectField';

const FormikRichMultiSelectField = (props) => {
  const { name } = props;
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const error = meta.touched && !!meta.error;

  return (
    <RichMultiSelectField
      value={field.value}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
      }}
      error={error}
      errorText={meta.error}
      onDelete={(value) => {
        setFieldValue(name, value);
      }}
      {...props}
    />
  );
};

FormikRichMultiSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  selectElement: PropTypes.node,
  chipElement: PropTypes.node,
  specialOptions: PropTypes.array,
  isSelectAll: PropTypes.bool,
  specialMenuOptions: PropTypes.node
};

export default FormikRichMultiSelectField;
