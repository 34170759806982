import { IconButton, Paper, Popper, TextField, Tooltip } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import GifIcon from '@mui/icons-material/GifBoxOutlined';
import Masonry from '@mui/lab/Masonry';
import axios from 'axios';

const GifSelector = ({ onChange, setGif, sx }) => {
  const apikey = 'AIzaSyC3jnX6iv9wBnyAvBHEYgUcuUy41w9uc8w';
  const [featuredSrc, setSrc] = useState();
  const [searchText, setSearchText] = useState(undefined);

  const getFeatured = () => {
    axios
      .get('https://tenor.googleapis.com/v2/categories?key=' + apikey + '&client_key=4parents')
      .then((res) => {
        setSrc(res.data.tags);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const getBySearch = () => {
      axios
        .get(
          'https://tenor.googleapis.com/v2/search?q="' +
            searchText +
            '"&key=' +
            apikey +
            '&client_key=4parents&limit=10'
        )
        .then((res) => {
          setSrc(res.data.results);
        })
        .catch((err) => console.log(err));
    };
    searchText ? getBySearch() : getFeatured();
  }, [searchText]);

  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleGifSelected = (href) => {
    onChange(href);
    setGif(href);
    setOpen(false);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const generateGifs = () => {
    return (
      <Masonry columns={{ xs: 1, md: 2 }} sx={{ mt: 0, p: 0, width: 'auto' }}>
        <>
          {featuredSrc?.map((img, index) => {
            return (
              <Box
                onClick={(e) => handleGifSelected(e.target.src)}
                key={!!img.image ? img.image : img.media_formats.gif.url}>
                <img
                  src={!!img.image ? img.image : img.media_formats.gif.url}
                  alt={!!img.image ? img.image : img.media_formats.gif.url}
                  style={{ width: '100%', borderRadius: 5 }}
                />
              </Box>
            );
          })}
        </>
      </Masonry>
    );
  };

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="top-end" sx={{ zIndex: 9999, ...sx }}>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}>
          <Paper sx={{ width: 300, height: 500, overflowY: 'auto', zIndex: 9999, p: 2, ...sx }} elevation={3}>
            <TextField
              placeholder="Szukaj"
              onChange={(e) => handleSearch(e.target.value)}
              variant="standard"
              sx={{ width: '100%' }}
            />
            {generateGifs()}
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Tooltip title="gify">
        <IconButton
          aria-label="gify"
          id="gif-popper"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="large">
          <GifIcon sx={{ color: (theme) => theme.palette.color.color25 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default GifSelector;
