import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../../common/TextField';
import SelectField from '../../../common/SelectField';
import { MenuItem, Box } from '@mui/material';
import { attributeTypes } from '../../../../constants/attributeTypes';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const AttributeForm = ({ attribute, onChange, errors }) => {
  return (
    <Box>
      <FieldsetWrapper legend="Dane atrybutu">
        <TextField
          floatingLabelText="Nazwa*"
          autoFocus={true}
          name="name"
          value={attribute.name}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.name}
        />
        <SelectField
          contrast
          hintText="Typ"
          floatingLabelText="Typ*"
          floatingLabelFixed={false}
          id="type"
          value={attribute.type}
          onChange={(e) => {
            onChange({ target: { name: 'type', value: e.target.value } });
          }}
          errorText={errors.type}>
          {Object.values(attributeTypes).map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

AttributeForm.propTypes = {
  attribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default AttributeForm;
