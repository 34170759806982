import BaseModel from '../BaseModel';

export class VirtualDiscFileModel extends BaseModel {
  constructor(id = null) {
    super();
    this.id = id;
    this.name = '';
    this.size = null;
    this.fileUrl = false;
    this.mimeType = null;
    this.uploadedAt = null;
    this.uploadedBy = null;
    this.isPublic = null;
    this.shareForUserRoles = [];
    this.shareForUserIds = [];
    this.isOwnByUser = false;
    this.type = 'file';
  }

  setNew() {
    this.isNew = true;
  }
}

export class VirtualDiscSaveFileModel extends BaseModel {
  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.folderId = null;
    this.size = null;
    this.mimeType = null;
  }
}
