import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useEffect } from 'react';

const UploadDialog = ({ open, setOpen, file, handleUpload }) => {
  const [description, setDescription] = useState();

  useEffect(() => {
    setDescription(file?.map((e) => e.name));
  }, [file]);

  const handleSave = () => {
    handleUpload(file, description);
    setOpen(false);
  };
  const getUrlFromFile = (imageFile) => {
    return URL.createObjectURL(imageFile);
  };

  const handleImageAlt = (index, text) => {
    setDescription(description.map((e, i) => (i === index ? text : e)));
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open} maxWidth="md">
      <DialogTitle>Dodaj zdjęcie</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          Proszę podać opcjonalny opis zdjęcia lub zatwierdzić je bez opisu
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', my: 2 }}>
          {file?.map((singleFile, index) => (
            <>
              <img src={getUrlFromFile(singleFile)} style={{ width: 300 }} />
              <TextField
                sx={{ mb: 2, mt: 1, maxWidth: 300 }}
                fullWidth
                label="Opis zdjęcia"
                id="fullWidth"
                variant="standard"
                onChange={(e) => {
                  handleImageAlt(index, e.target.value);
                }}
              />
            </>
          ))}
        </Box>
        <DialogActions>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Anuluj
            </Button>
            <Button variant="contained" onClick={() => handleSave()}>
              Zapisz
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDialog;
