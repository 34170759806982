import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../actions/attendanceActions';
import * as childrenActions from '../../actions/childrenActions';
import moment from 'moment';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import _ from 'lodash';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { TimeModel } from '../../models/TimeModel';
import * as digitalDiaryActions from '../../actions/digitalDiaryActions';
import AttendanceList from './currentJournal/AttendanceList';
import EmptyState from '../common/EmptyState';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import GroupSwitch from '../common/groups/GroupSwitch';
import FilterBar from '../common/FilterBar';
import { Box, Grid, CircularProgress, Paper, Stack } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';
import userRoles from '../../constants/userRoles';

class CurrentAttendanceJournalPage extends React.Component {
  constructor(props) {
    super(props);
    this.getUnregisteredPupilEntries = this.getUnregisteredPupilEntries.bind(this);
    this.getAttendantPupilEntries = this.getAttendantPupilEntries.bind(this);
    this.getPickedOrAbsentPupilEntries = this.getPickedOrAbsentPupilEntries.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleOnReportDropOffStart = this.handleOnReportDropOffStart.bind(this);
    this.handleRevertAttendanceWithNoHours = this.handleRevertAttendanceWithNoHours.bind(this);
    this.handleRevertAbsenceWithNoHours = this.handleRevertAbsenceWithNoHours.bind(this);
    this.getTeacherGroupsIds = this.getTeacherGroupsIds.bind(this);
    this.handleFilterChildren = this.handleFilterChildren.bind(this);
    this.state = {
      selectedFilter: null,
      filteredPupils: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.handleGroupChange(this.props.choosenGroup);
    this.props.childrenActions.loadChildrenAsync().then(() => {
      !this.props.unit.teacherAllGroupVisibility && this.props.userRole === userRoles.staffMemberTeacher
        ? this.setState({
            isLoading: false,
            filteredPupils: [
              ...this.props.pupils
                .filter((e) => e.enrolledToGroup !== null)
                .filter((e) => this.getTeacherGroupsIds().includes(e.enrolledToGroup.id))
            ]
          })
        : this.setState({
            filteredPupils: [...this.props.pupils],
            isLoading: false
          });
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.state.selectedFilter && prevProps.groups.length) {
      this.handleGroupChange(this.props.groups[0].id);
    }
  }

  getTeacherGroupsIds() {
    const gropus = this.props.staffMembers.filter((e) => e.id === this.props.userId)[0].groups;
    return gropus.map((e) => e.id);
  }

  handleGroupChange(value) {
    this.setState({ isLoading: true });
    this.props.digitalDiaryEvents.changeChoosenGroup(value);
    this.setState({ selectedFilter: value }, () => {
      return !this.areAttendantPupilsSelected() && value.id && value.id !== 'all'
        ? this.props.actions.loadCurrentAttendanceAsync(value.id).then(() => this.setState({ isLoading: false }))
        : this.props.actions.loadAllCurrentAttendanceAsync().then(() => this.setState({ isLoading: false }));
    });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredPupils: filteredList
    });
  }

  handleOnReportDropOffStart(childId) {
    if (this.props.noHoursAttendancesEnabled) {
      const child = this.props.pupils.find((pupil) => pupil.id === childId);
      this.props.actions.reportChildAttendanceNoHoursAsync(
        child.id,
        new TimeModel().assign(child.declaredDropOffAt).toDate(),
        new TimeModel().assign(child.declaredPickUpAt).toDate()
      );
    } else {
      this.props.actions.reportChildDropOffStart();
    }
  }

  handleRevertAttendanceWithNoHours(childId, date) {
    if (this.props.noHoursAttendancesEnabled) {
      this.props.actions.revertChildAttendanceAsync(childId, date);
    } else {
      this.props.actions.revertChildDropOffAsync(childId, date).then(() => {});
    }
  }

  handleRevertAbsenceWithNoHours(childId, date) {
    if (this.props.noHoursAttendancesEnabled) {
      this.props.actions.revertChildAbsenceAsync(childId, date, date);
    } else {
      this.props.actions.revertChildAbsenceAsync(childId, date, date);
    }
  }

  getJournalWithPupils(childrenAttendance, pupils) {
    return childrenAttendance
      .map((a) => {
        const child = pupils.find((c) => c.id === a.child.id);
        return child
          ? Object.assign({}, a, {
              childId: child.id,
              firstName: child.firstName,
              lastName: child.lastName,
              contract: child.contract,
              photoUrl: child.photoUrl,
              declaredDropOffAt: child.declaredDropOffAt,
              declaredPickUpAt: child.declaredPickUpAt,
              allGuardians: [...child.legalGuardians, ...this.filterAuthorizedGuardians(child.guardians)]
            })
          : null;
      })
      .filter((a) => a !== null)
      .sort((a, b) => {
        return a.lastName.localeCompare(b.lastName, 'pl');
      });
  }

  getUnregisteredPupilEntries(entries) {
    return entries.filter((e) => !e.droppedOffAt && !e.absenceFrom && !e.absenceTo);
  }

  getAttendantPupilEntries(entries) {
    if (this.props.noHoursAttendancesEnabled) {
      return entries.filter((e) => e.droppedOffAt && e.pickedUpAt);
    }

    return entries.filter((e) => e.droppedOffAt && !e.pickedUpAt);
  }

  getPickedOrAbsentPupilEntries(entries) {
    if (this.props.noHoursAttendancesEnabled) {
      return entries.filter((e) => e.absenceFrom && e.absenceTo);
    }
    return entries.filter((e) => (e.droppedOffAt && e.pickedUpAt) || (e.absenceFrom && e.absenceTo));
  }

  filterAuthorizedGuardians(guardians) {
    return guardians.filter(
      (g) =>
        g.isAuthorized &&
        (g.authorizationExpirationDate == null ||
          !moment(g.authorizationExpirationDate).isBefore(moment(this.props.journalDate).startOf('day')))
    );
  }

  areAttendantPupilsSelected() {
    return this.state.selectedFilter === 'attendantPupils';
  }

  handleFilterChildren(child, keyword) {
    return (
      ((child.lastName.toLowerCase() + ' ' + child.firstName.toLowerCase()).includes(keyword.toLowerCase()) ||
        (child.firstName.toLowerCase() + ' ' + child.lastName.toLowerCase()).includes(keyword.toLowerCase())) &&
      (!this.props.unit.teacherAllGroupVisibility && this.props.userRole === userRoles.staffMemberTeacher
        ? child.enrolledToGroup !== null && this.getTeacherGroupsIds().includes(child.enrolledToGroup?.id)
        : true)
    );
  }

  render() {
    const journalEntries = this.getJournalWithPupils(this.props.childrenAttendance, this.state.filteredPupils);
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText
            title="Dziennik obecności"
            additionalInfo={this.props.journalDate ? moment(this.props.journalDate).format('DD.MM.YYYY') : null}
            titleIcon={<EventBusyIcon />}
          />
          <ContentWrapper>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} md={6}>
                <FilterBar
                  itemsToFilter={this.props.pupils}
                  sx={{ my: 3 }}
                  onFilter={this.handleFilterChange}
                  filter={(child, keyword) => this.handleFilterChildren(child, keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GroupSwitch onGroupChange={this.handleGroupChange} showArchivedGroups={false} showAllGroups={true} />
              </Grid>
            </Grid>

            {this.props.childrenAttendance.length <= 0 && !this.state.isLoading && (
              <EmptyState isPaper message="Do wybranej grupy nie zostały jeszcze przypisane dzieci" contrast />
            )}
            {this.props.childrenAttendance.length > 0 && journalEntries.length <= 0 && !this.state.isLoading && (
              <EmptyState isPaper contrast message="Nie znaleziono dziecka" />
            )}
            {this.state.isLoading && (
              <Paper
                sx={{
                  mb: 4,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  py: 3,
                  px: 2
                }}>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress size={30} left={10} top={0} sx={{ display: 'inline-block' }} />
                </Stack>
              </Paper>
            )}

            {this.areAttendantPupilsSelected()
              ? null
              : !this.state.isLoading && (
                  <AttendanceList
                    title=""
                    user={this.props.user}
                    journalEntries={this.getUnregisteredPupilEntries(journalEntries)}
                    onReportDropOffStart={this.handleOnReportDropOffStart}
                    onReportDropOffCancel={this.props.actions.reportChildDropOffCancel}
                    onReportDropOff={this.props.actions.reportChildDropOffAsync}
                    onReportAbsenceStart={this.props.actions.reportChildAbsenceStart}
                    onReportAbsenceCancel={this.props.actions.reportChildAbsenceCancel}
                    onReportAbsence={this.props.actions.reportChildAbsenceAsync}
                    ui={this.props.ui}
                    noHoursAttendancesEnabled={this.props.noHoursAttendancesEnabled}
                  />
                )}
            <AttendanceList
              title="Obecne"
              user={this.props.user}
              journalEntries={this.getAttendantPupilEntries(journalEntries)}
              onReportPickUpStart={this.props.actions.reportChildPickUpStart}
              onReportPickUpCancel={this.props.actions.reportChildPickUpCancel}
              onReportPickUp={this.props.actions.reportChildPickUpAsync}
              onRevertDropOff={(childId) => this.handleRevertAttendanceWithNoHours(childId, this.props.journalDate)}
              ui={this.props.ui}
              noHoursAttendancesEnabled={this.props.noHoursAttendancesEnabled}
            />
            {this.areAttendantPupilsSelected() ? null : (
              <AttendanceList
                user={this.props.user}
                title={this.props.noHoursAttendancesEnabled ? 'Nieobecne' : 'Nieobecne/Odebrane'}
                journalEntries={this.getPickedOrAbsentPupilEntries(journalEntries)}
                onRevertPickUp={(childId) => this.props.actions.revertChildPickUpAsync(childId, this.props.journalDate)}
                onRevertAbsence={(childId) =>
                  this.handleRevertAbsenceWithNoHours(childId, this.props.journalDate, this.props.journalDate)
                }
                ui={this.props.ui}
                noHoursAttendancesEnabled={this.props.noHoursAttendancesEnabled}
              />
            )}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

CurrentAttendanceJournalPage.propTypes = {
  choosenGroup: PropTypes.object,
  defaultGroupId: PropTypes.string,
  groups: PropTypes.array.isRequired,
  childrenAttendance: PropTypes.array.isRequired,
  pupils: PropTypes.array.isRequired,
  journalDate: PropTypes.object,
  actions: PropTypes.object.isRequired,
  childrenActions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  digitalDiaryEvents: PropTypes.object.isRequired,
  noHoursAttendancesEnabled: PropTypes.bool.isRequired,
  location: PropTypes.object
};

function mapStateToProps(state) {
  const loggedInStaffMember = state.staffMembers.find((s) => s.id === state.auth.userId);
  const staffMemberGroup = loggedInStaffMember ? _.head(loggedInStaffMember.groups) : '';
  const getDefaultGroup = () => (state.groups.length ? state.groups[0].id : '');

  return {
    unit: state.configuration.unit,
    groups: state.groups,
    defaultGroupId: staffMemberGroup ? staffMemberGroup.id : getDefaultGroup(),
    journalDate: state.attendance.date,
    childrenAttendance: state.attendance.childrenAttendance,
    pupils: state.children,
    ui: state.attendanceUi,
    noHoursAttendancesEnabled: state.configuration.unit.noHoursAttendancesEnabled,
    choosenGroup: state.digitalDiary.choosenGroup,
    staffMembers: state.staffMembers,
    userId: state.auth.userId,
    userRole: state.auth.userRole,
    user: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(attendanceActions, dispatch),
    childrenActions: bindActionCreators(childrenActions, dispatch),
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentAttendanceJournalPage);
