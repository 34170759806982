/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function psychologicalIndividualWorkProgramReducer(
  state = initialState.psychologicalIndividualWorkProgram,
  action
) {
  switch (action.type) {
    case types.LOAD_INDIVIDUAL_CLASSES: {
      const newState = Object.assign({}, state);

      newState.id = action.payload.id;
      newState.basicGoals = action.payload.basicGoals;
      newState.specificGoals = action.payload.specificGoals;
      newState.methods = action.payload.methods;
      newState.conclusions = action.payload.conclusions;
      newState.childId = action.id;
      return newState;
    }
    case types.LOAD_INDIVIDUAL_CLASSES_CALENDAR: {
      const newState = Object.assign({}, state);
      newState.posts = action.response;
      return newState;
    }
    case types.ADD_INDIVIDUAL_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = [...newState.posts, { ...action.response }];
      return newState;
    }
    case types.EDIT_INDIVIDUAL_CLASSES: {
      const newState = Object.assign({}, state);
      const postIdx = newState.posts.findIndex((post) => post.id === action.data.id);
      newState.posts[postIdx] = { ...action.data };
      return newState;
    }
    case types.DELETE_INDIVIDUAL_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = newState.posts.filter((post) => post.id !== action.postId);
      return newState;
    }
    case types.ADD_INDIVIDUAL_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data, id } = action;
      newState[data.type] = [...newState[data.type], { ...data, id: id.id }];
      return newState;
    }
    case types.EDIT_INDIVIDUAL_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState[data.type].findIndex((el) => el.id === data.id);
      newState[data.type][elementIdx] = data;
      return newState;
    }
    case types.DELETE_INDIVIDUAL_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data } = action;
      newState[data.type] = [...newState[data.type].filter((el) => el.id !== data.id)];
      return newState;
    }
    default:
      return state;
  }
}
