import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../../routePaths';
import { useSelector } from 'react-redux';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';
import { SearchBar } from '../../common/SearchBar';
import { useMemo, useState } from 'react';
import EmptyState from '../../common/EmptyState';
import userRoles from '../../../constants/userRoles';

const ChildrenDiagnosesReport = () => {
  const children = useSelector((state) => state.children);
  const user = useSelector((state) => state.auth);
  const staffMembers = useSelector((state) => state.staffMembers);
  const [searchText, setSearchText] = useState('');

  const availableChildren = useMemo(() => {
    if (user.userRole === userRoles.staffMemberTeacher && !!children) {
      const loggedUser = staffMembers.find((u) => u.id === user.userId);
      const groupsIds = loggedUser.groups.map((g) => g.id);

      return children.filter((child) => groupsIds.includes(child.enrolledToGroup?.id));
    }
    return children;
  }, [staffMembers, user, children]);

  const filteredChildren = useMemo(() => {
    if (searchText === '') return availableChildren;
    return availableChildren.filter((child) => {
      return (
        (child.firstName + ' ' + child.lastName).toLowerCase().includes(searchText.toLowerCase()) ||
        (child.lastName + ' ' + child.firstName).toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [availableChildren, searchText]);

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Typography align="justify">
        Wybierz dziecko, a następnie wygeneruj raport dostępny w zakładce Diagnozy
      </Typography>
      <SearchBar contrast value={searchText} onChange={handleSearchTextChange} label="Wyszukaj dziecko" />
      {filteredChildren.length !== 0 ? (
        <List sx={{ minHeight: 400, maxHeight: 400, overflowY: 'auto' }}>
          {filteredChildren.map((child) => {
            return (
              <ListItem
                sx={{
                  p: 0,
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}
                key={child.id}
                component="li"
                aria-label={`Wybierz dziecko ${child.firstName + ' ' + child.lastName}`}>
                <ListItemButton
                  onClick={() => {
                    browserHistory.push(fromTemplate(`${routePaths.children}` + `/${child.id}`));
                  }}>
                  <ListItemText
                    primary={child.firstName + ' ' + child.lastName}
                    primaryTypographyProps={{
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}
                    secondary={child.enrolledToGroup ? child.enrolledToGroup.name : 'Brak grupy'}
                  />
                  <ListItemIcon>
                    <PersonAvatar initials url={child.photoUrl} firstName={child.firstName} lastName={child.lastName} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Box sx={{ height: 384 }}>
          <EmptyState contrast message="Brak wyników wyszukiwania" />
        </Box>
      )}
    </>
  );
};

export default ChildrenDiagnosesReport;
