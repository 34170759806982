import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';

const CustomCheckbox = ({
  name,
  label,
  defaultChecked,
  onCheck,
  checkedIcon,
  uncheckedIcon,
  disabled,
  style,
  sx,
  labelStyle
}) => {
  return (
    <FormControlLabel
      disableTypography
      control={
        <Checkbox
          checked={defaultChecked}
          onChange={(e) => onCheck(e, !defaultChecked)}
          name={name}
          checkedIcon={checkedIcon}
          icon={uncheckedIcon}
          disabled={disabled}
        />
      }
      label={label}
      name={name}
      sx={{ ...style, ...sx, ...labelStyle }}
    />
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  checkedIcon: PropTypes.element,
  uncheckedIcon: PropTypes.element,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  sx: PropTypes.object,
  labelStyle: PropTypes.object
};

export default CustomCheckbox;
