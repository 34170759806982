import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as activityActions from '../../actions/activitiesActions';
import ActivityDetailsCard from './details/ActivityDetailsCard';
import { ActivityDetailsModel } from '../../models/activities/ActivityModels';
import { ActivityDetailsModelValidator } from '../../models/activities/ActivityModelsValidator';
import { create } from '../../utils/AvatarContentGenerator';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box } from '@mui/material';

class ActivityRegisterPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activity: Object.assign({}, new ActivityDetailsModel())
    };
    this.onUpdateActivityState = this.onUpdateActivityState.bind(this);
    this.onSaveActivity = this.onSaveActivity.bind(this);
    this.onCancelActivity = this.onCancelActivity.bind(this);
    this.onEditActivity = this.onEditActivity.bind(this);
  }

  componentDidMount() {
    this.onEditActivity();
  }

  onUpdateActivityState(event) {
    const field = event.target.name;
    const { activity } = this.state;
    activity[field] = event.target.value;
    return this.setState({ activity });
  }

  onSaveActivity() {
    let { activity } = this.state;
    if (!activity.color) {
      activity = Object.assign({}, activity, { color: create().generateColorFromName(activity.name) });
    }
    this.props.actions.createActivityDetailsAsync(activity);
  }

  onEditActivity() {
    this.props.actions.createActivityDetailsStart();
  }

  onCancelActivity() {
    this.props.actions.createActivityDetailsCancel();
    browserHistory.push(routePaths.activities);
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <ActivityDetailsCard
            activity={this.state.activity}
            onChange={this.onUpdateActivityState}
            onSave={this.onSaveActivity}
            onCancel={this.onCancelActivity}
            onEdit={this.onEditActivity}
            onValidate={() => new ActivityDetailsModelValidator().validate(this.state.activity)}
            isEditing={this.props.ui.isEditing}
            isProcessing={this.props.ui.isProcessing}
            title="Nowe zajęcia"
          />
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ActivityRegisterPage.propTypes = {
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    ui: state.activityDetailsUi.details
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityRegisterPage);
