import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, ListItem, ListItemText, ListItemAvatar, Typography } from '@mui/material';

const WorkPlanSelectGroup = ({ group, contrast }) => {
  const { name, yearbook, color } = group;
  const expression = !yearbook ? 'Tymczasowa' : `Rok ${yearbook}/${yearbook + 1}`;
  return (
    <ListItem style={{ width: 'auto' }}>
      <ListItemAvatar>
        <Avatar sx={{ background: (theme) => theme.palette.color.wcag || color }} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography sx={{ color: contrast ? '000' : '#FFF', fontWeight: (theme) => theme.typography.fontWeightBold }}>
            {!name ? 'Brak' : name}
          </Typography>
        }
        secondary={
          <Typography sx={{ color: contrast ? '000' : '#FFF', opacity: '50%' }}>
            {!name ? 'Nie wybrano grupy' : expression}
          </Typography>
        }
      />
    </ListItem>
  );
};

WorkPlanSelectGroup.propTypes = {
  group: PropTypes.object.isRequired
};

export default WorkPlanSelectGroup;
