import ApiBase, { ROOT_URL } from './ApiBase';

class VirtualDiscFoldersApi extends ApiBase {
  static createFolder(data) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/virtualDiscFolders`).send(data).type('application/json'));
  }

  static deleteFolder(folderId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/virtualDiscFolders/${folderId}`).type('application/json')
    );
  }

  static editFolder(data) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/virtualDiscFolders/${data.id}`).send(data).type('application/json')
    );
  }

  static moveToFolder(data) {
    return super.toPromise((agent) =>
      agent.patch(`${ROOT_URL}/virtualDiscFolders/items/move`).send(data).type('application/json')
    );
  }

  static getFolderContent(folderId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/virtualDiscFolders/${folderId}`).type('application/json'));
  }

  static getRootFolderContent() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/virtualDiscFolders/`).type('application/json'));
  }

  static getFolderMetadata(folderId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/virtualDiscFolders/folders/${folderId}`).type('application/json')
    );
  }

  static shareFolder(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/virtualDiscFolders/${data.folderId}/share`).send(data).type('application/json')
    );
  }

  static downloadFolder(folderId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/virtualDiscFolders/${folderId}/download`));
  }
}

export default VirtualDiscFoldersApi;
