import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import StaffCalendarDetailsContainer from './StaffCalendarDetailsContainer';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';

const StaffCalendarDetailPage = (props) => {
  const { params } = props;

  return (
    <Box>
      <PageHeaderText title="Wydarzenia - pracownicy" titleIcon={<EventOutlinedIcon />} />
      <ContentWrapper>
        <StaffCalendarDetailsContainer year={params.year} month={params.month} day={params.day} />
      </ContentWrapper>
    </Box>
  );
};

StaffCalendarDetailPage.propTypes = {
  params: PropTypes.object.isRequired
};

export default withRouter(StaffCalendarDetailPage);
