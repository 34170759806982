import PropTypes from 'prop-types';
import React from 'react';
import WelcomeScreen from '../../common/welcomeScreen/WelcomeScreen';
import PasswordExpiredContainer from './Containers/PasswordExpiredContainer';

const ResetPasswordPage = ({ location }) => (
  <WelcomeScreen>
    <PasswordExpiredContainer location={location} />
  </WelcomeScreen>
);

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired
};
export default ResetPasswordPage;
