import React from 'react';
import PropTypes from 'prop-types';
import PasswordRecoveryFormWrap from './Forms/PasswordRecoveryFormWrap';
import { Typography, Container } from '@mui/material';

const MessageInformation = ({ onSubmit, isLoading }) => {
  return (
    <Container>
      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium, textAlign: 'justify' }}>
        Wprowadź adres email, który używasz do logowania. Prześlemy na niego odnośnik, który umożliwi Ci zresetowanie
        hasła.
      </Typography>
      <PasswordRecoveryFormWrap onSubmit={onSubmit} isLoading={isLoading} />
    </Container>
  );
};

MessageInformation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MessageInformation;
