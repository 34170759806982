import ApiBase, { ROOT_URL } from './ApiBase';

class ActivitiesApi extends ApiBase {
  static getActivities() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/activities`).type('application/json'));
  }

  static getActivitiesArchive() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/activities/archive`).type('application/json'));
  }

  static getActivity(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/activities/${id}`).type('application/json'));
  }

  static getActivityArchive(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/activities/archive/${id}`).type('application/json'));
  }

  static createActivity(group) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/activities`).send(group).set('Location', '').type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateActivity(id, group) {
    return super.toPromise((agent) => agent.put(`${ROOT_URL}/activities/${id}`).send(group).type('application/json'));
  }

  static archiveActivity(id) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/activities/${id}/archive`).type('application/json'));
  }

  //enrolled children

  static patchActivityChildren(id, childrenIds) {
    return super.toPromise((agent) =>
      agent.patch(`${ROOT_URL}/activities/${id}/children`).send(childrenIds).type('application/json')
    );
  }

  static removeChild(id, childId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/activities/${id}/children/${childId}`).send().type('application/json')
    );
  }
}

export default ActivitiesApi;
