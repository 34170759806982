import PropTypes from 'prop-types';
import {
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import GroupIdentification from './GroupIdentification';
import PersonAvatar from '../../../forms/PersonAvatar';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import LinkAdapter from '../../../common/LinkAdapter/LinkAdapter';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { fromTemplate, routePaths } from '../../../../routePaths';

const SingleListItem = ({ pupil, groupIdentificationText, inset }) => {
  const graduationDate = moment(pupil.educationEndDate);
  const monthsToGraduation = moment().diff(graduationDate, 'months');
  const { id, firstName, lastName, enrolledToGroup, photoUrl, birthDate } = pupil;
  const months = moment().diff(birthDate, 'months');
  const showAgeConfig = useSelector((state) => state.configuration.unit.childrenAgeInfoEnabled);
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);

  const displayChildrenAge = () => {
    if (showAgeConfig) {
      if (months >= 29 && months < 30) {
        return (
          <Tooltip title="Dziecko skończy 2.5 roku za mniej niż miesiąc">
            <InfoIcon
              sx={{
                mr: 1,
                transform: 'scale(1.3)',
                color: (theme) => theme.palette.color.color4
              }}
            />
          </Tooltip>
        );
      }
      if (months >= 30) {
        return (
          <Tooltip title="Dziecko ma więcej  niż 2.5 roku">
            <InfoIcon
              sx={{
                mr: 1,
                transform: 'scale(1.3)',
                color: (theme) => theme.palette.color.color12
              }}
            />
          </Tooltip>
        );
      }
    }
    return null;
  };

  const displayChildrenGraduation = () => {
    if (monthsToGraduation === 0 && graduationDate.diff(moment()) > 0) {
      return (
        <Tooltip title="Dziecko niedługo zakończy naukę!">
          <PriorityHighIcon sx={{ mr: 1, transform: 'scale(1.3)', color: 'red' }} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <ListItemButton
      key={id}
      dense
      component={LinkAdapter}
      to={fromTemplate(nadarzyn ? routePaths.studentDetails : routePaths.childDetails, id)}
      sx={{
        pl: { xs: 0, md: 2 },
        minHeight: 60,
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}>
      {groupIdentificationText && (
        <ListItemIcon sx={{ fontWeight: (theme) => theme.typography.fontWeightLight }}>
          <GroupIdentification text={groupIdentificationText} />
        </ListItemIcon>
      )}
      <ListItemText
        inset={inset}
        sx={{ color: (theme) => theme.palette.text.primary, pr: 10 }}
        primary={
          <Typography
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>{`${lastName} ${firstName}`}</Typography>
        }
        secondary={<Typography>{enrolledToGroup ? enrolledToGroup.name : null}</Typography>}
      />
      <ListItemSecondaryAction sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {displayChildrenGraduation()}
        {displayChildrenAge()}
        <PersonAvatar initials alt={firstName} url={photoUrl} firstName={firstName} lastName={lastName} />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

SingleListItem.propTypes = {
  groupIdentificationText: PropTypes.any,
  inset: PropTypes.any,
  pupil: PropTypes.shape({
    enrolledToGroup: PropTypes.shape({
      name: PropTypes.string
    }),
    educationEndDate: PropTypes.any,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    photoUrl: PropTypes.string,
    birthDate: PropTypes.string
  })
};

export default SingleListItem;
