import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import React from 'react';

const GroupIdentification = ({ text }) => {
  return (
    <Avatar
      sx={{
        color: (theme) => theme.palette.color.color25,
        background: (theme) => theme.palette.background.transparent
      }}>
      {text}
    </Avatar>
  );
};

GroupIdentification.propTypes = {
  text: PropTypes.string
};

export default GroupIdentification;
