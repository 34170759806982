import React from 'react';
import PropTypes from 'prop-types';
import SpecificIcon from '../../../groupsAndActivities/SpecificIcon';
import { Chip, Typography, useTheme } from '@mui/material';
import { GROUP } from '../../../../constants/groupsAndActivitiesTypes';

const SingleMemberChip = ({ name, type, color, onDelete, wrapToNextLine, sx, contrast }) => {
  const theme = useTheme();
  const borderColor = color === 'error' ? theme.palette.color.error : color;

  return (
    <Chip
      icon={
        <SpecificIcon
          type={type || GROUP}
          sx={{ color: `${theme.palette.color.wcag || borderColor || theme.palette.color.color4} !important` }}
        />
      }
      label={
        <Typography
          sx={{
            color: theme.palette.color.wcag || !contrast ? theme.palette.color.contrast : theme.palette.color.primary
          }}
          variant="chipContent">
          {name}
        </Typography>
      }
      variant="outlined"
      onDelete={onDelete}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      sx={{
        color: (theme) => theme.palette.color.contrast,
        background: (theme) =>
          !contrast
            ? `linear-gradient(${theme.palette.background.default}, ${theme.palette.background.default}) padding-box, ${
                theme.palette.color.wcag || borderColor || theme.palette.color.color4
              } border-box`
            : null,
        border: !contrast ? '2px solid transparent' : '2px solid white',
        '& .MuiSvgIcon-root': { fill: contrast ? 'white' : null },
        whiteSpace: wrapToNextLine ? 'pre-wrap' : 'nowrap'
      }}
    />
  );
};

SingleMemberChip.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  customClass: PropTypes.object,
  onDelete: PropTypes.func,
  wrapToNextLine: PropTypes.bool
};

export default SingleMemberChip;
