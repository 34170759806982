import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import {
  tryConvertToTimeSpan,
  tryConvertSanitizeToTimeSpan,
  tryConvertToString
} from '../../../utils/TimeSpanConverter';
import TextField from '../../common/TextField';

const OpeningBalanceForm = ({ openingBalance, onChange, errors }) => {
  return (
    <Box>
      <Typography variant="h6">Obecności</Typography>
      <TextField
        hintText="Obecność całkowita (dni)"
        floatingLabelText="Obecność całkowita (dni)"
        floatingLabelFixed={false}
        name="totalDays"
        value={openingBalance.totalDays}
        onChange={onChange}
        onBlur={(e) => {
          onChange({ target: { name: 'totalDays', value: tryConvertToNumber(e.target.value) } });
        }}
        errorText={errors.totalDays}
        disabled={openingBalance.isLocked}
        fullWidth={true}
      />
      <TextField
        hintText="Obecność całkowita (godziny : minuty)"
        floatingLabelText="Obecność całkowita (godziny : minuty)"
        floatingLabelFixed={false}
        name="totalTime"
        value={tryConvertToString(openingBalance.totalTime)}
        onChange={(e) => {
          onChange({ target: { name: 'totalTime', value: tryConvertSanitizeToTimeSpan(e.target.value) } });
        }}
        onBlur={(e) => {
          onChange({ target: { name: 'totalTime', value: tryConvertToTimeSpan(e.target.value) } });
        }}
        errorText={errors.totalTime ? errors.totalTime.minutes : null}
        disabled={openingBalance.isLocked}
        fullWidth={true}
      />
      <TextField
        hintText="Obecność płatna (godziny : minuty)"
        floatingLabelText="Obecność płatna (godziny : minuty)"
        floatingLabelFixed={false}
        name="totalChargeableTime"
        value={tryConvertToString(openingBalance.totalChargeableTime)}
        onChange={(e) => {
          onChange({ target: { name: 'totalChargeableTime', value: tryConvertSanitizeToTimeSpan(e.target.value) } });
        }}
        onBlur={(e) => {
          onChange({ target: { name: 'totalChargeableTime', value: tryConvertToTimeSpan(e.target.value) } });
        }}
        errorText={errors.totalChargeableTime ? errors.totalChargeableTime.minutes : null}
        disabled={openingBalance.isLocked}
        fullWidth={true}
      />
      <Typography variant="h6">Odpisy</Typography>
      <TextField
        hintText="Odpis za pobyt"
        floatingLabelText="Odpis za pobyt"
        floatingLabelFixed={false}
        name="stayDueCorrection"
        value={openingBalance.stayDueCorrection}
        onChange={onChange}
        onBlur={(e) => {
          onChange({ target: { name: 'stayDueCorrection', value: tryConvertToNumber(e.target.value) } });
        }}
        errorText={errors.stayDueCorrection}
        disabled={openingBalance.isLocked}
      />
      <br />
      <TextField
        hintText="Odpis za wyżywienie"
        floatingLabelText="Odpis za wyżywienie"
        floatingLabelFixed={false}
        name="cateringDueCorrection"
        value={openingBalance.cateringDueCorrection}
        onChange={onChange}
        onBlur={(e) => {
          onChange({ target: { name: 'cateringDueCorrection', value: tryConvertToNumber(e.target.value) } });
        }}
        errorText={errors.cateringDueCorrection}
        disabled={openingBalance.isLocked}
      />
      <br />
      <TextField
        hintText="Odpis za inne opłaty"
        floatingLabelText="Odpis za inne opłaty"
        floatingLabelFixed={false}
        name="otherDueCorrection"
        value={openingBalance.otherDueCorrection}
        onChange={onChange}
        onBlur={(e) => {
          onChange({ target: { name: 'otherDueCorrection', value: tryConvertToNumber(e.target.value) } });
        }}
        errorText={errors.otherDueCorrection}
        disabled={openingBalance.isLocked}
      />
      <br />
    </Box>
  );
};

OpeningBalanceForm.propTypes = {
  openingBalance: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default OpeningBalanceForm;
