/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function groupDetailsUiReducer(state = initialState.groupDetailsUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_GROUP_DETAILS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.CREATE_GROUP_DETAILS_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.CREATE_GROUP_DETAILS_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.CREATE_GROUP_DETAILS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.CREATE_GROUP_DETAILS_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //update
    case types.UPDATE_GROUP_DETAILS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_GROUP_DETAILS_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.UPDATE_GROUP_DETAILS_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.UPDATE_GROUP_DETAILS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.UPDATE_GROUP_DETAILS_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //enrolled
    case types.ENROLL_CHILDREN_TO_GROUP: {
      return Object.assign({}, state, { enrolledChildren: UiModel.start() });
    }
    case types.ENROLL_CHILDREN_TO_GROUP_CANCEL: {
      return Object.assign({}, state, { enrolledChildren: UiModel.cancel() });
    }
    case types.ENROLL_CHILDREN_TO_GROUP_SUBMIT: {
      return Object.assign({}, state, { enrolledChildren: UiModel.submit() });
    }
    case types.ENROLL_CHILDREN_TO_GROUP_SUCCESS: {
      return Object.assign({}, state, { enrolledChildren: UiModel.success() });
    }
    case types.ENROLL_CHILDREN_TO_GROUP_FAILURE: {
      return Object.assign({}, state, { enrolledChildren: UiModel.failure(action.errors) });
    }
    //associated
    case types.ASSOCIATE_TEACHER_TO_GROUP: {
      return Object.assign({}, state, { associatedTeachers: UiModel.start() });
    }
    case types.ASSOCIATE_TEACHER_TO_GROUP_CANCEL: {
      return Object.assign({}, state, { associatedTeachers: UiModel.cancel() });
    }
    case types.ASSOCIATE_TEACHER_TO_GROUP_SUBMIT: {
      return Object.assign({}, state, { associatedTeachers: UiModel.submit() });
    }
    case types.ASSOCIATE_TEACHER_TO_GROUP_SUCCESS: {
      return Object.assign({}, state, { associatedTeachers: UiModel.success() });
    }
    case types.ASSOCIATE_TEACHER_TO_GROUP_FAILURE: {
      return Object.assign({}, state, { associatedTeachers: UiModel.failure(action.errors) });
    }
    // archival
    case types.ARCHIVE_GROUP: {
      return Object.assign({}, state, { archival: UiModel.start() });
    }
    case types.ARCHIVE_GROUP_CANCEL: {
      return Object.assign({}, state, { archival: UiModel.cancel() });
    }
    case types.ARCHIVE_GROUP_SUBMIT: {
      return Object.assign({}, state, { archival: UiModel.submit() });
    }
    case types.ARCHIVE_GROUP_SUCCESS: {
      return Object.assign({}, state, { archival: UiModel.success() });
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.groupDetailsUi);
    }
    default:
      return state;
  }
}
