import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import DatePicker from '../../common/DatePicker';
import moment from 'moment';
import RichTextEditor from '../../forms/richTextEditor/RichTextEditor';
import TextField from '../../common/TextField';
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Stack } from '@mui/material';
import SelectField from '../../common/SelectField';
import FilePickerWithRef from '../../common/FilePickerWithRef/FilePickerWithRef';

const childOpinions = [
  { name: 'orzeczenie o potrzebie kształcenia specjalistycznego', value: 'specialist-education' },
  { name: 'orzeczenie o niepełnosprawności', value: 'disability' },
  { name: 'opinia poradni psychologiczno-pedagogicznej', value: 'psychological-pedagogical ' },
  { name: 'opinia o potrzebie wspomagania wczesnego rozwoju dziecka', value: 'support-child-development' },
  { name: 'inny (jaki?)', value: 'other' }
];

const ChildOpinionForm = ({
  fileAttachment,
  onTitleChange,
  onReleasedByChange,
  onDateChange,
  onTypeChange,
  onNumberChange,
  onTypeValueChange,
  onReasonChange,
  onNeedHelp,
  onDateEndChange,
  onDateStartChange,
  handleFilePicked,
  errors,
  readonly,
  onSuperTextEditorChange
}) => {
  const filePickerRef = useRef();
  return (
    <Box sx={{ maxHeight: '50vh' }}>
      <Box sx={{ display: 'none' }}>
        <FilePickerWithRef ref={filePickerRef} onDrop={handleFilePicked} />
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="flex-start" spacing={2}>
        <SelectField
          contrast
          sx={{
            minWidth: 88,
            width: '100%'
          }}
          errorText={errors.opinionType}
          value={fileAttachment.opinionType}
          id="opinionType"
          onChange={(e) => {
            onTypeChange(e.target.value);
          }}
          disabled={readonly}
          floatingLabelText="Rodzaj*">
          {childOpinions.map((opinion) => (
            <MenuItem value={opinion.value} key={opinion.value}>
              {opinion.name}
            </MenuItem>
          ))}
        </SelectField>
        <TextField
          hintText="Plik"
          floatingLabelText="Plik*"
          name="file"
          value={fileAttachment.file && fileAttachment.file.name ? fileAttachment.file.name : ''}
          disabled
          fullWidth
        />
        {!readonly && (
          <Button variant="contained" onClick={() => filePickerRef.current.open()}>
            Wybierz plik
          </Button>
        )}
      </Stack>
      {fileAttachment.opinionType === 'other' && (
        <TextField
          hintText="Podaj rodzaj opini"
          floatingLabelText="Podaj rodzaj opini*"
          name="opinionTypeOther"
          value={fileAttachment.opinionTypeOtherValue}
          onChange={(e) => onTypeValueChange(e.target.value)}
          errorText={errors.opinionTypeOtherValue}
          autoFocus={true}
          fullWidth
          disabled={readonly}
        />
      )}
      <TextField
        hintText="Nazwa opinii"
        floatingLabelText="Nazwa opinii*"
        name="title"
        value={fileAttachment.fileInfo.title}
        onChange={(e) => onTitleChange(e.target.value)}
        errorText={errors.name}
        autoFocus={true}
        fullWidth
        disabled={readonly}
      />
      <TextField
        hintText="Wydana przez"
        floatingLabelText="Wydana przez"
        name="releasedBy"
        value={fileAttachment.fileInfo.releasedBy}
        onChange={(e) => onReleasedByChange(e.target.value)}
        errorText={errors.releasedBy}
        fullWidth={true}
        disabled={readonly}
      />
      <TextField
        hintText="Przyczyna objęcia dziecka pomocą"
        floatingLabelText="Przyczyna objęcia dziecka pomocą"
        name="releasedBy"
        value={fileAttachment.reason}
        onChange={(e) => {
          onReasonChange(e.target.value);
        }}
        disabled={readonly}
        errorText={errors.reason}
        fullWidth={true}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ mb: 1 }}>
        <DatePicker
          hintText="Data wydania"
          floatingLabelText="Data wydania"
          name="date"
          disabled={readonly}
          value={fileAttachment.fileInfo.date}
          errorText={errors.date}
          maxDate={moment.utc().toDate()}
          onChange={(empty, date) => {
            onDateChange(date);
          }}
        />
        <TextField
          sx={{ mt: 0 }}
          hintText="Numer opini"
          floatingLabelText="Numer opini"
          name="releasedBy"
          disabled={readonly}
          value={fileAttachment.opinionNumber}
          onChange={(e) => onNumberChange(e.target.value)}
          errorText={errors.opinionNumber}
          fullWidth={true}
        />
      </Stack>
      <FormControlLabel
        control={
          <Checkbox
            disabled={readonly}
            onChange={(e) => onNeedHelp(e.target.checked)}
            checked={fileAttachment.needHelp}
          />
        }
        label="Obejmij pomocą psychologiczno-pedagogiczną"
      />
      {!!fileAttachment.needHelp && (
        <Stack direction="row" alignItems="start" spacing={2} sx={{ mb: 1 }}>
          <DatePicker
            disabled={readonly}
            hintText="Data objęcia pomocą"
            floatingLabelText="Data objęcia pomocą"
            name="helpDateStart"
            value={fileAttachment.helpDateStart}
            errorText={errors.helpDateStart ? errors.helpDateStart : null}
            onChange={(empty, date) => {
              onDateStartChange(date);
            }}
          />
          <DatePicker
            disabled={readonly}
            hintText="Data zakończenia pomocy"
            floatingLabelText="Data zakończenia pomocy"
            name="helpDateEnd"
            value={fileAttachment.helpDateEnd}
            errorText={errors.helpDateEnd ? errors.helpDateEnd : ''}
            onChange={(empty, date) => {
              onDateEndChange(date);
            }}
          />
        </Stack>
      )}
      <RichTextEditor
        onChange={onSuperTextEditorChange}
        initialText={fileAttachment.fileInfo.comments}
        disabled={readonly}
      />
    </Box>
  );
};

ChildOpinionForm.propTypes = {
  fileAttachment: PropTypes.object.isRequired,
  onReleasedByChange: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onSuperTextEditorChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ChildOpinionForm;
