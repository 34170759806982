import React from 'react';
import PropTypes from 'prop-types';
import { InputFieldStyles } from '../../constants/ComponentStyles/inputFieldStyles';
import { makeStyles } from '@mui/styles';
import { TextField, useMediaQuery, useTheme } from '@mui/material';

const useStyle = makeStyles(InputFieldStyles);

const labelStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%'
};

const shouldShrinkLabel = (shrink) => {
  if (shrink) return { shrink: true };
  return {};
};

const CustomTextField = ({
  name,
  floatingLabelText,
  floatingLabelFixed = false,
  onChange,
  onBlur,
  fullWidth,
  value,
  errorText,
  disabled,
  type,
  endAdornment,
  style,
  sx,
  autocomplete = null,
  variant = 'standard',
  inputProps
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TextField
      sx={{ my: 1, mx: 0, ...style, ...sx }}
      name={name}
      id={name}
      autoComplete={autocomplete}
      type={type}
      label={floatingLabelText}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth={fullWidth}
      error={!!errorText}
      inputProps={inputProps}
      InputProps={{ classes: { underline: classes.underline }, endAdornment }}
      helperText={errorText}
      FormHelperTextProps={{ role: 'status' }}
      value={value || ''}
      variant={variant}
      InputLabelProps={{ ...shouldShrinkLabel(floatingLabelFixed), style: isMobile ? labelStyle : null }}
      disabled={disabled}
    />
  );
};

CustomTextField.propTypes = {
  name: PropTypes.string,
  hintText: PropTypes.string,
  errorText: PropTypes.string,
  floatingLabelText: PropTypes.string,
  floatingLabelFixed: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  style: PropTypes.object,
  autocomplete: PropTypes.any,
  variant: PropTypes.string,
  sx: PropTypes.object,
  inputProps: PropTypes.object
};

export default CustomTextField;
