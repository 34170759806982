import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { create } from '../../../utils/AvatarContentGenerator';
import { useSelector } from 'react-redux';

const ActivityAvatar = ({ activity, activityName, ...other }) => {
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <Avatar
      sx={{
        background: !activity
          ? create().generateColorFromName(activityName)
          : isContrastColor === 'contrast'
          ? theme.palette.color.wcag
          : activity.color,
        borderRadius: '50%',
        width: 42,
        height: 42
      }}
      variant="rounded"
      {...other}>
      <VerifiedOutlinedIcon size="large" sx={{ color: theme.palette.color.primary }} />
    </Avatar>
  );
};

ActivityAvatar.propTypes = {
  activity: PropTypes.object,
  activityName: PropTypes.string
};

export default ActivityAvatar;
