import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButtonPipe from '../../common/IconButtonPipe';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import {
  Popover,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Box,
  Typography
} from '@mui/material';
import { RotateLeft, Clear } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: 350
  }
});

const BoardPostCardDialogFilter = (props) => {
  const { setFilter, filter, setRows, rows, setPage } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const cl = useStyles();

  const handleResetFilters = () => {
    setFilter('');
    setRows('5');
    setPage(event, 1);
  };

  const handleChangeFilter = (value) => {
    setFilter(value);
    setPage(event, 1);
  };

  const handleChangeRows = (value) => {
    setRows(value);
    setPage(event, 1);
  };

  return (
    <>
      <IconButtonPipe
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        tooltip="Filtruj"
        aria-haspopup="true">
        <TuneRoundedIcon />
      </IconButtonPipe>
      <Popover
        id="1"
        open={open}
        anchorEl={anchorEl}
        classes={{ paper: cl.wrapper }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ pl: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: ' center' }}>
              <Typography variant="h6">Filtry</Typography>
              <IconButtonPipe onClick={handleResetFilters} tooltip="Zresetuj filtry">
                <RotateLeft />
              </IconButtonPipe>
            </Box>
            <IconButtonPipe onClick={() => setAnchorEl(null)} tooltip="Zamknij okno filtrów">
              <Clear />
            </IconButtonPipe>
          </Grid>
          <Box sx={{ px: 2 }}>
            <FormLabel component="legend">
              <Typography sx={{ color: (theme) => theme.palette.color.contrast }}>Opcje wyświetlania:</Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Sortowanie plików"
                name="sort"
                value={filter}
                sx={{ p: 1 }}
                onChange={(e) => handleChangeFilter(e.target.value)}>
                <FormControlLabel value="" control={<Radio />} label="Wszyscy" />
                <FormControlLabel value="seen" control={<Radio />} label="Wyświetlono" />
                <FormControlLabel value="unseen" control={<Radio />} label="Nie wyświetlono" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ px: 2 }}>
            <FormLabel component="legend">
              <Typography sx={{ color: (theme) => theme.palette.color.contrast }}>
                Ilość elementów na stronę:
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Ilość linii"
                name="rowsNumber"
                value={rows}
                sx={{ p: 1 }}
                onChange={(e) => handleChangeRows(e.target.value)}>
                <FormControlLabel value="1" control={<Radio color="info" />} label="1" />
                <FormControlLabel value="2" control={<Radio color="info" />} label="2" />
                <FormControlLabel value="5" control={<Radio color="info" />} label="5" />
                <FormControlLabel value="10" control={<Radio color="info" />} label="10" />
                <FormControlLabel value="25" control={<Radio color="info" />} label="25" />
                <FormControlLabel value="50" control={<Radio color="info" />} label="50" />
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      </Popover>
    </>
  );
};

BoardPostCardDialogFilter.propTypes = {
  setFilter: PropTypes.func,
  filter: PropTypes.string,
  setRows: PropTypes.func,
  rows: PropTypes.string,
  setPage: PropTypes.func
};

export default BoardPostCardDialogFilter;
