import api from '../api/psychologicalApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import * as get from './getStateHelpers';
import DigitalDiaryApi from '../api/DigitalDiaryApi';

export function loadGroupClassesWorkPlan(date, helpId) {
  return (dispatch) => {
    return api
      .loadGroupWorkPlan(date, helpId)
      .then((response) => dispatch({ type: types.LOAD_GROUP_CLASSES_CALENDAR, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać rozkładu zajęć`));
        return logger.error(error);
      });
  };
}

export function loadGroupDiaryCalendar(date, helpId) {
  return (dispatch) => {
    return api
      .loadGroupClasses(date, helpId)
      .then((response) => dispatch({ type: types.LOAD_GROUP_DIARY_CALENDAR, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać rozkładu zajęć`));
        return logger.error(error);
      });
  };
}

export function loadPsychologicalGroupAttendance(groupId) {
  return (dispatch, getState) => {
    return api
      .getTodayGroupAttendance(groupId)
      .then((attendance) => dispatch({ type: types.LOAD_PSYCHOLOGICAL_GROUP_ATTENDANCE, attendance }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać listy obecności dla grupy '${get.groupName(getState, groupId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function reportPsychologicalGroupAttendance(child, groupId) {
  return (dispatch, getState) => {
    return api
      .reportGroupAttendance(child, groupId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zgłoszono obecność dziecka ${get.childName(getState, child.id)}`));
        dispatch({ type: types.REPORT_PSYCHOLOGICAL_GROUP_ATTENDANCE, child });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się zgłosić obecności dziecka ${get.childName(getState, child.id)}`)
        );
        return logger.error(error);
      });
  };
}

export function reportPsychologicalGroupAbsence(child, groupId) {
  return (dispatch, getState) => {
    return api
      .reportGroupAbsence(child, groupId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zgłoszono nieobecność dziecka ${get.childName(getState, child.id)}`));
        dispatch({ type: types.REPORT_PSYCHOLOGICAL_GROUP_ABSENCE, child });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się zgłosić nieobecności dziecka ${get.childName(getState, child.id)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function setClassesDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.GROUP_CLASSES_START });
  };
}

export function setClassesDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.GROUP_CLASSES_CANCEL });
  };
}

export function addGroupClasses(data) {
  return (dispatch) => {
    dispatch({ type: types.GROUP_CLASSES_SUBMIT });
    return DigitalDiaryApi.addCustomNewEvent(data, data.eventType)
      .then((response) => dispatch({ type: types.ADD_GROUP_CLASSES, response }))
      .catch((error) => {
        dispatch({ type: types.GROUP_CLASSES_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się pobrać rozkładu zajęć`));
        return logger.error(error);
      });
  };
}

export function editGroupClasses(data) {
  return (dispatch) => {
    dispatch({ type: types.GROUP_CLASSES_SUBMIT });
    return DigitalDiaryApi.editCustomEvent(data)
      .then(() => dispatch({ type: types.EDIT_GROUP_CLASSES, data }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować wpisu`));
        dispatch({ type: types.GROUP_CLASSES_FAILURE });
        return logger.error(error);
      });
  };
}

export function deleteGroupClasses(postId) {
  return (dispatch) => {
    return DigitalDiaryApi.deleteCustomEvent(postId)
      .then(() => dispatch({ type: types.DELETE_GROUP_CLASSES, postId }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu`));
        return logger.error(error);
      });
  };
}

//===============================

export function setDiaryDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.GROUP_DIARY_START });
  };
}

export function setDiaryDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.GROUP_DIARY_CANCEL });
  };
}

export function addGroupDiary(data) {
  return (dispatch) => {
    dispatch({ type: types.GROUP_DIARY_SUBMIT });
    return DigitalDiaryApi.addCustomNewEvent(data, data.eventType)
      .then((response) => dispatch({ type: types.ADD_GROUP_DIARY, response }))
      .catch((error) => {
        dispatch({ type: types.GROUP_DIARY_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się dodać wpisu`));
        dispatch({ type: types.GROUP_DIARY_FAILURE });
        return logger.error(error);
      });
  };
}

export function editGroupDiary(data) {
  return (dispatch) => {
    dispatch({ type: types.GROUP_DIARY_SUBMIT });
    return DigitalDiaryApi.editCustomEvent(data)
      .then(() => dispatch({ type: types.EDIT_GROUP_DIARY, data }))
      .catch((error) => {
        dispatch({ type: types.GROUP_DIARY_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się zaktualizować wpisu`));
        dispatch({ type: types.GROUP_DIARY_FAILURE });
        return logger.error(error);
      });
  };
}

export function deleteGroupDiary(postId) {
  return (dispatch) => {
    return DigitalDiaryApi.deleteCustomEvent(postId)
      .then(() => dispatch({ type: types.DELETE_GROUP_DIARY, postId }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu`));
        return logger.error(error);
      });
  };
}

//=================================

export function setGeneralDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.GROUP_PROGRAM_START });
  };
}

export function setGeneralDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.GROUP_PROGRAM_CANCEL });
  };
}

export function createNewProgramElement(data, groupId) {
  return (dispatch) => {
    dispatch({ type: types.GROUP_PROGRAM_SUBMIT });
    return api
      .createWorkPlanGroupElement(data, groupId)
      .then((id) => dispatch({ type: types.ADD_GROUP_PROGRAM, data, id }))
      .catch((error) => {
        dispatch({ type: types.GROUP_PROGRAM_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się dodać elementu`));
        return logger.error(error);
      });
  };
}

export function editProgramElement(data, groupId) {
  return (dispatch) => {
    dispatch({ type: types.GROUP_PROGRAM_SUBMIT });
    return api
      .editWorkPlanGroupElement(data, groupId)
      .then(() => dispatch({ type: types.EDIT_GROUP_PROGRAM, data }))
      .catch((error) => {
        dispatch({ type: types.GROUP_PROGRAM_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}

export function deleteProgramElement(data, groupId) {
  return (dispatch) => {
    return api
      .deleteWorkPlanGroupElement(data, groupId)
      .then(() => dispatch({ type: types.DELETE_GROUP_PROGRAM, data }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}
