import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as childrenActions from '../../actions/childrenActions';
import * as attendanceActions from '../../actions/attendanceActions';
import * as attendanceHistoryActions from '../../actions/attendanceHistoryActions';
import * as daysOffActions from '../../actions/daysOffActions';
import * as daycareActions from '../../actions/daycareActions';
import * as reportsActions from '../../actions/reportsActions';
import * as mealActions from '../../actions/mealActions';
import * as mealCalendarActions from '../../actions/mealCalendarActions';
import * as mealCategoriesActions from '../../actions/mealCategoriesActions';
import ChildDetailsHeader from './header/ChildDetailsHeader';
import ChildGuardiansCard from './guardians/ChildGuardiansCard';
import ChildDocumentsCard from './documents/ChildDocumentsCard';
import ChildLegalGuardiansCard from './legalGuardians/ChildLegalGuardiansCard';
import ChildChargingSchemesCard from './chargingSchemes/ChildChargingSchemesCard';
import ChildAttendanceCard from './attendance/ChildAttendanceCard';
import ChildDaycareCard from './daycare/ChildDaycareCard';
import ChildFileAttachmentsCard from './fileAttachments/ChildFileAttachmentsCard';
import ChildOpinionCard from './opinions/ChildOpinionCard';
import ChildEvaluationSurveyCard from './evaluationSurvey/ChildEvaluationSurveyCard';
import ChildDiagnosticSchemesCard from './diagnosticSchemes/ChildDiagnosesCard';
import GroupEnrollmentHistoryCard from './groupEnrollmentHistory/GroupEnrollmentHistoryCard';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { PersonalIdTypes } from '../../constants/personalIdTypes';
import { routePathActions } from '../../routePathActions';
import _ from 'lodash';
import Pesel from '../../utils/Pesel';
import moment from 'moment';
import ArchiveChildDialog from './archiveChildDialog';
import userRoles from '../../constants/userRoles';
import ChildDetailsFormRefactored from './details/ChildDetailsFormRefactored';
import { isInitiallyExpanded } from './config/DialogInitiallyOpenConfig';
import ChildAttendanceActivitiesCard from './activitiesAtendance/ChildAttendanceActivitiesCard';
import { Box, Button } from '@mui/material';
import ChildMealsCalendarCard from './meals/ChildMealsCalendarCard';
import { ChildMealsCard } from './meals/ChildMealsCard';
import ChildIndividualPsychologicalAttendance from './individualPsychologicalAttendance/ChildIndividualPsychologicalAttendance';
import ChildGroupPsychologicalAttendance from './groupPsychologicalAttendance/ChildGroupPsychologicalAttendance';
import ChildVacationPeriodsCard from './vacationPeriod/ChildVacationPeriodsCard';

class ChildDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pupil: Object.assign({}, this.props.pupil),
      areChildMealsLoading: false
    };
    this.onUpdateChildState = this.onUpdateChildState.bind(this);
    this.onSaveChild = this.onSaveChild.bind(this);
    this.onCancelChild = this.onCancelChild.bind(this);
    this.onEditChild = this.onEditChild.bind(this);
    this.onSaveGuardian = this.onSaveGuardian.bind(this);
    this.onCancelGuardian = this.onCancelGuardian.bind(this);
    this.onSaveLegalGuardian = this.onSaveLegalGuardian.bind(this);
    this.onCancelLegalGuardian = this.onCancelLegalGuardian.bind(this);
    this.onArchiveChild = this.onArchiveChild.bind(this);
    this.onPhotoDrop = this.onPhotoDrop.bind(this);
    this.onPhotoRemove = this.onPhotoRemove.bind(this);
    this.onAssignChargingScheme = this.onAssignChargingScheme.bind(this);
    this.onCancelAssignChargingScheme = this.onCancelAssignChargingScheme.bind(this);
    this.onSaveChargingScheme = this.onSaveChargingScheme.bind(this);
    this.onRemoveChargingScheme = this.onRemoveChargingScheme.bind(this);
    this.loadAttendanceData = this.loadAttendanceData.bind(this);
    this.loadDaycareData = this.loadDaycareData.bind(this);
    this.loadMealsCalendarData = this.loadMealsCalendarData.bind(this);
    this.handleChildChangeSubmit = this.handleChildChangeSubmit.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
    this.handlePupilChange = this.handlePupilChange.bind(this);
  }

  componentDidMount() {
    this.loadAttendanceData(new Date());
    this.loadDaycareData(new Date());
    this.loadMealsCalendarData(new Date());
  }

  componentDidUpdate(prevProps) {
    if (this.props.pupil !== prevProps.pupil) {
      this.handlePupilChange(this.props.pupil);
    }
  }

  handlePupilChange(value) {
    this.setState({ pupil: Object.assign({}, value) });
  }

  onUpdateChildState(event) {
    const field = event.target.name;
    if (field === 'personalId') {
      this.onPersonalIdChanged(event.target.value);
    }
    const pupil = Object.assign({}, this.state.pupil);
    _.set(pupil, field, event.target.value);
    return this.setState({ pupil });
  }

  onPersonalIdChanged(personalId) {
    if (this.state.pupil.personalIdType !== PersonalIdTypes.pesel.value) return;
    const pesel = new Pesel(personalId);
    if (!pesel.isValid()) return;
    const decodedPesel = pesel.decode();
    const birthDate = new Date(Date.UTC(decodedPesel.year, decodedPesel.month - 1, decodedPesel.day));

    const pupil = Object.assign(this.state.pupil, { birthDate });
    this.setState({ pupil });
  }

  onSaveChild() {
    this.props.actions
      .updateChildAsync(this.state.pupil)
      .then(() =>
        this.state.pupil.attributes.reduce(
          (p, att) => p.then(() => this.props.actions.updateChildAttributesAsync(this.state.pupil.id, att)),
          Promise.resolve()
        )
      );
  }

  onCancelChild() {
    this.props.actions.updateChildCancel();
    return this.setState({ pupil: Object.assign({}, this.props.pupil) });
  }

  onPhotoDrop(file) {
    this.props.actions.updateProfilePhoto(this.state.pupil.id, file);
  }

  onPhotoRemove() {
    this.props.actions.removeProfilePhoto(this.state.pupil.id);
  }

  onEditChild() {
    this.props.actions.updateChildStart();
  }

  onSaveGuardian(guardian) {
    if (guardian.id) {
      this.props.actions.updateGuardianOfChildAsync(this.state.pupil.id, guardian);
    } else {
      this.props.actions.addGuardianToChildAsync(this.state.pupil.id, guardian);
    }
  }

  onCancelGuardian(guardian) {
    if (guardian.id) {
      this.props.actions.updateGuardianOfChildCancel();
    } else {
      this.props.actions.addGuardianToChildCancel();
    }
  }

  onSaveLegalGuardian(guardian) {
    if (guardian.id) {
      if (this.state.pupil.legalGuardians.some((legal) => legal.id === guardian.id)) {
        this.props.actions.updateLegalGuardianOfChildAsync(this.state.pupil.id, guardian);
      } else {
        this.props.actions.assignLegalGuardianToChildAsync(this.state.pupil.id, guardian);
      }
    } else {
      this.props.actions.addLegalGuardianToChildAsync(this.state.pupil.id, guardian);
    }
  }

  onCancelLegalGuardian(guardian) {
    if (guardian.id) {
      this.props.actions.updateLegalGuardianOfChildCancel();
    } else {
      this.props.actions.addLegalGuardianToChildCancel();
    }
  }

  onArchiveChild(deleteLegalGuardians) {
    this.props.actions.archiveChildAsync(this.state.pupil.id, deleteLegalGuardians);
  }

  onAssignChargingScheme() {
    this.props.actions.assignChargingSchemeStart();
  }

  onCancelAssignChargingScheme() {
    this.props.actions.assignChargingSchemeCancel();
  }

  onSaveChargingScheme(scheme) {
    this.props.actions.updateChargingScheme(this.state.pupil.id, scheme);
  }

  onRemoveChargingScheme(schemeId) {
    this.props.actions.removeChargingScheme(this.state.pupil.id, schemeId);
  }

  loadAttendanceData(date) {
    this.props.attendanceActions.loadChildAttendancesAsync(this.props.pupilId, date);
    this.props.daysOffActions.loadDaysOffAsync(moment(date).format('YYYY'));
  }

  loadDaycareData(date) {
    this.props.daycareActions.loadChildDaycare(this.props.pupilId, date);
  }

  loadMealsCalendarData(date) {
    this.setState({ areChildMealsLoading: true });

    this.props.mealCalendarActions
      .loadChildMealsByMonthAsync(this.props.pupilId, moment(date).format('YYYY'), moment(date).format('MM'))
      .then(() => {
        this.setState({ areChildMealsLoading: false });
      });
  }

  handleLoaded() {
    this.setState({ pupil: Object.assign({}, this.props.pupil) });
  }

  async handleChildChangeSubmit(childDetails, actions) {
    try {
      await this.props.actions.updateChildAsync(childDetails);
    } catch (e) {
      Object.entries(e).forEach((element) => {
        actions.setFieldError(element[0], element[1]);
      });
    }
    childDetails.attributes.reduce(
      (p, att) => p.then(() => this.props.actions.updateChildAttributesAsync(childDetails.id, att)),
      Promise.resolve()
    );
  }

  renderArchiveChildDialog() {
    if (!this.props.archivalUi.isEditing) {
      return null;
    }

    return (
      <ArchiveChildDialog
        pupil={this.props.pupil}
        isDialogOpen={this.props.archivalUi.isEditing}
        isProcessing={this.props.archivalUi.isProcessing}
        onArchive={(deleteLegalGuardians) => this.onArchiveChild(deleteLegalGuardians)}
        onCancel={() => this.props.actions.archiveChildCancel()}
      />
    );
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <ChildDetailsHeader
            name={`${this.state.pupil.firstName} ${this.state.pupil.lastName}`}
            group={this.state.pupil.enrolledToGroup}
            photoUrl={this.state.pupil.photoUrl}
            onPhotoDrop={this.onPhotoDrop}
            onPhotoRemove={this.onPhotoRemove}
          />
          <ChildDetailsFormRefactored
            pupil={this.state.pupil}
            onSubmit={this.handleChildChangeSubmit}
            symphony={this.props.symphonyIntegration}
            isInitiallyExpanded={isInitiallyExpanded.ChildDetailsForm}
          />
          {this.props.isAllowed(routePathActions.childDetails.viewChargingSchemesSection) ? (
            <ChildChargingSchemesCard
              childChargingSchemes={this.state.pupil.contract.schemes}
              allChargingSchemes={this.props.chargingSchemes}
              allReliefs={this.props.reliefs}
              onCreate={this.props.actions.updateChargingSchemeStart}
              onEdit={this.props.actions.updateChargingSchemeStart}
              onCancel={this.props.actions.updateChargingSchemeCancel}
              onSave={this.onSaveChargingScheme}
              onRemove={this.onRemoveChargingScheme}
              isEditing={this.props.chargingSchemesUi.isEditing}
              isProcessing={this.props.chargingSchemesUi.isProcessing}
              pupilId={this.props.pupilId}
              isInitiallyExpanded={isInitiallyExpanded.ChildChargingSchemesCard}
              pupil={this.props.pupil}
            />
          ) : null}
          <ChildMealsCard isInitiallyExpanded={isInitiallyExpanded.ChildMealsCard} pupil={this.props.pupil} />
          <ChildAttendanceCard
            attendances={this.props.attendances}
            daysOff={this.props.daysOff}
            onDateChange={(date) => this.loadAttendanceData(date)}
            isCalendarLoading={this.props.isCalendarLoading}
            attendanceUi={this.props.attendanceUi}
            attendanceHistoryAction={this.props.attendanceHistoryActions}
            attendanceAction={this.props.attendanceActions}
            childDetails={this.state.pupil}
            isInitiallyExpanded={isInitiallyExpanded.ChildAttendanceCard}
          />
          <ChildAttendanceActivitiesCard
            isInitiallyExpanded={isInitiallyExpanded.ChildAttendanceActivitiesCard}
            pupil={this.props.pupil}
          />
          {this.state.pupil.assistanceId.length > 0 &&
            this.props.auth.isStaffMember([
              userRoles.staffMemberPrincipal,
              userRoles.staffMemberAccountant,
              userRoles.staffMemberSecretary,
              userRoles.staffMemberTeacher
            ]) && (
              <ChildIndividualPsychologicalAttendance
                individualAttendance
                childDetails={this.state.pupil}
                isInitiallyExpanded={isInitiallyExpanded.ChildAttendanceCard}
              />
            )}
          {this.state.pupil.enrolledToPsychologicalPedagogicalGroup.length > 0 &&
            this.props.auth.isStaffMember([
              userRoles.staffMemberPrincipal,
              userRoles.staffMemberAccountant,
              userRoles.staffMemberSecretary,
              userRoles.staffMemberTeacher
            ]) && (
              <ChildGroupPsychologicalAttendance
                groupAttendance
                childDetails={this.state.pupil}
                isInitiallyExpanded={isInitiallyExpanded.ChildAttendanceCard}
              />
            )}
          {this.props.isDaycareEnabled && (
            <ChildDaycareCard
              daycare={this.props.daycare}
              daysOff={this.props.daysOff}
              onDateChange={(date) => this.loadDaycareData(date)}
              isCalendarLoading={this.props.isCalendarLoading}
              attendanceUi={this.props.attendanceUi}
              attendanceHistoryAction={this.props.attendanceHistoryActions}
              daycareActions={this.props.daycareActions}
              childDetails={this.state.pupil}
              propertiesDefinition={this.props.daycareConfiguration.propertiesDefinition}
              isInitiallyExpanded={isInitiallyExpanded.ChildDaycareCard}
            />
          )}
          <ChildMealsCalendarCard
            daysOff={this.props.daysOff}
            pupil={this.state.pupil}
            meals={this.props.mealsCalendar}
            isInitiallyExpanded={isInitiallyExpanded.ChildMealsCalendarCard}
            onDateChange={(date) => this.loadMealsCalendarData(date)}
            allMeals={this.props.meals}
            allCategories={this.props.mealsCategories}
            userRole={this.props.auth.userRole}
            isLoading={this.state.areChildMealsLoading}
          />
          <ChildLegalGuardiansCard
            pupilAddress={this.state.pupil.address}
            guardians={this.state.pupil.legalGuardians}
            onChange={this.onUpdateChildState}
            onSave={this.onSaveLegalGuardian}
            onCancel={this.onCancelLegalGuardian}
            onEdit={this.props.actions.updateLegalGuardianOfChildStart}
            onCreate={this.props.actions.addLegalGuardianToChildStart}
            onRemove={(id) => this.props.actions.removeLegalGuardianFromChildAsync(this.state.pupil.id, id)}
            isEditing={this.props.legalGuardiansUi.isEditing}
            isProcessing={this.props.legalGuardiansUi.isProcessing}
            isPinEnabled={this.props.isPinEnabled}
            isInitiallyExpanded={isInitiallyExpanded.ChildLegalGuardiansCard}
          />
          <ChildGuardiansCard
            guardians={this.state.pupil.guardians}
            onChange={this.onUpdateChildState}
            onSave={this.onSaveGuardian}
            onCancel={this.onCancelGuardian}
            onEdit={this.props.actions.updateGuardianOfChildStart}
            onCreate={this.props.actions.addGuardianToChildStart}
            onRemove={(id) => this.props.actions.removeGuardianFromChildAsync(this.state.pupil.id, id)}
            onAuthorize={(id) => this.props.actions.authorizeGuardianOnChildAsync(this.state.pupil.id, id)}
            onUnAuthorize={(id) => this.props.actions.unauthorizeGuardianOnChildAsync(this.state.pupil.id, id)}
            isEditing={this.props.guardiansUi.isEditing}
            isProcessing={this.props.guardiansUi.isProcessing}
            isPinEnabled={this.props.isPinEnabled}
            isInitiallyExpanded={isInitiallyExpanded.ChildGuardiansCard}
          />
          <ChildFileAttachmentsCard
            pupilId={this.state.pupil.id}
            attachments={this.props.pupil.attachments.filter((attachment) => attachment.fileInfo === null)}
            onAdd={(fileAttachment) => this.props.actions.addFileAttachment(this.state.pupil.id, fileAttachment)}
            onCancel={this.props.actions.addFileAttachmentCancel}
            onRemove={(id) => this.props.actions.removeFileAttachment(this.state.pupil.id, id)}
            onCreate={this.props.actions.addFileAttachmentStart}
            isEditing={this.props.attachmentsUi.isEditing}
            isProcessing={this.props.attachmentsUi.isProcessing}
            isInitiallyExpanded={isInitiallyExpanded.ChildFileAttachmentsCard}
          />
          <ChildEvaluationSurveyCard
            pupilId={this.state.pupil.id}
            onCreate={this.props.actions.addSurveyToChildStart}
            attachments={this.props.pupil.attachments.filter(
              (attachment) => attachment.fileInfo && attachment.fileInfo.fileType === 'survey'
            )}
            onCancel={this.props.actions.addSurveyToChildCancel}
            onRemove={(id) => this.props.actions.removeFileAttachment(this.state.pupil.id, id)}
            isEditing={this.props.surveyUi.isEditing}
            isProcessing={this.props.surveyUi.isProcessing}
            onAdd={(fileAttachment) => this.props.actions.addSurveyAsync(this.state.pupil.id, fileAttachment)}
            isInitiallyExpanded={isInitiallyExpanded.ChildEvaluationSurveyCard}
          />
          {this.props.auth.isStaffMember(userRoles.staffMemberPrincipal) ||
          this.props.auth.isStaffMember(userRoles.staffMemberTeacher) ? (
            <ChildDiagnosticSchemesCard
              diagnoses={this.props.pupil.diagnoses}
              onAdd={() => this.props.actions.addChildDiagnoseStart()}
              onCancel={() => this.props.actions.addChildDiagnoseCancel()}
              onSave={(childDiagnosticScheme) =>
                this.props.actions.addChildDiagnoseAsync(this.state.pupil.id, childDiagnosticScheme)
              }
              onRemove={(choosenChildDiagnosticScheme) =>
                this.props.actions.removeChildDiagnoseAsync(this.state.pupil.id, choosenChildDiagnosticScheme)
              }
              onUpdate={(childDiagnosticScheme) => this.props.actions.updateChildDiagnoseAsync(childDiagnosticScheme)}
              onGeneratePdf={() =>
                this.props.reportsActions.generateReportAsync('childrenDiagnosesReport', 'pdf', {
                  childId: this.props.pupilId
                })
              }
              isEditing={this.props.diagnosesUi.isEditing}
              isProcessing={this.props.diagnosesUi.isProcessing}
              auth={this.props.auth}
              isInitiallyExpanded={isInitiallyExpanded.ChildDiagnosticSchemesCard}
            />
          ) : null}
          <ChildOpinionCard
            pupilId={this.state.pupil.id}
            onCreate={this.props.actions.addOpinionToChildStart}
            attachments={this.props.pupil.attachments.filter(
              (attachment) => attachment.fileInfo && attachment.fileInfo.fileType === 'opinion'
            )}
            onCancel={this.props.actions.addOpinionToChildCancel}
            onRemove={(id) => this.props.actions.removeFileAttachment(this.state.pupil.id, id)}
            isEditing={this.props.opinionsUi.isEditing}
            isProcessing={this.props.opinionsUi.isProcessing}
            onAdd={(fileAttachment) => this.props.actions.addOpinionAsync(this.state.pupil.id, fileAttachment)}
            isInitiallyExpanded={isInitiallyExpanded.ChildOpinionCard}
          />

          <ChildVacationPeriodsCard
            vacationPeriods={this.props.pupil.vacationPeriods}
            onAdd={() => this.props.actions.addVacationPeriodToChildStart()}
            onCancel={() => this.props.actions.addVacationPeriodToChildCancel()}
            onSave={(vacationPeriod) =>
              this.props.actions.addVacationPeriodToChildAsync(this.state.pupil.id, vacationPeriod)
            }
            onRemove={(choosenVacationPeriod) =>
              this.props.actions.removeVacationPeriodFromChildAsync(this.state.pupil.id, choosenVacationPeriod.id)
            }
            isEditing={this.props.vacationPeriodsUi.isEditing}
            isProcessing={this.props.vacationPeriodsUi.isProcessing}
            auth={this.props.auth}
            isInitiallyExpanded={isInitiallyExpanded.ChildVacationPeriodsCard}
          />

          {this.props.auth.isStaffMember(userRoles.staffMemberPrincipal) ? (
            <ChildDocumentsCard
              actions={this.props.actions}
              reportsActions={this.props.reportsActions}
              childId={this.props.pupilId}
              enrolledToGroupHistory={this.props.pupil.enrolledToGroupHistory}
              groups={this.props.groups}
              isInitiallyExpanded={isInitiallyExpanded.ChildDocumentsCard}
            />
          ) : null}
          <GroupEnrollmentHistoryCard
            enrolledToGroupHistory={this.props.pupil.enrolledToGroupHistory}
            groups={this.props.groups}
            isInitiallyExpanded={isInitiallyExpanded.GroupEnrollmentHistoryCard}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" aria-label="archiwizuj" onClick={this.props.actions.archiveChildStart}>
              archiwizuj
            </Button>
          </Box>
          {this.renderArchiveChildDialog()}
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ChildDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  pupil: PropTypes.object.isRequired,
  pupilId: PropTypes.string.isRequired,
  detailsUi: PropTypes.object.isRequired,
  legalGuardiansUi: PropTypes.object.isRequired,
  guardiansUi: PropTypes.object.isRequired,
  opinionsUi: PropTypes.object.isRequired,
  vacationPeriodsUi: PropTypes.object.isRequired,
  chargingSchemesUi: PropTypes.object.isRequired,
  chargingSchemes: PropTypes.array.isRequired,
  reliefs: PropTypes.array.isRequired,
  attendances: PropTypes.object.isRequired,
  attendanceUi: PropTypes.object.isRequired,
  attachmentsUi: PropTypes.object.isRequired,
  attendanceActions: PropTypes.object.isRequired,
  attendanceHistoryActions: PropTypes.object.isRequired,
  surveyUi: PropTypes.object.isRequired,
  daysOff: PropTypes.array.isRequired,
  daysOffActions: PropTypes.object.isRequired,
  isCalendarLoading: PropTypes.bool.isRequired,
  isPinEnabled: PropTypes.bool.isRequired,
  isAllowed: PropTypes.func.isRequired,
  isDaycareEnabled: PropTypes.bool.isRequired,
  daycareConfiguration: PropTypes.object.isRequired,
  daycare: PropTypes.object.isRequired,
  daycareActions: PropTypes.object.isRequired,
  archivalUi: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  diagnosesUi: PropTypes.object.isRequired,
  reportsActions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  symphonyIntegration: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  const pupilId = ownProps.params.id; //from group/:id path !parameter is always a string!
  return {
    pupilId,
    pupil: state.childDetails,
    detailsUi: state.childDetailsUi.details,
    legalGuardiansUi: state.childDetailsUi.legalGuardians,
    guardiansUi: state.childDetailsUi.guardians,
    opinionsUi: state.childDetailsUi.opinions,
    vacationPeriodsUi: state.childDetailsUi.vacationPeriods,
    attachmentsUi: state.childDetailsUi.attachments,
    surveyUi: state.childDetailsUi.surveys,
    chargingSchemesUi: state.childDetailsUi.chargingSchemes,
    chargingSchemes: state.configuration.chargingSchemes,
    reliefs: state.configuration.reliefs,
    attendances: state.childAttendances,
    mealsCalendar: state.childMealsCalendar,
    meals: state.meals,
    mealsCategories: state.mealsCategories,
    attendanceUi: state.attendanceUi,
    daysOff: state.configuration.daysOff,
    isCalendarLoading: state.childDetailsUi.isCalendarLoading,
    isPinEnabled: state.configuration.guardiansPinService.enabled,
    isAllowed: state.auth.isAllowed.bind(state.auth),
    daycare: state.childDaycare,
    isDaycareEnabled: state.configuration.daycare.enabled,
    daycareConfiguration: state.configuration.daycare,
    archivalUi: state.childDetailsUi.archival,
    groups: state.groups,
    diagnosesUi: state.childDetailsUi.diagnoses,
    auth: state.auth,
    symphonyIntegration: state.configuration.settlementsSettings.symphonyIntegrationEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(childrenActions, dispatch),
    attendanceActions: bindActionCreators(attendanceActions, dispatch),
    meal: bindActionCreators(mealActions, dispatch),
    mealCategoriesActions: bindActionCreators(mealCategoriesActions, dispatch),
    mealCalendarActions: bindActionCreators(mealCalendarActions, dispatch),
    daycareActions: bindActionCreators(daycareActions, dispatch),
    daysOffActions: bindActionCreators(daysOffActions, dispatch),
    attendanceHistoryActions: bindActionCreators(attendanceHistoryActions, dispatch),
    reportsActions: bindActionCreators(reportsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildDetailsPage);
