'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Button,
  ListItemButton,
  Box,
  ListSubheader
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmptyState from '../common/EmptyState';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GroupModel } from '../../models/groups/GroupModels';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

const styles = {
  dialogPaper: {
    width: 768,
    maxWidth: '90vw',
    maxHeight: '80vh'
  }
};

class GroupPickerDialog extends React.Component {
  renderEmptyState() {
    return <EmptyState contrast message="Brak zdefiniowanych grup" />;
  }

  renderList() {
    return (
      <List sx={{ width: '100%' }}>
        {this.props.showAllGroups && (
          <ListItem sx={{ py: 1, px: 3 }} component="li" aria-label="Wybierz wszystkie możliwe grupy">
            <ListItemButton
              onClick={() =>
                this.props.onSelect(
                  new GroupModel().assign({
                    id: 'all',
                    name: 'Wszystkie grupy',
                    yearbook: 2023,
                    isTemporary: false,
                    //Nie podpinamy tutaj theme !!!!
                    color: 'linear-gradient(#66A6FF, #89F7FE)',
                    enrolledChildren: [],
                    associatedTeachers: []
                  })
                )
              }>
              <ListItemText
                disableTypography
                primary={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <GroupsOutlinedIcon
                      sx={{
                        ml: 1,
                        mr: 2,
                        color: (theme) => theme.palette.color.color25,
                        fontSize: 30
                      }}
                    />
                    <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                      Wszystkie grupy
                    </Typography>
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
        )}
        {this.props.groups.map((group) => {
          return (
            <ListItem key={group.id} sx={{ px: 3, py: 0 }} component="li" aria-label={`Wybierz grupę ${group.name}`}>
              <ListItemButton onClick={() => this.props.onSelect(group)}>
                <ListItemAvatar>
                  <Avatar sx={{ background: (theme) => theme.palette.color.wcag || group.color }} />
                </ListItemAvatar>
                <ListItemText
                  primary={group.name}
                  primaryTypographyProps={{
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}
                  secondary={group.isTemporary ? 'Tymczasowa' : `Rok ${group.yearbook}/${group.yearbook + 1}`}
                  secondaryTypographyProps={{
                    fontSize: 14,
                    color: (theme) => theme.palette.color.contrast
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  renderArchivedList() {
    return (
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <ListSubheader sx={{ fontSize: 16, color: (theme) => theme.palette.color.contrast }}>
              Grupy archiwalne
            </ListSubheader>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ width: '100%' }}>
              {this.props.archivedGroups.map((group) => {
                return (
                  <ListItem
                    key={group.id}
                    sx={{ px: 1, py: 0 }}
                    component="li"
                    aria-label={`Wybierz grupę ${group.name}`}>
                    <ListItemButton onClick={() => this.props.onSelect(group)}>
                      <ListItemAvatar>
                        <Avatar sx={{ background: (theme) => theme.palette.color.wcag || group.color }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={group.name}
                        primaryTypographyProps={{
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}
                        secondary={group.isTemporary ? 'Tymczasowa' : `Rok ${group.yearbook}/${group.yearbook + 1}`}
                        secondaryTypographyProps={{
                          fontSize: 14,
                          color: (theme) => theme.palette.color.contrast
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        onClose={() => {
          if (!this.props.cancelDisabled) this.props.onCancel();
        }}
        open={this.props.open}
        classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>
          <Typography variant="h6" component="p">
            Wybierz grupę
          </Typography>
        </DialogTitle>
        {this.props.groups.length === 0 ? this.renderEmptyState() : this.renderList()}
        {this.props.archivedGroups.length === 0 ? null : this.renderArchivedList()}
        <DialogActions sx={{ ml: 'auto', pt: 3 }}>
          <Button
            variant="contained"
            aria-label="Anuluj"
            onClick={this.props.onCancel}
            disabled={this.props.cancelDisabled}>
            anuluj
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

GroupPickerDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  archivedGroups: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.any,
  cancelDisabled: PropTypes.bool
};

export default withStyles(styles)(GroupPickerDialog);
