import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Small = ({ sx, ...rest }) => (
  <Typography
    sx={{ fontWeight: (t) => t.typography.fontWeightlight, fontSize: '0.9rem', display: 'inline', ...sx }}
    {...rest}
  />
);

Small.propTypes = {
  sx: PropTypes.object
};

export default Small;
