import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import EmptyState from '../../common/EmptyState';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material';

const SummaryTable = ({ childDetailsSummary, type, isError }) => {
  const theme = useTheme();
  const totalPayments =
    !!childDetailsSummary.length &&
    childDetailsSummary.reduce((acc, crr) => ({
      baseDue: acc.baseDue + crr.baseDue,
      calculatedTotal: acc.calculatedTotal + crr.calculatedTotal,
      availableFund: acc.availableFund + crr.availableFund,
      correctionTotal: acc.correctionTotal + crr.correctionTotal,
      paymentsSummary: acc.paymentsSummary + crr.paymentsSummary,
      totalDelayInterests: acc.totalDelayInterests + crr.totalDelayInterests,
      unpaidTotal: acc.unpaidTotal + crr.unpaidTotal
    }));

  const renderRow = (item, summary) => {
    const directionTable = [
      item.baseDue,
      item.correctionTotal,
      item.totalDelayInterests,
      item.calculatedTotal,
      item.paymentsSummary,
      item.unpaidTotal,
      item.availableFund
    ];

    return (
      <>
        <TableCell>
          <Typography sx={{ fontWeight: summary && theme.typography.fontWeightBold }}>
            {summary ? 'Razem' : item.month}
          </Typography>
        </TableCell>
        {directionTable.map((x, index) => {
          return (
            <TableCell key={index}>
              <Typography sx={{ fontWeight: summary && theme.typography.fontWeightBold }}>{x}</Typography>
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <>
      {childDetailsSummary.length && !isError ? (
        <TableContainer component="div">
          <Table aria-label="Historia operacji">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Należność bieżąca (zł)</TableCell>
                <TableCell>Odpis (zł)</TableCell>
                <TableCell>Odsetki (zł)</TableCell>
                <TableCell>Do zapłaty (zł)</TableCell>
                <TableCell>Wpłata razem (zł)</TableCell>
                <TableCell>Zaległość (zł)</TableCell>
                <TableCell>Nadpłaty (zł)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {childDetailsSummary.map((detail, index) => (
                <TableRow key={index}>{renderRow(detail)}</TableRow>
              ))}
              <TableRow key="summary" sx={{ borderTop: '1px solid grey' }}>
                {renderRow(totalPayments, true)}
              </TableRow>
            </TableBody>
          </Table>
          <Typography sx={{ py: 1, fontSize: 14 }}>Ewidencja płatności za {type.toLowerCase()}</Typography>
        </TableContainer>
      ) : (
        <EmptyState message="Brak historii operacji" contrast />
      )}
    </>
  );
};

SummaryTable.propTypes = {
  childDetailsSummary: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  isError: PropTypes.bool
};

export default SummaryTable;
