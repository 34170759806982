import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TextField, Typography, Box, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import GroupPickerTemplate from './common/GroupPickerTemplate';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';

const ChildrenAttendanceInHourIntervalsMonthReport = ({
  groupId,
  startDate,
  groups,
  onChange,
  disableSaveButton,
  errors,
  legend
}) => {
  const [startingHour, setStartingHour] = useState(8);
  const [endingHour, setEndingHour] = useState(16);
  const [startingErrors, setStartingErrors] = useState([]);
  const [endingErrors, setEndingErrors] = useState([]);
  const [maxRows, setMaxRows] = useState(endingHour - startingHour);
  const [finalPayload, setFinalPayload] = useState();
  const [checkboxState, setCheckboxState] = useState(false);
  const [startingHourError, setStartingHourError] = useState(false);
  const [endingHourError, setEndingHourError] = useState(false);

  const [intervalHours, setIntervalHours] = useState([
    {
      start: '',
      end: ''
    }
  ]);

  const createDefaultPayload = () => {
    if (startingHour && endingHour && parseInt(endingHour, 10) > parseInt(startingHour, 10)) {
      setFinalPayload(
        Array.from(
          new Array(endingHour - startingHour),
          (x, i) => `{"start": "${i + parseInt(startingHour, 10)}", "end": "${i + parseInt(startingHour, 10) + 1}"}`
        )
      );
    }
  };

  const handleChangeStartingIntervalHours = (event, index) => {
    const newArray = intervalHours;
    setIntervalHours();
    createDefaultPayload();
    newArray[index].start = event.target.value;
    setIntervalHours([...newArray]);
  };

  const handleChangeEndingIntervalHours = (event, index) => {
    const newArray = intervalHours;
    setIntervalHours();
    createDefaultPayload();
    newArray[index].end = event.target.value;
    setIntervalHours([...newArray]);
  };

  const handleChangeStartingHour = (event) => {
    const value = parseInt(event.target.value, 10);
    setStartingHour(Number.isNaN(value) ? '' : value);
    setMaxRows(endingHour - value);
    setIntervalHours([{ start: '', end: '' }]);
    if (value < 0 || value > 24) {
      setStartingHourError(true);
    } else {
      setStartingHourError(false);
    }
    setStartingErrors([]);
    setEndingErrors([]);
    createDefaultPayload();
  };

  const handleChangeEndingHour = (event) => {
    const value = parseInt(event.target.value, 10);
    setEndingHour(Number.isNaN(value) ? '' : value);
    setMaxRows(value - startingHour);
    setIntervalHours([{ start: '', end: '' }]);
    if (value < 0 || value > 24 || value < parseInt(startingHour, 10)) {
      setEndingHourError(true);
    } else {
      setEndingHourError(false);
    }
    setStartingErrors([]);
    setEndingErrors([]);
    createDefaultPayload();
  };

  const handleValidateHours = (intervalHour, index, staringErr, endingErr) => {
    if (intervalHour.start && intervalHour.start !== '') {
      if (index > 0) {
        if (parseInt(intervalHour.start, 10) < intervalHours[index - 1].end) {
          staringErr.push({
            errorIndex: index,
            errorMessage: 'Godzina rozpoczęcia interwału nie może być wcześniejsza niż godzina, poprzedniego interwału'
          });
        }
      }
      if (parseInt(intervalHour.start, 10) < startingHour) {
        staringErr.push({
          errorIndex: index,
          errorMessage:
            'Godzina rozpoczęcia interwału nie może być wcześniejsza niż godzina, dla której raport jest generowany'
        });
      }
      if (parseInt(intervalHour.start, 10) > endingHour) {
        staringErr.push({
          errorIndex: index,
          errorMessage:
            'Godzina rozpoczęcia interwału nie może być późniejsza niż godzina, dla której raport jest generowany'
        });
      }
      setStartingErrors(staringErr);
    }
    if (intervalHour.end && intervalHour.end !== '') {
      if (parseInt(intervalHour.end, 10) < parseInt(intervalHour.start, 10)) {
        endingErr.push({
          errorIndex: index,
          errorMessage: 'Godzina zakończenia interwału nie może być wcześniejsza niż godzina rozpoczęcia'
        });
      }
      if (parseInt(intervalHour.end, 10) < startingHour) {
        endingErr.push({
          errorIndex: index,
          errorMessage:
            'Godzina zakończenia interwału nie może być wcześniejsza niż godzina, dla której raport jest generowany'
        });
      }
      if (parseInt(intervalHour.end, 10) > endingHour) {
        endingErr.push({
          errorIndex: index,
          errorMessage:
            'Godzina zakończenia interwału nie może być późniejsza niż godzina, dla której raport jest generowany'
        });
      }
      setEndingErrors(endingErr);
    }
  };

  const createPayload = (intervalHour, index) => {
    if (index === 0 && intervalHour.start !== '' && intervalHour.end !== '') {
      setFinalPayload([`{"start": "${intervalHour.start}", "end": "${intervalHour.end}"}`]);
    }
    if (index > 0 && intervalHour.start !== '' && intervalHour.end !== '') {
      setFinalPayload((prev) => [...prev, `{"start": "${intervalHour.start}", "end": "${intervalHour.end}"}`]);
    }
  };

  const handleChangeCheckboxState = () => {
    setCheckboxState((prev) => !prev);
  };

  useEffect(() => {
    const startingErr = [];
    const endingErr = [];
    const lastElement = intervalHours.slice(-1);
    const newArray = intervalHours;
    if (lastElement[0].start !== '' && lastElement[0].end !== '' && intervalHours.length < maxRows) {
      newArray.push({ start: '', end: '' });
      newArray.map((intervalHour, index) => handleValidateHours(intervalHour, index, startingErr, endingErr));
      setIntervalHours(newArray);
    }
  }, [JSON.stringify(intervalHours)]);

  useEffect(() => {
    const startingErr = [];
    const endingErr = [];
    setFinalPayload();
    if (intervalHours.length > 0) {
      intervalHours.map((intervalHour, index) => handleValidateHours(intervalHour, index, startingErr, endingErr));
    }
    intervalHours.map((intervalHour, index) => createPayload(intervalHour, index));
  }, [JSON.stringify(intervalHours)]);

  useEffect(() => {
    disableSaveButton(false);
    if (startingErrors.length > 0 || endingErrors.length > 0) {
      disableSaveButton(true);
    }
  }, [JSON.stringify(startingErrors), JSON.stringify(endingErrors), startingHourError, endingHourError]);

  useEffect(() => {
    setStartingErrors([]);
    setEndingErrors([]);
    setIntervalHours([{ start: '', end: '' }]);
    createDefaultPayload();
  }, [JSON.stringify(startingHour), JSON.stringify(endingHour), checkboxState]);

  useEffect(() => {
    if (finalPayload) {
      onChange({
        target: {
          name: 'intervals',
          value: finalPayload
        }
      });
    }
    if (!finalPayload) {
      createDefaultPayload();
    }
  }, [JSON.stringify(finalPayload)]);

  return (
    <Box>
      <FieldsetWrapper legend="Obecność dzieci w interwałach godzinowych">
        <GroupPickerTemplate
          groupId={groupId}
          groups={groups}
          onChange={onChange}
          legend={legend}
          errorText={errors.groupId}
        />
        <Typography variant="h6">Wybierz miesiąc</Typography>

        <YearAndMonthOptionsSlider
          contrast
          onSelected={(year, month) => {
            const date = moment(`${year}-${month}`, 'YYYY-MM');
            onChange({ target: { name: 'startDate', value: date.startOf('month').format('YYYY-MM-DD') } });
            onChange({ target: { name: 'endDate', value: date.endOf('month').format('YYYY-MM-DD') } });
          }}
          defaultMonth={moment(startDate).format('MM')}
          defaultYear={moment(startDate).format('YYYY')}
        />
      </FieldsetWrapper>
      <Typography variant="h6" sx={{ pb: 2, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
        Podaj godziny dla których ma być wygenerowany raport:
      </Typography>
      <Box sx={{ display: { sm: 'flex' }, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
        <Box
          sx={{
            pb: { xs: 1, sm: 0 },
            mr: { sm: 2 },
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-start' }
          }}>
          <TextField
            value={startingHour}
            error={startingHourError}
            onChange={() => handleChangeStartingHour(event)}
            label="Od"
            variant="standard"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          <TextField
            value={endingHour}
            error={endingHourError}
            onChange={() => handleChangeEndingHour(event)}
            label="Do"
            variant="standard"
          />
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={checkboxState} onChange={handleChangeCheckboxState} name="checkBox" />}
          label="Zaawansowane ustalanie godzin"
        />
      </Box>
      {checkboxState && (
        <>
          <Typography variant="h6" sx={{ pb: 2 }}>
            Podaj godziny interwałów:
          </Typography>
          {intervalHours.map((intervalHour, index) => (
            <Box
              key={index}
              sx={{
                py: { xs: 2, sm: 1 },
                display: { sm: 'flex' },
                justifyContent: { xs: 'center', sm: 'flex-start' }
              }}>
              <Box
                sx={{
                  pb: 1,
                  mr: { sm: 2 },
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-start' }
                }}>
                <TextField
                  label="Od"
                  error={startingErrors.some((error) => error.errorIndex === index)}
                  value={intervalHour.start}
                  onChange={() => handleChangeStartingIntervalHours(event, index)}
                  variant="standard"
                />
              </Box>
              <Box sx={{ pb: 1, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                <TextField
                  label="Do"
                  error={endingErrors.some((error) => error.errorIndex === index)}
                  value={intervalHour.end}
                  onChange={() => handleChangeEndingIntervalHours(event, index)}
                  variant="standard"
                />
              </Box>
            </Box>
          ))}
          <Box sx={{ mt: 2 }}>
            {startingErrors.map((error, index) => (
              <Typography key={index} variant="body2" sx={{ color: (theme) => theme.palette.color.error }}>
                {error.errorMessage}
              </Typography>
            ))}
          </Box>
          <Box sx={{ mt: 1 }}>
            {endingErrors.map((error, index) => (
              <Typography key={index} variant="body2" gutterBottom sx={{ color: (theme) => theme.palette.color.error }}>
                {error.errorMessage}
              </Typography>
            ))}
          </Box>
        </>
      )}
      <Box sx={{ mt: 1 }}>
        {startingHourError ? (
          <Typography variant="body2" gutterBottom sx={{ color: (theme) => theme.palette.color.error }}>
            Wprowadzona godzina rozpoczęcia jest nieprawidłowa lub poza zakresem
          </Typography>
        ) : null}
        {endingHourError ? (
          <Typography variant="body2" gutterBottom sx={{ color: (theme) => theme.palette.color.error }}>
            Wprowadzona godzina zakończenia jest nieprawidłowa, poza zakresem lub mniejsza od godziny rozpoczęcia
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

ChildrenAttendanceInHourIntervalsMonthReport.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  disableSaveButton: PropTypes.func
};

export default ChildrenAttendanceInHourIntervalsMonthReport;
