import ApiBase, { ROOT_URL } from './ApiBase';

class SettlementsSettingsApi extends ApiBase {
  static getSettlementsSettings() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/settlements`).type('application/json'));
  }

  static updateSettlementsSettings(settings) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/settlements`).send(settings).type('application/json')
    );
  }
}

export default SettlementsSettingsApi;
