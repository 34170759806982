import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import PersonAvatar from '../forms/PersonAvatar';

const SelectChildDialog = (props) => {
  const { onSelect, children, open, onCancel } = props;
  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>Wybierz dziecko</DialogTitle>
      <DialogContent>
        <List component="nav">
          {children.map((child) => {
            return (
              <ListItem
                key={child.id}
                component="li"
                aria-label={`Wybierz dziecko ${child.firstName + ' ' + child.lastName}`}>
                <ListItemButton onClick={() => onSelect(child)}>
                  <ListItemIcon>
                    <PersonAvatar initials url={child.photoUrl} firstName={child.firstName} lastName={child.lastName} />
                  </ListItemIcon>
                  <ListItemText
                    primary={child.firstName + ' ' + child.lastName}
                    primaryTypographyProps={{
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlinedContrast" onClick={onCancel} aria-label="Anuluj">
            Anuluj
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

SelectChildDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default SelectChildDialog;
