import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box
} from '@mui/material';

class ActionConfirmationMenuItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.handleAction = this.handleAction.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.confirmButton = React.createRef();
  }

  handleAction(event) {
    event.preventDefault();

    if (!!this.props.onItemClick) this.props.onItemClick();
    this.setState({ isOpen: true });
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  handleCancel() {
    this.setState({ isOpen: false });
    this.props.onCancel();
  }

  // getButtonColor() {
  //   if (this.props.isPrimary) return 'primary';
  //   if (this.props.isSecondary) return 'secondary';
  //   return 'inherit';
  // }

  renderActionButtons() {
    return [
      <Button variant="outlinedContrast" aria-label={this.props.cancelLabel} onClick={this.handleCancel}>
        {this.props.cancelLabel}
      </Button>,
      <Button variant="contained" aria-label={this.props.confirmLabel} onClick={this.handleConfirm} autoFocus>
        {this.props.confirmLabel}
      </Button>
    ];
  }

  render() {
    return (
      <>
        <MenuItem
          onClick={(e) => this.handleAction(e)}
          sx={this.props.style}
          aria-haspopup="true"
          aria-controls="confirm-dialog-control"
          aria-label={this.props.actionLabel}
          autoFocus={this.props.autoFocus}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {this.props.leftIcon && (
              <Box sx={{ minWidth: 9, display: 'flex', alignItems: 'center' }}>{this.props.leftIcon}</Box>
            )}
            {this.props.actionLabel}
            {this.props.rightIcon && (
              <Box sx={{ minWidth: 9, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {this.props.rightIcon}
              </Box>
            )}
          </Box>
        </MenuItem>

        <Dialog
          sx={{ zIndex: (theme) => theme.zIndex.modal, p: 5 }}
          id="confirm-dialog"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleCancel();
            }
            if (reason === 'escapeKeyDown') {
              this.handleCancel();
            }
          }}
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={this.state.isOpen}>
          <DialogTitle>{this.props.confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: (theme) => theme.palette.color.contrast, textAlign: 'justify' }}>
              {this.props.confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlinedContrast"
              aria-label={this.props.cancelLabel}
              autoFocus
              onClick={this.handleCancel}>
              {this.props.cancelLabel}
            </Button>
            <Button
              variant="contained"
              aria-label={this.props.confirmLabel}
              onClick={this.handleConfirm}
              ref={this.confirmButton}>
              {this.props.confirmLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ActionConfirmationMenuItem.propTypes = {
  actionLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  style: PropTypes.object,
  onItemClick: PropTypes.func,
  autoFocus: PropTypes.bool
};

ActionConfirmationMenuItem.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
  isPrimary: false,
  isSecondary: false,
  isDisabled: false,
  autoFocus: false
};

export default ActionConfirmationMenuItem;
