import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import locale from 'moment/locale/pl';
import ActionConfirmationMenuItem from '../../../forms/ActionConfirmationMenuItem';
import PostHeader from './PostHeader';
import DotsMenu from '../../../common/menu/DotsMenu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import Strong from '../../../common/Strong';

moment.locale('pl', locale);

class BoardPostCard extends React.Component {
  renderTitleWithEditButton(post, canEdit, onEdit, onDelete, id) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <PostHeader post={post} />
        {canEdit && (
          <DotsMenu key={`dostMenu-${id}`}>
            {(onClose) => [
              <MenuItem key={`edit-${id}`} onClick={() => onEdit()}>
                Edytuj
              </MenuItem>,
              <ActionConfirmationMenuItem
                id={id}
                key={`action-${id}`}
                actionLabel="Usuń"
                confirmationText="Czy na pewno chcesz usunąć wpis?"
                confirmationTitle="Usuń wpis"
                confirmLabel="Usuń"
                onItemClick={() => onClose()}
                onConfirm={() => onDelete()}
                onCancel={() => {}}
              />
            ]}
          </DotsMenu>
        )}
      </Box>
    );
  }

  render() {
    return (
      <Card>
        <CardHeader
          sx={{ pb: 1 }}
          title={this.renderTitleWithEditButton(
            this.props.post,
            this.props.canEdit,
            this.props.onEdit,
            this.props.onDelete,
            this.props.id
          )}
        />
        <CardContent sx={{
          py: 0,
          '& img': {
            maxWidth: '100%'
          }
        }} dangerouslySetInnerHTML={{ __html: this.props.post.content }} />
        {this.props.post.learningMaterials && (
          <CardContent>
            <Strong>materiały dydaktyczne</Strong>
            <Typography>{this.props.post.learningMaterials}</Typography>
          </CardContent>
        )}
        {this.props.post.programPoints && (
          <CardContent>
            <Strong>Podstawa programowa</Strong>
            <Box>{this.props.post.programPoints}</Box>
          </CardContent>
        )}
      </Card>
    );
  }
}

BoardPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  id: PropTypes.string
};

BoardPostCard.contextTypes = {
  muiTheme: PropTypes.object
};

export default BoardPostCard;