import { Box, Button, Grid } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import { SearchBar } from '../common/SearchBar';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import MealsList from './MealsList';
import EmptyState from '../common/EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import DialogForm from '../forms/DialogForm';
import userRoles from '../../constants/userRoles';
import AddMealCategoryForm from './forms/AddMealCategoryForm';
import { MealCategory } from '../../models/meals/MealModels';
import { MealCategoryModelValidator } from './MealCategoryModelValidator';
import { createMealCategoryAsync } from '../../actions/mealCategoriesActions';
import MealsWithoutCategoryList from './MealsWithoutCategoryList';

const MealsPage = () => {
  const mealsCategories = useSelector((state) => state.mealsCategories);
  const meals = useSelector((state) => state.meals);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [isAddCategoryDialogOpen, setIsAddCategoryDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [mealCategory, setMealCategory] = useState(new MealCategory());

  const allMealsWithCategory = mealsCategories.reduce((acc, category) => {
    return acc.concat(category.meals);
  }, []);

  const mealsWithoutCategory = meals.filter((meal) => {
    return !allMealsWithCategory.some((m) => m.id === meal.id);
  });

  const redirectToRegisterMealPage = () => {
    browserHistory.push(routePaths.mealRegister);
  };

  const redirectToArchivedMealsPage = () => {
    browserHistory.push(routePaths.mealsArchive);
  };

  const handleOnAddCategoryClick = () => {
    setIsAddCategoryDialogOpen((prev) => !prev);
    setMealCategory((prevMealCategory) => ({
      ...prevMealCategory,
      name: ''
    }));
  };

  const onUpdateCategoryState = (event) => {
    const field = event.target.name;
    const { value } = event.target;
    setMealCategory((prevMealCategory) => ({
      ...prevMealCategory,
      [field]: value
    }));
  };

  const onSaveCategory = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(createMealCategoryAsync(mealCategory));
      setIsAddCategoryDialogOpen(false);
      setMealCategory((prevMealCategory) => ({
        ...prevMealCategory,
        name: ''
      }));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, mealCategory]);

  return (
    <LoadingRenderWrapper>
      <>
        <PageHeaderText title="Posiłki" titleIcon={<DinnerDiningOutlinedIcon />} />
        <ContentWrapper>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} md={5} sx={{ dispaly: 'flex', justifyContent: 'center' }}>
              <SearchBar
                label="Wyszukaj"
                SearchOnClick={(value) => {
                  setSearchText(value.target.value);
                }}
                onKeyPress={(value) => {
                  setSearchText(value.target.value);
                }}
                value={searchText}
                onChange={(value) => {
                  setSearchText(value.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
              <Button
                variant="contained"
                aria-label="Dodaj posiłek"
                onClick={redirectToRegisterMealPage}
                sx={{ my: 1 }}>
                Dodaj posiłek
              </Button>
              <Button
                variant="contained"
                aria-label="Dodaj kategorię"
                onClick={handleOnAddCategoryClick}
                sx={{ my: 1 }}>
                Dodaj kategorię
              </Button>
            </Grid>
          </Grid>
          {mealsCategories.length === 0 && meals.length === 0 ? (
            <EmptyState isPaper contrast message="Nie utworzono jeszcze żadnych kategorii posiłków ani posiłków" />
          ) : (
            <>
              {mealsCategories
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((category) => (
                  <MealsList key={category.id} meals={category.meals} category={category} filterBy={searchText} />
                ))}
              <MealsWithoutCategoryList
                mealsCategories={mealsCategories}
                meals={mealsWithoutCategory}
                filterBy={searchText}
              />
              {!auth.isStaffMember(userRoles.staffMemberDigitalDiary) && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    variant="outlined"
                    aria-label="Pokaż zarchiwizowane posiłki"
                    onClick={redirectToArchivedMealsPage}>
                    Pokaż zarchiwizowane posiłki
                  </Button>
                </Box>
              )}
            </>
          )}
          {isAddCategoryDialogOpen && (
            <DialogForm
              maxWidth="xs"
              header="Dodaj kategorię posiłków"
              isDialogOpen={isAddCategoryDialogOpen}
              onSave={(result) => {
                onSaveCategory(result);
              }}
              isProcessing={isLoading}
              onCancel={handleOnAddCategoryClick}
              onValidate={() => new MealCategoryModelValidator().validate(mealCategory)}
              onValidationDone={(errors) => setErrors(errors)}>
              <AddMealCategoryForm category={mealCategory} onChange={onUpdateCategoryState} errors={errors} />
            </DialogForm>
          )}
        </ContentWrapper>
      </>
    </LoadingRenderWrapper>
  );
};

MealsPage.propTypes = {};

export default MealsPage;
