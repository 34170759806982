import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DialogForm from '../forms/DialogForm';
import { tryConvertToNumber } from '../../utils/numberConverters';
import _ from 'lodash';
import TextField from '../common/TextField';
import { Grid, Typography } from '@mui/material';

class CalculateStaffSettlementsDialog extends React.Component {
  constructor(props) {
    super(props);

    const attendance = props.staffMembers.map((staff) => ({
      staffMemberId: staff.id,
      daysPresent: 0
    }));
    this.state = {
      calculationParameters: { attendance },
      errors: {}
    };

    this.handleParamsChange = this.handleParamsChange.bind(this);
  }

  handleParamsChange(event) {
    const calculationParameters = _.cloneDeep(this.state.calculationParameters);
    const attendance = calculationParameters.attendance.find((x) => x.staffMemberId === event.target.name);
    attendance.daysPresent = event.target.value;
    return this.setState({ calculationParameters });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        maxWidth="sm"
        header="Generowanie opłat"
        onSave={() => onSave(this.state.calculationParameters)}
        onCancel={onCancel}
        saveLabel="Przelicz"
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="settlementsCalculationUi">
        <Typography sx={{ py: 2, fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Wylicz opłaty dla
        </Typography>
        {this.props.staffMembers.map((staffMember) => {
          return (
            <Grid
              container
              columnSpacing={1}
              key={staffMember.id}
              sx={{
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}>
              <Grid item xs={8} sm={9} sx={{ display: 'flex', alignItems: 'center' }}>
                {staffMember.lastName} {staffMember.firstName}
              </Grid>
              <Grid item xs={3} sm={2} sx={{ mb: 1 }}>
                <TextField
                  inputProps={{
                    style: {
                      textAlign: 'center'
                    }
                  }}
                  fullWidth={true}
                  onChange={this.handleParamsChange}
                  name={staffMember.id}
                  value={
                    _.find(this.state.calculationParameters.attendance, (a) => a.staffMemberId === staffMember.id)
                      .daysPresent
                  }
                  onBlur={(e) => {
                    this.handleParamsChange({
                      target: { name: staffMember.id, value: tryConvertToNumber(e.target.value) }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                dni
              </Grid>
            </Grid>
          );
        })}
      </DialogForm>
    );
  }
}

CalculateStaffSettlementsDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  staffMembers: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    staffMembers: state.staffMembers
  };
}

export default connect(mapStateToProps)(CalculateStaffSettlementsDialog);
