import BaseModel from '../BaseModel';

export class Person extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = '';
  }
}

export class PersonGuardian extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = '';
    this.relationship = '';
  }
}

export class AttendanceJournalEntryModel extends BaseModel {
  constructor() {
    super();
    this.child = new Person();
    this.droppedOffAt = null;
    this.pickedUpAt = null;
    this.pickedUpBy = new PersonGuardian();
    this.absenceFrom = null;
    this.absenceTo = null;
    this.absenceNotes = null;
    this.absenceReportedAt = null;
    this.absenceReportedBy = new Person();
    this.droppedOffModifiedAt = null;
    this.pickedUpModifiedAt = null;
    this.violatedSchemeDeadlines = [];
    this.receivedBy = new Person();
    this.releasedBy = new Person();
    this.isAdaptation = false;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.droppedOffAt = assigned.droppedOffAt ? new Date(assigned.droppedOffAt) : null;
    assigned.pickedUpAt = assigned.pickedUpAt ? new Date(assigned.pickedUpAt) : null;
    assigned.absenceFrom = assigned.absenceFrom ? new Date(assigned.absenceFrom) : null;
    assigned.absenceTo = assigned.absenceTo ? new Date(assigned.absenceTo) : null;
    assigned.absenceReportedAt = assigned.absenceReportedAt ? new Date(assigned.absenceReportedAt) : null;
    assigned.droppedOffModifiedAt = assigned.droppedOffModifiedAt ? new Date(assigned.droppedOffModifiedAt) : null;
    assigned.pickedUpModifiedAt = assigned.pickedUpModifiedAt ? new Date(assigned.pickedUpModifiedAt) : null;
    assigned.child = new Person().assign(assigned.child);
    assigned.absenceReportedBy = new Person().assign(assigned.absenceReportedBy);
    assigned.pickedUpBy = new PersonGuardian().assign(assigned.pickedUpBy);
    assigned.receivedBy = new Person().assign(assigned.receivedBy);
    assigned.releasedBy = new Person().assign(assigned.releasedBy);
  }
}

export class AttendanceJournalModel extends BaseModel {
  constructor() {
    super();
    this.groupId = null;
    this.date = null;
    this.childrenAttendance = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.childrenAttendance = assigned.childrenAttendance.map((e) => new AttendanceJournalEntryModel().assign(e));
    assigned.date = assigned.date ? new Date(assigned.date) : null;
  }
}

//Child attendance

export class ChildAttendanceEntryModel extends BaseModel {
  constructor() {
    super();
    this.date = null;
    this.droppedOffAt = null;
    this.pickedUpAt = null;
    this.pickedUpBy = new PersonGuardian();
    this.absenceFrom = null;
    this.droppedOffModifiedAt = null;
    this.pickedUpModifiedAt = null;
    this.absenceTo = null;
    this.absenceNotes = null;
    this.absenceReportedAt = null;
    this.violatedSchemeDeadlines = [];
    this.absenceReportedBy = new Person();
    this.receivedBy = new Person();
    this.releasedBy = new Person();
    this.isAdaptation = false;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.date = assigned.date ? new Date(assigned.date) : null;
    assigned.droppedOffAt = assigned.droppedOffAt ? new Date(assigned.droppedOffAt) : null;
    assigned.pickedUpAt = assigned.pickedUpAt ? new Date(assigned.pickedUpAt) : null;
    assigned.absenceFrom = assigned.absenceFrom ? new Date(assigned.absenceFrom) : null;
    assigned.absenceTo = assigned.absenceTo ? new Date(assigned.absenceTo) : null;
    assigned.droppedOffModifiedAt = assigned.droppedOffModifiedAt ? new Date(assigned.droppedOffModifiedAt) : null;
    assigned.pickedUpModifiedAt = assigned.pickedUpModifiedAt ? new Date(assigned.pickedUpModifiedAt) : null;
    assigned.absenceReportedAt = assigned.absenceReportedAt ? new Date(assigned.absenceReportedAt) : null;
    assigned.absenceReportedBy = new Person().assign(assigned.absenceReportedBy);
    assigned.pickedUpBy = new PersonGuardian().assign(assigned.pickedUpBy);
    assigned.receivedBy = new Person().assign(assigned.receivedBy);
    assigned.releasedBy = new Person().assign(assigned.releasedBy);
  }
}

export class ChildAttendanceSummaryModel extends BaseModel {
  constructor() {
    super();
    this.schemeHasFreeHours = false;
    this.totalDays = 0;
    this.totalPaidDays = null;
    this.totalHours = 0;
    this.totalMinutes = 0;
    this.totalPaidHours = 0;
    this.totalPaidMinutes = 0;
  }
}

export class ChildAttendanceModel extends BaseModel {
  constructor() {
    super();
    this.childId = '';
    this.summary = new ChildAttendanceSummaryModel();
    this.entries = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.entries = assigned.entries.map((attendance) => new ChildAttendanceEntryModel().assign(attendance));
  }
}

//History

export class AttendancesHistoryModel extends BaseModel {
  constructor(groupId, months, days) {
    super();
    this.groupId = groupId;
    this.months = months || [];
    this.days = days || [];
  }
}
