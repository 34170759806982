import PropTypes from 'prop-types';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import _ from 'lodash';
import { Box } from '@mui/material';

class WaypointScroll extends React.Component {
  constructor(props) {
    super(props);
    this.scrollAtBottom = true;
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleRapidScroll = this.handleRapidScroll.bind(this);
    this.state = { scrollReady: !this.props.isReverse };
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.myRef = React.createRef();
    this.boxRef = React.createRef();
  }

  componentDidUpdate(prevState) {
    if (
      this.props.autoScroll &&
      this.boxRef &&
      this.boxRef.current &&
      this.boxRef.current.lastChild &&
      prevState.isInitial &&
      prevState.isLoading &&
      !this.props.isLoading
    ) {
      this.boxRef.current.lastChild.scrollIntoView();
    }
    if (this.state.scrollReady || this.props.isLoading) return;
    this.myRef.current.scrollTop = this.myRef.current.scrollHeight;
    if (this.myRef.current.scrollTop !== 0) {
      /* eslint react/no-did-update-set-state:0 */
      this.setState({ scrollReady: true });
    }
  }

  scrollToBottom() {
    const { scrollHeight } = this.myRef.current;
    const height = this.myRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.myRef.current.scrollTo({ top: maxScrollTop > 0 ? maxScrollTop : 0, behavior: 'smooth' });
  }

  shouldReset(children, nextChildren) {
    const key = children.length && _.last(children).key;
    const nextKey = nextChildren.length && _.last(nextChildren).key;
    return key !== nextKey;
  }

  handleLoadMore() {
    if (this.props.canLoadMore && this.state.scrollReady && !this.props.isLoading) {
      this.props.onLoadMore();
    }
  }

  handleRapidScroll(event) {
    if (event.previousPosition === undefined && event.currentPosition === 'inside') {
      if (this.props.isReverse) this.myRef.current.scrollTop = this.myRef.current.scrollTop + 50;
      if (!this.props.isReverse) this.myRef.current.scrollTop = this.myRef.current.scrollTop - 50;
    }
  }

  render() {
    const {
      loadOffset,
      children,
      isReverse,
      isLoading,
      loadIndicator,
      onFocus,
      isFocus = true,
      onBlur,
      onMouseUp,
      onMouseDown,
      style,
      sx,
      customscrollbar
    } = this.props;
    return (
      <Box
        sx={{
          scrollbarWidth: customscrollbar && 'thin',
          '&::-webkit-scrollbar': customscrollbar && {
            width: '6px',
            borderRadius: 2
          },
          '&::-webkit-scrollbar-track': customscrollbar && {
            background: (theme) => theme.palette.color.primary
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => customscrollbar && theme.palette.color.color9,
            width: customscrollbar && '8px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: (theme) => customscrollbar && theme.palette.color.color19
          },
          height: `calc(100vh - 250px)`,
          overflowX: 'hidden',
          ...style,
          ...sx
        }}
        onFocus={(e) => (onFocus ? onFocus(e) : null)}
        onBlur={(e) => (onBlur ? onBlur(e) : null)}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        ref={this.myRef}
        className={this.props.className}
        tabIndex={isFocus ? -1 : 0}>
        {!isReverse && <Box ref={this.boxRef}>{children}</Box>}
        {!isLoading && (
          <Waypoint
            onEnter={() => this.handleLoadMore()}
            onLeave={() => {}}
            topOffset={loadOffset}
            bottomOffset={loadOffset}
            onPositionChange={this.handleRapidScroll}
          />
        )}
        {isLoading && loadIndicator}
        {isReverse && <Box ref={this.boxRef}>{children}</Box>}
      </Box>
    );
  }
}

WaypointScroll.defaultProps = {
  loadOffset: '-200px',
  isReverse: false,
  autoScroll: false,
  customscrollbar: false
};

WaypointScroll.propTypes = {
  loadOffset: PropTypes.string,
  customscrollbar: PropTypes.bool,
  autoScroll: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  loadIndicator: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isInitial: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isReverse: PropTypes.bool,
  className: PropTypes.string,
  onUpdate: PropTypes.func,
  onFocus: PropTypes.func,
  isFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  style: PropTypes.object,
  sx: PropTypes.object
};

export default WaypointScroll;
