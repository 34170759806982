import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ValidationSummary = ({ messages, title, type }) => {
  const useStyles = makeStyles((theme) => ({
    validationSummaryMessage: {
      marginTop: '8px'
    },
    validationError: {
      borderLeftColor: theme.palette.color.error
    },
    validationWarning: {
      borderLeftColor: theme.palette.color.warning
    },
    validationInfo: {
      borderLeftColor: theme.palette.color.info
    }
  }));
  const classes = useStyles();
  if (!messages) {
    return null;
  }
  const messagesArray = Array.isArray(messages) ? messages : [messages];
  if (messagesArray.length === 0) {
    return null;
  }
  const getType = (types) => {
    if (types === 'warning') {
      return classes.validationWarning;
    }
    if (types === 'error') {
      return classes.validationError;
    }
    return classes.validationInfo;
  };

  return (
    <Box
      role="status"
      sx={{
        width: '100%',
        p: 2,
        my: 2,
        mx: 0,
        border: (theme) => '1px solid' + theme.palette.color.primary,
        borderLeftWidth: '5px',
        borderRadius: '3px',
        textAlign: 'left'
      }}
      className={getType(type)}>
      <Typography variant="h6" component="p" sx={{ color: (theme) => theme.palette.color.contrast }}>
        {title}
      </Typography>
      {messagesArray.map((message, index) => {
        return (
          <Box
            className={classes.validationSummaryMessage}
            key={`validation-summary-message-${index}`}
            sx={{ color: (theme) => theme.palette.color.contrast }}>
            {messagesArray.length > 1 ? `${index + 1}. ${message}` : message}
          </Box>
        );
      })}
    </Box>
  );
};

ValidationSummary.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default ValidationSummary;

export const validationTypes = Object.freeze({
  error: 'error',
  warning: 'warning',
  info: 'info'
});
