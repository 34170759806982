/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UnitModel } from '../models/configuration/unit/UnitModels';
import { initOneSignal } from '../utils/OneSignal';
import userRoles from '../constants/userRoles';

export default function unitReducer(state = initialState.configuration.unit, action) {
  switch (action.type) {
    case types.LOAD_UNIT_DATA_SUCCESS: {
      const { authModel } = action;
      if (
        authModel.userRole === userRoles.staffMemberTeacher ||
        authModel.userRole === userRoles.staffMemberAccountant ||
        authModel.userRole === userRoles.staffMemberPrincipal ||
        authModel.userRole === userRoles.staffMemberSecretary
      ) {
        initOneSignal(action.unit.oneSignalWebAppId, authModel.userId);
      }

      return new UnitModel().assign(action.unit);
    }
    case types.LOAD_UNIT_DATA_NOT_FOUND: {
      return null;
    }
    case types.UPDATE_UNIT_DEFAULT_PUBLISHER_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.defaultPublishingHouseId = action.defaultPublishingHouseId;
      return unitModel;
    }

    case types.REMOVE_UNIT_DEFAULT_PUBLISHER: {
      const unitModel = new UnitModel().assign(state);
      unitModel.defaultPublishingHouseId = '';
      return unitModel;
    }

    case types.UPDATE_UNIT_ADDRESS_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.address = action.address;
      return unitModel;
    }
    case types.UPDATE_UNIT_WORKING_HOURS_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.workingHours = action.workingHours;
      return unitModel;
    }
    case types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.legalGuardiansAbsenceRemovalDeadlineHours = action.legalGuardiansAbsenceRemovalDeadlineHours;
      return unitModel;
    }
    case types.UPDATE_UNIT_PENALTIES_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.penalties = action.penalties;
      return unitModel;
    }
    case types.ENABLE_TWO_WAY_MESSAGING_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.twoWayMessagingEnabled = true;
      return unitModel;
    }
    case types.ENABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.reportAbsenceInDaysOffEnabled = true;
      return unitModel;
    }
    case types.DISABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.reportAbsenceInDaysOffEnabled = false;
      return unitModel;
    }
    case types.DISABLE_TWO_WAY_MESSAGING_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.twoWayMessagingEnabled = false;
      return unitModel;
    }
    case types.ENABLE_QR_CODE_SERVICE_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.guardiansQRServiceSettings.enabled = true;
      unitModel.guardiansQRServiceSettings.dynamic = action.payload.dynamic;
      return unitModel;
    }
    case types.DISABLE_QR_CODE_SERVICE_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.guardiansQRServiceSettings.enabled = false;
      unitModel.guardiansQRServiceSettings.dynamic = false;
      return unitModel;
    }
    case types.ENABLE_NO_HOURS_ATTENDANCE_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.noHoursAttendancesEnabled = true;
      return unitModel;
    }
    case types.DISABLE_NO_HOURS_ATTENDANCE_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.noHoursAttendancesEnabled = false;
      return unitModel;
    }
    case types.ENABLE_POSTS_MODIFYING_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.postsModifyingEnabled = true;
      return unitModel;
    }
    case types.DISABLE_POSTS_MODIFYING_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.postsModifyingEnabled = false;
      return unitModel;
    }
    case types.ENABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.individualBankAccounts = true;
      return unitModel;
    }
    case types.DISABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.individualBankAccounts = false;
      return unitModel;
    }
    case types.ENABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.addContractNumberToSettlementNumber = true;
      return unitModel;
    }
    case types.DISABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.addContractNumberToSettlementNumber = false;
      return unitModel;
    }
    case types.ENABLE_CHILDREN_AGE_INFO: {
      const unitModel = new UnitModel().assign(state);
      unitModel.childrenAgeInfoEnabled = true;
      return unitModel;
    }
    case types.DISABLE_CHILDREN_AGE_INFO: {
      const unitModel = new UnitModel().assign(state);
      unitModel.childrenAgeInfoEnabled = false;
      return unitModel;
    }
    case types.ENABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED: {
      const unitModel = new UnitModel().assign(state);
      unitModel.editingChildDataByTeacherBlocked = true;
      return unitModel;
    }
    case types.DISABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED: {
      const unitModel = new UnitModel().assign(state);
      unitModel.editingChildDataByTeacherBlocked = false;
      return unitModel;
    }

    case types.ENABLE_MEALS_CATERING_DUES: {
      const unitModel = new UnitModel().assign(state);
      unitModel.mealsCateringDues = true;
      return unitModel;
    }
    case types.DISABLE_MEALS_CATERING_DUES: {
      const unitModel = new UnitModel().assign(state);
      unitModel.mealsCateringDues = false;
      return unitModel;
    }

    case types.ENABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED: {
      const unitModel = new UnitModel().assign(state);
      unitModel.sendingChildPickedUpNotificationBlocked = true;
      return unitModel;
    }
    case types.DISABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED: {
      const unitModel = new UnitModel().assign(state);
      unitModel.sendingChildPickedUpNotificationBlocked = false;
      return unitModel;
    }

    // case types.ENABLE_NOTIFICATION_SENDING_SUCCESS: {
    //   const unitModel = new UnitModel().assign(state);
    //   unitModel.notificationSendingEnabled = true;
    //   return unitModel;
    // }
    // case types.DISABLE_NOTIFICATION_SENDING_SUCCESS: {
    //   const unitModel = new UnitModel().assign(state);
    //   unitModel.notificationSendingEnabled = false;
    //   return unitModel;
    // }

    case types.ENABLE_TEACHER_GROUP_ACCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.teacherAllGroupVisibility = true;
      return unitModel;
    }
    case types.DISABLE_TEACHER_GROUP_ACCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.teacherAllGroupVisibility = false;
      return unitModel;
    }
    case types.ENABLE_ALLOW_DEBT_COLLECTION: {
      const unitModel = new UnitModel().assign(state);
      unitModel.allowDebtCollection = true;
      return unitModel;
    }
    case types.DISABLE_ALLOW_DEBT_COLLECTION: {
      const unitModel = new UnitModel().assign(state);
      unitModel.allowDebtCollection = false;
      return unitModel;
    }
    case types.ENABLE_ALLOW_ADAPTATION: {
      const unitModel = new UnitModel().assign(state);
      unitModel.allowAdaptation = true;
      return unitModel;
    }
    case types.DISABLE_ALLOW_ADAPTATION: {
      const unitModel = new UnitModel().assign(state);
      unitModel.allowAdaptation = false;
      return unitModel;
    }
    case types.ENABLE_TEACHER_POSTS_ACCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.teacherAllBoardPostsVisibility = true;
      return unitModel;
    }
    case types.DISABLE_TEACHER_POSTS_ACCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.teacherAllBoardPostsVisibility = false;
      return unitModel;
    }
    case types.ENABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.legalGuardianPartOfBoardPostsVisibility = true;
      return unitModel;
    }
    case types.DISABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.legalGuardianPartOfBoardPostsVisibility = false;
      return unitModel;
    }
    case types.ENABLE_SENDING_OVERDUES_NOTIFICATIONS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.sendingOverduesNotification = true;
      return unitModel;
    }
    case types.DISABLE_SENDING_OVERDUES_NOTIFICATIONS: {
      const unitModel = new UnitModel().assign(state);
      unitModel.sendingOverduesNotification = false;
      return unitModel;
    }
    default:
      return state;
  }
}
