import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Fade, Grid, useTheme, Box, Typography, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PersonIcon from '@mui/icons-material/Person';
import EmptyState from '../../../../../components/common/EmptyState';
import SharePersonListComponent from '../SharePersonListComponent/SharePersonListComponent';
import SaveAndCancel from '../../SaveAndCancel/SaveAndCancel';
import IconButtonPipe from '../../../../../components/common/IconButtonPipe';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PrivateFileBackdrop from '../../PrivateFileBackdrop/PrivateFileBackdrop';
import Expand from '../Expand/Expand';
import { useShareFileContext } from '../../../context/ShareFileContext';
import { useSelector } from 'react-redux';
import ReducedSharePersonListComponent from '../SharePersonListComponent/ReducedSharePersonListComponent';

const useStyles = makeStyles(() => ({
  wrapper: { display: 'flex', flexDirection: 'column', height: '100%', padding: 10 },
  contentWrapper: { p: 10, position: 'relative', height: '100%', overflowY: 'auto' }
}));

const SelectedUsersContent = ({ selectedList, onSingleAction, onMultiAction, onCloseDialog, onResetSelectedList }) => {
  const { isMobile, isDisabled, isPrivate, isSingleShare, isFolder } = useShareFileContext();
  const cl = useStyles({ isMobile });
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const theme = useTheme();
  const [forceOpenCollapse, setForceOpenCollapse] = useState(true);
  const isSelectedListEmpty = !selectedList.some(
    (item) => (item.users && item.users.length !== 0) || (item.teachers && item.teachers.length !== 0)
  );

  const handleResetSelectedList = (e) => {
    e.preventDefault();
    onResetSelectedList();
  };

  return (
    <Grid item xs={12} md={6} className={cl.wrapper}>
      <Box className={cl.contentWrapper}>
        {!isPrivate && (
          <>
            {isFolder && (
              <Alert severity="warning" sx={{ m: 2 }}>
                Wszystkie pliki oraz podfoldery znajdujące się w udostępnianym folderze zostaną również udostępnione.
              </Alert>
            )}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                opacity: isPrivate ? 0.1 : undefined,
                pb: 2
              }}>
              <PersonIcon sx={{ fontSize: 32, mr: 2, color: isDisabled ? theme.palette.color.disabled : undefined }} />
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}>
                Wybrane osoby, dla których{' '}
                {isFolder
                  ? 'folder zostanie udostępniony'
                  : isSingleShare
                  ? 'plik zostanie udostępniony'
                  : 'pliki zostaną udostępnione'}
              </Typography>
              <Expand
                onClick={() => {
                  setForceOpenCollapse((prev) => !prev);
                }}
                isForceOpenCollapse={forceOpenCollapse}
                isVisible={!isSelectedListEmpty}
              />
              <Fade in={!isSelectedListEmpty} timeout={500}>
                <Box>
                  <IconButtonPipe onClick={handleResetSelectedList} tooltip="Usuń wszystkie osoby z listy">
                    <RotateLeftIcon sx={{ color: isDisabled ? theme.palette.color.disabled : undefined }} />
                  </IconButtonPipe>
                </Box>
              </Fade>
            </Box>

            {isSelectedListEmpty && <EmptyState contrast message="Nie wybrano jeszcze żadnej osoby" />}
            {nadarzyn
              ? selectedList.map((item, index) => (
                  <Box sx={{ p: 2 }} key={index}>
                    <ReducedSharePersonListComponent
                      onSingleAction={onSingleAction}
                      onMultiAction={onMultiAction}
                      {...item}
                      isSelected
                      forceOpenCollapse={forceOpenCollapse}
                    />
                  </Box>
                ))
              : selectedList.map((item, index) => {
                  return item.users.length ? (
                    <Box sx={{ px: 2 }} key={index}>
                      <SharePersonListComponent
                        onSingleAction={onSingleAction}
                        onMultiAction={onMultiAction}
                        {...item}
                        isSelected
                        forceOpenCollapse={forceOpenCollapse}
                      />
                    </Box>
                  ) : null;
                })}
          </>
        )}
        {isPrivate && (
          <PrivateFileBackdrop
            isOpen={isPrivate}
            text={`${
              isSingleShare ? 'Wybrany plik stanie się prywatny' : 'Wszystkie wybrane pliki staną się prywatne'
            }. Zapisane udostępnienia dla roli oraz dla pojedynczych użytkowników zostaną usunięte.`}
          />
        )}
      </Box>
      {!isMobile && <SaveAndCancel onCancel={onCloseDialog} />}
    </Grid>
  );
};

SelectedUsersContent.propTypes = {
  onMultiAction: PropTypes.func.isRequired,
  onSingleAction: PropTypes.func.isRequired,
  selectedList: PropTypes.array.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onResetSelectedList: PropTypes.func.isRequired
};

export default SelectedUsersContent;
