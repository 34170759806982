import BaseModel from './BaseModel';
import moment from 'moment';

export class TimeModel extends BaseModel {
  constructor() {
    super();
    this.hourLocal = null;
    this.minuteLocal = null;
  }

  toDate() {
    return !this.isEmpty() ? moment(`${this.hourLocal}:${this.minuteLocal}`, 'HH:mm').toDate() : null;
  }

  toString() {
    return moment(`${this.hourLocal}:${this.minuteLocal}`, 'HH:mm').format('HH:mm');
  }

  isEmpty() {
    return this.hourLocal === null || this.minuteLocal === null;
  }

  isAfter(timeModel) {
    return moment(this.toDate()).isAfter(timeModel.toDate());
  }

  parse(timeString) {
    if (timeString === null) return new TimeModel();
    const localTime = moment(timeString, 'HH:mm');
    this.hourLocal = localTime.hour();
    this.minuteLocal = localTime.minute();
    return this;
  }

  fromMoment(dateToParse) {
    if (dateToParse === null) return new TimeModel();
    const date = moment(dateToParse);
    this.hourLocal = date.hour();
    this.minuteLocal = date.minute();
    return this;
  }

  toMoment() {
    return !this.isEmpty() ? moment(`${this.hourLocal}:${this.minuteLocal}`, 'HH:mm') : null;
  }
}
