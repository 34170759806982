import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../TextField';

const TextAttributeTemplate = ({ id, name, value, onChange, className }) => {
  const convertNull = (v) => (v === null ? undefined : v);
  return (
    <TextField
      hintText={name}
      floatingLabelText={name}
      floatingLabelFixed={false}
      name={id}
      value={convertNull(value)}
      onChange={onChange}
      fullWidth={true}
      className={className}
    />
  );
};

TextAttributeTemplate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default TextAttributeTemplate;
