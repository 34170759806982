import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as staffMembersActions from '../../actions/staffMembersActions';
import * as profileHistoryActions from '../../actions/profileHistoryActions';
import StaffMemberDetailsCard from './details/StaffMemberDetailsCard';
import StaffChargingSchemesCard from './chargingSchemes/StaffChargingSchemesCard';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import { StaffMemberModelValidator } from '../../models/staffMembers/StaffMemberModelsValidator';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { ChargingReportTypes } from '../../constants/chargingReportTypes';
import _ from 'lodash';
import { fromTemplate, routePaths } from '../../routePaths';
import { browserHistory } from 'react-router';
import StaffMemberProfileHistoryCard from './profileHisotry/StaffMemberProfileHistoryCard';
import userRoles from '../../constants/userRoles';
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button, Box } from '@mui/material';
import { resetUserPassword } from '../../actions/userAccountActions';
import IconButtonPipe from '../common/IconButtonPipe';
import * as notificationsActions from '../../actions/notificationActions';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import staffMemberRoles from '../../constants/staffMemberRoles';

const StaffMemberDetailsPage = (props) => {
  const user = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { clipboard } = navigator;

  const [member, setMember] = useState(Object.assign({}, props.member));
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState("Kliknij przycisk 'Generuj' aby wygenerować nowe hasło");

  useEffect(() => {
    setMember(props.member);
  }, [props.member]);

  const handleChange = (event) => {
    const field = event.target.name;
    const temp = Object.assign({}, member);
    _.set(temp, field, event.target.value);
    return setMember(temp);
  };

  const handleSave = () => {
    props.actions.updateStaffMemberAsync(member);
  };

  const handleCancel = () => {
    props.actions.updateStaffMemberCancel();
    setIsOpen(false);
  };

  const handleEdit = () => {
    props.actions.updateStaffMemberStart();
  };

  const handleArchive = () => {
    props.actions.archiveStaffMemberAsync(props.memberId);
    browserHistory.push(routePaths.staffMembers);
  };

  const handleSaveChargingScheme = (scheme) => {
    props.actions.updateChargingScheme(member.id, scheme);
  };

  const handleRemoveChargingScheme = (schemeId) => {
    props.actions.removeChargingScheme(member.id, schemeId);
  };

  const handlePasswordDialogCancel = () => {
    setIsOpen(false);
  };

  const handleChangePassword = async (id) => {
    setPassword(await dispatch(resetUserPassword(id)));
  };

  return (
    <>
      <LoadingRenderWrapper>
        <Box>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => browserHistory.replace(fromTemplate(routePaths.staffMembers))}>
            powrót do listy pracowników
          </Button>

          <StaffMemberDetailsCard
            member={member}
            onChange={handleChange}
            onSave={handleSave}
            onCancel={handleCancel}
            onEdit={handleEdit}
            onValidate={() => new StaffMemberModelValidator().validate(member)}
            isEditing={props.detailsUi.isEditing}
            isProcessing={props.detailsUi.isProcessing}
          />
          {!props.nadarzyn && (
            <StaffChargingSchemesCard
              staffMember={member}
              staffChargingSchemes={member.contract.schemes}
              allChargingSchemes={props.chargingSchemes}
              allReliefs={props.reliefs}
              onCreate={props.actions.updateChargingSchemeStart}
              onEdit={props.actions.updateChargingSchemeStart}
              onCancel={props.actions.updateChargingSchemeCancel}
              onSave={handleSaveChargingScheme}
              onRemove={handleRemoveChargingScheme}
              isEditing={props.chargingSchemesUi.isEditing}
              isProcessing={props.chargingSchemesUi.isProcessing}
            />
          )}
          {props.auth.isStaffMember([userRoles.staffMemberPrincipal]) && (
            <StaffMemberProfileHistoryCard
              isInitiallyExpanded={false}
              profileHistory={props.profileHistory}
              userId={props.memberId}
              onFilter={props.profileHistoryActions.loadProfileHistoryAsync}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
            <Button
              variant="contained"
              aria-label="generowanie hasła"
              onClick={() => setIsOpen(true)}
              sx={{ whiteSpace: 'nowrap' }}>
              Generowanie hasła
            </Button>

            {member.role === staffMemberRoles.principal.value ? (
              user.userRole === userRoles.staffMemberPrincipal ? (
                <ActionConfirmationButton
                  variant="outlined"
                  actionLabel="archiwizuj"
                  confirmationText="Czy na pewno chcesz zarchiwizować pracownika?"
                  confirmationTitle="Archiwizuj pracownika"
                  isSecondary={true}
                  confirmLabel="archiwizuj"
                  onConfirm={handleArchive}
                  onCancel={() => {}}
                />
              ) : null
            ) : (
              <ActionConfirmationButton
                variant="outlined"
                actionLabel="archiwizuj"
                confirmationText="Czy na pewno chcesz zarchiwizować pracownika?"
                confirmationTitle="Archiwizuj pracownika"
                isSecondary={true}
                confirmLabel="archiwizuj"
                onConfirm={handleArchive}
                onCancel={() => {}}
              />
            )}
          </Box>
        </Box>
      </LoadingRenderWrapper>
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
        key="password-dialog"
        id="password-dialog"
        maxWidth="xs"
        onClose={handlePasswordDialogCancel}
        aria-labelledby="password-dialog-title"
        open={isOpen}>
        <DialogTitle id="password-dialog-title">Resetowanie hasła</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightBold,
              color: (theme) => theme.palette.color.contrast
            }}>
            {password === "Kliknij przycisk 'Generuj' aby wygenerować nowe hasło"
              ? 'Generowanie nowego hasła'
              : 'Wygenerowane hasło:'}
          </DialogContentText>
          <DialogContentText sx={{ color: (theme) => theme.palette.color.contrast }}>
            {password}
            {password !== "Kliknij przycisk 'Generuj' aby wygenerować nowe hasło" ? (
              <IconButtonPipe
                tooltip="Skopiuj link albumu do schowka"
                onClick={async () => {
                  try {
                    await clipboard.writeText(password);
                    dispatch(notificationsActions.showSuccess('Skopiowano link albumu do schowka'));
                  } catch (error) {
                    dispatch(notificationsActions.showError('Nie udało się skopiować linku albumu do schowka'));
                  }
                }}>
                <FileCopyIcon />
              </IconButtonPipe>
            ) : null}
          </DialogContentText>
        </DialogContent>
        {password === "Kliknij przycisk 'Generuj' aby wygenerować nowe hasło" ? (
          <DialogActions>
            <Button variant="outlinedContrast" onClick={handlePasswordDialogCancel}>
              Anuluj
            </Button>
            <Button variant="contained" onClick={() => handleChangePassword(member.id)}>
              Generuj
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button variant="contained" onClick={handleCancel}>
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

StaffMemberDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  memberId: PropTypes.string.isRequired,
  detailsUi: PropTypes.object.isRequired,
  chargingSchemesUi: PropTypes.object.isRequired,
  profileHistory: PropTypes.object.isRequired,
  profileHistoryActions: PropTypes.object.isRequired,
  chargingSchemes: PropTypes.array.isRequired,
  reliefs: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  const memberId = ownProps.params.id;
  return {
    memberId,
    auth: state.auth,
    member: state.staffMemberDetails,
    detailsUi: state.staffMemberDetailsUi.details,
    reliefs: state.configuration.reliefs,
    chargingSchemesUi: state.staffMemberDetailsUi.chargingSchemes,
    chargingSchemes: state.configuration.chargingSchemes.filter(
      (scheme) => scheme.reportType === ChargingReportTypes.staffCatering.value
    ),
    profileHistory: state.profileHistory,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(staffMembersActions, dispatch),
    profileHistoryActions: bindActionCreators(profileHistoryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffMemberDetailsPage);
