import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, useMediaQuery, useTheme, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ApplicationActionsButtons = (props) => {
  const { application, edit, archive, approve, reject } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isActionDisabled = application.acceptedBy !== null || application.rejectedBy !== null;
  return (
    <>
      {edit && !isMobile && (
        <Tooltip title="Edytuj wniosek" disableHoverListener={isActionDisabled}>
          <span>
            <IconButton
              edge="end"
              aria-label="edytuj"
              onClick={() => edit(application)}
              disabled={isActionDisabled}
              size="large">
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {archive && (
        <Tooltip title="Zarchiwizuj wniosek" disableHoverListener={isActionDisabled}>
          <span>
            <IconButton
              edge="end"
              aria-label="zarchiwizuj"
              onClick={() => archive(application)}
              disabled={isActionDisabled}
              size="large">
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {approve && (
        <Tooltip title="Zaakceptuj wniosek" disableHoverListener={isActionDisabled}>
          <span>
            <Button
              variant="text"
              aria-label="zaakceptuj"
              edge="end"
              onClick={() => approve(application)}
              disabled={isActionDisabled}
              sx={{
                color: (theme) => theme.palette.color.color7,
                textTransform: 'lowercase',
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}>
              zaakceptuj
            </Button>
          </span>
        </Tooltip>
      )}
      {reject && (
        <Tooltip title="Odrzuć wniosek" disableHoverListener={isActionDisabled}>
          <span>
            <Button
              variant="text"
              aria-label="odrzuć"
              edge="end"
              onClick={() => reject(application)}
              disabled={isActionDisabled}
              sx={{
                color: (theme) => theme.palette.color.color25,
                textTransform: 'lowercase',
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}>
              odrzuć
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

ApplicationActionsButtons.propTypes = {
  application: PropTypes.object.isRequired,
  archive: PropTypes.func,
  edit: PropTypes.func,
  approve: PropTypes.func,
  reject: PropTypes.func
};

export default ApplicationActionsButtons;
