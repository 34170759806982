import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as diagnosticSchemesActions from '../../../actions/diagnosticSchemesActions';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Button, Paper } from '@mui/material';
import DiagnosticSchemesSetsList from './DiagnosticSchemesSetsList';
import DiagnosticSchemeSetDialog from './dialogs/DiagnosticSchemeSetDialog';
import { DiagnosticSchemaSetModel } from '../../../models/configuration/diagnosticSchemes/DiagnosticSchemeSetModel';

import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

class DiagnosticSchemesSetsPage extends React.Component {
  constructor() {
    super();
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderDiagnosticSchemeSetDialog = this.renderDiagnosticSchemeSetDialog.bind(this);
    this.state = {
      diagnosticSchemeSet: new DiagnosticSchemaSetModel()
    };
  }

  handleCreate() {
    const diagnosticSchemeSet = new DiagnosticSchemaSetModel();
    this.setState({ diagnosticSchemeSet }, () => this.props.actions.addDiagnosticSchemesSetStart());
  }

  handleUpdate(set) {
    this.setState({ diagnosticSchemeSet: set }, () => this.props.actions.updateDiagnosticSchemesSetStart());
  }

  handleCancel() {
    if (this.state.diagnosticSchemeSet.id) {
      this.props.actions.updateDiagnosticSchemesSetCancel();
    } else this.props.actions.addDiagnosticSchemesSetCancel();
  }

  handleSave(set) {
    if (!!set.id) this.props.actions.updateDiagnosticSchemesSetAsync(set);
    else this.props.actions.addDiagnosticSchemesSetAsync(set);
  }

  renderDiagnosticSchemeSetDialog() {
    if (this.props.ui.isEditing) {
      return (
        <DiagnosticSchemeSetDialog
          diagnosticSchemeSet={this.state.diagnosticSchemeSet}
          onCancel={this.handleCancel}
          onSave={this.handleSave}
          isProcessing={this.props.ui.isProcessing}
          isDialogOpen={this.props.ui.isEditing}
          isEditing={!!this.state.diagnosticSchemeSet.title}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Szablony diagnoz" titleIcon={<ReceiptIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" aria-label="Dodaj szablon" onClick={this.handleCreate} sx={{ mb: 2 }}>
                Dodaj szablon
              </Button>
            </Box>
            <Paper sx={{ px: 2, py: 1 }}>
              <DiagnosticSchemesSetsList
                sets={this.props.diagnosticSchemesSets}
                onRemove={(set) => this.props.actions.removeDiagnosticSchemesSetAsync(set)}
                onSelected={(set) => this.handleUpdate(set)}
              />
            </Paper>
            {this.renderDiagnosticSchemeSetDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

DiagnosticSchemesSetsPage.propTypes = {
  diagnosticSchemesSets: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    diagnosticSchemesSets: state.configuration.diagnosticSchemesSets,
    ui: state.configuration.diagnosticSchemesUi.details
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(diagnosticSchemesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticSchemesSetsPage);
