import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';

const FormikRadioSelect = ({ name, children, sx }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const radioConfig = {
    ...field,
    onChange: handleChange
  };

  return (
    <RadioGroup sx={{ ...sx }} aria-label="gender" {...radioConfig}>
      {children}
    </RadioGroup>
  );
};

FormikRadioSelect.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  sx: PropTypes.object
};

export default FormikRadioSelect;
