import { Box, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';

const FilePickerWithRef = React.forwardRef((props, ref) => {
  const { onDrop, multiple = false, labelId, sx } = props;
  const handleDrop = useCallback((files) => {
    if (multiple) onDrop(files);
    else onDrop(files[0]);
  });

  return (
    <Dropzone
      sx={{ ...sx }}
      inputProps={{ 'aria-label': 'Strefa umieszczania plików' }}
      ref={ref}
      onDrop={handleDrop}
      multiple={multiple}
      noClick
      noKeyboard>
      {({ getRootProps, getInputProps }) => {
        return (
          <Box {...getRootProps({ className: 'dropzone' })} sx={{ visibility: 'collapse' }}>
            <FormControl>
              <input {...getInputProps()} aria-label="upload" id={labelId || 'file-upload'} />
            </FormControl>
          </Box>
        );
      }}
    </Dropzone>
  );
});

FilePickerWithRef.propTypes = {
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  labelId: PropTypes.string,
  sx: PropTypes.object
};

export default FilePickerWithRef;
