import PropTypes from 'prop-types';
import React from 'react';
import { ChargingSchemeTypes } from '../../../../constants/chargingSchemeTypes';
import { ChargingPrecisions } from '../../../../constants/chargingPrecisions';
import { ChargingCalculationMethod } from '../../../../constants/chargingCalculationMethods';
import { getConstantByValue } from '../../../../utils/getConstantByValue';
import { Box, Typography } from '@mui/material';

const HourlySchemeDescription = ({ scheme }) => {
  return (
    <Box
      sx={{
        color: (theme) => theme.palette.color.contrast
      }}>
      <Typography sx={{ fontSize: 14 }}>
        Stawka:
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
          {scheme.rate} {ChargingSchemeTypes[scheme.schemeType].unit}
        </Typography>
        naliczana co
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
          {getConstantByValue(ChargingPrecisions, scheme.chargingPrecision).name}
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 14 }}>
        Opłata naliczana:
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
          {getConstantByValue(ChargingCalculationMethod, scheme.calculationMethod).name}
        </Typography>
      </Typography>
      {!scheme.nonChargeableFrom.isEmpty() && !scheme.nonChargeableTo.isEmpty() ? (
        <Typography sx={{ fontSize: 14 }}>
          Darmowe godziny:
          <Typography
            component="span"
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
            {scheme.nonChargeableFrom.toString()} - {scheme.nonChargeableTo.toString()}
          </Typography>
        </Typography>
      ) : null}
    </Box>
  );
};

HourlySchemeDescription.propTypes = {
  scheme: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default HourlySchemeDescription;
