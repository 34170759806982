import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, Typography } from '@mui/material';
import GroupPickerTemplate from './common/GroupPickerTemplate';
import { ReportTypes } from '../../../constants/reportTypes';
import SingleDatePickerTemplate from './common/SingleDatePickerTemplate';

const ChildrenPlannedAttendancesReport = ({ groupId, date, groups, onChange, allergiesFlag, errors }) => {
  return (
    <Box>
      <GroupPickerTemplate
        errorText={errors.groupId}
        groupId={groupId}
        groups={groups}
        onChange={onChange}
        legend={ReportTypes.childrenPlannedAttendances.name}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.childrenPlannedAttendances.name}`}>
        <Typography variant="h6" sx={{ pt: 2, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          Wybierz datę
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          <SingleDatePickerTemplate date={date} onChange={onChange} name="date" errors={errors} />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Checkbox
            label="Uwzględnij w raporcie uczulenia dzieci"
            onCheck={(event, isChecked) => onChange({ target: { name: 'allergiesFlag', value: isChecked } })}
          />
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

ChildrenPlannedAttendancesReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  allergiesFlag: PropTypes.bool,
  errors: PropTypes.object
};

export default ChildrenPlannedAttendancesReport;
