import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import AvailableRolesContent from '../components/ShareToRole/RolesContent/AvailableRolesContent';
import { ShareFileFormFields, SHARE_DETAILS_OPTIONS } from './ShareFileFormContainer';
import SelectedRolesContent from '../components/ShareToRole/RolesContent/SelectedRolesContent';
import { userRolesOptions as normalUserRolesOption } from '../helpers/userRolesOptions';
import { useShareFileContext } from '../context/ShareFileContext';
import { reducedUserRolesOptions } from '../helpers/reducedUserRolesOptions';
import { useSelector } from 'react-redux';

const ShareFileToRoleContainer = ({ onCloseDialog, selectedDetailsSharingOption }) => {
  const { isMobile } = useShareFileContext();
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const userRolesOptions = nadarzyn ? reducedUserRolesOptions : normalUserRolesOption;
  const { setFieldValue } = useFormikContext();
  const [field] = useField(ShareFileFormFields.shareForUserRoles);
  const shareForUserRoles = field.value;

  const handleSingleAction = (role, isRemove) => {
    if (isRemove) {
      setFieldValue(
        ShareFileFormFields.shareForUserRoles,
        shareForUserRoles.filter((element) => element !== role)
      );
    } else {
      setFieldValue(ShareFileFormFields.shareForUserRoles, [...shareForUserRoles, role]);
    }
  };

  const handleResetSelectedRoles = () => {
    setFieldValue(ShareFileFormFields.shareForUserRoles, []);
  };

  const handleShareAllRoles = () => {
    setFieldValue(
      ShareFileFormFields.shareForUserRoles,
      userRolesOptions.map((item) => item.role)
    );
  };

  const availableRoles = userRolesOptions.filter((item) => !shareForUserRoles.includes(item.role));
  const selectedRoles = userRolesOptions.filter((item) => shareForUserRoles.includes(item.role));

  return (
    <>
      <Grid container style={{ flex: 1, minHeight: 0 }}>
        {((isMobile && selectedDetailsSharingOption === SHARE_DETAILS_OPTIONS.availableScreen) || !isMobile) && (
          <AvailableRolesContent
            availableList={availableRoles}
            onSingleAction={handleSingleAction}
            onShareAllRoles={handleShareAllRoles}
          />
        )}
        {((isMobile && selectedDetailsSharingOption === SHARE_DETAILS_OPTIONS.selectedScreen) || !isMobile) && (
          <SelectedRolesContent
            selectedList={selectedRoles}
            onSingleAction={handleSingleAction}
            onCloseDialog={onCloseDialog}
            onResetSelectedRoles={handleResetSelectedRoles}
          />
        )}
      </Grid>
    </>
  );
};

ShareFileToRoleContainer.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  selectedDetailsSharingOption: PropTypes.string.isRequired
};

export default ShareFileToRoleContainer;
