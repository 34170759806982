import initialState from './initial';
import * as types from '../actions/actionTypes';
import {
  WorkPlanModel,
  WorkPlanContentModel,
  TopicModel,
  GoalModel
} from '../models/digitalDiary/workPlan/WorkPlanModel';

export default function digitalDiaryWorkPlanReducer(state = initialState.digitalDiary.workPlan, action) {
  const { type, set } = action;
  switch (type) {
    case types.LOAD_DIGITAL_DIARY_WORKPLAN_SETS_SUCCESS: {
      return new WorkPlanModel().assign(action.data[0]);
    }
    case types.CREATE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS: {
      const content = new WorkPlanContentModel().assign({ ...state.content, goals: [...state.content.goals, set] });
      return new WorkPlanModel().assign({ ...state, content });
    }
    case types.CREATE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS: {
      const content = new WorkPlanContentModel().assign({ ...state.content, topics: [...state.content.topics, set] });
      return new WorkPlanModel().assign({ ...state, content });
    }
    case types.UPDATE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS: {
      const updatedGoals = state.content.goals.map((goal, index) => {
        return set.id === index ? new GoalModel().assign(set.newGoal) : goal;
      });
      const content = new WorkPlanContentModel().assign({ ...state.content, goals: updatedGoals });
      return new WorkPlanModel().assign({ ...state, content });
    }
    case types.UPDATE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS: {
      const updatedTopics = state.content.topics.map((topic, index) => {
        return set.id === index ? new TopicModel().assign(set.newTopic) : topic;
      });
      const content = new WorkPlanContentModel().assign({ ...state.content, topics: updatedTopics });
      return new WorkPlanModel().assign({ ...state, content });
    }
    case types.REMOVE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS: {
      const filteredGoals = state.content.goals.filter((goal, index) => set !== index);
      const content = new WorkPlanContentModel().assign({ ...state.content, goals: filteredGoals });
      return new WorkPlanModel().assign({ ...state, content });
    }
    case types.REMOVE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS: {
      const filteredTopics = state.content.topics.filter((topic, index) => set !== index);
      const content = new WorkPlanContentModel().assign({ ...state.content, topics: filteredTopics });
      return new WorkPlanModel().assign({ ...state, content });
    }
    case types.SAVEDATA_DIGITAL_DIARY_WORKPLAN_SUCCESS: {
      return new WorkPlanModel().assign(set);
    }
    default:
      return state;
  }
}
