/*eslint indent:0*/
import initialState from "./initial";
import * as types from "../actions/actionTypes";
import { PrivateThreadModel } from "../models/privateMessages/PrivateMessagesModels";

export default function privateThreadsReducer(state = initialState.privateThreads, action) {
  switch (action.type) {
    case types.LOAD_PRIVATE_THREADS:
    case types.LOAD_MY_PRIVATE_THREADS: {
      return Object.assign({}, state, {
        activeView: action.view,
        threads: [],
        isLoading: true
      });
    }
    case types.LOAD_PRIVATE_THREADS_SUCCESS:
    case types.LOAD_MY_PRIVATE_THREADS_SUCCESS: {
      const threads = action.threads.data.map((thread) => {
        const threadModel = new PrivateThreadModel().assign(thread);
        threadModel.readByMe = !state.unreadThreadsIds.includes(threadModel.threadId);
        return threadModel;
      });
      return Object.assign({}, state, {
        threads,
        canLoadMore: action.threads.data.length < action.threads.totalCount,
        isLoading: false
      });
    }
    case types.LOAD_NEXT_PRIVATE_THREADS: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case types.LOAD_NEXT_PRIVATE_THREADS_SUCCESS: {
      const threads = [
        ...state.threads,
        ...action.threads.data.map((thread) => new PrivateThreadModel().assign(thread))
      ];
      return Object.assign({}, state, {
        threads,
        canLoadMore: threads.length < action.threads.totalCount,
        isLoading: false
      });
    }
    case types.LOAD_UNREAD_THREADS_SUCCESS: {
      return Object.assign({}, state, { unreadThreadsIds: action.ids });
    }
    case types.MARK_MESSAGE_READ_SUCCESS: {
      const { messageTimestamp, threadId, currentUserId } = action;
      const unreadThreadsIds = state.unreadThreadsIds.filter((x) => x !== action.threadId);
      const thread = state.threads.find((x) => x.threadId === threadId);
      const lastRead = Object.assign({}, thread.lastRead, { [currentUserId]: messageTimestamp });
      const updatedThread = new PrivateThreadModel().assign(Object.assign({}, thread, { lastRead, readByMe: true }));

      const threads = state.threads.map((t) => (t.threadId === threadId ? updatedThread : t));
      return Object.assign({}, state, { threads, unreadThreadsIds });
    }
    case types.MESSAGE_READ_NOTIFICATION: {
      const { messageTimestamp, threadId, senderId } = action.message;
      const thread = state.threads.find((x) => x.threadId === threadId);
      const lastRead = Object.assign({}, thread.lastRead, {
        [senderId]: messageTimestamp
      });
      const updatedThread = new PrivateThreadModel().assign(Object.assign({}, thread, { lastRead }));
      updatedThread.readByParticipants = messageTimestamp >= updatedThread.lastMessage.sentAt;
      const threads = state.threads.map((t) => (t.threadId === threadId ? updatedThread : t));

      return Object.assign({}, state, { threads });
    }
    case types.UPDATE_PRIVATE_MESSAGE_SUCCESS:
    case types.DELETE_PRIVATE_MESSAGE_SUCCESS:
    case types.SEND_PRIVATE_MESSAGE_SUCCESS: {
      const newThread = Object.assign({}, action.thread, {
        lastMessage: action.message,
        readByParticipants: false,
        readByMe: true
      });
      const threads = [
        new PrivateThreadModel().assign(newThread),
        ...state.threads.filter((t) => t.threadId !== newThread.threadId)
      ];
      return Object.assign({}, state, { threads }, { isLoading: false });
    }
    case types.SEND_PRIVATE_MULTI_MESSAGE_SUCCESS: {
      const newThreads = action.threads.map((thread) =>
        Object.assign({}, thread, {
          lastMessage: action.message,
          readByParticipants: false,
          readByMe: true
        })
      );
      const threads = [
        ...newThreads.map((newThread) => new PrivateThreadModel().assign(newThread)),
        ...state.threads.filter((t) => !newThreads.some((thread) => thread.threadId === t.threadId))
      ];
      return Object.assign({}, state, { threads });
    }
    case types.NEW_THREAD_NOTIFICATION: {
      const { thread } = action.message;
      const newThread = new PrivateThreadModel().assign(thread);
      const unreadThreadsIds = [...state.unreadThreadsIds, thread.threadId];
      newThread.threadId = thread.threadId;
      newThread.participants = thread.participants;
      newThread.lastMessage = thread.lastMessage;
      newThread.readByMe = false;
      const threads = [newThread, ...state.threads.filter((t) => t.threadId !== action.message.threadId)];
      return Object.assign({}, state, { threads, unreadThreadsIds });
    }
    case types.PRIVATE_MESSAGE_SENT_NOTIFICATION:
    case types.PRIVATE_MESSAGE_UPDATED_NOTIFICATION: {
      const currentThread = state.threads.find((t) => t.threadId === action.message.threadId);
      if (!currentThread) return state;
      const nextThread = new PrivateThreadModel().assign(currentThread).fromMessage(action.message);
      nextThread.readByParticipants = true;
      nextThread.readByMe = false;

      const threads = [nextThread, ...state.threads.filter((t) => t.threadId !== action.message.threadId)];
      const unreadThreadsIds = state.unreadThreadsIds.find((x) => x === action.message.threadId)
        ? state.unreadThreadsIds
        : [...state.unreadThreadsIds, action.message.threadId];
      return Object.assign({}, state, { threads, unreadThreadsIds });
    }
    case types.PM_TYPING_NOTIFICATION_RECEIVED: {
      const { threadId, senderId } = action.message;
      const thread = state.threads.find((t) => t.threadId === threadId);
      if (!thread) return state;
      const typingParticipant = thread.participants.find((p) => p.id === senderId);
      if (!typingParticipant) return state;
      const nextThread = Object.assign({}, thread, { typingParticipant });
      return Object.assign({}, state, {
        threads: state.threads.map((t) => (t.threadId === threadId ? new PrivateThreadModel().assign(nextThread) : t))
      }); // eslint-disable-line no-confusing-arrow
    }
    case types.PM_NOT_TYPING_NOTIFICATION_RECEIVED: {
      const { threadId } = action.message;
      const thread = state.threads.find((t) => t.threadId === threadId);
      if (!thread) return state;
      const nextThread = Object.assign({}, thread, { typingParticipant: null });
      return Object.assign({}, state, {
        threads: state.threads.map((t) => (t.threadId === threadId ? new PrivateThreadModel().assign(nextThread) : t))
      }); // eslint-disable-line no-confusing-arrow
    }
    default:
      return state;
  }
}
