import BaseModel from '../BaseModel';

export class AlbumModel extends BaseModel {
  constructor(id = null) {
    super();
    this.id = id;
    this.name = '';
    this.description = '';
    this.isPublic = false;
    this.limitedToGroups = [];
    this.limitedToActivities = [];
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.files = [];
  }
}
