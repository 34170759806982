import ApiBase, { ROOT_URL } from './ApiBase';
// import moment from 'moment';

export default class StaffMessageBoardApi extends ApiBase {
  static getStaffPosts(pagination) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/internal`).query(pagination).type('application/json')
    );
  }

  static getFilteredStaffPosts(filters) {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/boardPosts/byFilter`)
        .query({ ...filters, internal: 'true' })
        .type('application/json')
    );
  }

  static createStaffPost(data) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/boardPosts`).send(data).type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateStaffPost(data) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/boardPosts/${data.id}`).send(data).type('application/json')
    );
  }

  static deleteStaffPost(data) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/boardPosts/${data.id}/archive`).type('application/json'));
  }

  static getStaffMembersList(postId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/getUnreadStaffMember/${postId}`).type('application/json')
    );
  }

  static readAll() {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/boardPosts/staffMembers/readAll`));
  }

  static loadUnreadedPosts() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/boardPosts/getUnreadStaffMember`).type('application/json')
    );
  }
}
