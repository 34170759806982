import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShareFileToRoleContainer from './ShareFileToRoleContainer';
import ShareFileToUsersContainer from './ShareFileToUsersContainer';
import BaseModel from '../../../models/BaseModel';
import * as Yup from 'yup';
import ShareFileHeader from '../components/Header/ShareFileHeader';
import { useField, useFormikContext } from 'formik';
import ShareFileHeaderFilesList from '../components/Header/ShareFileHeaderFiles';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ShareFileProvider } from '../context/ShareFileContext';
import { useSelector } from 'react-redux';
import ReducedShareFileToUsersContainer from './ReducedShareFileToUsersContainer';

export const SHARE_OPTIONS = {
  roleShare: 0,
  userShare: 1,
  filesScreen: 2
};

export const SHARE_DETAILS_OPTIONS = {
  availableScreen: 'availableScreen',
  selectedScreen: 'selectedScreen'
};

export const ShareFileFormFields = {
  shareForUserRoles: 'shareForUserRoles',
  shareForUserIds: 'shareForUserIds',
  isPrivate: 'isPrivate'
};

const ShareFileFormContainer = ({
  selectedItems,
  onCloseDialog,
  missingPermissionsItemsArray,
  isFolder,
  isSingleShare
}) => {
  const [selectedSharingOption, setSelectedSharingOption] = useState(SHARE_OPTIONS.roleShare);
  const [shareToUsersTouched, setShareToUsersTouched] = useState(false);
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const [selectedDetailsSharingOption, setSelectedDetailsSharingOption] = useState(
    SHARE_DETAILS_OPTIONS.availableScreen
  );
  const { setFieldValue, values } = useFormikContext();
  const { shareForUserIds } = values;
  const [field] = useField(ShareFileFormFields.isPrivate);
  const isForbidden = missingPermissionsItemsArray.length > 0;
  const isPrivate = field.value;
  const isDisabled = isPrivate || isForbidden;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handlePrivate = () => {
    setSelectedDetailsSharingOption(SHARE_DETAILS_OPTIONS.selectedScreen);
    if (selectedSharingOption === SHARE_OPTIONS.filesScreen) setSelectedSharingOption(SHARE_OPTIONS.roleShare);
    setFieldValue(ShareFileFormFields.isPrivate, !isPrivate);
  };

  useEffect(() => {
    if (selectedSharingOption === SHARE_OPTIONS.filesScreen && !isMobile) {
      setSelectedSharingOption(SHARE_OPTIONS.roleShare);
    }
  }, [isMobile]);

  useEffect(() => {
    if (shareForUserIds.length > 0) {
      setShareToUsersTouched(true);
    }
  }, [shareToUsersTouched]);

  return (
    <ShareFileProvider
      isForbidden={isForbidden}
      isPrivate={isPrivate}
      isDisabled={isDisabled}
      isSingleShare={isSingleShare}
      isFolder={isFolder}>
      <ShareFileHeader
        selectedItems={selectedItems}
        onCloseDialog={onCloseDialog}
        selectedSharingOption={selectedSharingOption}
        onChangeSharingOption={setSelectedSharingOption}
        selectedDetailsSharingOption={selectedDetailsSharingOption}
        setSelectedDetailsSharingOption={setSelectedDetailsSharingOption}
        onPrivate={handlePrivate}
        shareToUsersTouched={shareToUsersTouched}
        setShareToUsersTouched={setShareToUsersTouched}
        missingPermissionsItemsArray={missingPermissionsItemsArray}
      />
      {selectedSharingOption === SHARE_OPTIONS.roleShare && (
        <ShareFileToRoleContainer
          onCloseDialog={onCloseDialog}
          selectedDetailsSharingOption={selectedDetailsSharingOption}
        />
      )}
      {selectedSharingOption === SHARE_OPTIONS.userShare &&
        (nadarzyn ? (
          <ReducedShareFileToUsersContainer
            onCloseDialog={onCloseDialog}
            selectedDetailsSharingOption={selectedDetailsSharingOption}
          />
        ) : (
          <ShareFileToUsersContainer
            onCloseDialog={onCloseDialog}
            selectedDetailsSharingOption={selectedDetailsSharingOption}
          />
        ))}
      {selectedSharingOption === SHARE_OPTIONS.filesScreen && (
        <Grid
          container
          sx={{
            flex: 1,
            overflowY: 'auto',
            boxSizing: 'border-box',
            padding: 5,
            position: 'relative',
            maxWidth: '100%'
          }}>
          <ShareFileHeaderFilesList
            selectedItems={selectedItems}
            missingPermissionsItemsArray={missingPermissionsItemsArray}
          />
        </Grid>
      )}
    </ShareFileProvider>
  );
};

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.shareForUserRoles = [];
    this.shareForUserIds = new Set();
    this.isPrivate = false;
    this.isForbidden = false;
  }
}

export const VALIDATION_SCHEMA = Yup.object().shape({});

export const validate = ({ isPrivate, shareForUserRoles, shareForUserIds, isForbidden, isSingleShare }) => {
  const errors = {};
  if (isForbidden)
    errors.isForbidden = 'Nie posiadasz uprawnień do zarządzania udostępnianiem jednego z wybranych plików';
  if (!isPrivate) {
    if (shareForUserRoles.length === 0 && [...shareForUserIds].length === 0) {
      errors.atLeastOneItem = `Musisz wybrać co najmniej jedną rolę lub osobę aby udostępnić ${
        isSingleShare ? 'plik' : 'plik'
      } lub wyłączyć udostępnianie ${isSingleShare ? 'plików' : 'pliku'} za pomocą przycisku "wyłącz udostępnianie ${
        isSingleShare ? 'plików' : 'pliku'
      } " `;
    }
  }
  return errors;
};

ShareFileFormContainer.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  isSingleShare: PropTypes.bool,
  isFolder: PropTypes.bool,
  missingPermissionsItemsArray: PropTypes.array
};

export default ShareFileFormContainer;
