import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const AuthorizationChipElement = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        borderRadius: '8px',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        fontWeight: (theme) => theme.typography.fontWeightMedium,
        letterSpacing: '0.6px',
        lineHeight: 1,
        textTransform: 'uppercase',
        py: 0.5,
        px: 2,
        m: 0.5,
        pt: 1,
        ...sx
      }}>
      {children}
    </Typography>
  );
};

export default AuthorizationChipElement;

AuthorizationChipElement.propTypes = {
  sx: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};
