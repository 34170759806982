import React from 'react';
import PropTypes from 'prop-types';
import FormikSwitch from '../../../formik/FormikToggle';
import { FieldArray } from 'formik';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Box, Typography, ListItem } from '@mui/material';

const ChildDetailsFormOpinions = (props) => {
  const { values } = props;

  return (
    <FieldsetWrapper
      legend={
        <Typography
          component="span"
          sx={{
            color: (theme) => theme.palette.color.contrast,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            pl: 2
          }}>
          Zaświadczenia
        </Typography>
      }
      invisible={false}>
      <FieldArray
        name="opinions"
        render={() => (
          <Box sx={{ pb: 3 }}>
            {values.opinions.map((opinion, index) => {
              return (
                <ListItem key={opinion.key}>
                  <FormikSwitch name={`opinions.${index}.value`} label={opinion.key} />
                </ListItem>
              );
            })}
          </Box>
        )}
      />
    </FieldsetWrapper>
  );
};

ChildDetailsFormOpinions.propTypes = {
  values: PropTypes.object
};

export default ChildDetailsFormOpinions;
