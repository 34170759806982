import { isNumeric, getValue } from './numberValidators';

export function tryConvertToNumber(value) {
  return isNumeric(value) ? Number.parseFloat(getValue(value)) : '';
}

export function tryConvertToInteger(value) {
  return isNumeric(value) ? Number.parseInt(getValue(value), 10) : '';
}

export function tryConvertToFloat(value) {
  return isNaN(Number.parseFloat(value)) ? undefined : Number.parseFloat(value);
}
