import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormControl, Typography } from '@mui/material';

const FieldsetWrapper = ({ children, legend, invisible = true, style, labelStyle, className, sx }) => {
  const invisibleStyle = invisible ? { position: 'absolute', clip: 'rect(0 0 0 0)' } : {};
  return (
    <FormControl component="fieldset" sx={{ width: '100%', ...sx, ...style }} className={className}>
      <FormLabel component="legend" sx={{ ...sx, ...invisibleStyle, ...labelStyle }}>
        <Typography>{legend}</Typography>
      </FormLabel>
      {children}
    </FormControl>
  );
};
FieldsetWrapper.propTypes = {
  sx: PropTypes.any,
  children: PropTypes.node.isRequired,
  legend: PropTypes.any.isRequired,
  invisible: PropTypes.bool,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  className: PropTypes.any
};

export default FieldsetWrapper;
