import { Box, Button, Grid, MenuItem } from '@mui/material';
import React from 'react';
import DatePicker from '../common/DatePicker';
import SelectField from '../common/SelectField';

const PsychologicalHeader = ({ rowsPerPage, setRowsPerPage, numberArrayOfOptions, setDialogOpen }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <SelectField
        sx={{
          width: 88
        }}
        id="rowsPerPage"
        value={rowsPerPage}
        onChange={(e) => setRowsPerPage(e.target.value)}
        floatingLabelText="Wierszy na stronę">
        {numberArrayOfOptions.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </SelectField>
      <Button variant="contained" onClick={() => setDialogOpen(true)} sx={{ whiteSpace: 'nowrap' }}>
        Dodaj dziecko
      </Button>
    </Box>
  );
};

export default PsychologicalHeader;
