/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function settlementsUiReducer(state = initialState.settlementsUi, action) {
  switch (action.type) {
    case types.UPDATE_SETTLEMENT: {
      return UiModel.start();
    }
    case types.UPDATE_SETTLEMENT_CANCEL: {
      return UiModel.cancel();
    }
    case types.UPDATE_SETTLEMENT_SUBMIT: {
      return UiModel.submit();
    }
    case types.UPDATE_SETTLEMENT_SUCCESS: {
      return UiModel.success();
    }
    case types.UPDATE_SETTLEMENT_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.settlementsUi);
    }
    default:
      return state;
  }
}
