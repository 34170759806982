import * as types from './actionTypes';
// import messageBoardApi from '../api/MessageBoardApi';
// import * as notificationActions from './notificationActions';
// import {BoardPostCommandModel} from '../models/messageBoard/MessageBoardModels';
// import * as get from './getStateHelpers';
// import * as logger from '../utils/logger';
// import userRoles from '../constants/userRoles';

export function filterPostStart() {
  return { type: types.FILTER_MESSAGE_BOARD_START };
}

export function filterPostCancel() {
  return { type: types.FILTER_MESSAGE_BOARD_CANCEL };
}
