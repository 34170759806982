import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

const Filter = (props) => {
  const { itemsToFilter, sx, onFilter } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const filters = { all: '', group: 'group', activity: 'activity' };

  const handleChangeActive = (value) => {
    setFilter(value);
    if (value === filters.all) {
      onFilter(itemsToFilter);
    }
    if (value === filters.group) {
      onFilter(itemsToFilter.filter((item) => item.type === filters.group));
    }
    if (value === filters.activity) {
      onFilter(itemsToFilter.filter((item) => item.type === filters.activity));
    }
  };

  return (
    <>
      <Button sx={sx} variant="outlined" onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
        filtruj
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <FormLabel component="legend">
              <Typography sx={{ color: (theme) => theme.palette.color.contrast }}>Opcje wyświetlania:</Typography>
            </FormLabel>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row' }}>
              <RadioGroup
                aria-label="Sortowanie plików"
                name="sort"
                value={filter}
                sx={{ p: 1 }}
                onChange={(e) => handleChangeActive(e.target.value)}>
                <FormControlLabel value="" control={<Radio />} label="Wszyscy" />
                <FormControlLabel value="group" control={<Radio />} label="Przenosiny pomiędzy grupami" />
                <FormControlLabel
                  value="activity"
                  control={<Radio />}
                  label="Przenosiny pomiędzy zajęciami dodatkowymi"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

Filter.propTypes = {
  itemsToFilter: PropTypes.array.isRequired
};

export default Filter;
