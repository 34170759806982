import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settlementsActions from '../../actions/settlementsActions';
import * as allReportsActions from '../../actions/reportsActions';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import UpdateIcon from '@mui/icons-material/Update';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SettlementsList from './SettlementsList';
import SettlementsStatistics from './SettlementsStatistics';
import CalculateChildrenCorrectiveDuesDialog from './CalculateChildrenCorrectiveDuesDialog';
import CalculateChildrenSettlementsDialog from './CalculateChildrenSettlementsDialog';
import SettlementsFilters from './filters/SettlementsFilters';
import SettlementDetailsDialog from './details/SettlementDetailsDialog';
import RegisterPaymentsDialog from './payments/RegisterPaymentsDialog';
import RegisterRebateDialog from './payments/RegisterRebateDialog';
import ApproveDuesDialog from './details/ApproveDuesDialog';
import FilterBar from '../common/FilterBar';
import _ from 'lodash';
import numberFormatter from '../../utils/numberFormatter';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../routePaths';
import Alert from '@mui/material/Alert';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import EmptyState from '../common/EmptyState';

class ChildrenSettlementsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentSettlement: props.settlements.find((s) => s.id === props.settlementId),
      filteredItems: props.settlementsForSelectedGroup,
      selectedItemsIds: [],
      duesToApprove: [],
      duesToCorrectIds: [],
      isAlert: true
    };
    this.handleChargingTypeChange = this.handleChargingTypeChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleFilterKeywordChange = this.handleFilterKeywordChange.bind(this);
    this.applyFilters = this.applyFilters.bind(this);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.handleCalculateStart = this.handleCalculateStart.bind(this);
    this.handleCalculateCancel = this.handleCalculateCancel.bind(this);
    this.handleCalculate = this.handleCalculate.bind(this);

    this.handlePrint = this.handlePrint.bind(this);
    this.handleXlsPrint = this.handleXlsPrint.bind(this);

    this.handleApproveStart = this.handleApproveStart.bind(this);
    this.handleApproveCancel = this.handleApproveCancel.bind(this);
    this.handleApprove = this.handleApprove.bind(this);

    this.handleDuesCancel = this.handleDuesCancel.bind(this);
    this.handleDueCancel = this.handleDueCancel.bind(this);

    this.handleDuesDelete = this.handleDuesDelete.bind(this);

    this.handleRegisterPaymentStart = this.handleRegisterPaymentStart.bind(this);
    this.handleRegisterPaymentCancel = this.handleRegisterPaymentCancel.bind(this);
    this.handleRegisterPayment = this.handleRegisterPayment.bind(this);

    this.handleRegisterRebateStart = this.handleRegisterRebateStart.bind(this);
    this.handleRegisterRebateCancel = this.handleRegisterRebateCancel.bind(this);
    this.handleRegisterRebate = this.handleRegisterRebate.bind(this);

    this.handleUndoPayment = this.handleUndoPayment.bind(this);
    this.handleCancelDelayInterest = this.handleCancelDelayInterest.bind(this);
    this.handleSaveCustomDelayInterest = this.handleSaveCustomDelayInterest.bind(this);
    this.handleReserveBalanceFunds = this.handleReserveBalanceFunds.bind(this);
    this.handleRemoveReservedFunds = this.handleRemoveReservedFunds.bind(this);
    this.handleCancelOnlinePayment = this.handleCancelOnlinePayment.bind(this);
    this.handleRemoveRebate = this.handleRemoveRebate.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleCalculateCorrectiveDuesStart = this.handleCalculateCorrectiveDuesStart.bind(this);
    this.handleCalculateCorrectiveDuesCancel = this.handleCalculateCorrectiveDuesCancel.bind(this);
    this.handleCalculateCorrectiveDues = this.handleCalculateCorrectiveDues.bind(this);

    this.redirectToOpenSettlementDetailsModal = this.redirectToOpenSettlementDetailsModal.bind(this);
    this.redirectToOpenSettlementGroup = this.redirectToOpenSettlementGroup.bind(this);
  }

  componentDidMount() {
    this.applyFilters();
    if (this.state.currentSettlement) {
      this.handleOpen(this.state.currentSettlement);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settlements !== this.props.settlements) {
      this.applyFilters(this.props.settlements);
    }
    if (prevProps.settlementId !== this.props.settlementId) {
      if (this.props.settlementId) {
        const currentSettlement = this.props.settlements.find((s) => s.id === this.props.settlementId);
        this.handleOpen(currentSettlement);
        // this.setState({ currentSettlement });
      }
    }
    if (prevProps.settlementsForSelectedGroup !== this.props.settlementsForSelectedGroup) {
      this.setState({
        filteredItems: this.props.settlementsForSelectedGroup
      });
    }

    if (prevProps.group !== this.props.group) {
      this.setState({
        selectedItemsIds: []
      });
    }
  }

  // settlementsForSelectedGroup() {
  //   console.log('getting settlements for selected group');
  //   if (this.props.chosenGroup.id === 'all') {
  //     return this.props.settlements;
  //   } else
  //     return this.props.settlements.filter(
  //       (s) => this.props.chosenGroup && s.person.groupId === this.props.chosenGroup.id
  //     );
  // }

  handleChargingTypeChange(value) {
    this.props.actions.saveSettlementsFilters({ selectedChargingType: value }).then(() => this.applyFilters());
  }

  handleStatusChange(value) {
    this.props.actions.saveSettlementsFilters({ selectedStatus: value }).then(() => this.applyFilters());
  }

  handleFilterKeywordChange(keyword) {
    this.props.actions.saveSettlementsFilters({ filterKeyword: keyword }).then(() => this.applyFilters());
  }

  applyFilters(settlements) {
    const { selectedChargingType, selectedStatus, filterKeyword } = this.props.settlementsFilters;
    const filteredItems = _.orderBy(
      settlements || this.props.settlements,
      ['calculationStatus', 'person.lastName', 'person.firstName', 'dueType'],
      ['desc']
    )
      .filter(
        (s) =>
          this.props.chosenGroup.id === 'all' ||
          (this.props.chosenGroup && this.props.chosenGroup.id === s.person.groupId)
      )
      .filter((s) => !selectedStatus || selectedStatus === s.calculationStatus)
      .filter((s) => !selectedChargingType || selectedChargingType === s.dueType)
      .filter(
        (s) =>
          !filterKeyword ||
          s.person.firstName.toLowerCase().includes(filterKeyword) ||
          s.person.lastName.toLowerCase().includes(filterKeyword)
      );
    this.setState({ selectedItemsIds: [], filteredItems });
  }

  redirectToOpenSettlementDetailsModal(settlement) {
    const { year, month, group } = this.props;
    browserHistory.push(fromTemplate(routePaths.settlementsGroupDetails, [year, month, group.id, settlement.id]));
  }

  redirectToOpenSettlementGroup() {
    const { year, month, group } = this.props;
    browserHistory.push(fromTemplate(routePaths.settlementsGroup, [year, month, group.id]));
  }

  handleOpen(settlement) {
    this.setState({ currentSettlement: settlement });
    this.props.actions.updateSettlementStart();
  }

  handleSave(settlement) {
    this.props.actions.updateSettlementAsync(settlement).finally(() => this.redirectToOpenSettlementGroup());
  }

  handleCancel() {
    this.props.actions.updateSettlementCancel();
    this.setState({ currentSettlement: null });
    browserHistory.goBack();
  }

  handleCalculateStart() {
    this.props.actions.calculateSettlementsStart();
  }

  handleCalculateCancel() {
    this.props.actions.calculateSettlementsCancel();
  }

  handleCalculate(params) {
    if (this.props.chosenGroup.id !== 'all') {
      params.groupIds.push(this.props.chosenGroup.id);
    } else {
      params.groupIds = this.props.groups.map((element) => element.id);
    }
    this.props.actions.calculateSettlementsAsync(this.props.year, this.props.month, params);
  }

  handleCalculateCorrectiveDuesStart(duesIds) {
    this.setState({ duesToCorrectIds: duesIds });
    this.props.actions.calculateCorrectiveDuesStart();
  }

  handleCalculateCorrectiveDuesCancel() {
    this.setState({ duesToCorrectIds: [] });
    this.props.actions.calculateCorrectiveDuesCancel();
  }

  handleCalculateCorrectiveDues(options) {
    this.props.actions.calculateCorrectiveDuesAsync(
      this.props.year,
      this.props.month,
      [...this.state.duesToCorrectIds],
      options
    );
  }

  handlePrint(duesIds) {
    this.props.actions.print(duesIds);
    this.setState({ selectedItemsIds: [] });
  }

  handleXlsPrint(childId, dueType) {
    this.props.reportsActions.generateReportAsync('detailedPaymentsSummaryReport', 'xls', {
      childId,
      fromMonth: this.props.month,
      fromYear: this.props.year,
      toMonth: this.props.month,
      toYear: this.props.year,
      dueType,
      dontShowNegativeValues: false,
      totalPaymentsAmount: false
    });
  }

  handleApproveStart(duesIds) {
    this.setState({ duesToApprove: duesIds });
    this.props.actions.approveDuesStart();
  }

  handleApproveCancel() {
    this.props.actions.approveDuesCancel();
  }

  handleApprove(approveDetails) {
    this.props.actions.approveDuesAsync(this.props.year, this.props.month, approveDetails);
  }

  handleDuesCancel(settlementIds) {
    this.props.actions.cancelSettlementsAsync(this.props.year, this.props.month, settlementIds);
  }

  handleDuesDelete(settlementIds) {
    this.props.actions.deleteSettlementsAsync(this.props.year, this.props.month, settlementIds);
  }

  handleDueCancel(settlement) {
    this.props.actions.cancelSettlementAsync(this.props.year, this.props.month, settlement);
  }

  handleRegisterPaymentStart(settlement) {
    this.setState({ selectedItemsIds: [], currentSettlement: settlement });
    this.props.actions.registerPaymentsStart();
  }

  handleRegisterPaymentCancel() {
    this.props.actions.registerPaymentsCancel();
    this.setState({ currentSettlement: null });
  }

  handleRegisterPayment(paymentDetails) {
    this.props.actions.registerPaymentsAsync(
      paymentDetails,
      this.state.currentSettlement.person,
      this.props.year,
      this.props.month
    );
  }

  handleRegisterRebateStart(settlement) {
    this.setState({ selectedItemsIds: [], currentSettlement: settlement });
    this.props.actions.registerRebateStart();
  }

  handleRegisterRebateCancel() {
    this.props.actions.registerRebateCancel();
    this.setState({ currentSettlement: null });
  }

  handleRegisterRebate(rebateDetails) {
    this.props.actions.registerRebateAsync(rebateDetails, this.state.currentSettlement);
  }

  handleUndoPayment(settlement, paymentId) {
    this.props.actions.undoPaymentAsync(settlement, paymentId).finally(() => this.handleCancel());
  }

  handleCancelDelayInterest(settlement) {
    return this.props.actions.cancelSettlementDelayInterestsAsync(settlement);
  }

  handleSaveCustomDelayInterest(settlement, value) {
    return this.props.actions.saveCustomInterestsAsync(settlement, value);
  }

  handleReserveBalanceFunds(settlement) {
    return this.props.actions.reserveBalanceFunds(settlement);
  }

  handleRemoveReservedFunds(settlement) {
    return this.props.actions.removeReservedFunds(settlement);
  }

  handleCancelOnlinePayment(settlement, onlinePaymentId) {
    this.props.actions.cancelOnlinePaymentAsync(settlement, onlinePaymentId).finally(() => this.handleCancel());
  }

  handleRemoveRebate(settlement) {
    this.props.actions.removeRebateAsync(settlement);
  }

  handleSelect(selection) {
    this.setState((prevState) => {
      let selectedItemsIds = [...prevState.selectedItemsIds];
      if (selection === 'all') {
        selectedItemsIds = prevState.filteredItems.map((i) => i.id);
      } else if (selection === 'none') {
        selectedItemsIds = [];
      } else if (selection && selection.id) {
        if (selectedItemsIds.some((i) => i === selection.id)) {
          _.remove(selectedItemsIds, (i) => i === selection.id);
        } else {
          selectedItemsIds.push(selection.id);
        }
      }

      return { ...prevState, selectedItemsIds };
    });
  }

  canBulkCorrect() {
    if (this.state.selectedItemsIds.length === 0) return false;
    return _.every(
      this.state.selectedItemsIds.map((id) => this.props.settlements.find((s) => s.id === id)?.canBeCorrected())
    );
  }

  canBulkCancel() {
    if (this.state.selectedItemsIds.length === 0) return false;
    return _.every(
      this.state.selectedItemsIds.map((id) => this.props.settlements.find((s) => s.id === id)?.canBeCanceled())
    );
  }

  canBulkDelete() {
    if (this.state.selectedItemsIds.length === 0) return false;
    return _.every(
      this.state.selectedItemsIds.map((id) => this.props.settlements.find((s) => s.id === id)?.canBeDeleted())
    );
  }

  canBulkApprove() {
    if (this.state.selectedItemsIds.length === 0) return false;
    return _.every(
      this.state.selectedItemsIds.map((id) => this.props.settlements.find((s) => s.id === id)?.canBeApproved())
    );
  }

  renderList() {
    if (this.props.settlementsForSelectedGroup.length > 0 && !!this.props.chosenGroup.id) {
      return (
        <SettlementsList
          settlements={this.state.filteredItems}
          selectedSettlementsIds={this.state.selectedItemsIds}
          onChoose={this.redirectToOpenSettlementDetailsModal}
          onSelect={this.handleSelect}
          onCancel={this.handleDueCancel}
          onApprove={this.handleApproveStart}
          onDelete={this.handleDuesDelete}
          onRegisterPayment={this.handleRegisterPaymentStart}
          onRegisterRebate={this.handleRegisterRebateStart}
          onRemoveRebate={this.handleRemoveRebate}
          onPrint={this.handlePrint}
          onXlsPrint={this.handleXlsPrint}
          personLabel="Dziecko"
          onCalculateCorrectiveDues={this.handleCalculateCorrectiveDuesStart}
          isContrastColor={this.props.isContrastColor === 'contrast'}
        />
      );
    }
    if (this.props.chosenGroup?.id === 'all') {
      return (
        <Paper sx={{ textAlign: 'center', p: 5 }}>
          <EmptyState message="Nie wygenerowano jeszcze opłat dla żadnej grupy" contrast />
        </Paper>
      );
    }
    return (
      <Paper sx={{ textAlign: 'center', p: 5 }}>
        <EmptyState message="Nie wygenerowano jeszcze opłat dla wybranej grupy" contrast />
        <Button variant="contained" aria-label="Wygeneruj rozliczenia" onClick={this.handleCalculateStart}>
          Wygeneruj rozliczenia
        </Button>
      </Paper>
    );
  }

  renderBulkActions() {
    if (this.state.selectedItemsIds.length === 0) {
      return this.renderActionsToolbar();
    }
    return (
      <Grid container>
        <Grid item container xs={12} xl={5}>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <Avatar size={20} sx={{ display: 'flex', alignSelf: 'center', mr: 2 }} key="avatar">
              {this.state.selectedItemsIds.length}
            </Avatar>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <Typography sx={{ mr: 1, display: 'flex', color: (theme) => theme.palette.color.primary, fontSize: 16 }}>
              opłaty:
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary,
                fontSize: 16
              }}>
              {numberFormatter.format(
                _.round(
                  _.sum(
                    this.props.settlements
                      .filter((s) => this.state.selectedItemsIds.some((id) => id === s.id))
                      .map((s) => _.round(s.getTotalAmount(), 2))
                  ),
                  2
                )
              )}
              zł
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <Button
              aria-label="Zatwierdź"
              key="approve-button"
              variant="text"
              sx={{ color: (theme) => theme.palette.color.primary, fontSize: 16, width: 100 }}
              disabled={!this.canBulkApprove()}
              onClick={() => this.handleApproveStart(this.state.selectedItemsIds)}
              startIcon={<DoneAllIcon />}>
              zatwierdź
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <Button
              aria-label="Korekta"
              key="calculate-correction-button"
              variant="text"
              sx={{ color: (theme) => theme.palette.color.primary, fontSize: 16, width: 100 }}
              disabled={!this.canBulkCorrect()}
              onClick={() => this.handleCalculateCorrectiveDuesStart(this.state.selectedItemsIds)}
              startIcon={<UpdateIcon />}>
              korekta
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} xl={7}>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <ActionConfirmationButton
              actionLabel="Anuluj"
              variant="text"
              sx={{ color: (theme) => theme.palette.color.primary, fontSize: 16, width: 100 }}
              confirmationText="Czy na pewno chcesz anulować wybrane opłaty? Tej operacji nie można cofnąć."
              confirmationTitle="Anulowanie opłat"
              isDisabled={!this.canBulkCancel()}
              onConfirm={() => this.handleDuesCancel(this.state.selectedItemsIds)}
              onCancel={() => {}}
              actionIcon={<CancelOutlinedIcon />}
              key="cancel-button"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <ActionConfirmationButton
              actionLabel="Usuń"
              variant="text"
              sx={{ color: (theme) => theme.palette.color.primary, fontSize: 16, width: 100 }}
              confirmationText="Czy na pewno chcesz usunąć wybrane opłaty? Tej operacji nie można cofnąć."
              confirmationTitle="Usuwanie opłat"
              isDisabled={!this.canBulkDelete()}
              onConfirm={() => this.handleDuesDelete(this.state.selectedItemsIds)}
              onCancel={() => {}}
              actionIcon={<DeleteForeverOutlinedIcon />}
              key="delete-button"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            <Button
              sx={{
                color: (theme) => theme.palette.color.primary,
                whiteSpace: { xs: 'wrap', md: 'nowrap' }
              }}
              aria-label="Pobierz wydruki(.pdf)"
              disabled={this.state.selectedItemsIds.length === 0}
              onClick={() => this.handlePrint(this.state.selectedItemsIds)}
              startIcon={<PictureAsPdfIcon />}>
              Pobierz wydruki(.pdf)
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' }, alignItems: 'center' }}>
            {this.renderActionsToolbar()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderActionsToolbar() {
    return this.props.chosenGroup && this.props.chosenGroup.id !== 'all' ? (
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, alignItems: 'center' }}>
        <Button
          aria-label="przelicz opłaty"
          variant="contained"
          sx={{
            color: (theme) => theme.palette.color.primary,
            whiteSpace: { xs: 'wrap', md: 'nowrap', testAlign: 'left' }
          }}
          disabled={this.props.settlementsForSelectedGroup.length === 0}
          onClick={this.handleCalculateStart}
          label="Przelicz opłaty">
          <RefreshIcon />
          przelicz opłaty
        </Button>
      </Box>
    ) : null;
  }

  renderFilters() {
    return (
      <SettlementsFilters
        sx={{ pb: 2, mt: 0 }}
        selectedChargingType={this.props.settlementsFilters.selectedChargingType}
        selectedStatus={this.props.settlementsFilters.selectedStatus}
        handleChargingTypeChange={this.handleChargingTypeChange}
        handleStatusChange={this.handleStatusChange}
      />
    );
  }

  renderDetailsDialog() {
    if (!this.props.detailsUi.isEditing) {
      return null;
    }
    return (
      <SettlementDetailsDialog
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        isDialogOpen={this.props.detailsUi.isEditing}
        isProcessing={this.props.detailsUi.isProcessing}
        settlement={this.state.currentSettlement}
        undoPayment={this.handleUndoPayment}
        cancelOnlinePayment={this.handleCancelOnlinePayment}
        cancelDelayInterest={this.handleCancelDelayInterest}
        reserveBalanceFunds={this.handleReserveBalanceFunds}
        removeReservedFunds={this.handleRemoveReservedFunds}
        saveCustomInterest={this.handleSaveCustomDelayInterest}
      />
    );
  }

  renderCalculateSettlementsDialog() {
    if (!this.props.calculationUi.isEditing) {
      return null;
    }
    return (
      <CalculateChildrenSettlementsDialog
        groupName={this.props.chosenGroup.name}
        groupId={this.props.chosenGroup.id}
        onSave={this.handleCalculate}
        onCancel={this.handleCalculateCancel}
        isDialogOpen={this.props.calculationUi.isEditing}
        isProcessing={this.props.calculationUi.isProcessing}
      />
    );
  }

  renderCalculateCorrectiveDuesDialog() {
    if (!this.props.correctionsCalculationUi.isEditing) {
      return null;
    }
    return (
      <CalculateChildrenCorrectiveDuesDialog
        onSave={this.handleCalculateCorrectiveDues}
        onCancel={this.handleCalculateCorrectiveDuesCancel}
        isDialogOpen={this.props.correctionsCalculationUi.isEditing}
        isProcessing={this.props.correctionsCalculationUi.isProcessing}
      />
    );
  }

  renderRegisterPaymentDialog() {
    if (!this.props.registerPaymentsUi.isEditing) {
      return null;
    }
    return (
      <RegisterPaymentsDialog
        onSave={this.handleRegisterPayment}
        onCancel={this.handleRegisterPaymentCancel}
        isDialogOpen={this.props.registerPaymentsUi.isEditing}
        isProcessing={this.props.registerPaymentsUi.isProcessing}
        person={this.state.currentSettlement.person}
        dueType={this.state.currentSettlement.dueType}
        year={this.props.year}
        month={this.props.month}
      />
    );
  }

  renderRegisterRebateDialog() {
    if (!this.props.registerRebateUi.isEditing) {
      return null;
    }
    return (
      <RegisterRebateDialog
        onSave={this.handleRegisterRebate}
        onCancel={this.handleRegisterRebateCancel}
        isDialogOpen={this.props.registerRebateUi.isEditing}
        isProcessing={this.props.registerRebateUi.isProcessing}
        settlement={this.state.currentSettlement}
      />
    );
  }

  renderApproveDuesDialog() {
    if (!this.props.approveDuesUi.isEditing) {
      return null;
    }
    return (
      <ApproveDuesDialog
        onSave={this.handleApprove}
        onCancel={this.handleApproveCancel}
        isDialogOpen={this.props.approveDuesUi.isEditing}
        isProcessing={this.props.approveDuesUi.isProcessing}
        dueCalculationsIds={this.state.duesToApprove}
      />
    );
  }

  renderAvailableFundsInfo() {
    if (!this.state.filteredItems.some((due) => due.canBePaidWithBalanceFunds())) {
      return null;
    }
    return (
      <>
        {this.state.isAlert ? (
          <Alert
            sx={{ my: 2 }}
            onClose={() => {
              this.setState({ isAlert: false });
            }}
            severity="info">
            Podświetlone opłaty można rozliczyć za pomocą dostępnych środków
          </Alert>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <Box>
        <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} spacing={2}>
          <SettlementsStatistics
            settlements={this.props.settlementsForSelectedGroup}
            sx={{ justifyContent: { xs: 'center', sm: 'flex-end', md: 'start' } }}
          />

          {this.renderFilters()}
        </Stack>
        {this.renderAvailableFundsInfo()}
        {this.renderBulkActions()}
        <FilterBar
          sx={{ py: 4 }}
          itemsToFilter={this.props.settlements}
          onKeywordChange={this.handleFilterKeywordChange}
          initialKeyword={this.props.settlementsFilters.filterKeyword}
        />
        {this.renderList()}

        {this.renderCalculateSettlementsDialog()}
        {this.renderCalculateCorrectiveDuesDialog()}
        {this.renderDetailsDialog()}
        {this.renderRegisterPaymentDialog()}
        {this.renderRegisterRebateDialog()}
        {this.renderApproveDuesDialog()}
      </Box>
    );
  }
}

ChildrenSettlementsPage.propTypes = {
  settlements: PropTypes.array.isRequired,
  settlementsForSelectedGroup: PropTypes.array.isRequired,
  detailsUi: PropTypes.object.isRequired,
  calculationUi: PropTypes.object.isRequired,
  correctionsCalculationUi: PropTypes.object.isRequired,
  registerPaymentsUi: PropTypes.object.isRequired,
  registerRebateUi: PropTypes.object.isRequired,
  approveDuesUi: PropTypes.object.isRequired,
  listUi: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  currentSettlement: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  settlementId: PropTypes.string,
  settlementsFilters: PropTypes.object.isRequired,
  isContrastColor: PropTypes.string,
  reportsActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    settlements: state.settlements,
    detailsUi: state.settlementsUi,
    calculationUi: state.settlementsCalculationUi,
    correctionsCalculationUi: state.correctiveDuesCalculationUi,
    registerPaymentsUi: state.registerPaymentsUi,
    registerRebateUi: state.registerRebateUi,
    approveDuesUi: state.approveDuesUi,
    listUi: state.settlementsListUi,
    isLoading: state.isLoading,
    settlementsFilters: state.settlementsFilters,
    isContrastColor: state.contrastColor,
    groups: state.groups,
    chosenGroup: state.digitalDiary.choosenGroup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch),
    reportsActions: bindActionCreators(allReportsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenSettlementsPage);
