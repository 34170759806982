import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

const TextFileIcon = (props) => {
  const theme = useTheme();
  const textFileIconColor = theme.palette.components.fileIcons.textFile;

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M7 11C7 10.4477 7.44772 10 8 10H15C15.5523 10 16 10.4477 16 11V11C16 11.5523 15.5523 12 15 12H8C7.44772 12 7 11.5523 7 11V11Z"
        fill={textFileIconColor}
      />
      <path
        d="M7 14C7 13.4477 7.44772 13 8 13H15C15.5523 13 16 13.4477 16 14V14C16 14.5523 15.5523 15 15 15H8C7.44772 15 7 14.5523 7 14V14Z"
        fill={textFileIconColor}
      />
      <path
        d="M7 17C7 16.4477 7.44772 16 8 16H13C13.5523 16 14 16.4477 14 17V17C14 17.5523 13.5523 18 13 18H8C7.44772 18 7 17.5523 7 17V17Z"
        fill={textFileIconColor}
      />
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H12C12.5523 4 13 4.44772 13 5V8C13 8.55228 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V19Z"
        fill={textFileIconColor}
      />
    </SvgIcon>
  );
};

export default TextFileIcon;
