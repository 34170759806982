import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Form from '../../forms/Form';
import EmptyState from '../../common/EmptyState';
import { Link, browserHistory } from 'react-router';
import { SettlementModel } from '../../../models/settlements/SettlementModels';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { CalculationStatus } from '../../../constants/calculationStatus';
import { PersonTypes } from '../../../constants/personTypes';
import { SettlementModelValidator } from '../../../models/settlements/SettlementModelsValidators';
import SettlementDetails from './SettlementDetails';
import Payments from '../payments/RegisteredPaymentsList';
import PaymentRebate from '../payments/PaymentRebate';
import WarningSummary, { validationTypes } from '../../common/validation/ValidationSummary';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import { routePaths, fromTemplate } from '../../../routePaths';
import * as settlementsActions from '../../../actions/settlementsActions';
import moment from 'moment';
import _ from 'lodash';
import { Box, Button, Typography } from '@mui/material';

class DueDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settlement: new SettlementModel().assign(this.props.settlement),
      errors: {}
    };
    this.handleAdjustmentChange = this.handleAdjustmentChange.bind(this);
    this.handleCancelDelayInterest = this.handleCancelDelayInterest.bind(this);
    this.handleReserveBalanceFunds = this.handleReserveBalanceFunds.bind(this);
    this.handleRemoveReservedFunds = this.handleRemoveReservedFunds.bind(this);
  }

  handleCancelDelayInterest() {
    return this.props.cancelDelayInterest(this.state.settlement).then((r) => {
      if (!r) return;
      this.setState({
        settlement: Object.assign(new SettlementModel(), this.state.settlement, {
          totalDelayInterests: null,
          calculatedTotalWithDelayInterests: null,
          delayInterestCalculationDate: null
        })
      });
    });
  }

  handleAdjustmentChange(event) {
    const fieldName = event.target.name;
    const { schemeSnapshotId } = event.target;
    const { value } = event.target;
    const settlement = new SettlementModel().assign(this.state.settlement);
    const scheme = _.find(settlement.schemeResultItems, (s) => s.schemeSnaphot.id === schemeSnapshotId);
    scheme[fieldName] = value;

    if (fieldName === 'adjustmentAmount') {
      settlement.calculatedTotal =
        settlement.calculatedTotalUnadjusted +
        _.sum(settlement.schemeResultItems.map((s) => tryConvertToNumber(s.adjustmentAmount) || 0));
    }

    this.setState({ settlement });
  }

  handleReserveBalanceFunds() {
    return this.props.reserveBalanceFunds(this.state.settlement).then((result) => {
      if (!result) return;
      this.setState({
        settlement: Object.assign(new SettlementModel(), this.state.settlement, {
          reservedBalanceFunds: result.reservedBalanceFunds,
          calculatedTotal: result.newCalculatedTotal,
          personAvailableFunds: result.newAvailableFunds,
          hasFundsAvailable: result.newAvailableFunds > 0
        })
      });
    });
  }

  handleRemoveReservedFunds() {
    return this.props.removeReservedFunds(this.state.settlement).then((result) => {
      if (!result) return;
      this.setState({
        settlement: Object.assign(new SettlementModel(), this.state.settlement, {
          reservedBalanceFunds: null,
          calculatedTotal: result.newCalculatedTotal,
          personAvailableFunds: result.newAvailableFunds,
          hasFundsAvailable: result.newAvailableFunds > 0
        })
      });
    });
  }

  renderPersonLink(person) {
    const routePath = person.type === PersonTypes.child ? routePaths.childDetails : routePaths.staffMemberDetails;
    return (
      <Link to={fromTemplate(routePath, person.id)}>
        {person.firstName} {person.lastName}
      </Link>
    );
  }

  renderDialogTitle(settlement) {
    const reportTypeName = ChargingReportTypes[settlement.dueType].name.toLowerCase();
    return (
      <Box>
        <Box>
          {this.renderPersonLink(settlement.person)} -{' '}
          <Typography>{settlement.isCorrective() ? `${reportTypeName} (korekta)` : reportTypeName}</Typography>
          <Typography>{CalculationStatus[settlement.calculationStatus].name}</Typography>
        </Box>
      </Box>
    );
  }

  renderFinancialDetailsLinkButton(settlement) {
    if (settlement.person.type !== PersonTypes.child) return null;
    if (settlement.hasFundsAvailable === false) return null;
    return (
      <Button
        variant="contained"
        aria-label="rozlicz nadpłaty"
        disabled={this.props.detailsUi.isProcessing}
        onClick={() => browserHistory.push(fromTemplate(routePaths.childFinancialDetails, [settlement.person.id]))}>
        Rozlicz nadpłaty
      </Button>
    );
  }

  renderFailureMessage(message, code, details) {
    return (
      <Box>
        <WarningSummary
          messages={[message]}
          title="Wystąpił błąd podczas generowania rozliczenia"
          type={validationTypes.warning}
        />
        {code === 'unapprovedDrafts' && (
          <Link
            sx={{ textDecoration: 'none' }}
            to={fromTemplate(routePaths.settlementsGroupDetails, [
              details.dueMonth.substring(0, 4),
              details.dueMonth.substring(5, 7),
              details.dueGroupId,
              details.dueId
            ])}>
            <Typography sx={{ color: (theme) => theme.palette.color.contrast, textDecoration: 'underline' }}>
              Przejdź do niezatwierdzonego rozliczenia
            </Typography>
          </Link>
        )}
      </Box>
    );
  }

  renderPayments(settlement) {
    return (
      <Box>
        <Typography variant="h6">WPŁATY</Typography>
        {settlement.payments && settlement.payments.length > 0 ? (
          <Payments
            payments={settlement.payments}
            undoPayment={(paymentId) => this.props.undoPayment(settlement, paymentId)}
            cancelOnlinePayment={(onlinePaymentId) => this.props.cancelOnlinePayment(settlement, onlinePaymentId)}
            personType={settlement.person.type}
          />
        ) : (
          <EmptyState contrast message="Nie zarejetrowano jeszcze żadnych wpłat" noIcon={true} />
        )}
      </Box>
    );
  }

  renderRebates(settlement) {
    return (
      <Box>
        <Typography variant="h6">ZWROT</Typography>
        {settlement.paymentRebate ? (
          <PaymentRebate paymentRebate={settlement.paymentRebate} />
        ) : (
          <EmptyState contrast message="Nie zarejetrowano żadnego zwrotu" noIcon={true} />
        )}
      </Box>
    );
  }

  renderCalculations(settlement) {
    return (
      <Box>
        <SettlementDetails
          settlement={settlement}
          onAdjustmentChange={this.handleAdjustmentChange}
          onCancelDelayInterest={this.handleCancelDelayInterest}
          onUseAvailableFunds={this.handleUseAvailableFunds}
        />
        {settlement.calculationStatus === CalculationStatus.approved.value ||
        settlement.calculationStatus === CalculationStatus.paid.value ||
        settlement.calculationStatus === CalculationStatus.partlyPaid.value
          ? this.renderPayments(settlement)
          : null}
        {settlement.calculationStatus === CalculationStatus.paid.value ? this.renderRebates(settlement) : null}
      </Box>
    );
  }

  render() {
    const { onSave } = this.props;
    const { settlement } = this.state;
    const saveDisabled = settlement.calculationStatus !== CalculationStatus.draft.value;
    return (
      <Box>
        <Form
          header={this.renderDialogTitle(settlement)}
          onSave={() => onSave(settlement)}
          onCancel={() => browserHistory.goBack()}
          isProcessing={this.props.detailsUi.isProcessing}
          statePathToUi="settlementsUi"
          onValidate={() => new SettlementModelValidator().validate(settlement)}
          onValidationDone={(errors) => this.setState({ errors })}
          saveLabel="Zapisz"
          cancelLabel={saveDisabled ? 'Zamknij' : 'Anuluj'}
          saveDisabled={saveDisabled}>
          <header>
            <Box>Numer {settlement.number || 'zostanie wygenerowany po zatwierdzeniu'}</Box>
            <Box>Wygenerowano {moment(settlement.calculationDate).format('YYYY-MM-DD HH:mm')}</Box>
            {settlement.approvalDate ? (
              <Box>Zatwierdzono {moment(settlement.approvalDate).format('YYYY-MM-DD HH:mm')}</Box>
            ) : null}
            {settlement.delayInterestCalculationDate != null ? (
              <Box>Odsetki naliczono {moment(settlement.delayInterestCalculationDate).format('YYYY-MM-DD HH:mm')}</Box>
            ) : null}
          </header>

          {settlement.calculationStatus === CalculationStatus.failed.value
            ? this.renderFailureMessage(
                settlement.failureReason,
                settlement.failureReasonCode,
                settlement.failureDetails
              )
            : this.renderCalculations(settlement)}
          {this.renderFinancialDetailsLinkButton(settlement)}
        </Form>
      </Box>
    );
  }
}

DueDetailsPage.propTypes = {
  onSave: PropTypes.func.isRequired,
  detailsUi: PropTypes.object.isRequired,
  settlement: PropTypes.object.isRequired,
  undoPayment: PropTypes.func.isRequired,
  reserveBalanceFunds: PropTypes.func.isRequired,
  removeReservedFunds: PropTypes.func.isRequired,
  cancelOnlinePayment: PropTypes.func.isRequired,
  cancelDelayInterest: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    settlement: state.settlements.find((s) => s.id === ownProps.params.settlementId),
    detailsUi: state.settlementsUi,
    isLoading: state.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DueDetailsPage);
