/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChildAttendanceModel } from '../models/attendance/AttendanceModels';
import moment from 'moment';

export default function childAttendancesReducer(state = initialState.childAttendances, action) {
  switch (action.type) {
    case types.REPORT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS: {
      const isEqual = (c) => moment(c.date).format('YYYY-MM-DD') === moment(action.droppedOfAt).format('YYYY-MM-DD');
      if (!state.entries.find((c) => isEqual(c))) return state;

      const nextEntries = state.entries.map((e) => {
        return isEqual(e)
          ? Object.assign({}, e, {
              droppedOffAt: action.droppedOfAt,
              pickedUpAt: action.pickedUpAt,
              pickedUpBy: action.pickedUpBy,
              absenceFrom: null,
              absenceTo: null,
              absenceNotes: null,
              receivedBy: action.receivedBy,
              releasedBy: action.releasedBy
            })
          : e;
      });

      return Object.assign({}, state, { entries: [...nextEntries] });
    }
    case types.REVERT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS: {
      const isInRange = (c) => moment(c.date).isBetween(action.absenceFrom, action.absenceTo, 'day', '[]');
      if (!state.entries.find((c) => isInRange(c))) return state;

      const violated = action.absenceDeadlineViolated ? [{}] : [];
      const nextEntries = state.entries.map((e) => {
        return isInRange(e)
          ? Object.assign({}, e, {
              droppedOffAt: null,
              pickedUpAt: null,
              pickedUpBy: null,
              absenceFrom: action.absenceFrom,
              absenceTo: action.absenceTo,
              absenceNotes: action.absenceNotes,
              absenceReportedBy: action.absenceReportedBy,
              absenceReportedAt: moment().format(),
              violatedSchemeDeadlines: e.violatedSchemeDeadlines.length > 0 ? e.violatedSchemeDeadlines : violated
            })
          : e;
      });

      return Object.assign({}, state, { entries: [...nextEntries] });
    }
    case types.REVERT_CHILD_ABSENCE_SUCCESS: {
      const isInRange = (c) => moment(c.date).isBetween(action.from, action.to, 'day', '[]');
      if (!state.entries.find((c) => isInRange(c))) return state;

      const nextEntries = state.entries.map((e) => {
        return isInRange(e)
          ? Object.assign({}, e, {
              absenceFrom: null,
              absenceTo: null,
              absenceNotes: null,
              absenceReportedAt: null
            })
          : e;
      });

      return Object.assign({}, state, { entries: [...nextEntries] });
    }
    case types.LOAD_CHILD_ATTENDANCES_SUCCESS: {
      return new ChildAttendanceModel().assign(action.attendances);
    }
    default:
      return state;
  }
}
