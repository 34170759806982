import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function virtualDiscFolderUiReducer(state = initialState.virtualDiscFolderUI, action) {
  switch (action.type) {
    case types.VIRTUAL_DISC_ACTION_FOLDER_SUBMIT: {
      return Object.assign({}, state, { action: UiModel.submit() });
    }
    case types.VIRTUAL_DISC_ACTION_FOLDER_SUCCESS: {
      return Object.assign({}, state, { action: UiModel.success() });
    }
    case types.VIRTUAL_DISC_ACTION_FOLDER_FAILURE: {
      return Object.assign({}, state, { action: UiModel.failure() });
    }
    case types.VIRTUAL_DISC_FETCH_FOLDER_SUBMIT: {
      return Object.assign({}, state, { fetch: UiModel.submit() });
    }
    case types.VIRTUAL_DISC_FETCH_FOLDER_SUCCESS: {
      return Object.assign({}, state, { fetch: UiModel.success() });
    }
    case types.VIRTUAL_DISC_FETCH_FOLDER_FAILURE: {
      return Object.assign({}, state, { fetch: UiModel.failure() });
    }
    default:
      return state;
  }
}
