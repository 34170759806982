import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import ChildLegalGuardianForm from './ChildLegalGuardianForm';
import ChildLegalGuardiansList from './ChildLegalGuardiansList';
import { LegalGuardianModel } from '../../../models/children/ChildModels';
import { LegalGuardianModelValidator } from '../../../models/children/ChildModelsValidator';
import ActionButton from '../../forms/buttons/ActionButton';
import LegalGuardiansListDialog from './LegalGuardiansListDialog';
import EmptyState from '../../common/EmptyState';
import { Box } from '@mui/material';

const ChildLegalGuardiansCard = (props) => {
  const [activeGuardian, setActiveGuardian] = useState({});
  const [errors, setErrors] = useState({});
  const [showLegalGuardiansDialog, setShowLegalGuardiansDialog] = useState(false);

  useEffect(() => {
    setErrors({});
  }, [activeGuardian]);

  const handleCreate = () => {
    setActiveGuardian(new LegalGuardianModel());
    setShowLegalGuardiansDialog(false);
    props.onCreate();
  };

  const handleEdit = (guardian) => {
    setActiveGuardian(new LegalGuardianModel().assign(guardian));
    setShowLegalGuardiansDialog(false);
    props.onEdit();
  };

  const handleGuardianChange = (event) => {
    setActiveGuardian({ ...activeGuardian, [event.target.name]: event.target.value });
  };

  const handleCopyChildAddress = () => {
    setActiveGuardian({
      ...activeGuardian,
      address: `${props.pupilAddress.address}, ${props.pupilAddress.zipCode} ${props.pupilAddress.city}`
    });
  };

  const handleChooseLegalGuardian = () => {
    setShowLegalGuardiansDialog(true);
  };

  const handleChooseLegalGuardianDialogClose = () => {
    setShowLegalGuardiansDialog(false);
  };

  const handleLegalGuardianAssignment = (guardian) => {
    setShowLegalGuardiansDialog(false);
    props.onSave(guardian);
  };

  const renderEditForm = () => {
    return (
      <ChildLegalGuardianForm
        copyChildAddress={handleCopyChildAddress}
        guardian={activeGuardian}
        onChange={handleGuardianChange}
        errors={errors}
      />
    );
  };

  const renderReadonlyForm = () => {
    return (
      <ChildLegalGuardiansList
        guardians={props.guardians}
        isPinEnabled={props.isPinEnabled}
        onEdit={handleEdit}
        onRemove={props.onRemove}
      />
    );
  };

  const renderChooseGuardianActionButton = () => {
    return (
      <ActionButton
        onAction={handleChooseLegalGuardian}
        actionLabel="Wybierz istniejącego"
        inProgress={props.isProcessing}
      />
    );
  };

  const renderChooseLegalGuardianDialog = () => {
    return (
      <LegalGuardiansListDialog
        ignoredLegalGuardians={props.guardians.map((g) => g.id)}
        open={showLegalGuardiansDialog}
        onClose={handleChooseLegalGuardianDialogClose}
        onSelect={handleLegalGuardianAssignment}
      />
    );
  };
  return (
    <Box>
      <CardDialogEditForm
        title="Opiekunowie prawni"
        isInitiallyExpanded={props.isInitiallyExpanded}
        onSave={() => props.onSave(activeGuardian)}
        onCancel={() => props.onCancel(activeGuardian)}
        onAction={handleCreate}
        onValidate={() => new LegalGuardianModelValidator().validate(activeGuardian)}
        onValidationDone={(err) => setErrors(err)}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        statePathToUi="childDetailsUi.legalGuardians"
        actionLabel="Dodaj nowego"
        actionTitle="Opiekun prawny"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Brak przypisanych opiekunów" contrast />}
        editForm={renderEditForm()}
        isEmpty={props.guardians.length < 1}
        customActions={renderChooseGuardianActionButton()}
        requiredFieldText
      />
      {renderChooseLegalGuardianDialog()}
    </Box>
  );
};

ChildLegalGuardiansCard.propTypes = {
  guardians: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isPinEnabled: PropTypes.bool.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
  pupilAddress: PropTypes.object.isRequired
};

export default ChildLegalGuardiansCard;
