import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import SchemeTypeOptions from './SchemeTypeOptions';
import { ChargingSchemeTypes } from '../../../../constants/chargingSchemeTypes';
import HourlyChargingScheme from '../schemeForms/HourlyChargingScheme';

import DailyChargingScheme from '../schemeForms/DailyChargingScheme';

import MonthlyChargingScheme from '../schemeForms/MonthlyChargingScheme';
import { ChargingSchemeModel } from '../../../../models/configuration/chargingSchemes/ChargingSchemeModels';
import { ChargingSchemeModelValidator } from '../../../../models/configuration/chargingSchemes/ChargingSchemeModelsValidators';
import { TimeModel } from '../../../../models/TimeModel';

class ChargingSchemeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schemeTypeValue: this.props.scheme ? this.props.scheme.schemeType : null,
      scheme: this.props.scheme,
      errors: {}
    };
    this.handleSchemeTypeSelection = this.handleSchemeTypeSelection.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeadlineClear = this.handleDeadlineClear.bind(this);
  }

  getHeader(schemeTypeValue) {
    if (schemeTypeValue === ChargingSchemeTypes.hourly.value) return 'Płatność godzinowa';
    if (schemeTypeValue === ChargingSchemeTypes.daily.value) return 'Płatność dzienna';
    if (schemeTypeValue === ChargingSchemeTypes.monthly.value) return 'Płatność miesięczna';
    return 'Wybierz typ płatności';
  }

  handleSchemeTypeSelection(schemeType) {
    this.setState({
      scheme: ChargingSchemeModel.createEmpty(schemeType.value),
      schemeTypeValue: schemeType.value
    });
  }

  handleChange(event) {
    const field = event.target.name;
    const scheme = Object.assign({}, this.state.scheme);
    scheme[field] = event.target.value;
    return this.setState({ scheme });
  }

  handleDeadlineClear() {
    const scheme = Object.assign({}, this.state.scheme);
    scheme.absenceReportingDeadlineTime = new TimeModel();
    scheme.absenceReportingDeadlineDays = undefined;
    return this.setState({ scheme });
  }

  renderForm(schemeTypeValue) {
    const props = {
      scheme: this.state.scheme,
      onChange: this.handleChange,
      errors: this.state.errors,
      onDeadlineClear: this.handleDeadlineClear
    };
    if (schemeTypeValue === ChargingSchemeTypes.hourly.value) return <HourlyChargingScheme {...props} />;
    if (schemeTypeValue === ChargingSchemeTypes.daily.value) return <DailyChargingScheme {...props} />;
    if (schemeTypeValue === ChargingSchemeTypes.monthly.value) return <MonthlyChargingScheme {...props} />;
    return <SchemeTypeOptions onSelected={this.handleSchemeTypeSelection} />;
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        maxWidth="md"
        header={this.getHeader(this.state.schemeTypeValue)}
        onSave={() => onSave(this.state.scheme)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() =>
          this.state.schemeTypeValue
            ? ChargingSchemeModelValidator.createEmpty(this.state.schemeTypeValue).validate(this.state.scheme)
            : false
        }
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.chargingSchemesUi"
        saveDisabled={!this.state.schemeTypeValue}
        hideSubmit={!this.state.schemeTypeValue}
        requiredFieldText>
        {this.renderForm(this.state.schemeTypeValue)}
      </DialogForm>
    );
  }
}

ChargingSchemeDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  scheme: PropTypes.object
};

export default ChargingSchemeDialog;
