import moment from 'moment';
import { BaseModelValidator } from '../../models/BaseModelValidator';
import { check } from '../../models/validation/validateHelper';

export class PsychologicalChildValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      cause: (value) => check.isEmpty(value) && 'Nie podano przyczyny',
      child: (value) => check.isEmpty(value) && 'Nie wybrano dziecka',
      from: (value, model) => {
        if (check.isEmpty(value)) return 'Nie podano daty';
        if (moment(value).isAfter(moment(model.to)))
          return 'Data rozpoczęcia nie może być późniejsza niż data zakończenia';
        if (!moment(value).isValid()) return 'Niepoprawna data';
      },

      to: (value) => {
        if (check.isEmpty(value)) {
          return 'Nie podano daty';
        }
        if (!moment(value).isValid()) return 'Niepoprawna data';
      }
    };
  }
}
